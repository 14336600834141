import { useEffect, useState } from 'react'
import { CurrentPerson } from 'services/api/session'

export const getAccountName = (
  account: CurrentPerson,
  person: CurrentPerson
) => {
  if (!account?.id) {
    return '-'
  }
  if (account.id === person.id && person.isAccount) {
    return 'Personal'
  }
  return account.name
}

export const useAccountOptions = (borrowerAccounts, actor, person) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([])

  useEffect(() => {
    const accounts = [
      ...(borrowerAccounts ?? []),
      ...(person.isAccount ? [person] : []),
    ]
    const accountOptions = accounts.map((account) => {
      const name = getAccountName(account, person)
      return {
        value: account.id,
        label: name,
      }
    })
    setOptions(accountOptions)
  }, [borrowerAccounts, actor, person])

  return options
}
