import { Navigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useQuote } from 'admin/hooks/use-quotes'
import { pathTo } from 'admin/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { QuoteDraft } from './QuoteDraft'

const Quote = () => {
  const { id } = useParams() as { id: string }
  const { data: quote } = useQuote({ id })

  return quote ? (
    ['Archived', 'Converted'].includes(quote.status) ? (
      <Navigate to={pathTo('quotePreview', 'all', quote.id)} />
    ) : (
      <QuoteDraft quote={quote} />
    )
  ) : (
    <MainContent>
      <PageLoader />
    </MainContent>
  )
}

export { Quote }
