import { ColumnDef } from '@tanstack/react-table'
import { useMemo, ReactNode, Dispatch, SetStateAction } from 'react'
import { FundingInvestorStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import { LoanFundingSource, Filter, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

type Props = {
  loading: boolean
  formatName: (data: LoanFundingSource) => ReactNode
  filter?: Filter
  data?: LoanFundingSource[]
  sort?: Sort | undefined
  onClick?: (fundingSource?: LoanFundingSource) => void
  onFilter?: Dispatch<SetStateAction<Filter>>
  onSort?: (sort: Sort | string | undefined) => void
}

function TableInvestments({
  data = [],
  loading,
  filter,
  sort,
  formatName,
  onClick,
  onFilter,
  onSort,
}: Props) {
  const columns: ColumnDef<LoanFundingSource>[] = useMemo(
    () => [
      {
        header: 'Name',
        id: 'name',
        cell: ({ row }) => formatName(row.original),
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Yield',
        id: 'rate',
        size: 120,
        meta: {
          sortable: true,
        },
        cell: ({ row }) => (
          <Flex stack gap={4}>
            <div>
              {formatPercent(row.original.rate as number, {
                blankPlaceholder: '',
                maxDecimals: 3,
              })}
            </div>
            <div className={styles.minor}>{row.original.class}</div>
          </Flex>
        ),
      },
      {
        header: 'Invested',
        accessorKey: 'dateInvested',
        size: 115,
        meta: {
          sortable: true,
        },
        cell: ({ row, getValue }) => (
          <Flex stack gap={4}>
            <div>{formatUsd(row.original.committedAmount)}</div>
            <div className={styles.minor}>
              {formatDate(getValue() as string)}
            </div>
          </Flex>
        ),
      },

      {
        header: 'Returned',
        accessorKey: 'principalBalance',
        size: 110,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Balance',
        size: 115,
        accessorKey: 'amount',
        meta: {
          sortable: true,
        },
        cell: ({ getValue }) => formatUsd(getValue() as string),
      },
      {
        header: 'Income',
        accessorKey: 'interestBalance',
        size: 110,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 94,
        meta: onFilter
          ? {
              filter: {
                options: [
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                  {
                    value: 'Liquidated',
                    label: 'Liquidated',
                  },
                ],
                selected: filter?.status,
                onChange: (status) => onFilter({ status }),
              },
            }
          : undefined,
        cell: ({ row }) => {
          const { dateFunded, amount, loan, fund } = row.original
          return (
            <FundingInvestorStatusBadge
              dateFunded={dateFunded}
              amount={amount}
              loanStatus={loan?.status || (fund && 'Active')}
            />
          )
        },
      },
    ],
    [filter]
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onClick={onClick}
      onSort={onSort}
    />
  )
}

export default TableInvestments
