import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isArray } from 'lodash'
import {
  getOfferingImage,
  removeOfferingImage,
} from 'admin/services/api/offering-image'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useOfferingImage = (id: string) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id, 'image'],
    queryFn: () => getOfferingImage(id),
  })
}

const useAddOfferingImage = (id: string, url: string, imageId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          let image: string[] = []
          let retries = 0
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          if (imageId) {
            await removeOfferingImage(id, imageId)
          }
          await uploadDocument(url, file, (progressEvent) => {
            uploadToast.progress(progressEvent.loaded / progressEvent.total)
          })
          uploadToast.processing()
          while (isArray(image) && !image.length && retries < 5) {
            await new Promise((resolve) => setTimeout(resolve, 3000))
            const data = await getOfferingImage(id)
            image = data.images
            retries++
          }

          uploadToast.complete()
          return true
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id, 'image'] })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOfferingImage = (id: string, imageId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => removeOfferingImage(id, imageId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_OFFERINGS, id, 'image'],
        exact: true,
      })
      message.success('Image deleted')
    },
    onError: handleErrorResponse,
  })
}

export { useOfferingImage, useAddOfferingImage, useDeleteOfferingImage }
