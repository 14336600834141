import { request } from 'services/request'
import { CustomReport, Pagination, Sort, Meta } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

const getCustomReports = async (
  params: {
    sort?: Sort
    page?: Pagination
  } = {}
): Promise<{ reports: CustomReport[]; meta: Meta }> => {
  const {
    data: { reports, pagination },
  } = await request.get('/report', { params: updateRequestTable(params) })
  return { reports, meta: pagination }
}

const getCustomReport = async (id: string): Promise<CustomReport> => {
  const {
    data: { report },
  } = await request.get(`/report/${id}`)

  return report
}

const getCustomReportFields = async () => {
  const {
    data: { fields },
  } = await request.get('/report/fields')

  return fields
}

const addCustomReport = async (payload: {
  name: string
  description?: string
}): Promise<CustomReport> => {
  const {
    data: { report },
  } = await request.post('/report', payload)
  return report
}

const updateCustomReport = async (
  id: string,
  payload: { name?: string; config?: string }
): Promise<CustomReport> => {
  const {
    data: { report },
  } = await request.patch(`/report/${id}`, payload)

  return report
}

const removeCustomReport = async (
  id: string
): Promise<{ success: boolean }> => {
  return request.delete(`/report/${id}`)
}

export {
  getCustomReports,
  getCustomReport,
  getCustomReportFields,
  addCustomReport,
  updateCustomReport,
  removeCustomReport,
}
