import { isString, isNil } from 'lodash'

interface Options {
  showZero?: boolean
  decimals?: number
  maxDecimals?: number
  blankPlaceholder?: string
}

const formatPercent = (
  value: string | number | null,
  options: Options = {}
) => {
  const showZero = options.showZero || false
  const decimals = options.decimals ?? 3
  const blankPlaceholder = options.blankPlaceholder ?? '-'
  if (!isNil(value)) {
    const number = isString(value) ? parseFloat(value) : value
    if (number === 0 && !showZero) {
      return blankPlaceholder
    }
    if (options.maxDecimals) {
      return `${Number(number.toFixed(options.maxDecimals))}%`
    }
    return `${number.toFixed(decimals)}%`
  }

  return showZero ? '0%' : blankPlaceholder
}

export { formatPercent }
