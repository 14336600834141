import clsx from 'clsx'
import { Field } from 'formik'
import { Flex } from 'components/Flex'
import formStyles from 'components/Form/styles.module.scss'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  prefix: string
}

const PersonFormType = ({ prefix }: Props) => {
  return (
    <Field name={`${prefix}.type`}>
      {({ field: { value }, form: { setFieldValue }, meta }) => {
        return (
          <Flex stack gap={8} className={styles.chooseTypeWrapper}>
            <div className={styles.chooseType}>
              <div
                className={clsx(styles.type, {
                  [styles.selected]: value === 'individual',
                })}
                onClick={() => setFieldValue(`${prefix}.type`, 'individual')}
              >
                <Icon name={IconName.individual} size="xxl" />
                Personal
              </div>
              <div
                className={clsx(styles.type, {
                  [styles.selected]: value === 'entity',
                })}
                onClick={() => setFieldValue(`${prefix}.type`, 'entity')}
              >
                <Icon name={IconName.entity} size="xxl" />
                Company
              </div>
            </div>
            {meta.error && meta.touched && (
              <div className={formStyles.errorMessage}>{meta.error}</div>
            )}
          </Flex>
        )
      }}
    </Field>
  )
}

export { PersonFormType }
