import { request } from 'services/request'
import { Search, Filter, Meta, Pagination, PaymentDue } from 'types'

const getPaymentsDue = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ due: PaymentDue[]; meta: Meta }> => {
  const {
    data: { due, pagination },
  } = await request.get('/due', { params })
  return { due, meta: pagination }
}

const paymentsDueCount = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ count: number }> => {
  const { data } = await request.put(
    '/due',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

const getPaymentsDueBatchId = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ batch: string; count: number; countBanking: number }> => {
  const { data } = await request.post(
    '/due',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

const getPaymentFileUrl = async ({
  batchId,
  type,
}: {
  batchId: string
  type: 'csv' | 'nacha' | 'pdf'
}): Promise<{ download: string }> => {
  const { data } = await request.get(`/due/batch/${batchId}/${type}`)
  return data
}

const processPayments = async (
  batchId: string,
  pad: boolean
): Promise<{ success: boolean }> => {
  const { data } = await request.post(`/due/batch/${batchId}`, { pad })
  return data
}

export {
  getPaymentsDue,
  paymentsDueCount,
  getPaymentsDueBatchId,
  getPaymentFileUrl,
  processPayments,
}
