import { capitalize, isNil } from 'lodash'
import { Payment } from 'types/payment'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: Payment['status']
  datePaidout: string | null
  gl: string
}

const statusToColor: Record<string, ValueOf<Pick<BadgeProps, 'color'>>> = {
  reversed: 'red',
  cleared: 'green',
  'Paid Out': 'green',
  ready: 'blue',
  pending: 'yellow',
}

export const FundStatusBadge = ({ status, datePaidout, gl }: Props) => {
  let statusBadge = 'cleared'
  if (status === 'reversed') {
    statusBadge = 'reversed'
  } else if (!isNil(datePaidout)) {
    statusBadge = 'Paid Out'
  } else if (status === 'cleared' && ['1008', '1004', '1011'].includes(gl)) {
    statusBadge = 'ready'
  } else if (status === 'pending') {
    statusBadge = 'pending'
  }
  return (
    <Badge color={statusToColor[statusBadge]}>{capitalize(statusBadge)}</Badge>
  )
}
