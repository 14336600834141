import { request } from 'services/request'

type Image = {
  image: any
  client: any
  uploadUrl: string
  uploadIconUrl: string
}

const getClientImage = async (clientId: string): Promise<Image> => {
  const { data } = await request.get(`/client/${clientId}/image`)
  return data
}

export { getClientImage }
