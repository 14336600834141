import { User } from 'admin/services/api/users'
import { request } from 'services/request'
import { PersonDetails } from 'types'

interface Props {
  managerId: string
  type: 'borrower' | 'investor'
}

const getMembers = async ({
  managerId,
  type,
}: Props): Promise<{ borrower?: PersonDetails; investor?: PersonDetails }> => {
  const {
    data: { [type]: person },
  } = await request.get(`/${type}/${managerId}`)
  return {
    [type]: person,
  }
}

const addMember = async ({
  managerId,
  type,
  name,
  email,
  role,
}: {
  type: Props['type']
  managerId: string
  name: string
  email: string
  role: string
}): Promise<{ borrower?: PersonDetails; investor?: PersonDetails }> => {
  const {
    data: { [type]: person },
  } = await request.put(`/${type}/${managerId}/manager`, {
    name,
    email,
    role,
  })
  return {
    [type]: person,
  }
}

const updateMember = async ({
  managerId,
  type,
  memberId,
  role,
}: {
  managerId: string
  type: Props['type']
  memberId: string
  role: string
}): Promise<User> => {
  const {
    data: { user },
  } = await request.patch(`/${type}/${managerId}/manager/${memberId}`, {
    role,
  })
  return user
}

const reinviteMember = async ({
  managerId,
  type,
  memberId,
}: {
  managerId: string
  type: Props['type']
  memberId: string
}): Promise<User> => {
  const {
    data: { user },
  } = await request.put(`/${type}/${managerId}/manager`, {
    person_id: memberId,
  })
  return user
}

const deleteMember = async ({
  managerId,
  type,
  memberId,
}: {
  managerId: string
  type: Props['type']
  memberId: string
}): Promise<User> => {
  const {
    data: { user },
  } = await request.delete(`/${type}/${managerId}/manager/${memberId}`)
  return user
}

export { getMembers, addMember, updateMember, deleteMember, reinviteMember }
