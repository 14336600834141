import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { Flex } from 'components/Flex'
import { PanelTemplates } from './PanelTemplates'

function DocumentTemplates() {
  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Templates" />

        <PanelTemplates />
      </Flex>
    </MainContent>
  )
}

export { DocumentTemplates }
