import { get } from 'lodash'
import { getFundInvestors } from 'admin/services/api/fund-investors'
import { getFundTransactions } from 'admin/services/api/fund-payment'
import { CurrentUser } from 'services/api/session'
import { Filter, Row, Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { getFundingType } from 'utils/loan-transactions'

interface Props {
  fundId: string
  filter: Filter
  user: CurrentUser | null
}

const downloadFundTransactions = async ({
  fundId,
  filter,
  user,
}: Props): Promise<Row[]> => {
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })
  const isBalanceVisible = settings.autoInvestorAccounting !== 'Yes'

  const [source, investors] = await Promise.all([
    await getFundTransactions({
      id: fundId,
      filter,
      page: { page: 0, size: 1000 },
    }),
    await getFundInvestors(fundId),
  ])

  const transactions = source.transactions.map(
    (transaction) =>
      ({
        ...transaction,
        investor: investors?.find(
          (investor) =>
            investor.fundInvestors.id === transaction?.fundingInvestorId
        ),
      }) as Transaction & { investor: { id: string; name: string } }
  )

  return transactions.map((transaction) => ({
    Date: transaction.date,
    Type: getFundingType(transaction, true),
    Reference: (() => {
      if (transaction.investor) {
        return transaction.investor.name
      }
      if (transaction.loan?.name || transaction.fund?.name) {
        return transaction.loan?.name || transaction.fund?.name || ''
      }
      return transaction.payment?.description
    })(),
    Amount: formatUsd(transaction.amount),
    ...(isBalanceVisible ? { Balance: formatUsd(transaction.balance) } : {}),
  }))
}

export { downloadFundTransactions }
