import DOMPurify from 'dompurify'
import { Avatar } from 'components/Avatar'
import { getColor } from 'components/Avatar/Avatar'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import theme from 'styles/theme'
import { Comment } from 'types'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  comments: Comment[]
  userId?: string
}

const Comments = ({ comments, userId }: Props) => {
  return (
    <Flex stack gap={20}>
      {comments.map((comment) => (
        <Flex
          flexDirection={comment.by.id === userId ? 'row' : 'row-reverse'}
          gap={12}
          key={comment.id}
        >
          <div
            className="w-full overflow-x-auto px-3 pt-3 pb-2.5 rounded-xl"
            // "2e" is 18% opacity.
            style={{
              backgroundColor:
                comment.by.id === userId
                  ? theme.color.grey75
                  : getColor(comment.by.id) + '2e',
            }}
          >
            <Flex stack gap={12}>
              <div
                className={styles.comment}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(comment.text),
                }}
              />
              <Text variant="s" className="text-grey-600 mb-1.5">
                {friendlyDateTime(
                  comment.createdAt,
                  'EEEE MMM d, y @ h:mm aaa'
                )}
              </Text>
            </Flex>
          </div>

          <Tooltip content={comment.by.name}>
            <Avatar id={comment.by.id} name={comment.by.name} />
          </Tooltip>
        </Flex>
      ))}
    </Flex>
  )
}

export { Comments }
