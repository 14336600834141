import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  isLocked?: boolean
  isDownloadable: boolean
  onUpload?: () => void
  onDownload: () => void
}

export function NoPreview({
  isLocked = true,
  isDownloadable,
  onUpload,
  onDownload,
}: Props) {
  return (
    <div className={styles.noPreview}>
      <Icon name={IconName.noPreview} size="xxxl" />
      {isDownloadable ? (
        <Header className={styles.noPreviewHeader}>
          Unable to display.
          <br />
          <a className="link" onClick={() => onDownload()}>
            Click here
          </a>{' '}
          to download.
        </Header>
      ) : (
        <Header className={styles.noPreviewHeader}>
          No file has been uploaded yet.
          <br />
          {!isLocked && onUpload && (
            <>
              <a className="link" onClick={() => onUpload()}>
                Click here
              </a>{' '}
              to upload.{' '}
            </>
          )}
        </Header>
      )}
    </div>
  )
}
