import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo, pathToLoan } from 'admin/path-to'
import { Table } from 'components/Table'
import { Address, Insurance, Sort } from 'types'
import { formatAddress } from 'utils/address'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  data?: Insurance[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableInsuranceExpiration({ data = [], sort, onSort, loading }: Props) {
  const columns: ColumnDef<Insurance>[] = useMemo(
    () => [
      {
        id: 'loan',
        header: 'Loan',
        cell: ({ row }) => {
          const loan = row.original.address?.loans[0]
          return loan ? (
            <NavLink to={pathToLoan(loan)} className="link">
              {loan.name}
            </NavLink>
          ) : (
            '-'
          )
        },
      },
      {
        id: 'borrower',
        header: 'Borrower',
        cell: ({ row }) => {
          const loan = row.original.address?.loans[0]
          const borrowers = loan?.borrowers || []
          return borrowers.length
            ? borrowers.map(({ id, name }) => (
                <NavLink key={id} to={pathTo('borrower', id)} className="link">
                  {name}
                </NavLink>
              ))
            : '-'
        },
      },
      {
        id: 'address',
        header: 'Address',
        cell: ({ row }) =>
          formatAddress(row.original.address as unknown as Address),
      },
      {
        header: 'Insurance Type',
        accessorKey: 'type',
        size: 120,
      },
      {
        header: 'Premium',
        accessorKey: 'premium',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        size: 130,
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Expiration Date',
        accessorKey: 'expiration',
        cell: ({ getValue }) => formatDate(getValue() as string),
        size: 130,
        meta: {
          sortable: true,
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableInsuranceExpiration
