import clsx from 'clsx'
import Color from 'color'
import { getAcronym, getColor } from 'components/Avatar/Avatar'
import { Flex } from 'components/Flex'
import { Tooltip } from 'components/Tooltip'
import { Phase, PhaseRecipient } from 'types'
import { formatPercent } from 'utils/percent'

interface Props {
  recipients: PhaseRecipient[]
  defaultRecipient: Phase['defaultRecipient']
  assignedPercent: number
  percent: number
}

const SpreadBar = ({
  recipients,
  defaultRecipient,
  assignedPercent,
  percent,
}: Props) => {
  const maxPercent = Math.max(assignedPercent, percent)
  const isFull = defaultRecipient ? true : assignedPercent >= percent
  const defaultRecipientPercent = maxPercent - assignedPercent
  const defaultRecipientBg = getColor(defaultRecipient?.id)
  const defaultRecipientColor =
    Color(defaultRecipientBg).luminosity() > 0.9 ? '#000' : '#fff'

  return (
    <Flex gap={0} className="h-9 rounded shadow-200 bg-grey-100">
      {recipients.map((recipient) => {
        const background = getColor(recipient.id)
        const color = Color(background).luminosity() > 0.9 ? '#000' : '#fff'
        return (
          <Tooltip
            key={recipient.id}
            content={
              <Flex stack gap={4}>
                <div className="font-bold">{recipient.name}</div>
                <div className="text-center">
                  {formatPercent(recipient.percentage, { maxDecimals: 3 })}
                </div>
              </Flex>
            }
          >
            <div
              key={recipient.id}
              className={clsx(
                'flex items-center justify-center font-bold h-full border-0 border-r border-white-100 border-solid truncate',
                'first:rounded-l last:rounded-r last:border-r-0'
              )}
              style={{
                background,
                color,
                width: `${(recipient.percentage / maxPercent) * 100}%`,
              }}
            >
              {getAcronym(recipient.name)}
            </div>
          </Tooltip>
        )
      })}
      {defaultRecipient && defaultRecipientPercent > 0 && (
        <Tooltip
          key={defaultRecipient.id}
          content={
            <Flex stack gap={4}>
              <div className="font-bold">{defaultRecipient.name}</div>
              <div className="text-center">
                {formatPercent(defaultRecipientPercent, { maxDecimals: 3 })}
              </div>
            </Flex>
          }
        >
          <div
            key={defaultRecipient.id}
            className={clsx(
              'flex items-center justify-center font-bold h-full border-0 border-r border-white-100 border-solid truncate',
              'first:rounded-l last:rounded-r last:border-r-0'
            )}
            style={{
              background: defaultRecipientBg,
              color: defaultRecipientColor,
              width: `${(defaultRecipientPercent / maxPercent) * 100}%`,
            }}
          >
            {getAcronym(defaultRecipient.name)}
          </div>
        </Tooltip>
      )}
      {!isFull && (
        <div
          className="flex items-center justify-center text-grey-700 font-bold h-full"
          style={{
            width: `${((percent - assignedPercent) / maxPercent) * 100}%`,
          }}
        >
          {formatPercent(percent - assignedPercent, {
            showZero: true,
            maxDecimals: 3,
          })}
        </div>
      )}
    </Flex>
  )
}

export { SpreadBar }
