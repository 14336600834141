import clsx from 'clsx'
import { useDrop } from 'react-dnd'
import { BodyLoader } from 'components/Table/BodyLoader'
import { Empty } from 'components/Table/Empty'
import styles from 'components/Table/styles.module.scss'
import { PersonField } from './PersonField'

interface Props {
  fields: any[]
  loading?: boolean
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onDrop: (item: any, target: any | null) => void
  onSelect: (field: any, value: number) => void
}

function TablePersonFields({
  fields,
  loading,
  onEdit,
  onDelete,
  onDrop,
  onSelect,
}: Props) {
  const [, drop] = useDrop(
    () => ({
      accept: ['item'],
      drop: (item) => {
        onDrop(item, null)
      },
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop]
  )

  return (
    <div className={styles.tableWrapper}>
      <table className="w-full box-border border-collapse">
        <thead>
          <tr className={clsx(styles.th, 'text-grey-900 text-left')}>
            <th
              className={clsx(
                styles.cell,
                'border-grey-100 border-solid border-0 border-b'
              )}
            >
              Fields
            </th>
            <th
              className={clsx(
                styles.cell,
                'border-grey-100 border-solid border-0 border-b'
              )}
            >
              Use For
            </th>
            <th
              className={clsx(
                styles.cell,
                'border-grey-100 border-solid border-0 border-b'
              )}
            ></th>
          </tr>
        </thead>
        <tbody ref={drop}>
          {loading && <BodyLoader colSpan={3} />}
          {!fields.length && !loading && (
            <tr>
              <td colSpan={3}>
                <Empty hadData />
              </td>
            </tr>
          )}
          {!loading &&
            fields.map((field) => (
              <PersonField
                key={field.id}
                field={field}
                onEdit={onEdit}
                onDelete={onDelete}
                onDrop={onDrop}
                onSelect={onSelect}
              />
            ))}
        </tbody>
      </table>
    </div>
  )
}
export { TablePersonFields }
