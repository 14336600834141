import clsx from 'clsx'
import { cloneDeep } from 'lodash'
import { useState, useMemo, useCallback } from 'react'
import {
  useAddLoanModificationDocument,
  useUpdateLoanModification,
  useDeleteLoanModification,
} from 'admin/hooks/use-loan-modification'
import { StepFields } from 'admin/pages/Loan/ModificationModal/StepFields'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalFullSize } from 'components/ModalFullSize'
import { LoanModification } from 'types'
import { ConfirmTermsAndPayment } from './ConfirmTermsAndPayment'
import { PaymentDetails } from './PaymentDetails'

interface Props {
  loanId: string
  modifications: LoanModification[]
  modification: LoanModification
  onClose: () => void
}

const ModificationModal = ({
  loanId,
  modifications,
  modification: initialModification,
  onClose,
}: Props) => {
  const [step, setStep] = useState('fields')
  const [modification, setModification] = useState(
    cloneDeep(initialModification)
  )
  const [template, setTemplate] = useState<string | undefined>(
    modification.templateId
  )
  const [generate, setGenerate] = useState(false)
  const { mutateAsync: updateModification, isPending: isSaving } =
    useUpdateLoanModification({
      loanId: loanId,
    })
  const { mutate: deleteModification } = useDeleteLoanModification(loanId)
  const { mutateAsync: generateDocument, isPending: isGenerating } =
    useAddLoanModificationDocument(loanId)
  const fieldsLabel = useMemo(() => {
    if (initialModification.isOrigination) {
      return 'Edit Origination Details'
    }
    if (initialModification.isNew) {
      return 'Modify Loan'
    }
    return 'Edit Loan Modification'
  }, [initialModification])
  const steps = useMemo(
    () => [
      { id: 'fields', label: fieldsLabel },
      { id: 'payment', label: 'Review Updated Payment' },
      { id: 'confirm', label: 'Confirm Terms' },
    ],
    [fieldsLabel]
  )
  const previousModification = useMemo(() => {
    const index = modifications.findIndex(
      (m) => m.id === initialModification.id
    )
    return index >= 0 ? modifications[index + 1] : undefined
  }, [modifications, initialModification])
  const nextModification = useMemo(() => {
    const index = modifications.findIndex(
      (m) => m.id === initialModification.id
    )
    return index >= 0 ? modifications[index - 1] : undefined
  }, [modifications, initialModification])

  const onCloseOrDelete = useCallback(() => {
    if (initialModification.isNew) {
      deleteModification(initialModification.id)
    }
    onClose()
  }, [initialModification, deleteModification, onClose])

  const handleNext = useCallback(async () => {
    if (step === 'fields') {
      const updatedModification = await updateModification(modification)
      setModification(updatedModification)
      setStep('payment')
    } else if (step === 'payment') {
      const updatedModification = await updateModification(modification)
      setModification(updatedModification)
      setStep('confirm')
    } else if (step === 'confirm') {
      if (generate) {
        await updateModification({ ...modification, templateId: template })
        await generateDocument(modification.id)
      }
      onClose()
    }
  }, [
    step,
    onClose,
    generate,
    template,
    updateModification,
    generateDocument,
    modification,
  ])
  const handleBack = useCallback(() => {
    if (step === 'fields') {
      onCloseOrDelete()
    } else if (step === 'payment') {
      setStep('fields')
    } else if (step === 'confirm') {
      setStep('payment')
    }
  }, [step, onCloseOrDelete])
  const handleStepClick = useCallback(
    async (nextStep: string) => {
      const updatedModification = await updateModification(modification)
      setModification(updatedModification)
      setStep(nextStep)
    },
    [updateModification, modification]
  )

  return (
    <ModalFullSize onClose={onCloseOrDelete} className="px-[90px] relative">
      {isSaving && (
        <div className="absolute inset-0 bg-white-100 opacity-50">
          <PageLoader />
        </div>
      )}
      <Flex>
        <Flex
          stack
          gap={24}
          className="h-full border-solid border-0 border-l-[1px] border-grey-200"
        >
          {steps.map(({ id, label }) => (
            <div
              key={id}
              onClick={() => handleStepClick(id)}
              className={clsx(
                id === step
                  ? 'pl-[13px] border-solid border-0 border-l-[3px] border-blue-100'
                  : '',
                'min-w-56 py-1 pl-4 leading-[20px] font-bold cursor-pointer'
              )}
            >
              {label}
            </div>
          ))}
        </Flex>
        <Flex stack gap={20} className="w-full">
          <div className="text-xl font-bold text-grey-600">
            {modification.isOrigination && 'Edit Origination Details'}
            {!modification.isOrigination &&
              initialModification.isNew &&
              'New Loan Modification'}
            {!modification.isOrigination &&
              !initialModification.isNew &&
              'Edit Loan Modification'}
          </div>
          <Flex stack gap={32}>
            <Header variant="h2" className="leading-8">
              {
                {
                  fields: modification.isOrigination
                    ? 'Origination Details'
                    : 'Loan Modification Details',
                  payment: 'Payment Details',
                  confirm: 'Confirm Terms and Payment',
                }[step]
              }
            </Header>
            {step === 'fields' && (
              <StepFields
                modification={modification}
                previousModification={previousModification}
                nextModification={nextModification}
                onChange={setModification}
              />
            )}
            {step === 'payment' && (
              <PaymentDetails
                modification={modification}
                onChange={setModification}
              />
            )}
            {step === 'confirm' && (
              <ConfirmTermsAndPayment
                previousModification={previousModification}
                modification={modification}
                template={template}
                generate={generate}
                onTemplateChange={setTemplate}
                onGenerateChange={setGenerate}
              />
            )}
            <Flex justifyContent="flex-end" gap={10}>
              <Button
                variant="secondary"
                onClick={handleBack}
                loading={isSaving || isGenerating}
              >
                {step === 'fields' ? 'Cancel' : 'Back'}
              </Button>
              <Button
                variant="primary"
                onClick={handleNext}
                loading={isSaving || isGenerating}
              >
                {step === 'confirm' ? 'Save' : 'Next'}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </ModalFullSize>
  )
}

export { ModificationModal }
