import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  updateLoanAddress,
  updateLoanAddressField,
  LoanAddress,
  deleteLoanAddress,
  addLoanAddress,
} from 'admin/services/api/loan-address'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Field } from 'types'
import { message } from 'utils/message'

const useUpdateLoanAddress = ({
  id,
  silent,
  onSuccess,
}: {
  id: string
  silent?: boolean
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (address: Partial<LoanAddress>) =>
      updateLoanAddress(id, address),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      !silent && message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanAddress = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (address: LoanAddress) => addLoanAddress(id, address),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      message.success('Loan updated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanAddress = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => deleteLoanAddress(loanId, addressId),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanAddressField = ({
  id,
  addressId,
}: {
  id: string
  addressId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (field: Field) => updateLoanAddressField(id, addressId, field),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
    },
    onError: handleErrorResponse,
  })
}

export {
  useUpdateLoanAddress,
  useUpdateLoanAddressField,
  useDeleteLoanAddress,
  useAddLoanAddress,
}
