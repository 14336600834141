import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { TableDocuments } from 'components/TableDocuments'
import { useUploadDocument } from 'hooks/use-document'
import { useInvestorDocuments } from 'hooks/use-investor-documents'
import { openBrowseFile } from 'utils/file'

interface Props {
  investorId: string
  isInvestor?: boolean
}

export function TabDocuments({ investorId, isInvestor }: Props) {
  const navigate = useNavigate()
  const { data: documents, isPending } = useInvestorDocuments({
    id: investorId,
    isBorrower: !isInvestor,
  })
  const { mutate: uploadDocument } = useUploadDocument()
  const handleUpload = (id: string) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument({ id, name: doc?.name, file: files[0] })
      },
    })
  }
  const handleDropRow = (id: string, files: FileList) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    uploadDocument({ id, name: doc?.name, file: files[0] })
  }
  const handleView = (id: string) => {
    navigate(pathTo('investorDocument', id))
  }

  return (
    <TableDocuments
      minWidth={750}
      disabled={false}
      hideEllipsesMenu
      data={documents}
      onSign={handleView}
      onUpload={handleUpload}
      onFileDrop={handleDropRow}
      onView={handleView}
      loading={isPending}
    />
  )
}
