import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { OfferingStatusBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Offering, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

function TableOfferings({
  sort,
  onSort,
  data = [],
  loading,
  onClick,
  onDelete,
}: {
  data?: Offering[]
  loading: boolean
  onClick: (offering?: Offering) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  onDelete: (offering: Offering) => void
}) {
  const columns: ColumnDef<Offering>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Offered',
        accessorKey: 'dateIssue',
        cell: ({ getValue }) => formatDate(getValue() as string) || '-',
        meta: {
          sortable: true,
        },
      },

      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          sortable: true,
          align: 'right',
        },
      },
      {
        header: 'Type',
        accessorKey: 'type',
        cell: ({ getValue }) => (getValue() as string) || '-',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ getValue }) => (
          <OfferingStatusBadge status={getValue() as Offering['status']} />
        ),
        meta: {
          sortable: true,
        },
        size: 130,
      },
      {
        id: 'actions',
        header: '',
        size: 40,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [onDelete]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableOfferings
