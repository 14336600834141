import { isArray, without } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  useQuoteOptions,
  useQuoteOption,
  useUpdateQuoteOption,
} from 'admin/hooks/use-quote-options'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalQuoteOption } from 'components/Modal/QuoteOption'
import { Tabs } from 'components/Tabs'
import { Quote, QuoteOption as QuoteOptionType } from 'types'
import { message } from 'utils/message'
import { BrokerCompensationPanel } from './BrokerCompensationPanel'
import { NoOptions } from './NoOptions'
import { NoOptionsSelected } from './NoOptionsSelected'
import { QuoteInfoPanel } from './QuoteInfoPanel'
import { QuoteOption } from './QuoteOption'

interface Props {
  quote: Quote
  generatingInProgress: boolean
  onGoToStep: (step: string) => void
  onBack: () => void
}

export const LoanOptions = ({
  quote,
  generatingInProgress,
  onGoToStep,
  onBack,
}: Props) => {
  const [tab, setTab] = useState('all')
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [editingOption, setEditingOption] = useState<QuoteOptionType>()
  const { data: options, isPending } = useQuoteOptions(
    quote.id,
    generatingInProgress ? 5000 : undefined
  )
  const { mutate: downloadSizer } = useQuoteOption(quote.id)
  const { mutate: update, isPending: saving } = useUpdateQuoteOption(quote.id)
  const handleOptionClick = useCallback(
    (optionId) => {
      let nextOptionIds: string[] = []
      if (selectedOptions.includes(optionId)) {
        update({ optionId, isSelected: false })
        nextOptionIds = without(selectedOptions, optionId)
        without(selectedOptions, optionId)
      } else {
        update({ optionId, isSelected: true })
        nextOptionIds = [...selectedOptions, optionId]
      }
      setSelectedOptions(
        (options || [])
          .filter(({ id }) => nextOptionIds.includes(id))
          .map(({ id }) => id)
      )
    },
    [selectedOptions]
  )

  const handleOptionDownload = (optionId: string) => {
    downloadSizer(optionId, {
      onSuccess: (option) => {
        if (option.runUrl) {
          window.location.href = option.runUrl
        } else {
          message.error(
            'The run history of that sizer is no longer available (expires after 1 day).'
          )
        }
      },
    })
  }

  const handleOptionReset = useCallback((optionId) => {
    update({ optionId, reset: true })
  }, [])

  useEffect(() => {
    if (isArray(options) && !selectedOptions.length) {
      setSelectedOptions(
        options.filter(({ isSelected }) => isSelected).map(({ id }) => id)
      )
    }
  }, [options])

  return isPending ? (
    <PageLoader />
  ) : (
    <>
      <Grid gap={24}>
        <Grid.Item xs={4}>
          <Flex stack gap={8}>
            <QuoteInfoPanel quote={quote} onBackClick={onBack} />
            <BrokerCompensationPanel quote={quote} />
          </Flex>
        </Grid.Item>
        <Grid.Item xs={8}>
          <Flex stack gap={8}>
            <Header variant="h3">Loan Products</Header>
            <Tabs tab={tab} onTabSelect={setTab}>
              <Tabs.Pane tab="All" id="all">
                <Flex stack gap={8}>
                  {options?.map((option) => (
                    <QuoteOption
                      key={option.id}
                      option={option}
                      selected={selectedOptions.includes(option.id)}
                      resetting={saving}
                      onEdit={(option) => setEditingOption(option)}
                      onClick={() => handleOptionClick(option.id)}
                      onDownload={(option) => handleOptionDownload(option.id)}
                      onReset={() => handleOptionReset(option.id)}
                    />
                  ))}
                  {!options?.length && (
                    <NoOptions onClick={() => onGoToStep('Type')} />
                  )}
                </Flex>
              </Tabs.Pane>
              <Tabs.Pane
                tab={`Selected${
                  selectedOptions.length ? ` (${selectedOptions.length})` : ''
                }`}
                id="selected"
              >
                <Flex stack gap={8}>
                  {selectedOptions.map((optionId) => (
                    <QuoteOption
                      key={optionId}
                      option={
                        options?.find(
                          ({ id }) => id === optionId
                        ) as QuoteOptionType
                      }
                      resetting={saving}
                      onEdit={(option) => setEditingOption(option)}
                      onDownload={(option) => handleOptionDownload(option.id)}
                      onUnselect={() => handleOptionClick(optionId)}
                      onReset={() => handleOptionReset(optionId)}
                    />
                  ))}
                </Flex>

                {!selectedOptions.length && (
                  <NoOptionsSelected onClick={() => setTab('all')} />
                )}
              </Tabs.Pane>
            </Tabs>
          </Flex>
        </Grid.Item>
      </Grid>
      {editingOption && (
        <ModalQuoteOption
          option={editingOption}
          saving={saving}
          onSave={(values) => {
            update(
              { optionId: editingOption.id, ...values },
              {
                onSuccess: () => setEditingOption(undefined),
              }
            )
          }}
          onCancel={() => setEditingOption(undefined)}
        />
      )}
    </>
  )
}
