import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Field, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { CustomReport } from 'types'
import { createScheme, required } from 'utils/schemas'

const Schema = createScheme({
  name: required,
})

type FormValues = {
  name: string
  description: string
}

interface Props {
  report?: CustomReport
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalReport({ report, saving, onSave, onCancel }: Props) {
  const isEditing = !!report
  const initialValue: FormValues = {
    name: report?.name || '',
    description: report?.description || '',
  }

  return (
    <Modal title={isEditing ? 'Edit Report' : 'New Report'} onClose={onCancel}>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Name"
                name="name"
                placeholder="A name for your report"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                label="Description"
                name="description"
                placeholder="A longer description for your report"
                maxLength={250}
                counter
              />
            </Grid.Item>
            <Grid.Item xs={12} className="flex gap-4 justify-end">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalReport }
