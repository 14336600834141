const isEmpty = (editorContent: string) => {
  const htmlObject = document.createElement('div')
  htmlObject.innerHTML = editorContent
  return htmlObject.innerText.trim().length === 0
}

const clearHTML = (editorContent: string) => {
  const htmlObject = document.createElement('div')
  htmlObject.innerHTML = editorContent

  function clean(el: HTMLElement): HTMLElement {
    if (el.tagName?.toLowerCase() === 'p' && el.innerText === '') {
      el.parentNode?.removeChild(el)
    }
    ;(el.childNodes as unknown as HTMLElement[]).forEach(clean)
    return el
  }
  return clean(htmlObject).innerHTML
}

const htmlToTemplate = (editorContent: string) => {
  const htmlObject = document.createElement('div')
  htmlObject.innerHTML = editorContent

  const spans = htmlObject.querySelectorAll(
    'span[data-lexical-variable]'
  ) as NodeListOf<HTMLSpanElement>
  spans.forEach((span) => {
    const textNode = document.createTextNode(`{{${span.innerText}}}`)
    span.parentNode?.replaceChild(textNode, span)
  })

  return htmlObject.innerText
}

const templateToHtml = (template: string) => {
  const htmlObject = document.createElement('div')
  htmlObject.innerHTML = template.replace(
    /\{\{['"]?(.*?)['"]?}}/gi,
    '<span data-lexical-variable="$1">$1</span>'
  )
  return `<div>${htmlObject.innerHTML}</div>`
}

export { isEmpty, clearHTML, htmlToTemplate, templateToHtml }
