import { UseMutationResult } from '@tanstack/react-query'
import { useState } from 'react'
import { TableLinkedAccounts } from 'admin/components/table/TableLinkedAccounts'
import styles from 'admin/pages/Borrower/styles.module.scss'
import { BorrowerDetails, Borrower } from 'admin/services/api/borrowers'
import { Investor, InvestorDetails } from 'admin/services/api/investors'
import { Vendor, VendorDetails } from 'admin/services/api/vendors'
import { Icon, IconName } from 'components/Icon'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalLinkAccount } from 'components/Modal/LinkAccount'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { PersonRole, PersonTitle } from 'types/person'

interface Props {
  person: BorrowerDetails | InvestorDetails | VendorDetails
  availableAccounts: (Borrower | Investor | Vendor)[]
  showTitle?: boolean
  addPerson: UseMutationResult<any, any, any, any>
  addLink: UseMutationResult<any, any, any, any>
  updateLink: UseMutationResult<any, any, any, any>
  removeLink: UseMutationResult<any, any, any, any>
  type?: 'borrower' | 'investor' | 'vendor'
}

function PanelLinkedAccounts({
  person,
  availableAccounts,
  showTitle = true,
  addPerson,
  addLink,
  updateLink,
  removeLink,
  type = 'borrower',
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [personName, setPersonName] = useState<string>()
  const [addingPerson, setAddingPerson] = useState(false)
  const [newAddedPersonId, setNewAddedPersonId] = useState<string>()

  const linkAccounts =
    (person[person.type === 'individual' ? 'managing' : 'managers'] || []).map(
      (link) => ({
        ...link,
        personType: type,
      })
    ) || []

  const onChangeRole = (id: string, role: PersonRole) =>
    updateLink.mutate({
      individualId: person.type === 'individual' ? person.id : id,
      entityId: person.type === 'individual' ? id : person.id,
      role,
    })

  const onChangeTitle = (id: string, title: PersonTitle) =>
    updateLink.mutate({
      individualId: person.type === 'individual' ? person.id : id,
      entityId: person.type === 'individual' ? id : person.id,
      title,
    })

  const onDelete = (id: string) => setRemovingId(id)

  return (
    <Panel
      title={
        person.type === 'individual' ? 'Associated Companies' : 'Team Members'
      }
    >
      <TableLinkedAccounts
        data={linkAccounts}
        showTitle={showTitle}
        onChangeRole={onChangeRole}
        onChangeTitle={onChangeTitle}
        onDelete={onDelete}
      />
      <div className={styles.link}>
        <TextLink onClick={() => setIsModalVisible(true)}>
          <Icon name={IconName.plus} size="sm" />
          {person.type === 'individual' ? 'Add to Company' : 'Add Person'}
        </TextLink>
      </div>

      {isModalVisible && (
        <ModalLinkAccount
          borrower={person}
          availableAccounts={availableAccounts}
          saving={addLink.isPending}
          showTitle={showTitle}
          onCreateNewPerson={(personName: string) => {
            setPersonName(personName)
            setIsModalVisible(false)
            setAddingPerson(true)
          }}
          newAddedPersonId={newAddedPersonId}
          onSave={(data) =>
            addLink.mutate(
              {
                individualId:
                  person.type === 'individual' ? data.id : data.linkId,
                entityId: person.type === 'individual' ? data.linkId : data.id,
                title: data.title,
                role: data.role,
              },
              {
                onSettled: () => setIsModalVisible(false),
              }
            )
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
      {removingId && (
        <ModalDelete
          resource={person.type === 'individual' ? 'Company' : 'Person'}
          name={linkAccounts?.find((vendor) => vendor.id === removingId)?.name}
          loading={removeLink.isPending}
          onDelete={() =>
            removeLink.mutate(
              {
                individualId:
                  person.type === 'individual' ? person.id : removingId,
                entityId: person.type === 'individual' ? removingId : person.id,
              },
              {
                onSettled: () => {
                  setRemovingId(null)
                },
              }
            )
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
      {addingPerson ? (
        <ModalAddPerson
          saving={addLink.isPending}
          type={person.type === 'individual' ? 'entity' : 'individual'}
          include={['sendInvitation']}
          personName={personName}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            addPerson.mutate(borrower, {
              onSuccess: (person) => {
                setNewAddedPersonId(person.id)
                setAddingPerson(false)
                setIsModalVisible(true)
              },
            })
          }
          onCancel={() => {
            setAddingPerson(false)
            setIsModalVisible(true)
          }}
        />
      ) : null}
    </Panel>
  )
}

export { PanelLinkedAccounts }
