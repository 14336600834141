import {
  useDeleteImportEntities,
  useCloseImportEntities,
} from 'admin/hooks/use-import'
import { Alert } from 'components/Alert'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'

interface Props {
  alertData: string | null | boolean
  setAlertData: (val: boolean) => void
}

export const AlertPayments = ({ alertData, setAlertData }: Props) => {
  const { mutate: deleteImportEntities } = useDeleteImportEntities('payment')
  const closeImport = useCloseImportEntities('payment')

  return (
    <Alert
      onClose={() => {
        closeImport()
        setAlertData(false)
      }}
    >
      <Flex className="w-full" justifyContent="space-between">
        <Text className="text-base font-bold">
          {typeof alertData === 'string'
            ? `Payments were imported on ${alertData}`
            : 'Importing payments. Refresh to see new data.'}
        </Text>
        <TextLink
          className="font-bold hover:text-grey-700"
          onClick={() =>
            deleteImportEntities(undefined, {
              onSuccess: () => setAlertData(false),
            })
          }
        >
          Undo
        </TextLink>
      </Flex>
    </Alert>
  )
}
