import { request } from 'services/request'
import { Payment, Filter, Meta, Pagination, Search } from 'types'

const TO_BORROWER_PAYMENT_TYPE = 'To Borrower'

const getPayments = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
  full?: boolean
}): Promise<{ payments: Payment[]; meta: Meta }> => {
  const {
    data: { payments, pagination },
  } = await request.get(`/payment`, { params })
  return { payments, meta: pagination }
}

export { getPayments, TO_BORROWER_PAYMENT_TYPE }
