import { Row } from '@tanstack/react-table'
import clsx from 'clsx'
import {
  useCallback,
  useState,
  useRef,
  useEffect,
  DragEvent,
  DragEventHandler,
} from 'react'
import { DragDropFile } from 'components/DragDropFile'
import { BodyRowCell } from 'components/Table/BodyRowCell'
import styles from 'components/Table/styles.module.scss'

interface Props<TData> {
  row: Row<TData>
  onClick?: (row: any) => void
  onDragEnter?: (rowId: string, e: DragEvent) => void
  updateRowDropId: (rowId?: string) => void
  rowDropId?: string
  onFileDrop?: (row: any, files: FileList) => void
}

const BodyRowComponent = <TData,>({
  row,
  onClick,
  onDragEnter,
  rowDropId,
  updateRowDropId,
  onFileDrop,
}: Props<TData>) => {
  const mouseMoveRef = useRef<HTMLTableRowElement>(null)
  const [active, setActive] = useState(false)
  const [hover, setHover] = useState(false)
  const handleMouseEnter = useCallback(() => {
    setHover(true)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setHover(false)
  }, [])
  const handleClick = useCallback(() => {
    onClick?.(row.original)
  }, [row.original, onClick])
  const handleDrag = useCallback(
    (e: DragEvent) => {
      onDragEnter?.(row.id, e)
    },
    [row.id, onDragEnter]
  )
  const handleDragLeave = useCallback(() => {
    updateRowDropId(undefined)
  }, [updateRowDropId])
  const handleFileDrop = useCallback(
    (files: FileList) => {
      updateRowDropId(undefined)
      onFileDrop?.(row.original, files)
    },
    [onFileDrop, updateRowDropId, row.original]
  )
  const handleActive = useCallback((nextActive: boolean) => {
    setActive(nextActive)
  }, [])

  useEffect(() => {
    const checkHover = (e) => {
      const mouseOver = mouseMoveRef.current?.contains(e.target)
      if (hover && !mouseOver) {
        setHover(false)
      }
    }
    document.addEventListener('mousemove', checkHover)
    return () => document.removeEventListener('mousemove', checkHover)
  }, [hover])

  return (
    <tr
      id={(row.original as any)?.id}
      ref={mouseMoveRef}
      className={clsx({ [styles.clickable]: !!onClick })}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onDragEnter={
        handleDrag as unknown as DragEventHandler<HTMLTableRowElement>
      }
    >
      {onFileDrop && rowDropId === row.id && (
        <td
          className={clsx(
            styles.td,
            styles.droppable,
            (row.original as any)?.rowClassName
          )}
          colSpan={row.getVisibleCells().length}
        >
          <DragDropFile onLeave={handleDragLeave} onDrop={handleFileDrop}>
            <div className={styles.dropZone}>
              {(row.original as any)?.name || 'Drag and drop to upload file'}
            </div>
          </DragDropFile>
        </td>
      )}
      {rowDropId !== row.id &&
        row
          .getVisibleCells()
          .map((cell) => (
            <BodyRowCell
              key={cell.id}
              cell={cell}
              rowClassName={(row.original as any)?.rowClassName}
              onActive={handleActive}
              hover={hover || active}
            />
          ))}
    </tr>
  )
}

export const BodyRow = BodyRowComponent
