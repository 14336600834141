import { Formik } from 'formik'
import { omitBy } from 'lodash'
import { useMemo } from 'react'
import * as yup from 'yup'
import { useFunds } from 'admin/hooks/use-funds'
import { Button } from 'components/Button'
import { Form, Field, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Offering } from 'types'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
  type: required,
  fundId: yup.string().when('type', {
    is: 'Fund Offering',
    then: () => required,
  }),
})

type FormValues = {
  name: string
  type: Offering['type']
  fundId: string
}

interface Props {
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalAddOffering({ saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    name: '',
    type: '' as Offering['type'],
    fundId: '',
  }

  const { data: funds, isFetching } = useFunds()
  const fundsOptions = useMemo(
    () =>
      funds?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) || [],
    [funds]
  )

  const handleSave = (values: FormValues) => {
    onSave(omitBy(values, (value) => !value) as FormValues)
  }

  return (
    <Modal
      title="Add a New Offering"
      onClose={onCancel}
      className={styles.modal}
      loading={isFetching}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={handleSave}
        validateOnBlur={false}
      >
        {({ values }) => (
          <Form>
            <Grid className={styles.form} columnGap={16}>
              <Grid.Item xs={12}>
                <Select
                  label="Offering Type"
                  type="select"
                  name="type"
                  portal
                  options={[
                    { value: 'Loan Offering', label: 'Loan Offering' },
                    { value: 'Fund Offering', label: 'Fund Offering' },
                  ]}
                />
              </Grid.Item>
              {values.type === 'Fund Offering' && (
                <Grid.Item xs={12}>
                  <Select
                    label="Fund"
                    type="select"
                    name="fundId"
                    placeholder="Select the fund"
                    portal
                    options={fundsOptions}
                  />
                </Grid.Item>
              )}
              <Grid.Item xs={12}>
                <Field
                  label="Public Offering Name"
                  type="name"
                  name="name"
                  placeholder="Enter a name for this offering"
                  maxLength={100}
                  counter
                />
              </Grid.Item>
              <Grid.Item xs={12} className={styles.buttons}>
                <Button variant="tertiary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button loading={saving} type="submit">
                  Save
                </Button>
              </Grid.Item>
            </Grid>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default ModalAddOffering
