import { useState } from 'react'
import {
  useUploadFundDistribution,
  useDownloadFundDistribution,
} from 'admin/hooks/use-fund-distribution'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalConfirm } from 'components/Modal/Confirm'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { openBrowseFile } from 'utils/file'

interface Props {
  fundId: string
  distributionId: string
  isSimpleInterest?: boolean
  onImport: () => void
}

function ImportDistribution({
  fundId,
  distributionId,
  onImport,
  isSimpleInterest = false,
}: Props) {
  const [selectedFile, setSelectedFile] = useState<File>()

  const { mutate: uploadTemplate } = useUploadFundDistribution(
    fundId,
    distributionId,
    onImport
  )
  const { mutate: downloadTemplate } = useDownloadFundDistribution(
    fundId,
    distributionId
  )

  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => setSelectedFile(files[0]),
      accept: '.xlsx',
    })
  }

  return (
    <>
      {isSimpleInterest ? (
        <Flex gap={10}>
          <Button variant="tertiary" onClick={handleUpload}>
            Import
          </Button>
          <Button variant="tertiary" onClick={() => downloadTemplate()}>
            Export
          </Button>
        </Flex>
      ) : (
        <Flex
          className="mb-8 p-4 rounded-sm bg-blue-25"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text className="font-bold">
            Import a distribution by Excel or enter the data in the table below.
          </Text>
          <Flex className="mr-4" gap={24}>
            <TextLink onClick={() => downloadTemplate()}>
              <Icon name={IconName.download} size="sm" />
              Download Excel template
            </TextLink>
            <Button
              onClick={handleUpload}
              iconLeft={<Icon name={IconName.upload} size="sm" />}
            >
              Import
            </Button>
          </Flex>
        </Flex>
      )}
      {selectedFile && (
        <ModalConfirm
          title="Overwrite existing data?"
          text="Importing a new CSV file will overwrite any existing data entered in the table. Are you sure you want to continue?"
          color="negative"
          buttonText="Overwrite data"
          onConfirm={() => {
            uploadTemplate(selectedFile)
            setSelectedFile(undefined)
          }}
          onCancel={() => setSelectedFile(undefined)}
        />
      )}
    </>
  )
}

export { ImportDistribution }
