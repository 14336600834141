import clsx from 'clsx'
import { isNil, toString } from 'lodash'
import {
  ChangeEvent,
  ForwardedRef,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  useState,
  useRef,
  useEffect,
} from 'react'
import { IMaskInput } from 'react-imask'
import { mergeRefs } from 'react-merge-refs'
import styles from '../Form/styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  supportFormula?: boolean
  fullAccuracy?: boolean
  supportNegative?: boolean
  mode?: 'hover'
}

function round(value: string | number | readonly string[] | undefined) {
  if (isNil(value) || isNaN(parseFloat(value.toString()))) {
    return ''
  }
  return Math.round(parseFloat(value.toString()) * 1000) / 1000
}

const InputPercentage = forwardRef(
  (
    {
      className,
      value,
      onChange,
      onBlur,
      autoComplete = 'off',
      placeholder = '%',
      defaultValue,
      mode,
      fullAccuracy,
      supportFormula,
      supportNegative,
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const [inputValue, setInputValue] = useState(round(value ?? defaultValue))
    const imaskRef = useRef<any>(null)
    useEffect(() => {
      if (
        imaskRef.current &&
        imaskRef.current.maskRef.unmaskedValue !==
          toString(value ?? defaultValue)
      ) {
        imaskRef.current.element.value = toString(value ?? defaultValue)
        imaskRef.current.maskRef.updateValue()
        imaskRef.current.maskRef.updateControl()
      }
    }, [value])
    return (
      <IMaskInput
        ref={mergeRefs([imaskRef, ref])}
        mask={[
          {
            mask: '',
            ...(supportFormula ? { startsWith: '' } : {}),
          },
          {
            mask: 'num%',
            ...(supportFormula ? { startsWith: '' } : {}),
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                scale: fullAccuracy ? 10 : 3,
                lazy: false,
                padFractionalZeros: !fullAccuracy && mode === 'hover',
                thousandsSeparator: ',',
                radix: '.',
                ...(supportNegative ? { signed: true } : {}),
              },
            },
          },
        ]}
        unmask="typed"
        type="text"
        defaultValue={round(value ?? defaultValue)}
        className={clsx(styles.input, className)}
        autoComplete={autoComplete}
        onAccept={(value, mask, event) => {
          setInputValue(mask.unmaskedValue as string)
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = mask.unmaskedValue as string
            onChange &&
              onChange(event as unknown as ChangeEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        onBlur={(event) => {
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = inputValue as string
            onBlur && onBlur(event as unknown as FocusEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        placeholder={placeholder}
        {...rest}
      />
    )
  }
)
InputPercentage.displayName = 'InputPercentage'

export default InputPercentage
