import clsx from 'clsx'
import { without, get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useLenderOfRecords } from 'admin/hooks/use-lender-of-record'
import {
  useAddProduct,
  useEditProduct,
  useProduct,
} from 'admin/hooks/use-products'
import { pathTo } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Input } from 'components/Input'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import {
  loanTypes,
  transactionTypes as allTransactionTypes,
} from 'constants/loan-types'
import { useSession } from 'hooks/use-session'
import { Product } from 'types'
import { message } from 'utils/message'
import { Sizer } from '../Product/Sizer'
import styles from './styles.module.scss'

function ProductDetails() {
  const navigate = useNavigate()
  const { id } = useParams() as { id?: string }
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')
  const { data: lenders, isPending: lenderLoading } =
    useLenderOfRecords(clientId)
  const [product, setProduct] = useState<Product>()
  const [name, setName] = useState('')
  const [transactionTypes, setTransactionTypes] = useState<string[]>([])
  const [loanType, setLoanType] = useState('')
  const [lenderofrecordId, setLenderofrecordId] = useState<string | undefined>()
  const [isSharing, setIsSharing] = useState(true)
  const {
    mutate: add,
    mutateAsync: addAsync,
    isPending: adding,
  } = useAddProduct()
  const { mutate: edit, isPending: saving } = useEditProduct(
    id || (product?.id as string)
  )
  const { data: editableProduct, isPending } = useProduct({ id })

  const handleTransactionTypesClick = useCallback(
    (transactionType) => {
      if (transactionTypes.includes(transactionType)) {
        setTransactionTypes(without(transactionTypes, transactionType))
      } else {
        setTransactionTypes([...transactionTypes, transactionType])
      }
    },
    [transactionTypes]
  )
  const handleBack = useCallback(() => {
    const path = id ? pathTo('product', id) : pathTo('products')
    navigate(path)
  }, [id])

  const handleSubmit = useCallback(() => {
    const save = product ? edit : add
    if (name && transactionTypes.length && loanType) {
      save(
        {
          name,
          transactionTypes,
          loanType,
          lenderofrecordId,
          isSharing,
        },
        {
          onSuccess: (product) => {
            navigate(pathTo('product', product.id))
          },
        }
      )
    } else {
      message.error('Please fill out all fields')
    }
  }, [
    product,
    add,
    edit,
    name,
    transactionTypes,
    loanType,
    lenderofrecordId,
    isSharing,
  ])

  useEffect(() => {
    if (editableProduct) {
      setProduct(editableProduct?.product)
      setName(editableProduct.product.name)
      setTransactionTypes(editableProduct.product.transactionTypes || [])
      setLoanType(editableProduct.product.loanType || '')
      setLenderofrecordId(editableProduct.product.lenderofrecordId)
      setIsSharing(editableProduct.product.isSharing || false)
    }
  }, [editableProduct])

  return (
    <MainContent className="relative w-full min-w-0 pb-0">
      {id && (isPending || lenderLoading) ? (
        <PageLoader />
      ) : (
        <Flex stack gap={24} className={styles.content}>
          <Flex justifyContent="flex-start">
            <Breadcrumbs
              breadcrumbs={
                id
                  ? { title: 'Product', link: pathTo('product', id) }
                  : { title: 'Products', link: pathTo('products') }
              }
            />
          </Flex>

          <div className={styles.content}>
            <div className={styles.panel}>
              <Panel>
                <Flex stack gap={24}>
                  <Flex stack gap={8}>
                    <div>Name</div>
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Flex>
                  <Flex stack gap={16}>
                    <div className="font-bold">Use of Funds</div>
                    <Flex>
                      {allTransactionTypes.map((transactionType) => (
                        <Checkbox
                          className="items-center"
                          key={transactionType}
                          name="transactionType"
                          label={transactionType}
                          checked={transactionTypes.includes(transactionType)}
                          onChange={() =>
                            handleTransactionTypesClick(transactionType)
                          }
                        />
                      ))}
                    </Flex>
                  </Flex>
                  <Flex stack gap={16}>
                    <div className="font-bold">Loan</div>
                    <Flex flexWrap="wrap">
                      {loanTypes.map(({ name, description }) => (
                        <Flex
                          key={name}
                          stack
                          gap={8}
                          className={clsx(
                            styles.loanType,
                            name === loanType && styles.selected
                          )}
                          onClick={() => setLoanType(name)}
                        >
                          <div className="font-bold">{name}</div>
                          <div className={styles.loanTypeDescription}>
                            {description}
                          </div>
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>
                  <Flex stack gap={16}>
                    <div className="font-bold">Lender of Record</div>
                    <Select
                      name="lenderofrecordId"
                      options={
                        lenders?.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        })) || []
                      }
                      value={lenderofrecordId}
                      onChange={(option) =>
                        setLenderofrecordId(option.value as string)
                      }
                    />
                  </Flex>
                  <Flex stack gap={16}>
                    <div className="font-bold">Sharing</div>
                    <Flex
                      justifyContent="space-between"
                      alignItems="center"
                      className={styles.box}
                    >
                      Share this product with affiliated businesses
                      <Toggle
                        checked={isSharing}
                        onChange={(e) => setIsSharing(e.target.checked)}
                      />
                    </Flex>
                  </Flex>
                  <Sizer
                    product={product}
                    onInteract={async () => {
                      const newProduct = await addAsync({ name })
                      setProduct(newProduct)
                      return newProduct
                    }}
                  />
                </Flex>
              </Panel>
            </div>
          </div>

          <Flex justifyContent="space-between" className={styles.footer}>
            <Button onClick={() => handleBack()} variant="tertiary">
              Cancel
            </Button>

            <Button loading={adding || saving} onClick={() => handleSubmit()}>
              {id ? 'Save' : 'Next'}
            </Button>
          </Flex>
        </Flex>
      )}
    </MainContent>
  )
}

export { ProductDetails }
