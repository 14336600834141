import { capitalize } from 'lodash'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: 'success' | 'failed'
}

const statusToColor: Record<
  Props['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  success: 'green',
  failed: 'red',
}

function AutomationStatusBadge({ status }: Props) {
  return <Badge color={statusToColor[status]}>{capitalize(status)}</Badge>
}

export { AutomationStatusBadge }
