import { useState } from 'react'
import { useUsers } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { Role } from 'types'
import { SelectUser } from './SelectUser'

interface Props {
  role?: Role
  saving: boolean
  onSubmit: (role: Partial<Role>) => void
  onCancel: () => void
}

function ModalRole({ role, saving, onSubmit, onCancel }: Props) {
  const { user } = useSession()
  const [name, setName] = useState(role?.name || '')
  const [admins, setAdmins] = useState(role?.admins?.map(({ id }) => id) || [])
  const { data, isLoading } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  return (
    <Modal
      loading={isLoading}
      title={role ? 'Edit Role' : 'New Role'}
      onClose={onCancel}
    >
      <Flex stack gap={32}>
        <Flex stack gap={16}>
          <Flex stack gap={6}>
            <div className="text-grey-800">Role Name</div>
            <Input
              type="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
            />
          </Flex>
          <Flex stack gap={6}>
            <div className="text-grey-800">Assigned to</div>
            <SelectUser
              options={
                data?.map((user) => ({
                  value: user.id,
                  label: user.name,
                  email: user.email,
                })) || []
              }
              onChange={(value) => setAdmins(value)}
              value={admins}
            />
            <div className="text-grey-700">
              Choose which teammates should have this role assigned to them
            </div>
          </Flex>
        </Flex>

        <Flex justifyContent="flex-end" alignItems="center" gap={16}>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            loading={saving}
            disabled={!name}
            onClick={() =>
              onSubmit({ name, admins: admins as unknown as Role['admins'] })
            }
            type="submit"
          >
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalRole }
