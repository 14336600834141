import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableQuotes } from 'admin/components/table/TableQuotes'
import {
  useQuotes,
  useAddQuote,
  useUpdateQuote,
  useDeleteQuote,
} from 'admin/hooks/use-quotes'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalAddQuote } from 'components/Modal/AddQuote'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { loanTypes, transactionTypes } from 'constants/loan-types'
import { usePagination } from 'hooks/use-pagination'
import { Quote } from 'types'
import { message } from 'utils/message'
import { EmptyQuotes } from './EmptyQuotes'

const Quotes = () => {
  const { tab = 'all' } = useParams() as { tab: string }
  const {
    visibleItems,
    result,
    sort,
    setSort,
    search,
    setSearch,
    setPagination,
    isEmpty,
  } = usePagination<Quote>({
    property: 'quotes',
    useData: (params) =>
      useQuotes({
        ...params,
        filter:
          tab === 'archived'
            ? { status: ['Archived'] }
            : { status: ['Draft', 'Sent', 'Converted'] },
      }),
  })
  const { data: allQuotes } = useQuotes({
    pagination: { page: 0, size: 1 },
  })

  const [isFetchingVisible, setIsFetchingVisible] = useState(false)

  const [adding, setAdding] = useState(false)
  const [deletingQuote, setDeletingQuote] = useState<Quote>()
  const navigate = useNavigate()
  const { mutate: add, isPending: isAdding } = useAddQuote()
  const { mutate: update } = useUpdateQuote()
  const { mutate: remove, isPending: isRemove } = useDeleteQuote()

  const handleRowClick = (quote?: Quote) => {
    if (quote?.id) {
      navigate(pathTo('quote', tab || 'all', quote.id))
    }
  }

  useEffect(() => {
    setIsFetchingVisible(true)
  }, [tab])

  useEffect(() => {
    if (!result.isFetching) {
      setIsFetchingVisible(false)
    }
  }, [result.isFetching])

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Quotes" />
        {isEmpty && allQuotes?.meta.total === 0 ? (
          <EmptyQuotes onClick={() => setAdding(true)} />
        ) : (
          <Panel>
            <Flex
              gap={4}
              justifyContent="space-between"
              flexWrap="wrap"
              className="pb-4"
            >
              <Flex gap={8}>
                {[
                  { id: 'all', title: 'All' },
                  { id: 'archived', title: 'Archived' },
                ].map(({ id, title }) => (
                  <Button
                    key={id}
                    active={tab === id}
                    variant="panel"
                    onClick={() => navigate(pathTo('quotesTab', id))}
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <Flex gap={8}>
                <Search search={search} onSearch={setSearch} />
                <Button variant="primary" onClick={() => setAdding(true)}>
                  Add Quote
                </Button>
              </Flex>
            </Flex>
            <TableQuotes
              data={isFetchingVisible ? [] : visibleItems}
              loading={
                result.isPending || (isFetchingVisible && result.isFetching)
              }
              sort={sort}
              onSort={setSort}
              onClick={handleRowClick}
              onDelete={(quote) => setDeletingQuote(quote)}
              onChangeStatus={(quote) =>
                update(
                  {
                    id: quote.id,
                    status: quote.status === 'Archived' ? 'Draft' : 'Archived',
                  },
                  {
                    onSuccess: () => {
                      message.success('Quote updated')
                    },
                  }
                )
              }
            />
            <LoadMore
              loading={
                result.isPending || (isFetchingVisible && result.isFetching)
              }
              fetching={result.isFetching}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>
      {adding ? (
        <ModalAddQuote
          saving={isAdding}
          onSave={(quote) => {
            add(
              {
                ...quote,
                loanType: loanTypes[0].name as Quote['loanType'],
                transactionType:
                  transactionTypes[0] as Quote['transactionType'],
              },
              {
                onSuccess: ({ id }) => {
                  setAdding(false)
                  navigate(pathTo('quote', tab || 'all', id))
                },
              }
            )
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
      {deletingQuote && (
        <ModalDelete
          resource="quote"
          loading={isRemove}
          onDelete={() =>
            remove(deletingQuote.id, {
              onSuccess: () => setDeletingQuote(undefined),
            })
          }
          onCancel={() => setDeletingQuote(undefined)}
        />
      )}
    </MainContent>
  )
}

export { Quotes }
