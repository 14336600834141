import { capitalize } from 'lodash'
import { useCallback } from 'react'
import {
  useUpdateApplication,
  useDeleteApplication,
  useDeleteDraftApplication,
} from 'borrower/hooks/use-application'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Application } from 'types'
import { getApplicationStatus, bgColor, textColor } from './utils'

const iconName = {
  draft: IconName.draft,
  submitted: IconName.send,
  processing: IconName.watch,
  approved: IconName.approvedCircle,
}

interface Props {
  application: Application
  isEditable: boolean
}

export const ApplicationTileHeader = ({ application, isEditable }: Props) => {
  const { mutate: deleteApplication } = useDeleteApplication()
  const { mutate: archiveDraft } = useDeleteDraftApplication({
    isArchived: true,
  })
  const { mutate: deleteDraftApplication } = useDeleteDraftApplication()
  const { mutate: archiveSubmitted } = useUpdateApplication({
    id: application.id,
    isArchived: true,
  })
  const { mutate: update } = useUpdateApplication({
    id: application.id,
    dontSetQueryData: true,
  })

  const handleArchive = useCallback(() => {
    application.isDraft
      ? archiveDraft(application.id)
      : archiveSubmitted({ status: 'archived' })
  }, [application])

  const handleDeleteApplication = useCallback(() => {
    application.isDraft
      ? deleteDraftApplication(application.id)
      : deleteApplication(application.id)
  }, [application])

  const applicationStatus = getApplicationStatus(
    application.status,
    application.isDraft
  )

  return (
    <Flex
      gap={8}
      alignItems="center"
      justifyContent="space-between"
      className={`pt-1 pb-0.5 px-5 rounded-t-lg ${bgColor[applicationStatus]}`}
    >
      <Flex gap={8} alignItems="center">
        <Icon
          name={iconName[applicationStatus]}
          className={textColor[applicationStatus]}
        />
        <div className={`font-bold ${textColor[applicationStatus]}`}>
          {capitalize(applicationStatus)}
        </div>
      </Flex>
      <EllipsesActions>
        <EllipsesActions.Item
          icon
          disabled={application.isDraft || !isEditable}
          onSelect={() => update({ status: 'draft' })}
        >
          <Icon name={IconName.edit} />
          Withdraw
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          disabled={!isEditable}
          onSelect={handleArchive}
        >
          <Icon name={IconName.upload} />
          Archive
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          disabled={!isEditable}
          onSelect={handleDeleteApplication}
          className="text-red-100"
        >
          <Icon name={IconName.delete} />
          Delete
        </EllipsesActions.Item>
      </EllipsesActions>
    </Flex>
  )
}
