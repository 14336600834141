import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { VariableEditor } from 'admin/components/InlineWysiwyg/VariableEditor'
import { FormulaToolbarPlugin } from 'admin/components/InlineWysiwyg/plugins/FormulaToolbarPlugin'
import {
  isEmpty,
  clearHTML,
  htmlToTemplate,
  templateToHtml,
} from 'admin/components/InlineWysiwyg/utils/helpers'
import { Flex } from 'components/Flex'
import type { LexicalEditor } from 'lexical'

interface Props {
  singleLine?: boolean
  error?: string
  formula?: string
  variables?: string[]
  formattedValue: string
  onFormulaChange: (formula: string) => void
  onClose?: () => void
}

const Formula = ({
  singleLine,
  error,
  formula,
  variables,
  formattedValue,
  onFormulaChange,
  onClose,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [editor, setEditor] = useState<LexicalEditor | null>(null)
  const [isFocused, setIsFocused] = useState(true)
  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false)
    }, 200)
  }

  useEffect(() => {
    if (!isFocused && !isOpen) {
      onClose?.()
    }
  }, [isFocused, isOpen])

  return (
    <Flex gap={8} className="relative" alignItems="center">
      <Flex
        gap={8}
        className={clsx(
          'relative flex-grow border border-solid rounded',
          error && 'border-peach-100',
          !error && (isFocused || isOpen) && 'border-lime-100',
          !error && !isFocused && !isOpen && 'border-grey-200',
          singleLine && 'overflow-hidden'
        )}
        onKeyDown={(e) => {
          if (e.key === '@') {
            setIsOpen(true)
            e.stopPropagation()
            e.preventDefault()
          }
          if (e.key === 'Enter') {
            onClose?.()
          }
        }}
      >
        <VariableEditor
          className={clsx(
            '!min-h-[18px] !py-1 !pl-2 !pr-0',
            singleLine && 'overflow-auto scrollbar-hide !whitespace-nowrap'
          )}
          value={templateToHtml(formula || '=')}
          singleLine
          onBlur={handleBlur}
          onChange={(formula) => {
            if (isEmpty(clearHTML(formula))) {
              onFormulaChange('')
            } else {
              onFormulaChange(htmlToTemplate(formula))
            }
          }}
          onFocus={(editor) => {
            setEditor(editor)
            setIsFocused(true)
          }}
        />
        {error && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className="rounded bg-peach-25 text-peach-100 px-1.5 h-6 m-1 mt-[5px] flex-shrink-0"
          >
            {error}
          </Flex>
        )}
        {!error && formattedValue && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className="rounded bg-grey-100 px-1.5 h-6 m-1 mt-[5px] flex-shrink-0"
          >
            {formattedValue}
          </Flex>
        )}
      </Flex>
      <FormulaToolbarPlugin
        open={isOpen}
        onOpenChange={setIsOpen}
        variables={
          variables?.map((name) => ({
            label: name,
            value: name,
          })) || []
        }
        editor={editor as LexicalEditor}
      />
    </Flex>
  )
}

export { Formula }
