import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApplicationScheme } from 'admin/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import {
  readPreviewApplication,
  deletePreviewApplication,
} from 'services/storage'
import { PreviewApplication } from './PreviewApplication'
import styles from './styles.module.scss'

function PreviewApplicationPage() {
  const navigate = useNavigate()
  const { user } = useSession()
  const { data: client } = useClient()
  useEffect(() => {
    if (user?.actor?.isBorrower) {
      navigate(pathTo('applications'))
    }
  }, [user])
  const savedApplication = readPreviewApplication()
  const { data: defaultScheme } = useApplicationScheme(
    { clientId: client?.id },
    { enabled: !savedApplication && !!client?.id }
  )
  const scheme = savedApplication || defaultScheme

  const handleClose = useCallback(() => {
    deletePreviewApplication()
    window.close()
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', deletePreviewApplication)
    return () => {
      window.removeEventListener('beforeunload', deletePreviewApplication)
    }
  }, [])

  return (
    <Flex stack gap={0} className="flex-grow h-full">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="z-2 sticky top-0 border-0 border-b border-solid border-grey-200 bg-white-100 px-12 py-4"
      >
        <Header variant="h2" className="text-4xl">
          <div
            onClick={handleClose}
            className="mr-1 relative top-[1px] float-left cursor-pointer"
          >
            <Icon name={IconName.arrowLeftLong} />
          </div>
          Application Preview
        </Header>
        <Button variant="secondary" onClick={handleClose}>
          Close preview
        </Button>
      </Flex>
      <div className={styles.content}>
        {scheme && client?.id ? (
          <PreviewApplication scheme={scheme} />
        ) : (
          <PageLoader />
        )}
      </div>
    </Flex>
  )
}

export { PreviewApplicationPage }
