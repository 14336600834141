import { omit } from 'lodash'
import { LoanField, Field } from 'types/field'
import { orderFields } from '../fields'
import { updateResponseValue, updateRequestValue } from './field-parser'

const updateResponseFields = (fields: LoanField[]): Field[] => {
  return orderFields(
    fields.map(
      ({ loanProperty, ...rest }) =>
        ({
          ...rest,
          property: {
            ...loanProperty,
            value: updateResponseValue(loanProperty.value, rest.type),
          },
        }) as Field
    )
  )
}

const updateResponseLoan = (loan) => {
  return {
    ...loan,
    rate: loan.rate ? loan.rate * 100 : loan.rate,
    spreadRate: loan.spreadRate ? loan.spreadRate * 100 : loan.spreadRate,
    fields: updateResponseFields(loan.fields),
    addresses: loan.addresses.map((address) => ({
      ...address,
      fields: updateResponseFields(address.fields),
    })),
  }
}

const updateRequestFields = (fields: Field[]): LoanField[] => {
  return fields.map(({ property, ...field }) => ({
    ...omit(field, ['sectionServicing', 'sectionOrigination']),
    loanProperty: {
      ...property,
      value: updateRequestValue(property.value, field.type),
    },
  }))
}

export { updateResponseFields, updateResponseLoan, updateRequestFields }
