import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { User } from 'admin/services/api/users'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

type Props = {
  data?: User[]
  loading?: boolean
  onChangePermission: (id: string, permission: User['permission']) => void
  onEdit: (id: string) => void
  onReinvite: (email: string) => void
  onDelete: (id: string) => void
}

function TableMembers({
  data = [],
  loading,
  onChangePermission,
  onEdit,
  onReinvite,
  onDelete,
}: Props) {
  const { user } = useSession()

  const columns: ColumnDef<User>[] = useMemo(
    () => [
      {
        header: 'User',
        accessorKey: 'name',
        cell: ({ getValue, row }) => (
          <Flex gap={8} alignItems="center">
            <Avatar name={getValue() as string} id={row.original?.id} />
            <div>{getValue() as string}</div>
          </Flex>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'login.email',
      },
      {
        header: 'Roles',
        accessorKey: 'roles',
        size: 204,
        cell: ({ row }) => {
          return <AssociatedBadges associatedWith={row.original.roles || []} />
        },
      },
      {
        header: 'Access Level',
        accessorKey: 'permission',
        size: 100,
        cell: ({ getValue, cell }) => {
          const permission = (getValue() as string) || 'member'
          const { id } = cell.row.original

          return (
            <EllipsesActions
              className={styles.roleMenu}
              trigger={
                <div className={styles.role}>
                  {capitalize(permission)} <Icon name={IconName.arrowDown} />
                </div>
              }
            >
              <EllipsesActions.Item
                onSelect={() => onChangePermission(id, 'admin')}
              >
                <div className={styles.roleItem}>
                  <div className={styles.roleTitle}>
                    <Icon name={IconName.manager} />
                    Admin
                  </div>
                  {permission === 'admin' && (
                    <Icon name={IconName.check} className={styles.tick} />
                  )}
                </div>
              </EllipsesActions.Item>
              <EllipsesActions.Item
                onSelect={() => onChangePermission(id, 'member')}
              >
                <div className={styles.roleItem}>
                  <div className={styles.roleTitle}>
                    <Icon name={IconName.viewer} />
                    Member
                  </div>
                  {permission === 'member' && (
                    <Icon name={IconName.check} className={styles.tick} />
                  )}
                </div>
              </EllipsesActions.Item>
              <EllipsesActions.Item
                onSelect={() => onChangePermission(id, 'individual')}
              >
                <div className={styles.roleItem}>
                  <div className={styles.roleTitle}>
                    <Icon name={IconName.viewer} />
                    Individual
                  </div>
                  {permission === 'individual' && (
                    <Icon name={IconName.check} className={styles.tick} />
                  )}
                </div>
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        accessorKey: 'isActive',
        size: 90,
        cell({ getValue, row }) {
          const isActive =
            (getValue() as boolean) ||
            row.original.login?.status === 'active' ||
            false

          return (
            <Badge color={isActive ? 'green' : 'blue'}>
              {isActive ? 'Active' : 'Invited'}
            </Badge>
          )
        },
      },
      {
        header: '',
        accessorKey: 'actions',
        size: 40,
        cell: ({ cell }) => {
          const { id, email } = cell.row.original
          return (
            <div className={styles.rightAction}>
              <EllipsesActions>
                <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                  <Icon name={IconName.edit} />
                  Edit
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onReinvite(email)}
                  disabled={user?.person?.email === email}
                >
                  <Icon name={IconName.send} />
                  Re-invite
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onDelete(id)}
                  variant="danger"
                >
                  <Icon name={IconName.delete} />
                  Remove
                </EllipsesActions.Item>
              </EllipsesActions>
            </div>
          )
        },
        meta: {
          align: 'right',
        },
      },
    ],
    [onEdit, onReinvite, onDelete, onChangePermission, user]
  )

  return (
    <Table
      columns={columns}
      data={data}
      onClick={(user) => onEdit(user?.id as string)}
      loading={loading}
    />
  )
}

export { TableMembers }
