import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { ApplicationSchemePage } from 'types'
import { createScheme, required } from 'utils/schemas'

const Schema = createScheme({
  tag: required,
  header: required,
})

type FormValues = {
  tag: string
  header: string
  description: string
}

interface Props {
  page?: ApplicationSchemePage
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalCustomApplicationPage({ page, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    tag: page?.tag || '',
    header: page?.header || '',
    description: page?.description || '',
  }

  return (
    <Modal title="Page Properties" onClose={onCancel} className="w-modal">
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid className="mt-4" columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Page Tag"
                type="text"
                name="tag"
                maxLength="20"
                counter
                bottomHint="A one or two word label for the page used for navigation"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                label="Page Header"
                type="text"
                name="header"
                maxLength="70"
                counter
                bottomHint="Enter a headline to go at the top of the page"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                label="Page Description (Optional)"
                type="text"
                name="description"
                maxLength="100"
                counter
                bottomHint="A short description below the header"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Flex justifyContent="flex-end" alignItems="center">
                <Button variant="tertiary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </Flex>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalCustomApplicationPage }
