import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { $isHeadingNode, $createQuoteNode } from '@lexical/rich-text'
import { $setBlocksType } from '@lexical/selection'
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils'
import clsx from 'clsx'
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $createParagraphNode,
  FORMAT_TEXT_COMMAND,
} from 'lexical'
import { useCallback, useEffect, useState } from 'react'
import { Icon, IconName } from 'components/Icon'
import { Option } from 'components/Select/Select'
import styles from '../styles.module.scss'
import DropDown, { DropDownItem } from '../ui/Dropdown'
import { getSelectedNode } from '../utils/getSelectedNode'
import { INSERT_VARIABLE_COMMAND } from './VariablePlugin'
import type { LexicalEditor } from 'lexical'

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
}

function dropDownActiveClass(active: boolean) {
  return active ? styles.dropdownItemActive : ''
}

function VariablesDropDown({
  editor,
  variables,
}: {
  variables: Option[]
  editor: LexicalEditor
}): JSX.Element {
  const insertVariable = (value: string) => {
    editor.dispatchCommand(INSERT_VARIABLE_COMMAND, {
      variable: value,
    })
  }

  return (
    <DropDown
      buttonClassName={clsx(styles.toolbarItem, styles.blockControl)}
      buttonIcon={<Icon name={IconName.mergeField} size="md" />}
      buttonLabel="Merge Field"
      buttonAriaLabel="Formatting options for text style"
    >
      {variables.map(({ value, label }) => (
        <DropDownItem
          key={value as string}
          className={clsx(styles.dropdownItem, dropDownActiveClass(false))}
          onClick={() => insertVariable(value as string)}
        >
          <span className={styles.dropdownItemText}>{label}</span>
        </DropDownItem>
      ))}
    </DropDown>
  )
}

function VariableToolbarPlugin({
  editor,
  variables,
  disabled,
}: {
  editor: LexicalEditor
  variables: Option[]
  disabled?: boolean
}) {
  const [isBold, setIsBold] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>('paragraph')

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      // Update text format
      setIsBold(selection.hasFormat('bold'))

      // Update links
      const node = getSelectedNode(selection)
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType<ListNode>(
            anchorNode,
            ListNode
          )
          const type = parentList
            ? parentList.getListType()
            : element.getListType()
          setBlockType(type as keyof typeof blockTypeToBlockName)
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type as keyof typeof blockTypeToBlockName)
          }
        }
      }
    }
  }, [editor])

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://')
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink])

  const formatBulletList = useCallback(() => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }, [editor, blockType])

  const formatNumberedList = useCallback(() => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }, [editor, blockType])

  const formatQuote = useCallback(() => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection()
        $setBlocksType(selection, () => $createQuoteNode())
      })
    } else {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode())
        }
      })
    }
  }, [editor, blockType])

  useEffect(() => {
    if (editor) {
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar()
          })
        })
      )
    }
  }, [editor, updateToolbar])

  return (
    <div
      className={clsx(styles.toolbar)}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div className={styles.toolbarGroup}>
        <button
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
          }}
          className={clsx(styles.toolbarItem, isBold && styles.active)}
          aria-label="Format Bold"
          disabled={disabled}
        >
          <Icon name={IconName.textBold} size="md" />
        </button>
        <button
          className={clsx(
            styles.toolbarItem,
            dropDownActiveClass(blockType === 'quote')
          )}
          onClick={formatQuote}
          disabled={disabled}
        >
          <Icon name={IconName.blockquote} size="md" />
        </button>
        <button
          className={clsx(
            styles.toolbarItem,
            dropDownActiveClass(blockType === 'bullet')
          )}
          onClick={formatBulletList}
          disabled={disabled}
        >
          <Icon name={IconName.listUl} size="md" />
        </button>
        <button
          className={clsx(
            styles.toolbarItem,
            dropDownActiveClass(blockType === 'number')
          )}
          onClick={formatNumberedList}
          disabled={disabled}
        >
          <Icon name={IconName.listOl} size="md" />
        </button>
        <button
          onClick={insertLink}
          className={clsx(styles.toolbarItem, isLink && styles.active)}
          aria-label="Insert Link"
          disabled={disabled}
        >
          <Icon name={IconName.link} size="md" />
        </button>

        {variables.length > 0 && (
          <VariablesDropDown variables={variables} editor={editor} />
        )}
      </div>
    </div>
  )
}

export { VariableToolbarPlugin }
