import { kebabCase } from 'lodash'
import {
  ICalculatedCharge,
  ICalculatedFundingSource,
  ICalculatedInvestment,
  ICalculatedTrust,
  IDistribution,
  IPaymentInfo,
  ISource,
  SubmitPaymentData,
} from 'components/Modal/Payment/types'
import { request } from 'services/request'
import { Payment } from 'types'

const getLoanPayment = async (
  loanId: string,
  paymentId: string,
  showDetails = true
): Promise<Payment> => {
  const {
    data: { payment },
  } = await request.get(
    `/loan/${loanId}/payment/${paymentId}?show_details=${showDetails}`
  )
  return payment
}

const editLoanPayment = async (
  loanId: string,
  paymentId: string,
  data: Partial<Payment>
): Promise<Payment> => {
  const {
    data: { payment },
  } = await request.patch(`/loan/${loanId}/payment/${paymentId}`, data)
  return payment
}

const deleteLoanPayment = async (
  loanId: string,
  paymentId: string
): Promise<void> => {
  return request.delete(`/loan/${loanId}/payment/${paymentId}`)
}

const getLoanPaymentCalculate = async ({
  loanId,
  budgetDrawId,
  type,
  amount,
  principalAmount,
  interestAmount,
  paymentType,
  context,
  dateReceived,
  selectedFundingSources,
}: {
  loanId: string
  budgetDrawId?: string
  type:
    | 'interest'
    | 'principal'
    | 'charges'
    | 'trust'
    | 'principal_interest'
    | 'regular_payment'
    | 'payoff'
    | 'to_borrower'
    | 'investments'
    | 'funding'
  amount?: number
  principalAmount?: number
  interestAmount?: number
  // trust props
  paymentType?: IPaymentInfo['type']
  context?: 'source' | 'distribution'
  dateReceived?: string
  selectedFundingSources?: string[]
}): Promise<
  | ICalculatedFundingSource[]
  | ICalculatedCharge[]
  | ICalculatedTrust[]
  | { source: Partial<ISource>; distribution: Partial<IDistribution> }
  | ICalculatedInvestment[]
> => {
  const {
    data: {
      fundingSources,
      trusts,
      charges,
      source,
      distribution,
      investments,
    },
  } = await request.post(`/loan/${loanId}/calculate/${type}`, {
    amount,
    budgetDrawId,
    principalAmount,
    interestAmount,
    paymentType: paymentType ? kebabCase(paymentType) : undefined,
    context,
    dateReceived,
    selectedFundingSources,
  })
  if (type === 'investments') {
    return investments
  }
  if (type === 'trust') {
    return trusts
  }
  if (type === 'charges') {
    return charges
  }
  if (['to_borrower', 'regular_payment', 'payoff', 'funding'].includes(type)) {
    return { source, distribution }
  }
  return fundingSources
}

const submitLoanPayment = async (
  loanId: string,
  payload: SubmitPaymentData
): Promise<Payment> => {
  const {
    data: { payment },
  } = await request.post(`/loan/${loanId}/payment`, payload)
  return payment
}

export {
  getLoanPayment,
  editLoanPayment,
  deleteLoanPayment,
  getLoanPaymentCalculate,
  submitLoanPayment,
}
