import { useCallback, useState } from 'react'
import { useEditVendor } from 'admin/hooks/use-vendors'
import ModalInformation from 'admin/pages/Vendor/ModalInformation'
import { Vendor } from 'admin/services/api/vendors'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { formatPhone } from 'utils/phone'

function PanelPersonalInformation({ vendor }: { vendor: Vendor }) {
  const id = vendor.id
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const edit = useEditVendor()

  const onEdit = useCallback(() => {
    setIsInfoModalVisible(true)
  }, [isInfoModalVisible])

  const isIndividual = !vendor.type || vendor.type === 'individual'

  return (
    <>
      <Panel title="Information" onEdit={onEdit}>
        <Grid gap={24}>
          <Grid.Item sm={6}>
            <Summary name={isIndividual ? 'Full Name' : 'Company Name'}>
              {vendor.name}
            </Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Role">{vendor.role}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Phone">{formatPhone(vendor.phone)}</Summary>
          </Grid.Item>
        </Grid>
      </Panel>
      {isInfoModalVisible && (
        <ModalInformation
          vendor={vendor}
          onSave={(vendor: Partial<Vendor>) => {
            edit.mutate(
              { ...vendor, id: id },
              {
                onSettled: () => {
                  setIsInfoModalVisible(false)
                },
              }
            )
          }}
          onCancel={() => {
            setIsInfoModalVisible(false)
          }}
        />
      )}
    </>
  )
}
export default PanelPersonalInformation
