import { cloneDeep, without, isArray, isNil, isNumber, get } from 'lodash'
import { Field } from 'types/field'
import { formatPhone } from 'utils/phone'
import { formatUsd } from './currency'
import { formatDate } from './date'
import { formatNumber, formatFloat } from './number'
import { formatPercent } from './percent'

const getFieldsBySection = (
  fields: Field[] = [],
  sectionName: string,
  context: 'origination' | 'servicing' = 'origination'
): Field[] => {
  return cloneDeep(
    orderFields(
      fields.filter(({ sectionOrigination, sectionServicing }) =>
        context === 'servicing'
          ? sectionServicing === sectionName
          : sectionOrigination === sectionName
      )
    )
  )
}

const getFieldsByApplicationPage = (fields: Field[] = [], page: string) => {
  return cloneDeep(
    orderFields(fields.filter((field) => field.applicationPage === page))
  )
}

const fieldsToInitialValues = (fields: Field[]) => {
  return fields.reduce(
    (acc, { id, property }) => ({
      ...acc,
      [id]: isNil(property?.value.slice(-1)[0])
        ? ''
        : property?.value.slice(-1)[0],
    }),
    {}
  ) as Record<string, string>
}

const valuesToFields = (
  fields: Field[],
  values: Record<string, string | string[]>
) => {
  return Object.keys(values).reduce((acc, key) => {
    const field = fields.find(({ id }) => id === key) as Field
    const nextValue = isArray(values[key])
      ? values[key]
      : [...field.property.value.slice(0, -1), values[key]]
    return [
      ...without(acc, field),
      (field
        ? {
            ...field,
            property: { ...field.property, value: nextValue },
          }
        : {}) as Field,
    ]
  }, fields)
}

const getField = (
  fields: Field[] = [],
  fieldId: string,
  allowNull?: boolean
): Field | null => {
  const field = fields.find(({ id }) => id === fieldId) as Field
  if (!field && !allowNull) {
    throw `Field with id ${fieldId} not found`
  } else if (!field) {
    return null
  }
  return { ...field }
}

const getFieldValue = (
  fields: Field[] = [],
  fieldId: string,
  index = 0,
  allowNull?: boolean
) => {
  const field = getField(fields, fieldId, allowNull)
  return get(field, `property.value[${index}]`, null)
}

const mergeFields = <T extends { id?: string }>(
  fields: T[],
  updatedFields: T[]
): T[] => {
  const mergedFields = fields.map((field) => {
    const updatedField = updatedFields.find((f) => f?.id === field.id)
    return updatedField || field
  })
  return [...mergedFields, ...updatedFields.filter(({ id }) => !id)]
}

const formatField = (field: Field, index = 0): string => {
  const type = field.type[index]
  const value = field.property.value[index]
  if (!isNil(value)) {
    switch (type) {
      case 'currency':
        return formatUsd(isNumber(value) ? value : parseFloat(value || '0'), {
          showZero: value === 0 || value === '0',
        })
      case 'percentage':
        return formatPercent(
          isNumber(value) ? value : parseFloat(value || '0'),
          {
            showZero: value === 0 || value === '0',
            maxDecimals: field.fullAccuracy ? 10 : undefined,
          }
        )
      case 'decimal':
        return formatFloat(isNumber(value) ? value : parseFloat(value || '0'), {
          showZero: value === 0 || value === '0',
        })
      case 'number': {
        if (field.fullAccuracy) {
          return formatFloat(
            isNumber(value) ? value : parseFloat(value || '0'),
            {
              showZero: value === 0 || value === '0',
            }
          )
        }
        const mask = field.mask || '#'
        const formattedValue = formatNumber(
          isNumber(value) ? value : parseFloat(value || '0'),
          { showZero: value === 0 || value === '0' }
        )
        return formattedValue === '-'
          ? formattedValue
          : mask.replace('#', formattedValue)
      }
      case 'date':
        return formatDate(value as string) || '-'
      case 'phone':
        return formatPhone(value as string) || '-'
      default:
        return value as string
    }
  }
  return '-'
}

const orderFields = (fields: Field[]) => {
  return fields.sort((a, b) => {
    const aOrder = a.property?.order ?? a.order
    const bOrder = b.property?.order ?? b.order
    if (aOrder === bOrder) {
      return a.id > b.id ? 1 : -1
    }
    return aOrder > bOrder ? 1 : -1
  })
}

export {
  getField,
  getFieldValue,
  getFieldsBySection,
  getFieldsByApplicationPage,
  fieldsToInitialValues,
  valuesToFields,
  mergeFields,
  formatField,
  orderFields,
}
