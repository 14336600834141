import { MainContent } from 'borrower/components/layout/MainContent'
import { Applications } from './Applications'
import { Loans } from './Loans'

export const BorrowerDashboard = () => {
  return (
    <MainContent>
      <Applications />
      <Loans />
    </MainContent>
  )
}
