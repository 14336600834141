export const APPLICATION_HOWTO_HIDDEN = 'application-howto-hidden'
export const BORROWER_IMPORT_BANNER = 'borrower-import-banner'
export const INVESTOR_IMPORT_BANNER = 'investor-import-banner'
export const VENDOR_IMPORT_BANNER = 'vendor-import-banner'
export const LOAN_IMPORT_BANNER = 'loan-import-banner'
export const PAYMENT_IMPORT_BANNER = 'payment-import-banner'
export const FUNDING_IMPORT_BANNER = 'funding-import-banner'
export const CHARGE_IMPORT_BANNER = 'charge-import-banner'
export const INVESTOR_TRANSACTION_IMPORT_BANNER =
  'investor-transaction-import-banner'
export const VENDOR_TRANSACTION_IMPORT_BANNER =
  'vendor-transaction-import-banner'
