import { useCallback, useState, ReactElement } from 'react'
import { generateXlsx } from 'admin/services/csv/generate-xlsx'
import { Button, ButtonProps } from 'components/Button'
import { message } from 'utils/message'

interface Props {
  filename: string
  btnName?: string
  variant?: ButtonProps['variant']
  iconLeft?: ReactElement
  download: () => Promise<Record<string, string | number | undefined | null>[]>
}

function Download({
  filename,
  btnName = 'Export',
  variant = 'tertiary',
  iconLeft,
  download,
}: Props) {
  const [downloading, setDownloading] = useState(false)

  const handleDownloadClick = useCallback(async () => {
    setDownloading(true)
    const rows = await download()
    setDownloading(false)
    if (rows.length) {
      await generateXlsx(filename, rows)
    } else {
      message.error('Sorry, there were no rows to download')
    }
  }, [filename, download])

  return (
    <Button
      onClick={handleDownloadClick}
      variant={variant}
      loading={downloading}
      iconLeft={iconLeft}
    >
      {btnName}
    </Button>
  )
}

export default Download
