import { request } from 'services/request'
import {
  Address,
  Filter,
  Fund,
  FundPortfolio,
  LoanFundingSource,
  Meta,
  Pagination,
  Search,
  Sort,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'
import { formatPercent } from 'utils/math'

const getFunds = async (): Promise<Fund[]> => {
  const {
    data: { funds },
  } = await request.get('/fund')
  return funds
}

const getFund = async (
  id
): Promise<{ fund: Fund; fundingSources: LoanFundingSource[] | undefined }> => {
  const {
    data: { fund, fundingSources },
  } = await request.get(`/fund/${id}`)
  return {
    fund,
    fundingSources: fundingSources?.map((fundingSource: LoanFundingSource) => ({
      ...fundingSource,
      rate: formatPercent(fundingSource.rate),
    })),
  }
}

const getFundPortfolio = async (
  id: string,
  params: {
    search?: Search
    filter?: Filter
    page?: Pagination
    sort?: Sort
  }
): Promise<{ portfolio: FundPortfolio[]; meta: Meta }> => {
  const {
    data: { portfolio, pagination },
  } = await request.get(`/fund/${id}/portfolio`, {
    params: updateRequestTable(params),
  })
  return { portfolio, meta: pagination }
}

const addFund = async (payload: Partial<Fund>): Promise<Fund> => {
  const {
    data: { fund },
  } = await request.post('/fund', payload)
  return fund
}

const updateFund = async (
  id: string,
  payload: Partial<Fund>
): Promise<Fund> => {
  const {
    data: { fund },
  } = await request.patch(`/fund/${id}`, payload)
  return fund
}

const addFundAddress = (fundId: string, address: Address) => {
  return request.post(`/fund/${fundId}/address`, address)
}

const updateFundAddress = (
  fundId: string,
  addressId: string,
  address: Address
) => {
  return request.patch(`/fund/${fundId}/address/${addressId}`, address)
}

const deleteFund = (id: string) => {
  return request.delete(`/fund/${id}`)
}

export {
  getFunds,
  getFund,
  getFundPortfolio,
  addFund,
  updateFund,
  addFundAddress,
  updateFundAddress,
  deleteFund,
}
