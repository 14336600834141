import { useState } from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'
import { SwitcherProvider } from 'borrower/components/Switcher/SwitcherContext'
import { routerPath, pathTo } from 'borrower/path-to'
import { MainLayout } from 'components/layout'
import { useSession } from 'hooks/use-session'
import { Footer } from '../Footer'
import { SideNavigation } from '../SideNavigation'
import { TopNavigation } from '../TopNavigation'

function BorrowerMainLayout() {
  const { user } = useSession()
  const location = useLocation()
  const isInvestor = user?.actor?.isInvestor
  const isBorrower = user?.actor?.isBorrower
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)
  const navCollapsed = [
    routerPath.applicationDocument,
    routerPath.loanDocument,
    routerPath.investorDocument,
  ].some((path) => !!matchPath(path, location.pathname))

  return (
    <SwitcherProvider>
      <MainLayout
        topNavigation={
          !(isInvestor && isBorrower) ? <TopNavigation /> : undefined
        }
        navigation={
          <SideNavigation
            collapsed={navCollapsed}
            isMobileNavVisible={isMobileNavVisible}
            setIsMobileNavVisible={setIsMobileNavVisible}
          />
        }
        logoUrl={pathTo('dashboard')}
        isMobileNavVisible={isMobileNavVisible}
        setIsMobileNavVisible={setIsMobileNavVisible}
        footer={isInvestor && !isBorrower ? <Footer /> : undefined}
      >
        <Outlet />
      </MainLayout>
    </SwitcherProvider>
  )
}

export default BorrowerMainLayout
