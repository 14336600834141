import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Tabs } from 'components/Tabs'
import { Offering } from 'types'
import styles from '../Loan/styles.module.scss'
import { TabDocuments } from './TabDocuments'
import { TabFundGeneral } from './TabFundGeneral'

interface Props {
  offering: Offering
}

function OfferingFundView({ offering }: Props) {
  const { id, tab } = useParams() as { id: string; tab: string }
  const navigate = useNavigate()

  return (
    <Tabs
      key={tab}
      defaultActiveId={tab || 'general'}
      onTabSelect={(tabId) => navigate(pathTo('offering', id, tabId))}
      className={styles.tabs}
    >
      <Tabs.Pane tab="General" id="general">
        <TabFundGeneral offering={offering} />
      </Tabs.Pane>
      <Tabs.Pane tab="Documents" id="documents">
        <TabDocuments offering={offering} />
      </Tabs.Pane>
    </Tabs>
  )
}

export { OfferingFundView }
