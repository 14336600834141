import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Trust } from 'types'

type Props = {
  data?: Trust[]
  loading: boolean
  onEdit: (trust: Trust) => void
  onDelete: (trust: Trust) => void
}

function TableTrust({ data = [], loading, onEdit, onDelete }: Props) {
  const columns: ColumnDef<Trust>[] = useMemo(
    () => [
      {
        header: 'Trust Name',
        accessorKey: 'name',
      },
      {
        accessorKey: 'id',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(row.original)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableTrust
