import { compact, isNumber, toNumber, get } from 'lodash'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { isLoanServicing, pathTo as adminPathTo } from 'admin/path-to'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { BadgeBudgetStatus } from 'components/Badge'
import { Button } from 'components/Button'
import { Drawer } from 'components/Drawer'
import { DrawerActivity } from 'components/Drawer/Activity'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { InputCurrency } from 'components/InputCurrency'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalImage, useModalImage } from 'components/ModalImage'
import { Progress } from 'components/Progress'
import { TextLink } from 'components/TextLink'
import {
  useBudgetItemActivities,
  useDeleteBudgetItemActivity,
  useUpdateBudgetItemActivity,
} from 'hooks/use-loan-budget-draw'
import { useSession } from 'hooks/use-session'
import { BudgetItem, BudgetActivity, Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { subDecimal, divDecimal, timesDecimal, sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  addressId: string
  drawId: string
  readonly: boolean
  lender?: boolean
  budgetItem: BudgetItem
  onClose: () => void
}

const DrawerActivities = ({
  loan,
  addressId,
  drawId,
  readonly,
  lender,
  budgetItem,
  onClose,
}: Props) => {
  const { user } = useSession()
  const settings = get(user, 'client.settings', { budgetOveragePercentage: 0 })
  const budgetOveragePercentage = sumDecimal([
    settings.budgetOveragePercentage || 0,
    1,
  ])
  const {
    isImagesModalVisible,
    imageUrls,
    imageIndex,
    handleImageClick,
    handleClose,
  } = useModalImage()
  const [isDrawerActivityVisible, setIsDrawerActivityVisible] = useState(false)
  const [activityAmounts, setActivityAmounts] = useState<{
    [key: string]: number
  }>()
  const [editingActivity, setEditingActivity] = useState<BudgetActivity>()
  const [removingActivity, setRemovingActivity] = useState<BudgetActivity>()
  const { mutate: deleteActivity } = useDeleteBudgetItemActivity(
    loan.id,
    addressId
  )
  const { mutateAsync: editActivity, isPending: saving } =
    useUpdateBudgetItemActivity(loan.id, addressId)
  const { data: activities, isPending } = useBudgetItemActivities(
    loan.id,
    addressId,
    budgetItem.id
  )
  const drawBalancePercent = useMemo(
    () => divDecimal(budgetItem.balance, budgetItem.amount) * 100,
    [budgetItem.balance, budgetItem.amount]
  )
  const outstanding = useMemo(
    () => subDecimal(budgetItem.amount - budgetItem.balance),
    [budgetItem.balance, budgetItem.amount]
  )
  const handleSaveClick = useCallback(async () => {
    await Promise.all(
      compact(
        activities?.map(({ id, amount }) => {
          if (
            activityAmounts &&
            isNumber(activityAmounts[id]) &&
            activityAmounts[id] !== amount
          ) {
            return editActivity({ id, amount: activityAmounts[id] })
          }
        })
      ) || []
    )
    onClose()
  }, [activities, activityAmounts])
  const handleAmountChange = useCallback(
    (e, activity) => {
      const value = toNumber(e.target.value)
      setActivityAmounts((activityAmount) => ({
        ...activityAmount,
        [activity.id]: value,
      }))
    },
    [activityAmounts]
  )
  const pathToDraw = lender
    ? (...params) =>
        adminPathTo(
          isLoanServicing(loan.status) ? 'servicingLoanDraw' : 'loanDraw',
          ...params
        )
    : (...params) => borrowerPathTo('loanDraw', ...params)

  useEffect(() => {
    setActivityAmounts(
      activities?.reduce((acc, { id, amount }) => {
        acc[id] = amount
        return acc
      }, {})
    )
  }, [activities])

  return (
    <>
      <Drawer loading={isPending} onClose={onClose}>
        <Drawer.Header
          title={budgetItem.name}
          onClose={onClose}
          className="h-auto p-8 py-4"
        >
          <Flex stack gap={24}>
            <div className={styles.description}>{budgetItem.description}</div>
            <Flex stack gap={12}>
              <Flex justifyContent="space-between">
                <div className={styles.label}>Budget</div>
                <div className={styles.value}>
                  {formatUsd(budgetItem.amount)}
                </div>
              </Flex>
              <Flex justifyContent="space-between">
                <div className={styles.label}>Draw Balance</div>
                <Flex>
                  <Progress value={drawBalancePercent} />
                  <div className={styles.value}>
                    {formatUsd(budgetItem.balance)}
                  </div>
                </Flex>
              </Flex>
              <Flex justifyContent="space-between">
                <div className={styles.label}>Outstanding</div>
                <div className={styles.value}>
                  {formatUsd(outstanding, { showZero: true })}
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Drawer.Header>
        <Drawer.Content>
          <Header variant="h3" className={styles.header}>
            Activity
          </Header>
          {!activities?.length && (
            <div className={styles.emptyActivities}>
              <div>None have been created yet</div>
              {!lender && !readonly && (
                <TextLink onClick={() => setIsDrawerActivityVisible(true)}>
                  Add Activity
                </TextLink>
              )}
            </div>
          )}
          <Flex stack gap={24}>
            {activities?.map((activity) => (
              <Flex key={activity.id} stack gap={8}>
                <Flex stack gap={4}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <BadgeBudgetStatus status={activity.status} />
                    {!readonly &&
                      activity.budgetDrawId === drawId &&
                      activity.budgetDraw.status !== 'In Review' && (
                        <EllipsesActions>
                          <EllipsesActions.Item
                            icon
                            onSelect={() => {
                              setIsDrawerActivityVisible(true)
                              setEditingActivity(activity)
                            }}
                          >
                            <Icon name={IconName.edit} />
                            Edit
                          </EllipsesActions.Item>
                          <EllipsesActions.Item
                            icon
                            onSelect={() => setRemovingActivity(activity)}
                            className="text-red-100"
                          >
                            <Icon name={IconName.delete} />
                            Remove
                          </EllipsesActions.Item>
                        </EllipsesActions>
                      )}
                    {lender &&
                      activity.budgetDrawId === drawId &&
                      activity.budgetDraw.status === 'In Review' &&
                      !readonly && <div>Approve</div>}
                  </Flex>
                  <Flex alignItems="center" justifyContent="space-between">
                    <div>{activity.description}</div>
                    {lender &&
                    activity.budgetDrawId === drawId &&
                    activity.budgetDraw.status === 'In Review' &&
                    !readonly ? (
                      <Flex stack gap={4}>
                        <InputCurrency
                          className={styles.amount}
                          value={activity.amount}
                          onChange={(e) => handleAmountChange(e, activity)}
                        />
                        {activity.amountRequested &&
                          activity.amount !== activity.amountRequested && (
                            <div className={styles.requestedAmount}>
                              Requested {formatUsd(activity.amountRequested)}
                            </div>
                          )}
                      </Flex>
                    ) : (
                      <div className={styles.value}>
                        {formatUsd(activity.amount)}
                      </div>
                    )}
                  </Flex>
                </Flex>
                <div className={styles.images}>
                  {activity.images.map(({ viewUrl }) => (
                    <div
                      key={viewUrl}
                      style={{ backgroundImage: `url(${viewUrl})` }}
                      className={styles.image}
                      onClick={(e) =>
                        handleImageClick(
                          e,
                          activity.images.map(({ viewUrl }) => viewUrl),
                          viewUrl
                        )
                      }
                    />
                  ))}
                </div>
                <Flex gap={4} className={styles.activityDate}>
                  {friendlyDate(activity.createdAt)}
                  {activity.budgetDrawId !== drawId && (
                    <>
                      <div>•</div>
                      <div>
                        Draw{' '}
                        <NavLink
                          className={styles.linkDraw}
                          to={pathToDraw(
                            loan.id,
                            addressId,
                            activity.budgetDrawId
                          )}
                          onClick={onClose}
                        >
                          {friendlyDate(activity.budgetDraw.dateSubmitted)}
                        </NavLink>
                      </div>
                    </>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Drawer.Content>
        <Drawer.Footer>
          <Flex>
            {((lender && activities?.[0]?.budgetDraw.status !== 'In Review') ||
              !lender) && (
              <Button
                className={styles.footerButton}
                disabled={readonly}
                onClick={() => setIsDrawerActivityVisible(true)}
              >
                Add Activity
              </Button>
            )}
            {lender && activities?.[0]?.budgetDraw.status === 'In Review' && (
              <Button
                className={styles.footerButton}
                disabled={readonly}
                onClick={handleSaveClick}
                loading={saving}
              >
                Save
              </Button>
            )}
          </Flex>
        </Drawer.Footer>
      </Drawer>
      {isDrawerActivityVisible && (
        <DrawerActivity
          loanId={loan.id}
          addressId={addressId}
          drawId={drawId}
          budgetItem={budgetItem}
          activityId={editingActivity?.id}
          maxAmount={subDecimal(
            timesDecimal(budgetItem.amount, budgetOveragePercentage),
            budgetItem.balance
          )}
          onClose={() => {
            setIsDrawerActivityVisible(false)
            setEditingActivity(undefined)
          }}
        />
      )}
      {removingActivity && (
        <ModalDelete
          resource="activity"
          onDelete={() =>
            deleteActivity(removingActivity.id, {
              onSuccess: () => setRemovingActivity(undefined),
            })
          }
          onCancel={() => setRemovingActivity(undefined)}
        />
      )}
      {isImagesModalVisible && (
        <ModalImage
          onClose={handleClose}
          imageUrls={imageUrls}
          index={imageIndex}
        />
      )}
    </>
  )
}

export { DrawerActivities }
