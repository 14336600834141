import { useState, useEffect, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { AddressSummary, Summary } from 'components/Summary'
import { useBorrower } from 'hooks/use-borrower'
import { Loan } from 'types'
import { formatPhone } from 'utils/phone'
import styles from './styles.module.scss'

interface Props {
  borrower: Loan['borrowers'][0] | Loan['guarantors'][0]
}

function EntityDetails({ borrower }: Props) {
  const [socialSecurityNumbers, setSocialSecurityNumbers] = useState<
    string | null
  >(null)
  const [secure, setSecure] = useState<boolean>(false)
  const { data } = useBorrower(
    {
      id: borrower.id,
      params: { secure },
    },
    { enabled: secure }
  )

  useEffect(() => {
    if (data?.socialSecurityNumber) {
      setSocialSecurityNumbers(data.socialSecurityNumber)
    }
  }, [data])

  const isIndividual = !borrower.type || borrower.type === 'individual'
  return (
    <Grid gap={24}>
      <Grid.Item sm={12}>
        <Summary name={isIndividual ? 'Full Name' : 'Company Name'}>
          <NavLink to={pathTo('borrower', borrower.id)} className="link">
            {borrower.name}
          </NavLink>
        </Summary>
      </Grid.Item>
      {borrower.addresses &&
        borrower.addresses.map((address) => (
          <Grid.Item sm={6} key={address.id}>
            <AddressSummary address={address} />
          </Grid.Item>
        ))}
      <Grid.Item sm={6}>
        <Summary name="Phone">{formatPhone(borrower.phone)}</Summary>
      </Grid.Item>
      <Grid.Item sm={12}>
        <Summary name="Emails">
          {borrower.emails &&
            borrower.emails.map(({ email }) => (
              <Fragment key={email}>
                <LinkEmail email={email} />{' '}
              </Fragment>
            ))}
        </Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name={isIndividual ? 'Social Security Number' : 'Tax ID'}>
          {borrower.isSsnSet && socialSecurityNumbers}
          {borrower.isSsnSet && !socialSecurityNumbers ? (
            <span className={styles.ssnLink} onClick={() => setSecure(true)}>
              ••••••••• <Icon name={IconName.view} size="md" />
            </span>
          ) : undefined}
        </Summary>
      </Grid.Item>
    </Grid>
  )
}

export default EntityDetails
