import { forwardRef, ForwardedRef, memo, useMemo, useState } from 'react'
import {
  useCreateLoanTasksFromTemplate,
  useTaskTemplates,
} from 'admin/hooks/use-task-templates'
import { Button } from 'components/Button'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'

interface Props {
  loanId: string
  open: boolean
  onOpenChange: (value: boolean) => void
}

const TemplateDropdown = memo(
  forwardRef(
    ({ loanId, open, onOpenChange }: Props, ref: ForwardedRef<HTMLElement>) => {
      const [selectedTemplate, setSelectedTemplate] = useState<string | null>(
        null
      )
      const { mutate: createTasks, isPending: creating } =
        useCreateLoanTasksFromTemplate()
      const { data } = useTaskTemplates()
      const options = useMemo(
        () =>
          data?.taskTemplates.map((template) => ({
            label: template.name,
            value: template.id,
          })) || [],
        [data]
      )

      return (
        <Dropdown
          ref={ref}
          open={open}
          onOpenChange={(nextOpen) => {
            setSelectedTemplate(null)
            onOpenChange(nextOpen)
          }}
          trigger={
            <Button>
              <Icon name={IconName.addFromTemplate} className="mt-1" />
            </Button>
          }
        >
          <Flex stack className="p-4 w-[330px]">
            <Select
              options={options}
              value={selectedTemplate}
              placeholder="Select a Task Template"
              onChange={(option) => setSelectedTemplate(option.value as string)}
            />
            <Flex alignItems="center" justifyContent="flex-end" gap={10}>
              <Button variant="secondary" onClick={() => onOpenChange(false)}>
                Cancel
              </Button>
              <Button
                disabled={!selectedTemplate}
                loading={creating}
                onClick={() =>
                  createTasks(
                    { loanId, templateId: selectedTemplate as string },
                    { onSuccess: () => onOpenChange(false) }
                  )
                }
              >
                Add Tasks
              </Button>
            </Flex>
          </Flex>
        </Dropdown>
      )
    }
  )
)

export { TemplateDropdown }
