import { PanelLoanLenderOfRecord } from 'admin/components/PanelLenderOfRecord'
import { Grid } from 'components/Grid'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { Loan } from 'types'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabSettings({ loan }: Props) {
  return (
    <div className={styles.tabContent}>
      <Grid gap={16}>
        <Grid.Item sm={6} className={styles.panels}>
          <PanelLoanFields loan={loan} sections={['Loan Settings']} />
          <PanelLoanFields loan={loan} sections={['Late Charges']} />
        </Grid.Item>
        <Grid.Item sm={6} className={styles.panels}>
          <PanelLoanFields loan={loan} sections={['Prepayment Penalty']} />
          <PanelLoanFields loan={loan} sections={['Default Interest']} />
          <PanelLoanLenderOfRecord loanId={loan.id} />
        </Grid.Item>
      </Grid>
    </div>
  )
}
