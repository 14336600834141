import { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useDeleteBorrower } from 'admin/hooks/use-borrowers'
import { useFieldsByPersonId } from 'admin/hooks/use-person-fields'
import { pathTo } from 'admin/path-to'
import { BorrowerDetails } from 'admin/services/api/borrowers'
import { AccountTypeBadge, BorrowerStatusBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { useBorrower, useUpdateBorrowerOwners } from 'hooks/use-borrower'
import TabDocuments from './TabDocuments'
import TabGeneral from './TabGeneral'
import TabLoans from './TabLoans'
import styles from './styles.module.scss'

function Borrower() {
  const [secure, setSecure] = useState<boolean>()
  const [borrower, setBorrower] = useState<BorrowerDetails>()
  const { id, tab } = useParams() as { id: string; tab: string }
  const { data } = useBorrower({ id, params: { secure } })
  const { data: fields } = useFieldsByPersonId({
    personType: 'borrower',
    personId: id,
  })
  const { mutate: updateOwners } = useUpdateBorrowerOwners()
  const navigate = useNavigate()

  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id, owners })
    },
    [id, updateOwners]
  )

  const [isModalVisible, setIsModalVisible] = useState(false)

  const { mutate: deleteBorrower } = useDeleteBorrower()

  const handleClickDelete = () => {
    if (borrower) {
      deleteBorrower(borrower.id, {
        onSuccess: () => navigate(pathTo('borrowers')),
      })
    }
  }

  useEffect(() => {
    if (borrower && id !== borrower.id) {
      setBorrower(undefined)
      setSecure(false)
    }
  }, [id, borrower])

  useEffect(() => {
    if (data && fields) {
      setBorrower(data)
    }
  }, [data, fields])

  return (
    <MainContent>
      {borrower ? (
        <>
          <PageTop
            title={borrower.name}
            breadcrumbs={{ title: 'Borrowers', link: pathTo('borrowers') }}
            owners={borrower.owners || []}
            onOwnersChange={handleOwnersChange}
          />
          <div className={styles.badges}>
            <AccountTypeBadge
              type={borrower.type}
              isAccount={borrower.isAccount}
            />
            <BorrowerStatusBadge status={borrower.status} />
          </div>
          <PageTopFields
            fields={[
              { key: 'ID', value: borrower.id },
              ...(borrower?.type === 'individual'
                ? [
                    {
                      key: 'Email',
                      value: LinkEmail({ email: borrower.email }),
                    },
                  ]
                : []),
            ]}
          />
          <Tabs
            defaultActiveId={tab || 'general'}
            afterTabs={
              <EllipsesActions
                trigger={<Icon name={IconName.ellipses} size="md" />}
              >
                <EllipsesActions.Item
                  icon
                  onSelect={() => setIsModalVisible(true)}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Delete
                </EllipsesActions.Item>
              </EllipsesActions>
            }
            onTabSelect={(tabId) => navigate(pathTo('borrowerTab', id, tabId))}
            className={styles.tabs}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral
                borrower={borrower}
                fields={fields}
                onSecure={setSecure}
              />
            </Tabs.Pane>
            <Tabs.Pane tab="Loans" id="loans">
              <TabLoans loans={borrower.loans} />
            </Tabs.Pane>
            <Tabs.Pane tab="Documents" id="documents">
              <TabDocuments borrower={borrower} />
            </Tabs.Pane>
          </Tabs>
          {isModalVisible && (
            <ModalDelete
              resource="borrower"
              name={borrower.name}
              onDelete={handleClickDelete}
              onCancel={() => setIsModalVisible(false)}
            />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Borrower }
