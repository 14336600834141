import { getLoanFundingSources } from 'admin/services/api/loan-funding-sources'
import { Row } from 'types'

const downloadFundingInvestors = async (loanId: string): Promise<Row[]> => {
  const sources = await getLoanFundingSources(loanId)
  return sources.fundingSources.map((source) => ({
    Investor: source.investor.name,
    'Date Invested': source.dateInvested,
    'Principal Balance$': source.amount,
    Class: source.class,
    'Yield%': source.rate,
    Principal$: source.principalBalance,
    Interest$: source.interestBalance,
    Other$: source.otherBalance,
  }))
}

export { downloadFundingInvestors }
