import clsx from 'clsx'
import { DragEvent, useState } from 'react'
import {
  useLoanImage,
  useAddLoanImage,
  useRemoveLoanImage,
} from 'admin/hooks/use-loan-image'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalImage } from 'components/ModalImage'
import { Loan } from 'types'
import { openBrowseFile } from 'utils/file'
import styles from './LoanImage.module.scss'

interface Props {
  loan: Loan
  className?: string
}

export const LoanImage = ({ loan, className }: Props) => {
  const [isRemoveVisible, setIsRemoveVisible] = useState(false)
  const [isDragActive, setIsDragActive] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isFullImgVisible, setIsFullImgVisible] = useState(false)
  const { data: image } = useLoanImage(loan.id)
  const { mutate: remove, isPending: removing } = useRemoveLoanImage(loan.id)
  const { mutate: upload } = useAddLoanImage(
    loan.id,
    image?.uploadUrl as string,
    image?.images?.[0]?.id
  )
  const imageUrl = loan.image?.url || image?.images?.[0]?.url
  const imageId = image?.images?.[0]?.id
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        upload(files)
      },
      accept: '.png, .jpg, .jpeg',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files)
  }

  return (
    <>
      <div
        className={clsx(
          styles.preview,
          !imageUrl && styles.placeholder,
          className
        )}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
        onDragEnter={handleDrag}
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
        onClick={imageUrl ? () => setIsFullImgVisible(true) : handleUpload}
      >
        {imageUrl && isMouseOver && (
          <Flex justifyContent="flex-end">
            <EllipsesActions
              trigger={
                <div className="bg-grey-100 rounded w-7 h-7 p-1.5 mr-2 mt-2">
                  <Icon
                    name={IconName.ellipses}
                    size="md"
                    className={styles.ellipses}
                  />
                </div>
              }
            >
              <EllipsesActions.Item icon onSelect={handleUpload}>
                <Icon name={IconName.upload} />
                Upload
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => setIsRemoveVisible(true)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          </Flex>
        )}
        {isDragActive || (!imageUrl && isMouseOver) ? (
          <DragDropFile
            onLeave={() => setIsDragActive(false)}
            onDrop={handleDrop}
            accept={['png', 'jpg', 'jpeg']}
            className="border border-dashed border-grey-400 rounded-[5px] bg-grey-25"
          >
            <Button variant="secondary">Add image</Button>
          </DragDropFile>
        ) : null}
      </div>
      {isRemoveVisible && (
        <ModalDelete
          resource="image"
          loading={removing}
          onDelete={() => {
            remove(imageId, {
              onSuccess: () => {
                setIsRemoveVisible(false)
                setIsMouseOver(false)
              },
            })
          }}
          onCancel={() => {
            setIsRemoveVisible(false)
            setIsMouseOver(false)
          }}
        />
      )}
      {isFullImgVisible && (
        <ModalImage
          imageUrls={[imageUrl]}
          onClose={() => setIsFullImgVisible(false)}
          closeOnClickOutside
        />
      )}
    </>
  )
}
