import { request } from 'services/request'
import { Risk } from 'types'

const getOfferingRisks = async (offeringId: string): Promise<Risk[]> => {
  const {
    data: { risks },
  } = await request.get(`/offering/${offeringId}/risk`)
  return risks
}

const addOfferingRisk = async (
  offeringId: string,
  payload: Partial<Risk>
): Promise<Risk> => {
  const {
    data: { risk },
  } = await request.post(`/offering/${offeringId}/risk`, payload)
  return risk
}

const editOfferingRisk = async (
  offeringId: string,
  { id, ...payload }: Partial<Risk>
): Promise<Risk> => {
  const {
    data: { risk },
  } = await request.patch(`/offering/${offeringId}/risk/${id}`, payload)
  return risk
}

const deleteOfferingRisk = async (
  offeringId: string,
  riskId: string
): Promise<unknown> => {
  return request.delete(`/offering/${offeringId}/risk/${riskId}`)
}

export {
  getOfferingRisks,
  addOfferingRisk,
  editOfferingRisk,
  deleteOfferingRisk,
}
