import { capitalize } from 'lodash'
import { Button } from 'components/Button'
import { Address } from 'types'
import { Text } from '../Text'
import styles from './styles.module.scss'
import { Summary } from './index'

function AddressSummary({
  address,
  onClick,
}: {
  address: Address
  onClick?: () => void
}) {
  if (
    !address.street1 &&
    !address.street2 &&
    !address.city &&
    !address.state &&
    !address.zipcode &&
    !address.country
  ) {
    return (
      <Summary name={`${capitalize(address.type)} Address`}>
        <Button size="small" onClick={onClick}>
          Edit Address
        </Button>
      </Summary>
    )
  }

  return (
    <Summary name={`${capitalize(address.type)} Address`}>
      <div onClick={onClick} className={onClick ? styles.link : undefined}>
        <Text className={styles.addressLine}>{address.street1}</Text>
        <Text className={styles.addressLine}>{address.street2}</Text>
        <Text className={styles.addressLine}>
          {address.city ? `${address.city},` : ''} {address.state}{' '}
          {address.zipcode}
        </Text>
        <Text className={styles.addressLine}>{address.country}</Text>
      </div>
    </Summary>
  )
}

export default AddressSummary
