import { LoanStatus } from 'constants/loan-status'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  dateFunded: string | null
  amount: number
  loanStatus?: LoanStatus
}

type Status = 'Active' | 'Pending' | 'Liquidated'

const statusToColor: Record<Status, ValueOf<Pick<BadgeProps, 'color'>>> = {
  Active: 'green',
  Pending: 'yellow',
  Liquidated: 'gray',
}

const getStatus = ({ dateFunded, amount, loanStatus }): Status => {
  if (dateFunded && amount !== 0 && loanStatus !== 'liquidated') {
    return 'Active'
  }
  if (dateFunded) {
    return 'Liquidated'
  }
  return 'Pending'
}

function FundingInvestorStatusBadge({ dateFunded, amount, loanStatus }: Props) {
  const status = getStatus({ dateFunded, amount, loanStatus })
  return <Badge color={statusToColor[status]}>{status}</Badge>
}

export { FundingInvestorStatusBadge }
