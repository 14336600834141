import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getClientImage } from 'admin/services/api/client-image'
import { KEY_CLIENT } from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useClientImage = (id: string) => {
  return useQuery({
    queryKey: [KEY_CLIENT, id, 'image'],
    queryFn: () => getClientImage(id),
  })
}

const useAddClientImage = (id: string, url: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          await uploadDocument(url, file, (progressEvent) => {
            uploadToast.progress(progressEvent.loaded / progressEvent.total)
          })
          uploadToast.processing()
          await new Promise((resolve) => setTimeout(resolve, 5000))

          uploadToast.complete()
          return true
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CLIENT, id, 'image'] })
    },
    onError: handleErrorResponse,
  })
}

export { useClientImage, useAddClientImage }
