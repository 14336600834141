import * as yup from 'yup'

export const required = yup
  .string()
  .test('is-only-spaces', 'The field is required', (val) => val?.trim() !== '')
  .required('The field is required')
export const requiredCheckbox = yup
  .bool()
  .oneOf([true], 'The field is required')
  .required('The field is required')
export const email = yup.string().email('Invalid email')
export const passwordMin = yup
  .string()
  .min(6, 'Passwords must be at least 6 characters in length')
export const confirmation = (ref: string) =>
  yup.string().oneOf([yup.ref(ref)], 'Passwords must match')
export const accountName = yup
  .string()
  .required('The field is required')
  .max(23, 'The field max length is 23 characters')
  .matches(
    /[a-zA-Z0-9]/,
    'Only latin characters, numbers and spaces are allowed'
  )

export const createScheme = (scheme: yup.ObjectShape) => {
  return yup.object().shape(scheme)
}

export const mergeRules = (...rules: yup.AnySchema[]) => {
  const [first, ...rest] = rules

  return rest.reduce((mergedRules, rule) => mergedRules.concat(rule), first)
}
