import clsx from 'clsx'
import { Formik } from 'formik'
import { isNil } from 'lodash'
import { IMaskInput } from 'react-imask'
import { Button } from 'components/Button'
import { Field, Form, Select } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { BankingData } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  banking: BankingData | null
  saving: boolean
  onSave: (banking: BankingData) => void
  onCancel: () => void
}

type FormValues = {
  accountNumber: string
  routingNumber: string
  accountName: string
  accountType: 'checking' | 'savings'
  enabled: boolean
}

const Schema = createScheme({
  accountNumber: required,
  routingNumber: required,
  accountName: required,
  accountType: required,
})

function EntityBanking({ banking, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    accountNumber: banking?.accountNumber || '',
    routingNumber: banking?.routingNumber || '',
    accountName: banking?.accountName || '',
    accountType: banking?.accountType || 'checking',
    enabled: isNil(banking?.enabled) ? true : banking?.enabled || false,
  }

  return (
    <Modal
      title="Pre-Authorized Debit"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={6}>
              <Field label="Account Name" name="accountName">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^[A-Za-z0-9\s.,]+$/}
                    autoComplete="off"
                    maxLength={23}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Account Number" name="accountNumber">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^\d+$/}
                    autoComplete="off"
                    maxLength={17}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Field label="Routing Number" name="routingNumber">
                {({ field, meta: { touched, error } }) => (
                  <IMaskInput
                    mask={/^\d+$/}
                    autoComplete="off"
                    maxLength={9}
                    className={clsx(formStyles.input, {
                      [formStyles.errorField]: touched && error,
                    })}
                    {...field}
                  />
                )}
              </Field>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="accountType"
                label="Type"
                options={[
                  { label: 'Checking', value: 'checking' },
                  { label: 'Savings', value: 'savings' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="enabled"
                label="Status"
                options={[
                  { label: 'Active', value: true },
                  { label: 'Disabled', value: false },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default EntityBanking
