import qs from 'qs'
import { request } from 'services/request'
import {
  Address,
  Email,
  Person,
  PersonDetails,
  PersonRole,
  PersonTitle,
  Filter,
  Meta,
  Pagination,
  Sort,
  Search,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

type Investor = Person
type InvestorDetails = PersonDetails

const getInvestors = async ({
  details,
  ...params
}: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ investors: Investor[]; meta: Meta }> => {
  const searchParams = qs.stringify({ details }, { addQueryPrefix: true })
  const {
    data: { investors, pagination },
  } = await request.get(`/investor${searchParams}`, {
    params: updateRequestTable(params),
  })
  return {
    investors,
    meta: pagination,
  }
}

const getInvestor = async (
  id: string,
  params?: { secure?: boolean }
): Promise<InvestorDetails> => {
  const {
    data: { investor },
  } = await request.get(`/investor/${id}`, { params })
  return investor
}

const updateInvestor = async (payload: Investor): Promise<InvestorDetails> => {
  const {
    data: { investor },
  } = await request.patch(`/investor/${payload.id}`, payload)
  return investor
}

const addInvestor = async (
  payload: Omit<Investor, 'id'> & { sendInvitation?: boolean }
): Promise<InvestorDetails> => {
  const {
    data: { investor },
  } = await request.post('/investor', payload)
  return investor
}
const addAddress = ({ id, address }: { id: string; address: Address }) => {
  return request.post(`/investor/${id}/address`, address)
}

const updateAddress = ({
  id,
  addressId,
  address,
}: {
  id: string
  addressId: string
  address: Address
}) => {
  return request.patch(`/investor/${id}/address/${addressId}`, address)
}

const addEmail = async ({
  id,
  email,
}: {
  id: string
  email: string
}): Promise<Email> => {
  const { data } = await request.post(`/investor/${id}/email`, { email })
  return data.investor.emails.pop()
}

const updateEmail = ({
  id,
  emailId,
  primary,
}: {
  id: string
  emailId: string
  primary: boolean
}) => {
  return request.patch(`/investor/${id}/email/${emailId}`, { primary })
}

const inviteEmail = ({ id }: { id: string }) => {
  return request.put(`/investor/${id}/login`)
}

const verifyEmail = ({ id, emailId }: { id: string; emailId: string }) => {
  return request.post(`/investor/${id}/email/${emailId}/verify`)
}

const deleteEmail = ({ id, emailId }: { id: string; emailId: string }) => {
  return request.delete(`/investor/${id}/email/${emailId}`)
}

const linkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role: PersonRole
  title: PersonTitle
}): Promise<Investor> => {
  const {
    data: { investor },
  } = await request.put(`/investor/${entityId}/manager`, {
    id: individualId,
    role,
    title,
  })
  return investor
}

const updateLinkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role?: PersonRole
  title?: PersonTitle
}): Promise<Investor> => {
  const {
    data: { investor },
  } = await request.patch(`/investor/${entityId}/manager/${individualId}`, {
    role,
    title,
  })
  return investor
}

const unlinkAccount = async ({
  individualId,
  entityId,
}: {
  individualId: string
  entityId: string
}): Promise<Investor> => {
  const {
    data: { investor },
  } = await request.delete(`/investor/${entityId}/manager/${individualId}`)
  return investor
}

const deleteInvestor = (id: string) => {
  return request.delete(`/investor/${id}`)
}

const updateOwners = async ({
  id,
  owners,
}: {
  id: string
  owners: string[]
}): Promise<Person> => {
  const {
    data: { investor },
  } = await request.put(`/investor/${id}/owner`, { owners })

  return investor
}

export type { Investor, InvestorDetails }

export {
  getInvestors,
  getInvestor,
  addInvestor,
  updateInvestor,
  addAddress,
  updateAddress,
  addEmail,
  inviteEmail,
  verifyEmail,
  updateEmail,
  deleteEmail,
  deleteInvestor,
  unlinkAccount,
  updateLinkAccount,
  linkAccount,
  updateOwners,
}
