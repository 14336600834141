import { ReactNode } from 'react'
import { Flex } from 'components/Flex'

interface Props {
  icon: ReactNode
}

export const EmptyIcon = ({ icon }: Props) => {
  return <Flex justifyContent="center">{icon}</Flex>
}
