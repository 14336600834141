import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  loan: Loan
}

export const PanelPayment = ({ loan }: Props) => {
  return (
    <Panel title="Payment">
      <Grid gap={16}>
        <Grid.Item sm={6}>
          <Summary name="Paid Through">{formatDate(loan.datePaidto)}</Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Last Payment">
            {formatDate(loan.dateLastpayment)}
          </Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Next Payment">
            <Flex gap={8}>{formatDate(loan.dateNextpayment)}</Flex>
          </Summary>
        </Grid.Item>
        <Grid.Item sm={6}>
          <Summary name="Amount Due">
            {formatUsd(loan.nextPaymentAmount)}
          </Summary>
        </Grid.Item>
      </Grid>
    </Panel>
  )
}
