import { request } from 'services/request'
import { switchSession } from './session'

const createCompanyAccount = async ({
  entity,
  name,
}: {
  entity: 'borrower' | 'investor'
  name: string
}) => {
  const { data } = await request.post(`/${entity}`, {
    name,
  })
  return data
}

const createPersonalAccount = async ({ userId }: { userId: string }) => {
  const { data } = await request.patch(`/borrower/${userId}`, {
    is_account: true,
  })
  return data
}

const switchContext = async ({ personId }: { personId: string }) => {
  const { data } = await request.put(`/login`, {
    personId,
  })
  switchSession(data)
  return data
}

export { createCompanyAccount, createPersonalAccount, switchContext }
