import { useState } from 'react'
import {
  useAddLenderOfRecord,
  useUpdateLenderOfRecord,
} from 'admin/hooks/use-lender-of-record'
import { LenderOfRecord, Address } from 'types'
import { Modal } from '../index'
import { FormAddress } from './FormAddress'
import { FormEntityInfo } from './FormEntityInfo'
import styles from './styles.module.scss'

interface Props {
  clientId: string
  lender?: Partial<LenderOfRecord>
  onCancel: () => void
}

type Stage = 'information' | 'address'
const getTitle = (stage: Stage) => {
  switch (stage) {
    case 'information':
      return 'Entity Information'
    case 'address':
      return 'Addresses'
  }
}
const getStep = (stage: Stage) => {
  switch (stage) {
    case 'information':
      return 1
    case 'address':
      return 2
  }
}

function ModalLender({ clientId, lender, onCancel }: Props) {
  const { mutate: add, isPending: adding } = useAddLenderOfRecord(clientId)
  const { mutate: update, isPending: updating } =
    useUpdateLenderOfRecord(clientId)
  const [stage, setStage] = useState<Stage>('information')
  const [details, setDetails] = useState<Partial<LenderOfRecord> | undefined>(
    lender
  )
  const [address, setAddress] = useState<Address | undefined>(lender?.address)

  const handleNext = (data: any) => {
    if (stage === 'information') {
      setDetails(data)
      setStage('address')
    } else {
      const save = lender ? update : add
      save(
        {
          ...details,
          id: lender?.id,
          address: data?.street1 ? data : undefined,
        } as Partial<LenderOfRecord>,
        {
          onSuccess: () => onCancel(),
        }
      )
    }
  }
  const handleBack = (draftData?: any) => {
    if (stage === 'address') {
      draftData && setAddress(draftData)
      setStage('information')
    }
  }

  return (
    <Modal
      title={getTitle(stage)}
      onClose={onCancel}
      className={styles.modal}
      step={{
        step: getStep(stage),
        steps: 2,
      }}
      asChild
    >
      {stage === 'information' && (
        <FormEntityInfo
          lender={details}
          onSave={handleNext}
          onCancel={onCancel}
        />
      )}
      {stage === 'address' && (
        <FormAddress
          address={address}
          saving={adding || updating}
          onBack={handleBack}
          onSave={handleNext}
          onCancel={onCancel}
        />
      )}
    </Modal>
  )
}

export { ModalLender }
