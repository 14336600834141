import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  FundPayment,
  getFundFundingSources,
  getFundTransactions,
  addFundPayment,
  deleteFundPayment,
} from 'admin/services/api/fund-payment'
import { KEY_FUNDS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'
import { message } from 'utils/message'

const useFundFundingSources = ({
  fundId,
  params,
}: {
  fundId: string
  params?: { filter?: Filter; sort?: Sort }
}) => {
  const nextFilter = size(params?.filter) ? params?.filter : undefined
  return useQuery({
    queryKey: [KEY_FUNDS, fundId, 'funding_sources', nextFilter, params?.sort],
    queryFn: () =>
      getFundFundingSources({ fundId, filter: nextFilter, ...params }),
  })
}

const useFundTransactions = ({
  id,
  search,
  filter,
  pagination,
}: {
  id: string
  search?: string
  filter?: Filter
  pagination?: Pagination
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_FUNDS, id, 'transactions', search, nextFilter, pagination],
    queryFn: () =>
      getFundTransactions({
        id,
        search,
        filter: nextFilter,
        page: pagination,
      }),
    placeholderData: keepPreviousData,
  })
}

const useAddFundPayment = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fundPayment: FundPayment) =>
      addFundPayment(fundId, fundPayment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_FUNDS, fundId, 'transactions'],
      })
      message.success('Transaction created')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteFundPayment = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (paymentId: string) => deleteFundPayment(paymentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_FUNDS, fundId, 'transactions'],
      })
      message.success('Payment deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useFundFundingSources,
  useFundTransactions,
  useAddFundPayment,
  useDeleteFundPayment,
}
