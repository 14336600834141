import clsx from 'clsx'
import {
  useRef,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  ReactNode,
} from 'react'
import { mergeRefs } from 'react-merge-refs'
import styles from './styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  indeterminate?: boolean
  label?: ReactNode
  variant?: 'default' | 'rounded'
}

const Checkbox = forwardRef(
  (
    { className, label, value, indeterminate, variant, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const checkboxRef = useRef<HTMLInputElement>()

    useEffect(() => {
      if (checkboxRef.current) {
        checkboxRef.current.indeterminate = !!indeterminate
      }
    }, [indeterminate])

    return label ? (
      <label className={clsx(className, styles.label)}>
        <input
          type="checkbox"
          className={clsx(styles.checkbox, {
            [styles.rounded]: variant === 'rounded',
          })}
          ref={mergeRefs([ref, checkboxRef])}
          checked={value as unknown as boolean}
          {...rest}
        />
        {label}
      </label>
    ) : (
      <input
        type="checkbox"
        className={clsx(styles.checkbox, className, {
          [styles.rounded]: variant === 'rounded',
        })}
        ref={mergeRefs([ref, checkboxRef])}
        checked={value as unknown as boolean}
        {...rest}
      />
    )
  }
)
Checkbox.displayName = 'Checkbox'

export default Checkbox
