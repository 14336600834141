import { flexRender, Header } from '@tanstack/react-table'
import { memo } from 'react'
import TableHead from 'components/Table/Head'
import styles from 'components/Table/styles.module.scss'
import { ColumnMeta, Sort } from 'types'

interface Props<TData> {
  header: Header<TData, any>
  sort?: Sort
  onSort?: (sort: Sort | string | undefined) => void
}

const HeaderRowCellComponent = <TData,>({
  header,
  sort,
  onSort,
}: Props<TData>) => {
  const meta = header.column.columnDef.meta as ColumnMeta

  return (
    <th
      key={header.id}
      className={styles.th}
      onClick={() => {
        meta?.sortable && onSort && onSort(header.id)
      }}
    >
      <TableHead sort={sort} {...header.getContext()}>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, {
              ...header.getContext(),
              sort,
            })}
      </TableHead>
    </th>
  )
}

export const HeaderRowCell = memo(
  HeaderRowCellComponent
) as typeof HeaderRowCellComponent
