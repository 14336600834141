import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Field, Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Risk } from 'types'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const FormSchema = createScheme({
  name: required,
  description: required,
})

type FormValues = {
  name: string
  description: string
}

interface Props {
  saving: boolean
  risk?: Risk
  onSave: (risk: Partial<Risk>) => void
  onCancel: () => void
}

function ModalRisk({ risk, saving, onSave, onCancel }: Props) {
  const isEditing = !!risk
  const initialValue: FormValues = {
    name: risk?.name || '',
    description: risk?.description || '',
  }

  return (
    <Modal
      title={isEditing ? 'Edit Risk' : 'Add a New Risk'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={FormSchema}
        onSubmit={onSave}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field name="name" label="Risk" />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Textarea name="description" label="Description" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalRisk
