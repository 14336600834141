import clsx from 'clsx'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { formatUsd } from 'utils/currency'

interface Props {
  releasedAmount: number
  committedAmount: number
  loanAmount: number
  amount: number
  isScopeOfWork?: boolean
}

const MeterBar = ({
  releasedAmount,
  committedAmount,
  loanAmount,
  amount,
}: Props) => {
  const total = useMemo(
    () => Math.max(loanAmount, committedAmount),
    [loanAmount, committedAmount]
  )
  const releasedPercent = useMemo(
    () => (releasedAmount / total) * 100,
    [releasedAmount, total]
  )
  const commitmentPercent = useMemo(() => {
    const remainingAmount = Math.max(
      committedAmount - releasedAmount - amount,
      0
    )
    return (remainingAmount / total) * 100
  }, [committedAmount, releasedAmount, amount, total])
  const amountPercent = useMemo(() => (amount / total) * 100, [amount, total])
  const unfunded = useMemo(
    () => loanAmount - Math.max(amount + releasedAmount, committedAmount),
    [loanAmount, releasedAmount, committedAmount, amount]
  )

  return (
    <Flex stack gap={10} className="normal-nums">
      <Flex gap={0} className="h-2 bg-grey-100 rounded-[3px]">
        {releasedPercent > 0.1 && (
          <div
            style={{ width: `${releasedPercent}%` }}
            className="h-full bg-green-50 inline-block first:rounded-l-[3px]"
          />
        )}
        {commitmentPercent > 0.1 && (
          <div
            style={{ width: `${commitmentPercent}%` }}
            className="h-full bg-green-35 inline-block first:rounded-l-[3px]"
          />
        )}
        {amountPercent > 0.1 && (
          <div
            style={{ width: `${amountPercent}%` }}
            className={clsx(
              'h-full bg-grey-400 inline-block first:rounded-l-[3px]'
            )}
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" gap={12}>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-50 rounded-[3px]" />
          {formatUsd(releasedAmount, { showZero: true })} Invested
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-35 rounded-[3px]" />
          {formatUsd(committedAmount, { showZero: true })} Commitment
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-400 rounded-[3px]" />
          {formatUsd(amount, { showZero: true })} This Payment
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-100 rounded-[3px]" />
          {formatUsd(unfunded, { showZero: true })} Unfunded
        </Flex>
      </Flex>
    </Flex>
  )
}

export { MeterBar }
