import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Table } from 'components/Table'
import { Sort } from 'types'
import { formatUsd } from 'utils/currency'

interface Props {
  data?: any[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableTax1099({ data = [], sort, onSort, loading }: Props) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'investor',
        header: 'Investor',
        size: 200,
        cell: ({ row }) => {
          return row.original.investorId ? (
            <NavLink
              key={row.original.investorId}
              to={pathTo('investor', row.original.investorId)}
              className="link"
            >
              {row.original.investorName}
            </NavLink>
          ) : (
            '-'
          )
        },
      },
      {
        id: 'investorAddress',
        header: 'Address',
        size: 200,
        cell: ({ row }) => row.original.investorAddress as string,
      },
      {
        id: 'investorTaxIdType',
        header: 'ID Type',
        cell: ({ row }) => row.original.investorTaxIdType as string,
      },
      {
        id: 'investorTaxId',
        header: 'ID Number',
        size: 100,
        cell: ({ row }) => (row.original.investorTaxId ? '•••••••••' : '-'),
      },
      {
        header: 'Interest Income',
        accessorKey: 'interestIncome',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Spread Income',
        accessorKey: 'spreadIncome',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Charges Income',
        accessorKey: 'chargesIncome',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Total Income',
        accessorKey: 'totalIncome',
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableTax1099
