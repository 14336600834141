import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useAddLoanAddress,
  useDeleteLoanAddress,
} from 'admin/hooks/use-loan-address'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { useProduct } from 'admin/hooks/use-products'
import PanelInsurance from 'admin/pages/Loan/PanelInsurance'
import PanelValuation from 'admin/pages/Loan/PanelValuation'
import styles from 'admin/pages/Loan/styles.module.scss'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import LoanAddressesForm, {
  AddressFormValues,
  initialFormValues as addressFormValues,
} from 'components/Modal/AddLoan/LoanAddressesForm'
import { ModalConfirm } from 'components/Modal/Confirm'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { Tabs } from 'components/Tabs'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'
import { Address, Loan } from 'types'
import { PropertyDetails } from './PropertyDetails'
import { UnitDetails } from './UnitDetails'

interface Props {
  loan: Loan
}

function TabCollateral({ loan }: Props) {
  const { entityId: addressId = loan.addresses[0]?.id } = useParams()
  const { isOrigination } = useLoanType()
  const { isAdminManager } = useSession()
  const navigate = useNavigate()
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const address = loan.addresses.find(({ id }) => id === addressId) as Address
  const addressIndex = loan.addresses.findIndex(({ id }) => id === addressId)
  const { subTab } = useParams() as {
    subTab: string
  }

  const { mutate: deleteAddress, isPending: isDeletingAddress } =
    useDeleteLoanAddress({
      loanId: loan.id,
      addressId: addressId as string,
      onSuccess: () => {
        setIsRemoveModalVisible(false)
        navigate(
          pathTo(
            isOrigination ? 'loanTab' : 'servicingLoanTab',
            loan.id,
            'collateral'
          )
        )
      },
    })

  const { mutate: addAddress, isPending: isAddressAdding } = useAddLoanAddress({
    id: loan.id,
  })

  const { data: product } = useProduct({
    id: loan.productId,
  })

  const openAddress = useCallback(
    (addressId: string) => {
      navigate(
        pathTo(
          isOrigination ? 'loanTabEntityId' : 'servicingLoanTabEntityId',
          loan.id,
          'collateral',
          addressId
        )
      )
    },
    [navigate, loan.id, isOrigination]
  )

  const handleSaveAddresses = async (formValues: unknown) => {
    const { addresses } = formValues as { addresses: AddressFormValues[] }

    for await (const address of addresses) {
      addAddress(address as unknown as Address, {
        onSuccess: ({ addresses }) => {
          setIsAddModalVisible(false)
          const addressAfterSave = addresses.find(
            (a) => a.street1 === address.street1
          )
          if (addressAfterSave) {
            openAddress(addressAfterSave.id)
          } else {
            navigate(pathTo(isOrigination ? 'loan' : 'servicingLoan', loan.id))
          }
        },
      })
    }
  }
  return (
    <div className="mt-4 border border-solid border-grey-200 rounded bg-white-100">
      <Flex gap={0}>
        <Flex
          stack
          className="border-0 border-solid border-r border-grey-200 p-2.5 bg-grey-25 rounded-l relative"
          gap={10}
        >
          {loan.addresses.map((address) => (
            <Flex alignItems="center" key={address.id}>
              <Tooltip content={address.street1} placement="right">
                <div
                  className={clsx(
                    'w-8 h-8 text-grey-600 cursor-pointer border border-solid border-grey-200 rounded flex items-center justify-center',
                    address.id !== addressId && 'hover:bg-grey-75',
                    address.id === addressId &&
                      'bg-blue-200 text-white-100 border-blue-200'
                  )}
                  onClick={() => openAddress(address.id)}
                >
                  <Icon name={IconName.address} />
                </div>
              </Tooltip>
              {address.id === addressId && (
                <div className="absolute h-[13px] w-[3px] bg-blue-200 right-0" />
              )}
            </Flex>
          ))}
          {isAdminManager && (
            <Tooltip content="Add Address" placement="right">
              <Button
                variant="ghost"
                disabled={loan.lock}
                className="w-8 h-8 text-grey-600"
                onClick={() => setIsAddModalVisible(true)}
              >
                <Icon name={IconName.addCircle} />
              </Button>
            </Tooltip>
          )}
        </Flex>

        {address ? (
          <Flex stack gap={24} className="p-6 flex-grow">
            <PropertyDetails
              loan={loan}
              addressIndex={addressIndex}
              onOpenAddress={openAddress}
              onRemove={() => setIsRemoveModalVisible(true)}
            />
            <Tabs
              defaultActiveId={subTab || 'property_details'}
              onTabSelect={(subTab) =>
                navigate(
                  pathTo(
                    isOrigination
                      ? 'loanTabEntityId'
                      : 'servicingLoanTabEntityId',
                    loan.id,
                    'collateral',
                    address.id,
                    subTab
                  )
                )
              }
              variant="blue"
              className="gap-6"
            >
              <Tabs.Pane
                tab={
                  <Flex gap={8} className="text-blue-100">
                    <Icon name={IconName.home} />
                    Property Details
                  </Flex>
                }
                id="property_details"
              >
                <PanelLoanFields
                  loan={loan}
                  title="Property Value"
                  sections={['Property Value']}
                  address={address}
                  className="border-0 px-0 border-b rounded-none shadow-none"
                />
                <PanelLoanFields
                  loan={loan}
                  title="Taxes & HOA"
                  sections={['Taxes & HOA']}
                  address={address}
                  className="border-0 px-0 border-b rounded-none shadow-none"
                />
                <PanelLoanFields
                  loan={loan}
                  title="Property Features"
                  sections={['Features']}
                  address={address}
                  className="border-0 px-0 border-b rounded-none shadow-none"
                />
                <UnitDetails loan={loan} address={address} />
              </Tabs.Pane>
              <Tabs.Pane
                tab={
                  <Flex gap={8} className="text-blue-100">
                    <Icon name={IconName.valuation} />
                    Valuations
                  </Flex>
                }
                id="valuations"
              >
                <PanelValuation loan={loan} address={address} />
              </Tabs.Pane>
              <Tabs.Pane
                tab={
                  <Flex gap={8} className="text-blue-100">
                    <Icon name={IconName.insurance} />
                    Insurance
                  </Flex>
                }
                id="insurance"
              >
                <PanelInsurance loan={loan} address={address} />
              </Tabs.Pane>
            </Tabs>
          </Flex>
        ) : null}
      </Flex>

      {isRemoveModalVisible && (
        <ModalConfirm
          title="Delete loan address"
          text="Are you sure you want to delete this loan address?"
          loading={isDeletingAddress}
          onConfirm={deleteAddress}
          onCancel={() => setIsRemoveModalVisible(false)}
        />
      )}
      {isAddModalVisible && (
        <Modal
          title="Add Address"
          asChild
          className={styles.modal}
          onClose={() => setIsAddModalVisible(false)}
        >
          <LoanAddressesForm
            initialValues={[addressFormValues]}
            productFields={product?.fields || loan.fields}
            saving={isAddressAdding}
            loading={false}
            onCancel={() => setIsAddModalVisible(false)}
            onSubmit={handleSaveAddresses}
          />
        </Modal>
      )}
    </div>
  )
}

export { TabCollateral }
