import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  isLocked: boolean
  isPrintable: boolean
  isShared: boolean
  isSignable: boolean
  hasVersion: boolean
  onRename: () => void
  onPrint: () => void
  onComment: () => void
  onRequest?: () => void
  onShare?: () => void
  onSignature: () => void
  onDelete: () => void
}

const HeaderActions = ({
  isLocked,
  isPrintable,
  isShared,
  isSignable,
  hasVersion,
  onRename,
  onRequest,
  onPrint,
  onComment,
  onShare,
  onSignature,
  onDelete,
}: Props) => {
  return (
    <Flex gap={10} flexWrap="wrap">
      {isSignable && (
        <Button variant="ghost" disabled={isLocked} onClick={onSignature}>
          <Icon name={IconName.send} className="text-grey-600" />
          Send for Signature
        </Button>
      )}
      {onRequest && (
        <Button variant="ghost" disabled={isLocked} onClick={onRequest}>
          <Icon name={IconName.requestDocument} className="text-grey-600" />
          Request Document
        </Button>
      )}
      {isPrintable && (
        <Button variant="ghost" onClick={onPrint}>
          <Icon name={IconName.print} className="text-grey-600" />
          Print
        </Button>
      )}
      {onShare ? (
        <Button variant="ghost" disabled={isLocked} onClick={onShare}>
          <Icon name={IconName.share} className="text-grey-600" />
          {isShared ? 'Unshare' : 'Share'}
        </Button>
      ) : (
        <></>
      )}
      <Button variant="ghost" onClick={() => onComment()}>
        <Icon name={IconName.comments} className="text-grey-600" />
        Comment
      </Button>

      <EllipsesActions
        trigger={
          <Button variant="ghost" icon>
            <Icon name={IconName.ellipses} size="md" />
          </Button>
        }
      >
        <EllipsesActions.Item icon disabled={isLocked} onSelect={onRename}>
          <Icon name={IconName.edit} className="text-grey-600" />
          Rename
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          disabled={isLocked}
          onSelect={onDelete}
          variant="danger"
        >
          <Icon name={IconName.delete} />
          {hasVersion ? 'Delete version' : 'Delete'}
        </EllipsesActions.Item>
      </EllipsesActions>
    </Flex>
  )
}

export { HeaderActions }
