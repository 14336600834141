import clsx from 'clsx'
import { HTMLProps, ReactNode, useEffect, useState } from 'react'
import { useResolutions, Breakpoint } from '../../admin/hooks/use-resolutions'
import styles from './styles.module.scss'

interface ItemProps extends HTMLProps<HTMLDivElement> {
  xs?: number
  sm?: number
  md?: number
  lg?: number
  xl?: number
  className?: string
  children?: ReactNode
}

function Item({
  xs = 12,
  sm,
  md,
  lg,
  xl,
  className,
  children,
  ...rest
}: ItemProps) {
  const spans = { xs, sm, md, lg, xl }
  const [span, setSpan] = useState(xs)
  const availableBreakpoints = Object.keys(spans).filter(
    (key) => !!spans[key as keyof typeof spans]
  ) as Breakpoint[]
  const [resolution] = useResolutions(availableBreakpoints)

  useEffect(() => {
    const spans = { xs, sm, md, lg, xl }
    setSpan(spans[resolution] || xs)
  }, [resolution])

  return (
    <div
      className={clsx(className, styles.item)}
      style={{ gridColumn: `span ${span}` }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Item
