import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface PaneProps {
  children: ReactNode
  tab: ReactNode
  id: string
  more?: boolean
  group?: string
  disabled?: boolean
  className?: string
}

function Pane({ children, className }: PaneProps) {
  return <div className={clsx(styles.pane, className)}>{children}</div>
}

export type { PaneProps }
export default Pane
