import { useParams } from 'react-router-dom'
import { useApplication } from 'borrower/hooks/use-application'
import { PanelApplicationPage } from 'components/Application/PanelApplicationPage'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import { useDraftApplication } from 'hooks/use-application'
import styles from './LoanApplication.module.scss'

const LoanApplication = () => {
  const { id } = useParams() as {
    id: string
  }
  const { data: application } = useApplication({ id })
  const { data: applicationScheme } = useDraftApplication(
    application?.applicationId as string,
    {
      enabled: !!application?.applicationId,
    }
  )

  return !applicationScheme ? (
    <PageLoader />
  ) : (
    <Grid className={styles.container} gap={24} data-all-loaded>
      <Grid.Item xs={12}>
        <Header className={styles.header} variant="h1">
          {application?.name}
        </Header>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Flex stack gap={24}>
          {applicationScheme.data.pages.map((page) => (
            <PanelApplicationPage
              key={page.id}
              page={page}
              dateSubmitted={applicationScheme.dateSubmitted}
              className={styles.boxShadowNone}
            />
          ))}
        </Flex>
      </Grid.Item>
    </Grid>
  )
}

export { LoanApplication }
