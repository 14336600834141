import { isArray, isUndefined } from 'lodash'
import { Quote, QuoteOption, QuoteOptionEditableProps } from 'types'
import { QuoteField, Field } from 'types/field'
import { orderFields } from '../fields'
import { updateResponseValue, updateRequestValue } from './field-parser'

const updateResponseFields = (fields: QuoteField[]): Field[] => {
  return orderFields(
    fields.map(
      ({ quoteProperty, ...rest }) =>
        ({
          ...rest,
          property: {
            ...quoteProperty,
            value: updateResponseValue(quoteProperty.value, rest.type),
          },
        }) as Field
    )
  )
}

const updateResponseQuote = (quote): Quote => {
  const { compensationOriginationFee, compensationYieldSpread } =
    quote.compensation || {}
  return {
    ...quote,
    compensation: {
      ...(quote.compensation || {}),
      ...(isUndefined(compensationOriginationFee)
        ? {}
        : {
            compensationOriginationFee: compensationOriginationFee * 100,
          }),
      ...(isUndefined(compensationYieldSpread)
        ? {}
        : {
            compensationYieldSpread: compensationYieldSpread * 100,
          }),
    },
    fields: updateResponseFields(quote.quoteFields),
    options: quote.options?.map((option) => updateResponseOption(option)),
  }
}

const updateRequestFields = (fields: Field[]): QuoteField[] => {
  return fields.map(({ property, ...field }) => ({
    ...field,
    quoteProperty: {
      ...property,
      value: updateRequestValue(property.value, field.type),
    },
  }))
}

const updateResponseOption = ({
  rate,
  compensationYieldSpread,
  points,
  compensationOriginationFee,
  ...option
}: QuoteOption) => {
  return {
    ...option,
    fields: isArray(option.fields)
      ? updateResponseFields(option.fields as unknown as QuoteField[])
      : option.fields,
    rate: rate ? rate * 100 : rate,
    compensationYieldSpread: compensationYieldSpread
      ? compensationYieldSpread * 100
      : compensationYieldSpread,
    points: points ? points * 100 : points,
    compensationOriginationFee: compensationOriginationFee
      ? compensationOriginationFee * 100
      : compensationOriginationFee,
  }
}

const updateRequestOption = ({
  rate,
  compensationYieldSpread,
  points,
  compensationOriginationFee,
  ...option
}: QuoteOptionEditableProps) => {
  return {
    ...option,
    rate: rate ? rate / 100 : rate,
    compensationYieldSpread: compensationYieldSpread
      ? compensationYieldSpread / 100
      : compensationYieldSpread,
    points: points ? points / 100 : points,
    compensationOriginationFee: compensationOriginationFee
      ? compensationOriginationFee / 100
      : compensationOriginationFee,
  }
}

export {
  updateResponseFields,
  updateResponseQuote,
  updateRequestFields,
  updateResponseOption,
  updateRequestOption,
}
