import clsx from 'clsx'
import { ForwardedRef, forwardRef, HTMLProps } from 'react'
import { LoaderOverlay } from 'components/LoaderOverlay'
import styles from './styles.module.scss'

interface Props extends HTMLProps<HTMLDivElement> {
  loading?: boolean
}

const Content = forwardRef(
  (
    { children, loading, className, ...rest }: Props,
    ref: ForwardedRef<HTMLDivElement | null>
  ) => {
    return (
      <div ref={ref} className={clsx(styles.content, className)} {...rest}>
        {loading ? <LoaderOverlay /> : children}
      </div>
    )
  }
)

Content.displayName = 'Drawer.Content'

export { Content }
