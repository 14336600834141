import clsx from 'clsx'
import { useCallback, useMemo } from 'react'
import {
  useGetPayoutNachaFile,
  // useGetPayoutCsvFile,
} from 'admin/hooks/use-payout'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { pluralize } from 'utils/pluralize'

interface Props {
  transactions: Transaction[]
  destinations: Record<string, 'banking' | 'wallet'>
  saving: boolean
  onNext: () => void
  onBack: () => void
}

const StepReview = ({
  transactions,
  destinations,
  saving,
  onNext,
  onBack,
}: Props) => {
  const { mutate: getNachaFile } = useGetPayoutNachaFile()
  // const { mutate: getCsvFile } = useGetPayoutCsvFile()

  const walletTransactions = useMemo(
    () =>
      Object.entries(destinations)
        .filter(([, destination]) => destination === 'wallet')
        .map(
          ([transactionId]) =>
            transactions.find(({ id }) => transactionId === id) as Transaction
        ),
    [transactions, destinations]
  )
  const walletTransactionsAmount = useMemo(
    () => sumDecimal(walletTransactions.map(({ amount }) => amount)),
    [walletTransactions]
  )
  const bankingTransactions = useMemo(
    () =>
      Object.entries(destinations)
        .filter(([, destination]) => destination === 'banking')
        .map(
          ([transactionId]) =>
            transactions.find(({ id }) => transactionId === id) as Transaction
        ),
    [transactions, destinations]
  )
  const bankingTransactionsAmount = useMemo(
    () => sumDecimal(bankingTransactions.map(({ amount }) => amount)),
    [bankingTransactions]
  )
  const handleDownloadNacha = useCallback(() => {
    getNachaFile({
      include: bankingTransactions.map(({ id }) => id),
      exclude: [],
      completed: false,
    })
  }, [getNachaFile, bankingTransactions])
  // const handleDownloadCsv = useCallback(() => {
  //   getCsvFile({
  //     include: bankingTransactions.map(({ id }) => id),
  //     exclude: [],
  //   })
  // }, [getCsvFile, bankingTransactions])
  const totalAmount = useMemo(
    () => sumDecimal([walletTransactionsAmount, bankingTransactionsAmount]),
    [walletTransactionsAmount, bankingTransactionsAmount]
  )

  return (
    <Flex stack gap={32}>
      <Panel title="Payout Summary" className="pb-0">
        {walletTransactions.length > 0 && (
          <Flex
            className={clsx(
              'w-full py-4 flex-grow',
              bankingTransactions.length &&
                'border-0 border-b border-solid border-grey-200'
            )}
            alignItems="center"
            gap={12}
          >
            <Icon name={IconName.wallet} className="text-green-50" />
            <Flex stack gap={4} className="flex-grow">
              <div className="font-bold text-lg">
                {pluralize(walletTransactions.length, 'Wallet')} Payout
              </div>
              <div className="text-grey-700">
                To be credited in Recipient wallets
              </div>
            </Flex>
            <div className="font-bold justify-self-end text-right">
              {formatUsd(walletTransactionsAmount, { showZero: true })}
            </div>
          </Flex>
        )}
        {bankingTransactions.length > 0 && (
          <Flex className="w-full py-4 flex-grow" alignItems="center" gap={12}>
            <Icon name={IconName.bank} className="text-green-50" />
            <Flex stack gap={12} className="flex-grow">
              <Flex stack gap={4}>
                <div className="font-bold text-lg">
                  {pluralize(bankingTransactions.length, 'Manual')} Payout
                </div>
                <div className="text-grey-700">
                  To be sent manually through your banking institution
                </div>
              </Flex>
              <Flex gap={6} alignItems="center">
                <Button variant="ghost" onClick={handleDownloadNacha}>
                  <Icon name={IconName.download} />
                  Download NACHA
                </Button>
              </Flex>
            </Flex>
            <div className="font-bold justify-self-end text-right">
              {formatUsd(bankingTransactionsAmount, { showZero: true })}
            </div>
          </Flex>
        )}
        <div className="px-3 py-4 bg-grey-50 -mx-5 rounded-b">
          <Flex justifyContent="space-between" alignItems="center">
            <div className="font-bold">Total Payout amount</div>
            <div className="font-bold">
              {formatUsd(totalAmount, { showZero: true })}
            </div>
          </Flex>
        </div>
      </Panel>
      <Flex justifyContent="flex-end" gap={10}>
        <Button variant="secondary" size="large" onClick={onBack}>
          <Icon name={IconName.arrowLeftLong} />
          Back
        </Button>
        <Button
          variant="primary"
          size="large"
          loading={saving}
          onClick={onNext}
        >
          Process Payouts
        </Button>
      </Flex>
    </Flex>
  )
}

export { StepReview }
