import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { FormAddress } from 'components/FormAddress'
import { Address } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  address: Address
  saving: boolean
  onSave: (address: Partial<Address>) => void
  onCancel: () => void
}

type FormValues = {
  [key: string]:
    | string
    | string[]
    | Pick<
        Address,
        'street1' | 'street2' | 'city' | 'state' | 'zipcode' | 'country'
      >
  address: Pick<
    Address,
    'street1' | 'street2' | 'city' | 'state' | 'zipcode' | 'country'
  >
}

function ModalLoanPropertyDetails({
  address,
  saving,
  onSave,
  onCancel,
}: Props) {
  const initialValue: FormValues = {
    address: {
      street1: address.street1,
      street2: address.street2,
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
    },
  }
  const handleSubmit = (values: FormValues) => {
    onSave({
      id: address.id,
      ...values.address,
    })
  }

  return (
    <Modal
      title="Property Details"
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        <Form modal>
          <div>
            <FormAddress prefix="address" />
          </div>
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalLoanPropertyDetails
