import { get, set } from 'lodash'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { SelectRecipient } from 'admin/pages/LoanSpreadAllocation/SelectRecipient'
import { Flex } from 'components/Flex'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'

export const PanelSpread = () => {
  const { user } = useSession()
  const { mutate: update } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    defaultSpreadRecipient: '',
  })

  return (
    <Panel title="Spread Allocation" className="max-w-panel">
      <Flex>
        <Flex stack gap={8}>
          <Text className="font-bold">
            By default, who should be assigned surplus/deficit spread?
          </Text>
          <Text className="text-grey-700">
            This person will automatically be placed on the spread allocation
            table for each loan, receiving all available spread that hasn&apos;t
            been assigned to other investors or vendors. Conversely, they will
            also absorb deficit (negative) spread should the amount assigned to
            all other recipients exceed what is available to distribute.
          </Text>
        </Flex>
        <SelectRecipient
          className="w-60 flex-shrink-0"
          value={settings.defaultSpreadRecipient}
          allowCreate
          onSelect={(recipient) => {
            const updatedSettings = {
              ...settings,
              defaultSpreadRecipient: recipient.id,
            }
            set(user as any, 'client.settings', updatedSettings)
            update({ clientId, settings: updatedSettings })
          }}
        />
      </Flex>
    </Panel>
  )
}
