import clsx from 'clsx'
import DOMPurify from 'dompurify'
import { useEffect, useState, useRef } from 'react'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  value: string
}

function Summary({ value }: Props) {
  const preview = useRef<HTMLDivElement>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (preview.current) {
      const isOverflowing =
        preview.current.scrollHeight > preview.current.clientHeight ||
        preview.current.scrollWidth > preview.current.clientWidth
      setIsExpanded(!isOverflowing)
    }
  }, [])

  return (
    <Flex stack gap={16}>
      <Header variant="h2">Summary</Header>
      <div
        ref={preview}
        className={clsx(styles.summary, isExpanded && styles.expanded)}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
      />
      {!isExpanded && (
        <div
          className="font-bold cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Show more <Icon name={IconName.arrowDown} />
        </div>
      )}
    </Flex>
  )
}

export { Summary }
