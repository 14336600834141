import { useState } from 'react'
import {
  useAddLoanAddressValuation,
  useUpdateLoanAddressValuation,
  useDeleteLoanAddressValuation,
} from 'admin/hooks/use-loan-address-valuation'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { AddressValuation } from 'components/Modal/AddressValuation'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { Loan, Address } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  address: Address
}

export default function PanelValuation({ loan, address }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: addValuation, isPending: adding } =
    useAddLoanAddressValuation({
      loanId: loan.id,
      addressId: address.id,
      onSuccess: () => {
        setIsModalVisible(false)
      },
    })
  const { mutate: updateValuation, isPending: updating } =
    useUpdateLoanAddressValuation({
      loanId: loan.id,
      addressId: address.id,
      onSuccess: () => {
        setEditingId(null)
        setIsModalVisible(false)
      },
    })
  const { mutate: deleteValuation, isPending: deleting } =
    useDeleteLoanAddressValuation({
      loanId: loan.id,
      addressId: address.id,
    })

  return (
    <Panel title="Valuation">
      <div className={styles.valuations}>
        {address.valuations.map((valuation) => (
          <Grid gap={24} key={valuation.id}>
            <Grid.Item sm={12} className={styles.valuationHeader}>
              <Header variant="h4">{valuation.name}</Header>
              {!loan.lock && (
                <div className={styles.valuationButtons}>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditingId(valuation.id)
                      setIsModalVisible(true)
                    }}
                    disabled={deleting}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => deleteValuation(valuation.id)}
                    disabled={deleting}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Appraiser Name">{valuation.appraiser}</Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Report Date">
                {formatDate(valuation.reportDate)}
              </Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Value">{formatUsd(valuation.value)}</Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Type">{valuation.valuationType}</Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Market Rent">
                {formatUsd(valuation.marketRent)}
              </Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Source">{valuation.source}</Summary>
            </Grid.Item>
          </Grid>
        ))}
      </div>
      {!address.valuations.length && (
        <div className={styles.noData}>No valuations</div>
      )}
      {!loan.lock && (
        <div className={styles.addValuation}>
          <TextLink onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" /> Add Valuation
          </TextLink>
        </div>
      )}
      {isModalVisible && (
        <AddressValuation
          valuation={address.valuations.find(({ id }) => editingId === id)}
          saving={adding || updating}
          onSave={(valuation) =>
            editingId ? updateValuation(valuation) : addValuation(valuation)
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}
