import { default as Decimal } from 'decimal.js'
import { compact, toNumber } from 'lodash'

const sumDecimal = (amounts: undefined | (string | number | null)[]) => {
  const filteredAmounts = compact(amounts).map(toNumber)
  return filteredAmounts?.length
    ? Decimal.sum(...filteredAmounts).toNumber()
    : 0
}

const timesDecimal = (a = 0, b = 0) =>
  new Decimal(a || 0).times(b || 0).toNumber()

const subDecimal = (a = 0, b = 0) => Decimal.sub(a || 0, b || 0).toNumber()

const divDecimal = (a = 0, b = 0) => (b ? Decimal.div(a || 0, b).toNumber() : 0)

const isZero = (a: number) => new Decimal(a).isZero()

const formatPercent = (v: number | string) =>
  v ? new Decimal(v).times(100).toNumber() : v

const parsePercent = (v: number | string | undefined | null) =>
  v ? new Decimal(v).div(100).toNumber() : v

export {
  sumDecimal,
  timesDecimal,
  subDecimal,
  divDecimal,
  isZero,
  formatPercent,
  parsePercent,
}
