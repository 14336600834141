import clsx from 'clsx'
import { ReactNode } from 'react'
import { useMobile } from 'hooks/use-mobile'
import { LayoutProvider } from './LayoutProvider'
import { MainHeader } from './MainHeader'
import styles from './styles.module.scss'

interface MainLayoutProps {
  layoutClassName?: string
  logoUrl: string
  topNavigation?: ReactNode
  navigation: ReactNode | null
  children: ReactNode
  isMobileNavVisible: boolean
  setIsMobileNavVisible: (visible: boolean) => void
  footer?: ReactNode
}

function MainLayout({
  children,
  topNavigation,
  navigation,
  layoutClassName,
  logoUrl,
  isMobileNavVisible,
  setIsMobileNavVisible,
  footer,
}: MainLayoutProps) {
  const { isTablet } = useMobile()

  return (
    <>
      {isTablet && (
        <MainHeader
          logoUrl={logoUrl}
          onMenuClick={() => setIsMobileNavVisible(!isMobileNavVisible)}
        />
      )}
      <LayoutProvider isTopNavigation={!!topNavigation}>
        <div
          className={clsx(
            styles.mainLayout,
            topNavigation && styles.stack,
            layoutClassName
          )}
        >
          {!isTablet && topNavigation}
          {((!topNavigation && !isTablet) || isMobileNavVisible) && navigation}
          {children}
        </div>
        {footer}
      </LayoutProvider>
    </>
  )
}

export default MainLayout
