import { useQuery, keepPreviousData } from '@tanstack/react-query'
import {
  getLoanSchedule,
  AmortizationPayment,
} from 'admin/services/api/loan-schedule'
import { KEY_LOANS } from 'constants/query-keys'
import { Pagination } from 'types'

interface Params {
  page?: Pagination
}

export type { AmortizationPayment }

const useLoanSchedule = (loanId: string, params: Params) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, 'schedule', params.page],
    queryFn: () => getLoanSchedule(loanId, params),
    placeholderData: keepPreviousData,
  })
}

export { useLoanSchedule }
