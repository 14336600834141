import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import imagePlaceholder from 'images/offering-placeholder.png'
import { FundPortfolio, Sort } from 'types'
import { formatDate } from 'utils/date'
import { formatPercent as transformPercent } from 'utils/math'
import { formatPercent } from 'utils/percent'

function TableFundPortfolio({
  data = [],
  loading,
  sort,
  onSort,
  onClick,
}: {
  data?: FundPortfolio[]
  loading: boolean
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  onClick: (portfolio?: FundPortfolio) => void
}) {
  const columns: ColumnDef<FundPortfolio>[] = useMemo(
    () => [
      {
        header: 'Investment',
        key: 'name',
        cell: ({ row }) => {
          const image = row.original.image?.url || imagePlaceholder
          return (
            <Flex alignItems="center" gap={10}>
              <img src={image} className="w-9 h-9 rounded-sm object-cover" />
              <div className="font-bold">{row.original.name}</div>
            </Flex>
          )
        },
      },
      {
        header: 'Loan Type',
        accessorKey: 'loanType',
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'LTV Ratio',
        accessorKey: 'ltv',
        cell: ({ getValue }) =>
          formatPercent(transformPercent((getValue() as string) || 0)),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Maturity Date',
        accessorKey: 'dateMaturity',
        cell: ({ getValue }) => formatDate(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
      onClick={onClick}
    />
  )
}

export { TableFundPortfolio }
