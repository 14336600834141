import clsx from 'clsx'
import { capitalize, pick } from 'lodash'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useHandleAutomationLogAction } from 'admin/hooks/use-automation'
import { pathToLoan } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Modal } from 'components/Modal'
import ModalConfirm from 'components/Modal/Confirm/ModalConfirm'
import {
  IAutomationLog,
  ITriggerConfig,
  IActionConfig,
  IConditionConfig,
  IActionValue,
} from 'types'
import { friendlyDateTime } from 'utils/date'
import { MenuTrigger } from './MenuTrigger'

interface Props {
  automationLog?: IAutomationLog
  onClose: () => void
}

const getStatusDetails = (status) => {
  const icons = {
    success: {
      actionStatusIconKey: 'circleCheck',
      actionStatusIconStyles: 'text-green-50',
      status,
    },
    failed: {
      actionStatusIconKey: 'attention',
      actionStatusIconStyles: 'text-red-50',
      status,
    },
    default: {
      actionStatusIconKey: '',
      actionStatusIconStyles: '',
      status: '',
    },
  }
  return icons[status] || icons.default
}

const ModalAutomationLog = ({ automationLog, onClose }: Props) => {
  const [actionEventId, setActionEventId] = useState<string>()

  const { mutate: handleOperationAction, isPending: isActionPending } =
    useHandleAutomationLogAction()

  return (
    <Modal title="Automation Log" crossClose onClose={onClose}>
      <Flex stack gap={24}>
        <Flex gap={32} className="text-[13px] print:text-[10px]">
          {automationLog?.reference?.name && (
            <Flex gap={4} flexDirection="column">
              <div className="text-grey-900">Reference</div>
              <div className="text-grey-900 font-bold">
                <NavLink
                  to={pathToLoan(
                    pick(automationLog.reference, ['id', 'status'])
                  )}
                  className="link"
                >
                  {automationLog.reference.name}
                </NavLink>
              </div>
            </Flex>
          )}
          {automationLog?.createdAt && (
            <Flex gap={4} style={{ flexDirection: 'column' }}>
              <div className="text-grey-900">Executed</div>
              <div className="text-grey-900">
                {friendlyDateTime(automationLog?.createdAt as string)}
              </div>
            </Flex>
          )}
        </Flex>
        <Flex className="mt-4 -mb-4">Trigger</Flex>
        <Flex stack gap={0}>
          <MenuTrigger
            className={clsx(
              automationLog?.conditions?.length && 'rounded-b-none',
              'cursor-default'
            )}
            itemConfig={automationLog?.trigger?.config as ITriggerConfig}
            value={automationLog?.trigger}
          />
          {automationLog?.conditions?.map((condition, index) => (
            <MenuTrigger
              key={condition.id}
              className={clsx(
                'rounded-t-none border-t-0',
                index !== automationLog.conditions.length - 1 &&
                  'rounded-b-none'
              )}
              itemConfig={condition.config as IConditionConfig}
              value={condition}
            />
          ))}
        </Flex>
        <Flex className="ml-5 -mt-6">
          <div className="w-px bg-grey-200 h-8"></div>
        </Flex>
        <Flex className="-mt-4 -mb-4">Actions</Flex>
        {automationLog?.actions?.length && (
          <Flex stack gap={0}>
            {automationLog.actions.map((action, index) => (
              <MenuTrigger
                key={index}
                itemConfig={action.config as unknown as IActionConfig}
                value={action}
                className={clsx(
                  'cursor-default',
                  index !== automationLog.actions.length - 1 &&
                    'rounded-b-none',
                  index !== 0 && 'rounded-t-none border-t-0'
                )}
                statusDetails={getStatusDetails(
                  !action?.config?.success ? 'failed' : 'success'
                )}
                onActionEvent={() => {
                  setActionEventId(
                    (action.config as unknown as IActionValue)?.eventId
                  )
                }}
              />
            ))}
          </Flex>
        )}
      </Flex>
      {actionEventId && (
        <ModalConfirm
          title={`${capitalize(automationLog?.actions.find((action) => action.eventId === actionEventId)?.action || 'undo')} Action?`}
          text=""
          loading={isActionPending}
          onConfirm={() => {
            handleOperationAction(actionEventId, {
              onSuccess: () => {
                setActionEventId(undefined)
                onClose()
              },
            })
          }}
          onCancel={() => setActionEventId(undefined)}
        />
      )}
    </Modal>
  )
}

export { ModalAutomationLog }
