import clsx from 'clsx'
import { useCallback } from 'react'
import { Meta, Pagination } from 'types/table'
import { Button } from '../Button'
import styles from './styles.module.scss'

interface LoadMoreProps {
  hidePagination?: boolean
  meta?: Meta
  count: number
  perPage?: number
  loading?: boolean
  fetching?: boolean
  onLoadMore: (pagination: Pagination) => void
  className?: string
}

function LoadMore({
  hidePagination,
  count,
  meta = { page: 0, pageSize: 25, total: count },
  loading,
  fetching,
  onLoadMore,
  className,
}: LoadMoreProps) {
  const handleLoadMore = useCallback(() => {
    onLoadMore({ page: meta.page + 1, size: meta.pageSize })
  }, [meta])
  const isVisible = !loading && count && (!hidePagination || meta.total > count)

  return isVisible ? (
    <div className={clsx(styles.container, className)}>
      {!hidePagination && (
        <div className={styles.info}>
          Showing {count} of {meta.total}
        </div>
      )}
      {meta.total > count && (
        <Button variant="tertiary" onClick={handleLoadMore} loading={fetching}>
          Load more
        </Button>
      )}
    </div>
  ) : null
}

export default LoadMore
