import { toNumber, toString } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLoanPaymentCalculate } from 'admin/hooks/use-loan-payment'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { InputCurrency } from 'components/InputCurrency'
import { Summary } from 'components/Summary'
import { Loan, LoanFundingSource } from 'types'
import { sumDecimal } from 'utils/math'
import { AccordionPanel } from './AccordionPanel'
import { TableInvestments } from './TableInvestments'
import { TableTrust } from './TableTrust'
import styles from './styles.module.scss'
import {
  ICalculatedInvestment,
  ICalculatedTrust,
  IPaymentInfo,
  ISource,
} from './types'

interface Props {
  loan: Loan
  paymentType: IPaymentInfo['type']
  source: ISource
  selectedFundingSources?: LoanFundingSource[]
  disabled: boolean
  onChange: (source: ISource) => void
}

const getSourceTrustItems = (trust: ICalculatedTrust[]) => {
  return trust.map(({ id, amount }) => ({
    id,
    amount: toString(amount),
  }))
}

export const Source = ({
  loan,
  paymentType,
  source,
  selectedFundingSources,
  disabled,
  onChange,
}: Props) => {
  const investorsRef = useRef<any>()
  const [open, setOpen] = useState<'investors' | 'trust' | null>(null)
  const [focused, setFocused] = useState<'investors' | 'trust' | null>(null)
  const isBorrowerVisible =
    paymentType !== 'To Borrower' && paymentType !== 'Funding'
  const isInvestorsVisible = paymentType === 'Funding'
  const isTrustVisible = paymentType !== 'Funding'
  const { data: investments, isPending: isPendingInvestors } =
    useLoanPaymentCalculate(
      {
        loanId: loan.id,
        type: 'investments',
        amount: toNumber(source.investments?.requestAmount) || 0,
        paymentType,
        selectedFundingSources: selectedFundingSources?.map(({ id }) => id),
      },
      {
        keepPreviousData: true,
        enabled: open === 'investors',
      },
      { debounce: true }
    )
  const { data: trust, isPending: isPendingTrust } = useLoanPaymentCalculate(
    {
      loanId: loan.id,
      type: 'trust',
      amount: toNumber(source.trust?.requestAmount) || 0,
      paymentType,
      context: 'source',
    },
    {
      keepPreviousData: true,
      enabled: open === 'trust',
    },
    { debounce: true }
  )
  const handleRowChange = useCallback(
    (key: 'trust' | 'investments', rowId, value) => {
      let items: ISource['trust']['items'] | ISource['investments']['items'] =
        []
      if (key === 'trust') {
        items =
          ((trust || []) as ICalculatedTrust[]).length !==
          source.trust.items.length
            ? getSourceTrustItems((trust || []) as ICalculatedTrust[])
            : source.trust.items
      } else {
        items = source.investments.items
      }

      const nextItems = items.map(({ id, amount, ...rest }) => ({
        ...rest,
        id,
        amount: id === rowId ? value : amount,
      }))

      const nextAmount = sumDecimal(
        nextItems.map((item) => toNumber((item as any)['amount']) || 0)
      )

      onChange({
        ...source,
        [key]: {
          ...source[key],
          amount: toString(nextAmount),
          items: nextItems,
        },
      })
    },
    [source]
  )

  useEffect(() => {
    onChange({
      ...source,
      trust: {
        ...source.trust,
        items: getSourceTrustItems((trust as ICalculatedTrust[]) || []),
      },
    })
  }, [trust])

  useEffect(() => {
    onChange({
      ...source,
      investments: {
        ...source.investments,
        items: ((investments || []) as ICalculatedInvestment[]).map(
          ({ fundingSource, amount, remainingCommitment }) => ({
            id: fundingSource.id,
            fundingSource,
            amount: toString(amount),
            remainingCommitment: toString(remainingCommitment),
          })
        ),
      },
    })
  }, [investments])

  useEffect(() => {
    if (selectedFundingSources && investorsRef.current) {
      setTimeout(() => {
        investorsRef.current.element?.focus()
      }, 100)
    }
  }, [selectedFundingSources])

  useEffect(() => {
    setOpen(null)
  }, [paymentType])

  return (
    <Flex stack gap={8}>
      <Header variant="h4">Source</Header>
      <Grid gap={8}>
        {isBorrowerVisible && (
          <Grid.Item xs={3}>
            <Summary name="Borrower">
              <InputCurrency
                onFocus={() => setOpen(null)}
                onBlur={() => setFocused(null)}
                value={source.borrower?.amount}
                disabled={disabled}
                onChange={(e) =>
                  onChange({
                    ...source,
                    borrower: { ...source.borrower, amount: e.target.value },
                  })
                }
              />
            </Summary>
          </Grid.Item>
        )}
        {isInvestorsVisible && (
          <Grid.Item xs={3}>
            <Summary
              name={
                <span
                  className={styles.label}
                  onClick={() => setOpen('investors')}
                >
                  Investors
                </span>
              }
            >
              <InputCurrency
                ref={investorsRef}
                onFocus={() => {
                  setOpen('investors')
                  setFocused('investors')
                }}
                onBlur={() => setFocused(null)}
                value={source.investments?.amount}
                disabled={disabled}
                onChange={(e) =>
                  onChange({
                    ...source,
                    investments: {
                      ...source.investments,
                      requestAmount: e.target.value,
                      amount: e.target.value,
                    },
                  })
                }
              />
            </Summary>
          </Grid.Item>
        )}
        {isTrustVisible && (
          <Grid.Item xs={3}>
            <Summary
              name={
                <span className={styles.label} onClick={() => setOpen('trust')}>
                  Trust
                </span>
              }
            >
              <InputCurrency
                onFocus={() => {
                  setOpen('trust')
                  setFocused('trust')
                }}
                onBlur={() => setFocused(null)}
                value={source.trust.amount}
                disabled={disabled}
                onChange={(e) => {
                  onChange({
                    ...source,
                    trust: {
                      ...source.trust,
                      requestAmount: e.target.value,
                      amount: e.target.value,
                    },
                  })
                }}
              />
            </Summary>
          </Grid.Item>
        )}
      </Grid>
      {open === 'investors' && (
        <AccordionPanel
          active={[1]}
          title="Adjust Release Amounts"
          focused={focused === 'investors'}
          onClose={() => {
            setOpen(null)
            setFocused(null)
          }}
        >
          <TableInvestments
            loading={isPendingInvestors}
            investmentItems={source.investments.items}
            onChange={(rowId, value) =>
              handleRowChange('investments', rowId, value)
            }
          />
        </AccordionPanel>
      )}
      {open === 'trust' && (
        <AccordionPanel
          active={[isBorrowerVisible ? 2 : 1]}
          title="Adjust Trust Distribution"
          focused={focused === 'trust'}
          onClose={() => {
            setOpen(null)
            setFocused(null)
          }}
        >
          <TableTrust
            loading={isPendingTrust}
            type="source"
            trust={trust as ICalculatedTrust[]}
            trustItems={source.trust.items}
            onChange={(rowId, value) => handleRowChange('trust', rowId, value)}
          />
        </AccordionPanel>
      )}
    </Flex>
  )
}
