import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react'
import clsx from 'clsx'
import { useContext } from 'react'
import { SwitcherContext } from 'borrower/components/Switcher/SwitcherContext'
import { Option } from 'components/Form'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { useCreatePersonalAccount, useSwitchAccount } from 'hooks/use-borrower'
import { BorrowerAccount, CurrentPerson } from 'services/api/session'
import styles from './styles.module.scss'
import { useAccountOptions, getAccountName } from './use-account-options'

interface Props {
  borrowerAccounts?: BorrowerAccount[]
  actor: CurrentPerson
  person: CurrentPerson
  onAddCompany: () => void
}

function SelectBorrowerAccount({
  borrowerAccounts,
  actor,
  person,
  onAddCompany,
}: Props) {
  const options = useAccountOptions(borrowerAccounts, actor, person)
  const addPersonalAccount = useCreatePersonalAccount()
  const { mutate: switchAccount } = useSwitchAccount()
  const { open, setOpen: onOpen } = useContext(SwitcherContext)

  const handleSelectAccount = (account: Option) => {
    if (account.value !== actor.id) {
      switchAccount({ personId: account.value as string })
    }
  }

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: onOpen,
    whileElementsMounted: autoUpdate,
    placement: 'right-start',
    middleware: [
      offset(-10),
      flip(),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            width: `${rects.reference.width}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  const handleAddPersonal = () => {
    addPersonalAccount.mutate(
      {
        userId: person.id,
      },
      {
        onSuccess: ({ borrower }) => {
          switchAccount({ personId: borrower.id })
        },
      }
    )
  }

  const shouldEnablePersonalAccount = !person.isAccount

  return (
    <div
      className={styles.container}
      {...getReferenceProps({ ref: refs.setReference })}
    >
      <div
        className={clsx(styles.main, {
          [styles.mainSelected]: open,
        })}
      >
        <div className={styles.displayContainer}>
          <div>
            <Text className={styles.selectLabel}>Account</Text>
            <Text>{getAccountName(actor, person)}</Text>
          </div>
          {open ? (
            <Icon name={IconName.arrowLeft} size="md" key="1" />
          ) : (
            <Icon name={IconName.arrowRight} size="md" key="2" />
          )}
        </div>
      </div>
      {open && (
        <FloatingPortal>
          <div
            className={styles.list}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1,
              },
            })}
          >
            {options.map((item) => (
              <div
                className={clsx(styles.link, {
                  [styles.linkTextSelected]: actor.id === item.value,
                })}
                key={item.value}
                {...getItemProps({
                  onClick() {
                    handleSelectAccount(item)
                  },
                })}
              >
                {item.label}
                {actor.id === item.value ? (
                  <Icon
                    name={IconName.check}
                    className={styles.icon}
                    size="md"
                  />
                ) : null}
              </div>
            ))}
            <div>
              <div className={styles.divider} />
              <div
                className={clsx(styles.link, styles.createAccount)}
                {...getItemProps({
                  onClick() {
                    onAddCompany()
                  },
                })}
              >
                <div className={styles.createLine}>
                  <Icon
                    name={IconName.plus}
                    className={styles.icon}
                    size="sm"
                  />
                  Add Business Account
                </div>
              </div>
              {shouldEnablePersonalAccount ? (
                <div
                  className={clsx(styles.link, styles.createAccount)}
                  {...getItemProps({
                    onClick() {
                      handleAddPersonal()
                    },
                  })}
                >
                  <div className={styles.createLine}>
                    <Icon
                      name={IconName.plus}
                      className={styles.icon}
                      size="sm"
                    />
                    Add Personal Account
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}
export { SelectBorrowerAccount }
