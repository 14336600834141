import { useState } from 'react'
import { BorrowerAddress } from 'components/BorrowerAddress'
import { FormBorrowerEntity } from 'components/FormBorrowerEntity'
import { FundConfiguration } from 'components/FundConfiguration'
import { Address, Person } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  saving: boolean
  onSave: (values: Partial<Person>) => void
  onCancel: () => void
}

type Stage = 'information' | 'addresses' | 'fundConfiguration'
const getTitle = (stage: Stage) => {
  switch (stage) {
    case 'information':
      return 'Fund Information'
    case 'addresses':
      return 'Address'
    case 'fundConfiguration':
      return 'Fund Configuration'
  }
}
const getStep = (stage: Stage) => {
  switch (stage) {
    case 'information':
      return 1
    case 'addresses':
      return 2
    case 'fundConfiguration':
      return 3
  }
}

function ModalFund({ saving, onSave, onCancel }: Props) {
  const [stage, setStage] = useState<Stage>('information')
  const [details, setDetails] = useState<Person>()
  const [addresses, setAddresses] = useState<Address[]>()
  const [fundConfiguration, setFundConfiguration] = useState<{
    distributionCalculation: Person['distributionCalculation']
    distributionSchedule: Person['distributionSchedule']
  }>()

  const handleNext = (data: any) => {
    if (stage === 'information') {
      setDetails(data)
      setStage('addresses')
    } else if (stage === 'addresses') {
      setAddresses([data.primary, data.mailing])
      setStage('fundConfiguration')
    } else {
      onSave({
        ...details,
        emails: details?.email
          ? [
              {
                email: details.email,
                primary: true,
              },
            ]
          : [],
        status: 'draft',
        addresses: addresses?.filter((address: Address) => address.street1),
        distributionCalculation: data.distributionCalculation,
        distributionSchedule: data.distributionSchedule,
      } as Person)
    }
  }

  const handleBack = (draftData?: any) => {
    if (stage === 'addresses') {
      draftData && setAddresses(draftData)
      setStage('information')
    } else if (stage === 'fundConfiguration') {
      draftData && setFundConfiguration(draftData)
      setStage('addresses')
    }
  }

  return (
    <Modal
      title={getTitle(stage)}
      onClose={onCancel}
      className={styles.modal}
      step={{
        step: getStep(stage),
        steps: 3,
      }}
      asChild
    >
      {stage === 'information' && (
        <FormBorrowerEntity
          borrower={details}
          saveText="Next"
          isFund
          onSave={handleNext}
          onCancel={onCancel}
        />
      )}
      {stage === 'addresses' && (
        <BorrowerAddress
          addresses={addresses}
          saveText="Next"
          onBack={handleBack}
          onSave={handleNext}
          onCancel={onCancel}
        />
      )}
      {stage === 'fundConfiguration' && (
        <FundConfiguration
          fundConfiguration={fundConfiguration}
          saving={saving}
          onBack={handleBack}
          onSave={handleNext}
          onCancel={onCancel}
        />
      )}
    </Modal>
  )
}

export { ModalFund }
