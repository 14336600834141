import { isArray } from 'lodash'
import { useEffect } from 'react'
import { Route, Routes as Switch, useLocation } from 'react-router-dom'
import { MainLayout as AdminMainLayout } from 'admin/components/layout'
import { routes as adminRoutes } from 'admin/routes'
import { MainLayout as BorrowerMainLayout } from 'borrower/components/layout'
import { routes as borrowerRoutes } from 'borrower/routes'
import { PageLoader } from 'components/LoaderOverlay'
import { useAnalytics } from 'hooks/use-analytics'
import { useSession } from 'hooks/use-session'

interface Props {
  admin?: boolean
  borrower?: boolean
}

function Routes({ admin, borrower }: Props) {
  const { isLoading } = useSession()
  const location = useLocation()
  const { pageView } = useAnalytics()
  const MainLayout = admin ? AdminMainLayout : BorrowerMainLayout
  const routes = [
    ...(admin ? adminRoutes : []),
    ...(borrower ? borrowerRoutes.filter(({ isPublic }) => !isPublic) : []),
  ]

  useEffect(() => {
    pageView()
    window.scrollTo(0, 0)
  }, [location])

  return isLoading ? (
    <PageLoader />
  ) : (
    <Switch>
      <Route path="/" element={<MainLayout />}>
        {routes
          .filter(({ isCustomLayout }) => !isCustomLayout)
          .map(({ path, component: Component, props = {} }) =>
            isArray(path) ? (
              path.map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<Component {...props} />}
                />
              ))
            ) : (
              <Route
                key={path}
                path={path}
                element={<Component {...props} />}
              />
            )
          )}
      </Route>
      {routes
        .filter(({ isCustomLayout }) => !!isCustomLayout)
        .map(({ path, component: Component, props = {} }) =>
          isArray(path) ? (
            path.map((path) => (
              <Route
                key={path}
                path={path}
                element={<Component {...props} />}
              />
            ))
          ) : (
            <Route key={path} path={path} element={<Component {...props} />} />
          )
        )}
    </Switch>
  )
}

export default Routes
