import { useQuery } from '@tanstack/react-query'
import { KEY_INVESTOR_STATEMENTS } from 'constants/query-keys'
import {
  getInvestorStatements,
  getInvestorCashBalance,
  getInvestorPrincipalInvestedBalance,
} from '../services/api/investor-statements'

const useInvestorStatements = (investorId: string) => {
  return useQuery({
    queryKey: [KEY_INVESTOR_STATEMENTS, investorId, 'statement'],
    queryFn: () => getInvestorStatements(investorId),
  })
}

const useInvestorCashBalance = ({
  investorId,
  date,
}: {
  investorId: string
  date?: string
}) => {
  return useQuery({
    queryKey: [KEY_INVESTOR_STATEMENTS, investorId, 'cash-balance'],
    queryFn: () => getInvestorCashBalance({ investorId, date }),
  })
}

const useInvestorPrincipalInvestedBalance = ({
  investorId,
  date,
}: {
  investorId: string
  date?: string
}) => {
  return useQuery({
    queryKey: [
      KEY_INVESTOR_STATEMENTS,
      investorId,
      'principal-invested-balance',
    ],
    queryFn: () => getInvestorPrincipalInvestedBalance({ investorId, date }),
  })
}

export {
  useInvestorStatements,
  useInvestorCashBalance,
  useInvestorPrincipalInvestedBalance,
}
