import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { useState, useRef, useCallback } from 'react'
import { Icon, IconName } from '../Icon'
import Item from './Item'
import styles from './styles.module.scss'

interface ColumnFilterProps {
  items: {
    value: string
    label: string
  }[]
  filterValues: Array<string | number | boolean> | undefined
  onFilterValuesChange: (values: Array<string | number | boolean>) => void
}

function ColumnFilter({
  items = [],
  filterValues = [],
  onFilterValuesChange,
}: ColumnFilterProps) {
  const listRef = useRef<(HTMLDivElement | null)[]>([])
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [selectedFilters, setSelectedFilters] =
    useState<Array<string | number | boolean>>(filterValues)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: (nextOpen: boolean) => {
      setOpen(nextOpen)
    },
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [shift(), flip(), offset(4)],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useRole(context, { role: 'menu' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
      }),
    ]
  )

  const handleSelect = useCallback(
    (value: string) => {
      let filters
      if (selectedFilters.includes(value)) {
        filters = selectedFilters.filter((filterValue) => filterValue !== value)
      } else {
        filters = [...selectedFilters, value]
      }
      setSelectedFilters(filters)
      onFilterValuesChange(filters)
    },
    [selectedFilters, setSelectedFilters]
  )

  return (
    <>
      <Icon
        name={IconName.filter}
        size="md"
        className={clsx(
          styles.ellipses,
          selectedFilters.length > 0 && styles.selected
        )}
        {...getReferenceProps({ ref: refs.setReference })}
      />
      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 5,
              },
            })}
            className={styles.dropdown}
          >
            {items.length ? (
              items.map(({ value, label }, index) => (
                <Item
                  text={label}
                  active={activeIndex === index}
                  selected={selectedFilters.includes(value)}
                  key={value}
                  ref={(node: HTMLDivElement) =>
                    (listRef.current[index] = node)
                  }
                  {...getItemProps({
                    onClick: () => handleSelect(value),
                  })}
                />
              ))
            ) : (
              <div className={styles.emptyItem}>No data</div>
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

ColumnFilter.Item = Item

export default ColumnFilter
