import { request } from 'services/request'
import { Filter, Meta, Pagination, Search, Transaction, Sort } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

type FundPayment = {
  amount: number
  type: 'Deposit' | 'Withdrawal'
  date: string
  description: string
}

const getFundFundingSources = async ({
  fundId,
  filter,
  sort,
}: {
  fundId: string
  filter?: Filter
  sort?: Sort
}): Promise<any[]> => {
  const {
    data: { fundingSources },
  } = await request.get(`/fund/${fundId}/funding_source`, {
    params: updateRequestTable({ filter, sort }),
  })

  return (
    fundingSources.map((investment) => ({
      ...investment,
      rate: investment.rate * 100,
    })) || []
  )
}

const getFundTransactions = async ({
  id,
  ...params
}: {
  id: string
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Transaction[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.get(`/fund/${id}/transaction`, { params })
  return { transactions, meta: pagination }
}

const addFundPayment = (
  investorId: string,
  payload: FundPayment
): Promise<void> => {
  return request.post(`/fund/${investorId}/payment`, payload)
}

const deleteFundPayment = (paymentId: string): Promise<void> => {
  return request.delete(`/payment/${paymentId}`)
}

export type { FundPayment }

export {
  getFundFundingSources,
  getFundTransactions,
  addFundPayment,
  deleteFundPayment,
}
