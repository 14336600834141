import { request } from 'services/request'
import { Meta, Pagination } from 'types'

interface Params {
  page?: Pagination
}

export type AmortizationPayment = {
  due: string
  i: number
  interest: number
  principal: number
  principalBalance: number
  regularPayment: number
  toTrust: number
  status?: 'Paid'
  datePaid?: string
}

const getLoanSchedule = async (
  loanId: string,
  params: Params
): Promise<{ payments: AmortizationPayment[]; meta: Meta }> => {
  const {
    data: { payments, pagination },
  } = await request.get(`/loan/${loanId}/schedule`, { params })
  return {
    payments,
    meta: pagination,
  }
}

export { getLoanSchedule }
