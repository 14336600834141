import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { size } from 'lodash'
import {
  getPayouts,
  updatePayout,
  reversePayout,
  getPayoutFilters,
  payoutsCount,
  releasePayouts,
  getPayoutsNachaFileUrl,
  getPayoutsCsvFileUrl,
} from 'admin/services/api/payouts'
import { KEY_PAYOUTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Payout, Search } from 'types'
import { Filter, Pagination } from 'types/table'
import { message } from 'utils/message'

const usePayouts = (
  {
    search,
    filter,
    pagination,
  }: {
    search?: string
    filter?: Filter
    pagination?: Pagination
  } = {},
  options: { enabled: boolean } = { enabled: true }
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PAYOUTS, search, nextFilter, pagination],
    queryFn: () => getPayouts({ search, filter: nextFilter, page: pagination }),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useReversePayout = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: reversePayout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYOUTS] })
      message.success('Payout reversed')
    },
    onError: handleErrorResponse,
  })
}

const useUpdatePayout = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: {
      search?: Search
      filter?: Filter
      include?: string[]
      exclude?: string[]
      payout: Partial<Payout>
    }) => updatePayout(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYOUTS] })
    },
    onError: handleErrorResponse,
  })
}

const usePayoutFilters = (columns: string[]) => {
  return useQuery({
    queryKey: [KEY_PAYOUTS, 'filters'],
    queryFn: () => getPayoutFilters(columns),
    staleTime: 60 * 1000,
  })
}

const usePayoutsCount = (
  params: {
    search?: string
    filter?: Filter
    include: string[]
    exclude: string[]
  },
  options: { enabled: boolean }
) => {
  const nextFilter = size(params.filter) ? params.filter : undefined
  return useQuery({
    queryKey: [
      KEY_PAYOUTS,
      params.search,
      nextFilter,
      params.include.join(''),
      params.exclude.join(''),
    ],
    queryFn: () => payoutsCount(params),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useReleasePayouts = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: {
      date: string
      search?: string
      filter?: Filter
      exclude?: string[]
      include?: string[]
      transactions?: { id: string; bankingId: string | undefined | null }[]
    }) => releasePayouts(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYOUTS] })
      message.success('Payouts released')
    },
    onError: handleErrorResponse,
  })
}

const useGetPayoutNachaFile = () => {
  return useMutation({
    mutationFn: (params: {
      search?: Search
      filter?: Filter
      include: string[]
      exclude: string[]
      completed?: boolean
    }) => {
      message.success(`Generating NACHA file`, { autoClose: 1000 })
      return getPayoutsNachaFileUrl(params)
    },
    onSuccess: ({ download }) => {
      if (download) {
        window.location.href = download
      }
    },
    onError: (error: AxiosError) => {
      if (404 === error?.response?.status) {
        message.warning('No payouts had banking information')
      } else {
        handleErrorResponse(error)
      }
    },
  })
}
const useGetPayoutCsvFile = () => {
  return useMutation({
    mutationFn: (params: {
      search?: Search
      filter?: Filter
      include: string[]
      exclude: string[]
    }) => {
      message.success(`Generating CSV file...`)
      return getPayoutsCsvFileUrl(params)
    },
    onSuccess: ({ download }) => {
      if (download) {
        window.location.href = download
      }
    },
    onError: handleErrorResponse,
  })
}

export {
  usePayouts,
  useUpdatePayout,
  useReversePayout,
  usePayoutFilters,
  usePayoutsCount,
  useReleasePayouts,
  useGetPayoutNachaFile,
  useGetPayoutCsvFile,
}
