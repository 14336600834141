import clsx from 'clsx'
import { isNil } from 'lodash'
import { useEffect, useState, useRef, ImgHTMLAttributes } from 'react'
import { PageLoader } from 'components/LoaderOverlay'
import { DocumentVersion } from 'types'
import { NoPreview } from './NoPreview'
import styles from './styles.module.scss'

interface Props {
  isLocked?: boolean
  isVersionLoading: boolean
  onUpload?: () => void
  onDownload: () => void
  version?: DocumentVersion
}

const zoomRate = 1.4

function Img({
  classNameBeforeLoad,
  classNameAfterLoad,
  src,
  onLoad,
  ...rest
}: ImgHTMLAttributes<HTMLImageElement> & {
  classNameBeforeLoad?: string
  classNameAfterLoad?: string
}) {
  const [isLoaded, setLoaded] = useState<boolean>(false)

  return (
    <img
      draggable="false"
      className={isLoaded ? classNameAfterLoad : classNameBeforeLoad}
      src={src || 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='}
      onLoad={(e) => {
        if (src) {
          setLoaded(true)
          if (onLoad) {
            onLoad(e)
          }
        }
      }}
      {...rest}
    />
  )
}

export function Viewer({
  isLocked,
  isVersionLoading,
  version,
  onUpload,
  onDownload,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null)
  const [isZoom, setIsZoom] = useState(false)
  const [visibleVersion, setVisibleVersion] = useState(version)
  const [thumbnailsLoaded, setThumbnailsLoaded] = useState<boolean[]>([])
  const [fullsizesLoaded, setFullsizesLoaded] = useState<boolean[]>([])
  const [firstPageLoaded, setFirstPageLoaded] = useState<boolean>(false)

  useEffect(() => {
    setVisibleVersion(version)
    setFirstPageLoaded(false)

    setThumbnailsLoaded(
      (version?.thumbnails || []).map((value, index) => index <= 5)
    )
    setFullsizesLoaded(
      (version?.fullsize || []).map((value, index) => index <= 1)
    )
  }, [version?.id])
  const thumbnails = visibleVersion?.thumbnails || []
  const fullsizes = visibleVersion?.fullsize || []
  const zoom = () => {
    setIsZoom(!isZoom)
    if (ref.current) {
      const { scrollTop } = ref.current
      const nextScroll = isZoom ? scrollTop / 1.4 : scrollTop * 1.4
      ref.current.querySelectorAll('img').forEach((img) => {
        if (img.width) {
          img.width = isZoom ? img.naturalWidth : img.width * zoomRate
        }
      })
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollTop = nextScroll
        }
      }, 1)
    }
  }

  if (
    isVersionLoading ||
    (visibleVersion && isNil(visibleVersion?.hasThumbnails))
  ) {
    return (
      <div className={styles.viewerLoader}>
        <PageLoader />
      </div>
    )
  }

  if (!visibleVersion || !visibleVersion?.hasThumbnails) {
    return (
      <NoPreview
        isLocked={isLocked}
        isDownloadable={!!visibleVersion}
        onUpload={onUpload}
        onDownload={onDownload}
      />
    )
  }

  return (
    <div
      className={clsx({
        [styles.viewer]: firstPageLoaded,
        [styles.viewLoading]: !firstPageLoaded,
        [styles.viewerLoader]: !firstPageLoaded,
      })}
    >
      {!firstPageLoaded && <PageLoader />}
      <div className={styles.previewPanel}>
        {thumbnails.map((url, index) => (
          <a href={`#page-${index}`} key={url} draggable="false">
            <Img
              classNameBeforeLoad={styles.previewLoading}
              classNameAfterLoad={styles.preview}
              src={(thumbnailsLoaded[index] && url) || undefined}
              onLoad={() => {
                setThumbnailsLoaded(
                  thumbnailsLoaded.map(
                    (isLoaded, i) => isLoaded || index + 1 === i
                  )
                )
              }}
            />
          </a>
        ))}
      </div>
      <div className={styles.viewPanel} ref={ref}>
        {fullsizes.map((url, index) => (
          <Img
            id={`page-${index}`}
            classNameBeforeLoad={styles.view}
            classNameAfterLoad={styles.view}
            src={(fullsizesLoaded[index] && url) || undefined}
            key={url}
            style={{
              cursor: isZoom ? 'zoom-out' : 'zoom-in',
              maxWidth: isZoom ? `${100 * zoomRate}%` : '100%',
            }}
            draggable="false"
            onClick={zoom}
            onLoad={(e) => {
              if (isZoom) {
                const img = e.target as HTMLImageElement
                img.width = img.width * zoomRate
              }
              setFirstPageLoaded(true)
              setFullsizesLoaded(
                fullsizesLoaded.map(
                  (isLoaded, i) => isLoaded || index + 1 === i
                )
              )
            }}
          />
        ))}
      </div>
    </div>
  )
}
