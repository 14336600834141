import { request } from 'services/request'
import { Budget, BudgetItem, BudgetStatus, NewBudgetItem } from 'types'

const getBudget = async (
  loanId: string,
  addressId: string
): Promise<Budget> => {
  const {
    data: { budget },
  } = await request.get(`/loan/${loanId}/address/${addressId}/budget`)
  return budget
}

const addBudgetItem = async (
  loanId: string,
  addressId: string,
  item: NewBudgetItem
): Promise<Budget> => {
  const {
    data: { budget },
  } = await request.post(`/loan/${loanId}/address/${addressId}/budget`, item)
  return budget
}

const updateBudgetItem = async (
  loanId: string,
  addressId: string,
  { id: itemId, ...item }: Partial<BudgetItem & { reason?: string }>
): Promise<Budget> => {
  const {
    data: { budget },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/budget/${itemId}`,
    item
  )
  return budget
}

const updateBudgetStatus = async (
  loanId: string,
  addressId: string,
  status: BudgetStatus
): Promise<Budget> => {
  const {
    data: { budget },
  } = await request.patch(`/loan/${loanId}/address/${addressId}/budget`, {
    status,
  })
  return budget
}

const deleteBudgetItem = async (
  loanId: string,
  addressId: string,
  itemId: string
): Promise<Budget> => {
  const {
    data: { budget },
  } = await request.delete(
    `/loan/${loanId}/address/${addressId}/budget/${itemId}`
  )
  return budget
}

export {
  getBudget,
  addBudgetItem,
  updateBudgetItem,
  updateBudgetStatus,
  deleteBudgetItem,
}
