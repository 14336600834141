import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getLenderOfRecords,
  addLenderOfRecord,
  updateLenderOfRecord,
  deleteLenderOfRecord,
  getLoanLenderOfRecord,
} from 'admin/services/api/lender-of-record'
import { KEY_LENDER_OF_RECORD } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LenderOfRecord } from 'types'
import { message } from 'utils/message'

const useLenderOfRecords = (clientId: string) => {
  return useQuery({
    queryKey: [KEY_LENDER_OF_RECORD, clientId],
    queryFn: () => getLenderOfRecords(clientId),
  })
}

const useAddLenderOfRecord = (clientId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<LenderOfRecord>) =>
      addLenderOfRecord(clientId, payload),
    onSuccess: (lenderOfRecords) => {
      queryClient.setQueryData(
        [KEY_LENDER_OF_RECORD, clientId],
        lenderOfRecords
      )
      message.success('Lender of record added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLenderOfRecord = (clientId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<LenderOfRecord>) =>
      updateLenderOfRecord(clientId, payload),
    onSuccess: (lenderOfRecords) => {
      queryClient.setQueryData(
        [KEY_LENDER_OF_RECORD, clientId],
        lenderOfRecords
      )
      message.success('Lender of record updated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLenderOfRecord = (clientId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (lenderOfRecordId: string) =>
      deleteLenderOfRecord(clientId, lenderOfRecordId),
    onSuccess: (lenderOfRecords) => {
      queryClient.setQueryData(
        [KEY_LENDER_OF_RECORD, clientId],
        lenderOfRecords
      )
      message.success('Lender of record deleted')
    },
    onError: handleErrorResponse,
  })
}

const useLoanLenderOfRecord = (loanId?: string) => {
  return useQuery({
    queryKey: [KEY_LENDER_OF_RECORD, 'loan', loanId],
    queryFn: () => getLoanLenderOfRecord(loanId as string),
    enabled: !!loanId,
  })
}

export {
  useLenderOfRecords,
  useAddLenderOfRecord,
  useUpdateLenderOfRecord,
  useDeleteLenderOfRecord,
  useLoanLenderOfRecord,
}
