import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getBanking,
  updateBanking,
  updateInvestorBanking,
  updateVendorBanking,
} from 'admin/services/api/banking'
import { KEY_BANKING, KEY_INVESTORS, KEY_VENDORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Banking, BankingData } from 'types'
import { message } from 'utils/message'

export const useBanking = () => {
  return useQuery({ queryKey: [KEY_BANKING], queryFn: getBanking })
}

export const useUpdateBanking = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (banking: Banking) => updateBanking(banking),
    onSuccess: (banking) => {
      queryClient.setQueryData([KEY_BANKING], banking)
      message.success('NACHA Settings updated')
    },
    onError: handleErrorResponse,
  })
}

export const useUpdateInvestorBanking = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (banking: BankingData) => updateInvestorBanking(id, banking),
    onSuccess: (investor) => {
      queryClient.setQueryData([KEY_INVESTORS, id], investor)
      queryClient.invalidateQueries({
        queryKey: [KEY_INVESTORS, id],
      })
      message.success('Investor updated')
    },
    onError: handleErrorResponse,
  })
}

export const useUpdateVendorBanking = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (banking: BankingData) => updateVendorBanking(id, banking),
    onSuccess: (vendor) => {
      queryClient.setQueryData([KEY_VENDORS, id], vendor)
      queryClient.invalidateQueries({
        queryKey: [KEY_VENDORS, id],
      })
      message.success('Vendor updated')
    },
    onError: handleErrorResponse,
  })
}
