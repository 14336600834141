import { useEffect, useRef, useState } from 'react'

const useEffectOnceWhen = (
  callback,
  deps: any[] = [],
  predicate = () => true
) => {
  const hasBeenCalledRef = useRef(false)

  useEffect(() => {
    if (!hasBeenCalledRef.current) {
      if (predicate()) {
        callback()
        hasBeenCalledRef.current = true
      }
    }
  }, [...deps, callback, predicate])
}

const useMemoOnceWhen = (
  callback: () => any,
  deps: any[] = [],
  predicate = () => true
) => {
  const [data, setData] = useState()

  useEffectOnceWhen(
    () => {
      setData(callback())
    },
    [...deps, callback],
    predicate
  )

  return data
}

export { useEffectOnceWhen, useMemoOnceWhen }
