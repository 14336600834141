import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { FormAddress as FormAddressFields } from 'components/FormAddress'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { Address } from 'types'

interface Props {
  address?: Pick<
    Address,
    'street1' | 'street2' | 'city' | 'state' | 'zipcode' | 'country'
  >
  saving: boolean
  onBack: () => void
  onSave: (address: Partial<Address>) => void
  onCancel: () => void
}

type FormValues = Pick<
  Address,
  'street1' | 'street2' | 'city' | 'state' | 'zipcode' | 'country'
>

function FormAddress({ address, saving, onBack, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    street1: address?.street1 ?? '',
    street2: address?.street2 ?? '',
    city: address?.city ?? '',
    state: address?.state ?? '',
    zipcode: address?.zipcode ?? '',
    country: address?.country ?? '',
  }
  const handleSubmit = (values: FormValues) => {
    onSave(values)
  }

  return (
    <Formik initialValues={initialValue} onSubmit={handleSubmit}>
      <Form modal>
        <div>
          <FormAddressFields />
        </div>
        <Flex justifyContent="space-between" alignItems="center">
          <TextLink variant="invert" onClick={onBack}>
            <Icon name={IconName.arrowLeft} size="sm" />
            Back
          </TextLink>
          <Flex>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}

export { FormAddress }
