import { isString } from 'lodash'
import { useState } from 'react'
import { VariableEditor } from 'admin/components/InlineWysiwyg/VariableEditor'
import { VariableToolbarPlugin } from 'admin/components/InlineWysiwyg/plugins/VariableToolbarPlugin'
import { Option } from 'components/Select/Select'
import type { LexicalEditor } from 'lexical'

interface Props {
  subject: string
  body: string
  variables: Option[]
  onChangeSubject: (subject: string) => void
  onChangeBody: (body: string) => void
}

const NotificationEditor = ({
  subject,
  body,
  variables,
  onChangeSubject,
  onChangeBody,
}: Props) => {
  const [editor, setEditor] = useState<LexicalEditor | null>(null)

  return (
    <div className="border border-solid border-grey-200 rounded">
      <VariableEditor
        namespace="subject"
        placeholder="Add a Subject"
        className="min-h-7 font-bold"
        singleLine
        value={
          isString(subject) && subject.startsWith('<p')
            ? subject
            : `<p>${subject}</p>`
        }
        onChange={onChangeSubject}
        onFocus={(editor) => setEditor(editor)}
      />
      <div className="border border-solid border-grey-200 border-t-0 mx-3" />
      <VariableEditor
        namespace="body"
        placeholder="Enter your message here or choose from a template."
        value={body}
        onChange={onChangeBody}
        onFocus={(editor) => setEditor(editor)}
      />
      <VariableToolbarPlugin
        variables={variables}
        disabled={editor?._config.namespace === 'subject'}
        editor={editor as LexicalEditor}
      />
    </div>
  )
}

export { NotificationEditor }
