import { ColumnDef } from '@tanstack/react-table'
import { isArray, isNil } from 'lodash'
import { useMemo } from 'react'
import { LoanChangeLog } from 'admin/services/api/loan-activities'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { Field, FieldType } from 'types'
import { friendlyDateTime, friendlyDate } from 'utils/date'
import { formatField } from 'utils/fields'

type ActivitiesTableProps = {
  data?: LoanChangeLog[]
  loading: boolean
}

const formatValue = ({
  value,
  type,
  className,
}: {
  value: number | string | null
  type: FieldType
  className?: string
}) => {
  if (isNil(value)) {
    return <div className="text-grey-400 break-normal">(blank)</div>
  }
  return (
    <div className={className}>
      {formatField({ property: { value: [value] }, type: [type] } as Field)}
    </div>
  )
}

function logDescription(row) {
  const { description, value, fieldName, modificationDateEffective } = row

  if (description.includes('created loan modification')) {
    return `Created Loan Modification on ${friendlyDate(modificationDateEffective)}`
  }
  if (description.includes('deleted loan modification')) {
    return `Deleted Loan Modification on ${friendlyDate(modificationDateEffective)}`
  }
  if (description.includes('updated loan modification') && value && fieldName) {
    return `${fieldName} (Loan Modification on ${friendlyDate(modificationDateEffective)})`
  }
  if (description.includes('updated loan modification')) {
    return `Updated Loan Modification on ${friendlyDate(modificationDateEffective)}`
  }

  return value && fieldName ? fieldName : description
}

function TableActivities({ data = [], loading }: ActivitiesTableProps) {
  const columns: ColumnDef<LoanChangeLog>[] = useMemo(
    () => [
      {
        header: 'Timestamp',
        accessorKey: 'createdAt',
        size: 190,
        cell: ({ getValue }) => friendlyDateTime(getValue() as string),
      },
      {
        header: 'User',
        accessorKey: 'byName',
        size: 50,
        cell: ({ row }) => {
          const { byName, adminId, personId } = row.original
          return byName ? (
            <Tooltip content={byName}>
              <Avatar id={(adminId || personId) as string} name={byName} />
            </Tooltip>
          ) : (
            <Tooltip content="Unknown">
              <Avatar background="#d9d9da" />
            </Tooltip>
          )
        },
      },
      {
        header: 'Reference',
        accessorKey: 'description',
        cell: ({ row }) => logDescription(row.original),
      },
      {
        header: '',
        id: 'value',
        cell: ({ row }) => {
          const { value, type } = row.original
          if (isArray(value)) {
            return (
              <Flex
                gap={16}
                alignItems="center"
                justifyContent="flex-end"
                className="normal-nums"
              >
                {formatValue({
                  value: value?.[0],
                  type,
                  className: 'text-grey-700 break-normal',
                })}
                <Icon
                  name={IconName.arrowRightLong}
                  size="md"
                  className="text-grey-600 font-slim flex-shrink-0"
                />
                {formatValue({
                  value: value?.[1],
                  type,
                  className: 'font-bold text-grey-900',
                })}
              </Flex>
            )
          }
          return ''
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableActivities
