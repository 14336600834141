import { useQuery } from '@tanstack/react-query'
import { KEY_LOAN_STATEMENTS } from 'constants/query-keys'
import {
  getLoanStatements,
  getLoanStatement,
} from '../services/api/loan-statements'

const useLoanStatements = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_STATEMENTS, loanId],
    queryFn: () => getLoanStatements(loanId),
  })
}

const useLoanStatement = (loanId: string, statementId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_STATEMENTS, loanId, statementId],
    queryFn: () => getLoanStatement(loanId, statementId),
  })
}

export { useLoanStatements, useLoanStatement }
