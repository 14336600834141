import { Flex } from '../Flex'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'
import { Badge } from './index'

interface Props {
  type: 'open' | 'closed'
}

function OfferingTypeBadge({ type }: Props) {
  return (
    <Badge color={type === 'open' ? 'green' : 'gray'}>
      <Flex gap={6} alignItems="center" className={styles.inlineFlex}>
        <Icon
          name={type === 'open' ? IconName.active : IconName.check}
          size="sm"
        />
        {type === 'open' ? 'Current' : 'Completed'}
      </Flex>
    </Badge>
  )
}

export { OfferingTypeBadge }
