import { useCallback, useState } from 'react'

const useModalImage = () => {
  const [isImagesModalVisible, setIsImagesModalVisible] = useState(false)
  const [imageUrls, setImageUrls] = useState([])
  const [imageIndex, setImageIndex] = useState(0)
  const handleImageClick = useCallback((e, imageUrls, imageUrl = '') => {
    setImageUrls(imageUrls)
    setImageIndex(imageUrl ? imageUrls.findIndex((url) => url === imageUrl) : 0)
    setIsImagesModalVisible(true)
    e.stopPropagation()
  }, [])
  const handleClose = useCallback(() => {
    setImageUrls([])
    setImageIndex(0)
    setIsImagesModalVisible(false)
  }, [])

  return {
    isImagesModalVisible,
    imageUrls,
    imageIndex,
    handleImageClick,
    handleClose,
  }
}

export { useModalImage }
