import { isString } from 'lodash'
import { useCallback, useState } from 'react'
import { SelectVendor } from 'admin/components/SelectVendor'
import { useAddVendor } from 'admin/hooks/use-vendors'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor'
import { Vendor } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DatePicker'
import { Flex } from 'components/Flex'
import { InputCurrency } from 'components/InputCurrency'
import { Modal } from 'components/Modal'
import { NoteField } from 'components/NoteField'
import { Loan } from 'types'

interface Props {
  meta?: Loan['meta']
  saving: boolean
  onSave: (values: {
    sold: {
      date: string
      toId: string | undefined
      toName: string | undefined
      amount: string
      note: string
    }
  }) => void
  onCancel: () => void
}

function ModalMarkAsSold({ meta, saving, onSave, onCancel }: Props) {
  const [addingVendorName, setAddingVendorName] = useState<string | false>(
    false
  )
  const [date, setDate] = useState<string>(meta?.sold?.date ?? '')
  const [amount, setAmount] = useState<string>(meta?.sold?.amount ?? '')
  const [toId, setToId] = useState<string | undefined>(meta?.sold?.toId)
  const [toName, setToName] = useState<string | undefined>(meta?.sold?.toName)
  const [note, setNote] = useState<string>(meta?.sold?.note ?? '')

  const { mutate: addVendor, isPending: isVendorAdding } = useAddVendor()

  const handleSave = useCallback(() => {
    onSave({
      sold: {
        date,
        toId,
        toName,
        amount,
        note,
      },
    })
  }, [date, toId, toName, amount, note])

  return (
    <>
      <Modal
        title="Mark Loan as Sold"
        onClose={onCancel}
        className="w-modal"
        focusFirstInput={false}
      >
        <Flex stack gap={16}>
          <Flex stack gap={6}>
            <div>Date of Sale</div>
            <DatePicker value={date} onChange={setDate} />
          </Flex>
          <Flex stack gap={6}>
            <div>Realized Gain/Loss on Sale</div>
            <InputCurrency
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Flex>
          <Flex stack gap={6}>
            <div>Sold to</div>
            <SelectVendor
              value={toId}
              onSelect={(id, name) => {
                setToId(id)
                setToName(name)
              }}
              onCreate={(text) => setAddingVendorName(text)}
            />
          </Flex>
          <NoteField value={note} onChange={setNote} />

          <Flex justifyContent="flex-end" gap={10}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} onClick={handleSave}>
              Mark as Sold
            </Button>
          </Flex>
        </Flex>
      </Modal>
      {isString(addingVendorName) && (
        <ModalAddVendor
          saving={isVendorAdding}
          onSave={(vendor) =>
            addVendor(vendor as Omit<Vendor, 'id'>, {
              onSuccess: ({ id, name }) => {
                setToId(id)
                setToName(name)
                setAddingVendorName(false)
              },
            })
          }
          onCancel={() => {
            setAddingVendorName(false)
          }}
        />
      )}
    </>
  )
}

export { ModalMarkAsSold }
