import clsx from 'clsx'
import { compact, isString } from 'lodash'
import ReactSelect from 'react-select'
import { DatePicker } from 'components/DatePicker'
import { Option } from 'components/Form'
import { IconInput } from 'components/IconInput'
import { InputPhone } from 'components/InputPhone'
import { Textarea } from 'components/Textarea'
import { Field, FieldType } from 'types'
import styles from './styles.module.scss'

interface Props {
  value: string | number | null | undefined
  type: FieldType
  mask?: Field['mask']
  options?: Option[] | null
  hideEmptyOption?: boolean
  className?: string
  maxLength?: number
  autoFocus?: boolean
  mode?: 'hover'
  multiline?: boolean
  // uses for type percentage to support up to 10 decimals
  fullAccuracy?: boolean
  onChange?: (value: string) => void
  onBlur?: (value?: string) => void
  onEscPress?: () => void
  onEnterPress?: () => void
  onOpenChange?: (isOpen: boolean) => void
  loading?: boolean
}

function InlineField({
  value,
  mode,
  mask,
  options,
  hideEmptyOption,
  type,
  maxLength,
  className,
  autoFocus,
  multiline,
  fullAccuracy,
  onChange,
  onBlur,
  onEscPress,
  onEnterPress,
  onOpenChange,
  loading,
}: Props) {
  switch (type) {
    case 'option':
    case 'yes-no': {
      const selectOptions: Option[] =
        type === 'yes-no'
          ? [
              { label: 'Yes', value: 'Yes' },
              { label: 'No', value: 'No' },
            ]
          : (options as unknown as Option[])
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <ReactSelect
            classNamePrefix="formFieldSelect"
            placeholder="Select..."
            menuPortalTarget={document.getElementById('select-portal')}
            isLoading={loading}
            options={compact([
              hideEmptyOption ? undefined : { label: '(blank)', value: null },
              ...(selectOptions || []),
            ])}
            value={
              (selectOptions || []).find((option) => option.value === value) ||
              (hideEmptyOption
                ? undefined
                : {
                    label: '(blank)',
                    value: null,
                  })
            }
            onChange={(option) => {
              const value = option && (isString(option) ? option : option.value)
              onChange?.(value as string)
              onBlur?.(value as string)
            }}
            onBlur={() => {
              onChange?.(value as string)
              onBlur?.(value as string)
            }}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                onEscPress?.()
                e.preventDefault()
              }
            }}
            onMenuClose={() => onOpenChange?.(false)}
            onMenuOpen={() => onOpenChange?.(true)}
            autoFocus={autoFocus}
            openMenuOnFocus
          />
        </div>
      )
    }
    case 'date':
      return (
        <DatePicker
          value={value as string}
          className={clsx(styles.input, className)}
          onChange={(value) => onChange?.(value)}
          onSelect={() => onBlur?.()}
          onBlur={() => onBlur?.()}
          onCalendarClose={() => onBlur?.()}
          onKeyDown={(e) => {
            if (e.key === 'Tab' && onBlur) {
              setTimeout(onBlur, 1)
            }
            if (e.code === 'Escape') {
              onEscPress?.()
              e.preventDefault()
            }
          }}
          onFocus={() => {
            // @todo temp fix for calendar extra offset
            window.scrollTo(window.scrollX, window.scrollY + 1)
            window.scrollTo(window.scrollX, window.scrollY - 1)
          }}
          autoFocus={autoFocus}
        />
      )
    case 'phone':
      return (
        <InputPhone
          className={clsx(styles.input, className)}
          value={value || ''}
          autoFocus={autoFocus}
          onChange={(e) => onChange?.(e.target.value)}
          onBlur={() => onBlur?.()}
        />
      )
    case 'multiline':
      return (
        <Textarea
          className={className}
          value={value ?? ''}
          autoFocus={autoFocus}
          onFocus={(e) => {
            autoFocus && setTimeout(() => e.target.select(), 1)
          }}
          maxLength={maxLength}
          onChange={(e) => onChange?.(e.target.value)}
          onBlur={() => onBlur?.()}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              onEscPress?.()
              e.preventDefault()
            } else if (!e.shiftKey && e.code === 'Enter') {
              onEnterPress?.()
              e.preventDefault()
            }
          }}
        />
      )
    case 'document':
      return <div />
    default:
      return type === 'text' && multiline ? (
        <Textarea
          className={className}
          value={value ?? ''}
          autoFocus={autoFocus}
          onFocus={(e) => {
            autoFocus && setTimeout(() => e.target.select(), 1)
          }}
          maxLength={maxLength}
          onChange={(e) => onChange?.(e.target.value)}
          onBlur={() => onBlur?.()}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              onEscPress?.()
              e.preventDefault()
            } else if (!e.shiftKey && e.code === 'Enter') {
              onEnterPress?.()
              e.preventDefault()
            }
          }}
        />
      ) : (
        <IconInput
          className={clsx(styles.input, className)}
          type={
            type === 'percentage' && mode === 'hover'
              ? 'percentage-hover'
              : type
          }
          value={value ?? ''}
          mask={mask}
          autoFocus={autoFocus}
          onFocus={(e) => {
            autoFocus && setTimeout(() => e.target.select(), 1)
          }}
          maxLength={maxLength}
          fullAccuracy={fullAccuracy}
          onChange={(e) => onChange?.(e.target.value)}
          onBlur={() => onBlur?.()}
          onKeyDown={(e) => {
            if (e.code === 'Escape') {
              onEscPress?.()
              e.preventDefault()
            } else if (e.code === 'Enter') {
              onEnterPress?.()
              e.preventDefault()
            }
          }}
        />
      )
  }
}

export default InlineField
