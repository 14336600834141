import { request } from 'services/request'
import { Loan } from './loans'

const getSizerDownloadUrl = async (
  productId: string
): Promise<{ url: string }> => {
  const {
    data: { url },
  } = await request.get(`/product/${productId}/sizer`)
  return { url }
}

const getSizerUploadUrl = async (
  productId: string
): Promise<{ url: string }> => {
  const {
    data: { uploadUrl: url },
  } = await request.post(`/product/${productId}/sizer`)
  return { url }
}

const runSizer = async (loanId: string): Promise<{ loan: Loan }> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${loanId}/sizer`)
  return { loan }
}

const deleteSizer = async (productId: string): Promise<boolean> => {
  await request.delete(`/product/${productId}/sizer`)
  return true
}

const getSizerRuns = async (productId: string): Promise<{ runs: any }> => {
  const {
    data: { runs },
  } = await request.get(`/product/${productId}/sizer/runs`)
  return { runs }
}

export {
  getSizerDownloadUrl,
  getSizerUploadUrl,
  runSizer,
  deleteSizer,
  getSizerRuns,
}
