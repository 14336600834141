import { Transaction } from 'types'

export const getFundingType = (transaction: Transaction, reversed = false) => {
  const { gl: code, amount } = transaction
  const isFund = transaction.fundId

  if (!isFund) {
    let type = 'Other'
    if (['1008', '1010', '0001', '1001'].includes(code)) {
      type = amount >= 0 ? 'Returned Capital' : 'Investment'
    } else if (['1004', '1007'].includes(code)) {
      type = 'Interest'
    } else if (['1011', '1006'].includes(code)) {
      return 'Spread'
    } else if (['0008', '0018'].includes(code)) {
      return 'Charge'
    }
    switch (transaction.payment?.type) {
      case 'Transfer':
        if (amount >= 0) {
          return 'Returned Capital'
        } else {
          return 'Investment'
        }
      case 'Funding':
        return 'Investment'
      case 'Withdrawal':
      case 'Deposit':
        return transaction.payment?.type
    }
    return type
  }
  // Funds
  else {
    switch (code) {
      case '1001':
        return (reversed ? -1 * amount : amount) >= 0
          ? 'Returned Capital'
          : 'Investment'
      case '1010':
        return (reversed ? -1 * amount : amount) >= 0
          ? 'Returned Capital'
          : 'DRIP'
      case '1007':
        return 'Income'
      default:
        return 'Other'
    }
  }
}
