import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface SideNavigationLinkProps {
  icon?: IconName
  to?: string
  onClick?: () => void
  label: string
  hideActive?: boolean
}

function SideNavigationLink({
  icon,
  to,
  onClick = () => {},
  label,
  hideActive,
}: SideNavigationLinkProps) {
  return to ? (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.link, {
          [styles.selected]: isActive && !hideActive,
        })
      }
      to={to}
      onClick={onClick}
    >
      {icon && <Icon name={icon} size="md" />}
      {label}
    </NavLink>
  ) : (
    <div onClick={onClick} className={styles.link}>
      {icon && <Icon name={icon} size="md" />}
      {label}
    </div>
  )
}

export type { SideNavigationLinkProps }
export default SideNavigationLink
