const canvas = document.createElement('canvas')

function getCssStyle(element, prop) {
  return window.getComputedStyle(element, null).getPropertyValue(prop)
}

function getCanvasFont() {
  const fontWeight = 'bold'
  getCssStyle(document.body, 'font-weight')
  const fontSize = '14px'
  getCssStyle(document.body, 'font-size')
  const fontFamily = getCssStyle(document.body, 'font-family')

  return `${fontWeight} ${fontSize} ${fontFamily}`
}

function getTextWidth(text: string) {
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  context.font = getCanvasFont()
  const metrics = context.measureText(text)
  return metrics.width
}

export { getTextWidth }
