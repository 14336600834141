import { isNumber } from 'lodash'
import { useState } from 'react'
import { useAddBorrower } from 'admin/hooks/use-borrowers'
import {
  useAddQuoteGuarantor,
  useRemoveQuoteGuarantor,
  useUpdateQuotePerson,
} from 'admin/hooks/use-quote-person'
import { Borrower } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { ModalChooseBorrower } from 'components/Modal/ChooseBorrower'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Person, Quote } from 'types'
import { includeAttributes } from 'utils/includeAttributes'
import { EntityDetails } from './EntitiyDetails'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
  guarantors: Person[]
}

const PanelGuarantor = ({ quote, guarantors }: Props) => {
  const [editingGuarantorIndex, setEditingGuarantorIndex] = useState<
    number | null
  >(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAddingPerson, setIsAddingPerson] = useState(false)
  const [personName, setPersonName] = useState('')
  const [addedPersonId, setAddedPersonId] = useState('')

  const addBorrower = useAddBorrower()

  const { mutate: addGuarantor, isPending: saving } = useAddQuoteGuarantor(
    quote.id
  )
  const { mutate: deleteGuarantor, isPending: deleting } =
    useRemoveQuoteGuarantor(quote.id)
  const { mutate: updatePerson, isPending: savingPerson } =
    useUpdateQuotePerson(quote.id)
  const handleAddGuarantor = () => {
    setIsModalVisible(true)
  }

  const handleCreatePerson = (personName?: string) => {
    setIsModalVisible(false)
    setPersonName(personName ?? '')
    setIsAddingPerson(true)
  }

  const entityIncludes: string[] = includeAttributes({
    loanType: quote.loanType,
  })

  return (
    <Panel title="Guarantor">
      <div className={styles.entities}>
        {guarantors.length === 0 && (
          <div>Please add at least one guarantor</div>
        )}
        {guarantors.map((borrower, index) => (
          <div key={borrower.id}>
            <div className={styles.entityInfoHeader}>
              <Header variant="h4">
                {index === 0 ? 'Primary Guarantor' : 'Co-guarantor'}
              </Header>
              <Flex gap={8}>
                <Button
                  size="small"
                  onClick={() => setEditingGuarantorIndex(index)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  variant="tertiary"
                  disabled={deleting}
                  onClick={() =>
                    deleteGuarantor(
                      { id: borrower.id },
                      {
                        onSuccess: () => {
                          setIsModalVisible(false)
                        },
                      }
                    )
                  }
                >
                  Remove
                </Button>
              </Flex>
            </div>
            <EntityDetails entity={borrower} include={entityIncludes} />
          </div>
        ))}
      </div>
      <div className={styles.addLinkBox}>
        <TextLink onClick={() => handleAddGuarantor()}>
          <Icon name={IconName.plus} size="sm" />
          Add Guarantor
        </TextLink>
      </div>
      {isModalVisible && (
        <ModalChooseBorrower
          entity="Guarantor"
          saving={saving}
          include={entityIncludes}
          onSave={({ borrower }) =>
            addGuarantor(
              {
                id: borrower,
              },
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                },
              }
            )
          }
          onCancel={() => setIsModalVisible(false)}
          handleAddBorrower={(personName?: string) =>
            handleCreatePerson(personName)
          }
          addedPersonId={addedPersonId}
        />
      )}
      {isAddingPerson && (
        <ModalAddPerson
          saving={addBorrower.isPending}
          personName={personName}
          include={entityIncludes}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            addBorrower.mutate(borrower, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
                setIsModalVisible(true)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
            setIsModalVisible(true)
          }}
        />
      )}
      {isNumber(editingGuarantorIndex) && (
        <ModalPersonInfo
          personType="borrower"
          person={guarantors[editingGuarantorIndex]}
          saving={savingPerson}
          include={entityIncludes}
          exclude={['isAccount']}
          onSave={(values) => {
            updatePerson(
              { ...values, id: guarantors[editingGuarantorIndex].id },
              {
                onSuccess: () => {
                  setEditingGuarantorIndex(null)
                },
              }
            )
          }}
          onCancel={() => setEditingGuarantorIndex(null)}
        />
      )}
    </Panel>
  )
}

export { PanelGuarantor }
