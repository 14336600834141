import { Formik } from 'formik'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { ErrorMessage } from 'components/ErrorMessage'
import { Flex } from 'components/Flex'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  loan: Loan
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalTransferLoan({ loan, saving, onSave, onCancel }: Props) {
  const { user } = useSession()
  const isShared = !!loan.shared?.length
  const isBroker = isShared && loan.clientId === user?.client.id
  const title = isBroker
    ? 'Transfer ownership'
    : 'Request transfer of ownership'
  const warning = isBroker
    ? `Once a transfer is accepted by ${loan.product.client.name}, it can't be undone.`
    : "Once a transfer is accepted, it can't be undone."
  const text = isBroker
    ? `You can transfer this loan and its content to ${loan.product.client.name} so they can manage it moving forward.`
    : `You can transfer ownership of this loan and its associated contents to ${loan.product.client.name} for future management.`
  const users = isBroker
    ? [{ id: loan.product.clientId, name: loan.product.client.name }]
    : loan.owners
  const buttonText = isBroker ? 'Transfer' : 'Request Transfer'

  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal title={title} onClose={onCancel} className={styles.modal}>
      <Flex stack gap={16}>
        <ErrorMessage title="">{warning}</ErrorMessage>
        <div>{text}</div>
        <Flex stack gap={8}>
          <Header variant="h4">
            {isBroker ? 'Transferring to' : 'Requesting transfer from'}
          </Header>
          {users?.map(({ id, name }) => (
            <Flex key={id} gap={8} alignItems="center">
              <Avatar id={id} name={name} />
              <div>{name}</div>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <Formik
        initialValues={initialValue}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Textarea
                name="message"
                placeholder="Add a message if you’d like"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                {buttonText}
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalTransferLoan
