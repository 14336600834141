import { useCallback } from 'react'
import { useLoanStatements } from 'admin/hooks/use-loan-statement'
import { pathTo } from 'admin/path-to'
import { Panel } from 'components/Panel'
import { TableStatements } from 'components/TableStatements'
import { useMobile } from 'hooks/use-mobile'
import { Loan } from 'types'
import { downloadPdf } from 'utils/pdf'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabStatements({ loan }: Props) {
  const { data: statements, isPending } = useLoanStatements(loan.id)
  const { isTablet } = useMobile()
  const handleClick = useCallback(
    (id) => {
      if (isTablet) {
        downloadPdf({
          url: pathTo('statement', loan.id, id),
          fileName: 'Loan-Statement',
        })
      } else {
        window.open(`${pathTo('statement', loan.id, id)}`, '_blank')
      }
    },
    [isTablet]
  )

  return (
    <div className={styles.tabContent}>
      <Panel title="Statements">
        <TableStatements
          statements={statements}
          loading={isPending}
          onClick={handleClick}
          onDownload={(id) =>
            downloadPdf({
              url: pathTo('statement', loan.id, id),
              fileName: 'Loan-Statement',
            })
          }
        />
      </Panel>
    </div>
  )
}
