import { DragEvent, useCallback, useState } from 'react'
import { useClientImage, useAddClientImage } from 'admin/hooks/use-client-image'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import { openBrowseFile } from 'utils/file'

interface Props {
  readPropertyKey: 'url' | 'logoUrl' | 'statementUrl'
  uploadPropertyKey: 'uploadIconUrl' | 'uploadUrl' | 'uploadStatementUrl'
  entity: string
}

function ClientImage({ readPropertyKey, uploadPropertyKey, entity }: Props) {
  const session = useSession()
  const { user } = session

  const [isDragActive, setIsDragActive] = useState(false)
  const { data: image } = useClientImage(user?.client.id as string)
  const { mutate: upload } = useAddClientImage(
    user?.client.id as string,
    image?.[uploadPropertyKey] as string
  )
  const imageUrl =
    image?.client?.image?.[readPropertyKey] ||
    user?.client.image?.[readPropertyKey]
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        upload(files, {
          onSuccess: () => {
            session.refreshUser()
          },
        })
      },
      accept: '.png,.svg',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files, {
      onSuccess: () => {
        session.refreshUser()
      },
    })
  }

  const DropZone = useCallback(
    () => (
      <DragDropFile
        onLeave={() => setIsDragActive(false)}
        onDrop={handleDrop}
        accept={['png', 'svg']}
      >
        <Flex stack gap={8} alignItems="center">
          <Icon
            name={IconName.uploadCloud}
            size="xl"
            className="text-blue-200"
          />
          <Text className="px-2 text-center">
            Drag and drop a PNG, or <TextLink>browse</TextLink>
          </Text>
        </Flex>
      </DragDropFile>
    ),
    [handleDrop]
  )

  return (
    <Flex
      stack={readPropertyKey !== 'logoUrl'}
      alignItems={readPropertyKey === 'logoUrl' ? 'center' : 'flex-start'}
      gap={readPropertyKey === 'logoUrl' ? 10 : 20}
    >
      {readPropertyKey !== 'logoUrl' && (
        <div
          className="bg-center bg-no-repeat bg-contain bg-grey-50 relative p-4 w-80 h-40 rounded-lg border border-dashed border-grey-400"
          onDragEnter={handleDrag}
          style={{ backgroundImage: `url(${imageUrl})` }}
          onClick={handleUpload}
        >
          {isDragActive ? <DropZone /> : null}
          {!imageUrl && (
            <Flex
              alignItems="center"
              justifyContent="center"
              className="h-full"
            >
              <Button variant="secondary">Add {entity}</Button>
            </Flex>
          )}
        </div>
      )}
      {readPropertyKey === 'logoUrl' && (
        <Flex
          justifyContent="center"
          alignItems="center"
          className={`bg-center bg-no-repeat bg-cover ${imageUrl ? '' : 'bg-purple-300'} relative p-4 w-[30px] h-[30px] rounded text-white-100 font-bold`}
          onDragEnter={handleDrag}
          style={{ backgroundImage: `url(${imageUrl})` }}
          onClick={handleUpload}
        >
          {imageUrl ? '' : user?.client?.name.charAt(0).toUpperCase()}
        </Flex>
      )}
      {imageUrl && (
        <div>
          <Button variant="secondary" onClick={handleUpload}>
            Edit {entity}
          </Button>
        </div>
      )}
      {!imageUrl && readPropertyKey === 'logoUrl' && (
        <div>
          <Button variant="secondary" onClick={handleUpload}>
            Add {entity}
          </Button>
        </div>
      )}
    </Flex>
  )
}

export { ClientImage }
