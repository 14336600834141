import { request } from 'services/request'
import { Payment } from 'types'

const getLoanPayments = async (loanId: string): Promise<Payment[]> => {
  const {
    data: { payments },
  } = await request.get(`/loan/${loanId}/payment`)
  return payments
}

export { getLoanPayments }
