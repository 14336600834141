import {
  useAddInvestor,
  useInvestorLinkAccount,
  useInvestorUpdateLinkAccount,
  useInvestors,
  useInvestorUnlinkAccount,
} from 'admin/hooks/use-investors'
import { InvestorDetails } from 'admin/services/api/investors'
import { PanelLinkedAccounts } from 'components/Panel/PanelLinkedAccounts'

interface Props {
  investor: InvestorDetails
}

function PanelInvestorLinkedAccounts({ investor }: Props) {
  const add = useInvestorLinkAccount({ investorId: investor.id })
  const update = useInvestorUpdateLinkAccount({ investorId: investor.id })
  const remove = useInvestorUnlinkAccount({ investorId: investor.id })
  const addPerson = useAddInvestor()
  const availableAccounts = useInvestors({
    filter: {
      type: [investor.type === 'individual' ? 'entity' : 'individual'],
      isFund: null,
    },
  })

  return (
    <PanelLinkedAccounts
      person={investor}
      availableAccounts={availableAccounts.data?.investors || []}
      addPerson={addPerson}
      addLink={add}
      updateLink={update}
      removeLink={remove}
      type="investor"
    />
  )
}

export default PanelInvestorLinkedAccounts
