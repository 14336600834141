import { useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { components, GroupBase, MenuProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { fetchBorrowers } from 'admin/hooks/use-borrowers'
import { Borrower } from 'admin/services/api/borrowers'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Option } from 'components/Select'
import { CreateOption } from 'components/Select/CreateOption'

interface Props {
  defaultOptions: Borrower[] | undefined
  value?: any
  className?: string
  entity: string
  onSelect: (id: string) => void
  onCreate: (name: string) => void
}

const borrowersToOptions = (borrowers: Borrower[]) =>
  borrowers.map(({ name, id, associatedWith }) => ({
    label: <AssociatedLabels name={name} associatedWith={associatedWith} />,
    value: id,
  }))

export const SelectBorrower = ({
  defaultOptions,
  value,
  className,
  entity,
  onSelect,
  onCreate,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<any>()
  const queryClient = useQueryClient()
  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      fetchBorrowers(queryClient, {
        search: { name: inputValue },
      }).then(({ borrowers }) => callback(borrowersToOptions(borrowers)))
    }, 500),
    []
  )

  useEffect(() => {
    if (value && value !== selectedOption?.value) {
      fetchBorrowers(queryClient, {
        filter: { id: value },
      }).then(({ borrowers }) => {
        setSelectedOption(borrowersToOptions(borrowers)[0])
      })
    }
  }, [value])

  const Menu = useCallback(
    (props: MenuProps<unknown, boolean, GroupBase<unknown>>) => (
      <div className="absolute top-full w-full z-1">
        <components.Menu className="!relative" {...props}>
          {props.children}
        </components.Menu>
        <CreateOption onClick={() => onCreate(inputValue)}>
          <Flex
            gap={4}
            alignItems="center"
            className="py-2 font-bold text-blue-200 hover:text-black-100"
          >
            <Icon name={IconName.plus} size="sm" />
            Create a new {entity}
          </Flex>
        </CreateOption>
      </div>
    ),
    [inputValue]
  )

  return (
    <AsyncSelect
      classNamePrefix="formFieldSelect"
      className={className}
      value={selectedOption}
      placeholder="Select..."
      menuShouldBlockScroll
      menuPortalTarget={document.getElementById('select-portal')}
      loadOptions={loadSuggestedOptions}
      defaultOptions={borrowersToOptions(defaultOptions || [])}
      components={{ Menu }}
      noOptionsMessage={() => <>None found</>}
      onInputChange={(newValue) => setInputValue(newValue)}
      onChange={(option) => {
        setSelectedOption(option)
        onSelect((option as Option)?.value as string)
      }}
    />
  )
}
