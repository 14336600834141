import clsx from 'clsx'
import { Text } from 'components/Text'

interface Props {
  valueLength: number
  maxLength: string
  error: boolean
}

export const Counter = ({ valueLength, maxLength, error }: Props) => {
  return (
    <Text
      className={clsx('text-grey-600', {
        'text-red-100': error,
      })}
    >{`${valueLength}/${maxLength} Characters Remaining`}</Text>
  )
}
