import { useEffect, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { LoanFundingSource } from 'types'
import { PanelMyInvestmentsItem } from './PanelMyInvestmentsItem'

interface Props {
  fundingSources: LoanFundingSource[]
}

function PanelMyInvestments({ fundingSources }: Props) {
  const [tab, setTab] = useState<'performing' | 'liquidated'>()
  const [performingInvestments, setPerformingInvestments] = useState<
    LoanFundingSource[]
  >([])
  const [liquidatedInvestments, setLiquidatedInvestments] = useState<
    LoanFundingSource[]
  >([])

  useEffect(() => {
    const liquidatedItems =
      fundingSources.filter(
        ({ dateFunded, amount, loan }) =>
          (dateFunded && amount === 0) || loan?.status === 'liquidated'
      ) || []
    const performingItems = fundingSources.filter(
      (investment) => !liquidatedItems.includes(investment)
    )
    setPerformingInvestments(performingItems)
    setLiquidatedInvestments(liquidatedItems)
  }, [fundingSources])

  useEffect(() => {
    if (!tab && performingInvestments.length > 0) {
      setTab('performing')
    } else if (!tab && liquidatedInvestments.length > 0) {
      setTab('liquidated')
    }
  }, [tab, performingInvestments, liquidatedInvestments])

  const isTabsVisible =
    liquidatedInvestments.length > 0 && performingInvestments.length > 0
  const investments =
    tab === 'performing' ? performingInvestments : liquidatedInvestments

  return (
    <Flex stack gap={20}>
      <Header variant="h3">My Investments</Header>

      {isTabsVisible && (
        <Flex gap={8}>
          <Button
            variant="panel"
            active={tab === 'performing'}
            onClick={() => setTab('performing')}
          >
            Performing
          </Button>
          <Button
            variant="panel"
            active={tab === 'liquidated'}
            onClick={() => setTab('liquidated')}
          >
            Liquidated
          </Button>
        </Flex>
      )}
      {investments.map((investment, index) => (
        <PanelMyInvestmentsItem
          key={investment.id}
          fundingSource={investment}
          index={index}
        />
      ))}
    </Flex>
  )
}

export { PanelMyInvestments }
