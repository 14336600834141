import { request } from 'services/request'
import { Quote, Address } from 'types'
import { updateResponseQuote } from 'utils/api/quote-parser'

const addQuoteAddress = async ({
  quoteId,
  address,
}: {
  quoteId: string
  address: Address
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.put(`quote/${quoteId}/address`, address)
  return updateResponseQuote(quote)
}

export { addQuoteAddress }
