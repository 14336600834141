import { compact } from 'lodash'
import { useState } from 'react'
import { useAddInvestor } from 'admin/hooks/use-investors'
import { useAddVendor } from 'admin/hooks/use-vendors'
import { StepChooseType } from 'admin/pages/LoanSpreadAllocation/ModalRecipient/StepChooseType'
import {
  Recipient,
  Step,
} from 'admin/pages/LoanSpreadAllocation/ModalRecipient/types'
import { FormVendor } from 'admin/pages/Vendors/ModalAddVendor/FormVendor'
import { Investor } from 'admin/services/api/investors'
import { Vendor } from 'admin/services/api/vendors'
import { FormBorrowerEntity } from 'components/FormBorrowerEntity'
import { FormBorrowerIndividual } from 'components/FormBorrowerIndividual'
import { Modal } from 'components/Modal'
import { PersonType } from 'types'
import { StepSelect } from './StepSelect'

interface Props {
  title: string
  loading?: boolean
  paymentWarningVisible?: boolean
  onSelect: ({ id, name }: Recipient) => void
  onClose: () => void
}

const ModalDefaultRecipient = ({
  title,
  paymentWarningVisible,
  loading,
  onSelect,
  onClose,
}: Props) => {
  const [selectedRecipient, setSelectedRecipient] = useState<Recipient>()
  const [step, setStep] = useState<Step>('select')
  const [recipientType, setRecipientType] = useState<PersonType>()
  const { mutate: addVendor, isPending: isVendorAdding } = useAddVendor()
  const { mutate: addInvestor, isPending: isInvestorAdding } = useAddInvestor()

  return (
    <Modal
      title={step === 'select' ? title : 'Create Recipient'}
      onClose={onClose}
      asChild
    >
      {step === 'select' && (
        <StepSelect
          loading={loading}
          paymentWarningVisible={paymentWarningVisible}
          recipient={selectedRecipient}
          onSelect={setSelectedRecipient}
          onAddClick={() => onSelect(selectedRecipient as Recipient)}
          onCreateClick={() => setStep('choose-type')}
          onCloseClick={onClose}
        />
      )}
      {step === 'choose-type' && (
        <StepChooseType
          onBackClick={() => setStep('select')}
          onNextClick={({ type, personType }) => {
            let nextStep = ''
            if (type === 'vendor') {
              nextStep = compact(['create', type]).join('-')
            } else {
              nextStep = compact(['create', type, personType]).join('-')
            }
            setRecipientType(personType)
            setStep(nextStep as Step)
          }}
        />
      )}
      {step === 'create-vendor' && (
        <FormVendor
          saving={isVendorAdding}
          onSave={(vendor) => {
            addVendor(vendor as Omit<Vendor, 'id'>, {
              onSuccess: ({ id, name }) => {
                setSelectedRecipient({ id, name: name as string })
                setStep('select')
              },
            })
          }}
          onCancel={() => setStep('choose-type')}
          type={recipientType as PersonType}
          cancelText="Back"
          submitText="Next"
        />
      )}
      {step === 'create-investor-individual' && (
        <FormBorrowerIndividual
          saving={isInvestorAdding}
          include={['email']}
          cancelText="Back"
          saveText="Next"
          onSave={(investor: Investor) =>
            addInvestor(investor, {
              onSuccess: ({ id, name }) => {
                setSelectedRecipient({ id, name: name as string })
                setStep('select')
              },
            })
          }
          onCancel={() => setStep('choose-type')}
        />
      )}
      {step === 'create-investor-entity' && (
        <FormBorrowerEntity
          saving={isInvestorAdding}
          include={[]}
          cancelText="Back"
          saveText="Next"
          onSave={(investor: Investor) =>
            addInvestor(investor, {
              onSuccess: ({ id, name }) => {
                setSelectedRecipient({ id, name: name as string })
                setStep('select')
              },
            })
          }
          onCancel={() => setStep('choose-type')}
        />
      )}
    </Modal>
  )
}

export { ModalDefaultRecipient }
