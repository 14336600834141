import clsx from 'clsx'
import styles from './styles.module.scss'

interface IndicatorProps {
  color?: 'gray' | 'green' | 'red' | 'yellow' | 'blue' | 'purple'
  size?: 'sm' | 'md'
  className?: string
}

function Indicator({ color = 'gray', size = 'md', className }: IndicatorProps) {
  return (
    <span
      className={clsx(
        styles.indicator,
        {
          [styles.gray]: color === 'gray',
          [styles.green]: color === 'green',
          [styles.red]: color === 'red',
          [styles.yellow]: color === 'yellow',
          [styles.blue]: color === 'blue',
          [styles.purple]: color === 'purple',
          [styles.small]: size === 'sm',
          [styles.medium]: size === 'md',
        },
        className
      )}
    />
  )
}

export type { IndicatorProps }
export default Indicator
