import clsx from 'clsx'
import { useDrag } from 'react-dnd'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { CustomApplicationField } from 'types'

interface Props {
  field: CustomApplicationField
  disabled?: boolean
}

const fieldTypeToDndType = {
  signature: 'signature-item',
  'ssn-number': 'ssn-item',
  'tax-number': 'tax-item',
}

function FormQuestion({
  field: { type, icon, name },
  disabled = false,
}: Props) {
  const [, drag] = useDrag(
    () => ({
      type: fieldTypeToDndType[type] || 'item',
      item: { type },
      options: {
        dropEffect: 'move',
      },
      canDrag: !disabled,
    }),
    [type, disabled]
  )

  return (
    <Flex
      ref={drag}
      key={`${type}-${name}-${disabled}`}
      className={clsx(
        'rounded bg-white-100 px-2 py-3 hover:bg-grey-75',
        disabled && 'opacity-50 hover:bg-white-100'
      )}
      gap={12}
      alignItems="center"
    >
      <Icon name={IconName.dndHandler} className="text-grey-500" />
      <img src={icon} alt="" />
      <Text variant="l">{name}</Text>
    </Flex>
  )
}

export { FormQuestion }
