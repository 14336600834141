import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { SelectDestination } from 'admin/pages/Payouts/ModalProcessPayment/SelectDestination'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { Transaction } from 'types'
import { formatAccountNumber } from 'utils/banking'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { pluralize } from 'utils/pluralize'

interface Props {
  transactions: Transaction[]
  destinations: Record<string, 'banking' | 'wallet'>
  onChange: (destination: Record<string, 'banking' | 'wallet'>) => void
  onDelete: (transactionIds: string[]) => void
}

const TransactionGroupRow = ({
  transactions,
  destinations,
  onChange,
  onDelete,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleRowClick = useCallback(() => setIsExpanded((prev) => !prev), [])
  const transaction = transactions[0]
  const amount = sumDecimal(transactions.map((t) => t.amount))
  const groupDestinations = useMemo(
    () => transactions.map(({ id }) => destinations[id]),
    [transactions, destinations]
  )
  const selectedDestinationLabel = useMemo(() => {
    const hasBanking = groupDestinations.includes('banking')
    const hasWallet = groupDestinations.includes('wallet')
    if (hasBanking && hasWallet) {
      return `${capitalize(transaction.person?.banking?.accountType)} + Wallet`
    }
    return hasBanking
      ? `${capitalize(transaction.person?.banking?.accountType)} ${formatAccountNumber(transaction.person?.banking?.accountNumber)}`
      : 'Wallet'
  }, [transactions, groupDestinations])

  return (
    <>
      <tr
        key={transaction.id}
        onClick={handleRowClick}
        className="group cursor-pointer border-0 border-t border-solid border-grey-200"
      >
        <td className="pl-4 pr-2 py-3 pt-5 align-top font-bold truncate">
          {transaction.person?.name}
        </td>
        <td className="px-2 py-3 pt-5 align-top">
          <Flex stack gap={4}>
            <div className="font-bold">{formatUsd(amount)}</div>
            <div className="text-grey-700">
              {pluralize(transactions.length, 'Distribution')}
            </div>
          </Flex>
        </td>
        <td
          className="px-3 py-3 align-top"
          onClick={(e) => e.stopPropagation()}
        >
          <SelectDestination
            banking={transaction.person?.banking}
            cashBalance={transaction.person?.cashBalance}
            label={selectedDestinationLabel}
            value={groupDestinations.includes('banking') ? 'banking' : 'wallet'}
            onChange={(destination) =>
              onChange(
                transactions.reduce(
                  (acc, t) => ({ ...acc, [t.id]: destination }),
                  {}
                )
              )
            }
          />
        </td>
        <td>
          <Flex justifyContent="left" alignItems="center" gap={4}>
            <Tooltip content="Remove from this payout">
              <Button
                className="invisible group-hover:visible"
                variant="ghost"
                icon
                onClick={(e) => {
                  e.stopPropagation()
                  onDelete(transactions.map(({ id }) => id))
                }}
              >
                <Icon name={IconName.minusCircle} className="text-grey-600" />
              </Button>
            </Tooltip>
            <Icon
              name={IconName.arrowRight}
              className={clsx('text-grey-600', isExpanded && 'rotate-90')}
            />
          </Flex>
        </td>
      </tr>

      {isExpanded &&
        transactions.map((transaction, index) => (
          <tr
            key={transaction.id}
            className={clsx(
              'group bg-grey-75 border-0 border-t border-solid border-grey-200',
              index === transactions.length - 1 && 'border-b'
            )}
          >
            <td className="pl-4 pr-2 py-3" />
            <td className="px-2 py-3 pt-5 align-top">
              <Flex stack gap={4}>
                <div className="font-bold">{formatUsd(transaction.amount)}</div>
                <div className="text-grey-700 truncate">
                  {transaction.type} - {transaction.loan?.name}
                </div>
              </Flex>
            </td>
            <td
              className="px-3 py-3 align-top"
              onClick={(e) => e.stopPropagation()}
            >
              <SelectDestination
                banking={transaction.person?.banking}
                cashBalance={transaction.person?.cashBalance}
                value={destinations[transaction.id]}
                onChange={(destination) =>
                  onChange({ [transaction.id]: destination })
                }
              />
            </td>
            <td>
              <Flex justifyContent="left" alignItems="center" gap={4}>
                <Tooltip content="Remove from this payout">
                  <Button
                    className="invisible group-hover:visible"
                    variant="ghost"
                    icon
                    onClick={(e) => {
                      e.stopPropagation()
                      onDelete([transaction.id])
                    }}
                  >
                    <Icon
                      name={IconName.minusCircle}
                      className="text-grey-600"
                    />
                  </Button>
                </Tooltip>
              </Flex>
            </td>
          </tr>
        ))}
    </>
  )
}

export { TransactionGroupRow }
