import { memo } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import styles from './styles.module.scss'

interface Props {
  hadData: boolean
}

const Empty = memo(({ hadData }: Props) => {
  return hadData ? (
    <div className={styles.tableBodyContent}>
      <Flex stack alignItems="center">
        <Icon
          name={IconName.magnifyingGlass}
          className="text-grey-500 w-[30px] h-[30px]"
        />
        <Text variant="l" className="font-bold">
          No results found.
        </Text>
        <Text variant="l" className="text-center">
          Try adjusting your search terms and/or filter settings
        </Text>
      </Flex>
    </div>
  ) : (
    <div className={styles.tableBodyContent}>None have been created yet</div>
  )
})

Empty.displayName = 'Empty'

export { Empty }
