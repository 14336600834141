import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { isLoanServicing, pathTo as adminPathTo } from 'admin/path-to'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { TableDrawRequests } from 'components/TableDrawRequests'
import {
  useBudgetDraws,
  useAddBudgetDraw,
  useUpdateBudgetDraw,
  useDeleteBudgetDraw,
} from 'hooks/use-loan-budget-draw'
import { Address, Loan } from 'types'
import { Panel } from './index'

interface Props {
  loan: Loan
  address: Address
  borrower?: boolean
}

function PanelDrawRequests({ loan, address, borrower }: Props) {
  const navigate = useNavigate()
  const pathToDraw = borrower
    ? (...params) => borrowerPathTo('loanDraw', ...params)
    : (...params) =>
        adminPathTo(
          isLoanServicing(loan.status) ? 'servicingLoanDraw' : 'loanDraw',
          ...params
        )
  const { data, isPending } = useBudgetDraws(loan.id, address.id)
  const { mutate: addDraw } = useAddBudgetDraw(loan.id, address.id)
  const { mutate: updateDraw } = useUpdateBudgetDraw(loan.id, address.id)
  const { mutate: deleteDraw } = useDeleteBudgetDraw(loan.id, address.id)
  const handleNewDraw = useCallback(() => {
    addDraw(undefined, {
      onSuccess: (draw) => {
        navigate(pathToDraw(loan.id, address.id, draw.id))
      },
    })
  }, [addDraw, loan, address])

  return (
    <Panel
      title="Draw Requests"
      loading={isPending}
      actionChildren={
        <Button disabled={loan.lock} onClick={handleNewDraw}>
          New Draw
        </Button>
      }
    >
      <TableDrawRequests
        data={data}
        onClick={(draw) => navigate(pathToDraw(loan.id, address.id, draw.id))}
        onRelease={(draw) => updateDraw({ id: draw.id, status: 'Released' })}
        onDelete={(draw) => deleteDraw(draw.id)}
      />
    </Panel>
  )
}

export { PanelDrawRequests }
