import { client as webauthnClient } from '@passwordless-id/webauthn'
import { useState } from 'react'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useUpdateCurrentUser } from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { ModalEditPassword } from 'components/Modal/EditPassword'
import { ModalEditPersonalInfo } from 'components/Modal/EditPersonalInfo'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { useSession } from 'hooks/use-session'
import {
  getRegistrationChallenge,
  storeRegistration,
} from 'services/api/session'
import { formatPhone } from 'utils/phone'

function MyInfo() {
  const { user, refreshUser } = useSession()
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateCurrentUser()

  const show2FA = user?.client.settings?.mfa === true

  async function handle2FASetup() {
    const { challenge } = await getRegistrationChallenge({
      client: user?.client.id,
    })

    const registration = await webauthnClient.register({
      user: {
        id: user?.admin?.email as string,
        name: user?.admin?.email as string,
      },
      challenge,
    })

    await storeRegistration({
      subdomain: user?.client.subdomain as string,
      registration,
    })

    refreshUser()
  }

  async function disablePasskey() {
    update({ credentials: null }, { onSuccess: () => {} })
  }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="My Info"
        />

        <Panel
          title="Personal Information"
          onEdit={() => setIsEditModalVisible(true)}
          className="max-w-panel"
        >
          <Grid gap={16}>
            <Grid.Item xs={12}>
              <Summary name="Name">{user?.admin?.name}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Email">{user?.admin?.email}</Summary>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Summary name="Phone number">
                {formatPhone(user?.admin?.phone)}
              </Summary>
            </Grid.Item>
          </Grid>
        </Panel>
        <Panel
          title="Security"
          onEdit={() => setIsPasswordModalVisible(true)}
          className="max-w-panel"
        >
          <Grid gap={16}>
            <Grid.Item xs={12}>
              <Summary name="Password">••••••••</Summary>
            </Grid.Item>
            {show2FA && (
              <Grid.Item xs={12}>
                <Summary name="Multi-Factor Authentication with Passkey">
                  {user?.admin?.login.numCredentials ? (
                    <>
                      <Badge color="green">Active</Badge>
                      <Button
                        size="small"
                        color="negative"
                        variant="secondary"
                        className="ml-2"
                        onClick={disablePasskey}
                      >
                        Disable Passkey
                      </Button>
                    </>
                  ) : (
                    <Button onClick={handle2FASetup}>Enable</Button>
                  )}
                </Summary>
              </Grid.Item>
            )}
          </Grid>
        </Panel>
      </Flex>
      {isEditModalVisible && (
        <ModalEditPersonalInfo
          initialValues={{
            email: user?.admin?.email as string,
            name: user?.admin?.name as string,
            phone: user?.admin?.phone || '',
          }}
          saving={isUpdating}
          onClose={() => setIsEditModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsEditModalVisible(false) })
          }
        />
      )}
      {isPasswordModalVisible && (
        <ModalEditPassword
          saving={isUpdating}
          onClose={() => setIsPasswordModalVisible(false)}
          onSubmit={(data) =>
            update(data, { onSuccess: () => setIsPasswordModalVisible(false) })
          }
        />
      )}
    </MainContent>
  )
}

export { MyInfo }
