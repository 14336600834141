import { useEffect, useMemo, PropsWithChildren } from 'react'
import ReactDOM from 'react-dom'

interface Props extends PropsWithChildren {
  className?: string
}

function Portal({ children, className = '' }: Props) {
  const el = useMemo(() => document.createElement('div'), [])
  useEffect(() => {
    if (className) {
      className.split(' ').forEach((item) => el.classList.add(item))
    }
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [el, className])

  return ReactDOM.createPortal(children as any, el)
}

export default Portal
