import { Recipient } from 'admin/pages/LoanSpreadAllocation/ModalRecipient/types'
import { SelectRecipient } from 'admin/pages/LoanSpreadAllocation/SelectRecipient'
import { Alert } from 'components/Alert'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'

interface Props {
  loading?: boolean
  paymentWarningVisible?: boolean
  recipient?: Recipient
  onSelect: (recipient: Recipient) => void
  onCreateClick: () => void
  onAddClick: () => void
  onCloseClick: () => void
}

const StepSelect = ({
  loading,
  paymentWarningVisible,
  recipient,
  onSelect,
  onCreateClick,
  onAddClick,
  onCloseClick,
}: Props) => {
  return (
    <Flex stack className="px-8">
      {paymentWarningVisible && (
        <Alert severity="warning">
          Before allocating spread, you must first assign a recipient of surplus
          or deficit spread.
        </Alert>
      )}
      <Flex stack gap={6}>
        <div>By default, assign surplus or deficit spread to:</div>
        <SelectRecipient
          placeholder="Find or create a recipient"
          value={recipient?.id}
          onSelect={onSelect}
          onCreate={onCreateClick}
        />
      </Flex>
      <Text variant="l" className="text-grey-700">
        The investor or vendor above will be automatically placed on the spread
        allocation table for each new loan created in Baseline, receiving all
        spread that hasn&apos;t been allocated for other investors or vendors.
        Conversely, they will also receive deficit (negative) spread should the
        amount assigned to all other investors/vendors exceed what is available
        to distribute.
        <p>
          The recipient of surplus or deficit spread can be changed in your
          Company Settings, or adjusted for a specific loan within the spread
          allocation table.
        </p>
      </Text>
      <Flex justifyContent="flex-end" gap={8}>
        <Button variant="secondary" onClick={onCloseClick}>
          Cancel
        </Button>
        <Button disabled={!recipient} loading={loading} onClick={onAddClick}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}

export { StepSelect }
