import { request } from 'services/request'
import {
  Quote,
  Filter,
  Meta,
  Pagination,
  Sort,
  Loan,
  QuoteConvertToLoanProps,
} from 'types'
import { QuoteField } from 'types/field'
import { updateResponseQuote } from 'utils/api/quote-parser'
import { updateRequestTable } from 'utils/api/table-parser'

const getQuotes = async (params: {
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}): Promise<{ quotes: Quote[]; meta: Meta }> => {
  const {
    data: { quotes, pagination },
  } = await request.get('/quote', { params: updateRequestTable(params) })
  return {
    quotes,
    meta: pagination,
  }
}

const getQuote = async (id: string): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.get(`/quote/${id}`)
  return updateResponseQuote(quote)
}

const addQuote = async (payload: Partial<Quote>): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.post('/quote', payload)
  return updateResponseQuote(quote)
}

const updateQuote = async ({
  id,
  ...payload
}: Partial<Quote>): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.patch(`/quote/${id}`, payload)
  return updateResponseQuote(quote)
}

const updateQuoteField = async ({
  id,
  field,
}: {
  id: string
  field: Partial<QuoteField>
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.patch(`/quote/${id}/field/${field.id}`, field)
  return updateResponseQuote(quote)
}

const deleteQuote = async (quoteId: string): Promise<unknown> => {
  return request.delete(`/quote/${quoteId}`)
}

const sendQuote = async (
  id: string,
  data: {
    subject: string
    message: string
  }
): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.post(`/quote/${id}/version`, data)
  return updateResponseQuote(quote)
}

const convertQuote = async (
  id: string,
  data: QuoteConvertToLoanProps
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.put(`/quote/${id}/loan`, data)
  return loan
}

export {
  getQuotes,
  getQuote,
  addQuote,
  updateQuote,
  updateQuoteField,
  deleteQuote,
  sendQuote,
  convertQuote,
}
