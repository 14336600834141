import { omitBy, size } from 'lodash'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import PageTop from 'admin/components/PageTop/PageTop'
import { TopMenu } from 'admin/components/TopMenu'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddLoan } from 'admin/hooks/use-loans'
import { useProducts } from 'admin/hooks/use-products'
import { useUsers } from 'admin/hooks/use-users'
import { pathTo } from 'admin/path-to'
import { downloadLoans } from 'admin/services/csv/download-loans'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import {
  Filter,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
  filterValueToUrl,
  filterValueToTableFilter,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { LoadMore } from 'components/LoadMore'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalAddLoan } from 'components/Modal/AddLoan'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { LOAN_IMPORT_BANNER } from 'constants/local-storage-keys'
import { useOfFundsOptions } from 'constants/use-of-funds'
import { useLoans } from 'hooks/use-loans'
import { useMobile } from 'hooks/use-mobile'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { AlertLoans } from './AlertLoans'
import { EmptyServicing } from './EmptyServicing'
import { ServicingTable } from './ServicingTable'

const tabButtons: { id: 'servicing' | 'liquidated'; title: string }[] = [
  { id: 'servicing', title: 'Active' },
  { id: 'liquidated', title: 'Settled' },
]

export const Servicing = () => {
  const navigate = useNavigate()
  const { isAdmin, user } = useSession()
  const { isTablet } = useMobile()
  const [isLoading, setIsLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(LOAN_IMPORT_BANNER)
  )
  const { mutate: add, isPending: isAdding } = useAddLoan()
  const [searchParams, setSearchParams] = useSearchParams()
  const tab = (searchParams.get('tab') || 'servicing') as
    | 'servicing'
    | 'liquidated'
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const {
    result,
    visibleItems,
    search,
    filter,
    sort,
    isEmpty,
    setSort,
    setFilter,
    setSearch,
    setPagination,
    updateItem,
    resetPagination,
  } = usePagination<Loan>({
    property: 'loans',
    defaultFilter: { status: [tab] },
    useData: (params) =>
      useLoans(
        {
          ...params,
          filter: {
            ...params.filter,
            ...filterValueToTableFilter(filtersValue),
          },
        },
        { keepPreviousData: true }
      ),
  })
  const { data: products } = useProducts()
  const { data: owners } = useUsers({
    user,
    clientId: user?.client?.id,
  })

  useEffect(() => {
    if (!result.isFetching) {
      setIsLoading(false)
    }
  }, [result.isFetching])

  const filterConfig: IFilterConfig[] = useMemo(
    () => [
      {
        id: 'dateClosing',
        type: 'date',
        label: 'Origination Date',
      },
      {
        id: 'amount',
        type: 'currency',
        label: 'Loan Amount',
      },
      {
        id: 'product_id',
        type: 'select',
        label: 'Product',
        options: products?.products.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'admin_id',
        type: 'select',
        label: 'Owners',
        options: owners?.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      },
      {
        id: 'use_of_funds',
        type: 'select',
        label: 'Use of Funds',
        options: useOfFundsOptions,
      },
    ],
    [products, owners]
  )

  const handleFilterChange = useCallback(
    (value: IFilterValue) => {
      setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
    },
    [setSearchParams]
  )

  const handleDownload = () =>
    downloadLoans(
      {
        status: ['servicing', 'liquidated'],
        ...filter,
        ...filterValueToTableFilter(filtersValue),
      },
      sort,
      search
    )

  const isInitiallyEmpty = isEmpty && !search && !filtersValue.length

  const handleTabSelect = useCallback(
    (id: string) => {
      if (tab !== id) {
        setSearchParams({ tab: id }, { replace: true })
      }
    },
    [tab, setFilter, resetPagination]
  )

  useEffect(() => {
    setIsLoading(true)
    setFilter({ status: [tab] })
    resetPagination()
  }, [tab, setFilter, resetPagination])

  return (
    <MainContent>
      {isInitiallyEmpty ? (
        <Flex stack gap={16}>
          <PageTop
            title="Loans"
            className="gap-6"
            hideTopMenu={!isAdmin && isTablet}
          />
          <EmptyServicing
            onClick={() => setAdding(true)}
            onImport={() => setAlertData(true)}
          />
        </Flex>
      ) : (
        <Flex stack gap={16}>
          <Flex stack gap={24}>
            <Flex
              justifyContent="flex-end"
              className="md:-mx-8 lg:-mx-14 md:-mt-4 md:border-0 md:border-b md:border-solid md:border-grey-100 md:h-12"
            >
              {isAdmin && !isTablet && <TopMenu />}
            </Flex>
            <Flex alignItems="flex-start" justifyContent="space-between">
              <Flex alignItems="center" flexWrap="wrap">
                <Header variant="h1" className="mt-2">
                  Loans
                </Header>
              </Flex>
            </Flex>
            {alertData && (
              <AlertLoans alertData={alertData} setAlertData={setAlertData} />
            )}
          </Flex>
          <Panel>
            <Flex
              gap={4}
              justifyContent="space-between"
              flexWrap="wrap"
              className="pb-4 sm:justify-end"
            >
              <Flex gap={8}>
                {tabButtons.map(({ id, title }) => (
                  <Button
                    key={id}
                    active={tab === id}
                    variant="panel"
                    onClick={() => handleTabSelect(id)}
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <Flex gap={8}>
                <Filter
                  config={filterConfig}
                  value={filtersValue}
                  onApply={handleFilterChange}
                />
                <Search search={search} onSearch={setSearch} />
                <ImportEntity
                  entityType="loan"
                  setAlertData={() => setAlertData(true)}
                />
                <Download filename="loans" download={handleDownload} />
                <Button variant="primary" onClick={() => setAdding(true)}>
                  Add Loan
                </Button>
              </Flex>
            </Flex>
            {isLoading && <PageLoader />}
            {!isLoading && (
              <>
                <ServicingTable
                  tab={tab}
                  loading={result.isPending}
                  data={visibleItems}
                  filter={filter}
                  onFilter={(newFilter) => {
                    setFilter(
                      omitBy(
                        { ...filter, ...newFilter },
                        (value) => !size(value)
                      )
                    )
                  }}
                  sort={sort}
                  onSort={setSort}
                  onClick={(loan) =>
                    navigate(pathTo('servicingLoan', (loan as Loan).id))
                  }
                  onUpdateItem={updateItem}
                />
                <LoadMore
                  loading={result.isPending}
                  fetching={result.isFetching}
                  count={visibleItems.length}
                  meta={result.data?.meta}
                  onLoadMore={setPagination}
                />
              </>
            )}
          </Panel>
        </Flex>
      )}
      {adding ? (
        <ModalAddLoan
          include={['product']}
          saving={isAdding}
          onSave={(loan) => {
            add(
              { ...loan, status: 'servicing' },
              {
                onSuccess: (loan) => {
                  navigate(pathTo('servicingLoan', loan.id))
                  setAdding(false)
                },
              }
            )
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
    </MainContent>
  )
}
