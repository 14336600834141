import { Investor } from 'admin/services/api/investors'
import { request } from 'services/request'
import { Banking, BankingData } from 'types'

const getBanking = async (): Promise<Banking> => {
  try {
    const {
      data: { banking },
    } = await request.get('/banking')
    return banking
  } catch (e: any) {
    if (e?.response?.status === 404) {
      return {} as Banking
    } else {
      throw e
    }
  }
}

const updateBanking = async (payload: Banking): Promise<Banking> => {
  const {
    data: { banking },
  } = await request.post('/banking', payload)
  return banking
}

const updateInvestorBanking = async (
  investorId: string,
  payload: BankingData
): Promise<Investor> => {
  const {
    data: { investor },
  } = await request.post(`/investor/${investorId}/banking`, payload)
  return investor
}

const updateVendorBanking = async (
  vendorId: string,
  payload: BankingData
): Promise<Investor> => {
  const {
    data: { vendor },
  } = await request.post(`/vendor/${vendorId}/banking`, payload)
  return vendor
}

export { getBanking, updateBanking, updateInvestorBanking, updateVendorBanking }
