import { PropsWithChildren, useEffect } from 'react'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { routerPath, pathTo } from 'admin/path-to'
import { LoaderOverlay } from 'components/LoaderOverlay'
import { useSessionManager } from 'hooks/use-session-manager'
import {
  SetPassword,
  SignIn,
  SignOut,
  ForgotPassword,
  VerifyEmail,
  SignUp,
} from 'pages/Session'
import { SessionContext } from 'pages/Session/SessionContext'
import {
  setFailedAuthCallback,
  setNotFoundAuthCallback,
  setRedirectOnLoginUrl,
  getRedirectOnLoginUrl,
} from 'services/request'
import { handlePermalink } from 'utils/permalink'

export const SessionProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const sessionManager = useSessionManager()

  useEffect(() => {
    sessionManager.start()
    setRedirectOnLoginUrl(
      window.location.href.replace(window.location.origin, '')
    )
    // Notify session manager when an auth failure in the client occurs
    setFailedAuthCallback(sessionManager.invalidate)
    setNotFoundAuthCallback(() => navigate(pathTo('dashboard')))
  }, [])

  const isSignedIn = sessionManager.isSignedIn && sessionManager.isAdmin

  return (
    <SessionContext.Provider value={sessionManager}>
      {sessionManager.isLoading && !sessionManager.isSigningIn && (
        <LoaderOverlay />
      )}
      <Routes>
        <Route
          path={routerPath.signIn}
          element={
            isSignedIn ? (
              <Navigate
                replace
                to={handlePermalink(routerPath, getRedirectOnLoginUrl(), () =>
                  setRedirectOnLoginUrl('')
                )}
              />
            ) : (
              <SignIn pathTo={pathTo} admin />
            )
          }
        />
        <Route
          path={routerPath.forgotPassword}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <ForgotPassword pathTo={pathTo} />
            )
          }
        />
        <Route
          path={routerPath.forgotPasswordReset}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <SetPassword pathTo={pathTo} />
            )
          }
        />
        <Route
          path={routerPath.verifyEmail}
          element={<VerifyEmail pathTo={pathTo} />}
        />
        <Route
          path={routerPath.invitation}
          element={
            isSignedIn ? (
              <Navigate replace to={routerPath.root} />
            ) : (
              <SignUp pathTo={pathTo} />
            )
          }
        />
        <Route
          path={routerPath.logout}
          element={
            !isSignedIn ? (
              <Navigate replace to={routerPath.signIn} />
            ) : (
              <SignOut pathTo={pathTo} />
            )
          }
        />
        <Route
          path="*"
          element={
            !isSignedIn && !sessionManager.isLoading ? (
              <Navigate replace to={routerPath.signIn} />
            ) : (
              children
            )
          }
        />
      </Routes>
    </SessionContext.Provider>
  )
}
