import { SelectRecipient } from 'admin/pages/LoanSpreadAllocation/SelectRecipient'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Recipient } from './types'

interface Props {
  unavailableRecipientIds?: string[]
  recipient?: Recipient
  onSelect: (recipient: Recipient) => void
  onCreateClick: () => void
  onAddClick: () => void
  onCloseClick: () => void
}

const StepSelect = ({
  unavailableRecipientIds = [],
  recipient,
  onSelect,
  onCreateClick,
  onAddClick,
  onCloseClick,
}: Props) => {
  return (
    <Flex stack className="px-8">
      <Flex stack gap={6}>
        <div>Add recipient</div>
        <SelectRecipient
          unavailableRecipientIds={unavailableRecipientIds}
          value={recipient?.id}
          onSelect={onSelect}
          onCreate={onCreateClick}
        />
      </Flex>
      <Flex justifyContent="flex-end" gap={8}>
        <Button variant="secondary" onClick={onCloseClick}>
          Cancel
        </Button>
        <Button disabled={!recipient} onClick={onAddClick}>
          Add Recipient
        </Button>
      </Flex>
    </Flex>
  )
}

export { StepSelect }
