import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { pluralize } from 'utils/pluralize'

interface Props {
  transactions: Transaction[]
  destinations: Record<string, 'banking' | 'wallet'>
  onClose: () => void
}

const StepConfirmation = ({ transactions, destinations, onClose }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const walletTransactions = useMemo(
    () =>
      Object.entries(destinations)
        .filter(([, destination]) => destination === 'wallet')
        .map(
          ([transactionId]) =>
            transactions.find(({ id }) => transactionId === id) as Transaction
        ),
    [transactions, destinations]
  )
  const walletTransactionsAmount = useMemo(
    () => sumDecimal(walletTransactions.map(({ amount }) => amount)),
    [walletTransactions]
  )
  const bankingTransactions = useMemo(
    () =>
      Object.entries(destinations)
        .filter(([, destination]) => destination === 'banking')
        .map(
          ([transactionId]) =>
            transactions.find(({ id }) => transactionId === id) as Transaction
        ),
    [transactions, destinations]
  )
  const bankingTransactionsAmount = useMemo(
    () => sumDecimal(bankingTransactions.map(({ amount }) => amount)),
    [bankingTransactions]
  )
  const totalAmount = useMemo(
    () => sumDecimal([walletTransactionsAmount, bankingTransactionsAmount]),
    [walletTransactionsAmount, bankingTransactionsAmount]
  )

  return (
    <Flex justifyContent="center" alignItems="center" className="mt-[20%]">
      <Flex stack gap={32} className="w-[482px]">
        <Flex gap={16}>
          <Icon
            name={IconName.approvedCircle}
            className="w-8 h-8 text-lime-100"
          />
          <div className="font-bold text-5xl">
            You’ve processed {pluralize(transactions.length, 'Payout')}
          </div>
        </Flex>
        <Flex stack gap={24}>
          <Panel>
            <Flex stack gap={32}>
              <Flex stack gap={16}>
                <Flex justifyContent="space-between" alignItems="center">
                  <div>
                    {pluralize(transactions.length, 'Payout')} Processed
                  </div>
                  <Button
                    variant="ghost"
                    onClick={() => setIsExpanded((prevState) => !prevState)}
                  >
                    <Flex gap={6} className="cursor-pointer">
                      <div>{isExpanded ? 'Hide' : 'Show'} Details</div>
                      <Icon
                        name={IconName.arrowDown}
                        className={clsx(
                          'text-grey-600',
                          isExpanded && 'rotate-180'
                        )}
                      />
                    </Flex>
                  </Button>
                </Flex>
                <div className="text-6xl">{formatUsd(totalAmount)}</div>
              </Flex>
              {isExpanded && (
                <Flex stack gap={0}>
                  {walletTransactions.length > 0 && (
                    <Flex
                      alignItems="center"
                      gap={12}
                      className={clsx(
                        'py-[18px]',
                        bankingTransactions.length &&
                          'border-0 border-b border-solid border-grey-200'
                      )}
                    >
                      <Icon name={IconName.wallet} className="text-green-50" />
                      <div className="font-bold flex-grow">
                        {walletTransactions.length} Wallet{' '}
                        {pluralize(walletTransactions.length, 'Payout', false)}
                      </div>
                      <div>{formatUsd(walletTransactionsAmount)}</div>
                    </Flex>
                  )}
                  {bankingTransactions.length > 0 && (
                    <Flex alignItems="center" gap={12} className="py-[18px]">
                      <Icon name={IconName.bank} className="text-green-50" />
                      <Flex
                        gap={16}
                        alignItems="center"
                        className="font-bold flex-grow"
                      >
                        {bankingTransactions.length} Manual{' '}
                        {pluralize(bankingTransactions.length, 'Payout', false)}
                        {/*<Button variant="ghost" onClick={handleDownloadNacha}>*/}
                        {/*  <Icon name={IconName.download} />*/}
                        {/*  Download NACHA*/}
                        {/*</Button>*/}
                      </Flex>
                      <div>{formatUsd(bankingTransactionsAmount)}</div>
                    </Flex>
                  )}
                </Flex>
              )}
            </Flex>
          </Panel>
          <Flex justifyContent="flex-end">
            <Button variant="primary" size="large" onClick={onClose}>
              Back to Payouts
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export { StepConfirmation }
