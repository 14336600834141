import clsx from 'clsx'
import { isUndefined, toNumber, toString, isArray } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useLoanPaymentCalculate } from 'admin/hooks/use-loan-payment'
import { Flex } from 'components/Flex'
import formStyles from 'components/Form/styles.module.scss'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { InputCurrency } from 'components/InputCurrency'
import { ModalAddCharge } from 'components/Modal/AddCharge'
import { Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { useDebounce } from 'hooks/use-debounce'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { AccordionPanel } from './AccordionPanel'
import { TableCharges } from './TableCharges'
import { TableFundingSources } from './TableFundingSources'
import { TableTrust } from './TableTrust'
import styles from './styles.module.scss'
import {
  ICalculatedFundingSource,
  ICalculatedTrust,
  IDistribution,
  ICalculatedCharge,
  IPaymentInfo,
} from './types'

interface Props {
  loan: Loan
  balance: number
  paymentType: IPaymentInfo['type']
  distribution: IDistribution
  dateReceived?: string
  disabled: boolean
  onChange: (distribution: IDistribution) => void
}

export const Distribution = ({
  loan,
  balance,
  paymentType,
  distribution,
  dateReceived,
  disabled,
  onChange,
}: Props) => {
  const [open, setOpen] = useState<
    'principal_interest' | 'charges' | 'trust' | null
  >(null)
  const [focused, setFocused] = useState<
    'principal_interest' | 'charges' | 'trust' | null
  >(null)
  const [addCharge, setAddCharge] = useState(false)
  const [isChargeError, setIsChargeError] = useState(false)
  const [isPrincipalError, setIsPrincipalError] = useState(false)
  const [isUnpaidInterestExpanded, setIsUnpaidInterestExpanded] = useState<
    boolean | undefined
  >(undefined)

  const {
    data: fundingSources,
    isPending: isLoadingFundingSources,
    isFetching: isFetchingLoadingSources,
  } = useLoanPaymentCalculate(
    {
      loanId: loan.id,
      type: 'principal_interest',
      principalAmount: toNumber(
        distribution.fundingSources.requestPrincipalAmount
      ),
      interestAmount: toNumber(
        distribution.fundingSources.requestInterestAmount
      ),
      paymentType,
      dateReceived,
    },
    {
      enabled: open === 'principal_interest',
      keepPreviousData: true,
    },
    { debounce: true }
  )
  const { data: charges, isPending: isLoadingCharges } =
    useLoanPaymentCalculate(
      {
        loanId: loan.id,
        type: 'charges',
        amount: toNumber(distribution.charges.requestAmount),
        dateReceived,
        paymentType,
      },
      {
        enabled: open === 'charges',
        keepPreviousData: true,
      },
      { debounce: true }
    )
  const { data: trust, isPending: isLoadingTrust } = useLoanPaymentCalculate(
    {
      loanId: loan.id,
      type: 'trust',
      amount: toNumber(distribution.trust.requestAmount),
      context: 'distribution',
      paymentType,
    },
    {
      enabled: open === 'trust',
      keepPreviousData: true,
    },
    { debounce: true }
  )
  const isToBorrower =
    paymentType === 'To Borrower' || paymentType === 'Funding'

  const handleRowChange = useCallback(
    (key: 'fundingSources' | 'charges' | 'trust', itemKey, rowId, value) => {
      if (key) {
        const nextItems = distribution[key].items.map((item) => ({
          ...(isUndefined(item.id) ? {} : { id: item.id }),
          ...item,
          [itemKey]:
            item.id === rowId || item.spread?.id === rowId
              ? value
              : item[itemKey],
        }))

        const nextAmount = sumDecimal(
          nextItems.map((item) => toNumber((item as any)[itemKey]) || 0)
        )

        onChange({
          ...distribution,
          [key]: {
            ...distribution[key],
            [itemKey]: nextAmount,
            items: nextItems,
          },
        })
      }
    },
    [distribution]
  )

  useEffect(() => {
    onChange({
      ...distribution,
      fundingSources: {
        ...distribution.fundingSources,
        items: ((fundingSources as ICalculatedFundingSource[]) || []).map(
          ({
            fundingSource,
            spread,
            interestAmount,
            principalAmount,
            unpaidInterestAmount,
            fundingSourceUnpaidInterest,
            baseInterestAmount,
          }) => ({
            ...(isUndefined(fundingSource?.id)
              ? {}
              : { id: fundingSource?.id }),
            ...(isUndefined(principalAmount)
              ? {}
              : { principalAmount: toString(principalAmount) }),
            fundingSource,
            spread,
            interestAmount: toString(interestAmount),
            unpaidInterestAmount: toString(unpaidInterestAmount),
            fundingSourceUnpaidInterest: toString(fundingSourceUnpaidInterest),
            baseInterestAmount: toString(baseInterestAmount),
          })
        ),
      },
    })
  }, [fundingSources])

  useEffect(() => {
    onChange({
      ...distribution,
      charges: {
        ...distribution.charges,
        items: charges as ICalculatedCharge[],
      },
    })
  }, [charges])

  useEffect(() => {
    onChange({
      ...distribution,
      trust: {
        ...distribution.trust,
        items: ((trust as ICalculatedTrust[]) || []).map(({ id, amount }) => ({
          id,
          amount: toString(amount),
        })),
      },
    })
  }, [trust])

  useEffect(() => {
    const maxAmount = distribution.charges.items
      ? sumDecimal(
          distribution.charges.items.map(({ amountDue }) => amountDue || 0)
        )
      : 0
    setIsChargeError(toNumber(distribution.charges.amount) > maxAmount)
  }, [distribution.charges.items, distribution.charges.amount])

  useEffect(() => {
    const maxAmount = isArray(distribution.fundingSources.items)
      ? sumDecimal(
          distribution.fundingSources.items.map(({ id, principalAmount }) =>
            id ? toNumber(principalAmount) : 0
          )
        )
      : 0
    setIsPrincipalError(
      toNumber(distribution.fundingSources.principalAmount) > maxAmount
    )
  }, [distribution.fundingSources])

  useEffect(() => {
    const isUnpaidInterestExpandedByDefault =
      distribution.fundingSources?.items?.some(
        ({ unpaidInterestAmount }) => toNumber(unpaidInterestAmount) !== 0
      )
    if (
      isUnpaidInterestExpandedByDefault &&
      isUndefined(isUnpaidInterestExpanded)
    ) {
      setIsUnpaidInterestExpanded(true)
    }
  }, [distribution.fundingSources.items])

  useEffect(() => {
    setOpen(null)
    setFocused(null)
  }, [paymentType])

  const visibleBalance = useDebounce(balance, 500)

  return (
    <Flex stack gap={8}>
      <Header variant="h4">
        <Flex alignItems="center" gap={8}>
          Distribution
          <div
            className={clsx(
              styles.balance,
              visibleBalance < 0 && styles.negative
            )}
          >
            Available {formatUsd(visibleBalance, { showZero: true })}
          </div>
        </Flex>
      </Header>
      <Grid gap={8}>
        {isToBorrower && (
          <Grid.Item xs={3}>
            <Summary name="Borrower">
              <InputCurrency
                onFocus={() => {
                  setOpen(null)
                  setFocused(null)
                }}
                value={distribution.borrower.amount}
                disabled={disabled}
                onChange={(e) =>
                  onChange({
                    ...distribution,
                    borrower: {
                      ...distribution.borrower,
                      amount: e.target.value,
                    },
                  })
                }
              />
            </Summary>
          </Grid.Item>
        )}
        {!isToBorrower && (
          <Grid.Item xs={3}>
            <Summary
              name={
                <span
                  className={styles.label}
                  onClick={() => {
                    setOpen('principal_interest')
                    setFocused('principal_interest')
                  }}
                >
                  Principal
                </span>
              }
            >
              <InputCurrency
                onFocus={() => {
                  setOpen('principal_interest')
                  setFocused('principal_interest')
                }}
                onBlur={() => setFocused(null)}
                className={clsx(
                  !isFetchingLoadingSources &&
                    isPrincipalError &&
                    formStyles.errorField
                )}
                value={distribution.fundingSources.principalAmount}
                disabled={disabled}
                onChange={(e) => {
                  onChange({
                    ...distribution,
                    fundingSources: {
                      ...distribution.fundingSources,
                      principalAmount: e.target.value,
                      requestPrincipalAmount: e.target.value,
                    },
                  })
                }}
              />
            </Summary>
          </Grid.Item>
        )}
        {!isToBorrower && (
          <Grid.Item xs={3}>
            <Summary
              name={
                <Flex
                  gap={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <span
                    className={styles.label}
                    onClick={() => {
                      setOpen('principal_interest')
                      setFocused('principal_interest')
                    }}
                  >
                    Interest
                  </span>
                  {toNumber(loan.unpaidInterest) !== 0 && (
                    <span className={styles.unpaidInterestBadge}>
                      Unpaid {formatUsd(loan.unpaidInterest)}
                    </span>
                  )}
                </Flex>
              }
            >
              <InputCurrency
                onFocus={() => {
                  setOpen('principal_interest')
                  setFocused('principal_interest')
                }}
                onBlur={() => setFocused(null)}
                value={distribution.fundingSources.interestAmount}
                disabled={disabled}
                onChange={(e) => {
                  if (!isUnpaidInterestExpanded) {
                    setIsUnpaidInterestExpanded(undefined)
                  }
                  onChange({
                    ...distribution,
                    fundingSources: {
                      ...distribution.fundingSources,
                      interestAmount: e.target.value,
                      requestInterestAmount: e.target.value,
                    },
                  })
                }}
              />
            </Summary>
          </Grid.Item>
        )}
        <Grid.Item xs={3}>
          <Summary
            name={
              <span
                className={styles.label}
                onClick={() => {
                  setOpen('charges')
                  setFocused('charges')
                }}
              >
                Charges
              </span>
            }
          >
            <InputCurrency
              onFocus={() => {
                setOpen('charges')
                setFocused('charges')
              }}
              onBlur={() => setFocused(null)}
              value={distribution.charges.amount}
              className={clsx(isChargeError && formStyles.errorField)}
              disabled={disabled}
              onChange={(e) => {
                onChange({
                  ...distribution,
                  charges: {
                    ...distribution.charges,
                    amount: e.target.value,
                    requestAmount: e.target.value,
                  },
                })
              }}
            />
          </Summary>
        </Grid.Item>
        <Grid.Item xs={3}>
          <Summary
            name={
              <span
                className={styles.label}
                onClick={() => {
                  setOpen('trust')
                  setFocused('trust')
                }}
              >
                Trust
              </span>
            }
          >
            <InputCurrency
              onFocus={() => {
                setOpen('trust')
                setFocused('trust')
              }}
              onBlur={() => setFocused(null)}
              value={distribution.trust.amount}
              disabled={disabled}
              onChange={(e) => {
                onChange({
                  ...distribution,
                  trust: {
                    ...distribution.trust,
                    amount: e.target.value,
                    requestAmount: e.target.value,
                  },
                })
              }}
            />
          </Summary>
        </Grid.Item>
      </Grid>
      {open === 'principal_interest' && (
        <AccordionPanel
          active={[1, 2]}
          focused={focused === 'principal_interest'}
          onClose={() => {
            setOpen(null)
            setFocused(null)
          }}
          title="Adjust Principal & Interest Distribution"
        >
          <TableFundingSources
            fundingSourceItems={distribution.fundingSources.items}
            onChangePrincipal={(rowId, value) =>
              handleRowChange('fundingSources', 'principalAmount', rowId, value)
            }
            onChangeInterest={(rowId, value) =>
              handleRowChange('fundingSources', 'interestAmount', rowId, value)
            }
            onChangeUnpaidInterest={(rowId, value) =>
              handleRowChange(
                'fundingSources',
                'unpaidInterestAmount',
                rowId,
                value
              )
            }
            setIsExpandedUnpaidInterest={setIsUnpaidInterestExpanded}
            isExpandedUnpaidInterest={isUnpaidInterestExpanded}
            loading={isLoadingFundingSources}
          />
        </AccordionPanel>
      )}
      {open === 'charges' && (
        <AccordionPanel
          active={[isToBorrower ? 2 : 3]}
          focused={focused === 'charges'}
          onClose={() => {
            setOpen(null)
            setFocused(null)
          }}
          title="Adjust Charges Distribution"
        >
          <TableCharges
            loading={isLoadingCharges}
            chargesItems={distribution.charges.items}
            onChange={(rowId, value) =>
              handleRowChange('charges', 'amount', rowId, value)
            }
          />
          <div>
            <TextLink onClick={() => setAddCharge(true)}>
              <Icon name={IconName.plus} size="sm" /> Add a charge
            </TextLink>
          </div>
        </AccordionPanel>
      )}
      {open === 'trust' && (
        <AccordionPanel
          active={[isToBorrower ? 3 : 4]}
          focused={focused === 'trust'}
          onClose={() => {
            setOpen(null)
            setFocused(null)
          }}
          title="Adjust Trust Distribution"
        >
          <TableTrust
            loading={isLoadingTrust}
            type="distribution"
            trust={trust as ICalculatedTrust[]}
            trustItems={distribution.trust.items}
            onChange={(rowId, value) =>
              handleRowChange('trust', 'amount', rowId, value)
            }
          />
        </AccordionPanel>
      )}
      {addCharge && (
        <ModalAddCharge loanId={loan.id} onCancel={() => setAddCharge(false)} />
      )}
    </Flex>
  )
}
