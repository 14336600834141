import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  addLoanVendor,
  createLoanVendor,
  getLoanVendors,
  removeLoanVendor,
} from 'admin/services/api/loan-vendors'
import { Vendor } from 'admin/services/api/vendors'
import { KEY_VENDORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types/table'
import { message } from 'utils/message'

const useLoanVendors = (
  {
    loanId,
    search,
    filter,
    pagination,
    sort,
    details = false,
  }: {
    loanId: string
    search?: string
    filter?: Filter
    pagination?: Pagination
    sort?: Sort
    details?: boolean
  },
  options: { enabled?: boolean } = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_VENDORS, search, nextFilter, sort, pagination, details],
    queryFn: () =>
      getLoanVendors({
        loanId,
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useAddLoanVendor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      loanId,
      vendor,
    }: {
      loanId: string
      vendor: Vendor
    }) => {
      if (vendor.id) {
        await addLoanVendor(loanId, vendor)
        return vendor
      } else {
        const { vendor: createdVendor } = await createLoanVendor(loanId, vendor)
        return createdVendor
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveLoanVendor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ loanId, vendorId }: { loanId: string; vendorId: string }) =>
      removeLoanVendor(loanId, vendorId),
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS] })
      message.success('Vendor deleted')
    },
  })
}

export { useLoanVendors, useAddLoanVendor, useRemoveLoanVendor }
