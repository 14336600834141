import { getFundInvestors } from 'admin/services/api/fund-investors'
import { Row } from 'types'
import { parsePercent } from 'utils/math'

const downloadFundFunding = async (fundId: string): Promise<Row[]> => {
  const sources = await getFundInvestors(fundId)

  return sources.map((fundingSources) => ({
    Name: fundingSources.name,
    Class: fundingSources.fundInvestors.class,
    'Rate%': parsePercent(fundingSources.fundInvestors.rate),
    Invested$: fundingSources.fundInvestors.amount,
    'Invested Date': fundingSources.fundInvestors.dateInvested,
    Returned$: fundingSources.capitalReturn,
    Balance$: fundingSources.balance,
    Income$: fundingSources.income,
    DRIP$: fundingSources.reinvested,
  }))
}

export { downloadFundFunding }
