import { useQuery } from '@tanstack/react-query'
import { KEY_QUOTES } from 'constants/query-keys'
import { getQuoteVersion } from 'services/api/quote'

const useQuoteVersion = (quoteId: string) => {
  return useQuery({
    queryKey: [KEY_QUOTES, quoteId, 'version'],
    queryFn: () => getQuoteVersion(quoteId),
  })
}

export { useQuoteVersion }
