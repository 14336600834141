const workTypes = [
  'Basement',
  'Bathrooms',
  'Cabinets',
  'Carpet & Vinyl Floors',
  'Cleanout & Hauloff',
  'Concrete Flatwork',
  'Contingency',
  'Countertops & Backsplashes',
  'Decks & Patios',
  'Doors',
  'Drywall',
  'Electric Infrastructure',
  'Electric Panel',
  'Electrical Fixtures & Devices',
  'Exterior Paint',
  'Exterior Siding & Trim',
  'Fence',
  'Fireplace & Chimney',
  'Foundation',
  'Framing',
  'Garage Door',
  'General Contractor Fees',
  'Glass Windows & Mirrors',
  'HVAC System',
  'Hardware & Accessories',
  'Insulation',
  'Interior Paint',
  'Interior Trim Carpentry',
  'Kitchen Appliances',
  'Landscaping',
  'Permits & Inspections',
  'Pest Control',
  'Plumbing Fixtures',
  'Plumbing Systems',
  'Pool',
  'Roof',
  'SQF Addition',
  'Tile Floors',
  'Tub & Surround',
  'Wall Repairs',
  'Water Heater',
  'Window Replacement',
  'Wood & Laminate Floors',
]

const scales = ['Light', 'Moderate', 'Extensive']

export { workTypes, scales }
