import { useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TableFundTransactions } from 'admin/components/table/TableFundTransactions'
import { useFundInvestors } from 'admin/hooks/use-fund-investors'
import {
  useFundTransactions,
  useAddFundPayment,
  useDeleteFundPayment,
} from 'admin/hooks/use-fund-transaction'
import { downloadFundTransactions } from 'admin/services/csv/download-fund-transactions'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalInvestorPayment } from 'components/Modal/ModalInvestorPayment'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { useSession } from 'hooks/use-session'
import { Fund, Transaction } from 'types'

interface Props {
  fund: Fund
}

const filterConfig: IFilterConfig[] = [
  {
    id: 'type',
    type: 'select',
    label: 'Type',
    options: [
      { value: 'Charge', label: 'Charge' },
      { value: 'DRIP', label: 'DRIP' },
      { value: 'Deposit', label: 'Deposit' },
      { value: 'Income', label: 'Income' },
      { value: 'Interest', label: 'Interest' },
      { value: 'Investment', label: 'Investment' },
      { value: 'Other', label: 'Other' },
      { value: 'Returned Capital', label: 'Returned Capital' },
      { value: 'Spread', label: 'Spread' },
      { value: 'Withdrawal', label: 'Withdrawal' },
    ],
  },
  {
    id: 'date',
    type: 'date',
    label: 'Date',
  },
  {
    id: 'amount',
    type: 'currency',
    label: 'Amount',
  },
]

function TabTransactions({ fund }: Props) {
  const { user } = useSession()
  const [searchParams, setSearchParams] = useSearchParams()
  const [deletingId, setDeletingId] = useState<string>()
  const [adding, setAdding] = useState<boolean>(false)
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const { visibleItems, result, setPagination } = usePagination<Transaction>({
    property: 'transactions',
    useData: (params) =>
      useFundTransactions({
        ...params,
        filter: filterValueToTableFilter(filtersValue),
        id: fund.id,
      }),
  })
  const { data: investors } = useFundInvestors(fund.id)
  const { mutate: add, isPending: saving } = useAddFundPayment(fund.id)
  const { mutate: remove, isPending: deleting } = useDeleteFundPayment(fund.id)

  const transactions = useMemo(() => {
    return visibleItems?.map(
      (transaction) =>
        ({
          ...transaction,
          investor: investors?.find(
            (investor) =>
              investor.fundInvestors.id === transaction?.fundingInvestorId
          ),
        }) as Transaction & { investor: { id: string; name: string } }
    )
  }, [investors, visibleItems])

  const handleFilterChange = (value: IFilterValue) => {
    setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
  }

  return (
    <div>
      <Panel
        title="Transactions"
        actionChildren={
          <>
            <Filter
              config={filterConfig}
              value={filtersValue}
              onApply={handleFilterChange}
            />
            <Download
              filename="transactions"
              btnName="Export"
              variant="tertiary"
              download={() =>
                downloadFundTransactions({
                  fundId: fund.id,
                  filter: filterValueToTableFilter(filtersValue),
                  user,
                })
              }
            />
            <Button onClick={() => setAdding(true)}>Add Transaction</Button>
          </>
        }
      >
        <TableFundTransactions
          data={transactions}
          loading={result.isPending}
          hasFilterUrl={!!filtersValue.length}
          onDelete={(paymentId) => setDeletingId(paymentId)}
        />
        <LoadMore
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
        {adding && (
          <ModalInvestorPayment
            saving={saving}
            onSave={(data) => add(data, { onSuccess: () => setAdding(false) })}
            onCancel={() => setAdding(false)}
          />
        )}
        {deletingId && (
          <ModalDelete
            resource="payment"
            loading={deleting}
            onDelete={() => {
              remove(deletingId, {
                onSuccess: () => setDeletingId(undefined),
              })
            }}
            onCancel={() => setDeletingId(undefined)}
          />
        )}
      </Panel>
    </div>
  )
}

export { TabTransactions }
