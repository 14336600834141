import { useState } from 'react'
import { ImportEntity } from 'admin/components/ImportEntity'
import { Vendor } from 'admin/services/api/vendors'
import { downloadVendorsTransactions } from 'admin/services/csv/download-vendor-transactions'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { VENDOR_TRANSACTION_IMPORT_BANNER } from 'constants/local-storage-keys'
import { usePagination } from 'hooks/use-pagination'
import {
  useVendorTransactions,
  useAddVendorPayment,
  useDeleteVendorPayment,
} from 'hooks/use-vendor-transactions'
import { Transaction } from 'types'
import { AlertFunding } from './AlertFunding'
import { ModalVendorPayment } from './ModalVendorPayment'
import { TableVendorTransactions } from './TableVendorTransactions'

interface Props {
  vendor: Vendor
}

function TabTransactions({ vendor }: Props) {
  const [adding, setAdding] = useState<boolean>(false)
  const [deletingId, setDeletingId] = useState<string>()
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(VENDOR_TRANSACTION_IMPORT_BANNER)
  )
  const { visibleItems, result, setPagination, resetPagination } =
    usePagination<Transaction>({
      property: 'transactions',
      useData: (params) => useVendorTransactions({ ...params, id: vendor.id }),
    })
  const { mutate: add, isPending: saving } = useAddVendorPayment(vendor.id)
  const { mutate: remove, isPending: deleting } = useDeleteVendorPayment(
    vendor.id
  )

  return (
    <Panel
      title="Transactions"
      actionChildren={
        <>
          <ImportEntity
            entityType="transaction"
            loanId={vendor.id}
            setAlertData={() => setAlertData(true)}
          />
          <Download
            filename="transactions"
            download={() => downloadVendorsTransactions(vendor.id)}
          />
          <Button onClick={() => setAdding(true)}>Add transaction</Button>
        </>
      }
    >
      {alertData && (
        <AlertFunding alertData={alertData} setAlertData={setAlertData} />
      )}
      <TableVendorTransactions
        data={visibleItems}
        loading={result.isPending}
        onDelete={(paymentId) => setDeletingId(paymentId)}
      />
      <LoadMore
        loading={result.isPending}
        fetching={result.isFetching}
        count={visibleItems.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {adding && (
        <ModalVendorPayment
          saving={saving}
          onSave={(data) => {
            resetPagination()
            add(data, { onSuccess: () => setAdding(false) })
          }}
          onCancel={() => setAdding(false)}
        />
      )}
      {deletingId && (
        <ModalDelete
          resource="payment"
          loading={deleting}
          onDelete={() => {
            resetPagination()
            remove(deletingId, {
              onSuccess: () => setDeletingId(undefined),
            })
          }}
          onCancel={() => setDeletingId(undefined)}
        />
      )}
    </Panel>
  )
}

export default TabTransactions
