import { Formik } from 'formik'
import { toString } from 'lodash'
import { Button } from 'components/Button'
import { Date, FieldIcon, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { InvestorPayment } from 'services/api/investor-payment'
import { Transaction } from 'types'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  saving: boolean
  transaction?: Transaction
  onSave: (payment: InvestorPayment) => void
  onCancel: () => void
}

type FormValues = Omit<InvestorPayment, 'amount'> & {
  amount: string
}

const Schema = createScheme({
  date: required,
  amount: required,
})

function ModalInvestorPayment({
  saving,
  transaction,
  onSave,
  onCancel,
}: Props) {
  const initialValue: FormValues = {
    date: transaction?.date || '',
    amount: toString(transaction?.amount) || '',
    type: transaction?.type || 'Deposit',
    description: transaction?.payment?.description || '',
  }
  const onSubmit = (values: FormValues) => {
    onSave({
      ...values,
      amount: parseFloat(values.amount),
    })
  }

  return (
    <Modal
      title={transaction ? 'Edit Transaction' : 'Add Transaction'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={6}>
              <Date name="date" label="Date" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="currency" label="Amount" name="amount" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                label="Type"
                name="type"
                disabled={!!transaction}
                options={[
                  { value: 'Deposit', label: 'Deposit' },
                  { value: 'Withdrawal', label: 'Withdrawal' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="text" label="Memo" name="description" />
            </Grid.Item>

            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalInvestorPayment
