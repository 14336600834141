import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addPersonField,
  getPersonFields,
  getFieldsByPersonId,
  updatePersonField,
  deletePersonField,
  updateFieldsByPersonId,
} from 'admin/services/api/person-fields'
import { KEY_BORROWERS, KEY_INVESTORS, KEY_VENDORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Field } from 'types'
import { message } from 'utils/message'

type IPersonType = 'borrower' | 'investor' | 'vendor'

const getKeyByPersonType = (personType: IPersonType) => {
  let key: string = ''

  if (personType === 'borrower') {
    key = KEY_BORROWERS
  } else if (personType === 'investor') {
    key = KEY_INVESTORS
  } else if (personType === 'vendor') {
    key = KEY_VENDORS
  }

  return key
}

const usePersonFields = ({
  personType,
  search,
}: {
  personType: IPersonType
  search?: string
}) => {
  return useQuery({
    queryKey: [getKeyByPersonType(personType), 'fields', search],
    queryFn: () => getPersonFields({ search, personType }),
  })
}

const useFieldsByPersonId = ({
  personType,
  personId,
}: {
  personType: IPersonType
  personId: string
}) => {
  return useQuery({
    queryKey: [getKeyByPersonType(personType), personId, 'fields'],
    queryFn: () => getFieldsByPersonId({ personId, personType }),
  })
}

const useUpdateFieldsByPersonId = ({
  personId,
  personType,
}: {
  personType: IPersonType
  personId: string
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (fields: Field[]) =>
      updateFieldsByPersonId(personId, personType, fields),
    onSuccess: (fields: Field[]) => {
      queryClient.setQueryData(
        [getKeyByPersonType(personType), personId, 'fields'],
        fields
      )
    },
    onError: handleErrorResponse,
  })
}

const useAddPersonField = ({ personType }: { personType: IPersonType }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (payload: any) => addPersonField(personType, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getKeyByPersonType(personType), 'fields'],
      })
      message.success('Person field added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdatePersonField = ({
  personType,
  silent,
}: {
  personType: IPersonType
  silent?: boolean
}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ payload, fieldId }: { payload: any; fieldId: string }) =>
      updatePersonField({ payload, fieldId }),
    onSuccess: () => {
      if (!silent) {
        queryClient.invalidateQueries({
          queryKey: [getKeyByPersonType(personType), 'fields'],
        })
        message.success('Person field saved')
      }
    },
    onError: handleErrorResponse,
  })
}

const useRemovePersonField = ({ personType }: { personType: IPersonType }) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deletePersonField,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [getKeyByPersonType(personType), 'fields'],
      })
      message.success('Person field deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  usePersonFields,
  useAddPersonField,
  useUpdatePersonField,
  useRemovePersonField,
  useFieldsByPersonId,
  useUpdateFieldsByPersonId,
}
