import { HeaderContext } from '@tanstack/react-table'
import clsx from 'clsx'
import { isNaN } from 'lodash'
import { memo, ReactNode } from 'react'
import { ColumnMeta, Sort } from 'types'
import { ColumnFilter } from '../ColumnFilter'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface HeadProps extends HeaderContext<any, any> {
  children: ReactNode
  sortable?: boolean
  sort?: Sort
}

const Head = memo(({ children, header, column, sort }: HeadProps) => {
  const meta = column.columnDef.meta as ColumnMeta
  const align = meta?.align || 'left'
  const filter = meta?.filter

  return (
    <div
      className={clsx(styles.header, meta?.sortable && styles.sortable, {
        [styles.alignLeft]: align === 'left',
        [styles.alignRight]: align === 'right',
        [styles.alignCenter]: align === 'center',
      })}
      style={{
        width: isNaN(column.getSize()) ? 'auto' : column.getSize(),
      }}
    >
      {meta?.sortable && (
        <div className="flex flex-col">
          <Icon
            name={IconName.tableSort}
            size="sm"
            className={clsx(
              styles.sortArrow,
              sort?.[0] === header.id && sort?.[1] === 'ASC' && styles.selected
            )}
          />
          <Icon
            name={IconName.tableSort}
            size="sm"
            className={clsx(
              styles.sortArrow,
              styles.down,
              sort?.[0] === header.id && sort?.[1] === 'DESC' && styles.selected
            )}
          />
        </div>
      )}
      <div className="truncate">{children}</div>
      {filter && (
        <>
          {' '}
          <ColumnFilter
            key="filter"
            items={filter.options}
            filterValues={filter.selected}
            onFilterValuesChange={filter.onChange}
          />
        </>
      )}
    </div>
  )
})

Head.displayName = 'Head'

export default Head
