import { request } from 'services/request'
import { Loan, NewLoan } from 'types'
import { updateRequestFields, updateResponseLoan } from 'utils/api/loan-parser'

const updateLoan = async ({ id, ...data }: Partial<Loan>): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(`/loan/${id}`, {
    ...data,
    ...(data.fields
      ? {
          fields: updateRequestFields(data.fields),
        }
      : {}),
  })
  return updateResponseLoan(loan)
}

const addLoan = async (data: NewLoan): Promise<Loan> => {
  const payload = {
    ...data,
    borrowers: data.borrowers.map((id, index) => ({
      id,
      borrow: { primary: index === 0 },
    })),
    guarantors: data.guarantors.map((id, index) => ({
      id,
      guarantee: { primary: index === 0 },
    })),
    fields: data.fields ? updateRequestFields(data.fields) : undefined,
  }
  const {
    data: { loan },
  } = await request.post('/loan', payload)
  return updateResponseLoan(loan)
}

const deleteLoan = (id: string) => {
  return request.delete(`/loan/${id}`)
}

const duplicateLoan = async (id: string) => {
  const {
    data: { loan },
  } = await request.put(`/loan/${id}`)
  return updateResponseLoan(loan)
}

const addLoanBorrower = async ({
  id,
  borrower,
}: {
  id: string
  borrower: { id: string; borrow: { primary: boolean } }
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.put(`/loan/${id}/borrower`, borrower)
  return updateResponseLoan(loan)
}

const updateLoanBorrower = async ({
  id,
  borrowerId,
  primary,
}: {
  id: string
  borrowerId: string
  primary: boolean
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(`/loan/${id}/borrower/${borrowerId}`, { primary })
  return updateResponseLoan(loan)
}

const deleteLoanBorrower = async ({
  id,
  borrowerId,
}: {
  id: string
  borrowerId: string
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(`/loan/${id}/borrower/${borrowerId}`)
  return updateResponseLoan(loan)
}

const addLoanGuarantor = async ({
  id,
  guarantor,
}: {
  id: string
  guarantor: { id: string; guarantee: { primary: boolean } }
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.put(`/loan/${id}/guarantor`, guarantor)
  return updateResponseLoan(loan)
}

const updateLoanGuarantor = async ({
  id,
  guarantorId,
  primary,
}: {
  id: string
  guarantorId: string
  primary: boolean
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(`/loan/${id}/guarantor/${guarantorId}`, { primary })
  return updateResponseLoan(loan)
}

const deleteLoanGuarantor = async ({
  id,
  guarantorId,
}: {
  id: string
  guarantorId: string
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(`/loan/${id}/guarantor/${guarantorId}`)
  return updateResponseLoan(loan)
}

const shareLoan = async ({
  id,
  clientId,
  message,
  accepted,
}: {
  id: string
  clientId?: string
  message?: string
  accepted?: boolean
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${id}/share`, { clientId, message, accepted })
  return updateResponseLoan(loan)
}

const transferLoan = async ({
  id,
  clientId,
  message,
  accepted,
}: {
  id: string
  clientId?: string
  message?: string
  accepted?: boolean
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${id}/transfer`, {
    clientId,
    message,
    accepted,
  })
  return updateResponseLoan(loan)
}

const updateOwners = async ({
  id,
  owners,
}: {
  id: string
  owners: string[]
}): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.put(`/loan/${id}/owner`, { owners })

  return updateResponseLoan(loan)
}

export type { Loan, NewLoan }
export {
  addLoan,
  updateLoan,
  deleteLoan,
  addLoanBorrower,
  updateLoanBorrower,
  deleteLoanBorrower,
  addLoanGuarantor,
  updateLoanGuarantor,
  deleteLoanGuarantor,
  duplicateLoan,
  shareLoan,
  transferLoan,
  updateOwners,
}
