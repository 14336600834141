import clsx from 'clsx'
import { ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react'
import styles from './styles.module.scss'

type Props = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'type'> & {
  fieldClassName?: string
}

const Textarea = forwardRef(
  (
    { fieldClassName, ...rest }: Props,
    ref: ForwardedRef<HTMLTextAreaElement | null>
  ) => {
    return (
      <div className={clsx(styles.fieldTextarea, fieldClassName)}>
        <textarea
          ref={ref}
          {...rest}
          className={clsx(styles.textarea, rest.className)}
        />
      </div>
    )
  }
)
Textarea.displayName = 'Textarea'

export default Textarea
