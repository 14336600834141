import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useLogoUrl } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'
import theme from 'styles/theme'
import { LogoFullImg } from './LogoFullImg'
import { LogoIconImg } from './LogoIconImg'
import styles from './styles.module.scss'

interface Props {
  icon?: boolean
  wrapperClassName?: string
  type?: 'default' | 'statement'
  logoClassName?: string
  onLoad?: () => void
  to: string
}

function Logo({
  icon,
  wrapperClassName,
  logoClassName,
  type = 'default',
  to,
  onLoad,
}: Props) {
  const { user } = useSession()

  const [customLogoImage, setCustomLogoImage] = useState<string>()

  const hexColor =
    type === 'default' ? theme.color.white100 : theme.color.blue200
  const { data: loadedCustomLogoImage, isPending } = useLogoUrl({
    enabled: !user,
  })
  const isWaitingForLogo = !user && isPending

  useEffect(() => {
    if (!loadedCustomLogoImage) {
      setCustomLogoImage(
        type === 'statement'
          ? (user?.client?.image?.statementUrl ?? user?.client?.image?.url)
          : user?.client?.image?.url
      )
    } else {
      setCustomLogoImage(
        type === 'statement'
          ? (loadedCustomLogoImage.statementUrl ?? loadedCustomLogoImage.url)
          : loadedCustomLogoImage.url
      )
    }

    const img = new Image()
    img.onload = () => {
      setTimeout(() => {
        onLoad && onLoad()
      }, 1)
    }
    if (customLogoImage) {
      img.src = customLogoImage
    }
    if (!customLogoImage && !isWaitingForLogo) {
      onLoad && onLoad()
    }
  }, [user, loadedCustomLogoImage])

  if (isWaitingForLogo) {
    return <div className={clsx(styles.logoWrapper, wrapperClassName)} />
  }

  if (customLogoImage) {
    return (
      <NavLink to={to}>
        <div className={clsx(styles.logoWrapper, wrapperClassName)}>
          <img
            className={clsx(styles.custom, logoClassName)}
            src={customLogoImage}
          />
        </div>
      </NavLink>
    )
  }

  if (icon) {
    return (
      <NavLink to={to}>
        <div className={clsx(styles.logoWrapper, wrapperClassName)}>
          <LogoIconImg className={clsx(logoClassName)} />
        </div>
      </NavLink>
    )
  }

  return (
    <NavLink to={to}>
      <div className={clsx(styles.logoWrapper, wrapperClassName)}>
        <LogoFullImg
          className={clsx(logoClassName)}
          style={{ color: hexColor }}
        />
      </div>
    </NavLink>
  )
}

export default Logo
