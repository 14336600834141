import { request } from 'services/request'
import { Charge } from 'types/charge'

const getLoanCharges = async (loanId: string): Promise<Charge[]> => {
  const {
    data: { charges },
  } = await request.get(`/loan/${loanId}/charge`)
  return charges
}

const getLoanCharge = async (
  loanId: string,
  chargeId: string
): Promise<Charge> => {
  const {
    data: { charge },
  } = await request.get(`/loan/${loanId}/charge/${chargeId}`)
  return charge
}

const addLoanCharge = async (
  loanId: string,
  data: Pick<Charge, 'personId' | 'name' | 'dateCharged' | 'dateDue' | 'amount'>
): Promise<Charge[]> => {
  const {
    data: { charges },
  } = await request.post(`/loan/${loanId}/charge`, data)
  return charges
}

const updateLoanCharge = async (
  loanId: string,
  chargeId: string,
  data: Pick<Charge, 'personId' | 'name' | 'dateCharged' | 'dateDue' | 'amount'>
): Promise<Charge[]> => {
  const {
    data: { charges },
  } = await request.patch(`/loan/${loanId}/charge/${chargeId}`, data)
  return charges
}

const deleteLoanCharge = async (
  loanId: string,
  chargeId: string
): Promise<Charge[]> => {
  const {
    data: { charges },
  } = await request.delete(`/loan/${loanId}/charge/${chargeId}`)
  return charges
}

export {
  getLoanCharges,
  getLoanCharge,
  addLoanCharge,
  updateLoanCharge,
  deleteLoanCharge,
}
