import { useQuery } from '@tanstack/react-query'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import { getLoanDocuments } from 'services/api/loan-documents'

const useLoanDocuments = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS],
    queryFn: () => getLoanDocuments({ id }),
    gcTime: 0,
  })
}
export { useLoanDocuments }
