import { request } from 'services/request'
import { Product, Field, ProductProps } from 'types'
import { Filter, Meta, Pagination } from 'types/table'
import {
  updateResponseFields,
  updateRequestFields,
} from 'utils/api/product-parser'

const getProducts = async (params: {
  search?: string
  filter?: Filter
  page?: Pagination
}): Promise<{ products: Product[]; meta: Meta }> => {
  const {
    data: { products, pagination },
  } = await request.get('/product', { params })
  return {
    products,
    meta: pagination,
  }
}

const getProduct = async (
  id: string
): Promise<{ product: Product; fields: Field[] }> => {
  const { data } = await request.get(`/product/${id}`)
  return {
    product: data.product,
    fields: updateResponseFields(data.fields),
  }
}

const addProduct = async (body: ProductProps): Promise<Product> => {
  const {
    data: { product },
  } = await request.post(`/product`, body)
  return product
}

const editProduct = async (
  id: string,
  body: ProductProps
): Promise<Product> => {
  const {
    data: { product },
  } = await request.patch(`/product/${id}`, body)
  return product
}

const editProductFields = async ({
  id,
  fields,
}: {
  id: string
  fields: Field[]
}): Promise<Field[]> => {
  const { data } = await request.patch(`/product/${id}/fields`, {
    fields: updateRequestFields(fields as Field[]),
  })
  return data.fields
}

const duplicateProduct = async ({
  id,
}: {
  id: string
}): Promise<{ product: Product; fields: Field[] }> => {
  const { data } = await request.put(`/product/${id}`)
  return {
    product: data.product,
    fields: updateResponseFields(data.fields),
  }
}

const removeProduct = (id: string): Promise<void> => {
  return request.delete(`/product/${id}`)
}

export type { Product }
export {
  updateResponseFields,
  updateRequestFields,
  getProducts,
  getProduct,
  addProduct,
  editProduct,
  editProductFields,
  duplicateProduct,
  removeProduct,
}
