import { useState } from 'react'
import { TableOfferingLoans } from 'admin/components/table'
import {
  useAddOfferingLoan,
  useOfferingLoans,
  useDeleteOfferingLoan,
} from 'admin/hooks/use-offering-loan'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalAddOfferingLoan } from 'components/Modal/AddOfferingLoan'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Offering, OfferingLoan } from 'types'
import { ModalDelete } from '../../../components/Modal/Delete'
import styles from '../Settings/styles.module.scss'

interface Props {
  offering: Offering
}

function TabCollateral({ offering }: Props) {
  const [addingLoan, setAddingLoan] = useState(false)
  const [removingId, setRemovingId] = useState<string>()
  const { data: offeringLoans, isPending } = useOfferingLoans(offering.id)
  const { mutate: addOfferingLoan, isPending: isAdding } = useAddOfferingLoan(
    offering.id
  )
  const { mutate: remove, isPending: isRemoving } = useDeleteOfferingLoan(
    offering.id
  )

  return (
    <Panel title="Collateral">
      {isPending ? (
        <PageLoader />
      ) : (
        <>
          <TableOfferingLoans
            data={offeringLoans as OfferingLoan[]}
            loading={isPending}
            onDelete={(offeringLoan) => setRemovingId(offeringLoan.id)}
          />
          <div className={styles.link}>
            <TextLink onClick={() => setAddingLoan(true)}>
              <Icon name={IconName.plus} size="sm" />
              Add a New Loan
            </TextLink>
          </div>
          {addingLoan && (
            <ModalAddOfferingLoan
              saving={isAdding}
              onSubmit={(offeringLoan) =>
                addOfferingLoan(offeringLoan, {
                  onSuccess: () => {
                    setAddingLoan(false)
                  },
                })
              }
              onCancel={() => setAddingLoan(false)}
            />
          )}
        </>
      )}
      {removingId && (
        <ModalDelete
          resource="collateral"
          loading={isRemoving}
          onDelete={() =>
            remove(removingId, {
              onSuccess: () => {
                setRemovingId(undefined)
              },
            })
          }
          onCancel={() => setRemovingId(undefined)}
        />
      )}
    </Panel>
  )
}

export { TabCollateral }
