import { request } from 'services/request'
import { Field, Loan, FieldType } from 'types'
import { updateResponseLoan, updateRequestFields } from 'utils/api/loan-parser'

const addLoanField = async (loanId: string, field: Field): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(
    `/loan/${loanId}/field`,
    updateRequestFields([field])[0]
  )
  return updateResponseLoan(loan)
}

const updateLoanField = async (loanId: string, field: Field): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(
    `/loan/${loanId}/field/${field.id}`,
    updateRequestFields([field])[0]
  )
  return updateResponseLoan(loan)
}

const removeLoanField = async (
  loanId: string,
  fieldId: string
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(`/loan/${loanId}/field/${fieldId}`)
  return updateResponseLoan(loan)
}

const calculateFieldValue = async ({
  loanId,
  productId,
  fieldId = 'formula',
  formula,
  type,
}: {
  loanId?: string
  productId?: string
  fieldId?: string
  formula: string
  type: FieldType[]
}): Promise<{ value: string }> => {
  const url = loanId
    ? `/loan/${loanId}/field/${fieldId}`
    : `/product/${productId}/field/${fieldId}`
  const { data } = await request.post(url, { formula, type })
  return data
}

export { addLoanField, updateLoanField, removeLoanField, calculateFieldValue }
