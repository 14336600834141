import { ColumnDef } from '@tanstack/react-table'
import { compact, get } from 'lodash'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathToLoan, pathTo } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { LoanStatus } from 'constants/loan-status'
import { useSession } from 'hooks/use-session'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'

type InvestmentTransaction = Transaction & {
  investor: { name: string; id: string }
}

type Props = {
  data?: InvestmentTransaction[]
  onDelete?: (paymentId: string) => void
  loading: boolean
  hasFilterUrl: boolean
}

function TableFundTransactions({
  data = [],
  loading,
  hasFilterUrl,
  onDelete,
}: Props) {
  const navigate = useNavigate()
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })

  const showBalance = settings.autoInvestorAccounting !== 'Yes'

  const columns: ColumnDef<InvestmentTransaction>[] = useMemo(
    () =>
      compact([
        {
          header: 'Date',
          accessorKey: 'date',
          size: 150,
          cell: ({ getValue }) => formatDate(getValue() as string),
        },
        {
          header: 'Type',
          accessorKey: 'gl',
          size: 160,
          cell: ({ row }) => getFundingType(row.original, true),
        },
        {
          header: 'Reference',
          cell: ({ row }) => {
            if (row.original.investor) {
              return (
                <Flex gap={8}>
                  {' '}
                  <TextLink
                    onClick={() =>
                      navigate(
                        pathTo(
                          'investorTab',
                          row.original.investor?.id,
                          'transactions'
                        )
                      )
                    }
                  >
                    {row.original.investor.name}
                  </TextLink>
                </Flex>
              )
            }

            let path

            if (row.original.loan?.id) {
              path = pathToLoan(
                row.original.loan as {
                  id: string
                  status: LoanStatus
                }
              )
            }

            if (row.original.fund?.id) {
              path = pathTo('fund', row.original.fund?.id)
            }

            return (
              <Flex gap={8}>
                {(row.original.loan?.name || row.original.fund?.name) &&
                  (path ? (
                    <TextLink onClick={() => navigate(path)}>
                      {row.original.loan?.name || row.original.fund?.name || ''}
                    </TextLink>
                  ) : (
                    row.original.loan?.name || row.original.fund?.name || ''
                  ))}
                {row.original.payment?.description}
              </Flex>
            )
          },
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          size: 130,
          cell: ({ getValue }) => formatUsd(getValue() as string),
          meta: {
            align: 'right',
          },
        },
        ...(showBalance
          ? [
              {
                header: 'Balance',
                accessorKey: 'balance',
                size: 130,
                cell: ({ getValue }) => formatUsd(getValue() as string),
                meta: {
                  align: 'right',
                },
              },
            ]
          : []),
        onDelete
          ? {
              header: '',
              id: 'actions',
              size: 40,
              cell: ({ row }) => {
                const id = row.original.payment?.id

                return (
                  <EllipsesActions>
                    <EllipsesActions.Item
                      icon
                      onSelect={() => onDelete(id)}
                      disabled={
                        !id ||
                        !['Withdrawal', 'Deposit'].includes(
                          row.original.payment?.type
                        )
                      }
                      className="text-red-100"
                    >
                      <Icon name={IconName.delete} />
                      Delete
                    </EllipsesActions.Item>
                  </EllipsesActions>
                )
              },
            }
          : undefined,
      ]),
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      noResultsEmptyScreen={hasFilterUrl}
    />
  )
}

export default TableFundTransactions
