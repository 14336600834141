import clsx from 'clsx'
import { ReactNode, SyntheticEvent } from 'react'
import styles from './styles.module.scss'

interface TextLinkProps {
  variant?: 'invert'
  children: ReactNode
  onClick?: (e: SyntheticEvent) => void
  className?: string
}

function TextLink({ children, onClick, variant, className }: TextLinkProps) {
  return (
    <a
      className={clsx(
        'link',
        styles.link,
        { [styles.invert]: variant === 'invert' },
        className
      )}
      onClick={onClick}
    >
      {children}
    </a>
  )
}

export type { TextLinkProps }
export default TextLink
