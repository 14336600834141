import { useNavigate } from 'react-router-dom'
import { Button } from 'components/Button'
import { Icon, IconName } from '../Icon'

interface Props {
  breadcrumbs: { link: string; title: string }
}

function Breadcrumbs({ breadcrumbs }: Props) {
  const navigate = useNavigate()

  return (
    <Button variant="ghost" onClick={() => navigate(breadcrumbs.link)}>
      <Icon name={IconName.arrowLeftLong} size="md" />
      {breadcrumbs.title}
    </Button>
  )
}

export { Breadcrumbs }
