import { request } from 'services/request'
import { Trust } from 'types'

const getTrusts = async (): Promise<Trust[]> => {
  const {
    data: { trusts },
  } = await request.get('/trust')
  return trusts
}

const addTrust = async (data: Omit<Trust, 'id'>): Promise<Trust[]> => {
  const {
    data: { trusts },
  } = await request.post('/trust', data)
  return trusts
}

const editTrust = async ({ id, ...trust }: Trust): Promise<Trust[]> => {
  const {
    data: { trusts },
  } = await request.patch(`/trust/${id}`, trust)
  return trusts
}

const deleteTrust = async (id: string): Promise<Trust[]> => {
  const {
    data: { trusts },
  } = await request.delete(`/trust/${id}`)
  return trusts
}

export { getTrusts, addTrust, editTrust, deleteTrust }
