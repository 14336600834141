import { DragEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useDeleteDocument,
  useUpdateDocument,
  useRequestDocument,
} from 'admin/hooks/use-document'
import { pathTo } from 'admin/path-to'
import { InvestorDetails } from 'admin/services/api/investors'
import { DragDropFile } from 'components/DragDropFile'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalEditDocument } from 'components/Modal/EditDocument'
import { Panel } from 'components/Panel'
import { TableDocuments } from 'components/TableDocuments'
import { TextLink } from 'components/TextLink'
import { useDownloadDocument, useUploadDocument } from 'hooks/use-document'
import {
  useInvestorDocuments,
  useAddInvestorDocuments,
  useAddInvestorDocument,
} from 'hooks/use-investor-documents'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  investor: InvestorDetails
}

function TabDocuments({ investor }: Props) {
  const navigate = useNavigate()
  const [isDragActive, setIsDragActive] = useState(false)
  const [addingDocument, setAddingDocument] = useState<boolean>(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { data: documents, isPending } = useInvestorDocuments({
    id: investor.id,
  })
  const { mutate: updateDocument, isPending: isUpdating } = useUpdateDocument()
  const { mutate: addDocument, isPending: isAdding } = useAddInvestorDocument({
    id: investor.id,
  })
  const { mutate: addDocuments } = useAddInvestorDocuments({
    id: investor.id,
  })
  const { mutate: deleteDocument } = useDeleteDocument()
  const { mutate: downloadDocument } = useDownloadDocument()
  const { mutate: uploadDocument } = useUploadDocument()
  const { mutate: requestDocument } = useRequestDocument()

  const handleOpen = (id: string) =>
    navigate(pathTo('personDocument', 'investors', investor.id, id))
  const handleRequest = (id: string) => requestDocument(id)
  const handleEdit = (id: string) => setEditingId(id)
  const handleDownload = (id: string) => downloadDocument(id)
  const handleUpload = (id: string) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    openBrowseFile({
      onChoose: (files) => {
        uploadDocument({ id, name: doc?.name, file: files[0] })
      },
    })
  }
  const handleDropRow = (id: string, files: FileList) => {
    const doc = documents?.find(({ id: docId }) => id === docId)
    uploadDocument({ id, name: doc?.name, file: files[0] })
  }
  const handleDropNew = (files: FileList) => {
    setIsDragActive(false)
    addDocuments(files)
  }
  const handleDelete = (id: string) => {
    deleteDocument(id)
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    }
  }

  return (
    <Panel>
      {isPending ? (
        <PageLoader />
      ) : (
        <>
          <TableDocuments
            data={documents}
            onOpen={handleOpen}
            onEdit={handleEdit}
            onUpload={handleUpload}
            onFileDrop={handleDropRow}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onRequest={handleRequest}
          />
          <div className={styles.addDocument} onDragEnter={handleDrag}>
            {isDragActive && (
              <DragDropFile
                onLeave={() => setIsDragActive(false)}
                onDrop={handleDropNew}
              >
                Drag and drop to upload files
              </DragDropFile>
            )}
            <TextLink onClick={() => setAddingDocument(true)}>
              <Icon name={IconName.plus} size="sm" /> Create new document
            </TextLink>
          </div>
        </>
      )}
      {(addingDocument || !!editingId) && (
        <ModalEditDocument
          saving={isAdding || isUpdating}
          name={(documents || []).find(({ id }) => id === editingId)?.name}
          onSave={({ name }) => {
            if (editingId) {
              updateDocument(
                { id: editingId, name },
                { onSuccess: () => setEditingId(null) }
              )
            } else {
              addDocument(
                { name },
                { onSuccess: () => setAddingDocument(false) }
              )
            }
          }}
          onCancel={() => {
            setAddingDocument(false)
            setEditingId(null)
          }}
        />
      )}
    </Panel>
  )
}

export default TabDocuments
