import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $wrapNodeInElement } from '@lexical/utils'
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from 'lexical'
import { useEffect } from 'react'
import { $createVariableNode, VariableNode } from '../nodes/VariableNode'

type CommandPayload = {
  variable: string
}

export const INSERT_VARIABLE_COMMAND: LexicalCommand<CommandPayload> =
  createCommand('INSERT_VARIABLE_COMMAND')

function VariablePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!editor.hasNodes([VariableNode])) {
      throw new Error('VariablePlugin: VariableNode not registered on editor')
    }

    editor.registerCommand<CommandPayload>(
      INSERT_VARIABLE_COMMAND,
      (payload) => {
        const { variable } = payload
        const variableNode = $createVariableNode(variable)

        $insertNodes([variableNode])
        if ($isRootOrShadowRoot(variableNode.getParentOrThrow())) {
          $wrapNodeInElement(variableNode, $createParagraphNode).selectEnd()
        }

        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])

  return null
}

export { VariablePlugin }
