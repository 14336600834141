import { CellContext } from '@tanstack/react-table'
import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface TableProps extends CellContext<any, any> {
  children: ReactNode
}

function Cell({ children, column }: TableProps) {
  const meta = column.columnDef.meta as any
  const align = meta?.align || 'left'

  return (
    <div
      className={clsx(styles.cell, {
        [styles.alignLeft]: align === 'left',
        [styles.alignRight]: align === 'right',
        [styles.alignCenter]: align === 'center',
      })}
    >
      {children}
    </div>
  )
}

export default Cell
