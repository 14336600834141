import clsx from 'clsx'
import { useState, useRef } from 'react'
import { useDrop, useDrag } from 'react-dnd'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Toggle } from 'components/Toggle'
import { ApplicationSchemeField, CustomApplicationField } from 'types'
import { fields } from './fields'

interface Props {
  type?: string
  accept?: string | string[]
  field: ApplicationSchemeField
  denyFieldTypes?: string[]
  onDrop: (
    field: ApplicationSchemeField,
    target: ApplicationSchemeField
  ) => void
  onEdit: (field: ApplicationSchemeField) => void
  onDelete?: (field: ApplicationSchemeField) => void
  onToggleField: (field: ApplicationSchemeField) => void
}

function PageField({
  type = 'item',
  accept = ['item', 'signature-item'],
  field,
  denyFieldTypes = [],
  onDrop,
  onEdit,
  onDelete,
  onToggleField,
}: Props) {
  const [isEnableDrag, setIsEnableDrag] = useState(true)

  const ref = useRef<HTMLDivElement>(null)
  const fieldDetails = fields.find(
    ({ type }) => type === field.type
  ) as CustomApplicationField

  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept,
      drop: (item, monitor) => {
        if (monitor.canDrop()) {
          onDrop(item as ApplicationSchemeField, field)
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
      canDrop: (item: ApplicationSchemeField) =>
        !denyFieldTypes?.includes(item.type),
    }),
    [onDrop]
  )
  const [{ isDragging }, drag] = useDrag(
    {
      type,
      item: () => field,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      options: {
        dropEffect: 'move',
      },
      canDrag: () => isEnableDrag && !field.readonly,
    },
    [field, isEnableDrag]
  )

  drag(drop(ref))

  return (
    <Flex
      ref={ref}
      justifyContent="space-between"
      alignItems="center"
      className={clsx(
        'group -mx-2 cursor-pointer border-0 border-b-[3px] border-solid border-white-100 px-2 pb-[9px] pt-3',
        isOver && canDrop && '!border-blue-100',
        isDragging && 'hidden'
      )}
      onClick={() => onEdit(field)}
    >
      <Flex className="px-2" gap={12} alignItems="center">
        <Icon
          name={IconName.dndHandler}
          className="flex-shrink-0 text-grey-500"
        />
        <img src={fieldDetails.icon} className="flex-shrink-0" alt="" />
        <Text variant="l">{field.label}</Text>
      </Flex>
      {!field.readonly && (
        <Flex
          alignItems="center"
          onMouseEnter={() => setIsEnableDrag(false)}
          onMouseLeave={() => setIsEnableDrag(true)}
        >
          <div className="invisible group-hover:visible">
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(field)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!onDelete}
                onSelect={() => onDelete && onDelete(field)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          </div>
          <Toggle
            checked={field.enabled}
            onChange={() => onToggleField(field)}
          />
        </Flex>
      )}
    </Flex>
  )
}

export { PageField }
