import clsx from 'clsx'
import { createElement, HTMLAttributes, ReactNode } from 'react'
import styles from './styles.module.scss'

interface HeaderProps extends HTMLAttributes<HTMLHeadingElement> {
  children: ReactNode
  className?: string
  variant?: 'h1' | 'h2' | 'h3' | 'h4'
}

function Header({ children, className, variant = 'h2', ...rest }: HeaderProps) {
  return createElement(
    variant,
    {
      className: clsx(className, styles.header, {
        [styles.h1]: variant === 'h1',
        [styles.h2]: variant === 'h2',
        [styles.h3]: variant === 'h3',
        [styles.h4]: variant === 'h4',
      }),
      ...rest,
    },
    children
  )
}

export type { HeaderProps }
export default Header
