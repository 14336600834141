import clsx from 'clsx'
import { PropsWithChildren, HTMLAttributes, ReactNode } from 'react'
import Key from './Key'
import Value from './Value'
import styles from './styles.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  name: ReactNode
}

function Summary({
  name,
  children,
  className,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <div className={clsx(styles.summary, className)} {...rest}>
      <Key>{name}</Key>
      <Value>{children}</Value>
    </div>
  )
}

export default Summary
