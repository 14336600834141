import { Indicator } from 'components/Indicator'

interface Props {
  status: 'saving' | 'saved'
}

function SavingIndicator({ status }: Props) {
  return (
    <Indicator color={status === 'saving' ? 'yellow' : 'green'} size="sm" />
  )
}

export default SavingIndicator
