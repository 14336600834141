import { get, set } from 'lodash'
import { useState } from 'react'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { ModalPayment } from 'components/Modal/Payment'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { ModalDefaultRecipient } from './ModalDefaultRecipient'
import PanelLoanPayments from './PanelLoanPayments'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabPayments({ loan }: Props) {
  const { user } = useSession()
  const { mutate: update, isPending } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    defaultSpreadRecipient: '',
  })
  const [defaultRecipient, setDefaultRecipient] = useState(
    settings.defaultSpreadRecipient
  )
  const [isDefaultRecipientVisible, setIsDefaultRecipientVisible] =
    useState(false)
  const [isMakePaymentVisible, setIsMakePaymentVisible] = useState(false)

  return (
    <div className={styles.tabContent}>
      <PanelLoanPayments
        loan={loan}
        showDistributions
        onCreate={() => {
          if (!defaultRecipient) {
            setIsDefaultRecipientVisible(true)
          } else {
            setIsMakePaymentVisible(true)
          }
        }}
      />
      {isMakePaymentVisible && (
        <ModalPayment
          loan={loan}
          onCancel={() => setIsMakePaymentVisible(false)}
        />
      )}
      {isDefaultRecipientVisible && (
        <ModalDefaultRecipient
          title="Who should be assigned surplus or deficit spread?"
          paymentWarningVisible
          loading={isPending}
          onSelect={(recipient) => {
            const updatedSettings = {
              ...settings,
              defaultSpreadRecipient: recipient.id,
            }
            set(user as any, 'client.settings', updatedSettings)
            update(
              { clientId, settings: updatedSettings },
              {
                onSuccess: () => {
                  setIsDefaultRecipientVisible(false)
                  setIsMakePaymentVisible(true)
                  setDefaultRecipient(recipient.id)
                },
              }
            )
          }}
          onClose={() => setIsDefaultRecipientVisible(false)}
        />
      )}
    </div>
  )
}
