import { Loan } from 'types'
import PanelActivities from './PanelActivities'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabActivities({ loan }: Props) {
  return (
    <div className={styles.tabContent}>
      <PanelActivities loan={loan} />
    </div>
  )
}
