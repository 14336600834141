import {
  autoUpdate,
  flip,
  FloatingPortal,
  FloatingNode,
  shift,
  safePolygon,
  useHover,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useFloatingNodeId,
  useFloatingTree,
  offset,
} from '@floating-ui/react'
import clsx from 'clsx'
import {
  ReactNode,
  forwardRef,
  ForwardedRef,
  ReactElement,
  useRef,
  useState,
  cloneElement,
} from 'react'
import styles from './styles.module.scss'

interface ItemProps {
  children: ReactElement<ItemProps> | (ReactElement<ItemProps> | false)[]
  label: ReactNode
  className?: string
  disabled?: boolean
}

const Item = forwardRef(
  (
    { children, className, label }: ItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const listRef = useRef<(HTMLDivElement | null)[]>([])
    const [open, setOpen] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const { x, y, refs, context, strategy } = useFloating({
      nodeId,
      open,
      onOpenChange: setOpen,
      whileElementsMounted: autoUpdate,
      placement: 'right-start',
      middleware: [shift(), flip(), offset(-1)],
    })
    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([
        useHover(context, { delay: { open: 75 }, handleClose: safePolygon() }),
        useRole(context, { role: 'menu' }),
        useDismiss(context),
        useListNavigation(context, {
          listRef: listRef,
          activeIndex: activeIndex,
          onNavigate: setActiveIndex,
          virtual: true,
        }),
      ])

    const handleSelect = (onSelect: () => void) => () => {
      onSelect()
      setOpen(false)
      setActiveIndex(null)
      tree?.events.emit('click')
    }

    return (
      <FloatingNode id={nodeId}>
        <div
          ref={ref}
          className={clsx(styles.item, className)}
          {...getReferenceProps({
            ref: refs.setReference,
            onClick(event) {
              event.stopPropagation()
            },
          })}
        >
          {label}
        </div>
        {open && (
          <FloatingPortal>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                onClick(event) {
                  event.stopPropagation()
                },
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
              className={clsx(styles.dropdown, className)}
            >
              {[children]
                .flat()
                .filter((child) => child)
                .map((child: any, index) => {
                  const { disabled, className, onSelect } = child.props
                  return cloneElement(child, {
                    className: clsx(className, {
                      [styles.active]: activeIndex === index,
                      [styles.disabled]: disabled,
                    }),
                    ref: (node: HTMLDivElement) =>
                      (listRef.current[index] = node),
                    ...getItemProps({
                      key: index,
                      onClick: disabled ? () => {} : handleSelect(onSelect),
                    }),
                  })
                })}
            </div>
          </FloatingPortal>
        )}
      </FloatingNode>
    )
  }
)

Item.displayName = 'EllipsesActions.Item'

export type { ItemProps }
export default Item
