import { getVendorTransactions } from 'services/api/vendor-payment'
import { Row, Transaction } from 'types'
import { getFundingType } from 'utils/loan-transactions'

const downloadVendorsTransactions = async (
  vendorId: string
): Promise<Row[]> => {
  const { transactions } = await getVendorTransactions({
    id: vendorId,
  })

  return transactions.map((transaction: Transaction) => ({
    Date: transaction.date,
    Type: getFundingType(transaction),
    Reference:
      transaction.loan?.name ||
      transaction.fund?.name ||
      transaction.payment?.description ||
      '',
    Amount: transaction.amount,
    Balance: transaction.balance,
  }))
}

export { downloadVendorsTransactions }
