import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Offering } from 'types'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
}

function TabRisks({ offering }: Props) {
  return (
    <Flex stack gap={0}>
      {offering.risks?.map(({ id, name, description }) => (
        <Flex key={id} stack gap={4} className={styles.risk}>
          <Header variant="h4">{name}</Header>
          <div>{description}</div>
        </Flex>
      ))}
    </Flex>
  )
}

export { TabRisks }
