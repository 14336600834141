import { compact, uniq } from 'lodash'
import { useMemo } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { TransactionGroupRow } from './TransactionGroupRow'

interface Props {
  transactions: Transaction[]
  destinations: Record<string, 'banking' | 'wallet'>
  onChange: (destination: Record<string, 'banking' | 'wallet'>) => void
  onDelete: (transactionIds: string[]) => void
  onBack: () => void
  onNext: () => void
}

const StepPayoutDestinations = ({
  transactions,
  destinations,
  onChange,
  onDelete,
  onBack,
  onNext,
}: Props) => {
  const transactionsOrder = useMemo(
    () => compact(uniq(transactions.map(({ person }) => person?.id))),
    [transactions]
  )

  const transactionGroups = useMemo(
    () =>
      transactions.reduce(
        (acc, transaction) => {
          const personId = transaction.person?.id as string
          if (!acc[personId]) {
            acc[personId] = []
          }
          acc[personId].push(transaction)
          return acc
        },
        {} as Record<string, Transaction[]>
      ),
    [transactions]
  )
  const amount = useMemo(
    () => sumDecimal(transactions.map(({ amount }) => amount)),
    [transactions]
  )

  return (
    <Flex stack gap={32}>
      <Panel className="p-0 pt-4">
        <table className="table-fixed border-collapse w-full border-spacing-0 rounded-b">
          <thead>
            <tr>
              <th className="border-b border-0 border-solid border-grey-200 text-left text-sm pl-4 pr-2 py-3">
                Recipient
              </th>
              <th className="border-b border-0 border-solid border-grey-200 text-left text-sm px-2 py-3">
                Distribution
              </th>
              <th className="border-b border-0 border-solid border-grey-200 text-left text-sm px-3 py-3 w-[224px]">
                Destination
              </th>
              <th className="border-b border-0 border-solid border-grey-200 text-left text-sm px-2 py-3 w-[70px]" />
            </tr>
          </thead>
          <tbody>
            {transactionsOrder.map((personId) => (
              <TransactionGroupRow
                key={personId}
                transactions={transactionGroups[personId]}
                destinations={destinations}
                onChange={onChange}
                onDelete={onDelete}
              />
            ))}
            <tr className="bg-grey-75">
              <td className="pl-4 pr-2 py-3 font-bold rounded-bl">Total</td>
              <td colSpan={3} className="px-2 py-3 font-bold rounded-br">
                {formatUsd(amount, { showZero: true })}
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
      <Flex justifyContent="flex-end" gap={10}>
        <Button variant="secondary" size="large" onClick={onBack}>
          <Icon name={IconName.arrowLeftLong} />
          Back
        </Button>
        <Button variant="primary" size="large" onClick={onNext}>
          Next
          <Icon name={IconName.arrowRightLong} />
        </Button>
      </Flex>
    </Flex>
  )
}

export { StepPayoutDestinations }
