import { Address } from 'types'
import { BorrowerAddress } from '../../BorrowerAddress'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalBorrowerProps {
  addresses: Address[]
  saving?: boolean
  onSave: (addresses: Record<'primary' | 'mailing', Address>) => void
  onCancel: () => void
}

function ModalAddress({
  addresses,
  saving,
  onSave,
  onCancel,
}: ModalBorrowerProps) {
  return (
    <Modal
      title={'Address'}
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      <BorrowerAddress
        addresses={addresses}
        saving={saving}
        onSave={onSave}
        onCancel={onCancel}
      />
    </Modal>
  )
}

export default ModalAddress
