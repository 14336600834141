import { capitalize } from 'lodash'
import { LoanDocument } from 'types'
import { ValueOf } from 'utils/typescript'
import { Icon, IconName } from '../Icon'
import { Badge, BadgeProps } from './index'

interface StatusBadgeProps {
  status: LoanDocument['status']
  changeable?: boolean
  className?: string
  isSignature?: boolean
}

const statusToColor: Record<
  LoanDocument['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  in_review: 'yellow',
  accepted: 'green',
  rejected: 'red',
  expired: 'red',
  pending: 'gray',
  requested: 'blue',
  signed: 'green',
  recalled: 'red',
  declined: 'red',
}

function DocumentStatusBadge({
  status,
  changeable,
  className,
  isSignature,
}: StatusBadgeProps) {
  return (
    <Badge color={statusToColor[status]} className={className}>
      {status === 'requested' && isSignature && (
        <Icon
          name={IconName.signature}
          className="text-blue-200 align-middle mr-1"
        />
      )}
      {status === 'signed' && (
        <Icon
          name={IconName.signature}
          className="text-green-200 align-middle mr-1"
        />
      )}
      {['recalled', 'declined'].includes(status) && (
        <Icon
          name={IconName.signature}
          className="text-red-100 align-middle mr-1"
        />
      )}
      {capitalize(status.replace('_', ' '))}
      {changeable && <Icon name={IconName.arrowDown} />}
    </Badge>
  )
}

export default DocumentStatusBadge
