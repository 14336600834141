import { Field, Checkbox } from 'components/Form'
import { Grid } from 'components/Grid'

function TextForm() {
  return (
    <>
      <Grid.Item xs={12}>
        <Checkbox label="Allow multiple lines of text" name="multiline" />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Field
          label="Placeholder"
          type="text"
          name="placeholder"
          placeholder="e.g. Example text"
          bottomHint="Include an example inside the field."
        />
      </Grid.Item>
    </>
  )
}

export { TextForm }
