import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { size } from 'lodash'
import { getOffering } from 'admin/services/api/offerings'
import { getOfferings } from 'borrower/services/api/offerings'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { Filter, Pagination, Sort } from 'types'

interface Props {
  type: 'open' | 'closed'
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
}

const useOfferings = (
  { type, search, filter, pagination, sort }: Props = { type: 'open' }
) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_OFFERINGS, type, search, nextFilter, pagination, sort],
    queryFn: () =>
      getOfferings({
        type,
        search,
        filter: nextFilter,
        page: pagination,
        sort,
      }),
    placeholderData: keepPreviousData,
  })
}

const useOffering = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id],
    queryFn: () => getOffering(id),
  })
}

export { useOfferings, useOffering }
