import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getTransactions,
  getTransactionFilters,
} from 'admin/services/api/transactions'
import { KEY_TRANSACTIONS } from 'constants/query-keys'
import { Filter, Pagination } from 'types/table'

const useTransactions = ({
  filter,
  pagination,
}: {
  filter?: Filter
  pagination?: Pagination
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_TRANSACTIONS, nextFilter, pagination],
    queryFn: () => getTransactions({ filter: nextFilter, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

const useTransactionFilters = (columns: string[]) => {
  return useQuery({
    queryKey: [KEY_TRANSACTIONS, 'filters'],
    queryFn: () => getTransactionFilters(columns),
  })
}

export { useTransactions, useTransactionFilters }
