import { getInvestors } from 'admin/services/api/investors'
import { Row, Sort } from 'types'

const downloadInvestors = async (
  sort?: Sort,
  search?: string
): Promise<Row[]> => {
  const { investors } = await getInvestors({
    sort,
    search,
    filter: { isAccount: [true] },
  })
  return investors.map((borrower) => ({
    Name: borrower.name,
    Type: borrower.type === 'individual' ? 'Personal' : 'Company',
    Email: borrower.email,
    Status: borrower.status,
  }))
}

export { downloadInvestors }
