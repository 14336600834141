import { capitalize } from 'lodash'
import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'

type IPersonType = 'borrower' | 'investor' | 'vendor'

interface Props {
  personType: IPersonType
  onClick: () => void
}

function EmptyPersonFieldPanel({ personType, onClick }: Props) {
  return (
    <EmptyView>
      <EmptyView.Title title={`Custom ${capitalize(personType)} Fields`} />
      <EmptyView.Description
        description={`Custom ${capitalize(personType)} Fields let you store specialized investor data, including values, statistics and other data that is not available by default. Create a Custom ${capitalize(personType)} Field here and you’ll immediately have access to this field for all of your ${personType} profiles.`}
      />
      <Flex className="mt-6" justifyContent="center">
        <Button onClick={onClick}>Add Custom Field</Button>
      </Flex>
    </EmptyView>
  )
}

export { EmptyPersonFieldPanel }
