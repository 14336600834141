import clsx from 'clsx'
import {
  HTMLAttributes,
  forwardRef,
  ForwardedRef,
  memo,
  ReactNode,
} from 'react'
import { Flex } from 'components/Flex'
import styles from './styles.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
  variant?: 'default' | 'primary'
  className?: string
}
const ButtonGroup = memo(
  forwardRef(
    (
      { children, className, variant, ...rest }: Props,
      ref: ForwardedRef<HTMLDivElement | null>
    ) => {
      const classNames = clsx(
        className,
        variant === 'primary' && styles.primary,
        styles.buttonGroup
      )

      return (
        <Flex {...rest} ref={ref} className={classNames}>
          {children}
        </Flex>
      )
    }
  )
)
export { ButtonGroup }
