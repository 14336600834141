import { PanelClasses } from 'admin/components/PanelClasses'
import { useUpdateLoan } from 'admin/hooks/use-loans'
import { Loan, Field, Class } from 'types'
import { getField, getFieldsBySection, mergeFields } from 'utils/fields'

interface Props {
  loan: Loan
}

function PanelTranches({ loan }: Props) {
  const { mutateAsync: updateLoan } = useUpdateLoan({
    id: loan.id,
  })
  const tranchesFields = getFieldsBySection(loan.fields, 'Tranches')
  const tranchesWeightedAverageField = getField(
    loan.fields,
    'tranches-weighted-average',
    true
  )

  if (tranchesFields.length === 0) {
    return null
  }

  return (
    <PanelClasses
      lock={loan.lock}
      loanRate={loan.rate}
      classes={
        tranchesFields.map(({ id, name, order, editable, property }) => ({
          id,
          name,
          order,
          editable,
          amount: property.value[0] as number,
          rate: property.value[1] as number,
        })) as Class[]
      }
      average={tranchesWeightedAverageField?.property.value?.[0] as number}
      save={(classes) =>
        updateLoan({
          fields: mergeFields(
            loan.fields,
            classes.map(
              ({ id, name, order, editable, amount, rate }) =>
                ({
                  id,
                  name: name,
                  editable: editable,
                  order: order,
                  type: ['currency', 'percentage'],
                  page: tranchesFields?.[0]?.page || 'General',
                  section: 'Tranches',
                  property: { value: [amount, rate] },
                }) as Field
            )
          ) as unknown as Field[],
        })
      }
    />
  )
}

export default PanelTranches
