import { useState } from 'react'
import { useLoanLenderOfRecord } from 'admin/hooks/use-lender-of-record'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { ModalLoanLender } from 'components/Modal/LoanLender'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { formatAddress } from 'utils/address'
import styles from './styles.module.scss'

interface Props {
  loanId: string
}

function PanelLoanLenderOfRecord({ loanId }: Props) {
  const { data: lender, isPending: isLoading } = useLoanLenderOfRecord(loanId)
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <Panel
      title="Lender of Record"
      loading={isLoading}
      onEdit={lender ? () => setIsModalVisible(true) : undefined}
    >
      {!lender && (
        <div className={styles.empty}>
          <Text className="max-w-115 pb-4 text-center leading-5">
            Choose a Lender of Record for this loan to appear in documents.
          </Text>
          <Button variant="secondary" onClick={() => setIsModalVisible(true)}>
            Select Lender of Record
          </Button>
        </div>
      )}
      {lender && (
        <div className={styles.lender} key={lender.id}>
          <Grid gap={16}>
            <Grid.Item xs={6}>
              <Summary name="Name">{lender.name}</Summary>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Summary name="Jurisdiction">{lender.jurisdiction}</Summary>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Summary name="Type of corporation">{lender.type}</Summary>
            </Grid.Item>
            <Grid.Item xs={6}>
              <Summary name="Address">{formatAddress(lender.address)}</Summary>
            </Grid.Item>
          </Grid>
        </div>
      )}
      {isModalVisible && (
        <ModalLoanLender
          loanId={loanId}
          lenderId={lender?.id as string}
          onCancel={() => {
            setIsModalVisible(false)
          }}
        />
      )}
    </Panel>
  )
}

export { PanelLoanLenderOfRecord }
