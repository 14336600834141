import clsx from 'clsx'

interface Props {
  email: string
  className?: string
}

function LinkEmail({ email, className }: Props) {
  return (
    <a
      href={`mailto:${email}`}
      className={clsx('link', className)}
      onClick={(e) => e.stopPropagation()}
    >
      {email}
    </a>
  )
}

export default LinkEmail
