import DOMPurify from 'dompurify'
import { useMemo } from 'react'
import {
  IActionConfig,
  IActionValue,
  IConditionConfig,
  IConditionValue,
  ITriggerConfig,
  ITriggerValue,
} from 'types'
import { formatUsd } from 'utils/currency'
import { formatNumber } from 'utils/number'
import { formatPercent } from 'utils/percent'

const conditions = {
  eq: '',
  gt: '<span class="font-bold">Greater than</span>',
  gte: '<span class="font-bold">Greater than or Equal to</span>',
  lt: '<span class="font-bold">Less than</span>',
  lte: '<span class="font-bold">Less than or Equal to</span>',
}

const getOptionsHtml = (
  item: ITriggerConfig | IConditionConfig | IActionConfig,
  value: ITriggerValue | IConditionValue | IActionValue
) => {
  const labels = (value.value as string[])?.map((val) => {
    const option = item.options?.find((option) => option.value === val)
    return option?.label || val
  })
  const lastLabel = labels?.length > 1 ? labels.pop() : undefined
  const pills = labels
    ?.map(
      (val: string) =>
        `<div class="px-2 py-1 bg-grey-100 rounded-sm font-bold">${val}</div>`
    )
    .join(', ')
  const lastPill = lastLabel
    ? ` or <div class="px-2 py-1 bg-grey-100 rounded-sm font-bold">${lastLabel}</div>`
    : ''

  return DOMPurify.sanitize(
    `${item.sentence.replace('{{value}}', `${pills}${lastPill}`)}`
  )
}

interface Props {
  itemConfig: ITriggerConfig | IConditionConfig | IActionConfig
  value: ITriggerValue | IConditionValue | IActionValue
}

const MenuItem = ({ itemConfig, value }: Props) => {
  const itemByType = useMemo(
    () =>
      new Map([
        [
          'single',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(itemConfig.sentence),
              }}
            />
          ),
        ],
        [
          'currency',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatUsd(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'percentage',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatPercent(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'number',
          () => (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  itemConfig.sentence.replace(
                    '{{value}}',
                    `${conditions[(value as IConditionValue).condition as string]} ${formatNumber(value.value as string)}`
                  )
                ),
              }}
            />
          ),
        ],
        [
          'options',
          () => (
            <div
              className="flex flex-wrap gap-1"
              dangerouslySetInnerHTML={{
                __html: getOptionsHtml(
                  itemConfig,
                  value as ITriggerValue | IActionValue
                ),
              }}
            />
          ),
        ],
      ]),
    [itemConfig, value]
  )

  return itemByType.get(itemConfig?.type)?.() || <div></div>
}

export { MenuItem }
