import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableDistributions } from 'admin/components/table/TableDistributions'
import {
  useAddFundDistribution,
  useDeleteFundDistribution,
  useFundDistributions,
} from 'admin/hooks/use-fund-distribution'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalDistribution } from 'components/Modal/Distribution'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Fund } from 'types'

interface Props {
  fund: Fund
}

function TabDistributions({ fund }: Props) {
  const navigate = useNavigate()
  const [addingDistribution, setAddingDistribution] = useState(false)
  const [deletingDistributionId, setDeletingDistributionId] = useState<string>()
  const { result, sort, setSort } = usePagination({
    property: 'distributions',
    useData: (params) =>
      useFundDistributions({
        fundId: fund.id,
        params: { sort: params.sort },
      }),
  })
  const { mutate: addDistribution, isPending: isAdding } =
    useAddFundDistribution(fund.id)
  const { mutate: deleteDistribution, isPending: isDeleting } =
    useDeleteFundDistribution(fund.id)

  const handleEditClick = useCallback(
    (distribution) =>
      navigate(pathTo('fundDistribution', fund.id, distribution?.id as string)),
    [fund.id]
  )

  return (
    <div>
      <Panel
        title="Distributions"
        actionChildren={
          <Button onClick={() => setAddingDistribution(true)}>
            Add Distribution
          </Button>
        }
      >
        <TableDistributions
          data={result.data}
          loading={result.isPending}
          sort={sort}
          onClick={handleEditClick}
          onEdit={handleEditClick}
          onSort={setSort}
          onDelete={({ id }) => setDeletingDistributionId(id)}
        />
      </Panel>
      {addingDistribution && (
        <ModalDistribution
          saving={isAdding}
          onSave={({ date }) =>
            addDistribution(date, {
              onSuccess: (distribution) => {
                setAddingDistribution(false)
                navigate(
                  pathTo(
                    'fundDistribution',
                    fund.id,
                    distribution?.id as string
                  )
                )
              },
            })
          }
          onCancel={() => setAddingDistribution(false)}
        />
      )}
      {deletingDistributionId && (
        <ModalDelete
          resource="distribution"
          loading={isDeleting}
          onDelete={() =>
            deleteDistribution(deletingDistributionId, {
              onSuccess: () => {
                setDeletingDistributionId(undefined)
              },
            })
          }
          onCancel={() => setDeletingDistributionId(undefined)}
        />
      )}
    </div>
  )
}

export { TabDistributions }
