import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  KEY_APPLICATION_TEMPLATES,
  KEY_APPLICATIONS,
} from 'constants/query-keys'
import {
  addGuestApplication,
  getApplicationTemplate,
  getDraftApplication,
  addDraftApplication,
  updateDraftApplication,
  signGuestApplication,
} from 'services/api/application'
import { handleErrorResponse } from 'services/request'
import { ApplicationScheme } from 'types'
import { message } from 'utils/message'

const useApplicationTemplate = (clientId: string) =>
  useQuery({
    queryKey: [KEY_APPLICATION_TEMPLATES],
    queryFn: () => getApplicationTemplate(clientId),
    enabled: !!clientId,
  })

const useGuestApplication = (clientId: string) =>
  useMutation({
    mutationFn: ({ borrowers, ...rest }: any) =>
      addGuestApplication({ ...rest, borrower: borrowers[0], clientId }),
    onError: handleErrorResponse,
  })

const useSignGuestApplication = (clientId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ applicationId }: { applicationId: string }) =>
      signGuestApplication({ id: applicationId, clientId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_APPLICATIONS],
        exact: true,
      })
      message.success('Application submitted')
    },
    onError: handleErrorResponse,
  })
}

const useDraftApplication = (
  applicationId: string,
  options: Partial<any> = {}
) =>
  useQuery({
    queryKey: [KEY_APPLICATIONS, applicationId],
    queryFn: () => getDraftApplication(applicationId),
    gcTime: 0,
    ...options,
  })

const useAddDraftApplication = (clientId: string, isGuest: boolean = false) =>
  useMutation({
    mutationFn: (data: ApplicationScheme) =>
      addDraftApplication(clientId, data, isGuest),
    onError: handleErrorResponse,
  })

const useUpdateDraftApplication = ({
  applicationId,
  clientId,
}: {
  applicationId: string
  clientId: string
}) =>
  useMutation({
    mutationFn: (data: ApplicationScheme) =>
      updateDraftApplication(clientId, applicationId, data),
    onError: handleErrorResponse,
  })

export {
  useApplicationTemplate,
  useGuestApplication,
  useSignGuestApplication,
  useDraftApplication,
  useAddDraftApplication,
  useUpdateDraftApplication,
}
