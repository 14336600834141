import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getLoanComments,
  addLoanComment,
} from 'admin/services/api/loan-comments'
import { KEY_LOAN_COMMENTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'

const commentsPerPage = 25

const useLoanComments = (
  {
    loanId,
    params = { page: 0, size: commentsPerPage },
  }: { loanId: string; params?: { page: number; size: number } },
  options: {
    enabled?: boolean
    refetchInterval?: number
  } = {}
) => {
  return useQuery({
    queryKey: [KEY_LOAN_COMMENTS, loanId, params.page, params.size],
    queryFn: () => getLoanComments(loanId, params),
    staleTime: 10000,
    ...options,
  })
}

const useAddLoanComment = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (text: string) => addLoanComment(loanId, { text }),
    onSuccess: ({ comments, meta }) => {
      queryClient.setQueryData(
        [KEY_LOAN_COMMENTS, loanId, meta.page, meta.pageSize],
        {
          comments,
          total: meta.total,
        }
      )
    },
    onError: handleErrorResponse,
  })
}

export { useLoanComments, useAddLoanComment, commentsPerPage }
