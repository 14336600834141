import { Address, Row } from 'types'
import { formatAddress } from 'utils/address'
import { formatDate } from 'utils/date'
import { getReport } from '../api/reports'

const downloadInsurance = async (params): Promise<Row[]> => {
  const { insurances } = await getReport({ type: 'loan-insurance', params })

  return insurances.map((insurance) => ({
    Loan: insurance.address?.loans[0]?.name,
    Borrower: (insurance.address?.loans[0]?.borrowers || [])
      .map(({ name }) => name)
      .join(', '),
    Address: formatAddress(insurance.address as unknown as Address),
    'Insurance Type': insurance.type,
    Premium: insurance.premium,
    'Expiration Date': formatDate(insurance.expiration),
  }))
}

export { downloadInsurance }
