import {
  parseISO,
  differenceInCalendarDays,
  differenceInBusinessDays,
} from 'date-fns'
import { Loan } from 'types'
import { formatDate } from 'utils/date'
import { Indicator, IndicatorProps } from '../Indicator'
import { Text } from '../Text'
import styles from './styles.module.scss'

interface StatusBadgeProps {
  due: string
  paid: string
  loan: Loan
}

function AmortizationTableStatus({ due, paid, loan }: StatusBadgeProps) {
  let color = 'green' as IndicatorProps['color']

  const daysLate = (
    loan.graceMethod === 'Business Days'
      ? differenceInBusinessDays
      : differenceInCalendarDays
  )(parseISO(paid), parseISO(due))
  if (daysLate > loan.graceDays) {
    color = 'red' as IndicatorProps['color']
  } else if (daysLate > 0) {
    color = 'yellow' as IndicatorProps['color']
  }

  return (
    <Text className={styles.substatus}>
      <Indicator color={color} />
      Paid {formatDate(paid)}
    </Text>
  )
}

export default AmortizationTableStatus
