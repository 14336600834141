import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'

interface CreateOptionProps extends PropsWithChildren {
  onClick: () => void
}

function CreateOption({ children, onClick }: CreateOptionProps) {
  return (
    <div className={styles.createOption} onMouseDown={onClick}>
      {children}
    </div>
  )
}

export type { CreateOptionProps }
export { CreateOption }
