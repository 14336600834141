import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import {
  isLoanServicing,
  pathTo as adminPathTo,
  pathToLoan,
} from 'admin/path-to'
import { BadgeBudgetDrawStatus } from 'components/Badge'
import { Table } from 'components/Table'
import { Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

interface Props {
  data?: any[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableDrawRequests({ data = [], sort, onSort, loading }: Props) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        id: 'dateSubmitted',
        header: 'Date',
        accessorKey: 'dateSubmitted',
        cell: ({ row }) => {
          const date = row.original.dateSubmitted
          return friendlyDate(date) || '-'
        },
        meta: {
          sortable: true,
        },
      },
      {
        id: 'loan.name',
        header: 'Loan',
        accessorKey: 'loan.name',
        cell: ({ row }) => {
          const loan = row.original.loan
          return loan ? (
            <NavLink
              to={adminPathTo(
                isLoanServicing(row.original.loan.status)
                  ? 'servicingLoanDraw'
                  : 'loanDraw',
                row.original.loanId,
                row.original.addressId,
                row.original.id
              )}
              className="link"
            >
              {loan.name}
            </NavLink>
          ) : (
            '-'
          )
        },
        meta: {
          sortable: true,
        },
      },
      {
        id: 'address',
        header: 'Address',
        accessorKey: 'address.street1',
        size: 240,
        cell: ({ row }) => {
          return row.original.loanId ? (
            <NavLink
              to={pathToLoan({
                id: row.original.loanId,
                status: row.original.loan.status,
              })}
              className="link"
            >
              {row.original.address.street1}
            </NavLink>
          ) : (
            '-'
          )
        },
      },

      {
        id: 'amountRequested',
        header: 'Requested',
        accessorKey: 'amountRequested',
        size: 120,
        cell: (cell) => formatUsd(cell.getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Approved',
        accessorKey: 'balance',
        size: 120,
        cell: ({ cell, row }) =>
          ['Approved', 'Released'].includes(row.original.status)
            ? formatUsd(cell.getValue() as string)
            : '-',
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        id: 'status',
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => (
          <BadgeBudgetDrawStatus
            status={row.original.status}
            reason={row.original.reason}
          />
        ),
        size: 100,
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableDrawRequests
