import { TableFundingTransactions } from 'admin/components/table/TableFundingTransactions'
import { useLoanTransactions } from 'admin/hooks/use-loan-funding-sources'
import { MetaFields } from 'components/MetaFields'
import { Modal } from 'components/Modal'
import { LoanFundingSource } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  fundingSource: LoanFundingSource
  title?: string
  onCancel: () => void
}

function FundingHistory({ loanId, fundingSource, title, onCancel }: Props) {
  const { data: transactions, isPending } = useLoanTransactions({
    loanId,
    fundingSourceId: fundingSource.id,
  })

  const investorTransactions = transactions?.filter(
    (t) => !['0001', '1100'].includes(t.gl) && t.payment?.status !== 'pending'
  )

  return (
    <Modal
      asChild
      loading={isPending}
      title={title || `${fundingSource.investor.name} Investment Details`}
      onClose={onCancel}
      className={styles.modal}
      crossClose
    >
      <MetaFields
        fields={[
          {
            key: 'Investment',
            value: formatUsd(fundingSource.committedAmount),
          },
          {
            key: 'Yield',
            value: `${formatPercent(fundingSource.rate)}${
              fundingSource.class ? ` (${fundingSource.class})` : ''
            }`,
          },
          {
            key: 'Returned',
            value: formatUsd(fundingSource.principalBalance),
          },
          {
            key: 'Balance',
            value: formatUsd(fundingSource.amount),
          },
          {
            key: 'Income',
            value: formatUsd(fundingSource.interestBalance),
          },
        ]}
      />
      <div className={styles.container}>
        <TableFundingTransactions
          data={investorTransactions}
          loading={isPending}
        />
      </div>
    </Modal>
  )
}

export default FundingHistory
