import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Field } from 'components/Form'
import { Grid } from 'components/Grid'
import { Trust } from 'types'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
})

type FormValues = {
  name: string
}

interface Props {
  trust: Trust | null
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

function ModalTrust({ trust, saving, onSubmit, onCancel }: Props) {
  const initialValue: FormValues = {
    name: trust?.name || '',
  }

  return (
    <Modal title="Trust Account" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="name"
                label="Trust Account Name"
                name="name"
                placeholder="Trust Account Name"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalTrust
