import { useState } from 'react'
import { TableTrust } from 'admin/components/table'
import {
  useTrusts,
  useAddTrust,
  useEditTrust,
  useDeleteTrust,
} from 'admin/hooks/use-trusts'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalTrust } from 'components/Modal/Trust'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Trust } from 'types'
import styles from './styles.module.scss'

export const PanelTrust = () => {
  const [addingTrust, setAddingTrust] = useState(false)
  const [editingTrust, setEditingTrust] = useState<Trust | null>(null)
  const [deletingTrust, setDeletingTrust] = useState<Trust | null>(null)
  const { data: trusts, isPending: isTrustLoading } = useTrusts()
  const { mutate: addTrust, isPending: isAdding } = useAddTrust()
  const { mutate: editTrust, isPending: isEditing } = useEditTrust()
  const { mutate: deleteTrust, isPending: isDeleting } = useDeleteTrust()

  return (
    <Panel title="Trust Accounts" className="max-w-panel">
      <TableTrust
        loading={isTrustLoading}
        data={trusts}
        onEdit={(trust) => setEditingTrust(trust)}
        onDelete={(trust) => setDeletingTrust(trust)}
      />
      <div className={styles.link}>
        <TextLink onClick={() => setAddingTrust(true)}>
          <Icon name={IconName.plus} size="sm" />
          Add Trust
        </TextLink>
      </div>
      {(addingTrust || !!editingTrust) && (
        <ModalTrust
          trust={editingTrust}
          saving={isAdding || isEditing}
          onSubmit={(trust) => {
            if (editingTrust) {
              editTrust(
                { id: editingTrust.id, ...trust },
                {
                  onSuccess: () => {
                    setEditingTrust(null)
                  },
                }
              )
            } else {
              addTrust(trust, {
                onSuccess: () => {
                  setAddingTrust(false)
                },
              })
            }
          }}
          onCancel={() => {
            setEditingTrust(null)
            setAddingTrust(false)
          }}
        />
      )}
      {deletingTrust && (
        <ModalDelete
          resource="trust"
          loading={isDeleting}
          name={deletingTrust.name}
          onDelete={() =>
            deleteTrust(deletingTrust.id, {
              onSuccess: () => {
                setDeletingTrust(null)
              },
            })
          }
          onCancel={() => setDeletingTrust(null)}
        />
      )}
    </Panel>
  )
}
