import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Avatar } from 'components/Avatar'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { NotificationsIcon } from 'components/Notifications'
import { Tooltip } from 'components/Tooltip'
import { useSession } from 'hooks/use-session'

const TopMenu = () => {
  const navigate = useNavigate()
  const { user } = useSession()
  const [menuOpen, setMenuOpen] = useState(false)
  const id = user?.admin?.id ?? user?.person?.id
  const name = user?.admin?.name ?? user?.person?.name

  return (
    <Flex gap={8} alignItems="center">
      <NotificationsIcon buttonClassName="w-8 h-8" />
      <EllipsesActions
        onOpenChange={setMenuOpen}
        trigger={
          <div>
            <Tooltip content={menuOpen ? '' : 'My Profile and Settings'}>
              <div className="cursor-pointer">
                <Avatar id={id} name={name} />
              </div>
            </Tooltip>
          </div>
        }
      >
        <EllipsesActions.Item onSelect={() => navigate(pathTo('settings'))}>
          My Account
        </EllipsesActions.Item>
        <EllipsesActions.Item onSelect={() => navigate(pathTo('logout'))}>
          Log out
        </EllipsesActions.Item>
      </EllipsesActions>
    </Flex>
  )
}

export { TopMenu }
