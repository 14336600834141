import clsx from 'clsx'
import { PropsWithChildren, useEffect, useRef } from 'react'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  active: number[]
  focused?: boolean
  onClose: () => void
  title: string
}

export const AccordionPanel = ({
  active,
  focused,
  title,
  onClose,
  children,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (focused && ref.current) {
      const container = ref.current.closest('#payment-scrollable-area')
      ref.current.scrollIntoView({ behavior: 'instant', block: 'start' })
      container?.scrollTo(container.scrollLeft, container.scrollTop - 110)
    }
  }, [focused])

  return (
    <div ref={ref}>
      <Grid className={styles.accordionActiveHighlight}>
        {[1, 2, 3, 4].map((value) => (
          <Grid.Item
            key={value}
            xs={3}
            className={clsx({ [styles.active]: active.includes(value) })}
          />
        ))}
      </Grid>
      <div className={styles.accordion}>
        <div className={styles.accordionHeader}>
          <div className={styles.accordionTitle}>{title}</div>
          <Icon
            name={IconName.arrowUp}
            className={styles.accordionClose}
            onClick={onClose}
          />
        </div>
        {children}
      </div>
    </div>
  )
}
