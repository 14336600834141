import { Row } from 'types'
import { getReportTrustTransactions } from '../api/reports'

const downloadTrustTransactions = async (data): Promise<Row[]> => {
  const { transactions } = await getReportTrustTransactions(data)

  return transactions.map((transaction) => ({
    Date: transaction.date,
    Type: transaction.payment.type,
    Amount: transaction.amount,
    Balance: transaction.balance,
  }))
}

export { downloadTrustTransactions }
