import { useUpdateOffering } from 'admin/hooks/use-offerings'
import { Panel } from 'components/Panel'
import { Offering } from 'types'
import { InlineWysiwyg } from '../../components/InlineWysiwyg/InlineWysiwyg'

interface Props {
  offering: Offering
}

function PanelOverview({ offering }: Props) {
  const { mutateAsync: update } = useUpdateOffering(offering.id)

  return (
    <Panel title="Overview">
      <InlineWysiwyg
        value={offering.overview}
        save={(overview) => update({ overview: overview as string })}
      />
    </Panel>
  )
}

export { PanelOverview }
