import { NotificationSettings } from 'components/NotificationSettings'
import {
  useLoanNotificationSettings,
  useUpdateLoanNotificationSettings,
} from 'hooks/use-notification-settings'
import { Loan } from 'types'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabNotifications({ loan }: Props) {
  return (
    <div className={styles.tabContent}>
      <NotificationSettings
        useNotificationSettings={() => useLoanNotificationSettings(loan.id)}
        useUpdateNotificationSettings={() =>
          useUpdateLoanNotificationSettings(loan.id)
        }
      />
    </div>
  )
}
