import { getBorrowers } from 'admin/services/api/borrowers'
import { Row, Sort, Filter } from 'types'

const downloadBorrowers = async (
  sort?: Sort,
  search?: string,
  filter?: Filter
): Promise<Row[]> => {
  const { borrowers } = await getBorrowers({
    sort,
    search,
    filter: filter || { isAccount: [true] },
  })
  return borrowers.map((borrower) => ({
    Name: borrower.name,
    Type: borrower.type === 'individual' ? 'Personal' : 'Company',
    Email: borrower.email,
    Status: borrower.status,
  }))
}

export { downloadBorrowers }
