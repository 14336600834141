import { getPersons } from 'admin/services/api/persons'
import { Row, Sort, Filter } from 'types'

const downloadPeople = async (
  sort?: Sort,
  search?: string,
  filter?: Filter
): Promise<Row[]> => {
  const { people } = await getPersons({
    sort,
    search,
    filter: filter || { isAccount: [true] },
  })
  return people.map((person) => ({
    Name: person.name,
    Type: person.isBorrower
      ? 'Borrower'
      : person.isInvestor
        ? 'Investor'
        : 'Vendor',
    Email: person.email,
    Status: person.status,
    Person: person.type === 'individual' ? 'Person' : 'Company',
  }))
}

export { downloadPeople }
