import { capitalize, compact } from 'lodash'
import { useMemo } from 'react'
import { components, SingleValueProps } from 'react-select'
import { Flex } from 'components/Flex'
import { Select } from 'components/Select'
import { formatAccountNumber } from 'utils/banking'
import { formatUsd } from 'utils/currency'

interface Props {
  label?: string
  banking?: {
    accountName: string
    accountNumber: string
    accountType: string
  }
  cashBalance?: number
  value: 'banking' | 'wallet'
  onChange: (value: 'banking' | 'wallet') => void
}

export const SelectDestination = ({
  label,
  banking,
  cashBalance,
  value,
  onChange,
}: Props) => {
  const SingleValue = ({ children, ...props }: SingleValueProps<any>) => (
    <components.SingleValue {...props}>
      <Flex stack gap={4} className="py-1.5">
        <div className="font-bold truncate">{label ?? children}</div>
        {value === 'banking' && (
          <div className="font-bold text-grey-600 text-sm truncate">
            {banking?.accountName}
          </div>
        )}
        {value === 'wallet' && (
          <div className="font-bold text-grey-600 text-sm truncate">
            {formatUsd(cashBalance)}
          </div>
        )}
      </Flex>
    </components.SingleValue>
  )
  const options = useMemo(
    () =>
      compact([
        banking && {
          value: 'banking',
          label: `${capitalize(banking.accountType)} ${formatAccountNumber(banking.accountNumber)}`,
        },
        { value: 'wallet', label: 'Wallet' },
      ]),
    [banking]
  )

  return (
    <Select
      classNamePrefix="formFieldSelect"
      styles={{
        input: (baseStyles) => ({
          ...baseStyles,
          alignSelf: 'flex-start',
          paddingTop: '3px',
        }),
      }}
      value={value}
      placeholder="Select..."
      options={options}
      components={{ SingleValue }}
      onChange={(option) => {
        onChange((option as any)?.value as 'banking' | 'wallet')
      }}
    />
  )
}
