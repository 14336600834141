import clsx from 'clsx'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { Offering } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  isClosed: boolean
  openInvestModal: (investorClassId: string) => void
}

function PanelClasses({ offering, isClosed, openInvestModal }: Props) {
  return (
    <Flex stack gap={20} className="p-6 sm:pt-8 sm:p-12">
      <Header variant="h3">Offering</Header>
      {offering?.classes?.map(({ id, name, rate, amount, amountRemaining }) => (
        <Flex
          stack
          gap={8}
          key={id}
          className="border border-solid border-grey-200 rounded p-4"
        >
          <div className="text-2xl font-bold">{name}</div>
          <table className={styles.classTable}>
            <tbody>
              <tr>
                <td>
                  <Flex stack gap={6}>
                    <div className="text-sm text-grey-600">Yield</div>
                    <div className="font-bold">{formatPercent(rate)}</div>
                  </Flex>
                </td>
                <td>
                  <Flex stack gap={6}>
                    <div className="text-sm text-grey-600">Amount</div>
                    <div className="font-bold">{formatUsd(amount)}</div>
                  </Flex>
                </td>
                <td>
                  <Flex stack gap={6}>
                    <div className="text-sm text-grey-600">Remaining</div>
                    <div className="font-bold">
                      {formatUsd(amountRemaining)}
                    </div>
                  </Flex>
                </td>
                <td>
                  <Flex stack gap={6}>
                    <div className="text-sm text-grey-600">Status</div>
                    <div className="font-bold">
                      <Flex
                        gap={8}
                        alignItems="center"
                        className="text-grey-900"
                      >
                        <Indicator
                          color="green"
                          className={clsx(
                            'w-2.5 h-2.5 flex-shrink-0 rounded-[3px]',
                            isClosed ? 'bg-grey-300' : 'bg-green-50'
                          )}
                        />
                        {isClosed ? 'Closed' : 'Open'}
                      </Flex>
                    </div>
                  </Flex>
                </td>
                <td>
                  <Button
                    className={styles.classBtnInvest}
                    disabled={isClosed || amountRemaining <= 0}
                    onClick={() => openInvestModal(id)}
                  >
                    Invest
                    <Icon
                      name={IconName.arrowLeftLong}
                      className="rotate-180"
                    />
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </Flex>
      ))}
    </Flex>
  )
}

export { PanelClasses }
