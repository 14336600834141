import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

function SocialSecurityNumber(
  props: CustomFieldProps & { mask?: string; label?: string }
) {
  const mask = props.mask || '000-00-0000'
  const placeholder = mask.replace(/[^-]/g, 'X')

  return (
    <Field label={props.label || 'Social Security Number'} {...props}>
      {({ form, meta: { touched, error }, field }: FieldChildProps) => (
        <IMaskInput
          id={field.name}
          mask={mask}
          unmask="typed"
          placeholder={placeholder}
          className={clsx(styles.input, {
            [styles.errorField]: touched && error,
          })}
          autoComplete="off"
          onAccept={(value) => {
            form.setFieldValue(field.name, value as string)
          }}
          {...field}
        />
      )}
    </Field>
  )
}

export default SocialSecurityNumber
