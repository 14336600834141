import { request } from 'services/request'
import { Transaction, LoanFundingSource } from 'types'

interface LoanTransfer {
  from: [
    {
      fundingSourceId: string
      amount: number
    },
  ]
  to: [
    {
      rate: number
      amount: number
      investorId: string
    },
  ]
  dateInvested: string
}

const getLoanFundingSources = async (
  loanId: string
): Promise<{
  fundingSources: LoanFundingSource[]
  total: { committed: number; invested: number }
}> => {
  const {
    data: { fundingSources, total },
  } = await request.get(`/loan/${loanId}/funding_source`)
  return {
    fundingSources: fundingSources.map(({ rate, ...foundingSource }) => ({
      ...foundingSource,
      rate: rate ? rate * 100 : rate,
    })),
    total,
  }
}

const addLoanFundingSource = async (
  loanId: string,
  fundingSource: Partial<LoanFundingSource>
): Promise<{
  fundingSources: LoanFundingSource[]
  total: { committed: number; invested: number }
}> => {
  const {
    data: { fundingSources, total },
  } = await request.post(`/loan/${loanId}/funding_source`, {
    ...fundingSource,
    rate: fundingSource.rate ? fundingSource.rate / 100 : fundingSource.rate,
  })
  return {
    fundingSources: fundingSources.map(({ rate, ...foundingSource }) => ({
      ...foundingSource,
      rate: rate ? rate * 100 : rate,
    })),
    total,
  }
}

const updateLoanFundingSource = async (
  loanId: string,
  fundingSource: LoanFundingSource
): Promise<{
  fundingSources: LoanFundingSource[]
  total: { committed: number; invested: number }
}> => {
  const {
    data: { fundingSources, total },
  } = await request.patch(
    `/loan/${loanId}/funding_source/${fundingSource.id}`,
    {
      ...fundingSource,
      rate: fundingSource.rate ? fundingSource.rate / 100 : fundingSource.rate,
    }
  )
  return {
    fundingSources: fundingSources.map(({ rate, ...foundingSource }) => ({
      ...foundingSource,
      rate: rate ? rate * 100 : rate,
    })),
    total,
  }
}

const deleteLoanFundingSource = async (
  loanId: string,
  fundingSourceId: string
): Promise<{
  fundingSources: LoanFundingSource[]
  total: { committed: number; invested: number }
}> => {
  const {
    data: { fundingSources, total },
  } = await request.delete(`/loan/${loanId}/funding_source/${fundingSourceId}`)
  return {
    fundingSources: fundingSources.map(({ rate, ...foundingSource }) => ({
      ...foundingSource,
      rate: rate ? rate * 100 : rate,
    })),
    total,
  }
}

const addLoanTransfer = async (
  loanId: string,
  transfer: LoanTransfer
): Promise<{
  fundingSources: LoanFundingSource[]
  total: { committed: number; invested: number }
}> => {
  const {
    data: { fundingSources, total },
  } = await request.put(`/loan/${loanId}/funding_source`, {
    ...transfer,
    from: transfer.from.map(({ amount, ...from }) => ({
      amount: parseFloat(amount as unknown as string),
      ...from,
    })),
    to: transfer.to.map(({ rate, amount, ...to }) => ({
      rate: rate / 100,
      amount: parseFloat(amount as unknown as string),
      ...to,
    })),
  })
  return {
    fundingSources: fundingSources.map(({ rate, ...foundingSource }) => ({
      ...foundingSource,
      rate: rate ? rate * 100 : rate,
    })),
    total,
  }
}

const getLoanTransactions = async (
  loanId: string,
  fundingSourceId: string
): Promise<Transaction[]> => {
  const {
    data: { transactions },
  } = await request.get(
    `/loan/${loanId}/funding_source/${fundingSourceId}/transaction`
  )
  return transactions
}

export type { LoanTransfer }
export {
  getLoanFundingSources,
  addLoanFundingSource,
  updateLoanFundingSource,
  deleteLoanFundingSource,
  addLoanTransfer,
  getLoanTransactions,
}
