import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { Application as ApplicationType } from 'types'
import { formatAddress } from 'utils/address'
import { TabDocuments } from './TabDocuments'
import { TabLoanApplication } from './TabLoanApplication'
import { TabScopeOfWork } from './TabScopeOfWork'
import styles from './styles.module.scss'

interface Props {
  application: ApplicationType
}

export const Application = ({ application }: Props) => {
  const { tab } = useParams() as { tab: string }
  const navigate = useNavigate()

  const isApplicationComplete =
    !!application.applicationId && application.signature

  const handleAddApplication = () => {
    navigate(pathTo('applicationDraft', application.id))
  }

  return (
    <>
      <PageTopFields
        fields={[
          {
            key: 'ID',
            value: application.number,
          },
          {
            key: 'Property Address',
            value: formatAddress(application.addresses?.[0]),
          },
        ]}
      />
      <Tabs
        key={tab}
        defaultActiveId={tab || 'loanApplication'}
        onTabSelect={(tabId) =>
          navigate(pathTo('applicationSubmitted', application.id, tabId))
        }
        className={styles.tabs}
      >
        {isApplicationComplete ? (
          <Tabs.Pane tab="Application" id="loanApplication">
            <TabLoanApplication
              loanId={application.id}
              applicationId={application.applicationId as string}
              isDraft={application.isDraft}
            />
          </Tabs.Pane>
        ) : (
          <Tabs.Pane tab="Application" id="loanApplication">
            <Flex
              stack
              gap={20}
              justifyContent="center"
              alignItems="center"
              onClick={handleAddApplication}
              className="min-h-[350px] rounded-lg cursor-pointer hover:bg-grey-50 border border-dashed border-grey-200"
            >
              <Flex
                className="h-16 w-16 m-4 rounded bg-grey-75"
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  name={
                    application.applicationId
                      ? IconName.documentEdit
                      : IconName.documentPlus
                  }
                  className="w-12 h-12 text-grey-500"
                />
              </Flex>
              <div className="text-2xl font-bold">
                {application.applicationId
                  ? 'Continue your loan application'
                  : 'Start your loan application'}
              </div>
            </Flex>
          </Tabs.Pane>
        )}

        <Tabs.Pane tab="Documents" id="documents">
          <TabDocuments application={application} />
        </Tabs.Pane>
        <Tabs.Pane
          tab="Scope of Work"
          id="scopeOfWork"
          className={styles.tabWithNavigation}
        >
          <TabScopeOfWork application={application} />
        </Tabs.Pane>
      </Tabs>
    </>
  )
}
