import { omitBy, size } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableInvestments } from 'admin/components/table'
import { useFundFundingSources } from 'admin/hooks/use-fund-transaction'
import { pathToLoan } from 'admin/path-to'
import { downloadFundingSource } from 'admin/services/csv/download-funding-source'
import { Download } from 'components/Download'
import { ModalFundingHistory } from 'components/Modal/FundingHistory'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { usePagination } from 'hooks/use-pagination'
import { Fund, LoanFundingSource } from 'types'

function TabPortfolio({ fund }: { fund: Fund }) {
  const { result, filter, sort, setFilter, setSort } = usePagination({
    property: 'fundingSources',
    useData: (params) =>
      useFundFundingSources({
        fundId: fund.id,
        params: { filter: params.filter, sort: params.sort },
      }),
  })
  const navigate = useNavigate()
  const [transactionDetails, setTransactionDetails] =
    useState<LoanFundingSource | null>()

  return (
    <Panel
      title="Portfolio"
      actionChildren={
        <>
          <Download
            filename="portfolio"
            btnName="Export"
            variant="tertiary"
            download={() => downloadFundingSource(fund.id)}
          />
        </>
      }
    >
      <TableInvestments
        data={result.data}
        loading={result.isPending}
        filter={filter}
        sort={sort}
        onSort={setSort}
        onFilter={(newFilter) => {
          setFilter(
            omitBy({ ...filter, ...newFilter }, (value) => !size(value))
          )
        }}
        formatName={(fundingSource) => (
          <TextLink
            onClick={() =>
              navigate(pathToLoan(fundingSource.loan, 'loanFunding'))
            }
          >
            {fundingSource.loan?.name || ''}
          </TextLink>
        )}
        onClick={(fundingSource) => {
          if (fundingSource?.loan) {
            setTransactionDetails({
              ...fundingSource,
              fund: {
                ...fund,
                investmentId: fundingSource.id,
              },
            } as LoanFundingSource)
          }
        }}
      />
      {transactionDetails && (
        <ModalFundingHistory
          title={transactionDetails.loan?.name}
          loanId={transactionDetails.loan?.id}
          fundingSource={transactionDetails}
          onCancel={() => setTransactionDetails(undefined)}
        />
      )}
    </Panel>
  )
}

export { TabPortfolio }
