import {
  autoUpdate,
  FloatingPortal,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { capitalize } from 'lodash'
import { Fragment, useCallback, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoanDocument } from 'types'

interface Props {
  className?: string
  statuses: LoanDocument['status'][]
  status: LoanDocument['status']
  isSignature?: boolean
  onSelect: (status: LoanDocument['status']) => void
  onClick?: (event: any) => void
}

function StatusSelect({
  className,
  statuses,
  status,
  isSignature,
  onSelect,
  onClick,
}: Props) {
  const [open, setOpen] = useState(false)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift()],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [useClick(context), useRole(context, { role: 'menu' }), useDismiss(context)]
  )

  const handleSelect = useCallback(
    (status: LoanDocument['status']) => {
      onSelect(status)
      setOpen(false)
    },
    [onSelect]
  )

  return (
    <div className={clsx('inline-block', className)} onClick={onClick}>
      <Button
        variant="ghost"
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <Flex justifyContent="space-between" alignItems="center" gap={6}>
          <Flex alignItems="center" gap={6}>
            {status === 'requested' && isSignature ? (
              <Icon name={IconName.signature} className="text-blue-200" />
            ) : status === 'signed' ? (
              <Icon name={IconName.signature} className="text-green-100" />
            ) : ['recalled', 'declined'].includes(status) ? (
              <Icon name={IconName.signature} className="text-red-100" />
            ) : (
              <div
                className={clsx(
                  'w-2 h-2 rounded-[3px] flex-shrink-0',
                  status === 'in_review' && 'bg-yellow-100',
                  status === 'accepted' && 'bg-green-100',
                  status === 'rejected' && 'bg-red-100',
                  status === 'expired' && 'bg-red-100',
                  status === 'pending' && 'bg-grey-600',
                  status === 'requested' && 'bg-blue-200',
                  status === 'recalled' && 'bg-red-100',
                  status === 'declined' && 'bg-red-100'
                )}
              />
            )}
            <div className="whitespace-nowrap font-bold">
              {capitalize(status.replace('_', ' '))}
            </div>
          </Flex>
          <Icon
            name={IconName.arrowDownFilled}
            className="flex-shrink-0 text-grey-600"
          />
        </Flex>
      </Button>
      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
            className="z-2 border-solid border p-1 bg-white-100 rounded border-grey-100 cursor-pointer min-w-[173px] shadow-300"
          >
            {statuses.map((status) => {
              return (
                <Fragment key={status}>
                  {status === 'accepted' && (
                    <hr className="h-[1px] my-1.5 bg-grey-100 border-0" />
                  )}

                  <Flex
                    justifyContent="left"
                    alignItems="center"
                    gap={8}
                    className={clsx(
                      'px-2 py-2 rounded',
                      status === 'in_review' && 'hover:bg-yellow-50',
                      status === 'accepted' && 'hover:bg-green-25',
                      status === 'rejected' && 'hover:bg-red-25',
                      status === 'expired' && 'hover:bg-red-25',
                      status === 'pending' && 'hover:bg-grey-100',
                      status === 'requested' && 'hover:bg-blue-25'
                    )}
                    {...getItemProps({
                      onClick: () =>
                        handleSelect(status as LoanDocument['status']),
                    })}
                  >
                    <div
                      className={clsx(
                        'w-2 h-2 rounded-[3px] flex-shrink-0',
                        status === 'in_review' && 'bg-yellow-100',
                        status === 'accepted' && 'bg-green-100',
                        status === 'rejected' && 'bg-red-100',
                        status === 'expired' && 'bg-red-100',
                        status === 'pending' && 'bg-grey-600',
                        status === 'requested' && 'bg-blue-200'
                      )}
                    />
                    <div className="whitespace-nowrap">
                      {capitalize(status.replace('_', ' '))}
                    </div>
                  </Flex>
                </Fragment>
              )
            })}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export { StatusSelect }
