import clsx from 'clsx'
import { Formik } from 'formik'
import { debounce, isEqual, isUndefined } from 'lodash'
import { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SwitcherContext } from 'borrower/components/Switcher'
import { useSignApplication } from 'borrower/hooks/use-application'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { useUpdateDraftApplication } from 'hooks/use-application'
import { useSession } from 'hooks/use-session'
import { ApplicationScheme, Person } from 'types'
import { ApplicationField } from './ApplicationField'
import { Success } from './Success'
import { schemeToValidationSchema, schemeToValues } from './helpers'
import styles from './styles.module.scss'

interface Props {
  applicationId: string
  clientId: string
  initialApplicationScheme: ApplicationScheme
}

export const DraftApplication = ({
  applicationId,
  clientId,
  initialApplicationScheme,
}: Props) => {
  const navigate = useNavigate()
  const { user, isManager } = useSession()
  const { setOpen: setOpenAccountSwitcher } = useContext(SwitcherContext)
  const isPersonalAccount =
    user?.actor?.id === user?.person?.id && user?.person?.isAccount
  const isEditable = isManager || isPersonalAccount
  const { mutate: submit } = useSignApplication(applicationId)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [scheme, setScheme] = useState<ApplicationScheme>(
    initialApplicationScheme
  )
  const actorName = user?.actor?.name
  const tags = useMemo(() => scheme.pages.map(({ tag }) => tag), [scheme])
  const defaultTagIndex = useMemo(
    () =>
      Math.max(
        0,
        ...scheme.pages
          .map(({ complete }, index) => ({ index, complete }))
          .filter(({ complete }) => complete)
          .map(({ index }) => index)
      ),
    [scheme]
  )
  const [currentTagIndex, setCurrentTagIndex] =
    useState<number>(defaultTagIndex)
  const { id, tab } = useParams() as {
    id: string
    tab: string
  }
  const maxAvailableTagIndex = useMemo(
    () =>
      Math.max(
        currentTagIndex,
        ...scheme.pages
          .map(({ complete }, index) => ({ index, complete }))
          .filter(({ complete }) => complete)
          .map(({ index }) => index)
      ),
    [currentTagIndex, scheme.pages]
  )
  const page = useMemo(
    () => scheme.pages[currentTagIndex],
    [scheme, currentTagIndex]
  )
  const { mutate: update } = useUpdateDraftApplication({
    applicationId: id,
    clientId,
  })
  const save = useCallback(
    debounce((scheme: ApplicationScheme, submitted: boolean = false) => {
      update(
        {
          ...scheme,
          submitted,
        },
        {
          onSuccess: () => {
            if (submitted) {
              submit(undefined, {
                onSettled: () => {
                  setIsSubmitting(false)
                },
                onSuccess: () => {
                  setIsSubmitted(true)
                },
              })
            }
          },
        }
      )
    }, 500),
    [update]
  )
  const goToPage = useCallback(
    (pageIndex: number) => {
      if (isUndefined(scheme.pages[pageIndex])) {
        setIsSubmitting(true)
        save(scheme, true)
      } else {
        navigate(pathTo('applicationDraft', id, pageIndex.toString()))
      }
    },
    [save, scheme, currentTagIndex]
  )
  const goToNextPage = useCallback(() => {
    goToPage(currentTagIndex + 1)
  }, [goToPage, currentTagIndex])
  const handleBack = useCallback(() => {
    goToPage(currentTagIndex - 1)
  }, [goToPage, currentTagIndex])
  const initialValues = useMemo(
    () =>
      schemeToValues(scheme, currentTagIndex, user?.actor as unknown as Person),
    [currentTagIndex, user?.borrower]
  )
  const handleSwitch = useCallback(
    () => setOpenAccountSwitcher(true),
    [setOpenAccountSwitcher]
  )
  const Scheme = useMemo(
    () => schemeToValidationSchema(scheme, currentTagIndex),
    [currentTagIndex]
  )
  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page, index) => ({
          ...page,
          complete: index === currentTagIndex ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            ...(field.type === 'signature' &&
            !isUndefined(values[`${field.id}_checkbox`])
              ? {
                  signatureConfirmationCheckbox: values[`${field.id}_checkbox`]
                    ? new Date().toISOString()
                    : '',
                }
              : {}),
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }
      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        save(nextScheme)
      }
    },
    [scheme, currentTagIndex]
  )

  useEffect(() => {
    setCurrentTagIndex(isNaN(Number(tab)) ? defaultTagIndex : Number(tab))
  }, [tab])

  return isSubmitted ? (
    <Success>
      <Button onClick={() => navigate(pathTo('applications'))}>
        Back to applications
      </Button>
    </Success>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Scheme}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      <Form
        onChange={updateScheme}
        className={clsx(currentTagIndex === 0 && '-mt-8')}
      >
        {currentTagIndex === 0 && (
          <Flex
            justifyContent="center"
            alignItems="center"
            className="mx-auto my-0 px-0 pb-8"
          >
            <Flex className="bg-grey-100 max-w-[680px] px-6 py-4 rounded-full text-grey-800 font-bold">
              This application is for {actorName}
              <TextLink
                className="font-bold !text-blue-100 hover:!text-blue-200"
                onClick={handleSwitch}
              >
                Switch account
              </TextLink>
            </Flex>
          </Flex>
        )}
        <Flex stack gap={32} className={styles.panel}>
          <Flex stack gap={16}>
            <Flex>
              <div className={styles.steps}>
                Step {currentTagIndex + 1}/{tags.length}
              </div>
              <EllipsesActions
                placement="bottom-start"
                trigger={
                  <Flex className={styles.step} alignItems="center" gap={4}>
                    {page.tag} <Icon name={IconName.arrowDown} />
                  </Flex>
                }
              >
                {tags.map((tag, index) => (
                  <EllipsesActions.Item
                    key={tag + index}
                    disabled={index > maxAvailableTagIndex}
                    onSelect={() => goToPage(index)}
                  >
                    {tag}
                  </EllipsesActions.Item>
                ))}
              </EllipsesActions>
            </Flex>
            <Header>{page.header}</Header>
            {page.description && (
              <Text className="text-grey-600">{page.description}</Text>
            )}
          </Flex>
          <div className={styles.labels}>
            {page.fields
              .filter(({ enabled }) => enabled)
              .map((field) => (
                <ApplicationField
                  key={field.id}
                  field={field}
                  disabled={!isEditable}
                />
              ))}
          </div>
          <div className={styles.controls}>
            {currentTagIndex === 0 ? (
              <Button
                variant="tertiary"
                className={clsx('rounded-full h-11 px-6 text-xl')}
                onClick={() => navigate(pathTo('applications'))}
              >
                Cancel
              </Button>
            ) : (
              <div />
            )}
            <div className={styles.buttons}>
              {currentTagIndex !== 0 && (
                <Button
                  variant="tertiary"
                  className={clsx('rounded-full h-11 w-11 text-xl')}
                  onClick={handleBack}
                >
                  ←
                </Button>
              )}
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={currentTagIndex + 1 === tags.length && !isEditable}
                className={clsx('rounded-full h-11 px-6 text-xl')}
              >
                {currentTagIndex + 1 === tags.length ? 'Submit' : 'Continue →'}
              </Button>
            </div>
          </div>
        </Flex>
      </Form>
    </Formik>
  )
}
