import { toArray } from 'lodash'
import qs from 'qs'
import { Filter } from 'types'
import { IFilterValue } from './types'

const filterValueToUrl = (value: IFilterValue): string => {
  return qs.stringify(
    value.map(({ id, condition, value }) => ({ id, c: condition, v: value })),
    { strictNullHandling: true }
  )
}

const urlToFilterValue = (value: string): IFilterValue => {
  return toArray(
    qs.parse(value, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
      arrayLimit: 500,
    }) as any
  ).map(({ id, c, v }) => ({ id, condition: c, value: v })) as IFilterValue
}

const filterValueToTableFilter = (value: IFilterValue): Filter => {
  return value.reduce((acc, filter) => {
    if (Object.prototype.hasOwnProperty.call(acc, filter.id)) {
      acc[filter.id].push([filter.condition, filter.value].flat())
    } else {
      acc[filter.id] = [[filter.condition, filter.value].flat()]
    }
    return acc
  }, {})
}

const tableFilterToFilterValue = (value: Filter): IFilterValue => {
  return Object.entries(value).reduce((acc, [id, filterValue]) => {
    if (filterValue) {
      const [condition, ...value] = filterValue
      return [
        ...acc,
        { id, condition, value: value?.length > 1 ? value : value[0] },
      ] as IFilterValue
    }
    return acc
  }, [] as IFilterValue)
}

export {
  filterValueToUrl,
  urlToFilterValue,
  filterValueToTableFilter,
  tableFilterToFilterValue,
}
