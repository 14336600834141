import {
  autoUpdate,
  flip,
  FloatingPortal,
  FloatingNode,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useFloatingNodeId,
  useFloatingTree,
} from '@floating-ui/react'
import clsx from 'clsx'
import { useState, useRef, cloneElement, useEffect, ReactElement } from 'react'
import { Icon, IconName } from '../Icon'
import { ItemProps } from './Item'
import styles from './styles.module.scss'

interface MenuProps {
  trigger?: ReactElement
  triggerClassName?: string
  placement?: Placement
  hideOnSelect?: boolean
  className?: string
  onOpenChange?: (open: boolean) => void
  children: ReactElement<ItemProps> | (ReactElement<ItemProps> | false)[]
}

function Menu({
  trigger,
  triggerClassName,
  placement = 'bottom-end',
  hideOnSelect = true,
  className,
  children,
  onOpenChange,
}: MenuProps) {
  const listRef = useRef<(HTMLDivElement | null)[]>([])
  const [open, setOpen] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const tree = useFloatingTree()
  const nodeId = useFloatingNodeId()
  const { x, y, refs, context, strategy } = useFloating({
    nodeId,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [shift(), flip()],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context),
      useRole(context, { role: 'menu' }),
      useDismiss(context),
      useListNavigation(context, {
        listRef: listRef,
        activeIndex: activeIndex,
        onNavigate: setActiveIndex,
        virtual: true,
      }),
    ]
  )

  const handleSelect = (onSelect: () => void) => () => {
    if (onSelect) {
      onSelect()
      if (hideOnSelect) {
        setOpen(false)
      }
      setActiveIndex(null)
    }
  }

  const handleKeyDown = (onSelect: () => void) => (e: KeyboardEvent) => {
    if (onSelect && e.key === 'Enter') {
      e.preventDefault()
      onSelect()
    }
  }

  useEffect(() => {
    if (!tree) {
      return
    }

    function handleTreeClick() {
      setOpen(false)
    }

    tree.events.on('click', handleTreeClick)

    return () => {
      tree.events.off('click', handleTreeClick)
    }
  }, [tree, nodeId])

  useEffect(() => {
    onOpenChange?.(open)
  }, [open])

  return (
    <>
      {[children].flat().filter((child) => child).length ? (
        trigger ? (
          cloneElement(trigger, {
            ...getReferenceProps({
              ref: refs.setReference,
              onClick(event) {
                event.stopPropagation()
              },
            }),
          })
        ) : (
          <div
            className={clsx(styles.ellipses, triggerClassName)}
            {...getReferenceProps({
              ref: refs.setReference,
              onClick(event) {
                event.stopPropagation()
              },
            })}
          >
            <Icon name={IconName.ellipses} size="md" />
          </div>
        )
      ) : null}
      {open && (
        <FloatingPortal>
          <FloatingNode id={nodeId}>
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                onClick(event) {
                  event.stopPropagation()
                },
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                },
              })}
              className={clsx(styles.dropdown, className)}
            >
              {[children]
                .flat(2)
                .filter((child) => child)
                .map((child: any, index) => {
                  const { disabled, className, onSelect } = child.props
                  return cloneElement(child, {
                    className: clsx(className, {
                      [styles.active]: activeIndex === index,
                      [styles.disabled]: disabled,
                    }),
                    ref: (node: HTMLDivElement) =>
                      (listRef.current[index] = node),
                    ...getItemProps({
                      key: index,
                      onClick: disabled ? () => {} : handleSelect(onSelect),
                      onKeyDown: handleKeyDown(onSelect) as any,
                    }),
                  })
                })}
            </div>
          </FloatingNode>
        </FloatingPortal>
      )}
    </>
  )
}

export type { MenuProps }

export default Menu
