import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addAddress,
  updateAddress,
  updateInvestor,
} from 'admin/services/api/investors'
import { KEY_INVESTORS, KEY_REPORT } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useUpdateInvestor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateInvestor,
    onError: handleErrorResponse,
    onSuccess: (investor) => {
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investor.id] })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${investor.id}/cash-balances`],
      })
      queryClient.invalidateQueries({
        queryKey: [
          KEY_REPORT,
          `investor/${investor.id}/principal-invested-balance`,
        ],
      })
      message.success('Information saved')
    },
  })
}

const useAddInvestorAddress = (investorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investorId] })
      message.success('Address saved')
    },
  })
}

const useUpdateInvestorAddress = (investorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investorId] })
      message.success('Address saved')
    },
  })
}

export { useUpdateInvestor, useAddInvestorAddress, useUpdateInvestorAddress }
