import { Formik } from 'formik'
import { createScheme, required } from '../../../utils/schemas'
import { Button } from '../../Button'
import { Field, Form } from '../../Form'
import { Grid } from '../../Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

const FormSchema = createScheme({
  name: required,
})

type FormValues = {
  name: string
}

interface Props {
  saving: boolean
  name?: string
  onSave: ({ name }: FormValues) => void
  onCancel: () => void
}

function EditDocument({ name, saving, onSave, onCancel }: Props) {
  const isEditing = !!name
  const initialValue: FormValues = {
    name: name || '',
  }

  return (
    <Modal
      title={isEditing ? 'Edit Document' : 'Create New Document'}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={FormSchema}
        onSubmit={onSave}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field name="name" label="Name" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default EditDocument
