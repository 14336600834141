export type UseOfFunds =
  | 'Purchase'
  | 'Refinance'
  | 'Cashout'
  | 'Construction'
  | 'Acquisition'
  | 'Cash Out Refinance'
export const useOfFundsOptions = [
  { value: 'Purchase', label: 'Purchase' },
  { value: 'Refinance', label: 'Refinance' },
  { value: 'Cashout', label: 'Cashout' },
  { value: 'Construction', label: 'Construction' },
]
