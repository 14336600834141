import { sortBy } from 'lodash'
import { useState } from 'react'
import {
  useAddTemplate,
  useUpdateTemplate,
  useDownloadTemplate,
  useRemoveTemplate,
  useResetTemplate,
  useTemplates,
  useUploadTemplate,
} from 'admin/hooks/use-templates'
import { defaultTemplates } from 'admin/services/api/templates'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalTemplateName } from 'components/Modal/TemplateName'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Template } from 'types'
import { openBrowseFile } from 'utils/file'
import styles from './styles.module.scss'

export const PanelTemplates = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingTemplate, setEditingTemplate] = useState<{
    id: string
    name: string
  }>()
  const [deletingTemplate, setDeletingTemplate] = useState<{
    id: string
    name: string
  }>()
  const [resettingTemplate, setResettingTemplate] = useState<Template | null>(
    null
  )
  const { data: customTemplates = [], isPending } = useTemplates()
  const { mutate: addTemplate, isPending: adding } = useAddTemplate()
  const { mutate: updateTemplate, isPending: updating } = useUpdateTemplate()
  const { mutate: removeTemplate, isPending: deleting } = useRemoveTemplate()
  const { mutate: downloadTemplate } = useDownloadTemplate()
  const { mutate: reset, isPending: isResetting } = useResetTemplate()
  const { mutate: upload } = useUploadTemplate()

  const handleUpload = (id: string, name: string) => {
    openBrowseFile({
      onChoose: (files) => upload({ id, name, file: files[0] }),
      accept: '.docx,.xlsx',
    })
  }

  return (
    <Panel
      title="Document Templates"
      loading={isPending}
      className="max-w-panel"
    >
      <Flex stack>
        {sortBy(
          [
            ...defaultTemplates,
            ...customTemplates.filter(
              ({ name }) => !defaultTemplates.some(({ id }) => id === name)
            ),
          ],
          ['name']
        ).map(({ id, name }) => (
          <Flex
            key={id}
            justifyContent="space-between"
            alignItems="center"
            className={styles.template}
          >
            <a className="link" onClick={() => downloadTemplate(id)}>
              {name}
            </a>
            <Flex gap={4}>
              <div
                className={styles.icon}
                onClick={() => handleUpload(id, name)}
              >
                <Icon name={IconName.upload} />
              </div>
              <div className={styles.icon} onClick={() => downloadTemplate(id)}>
                <Icon name={IconName.download} />
              </div>
              {!defaultTemplates.every(
                ({ id: templateId }) => templateId !== id
              ) && (
                <div
                  className={styles.icon}
                  onClick={() => setResettingTemplate({ id, name })}
                >
                  <Icon name={IconName.documentRefresh} />
                </div>
              )}
              {defaultTemplates.every(
                ({ id: templateId }) => templateId !== id
              ) && (
                <div className={styles.ellipses}>
                  <EllipsesActions>
                    <EllipsesActions.Item
                      icon
                      onSelect={() => {
                        setIsModalVisible(true)
                        setEditingTemplate({ id, name })
                      }}
                    >
                      <Icon name={IconName.edit} />
                      Rename
                    </EllipsesActions.Item>
                    <EllipsesActions.Item
                      icon
                      onSelect={() => setDeletingTemplate({ id, name })}
                      className="text-red-100"
                    >
                      <Icon name={IconName.delete} />
                      Delete
                    </EllipsesActions.Item>
                  </EllipsesActions>
                </div>
              )}
            </Flex>
          </Flex>
        ))}
        <div className={styles.link}>
          <TextLink onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" />
            Add Template
          </TextLink>
        </div>
      </Flex>
      {resettingTemplate && (
        <ModalConfirm
          title="Reset template"
          text={`Are you sure you want to reset ${resettingTemplate.name} template?`}
          loading={isResetting}
          onConfirm={() => {
            reset(resettingTemplate.id, {
              onSuccess: () => {
                setResettingTemplate(null)
              },
            })
          }}
          onCancel={() => setResettingTemplate(null)}
        />
      )}
      {isModalVisible && (
        <ModalTemplateName
          title={editingTemplate ? 'Rename Template' : 'Add New Template'}
          template={editingTemplate}
          saving={adding || updating}
          onSubmit={({ name }) => {
            const save = (editingTemplate ? updateTemplate : addTemplate) as any
            save(
              editingTemplate
                ? { id: editingTemplate.id, params: { name } }
                : name,
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                  setEditingTemplate(undefined)
                },
              }
            )
          }}
          onCancel={() => {
            setIsModalVisible(false)
            setEditingTemplate(undefined)
          }}
        />
      )}
      {deletingTemplate && (
        <ModalDelete
          resource="template"
          name={deletingTemplate.name}
          loading={deleting}
          onDelete={() =>
            removeTemplate(deletingTemplate.id, {
              onSuccess: () => setDeletingTemplate(undefined),
            })
          }
          onCancel={() => setDeletingTemplate(undefined)}
        />
      )}
    </Panel>
  )
}
