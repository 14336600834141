import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  saving: boolean
  onSave: ({ message }: { message: string }) => void
  onCancel: () => void
}

function ModalSendForRevision({ saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal
      title="Send for Revisions"
      onClose={onCancel}
      className={styles.modal}
    >
      <div>
        You&apos;re about to send this draw request back to the borrower for
        revisions. Please provide specific feedback to guide them on what
        changes are needed.
      </div>
      <Formik initialValues={initialValue} onSubmit={onSave}>
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Textarea name="message" label="Message:" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Send
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalSendForRevision }
