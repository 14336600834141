import { request } from 'services/request'
import { Comment, Meta } from 'types'

const getLoanComments = async (
  id: string,
  { page, size }: { page: number; size: number }
): Promise<{ comments: Comment[]; total: number }> => {
  const {
    data: {
      comments,
      pagination: { total },
    },
  } = await request.get(`/loan/${id}/comments`, {
    params: {
      page: { size, page },
      sort: ['created_at desc'],
    },
  })
  return { comments: comments, total }
}

const addLoanComment = async (
  id: string,
  data: { text: string }
): Promise<{ comments: Comment[]; meta: Meta }> => {
  const {
    data: { comments, pagination: meta },
  } = await request.post(`/loan/${id}/comments`, data, {
    params: {
      page: {
        size: 25,
        page: 0,
      },
      sort: ['created_at desc'],
    },
  })
  return { comments, meta }
}

export { getLoanComments, addLoanComment }
