import { ReactNode } from 'react'
import { Header } from 'components/Header'
import { Link } from './Link'

interface Props {
  title?: string
  children: ReactNode
}

export const PanelLinks = ({ title, children }: Props) => {
  return (
    <div className="rounded border border-solid border-grey-200 bg-white-100 px-5 pb-5 pt-4 shadow-100 [&>*:last-child]:border-b-0">
      {title && (
        <Header className="mb-2" variant="h4">
          {title}
        </Header>
      )}
      {children}
    </div>
  )
}

PanelLinks.Link = Link
