import { useCallback, useEffect } from 'react'
import { useSession } from 'hooks/use-session'

export const AppTheme = () => {
  const { user } = useSession()

  const loadTheme = useCallback(async () => {
    if (user?.client?.id) {
      try {
        await import(`../styles/theme.${user.client.id}.scss`)
      } catch {
        /* empty */
      }
    }
  }, [user?.client?.id])

  useEffect(() => {
    loadTheme()
  }, [loadTheme])

  return null
}
