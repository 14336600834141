import { request } from 'services/request'
import { BaseField, Filter, Meta, Pagination, Search, Sort } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

interface CustomProductField extends BaseField {
  products?: {
    id: string
    name: string
  }[]
  default?: any
}

const getCustomFields = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ fields: CustomProductField[]; meta: Meta }> => {
  const {
    data: { fields, pagination },
  } = await request.get('/application/field/custom', {
    params: updateRequestTable(params),
  })
  return {
    fields,
    meta: pagination,
  }
}

const getCustomField = async (fieldId: string): Promise<CustomProductField> => {
  const {
    data: { field },
  } = await request.get(`/application/field/custom/${fieldId}`)
  return field
}

const addCustomField = async (
  payload: Partial<CustomProductField>
): Promise<CustomProductField> => {
  const {
    data: { field },
  } = await request.post('/application/field/custom', payload)
  return field
}

const editCustomField = async ({
  fieldId,
  data,
}: {
  fieldId: string
  data: Partial<CustomProductField>
}) => {
  const {
    data: { field },
  } = await request.patch(`/application/field/${fieldId}`, data)
  return field
}

const deleteCustomTask = async (fieldId: string) => {
  return request.delete(`/application/field/${fieldId}`)
}

export {
  getCustomFields,
  getCustomField,
  addCustomField,
  editCustomField,
  deleteCustomTask,
}
