import { pathTo } from 'borrower/path-to'
import { Panel } from 'components/Panel'
import { TablePayments } from 'components/TablePayments'
import { useLoanPayments } from 'hooks/use-loan-payment'
import { useDownloadPdf } from 'hooks/use-pdf'
import { Loan } from 'types'

interface Props {
  loan: Loan
}

export const PanelPayments = ({ loan }: Props) => {
  const { data: payments, isPending: loading } = useLoanPayments(loan.id)
  const { mutate: generatePdf } = useDownloadPdf()

  const paymentsWithoutTransfers = payments?.filter(
    (payment) => payment.type !== 'Transfer'
  )

  return (
    <Panel title="Payments">
      <TablePayments
        loading={loading}
        data={paymentsWithoutTransfers}
        minWidth={800}
        showDistributions
        onDownload={({ id }) => {
          generatePdf({ url: pathTo('receipt', loan.id, id) })
        }}
      />
    </Panel>
  )
}
