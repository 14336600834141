import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Application, ApplicationSchemePage } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { ApplicationTileHeader } from './ApplicationTileHeader'
import { getApplicationStatus, bgColor, textColor } from './utils'

const isSignatureFieldPresented = (application: Application) =>
  application.data?.pages.some((page: ApplicationSchemePage) =>
    page.fields.some((field) => field.type === 'signature' && field.enabled)
  )

interface IProps {
  applications: Application[]
  isEditable: boolean
}

export const ApplicationsTiles = ({ applications, isEditable }: IProps) => {
  const navigate = useNavigate()

  const handleAddApplication = useCallback(() => {
    navigate(pathTo('applicationDraft', 'new'))
  }, [navigate])

  const handleViewDetails = useCallback(
    (application: Application) =>
      navigate(
        pathTo(
          application.isDraft ? 'applicationDraft' : 'applicationSubmitted',
          application.id
        )
      ),
    [navigate]
  )

  return (
    <Grid gap={20}>
      {applications.map((application) => {
        const applicationStatus = getApplicationStatus(
          application.status,
          application.isDraft
        )

        return (
          <Grid.Item
            md={6}
            key={application.id}
            className="border border-solid border-grey-200 rounded-lg"
          >
            <ApplicationTileHeader
              application={application}
              isEditable={isEditable}
            />
            <Flex stack gap={20} className="py-6">
              <Flex stack gap={20} className="px-5">
                <div className="text-2xl font-bold">
                  {application.name ?? '-'}
                </div>
                <Flex stack gap={6} className="leading-[20px]">
                  <Flex gap={6} justifyContent="space-between">
                    <div className="text-grey-700">Requested amount</div>
                    <div className="text-grey-900 font-bold">
                      {application.amount
                        ? `${formatUsd(application.amount)}`
                        : '-'}
                    </div>
                  </Flex>
                  <Flex gap={6} justifyContent="space-between">
                    <div className="text-grey-700">Closing Date</div>
                    <div className="text-grey-900 font-bold">
                      {application.dateClosing
                        ? friendlyDate(application.dateClosing)
                        : '-'}
                    </div>
                  </Flex>
                </Flex>
              </Flex>

              <div className="px-3 text-xl">
                <Flex
                  gap={6}
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => handleViewDetails(application)}
                  className="py-4 px-2 border-0 border-t border-b border-solid border-grey-200 cursor-pointer hover:bg-grey-50"
                >
                  <Flex gap={12} alignItems="center">
                    <Icon name={IconName.signature} className="text-grey-600" />
                    <div>Signature Required</div>
                  </Flex>
                  <Flex gap={12} alignItems="center">
                    <div
                      className={`
                        px-2 py-0 leading-5 rounded-full font-bold
                        ${bgColor[applicationStatus]}
                        ${textColor[applicationStatus]}
                      `}
                    >
                      {(application.isDraft &&
                      isSignatureFieldPresented(application)
                        ? 1
                        : 0) + (application?.numSignatures || 0)}
                    </div>
                    <Icon
                      name={IconName.arrowRight}
                      className="text-grey-600"
                    />
                  </Flex>
                </Flex>
                <Flex
                  gap={6}
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={() => handleViewDetails(application)}
                  className="py-4 px-2 border-0 border-b border-solid border-grey-200 cursor-pointer hover:bg-grey-50"
                >
                  <Flex alignItems="center">
                    <Icon
                      name={IconName.documentBlank}
                      className="text-grey-600"
                    />
                    <div>Documents Requested</div>
                  </Flex>
                  <Flex gap={12} alignItems="center">
                    <div
                      className={`
                        px-2 py-0 leading-5 rounded-full font-bold
                        ${bgColor[applicationStatus]}
                        ${textColor[applicationStatus]}
                      `}
                    >
                      {application.numRequestedDocs || 0}
                    </div>
                    <Icon
                      name={IconName.arrowRight}
                      className="text-grey-600"
                    />
                  </Flex>
                </Flex>
              </div>

              <Flex justifyContent="flex-end" className="px-4">
                <Button
                  variant="secondary"
                  size="large"
                  onClick={() => handleViewDetails(application)}
                  className="rounded-full"
                >
                  View Details
                </Button>
              </Flex>
            </Flex>
          </Grid.Item>
        )
      })}
      <Grid.Item
        md={6}
        className="border border-dashed border-grey-200 rounded-lg"
      >
        <Flex
          stack
          gap={20}
          justifyContent="center"
          alignItems="center"
          onClick={handleAddApplication}
          className="min-h-[350px] rounded-lg cursor-pointer hover:bg-grey-50"
        >
          <Flex
            className="h-16 w-16 m-4 rounded bg-grey-75"
            alignItems="center"
            justifyContent="center"
          >
            <Icon
              name={IconName.documentPlus}
              className="w-12 h-12 text-grey-500"
            />
          </Flex>
          <div className="text-2xl font-bold">Start a new loan application</div>
        </Flex>
      </Grid.Item>
    </Grid>
  )
}
