import { useState } from 'react'
import { EntityDetails } from 'admin/components/EntityDetails'
import { useAddBorrower } from 'admin/hooks/use-borrowers'
import {
  useAddLoanGuarantor,
  useUpdateLoanGuarantor,
  useDeleteLoanGuarantor,
} from 'admin/hooks/use-loans'
import { Borrower } from 'admin/services/api/borrowers'
import { EllipsesActions } from 'components/EllipsesActions'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { ModalChooseBorrower } from 'components/Modal/ChooseBorrower'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Loan } from 'types'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

function PanelGuarantor({ loan }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAddingPerson, setIsAddingPerson] = useState(false)
  const [personName, setPersonName] = useState('')
  const [addedPersonId, setAddedPersonId] = useState('')

  const addBorrower = useAddBorrower()

  const { mutate: addGuarantor, isPending: saving } = useAddLoanGuarantor({
    id: loan.id,
    onSuccess: () => {
      setIsModalVisible(false)
    },
  })
  const { mutate: updateGuarantor, isPending: updating } =
    useUpdateLoanGuarantor({
      id: loan.id,
      onSuccess: () => {
        setIsModalVisible(false)
      },
    })
  const { mutate: deleteGuarantor, isPending: deleting } =
    useDeleteLoanGuarantor({
      id: loan.id,
      onSuccess: () => {
        setIsModalVisible(false)
      },
    })
  const handleAddGuarantor = () => {
    setIsModalVisible(true)
  }

  const handleCreatePerson = (personName?: string) => {
    setIsModalVisible(false)
    setPersonName(personName ?? '')
    setIsAddingPerson(true)
  }

  return (
    <Panel title="Guarantor">
      <div className={styles.entities}>
        {loan.guarantors
          .sort((a) => (a.guarantee.primary ? -1 : 1))
          .map((borrower) => (
            <div key={borrower.id}>
              <div className={styles.entityInfoHeader}>
                <Header variant="h4">
                  {borrower.guarantee.primary
                    ? 'Primary Guarantor'
                    : 'Co-guarantor'}
                </Header>
                {!loan.lock && (
                  <div className={styles.borrowersButtons}>
                    <EllipsesActions>
                      {!borrower.guarantee.primary && (
                        <EllipsesActions.Item
                          icon
                          disabled={updating}
                          onSelect={() =>
                            updateGuarantor({
                              guarantorId: borrower.id,
                              primary: true,
                            })
                          }
                        >
                          <Icon name={IconName.star} />
                          Make primary
                        </EllipsesActions.Item>
                      )}
                      <EllipsesActions.Item
                        icon
                        disabled={deleting}
                        onSelect={() => deleteGuarantor(borrower.id)}
                        className="text-red-100"
                      >
                        <Icon name={IconName.delete} />
                        Remove
                      </EllipsesActions.Item>
                    </EllipsesActions>
                  </div>
                )}
              </div>
              <EntityDetails borrower={borrower} />
            </div>
          ))}
      </div>
      {!loan.lock && (
        <div className={styles.addLinkBox}>
          <TextLink onClick={() => handleAddGuarantor()}>
            <Icon name={IconName.plus} size="sm" />
            Add Guarantor
          </TextLink>
        </div>
      )}
      {isModalVisible && (
        <ModalChooseBorrower
          entity="Guarantor"
          saving={saving}
          onSave={({ borrower, primary }) =>
            addGuarantor({
              id: borrower,
              guarantee: { primary: primary || loan.guarantors.length === 0 },
            })
          }
          onCancel={() => setIsModalVisible(false)}
          handleAddBorrower={(personName?: string) =>
            handleCreatePerson(personName)
          }
          addedPersonId={addedPersonId}
          include={['primary']}
        />
      )}
      {isAddingPerson && (
        <ModalAddPerson
          saving={addBorrower.isPending}
          personName={personName}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            addBorrower.mutate(borrower, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
                setIsModalVisible(true)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
            setIsModalVisible(true)
          }}
        />
      )}
    </Panel>
  )
}

export default PanelGuarantor
