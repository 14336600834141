import { request } from 'services/request'
import { Person, Filter, Meta, Pagination, Sort } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

interface Props {
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}

const getPersons = async ({
  ...params
}: Props): Promise<{ people: Person[]; meta: Meta }> => {
  const {
    data: { people, pagination },
  } = await request.get('/person', {
    params: updateRequestTable({ ...params, details: true }),
  })
  return { people, meta: pagination }
}

export { getPersons }
