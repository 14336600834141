import { request } from 'services/request'
import { Statement, StatementDetails } from 'types'

const getLoanStatements = async (loanId: string): Promise<Statement[]> => {
  const {
    data: { statements },
  } = await request.get(`/loan/${loanId}/statement`)
  return statements
}

const getLoanStatement = async (
  loanId: string,
  statementId: string
): Promise<StatementDetails> => {
  const {
    data: { statement },
  } = await request.get(`/loan/${loanId}/statement/${statementId}`)
  return {
    ...statement,
    rate: statement.rate ? statement.rate * 100 : statement.rate,
  }
}

export { getLoanStatements, getLoanStatement }
