import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { Field, Checkbox } from 'components/Form'
import { Grid } from 'components/Grid'

function NumberForm() {
  const form = useFormikContext<{
    allowMinMax?: boolean
    min?: string
    max?: string
  }>()

  useEffect(() => {
    if (!form.values.allowMinMax) {
      form.setFieldValue('min', '')
      form.setFieldValue('max', '')
    }
  }, [form.values.allowMinMax])

  return (
    <>
      <Grid.Item xs={12}>
        <Checkbox label="Set min and max value" name="allowMinMax" />
      </Grid.Item>
      {form.values.allowMinMax && (
        <>
          <Grid.Item xs={6}>
            <Field label="Min" type="number" name="min" step="1" />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Field label="Max" type="number" name="max" step="1" />
          </Grid.Item>
        </>
      )}
      <Grid.Item xs={12}>
        <Field
          label="Placeholder"
          type="text"
          name="placeholder"
          placeholder="e.g. 7"
          bottomHint="Include an example inside the field."
        />
      </Grid.Item>
    </>
  )
}

export { NumberForm }
