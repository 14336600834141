import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'

interface IProps {
  itemName: string
  iconName: IconName
  onSelect: () => void
}

export const DropdownItem = ({ itemName, iconName, onSelect }: IProps) => {
  return (
    <Flex
      gap={8}
      alignItems="center"
      onClick={onSelect}
      className="group p-2 rounded-sm cursor-pointer hover:bg-grey-75"
    >
      <Flex
        className="h-7 min-w-7 rounded bg-grey-75 group-hover:bg-grey-100"
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          name={iconName}
          className="text-grey-700 group-hover:text-grey-900"
        />
      </Flex>
      <Text className="text-grey-900">{itemName}</Text>
    </Flex>
  )
}
