import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'

interface Props {
  iconName: IconName
  title: string
  subtitle?: string
  action?: React.ReactNode
}

export const NoEntities = ({ iconName, title, subtitle, action }: Props) => {
  return (
    <Panel className="min-h-64 pt-5 px-6 pb-9 border-grey-500 border-dashed text-center">
      <Flex stack gap={20} alignItems="center">
        <Flex
          className="h-16 w-16 m-4 rounded bg-grey-75"
          alignItems="center"
          justifyContent="center"
        >
          <Icon name={iconName} className="w-12 h-12 text-grey-500" />
        </Flex>
        <div className="text-2xl text-grey-900 font-bold">{title}</div>
        {subtitle && <div className="text-lg text-grey-700">{subtitle}</div>}
        {action}
      </Flex>
    </Panel>
  )
}
