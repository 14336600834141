import clsx from 'clsx'
import { isUndefined } from 'lodash'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Flex } from 'components/Flex'
import { Option } from 'components/Form'
import { Summary } from 'components/Summary'
import { Field, FieldType } from 'types'
import { formatField } from 'utils/fields'
import styles from './styles.module.scss'
import { InlineField, SavingIndicator, Undo } from './index'

type Value = string | number | null | undefined
type Status = 'untouched' | 'saving' | 'saved'

interface Props {
  name: ReactNode
  type: FieldType
  value: Value
  mask?: Field['mask']
  options?: Option[] | null
  hideEmptyOption?: boolean
  save: (value: Value) => Promise<unknown>
  format?: (value) => ReactNode
  maxLength?: number
  multiline?: boolean
  // uses for type percentage to support up to 10 decimals
  fullAccuracy?: boolean
  onOpenChange?: (isOpen: boolean) => void
  loading?: boolean
}

function InlineSummary({
  name,
  type,
  value: initialValue,
  mask,
  options,
  hideEmptyOption,
  format,
  save,
  maxLength,
  multiline,
  fullAccuracy,
  onOpenChange,
  loading,
}: Props) {
  const formattedValueRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)
  const [status, setStatus] = useState<Status>('untouched')
  const [value, setValue] = useState<Value>(initialValue)
  const [undoValue, setUndoValue] = useState<Value>(initialValue)
  const [expanded, setExpanded] = useState(false)

  // update current value to the latest if it's not saving now
  useEffect(() => {
    if (status !== 'saving') {
      setValue(initialValue)
    }
  }, [status, initialValue])

  // save current value for undo purpose
  useEffect(() => {
    if (focus) {
      setUndoValue(value)
    }
  }, [focus])

  const handleUndo = () => {
    const currentUndoValue = undoValue
    setUndoValue(value)
    setValue(currentUndoValue)
    handleSave(currentUndoValue)
  }

  const handleSave = async (nextValue: Value) => {
    setFocus(false)
    setHover(false)
    if (nextValue !== initialValue) {
      setStatus('saving')
      await save(nextValue)
      setStatus('saved')
    }
  }

  const formattedValue = format
    ? format(value)
    : formatField({
        mask,
        fullAccuracy,
        type: [type],
        property: { value: [value] },
      } as unknown as Field)

  const isOverflowing = formattedValueRef.current
    ? formattedValueRef.current.scrollHeight >
        formattedValueRef.current.clientHeight ||
      formattedValueRef.current.scrollWidth >
        formattedValueRef.current.clientWidth
    : false

  return (
    <Summary
      name={
        <Flex className={styles.summaryTitle} gap={4} alignItems="center">
          {name}
          {status !== 'untouched' && <SavingIndicator status={status} />}
          {status !== 'untouched' && <Undo onClick={handleUndo} />}
        </Flex>
      }
      className={styles.summary}
      onClick={() => {
        setFocus(true)
        setHover(false)
      }}
      onMouseEnter={() => !focus && setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        className={styles.summaryValue}
        contentEditable={!focus}
        spellCheck={false}
        suppressContentEditableWarning={true}
        onFocus={() => {
          setExpanded(true)
          setFocus(true)
        }}
      >
        <div
          className={clsx(styles.preview, !expanded && 'line-clamp-2', {
            [styles.hidden]: focus || (hover && type !== 'multiline'),
          })}
          ref={formattedValueRef}
        >
          {formattedValue}
          {multiline && '\u00A0'}
        </div>
        {type === 'multiline' && isOverflowing && !expanded && (
          <div
            className="text-blue-200 font-bold cursor-pointer"
            onMouseDown={(e) => {
              setExpanded(true)
              e.stopPropagation()
            }}
          >
            Show More
          </div>
        )}
        {!focus && hover && type !== 'multiline' && (
          <div
            className={clsx(
              styles.summaryField,
              styles.hover,
              multiline && type === 'text' && styles.multiline
            )}
          >
            <div className={styles.overlay}></div>
            <InlineField
              key={value}
              type={type}
              options={options}
              value={value}
              mask={mask}
              mode="hover"
              fullAccuracy={fullAccuracy}
              multiline={multiline}
            />
          </div>
        )}
        {focus && (
          <div
            className={clsx(
              styles.summaryField,
              ((multiline && type === 'text') || type === 'multiline') &&
                styles.multiline
            )}
          >
            <InlineField
              value={value}
              type={type}
              mask={mask}
              options={options}
              hideEmptyOption={hideEmptyOption}
              autoFocus
              multiline={multiline}
              maxLength={maxLength}
              fullAccuracy={fullAccuracy}
              onChange={(value) => setValue(value)}
              onBlur={(nextValue) => {
                handleSave(isUndefined(nextValue) ? value : nextValue)
              }}
              onEscPress={() => {
                setValue(initialValue)
                setFocus(false)
              }}
              onEnterPress={() => {
                handleSave(value)
              }}
              onOpenChange={onOpenChange}
              loading={loading}
            />
          </div>
        )}
      </div>
    </Summary>
  )
}

export default InlineSummary
