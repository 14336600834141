import { request } from 'services/request'
import { Quote } from 'types'
import { updateResponseQuote } from 'utils/api/quote-parser'

const addQuoteBorrower = async ({
  quoteId,
  personId,
}: {
  quoteId: string
  personId: string
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.put(`quote/${quoteId}/borrower`, {
    id: [personId],
  })
  return updateResponseQuote(quote)
}

const addQuoteGuarantor = async ({
  quoteId,
  personId,
}: {
  quoteId: string
  personId: string
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.put(`quote/${quoteId}/guarantor`, { id: [personId] })
  return updateResponseQuote(quote)
}

const removeQuoteBorrower = async ({
  quoteId,
  personId,
}: {
  quoteId: string
  personId: string
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.delete(`/quote/${quoteId}/borrower/${personId}`)
  return updateResponseQuote(quote)
}

const removeQuoteGuarantor = async ({
  quoteId,
  personId,
}: {
  quoteId: string
  personId: string
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.delete(`/quote/${quoteId}/guarantor/${personId}`)
  return updateResponseQuote(quote)
}

export {
  addQuoteBorrower,
  addQuoteGuarantor,
  removeQuoteBorrower,
  removeQuoteGuarantor,
}
