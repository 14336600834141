import { request } from 'services/request'

type EntityType =
  | 'borrower'
  | 'vendor'
  | 'investor'
  | 'loan'
  | 'payment'
  | 'funding'
  | 'transaction'
  | 'charge'

const getImportUploadUrl = async (
  entityType: EntityType,
  loanId?: string
): Promise<{ url: string }> => {
  const {
    data: { upload: url },
  } = await request.post(
    loanId
      ? `/import/${entityType}/${entityType === 'transaction' ? 'person' : 'loan'}/${loanId}`
      : `/import/${entityType}`
  )
  return { url }
}

const deleteImportEntities = (entityType: EntityType): Promise<void> => {
  return request.delete(`/import/${entityType}`)
}

export type { EntityType }

export { getImportUploadUrl, deleteImportEntities }
