import { drop, omit } from 'lodash'
import { request } from 'services/request'
import {
  IAutomation,
  Search,
  IAutomationLog,
  Meta,
  Pagination,
  ITriggerConfig,
  IConditionConfig,
  IActionConfig,
  IConditionValue,
  IActionValue,
} from 'types'

const parseRequest = <T>(automation: any): T => ({
  ...automation,
  trigger: automation.trigger
    ? {
        ...omit(automation.trigger, 'config'),
        ...automation.trigger.config,
      }
    : undefined,
  conditions: automation.conditions?.map((condition: IConditionValue) => ({
    ...omit(condition, 'condition', 'config'),
    value: [condition.condition ?? 'eq', ...[condition.value].flat()],
    ...condition.config,
  })),
  actions: automation.actions?.map((action: IActionValue) => ({
    ...omit(action, 'config'),
    ...action.config,
  })),
})

const parseResponse = (automation: any): IAutomation => ({
  ...automation,
  trigger: {
    id: automation.trigger.id,
    value: automation.trigger.value,
    config: omit(automation.trigger, 'value'),
  },
  conditions: automation.conditions?.map((condition) => ({
    id: condition.id,
    condition: condition.value?.[0],
    value: drop(condition.value),
    config: omit(condition, 'value'),
  })),
  actions: automation.actions?.map((action) => ({
    id: action.id,
    value: action.value,
    config: omit(action, 'value'),
  })),
})

const getTriggerConfigs = async (): Promise<{
  triggers: ITriggerConfig[]
}> => {
  const {
    data: { triggers },
  } = await request.get('/automation/triggers')
  return { triggers }
}

const getConditionConfigs = async (): Promise<{
  conditions: IConditionConfig[]
}> => {
  const {
    data: { conditions },
  } = await request.get('/automation/conditions')
  return { conditions }
}

const getActionConfigs = async (): Promise<{
  actions: IActionConfig[]
}> => {
  const {
    data: { actions },
  } = await request.get('/automation/actions')
  return { actions }
}

const getAutomations = async (params: {
  search?: Search
}): Promise<{ automations: IAutomation[] }> => {
  const {
    data: { automations },
  } = await request.get('/automation', { params })
  return {
    automations: automations.map(parseResponse),
  }
}

const getAutomation = async (automationId: string): Promise<IAutomation> => {
  const {
    data: { automation },
  } = await request.get(`/automation/${automationId}`)
  return parseResponse(automation)
}

const addAutomation = async (
  payload: Partial<IAutomation>
): Promise<IAutomation> => {
  const {
    data: { automation },
  } = await request.post(
    '/automation',
    parseRequest<Partial<IAutomation>>(payload)
  )
  return parseResponse(automation)
}

const editAutomation = async (
  payload: Partial<IAutomation>
): Promise<IAutomation> => {
  const {
    data: { automation },
  } = await request.patch(
    `/automation/${payload.id}`,
    parseRequest<Partial<IAutomation>>(payload)
  )
  return parseResponse(automation)
}

const removeAutomation = async (id: string): Promise<{ success: boolean }> => {
  return request.delete(`/automation/${id}`)
}

const handleAutomationLogAction = async (
  eventId: string
): Promise<{ success: boolean }> => {
  return request.delete(`/automation/log/${eventId}`)
}

const getAutomationsLogs = async (params: {
  search?: Search
  page?: Pagination
}): Promise<{ log: IAutomationLog[]; meta: Meta }> => {
  const {
    data: { log, pagination },
  } = await request.get('/automation/log', { params })
  return {
    log: log.map(parseResponse),
    meta: pagination,
  }
}
export {
  getAutomations,
  getAutomation,
  addAutomation,
  editAutomation,
  removeAutomation,
  getTriggerConfigs,
  getConditionConfigs,
  getActionConfigs,
  handleAutomationLogAction,
  getAutomationsLogs,
}
