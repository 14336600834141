import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getProducts,
  getProduct,
  duplicateProduct,
  addProduct,
  editProduct,
  editProductFields,
  removeProduct,
} from 'admin/services/api/products'
import { KEY_PRODUCTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { ProductProps } from 'types'
import { Filter, Pagination } from 'types/table'
import { message } from 'utils/message'

interface Props {
  search?: string
  filter?: Filter
  pagination?: Pagination
}

const useProducts = ({ search, filter, pagination }: Props = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PRODUCTS, search, nextFilter, pagination],
    queryFn: () =>
      getProducts({ search, filter: nextFilter, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

const useProduct = ({ id }: { id?: string }) => {
  return useQuery({
    queryKey: [KEY_PRODUCTS, id],
    queryFn: () => getProduct(id as string),
    enabled: !!id,
  })
}

const useDuplicateProduct = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: duplicateProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PRODUCTS] })
      message.success('Product duplicated')
    },
    onError: handleErrorResponse,
  })
}

const useAddProduct = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PRODUCTS] })
      message.success('Product saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditProduct = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: ProductProps) => editProduct(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PRODUCTS] })
      message.success('Product saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditFields = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editProductFields,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PRODUCTS] })
      message.success('Product saved')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveProduct = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeProduct,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PRODUCTS] })
      message.success('Product deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useProducts,
  useProduct,
  useDuplicateProduct,
  useAddProduct,
  useEditProduct,
  useEditFields,
  useRemoveProduct,
}
