import clsx from 'clsx'
import { Formik } from 'formik'
import { compact, findIndex, isEqual, isUndefined } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  schemeToValues,
  isLastPage,
  isFirstPage,
} from 'borrower/components/DraftApplication/helpers'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import {
  savePreviewApplication,
  deletePreviewApplication,
  readPreviewApplication,
} from 'services/storage'
import { ApplicationScheme } from 'types'
import { ApplicationField } from '../GuestApplication/ApplicationField'
import styles from './styles.module.scss'

interface Props {
  scheme: ApplicationScheme
}

function PreviewApplication({ scheme: initialScheme }: Props) {
  const [scheme, setScheme] = useState<ApplicationScheme>(initialScheme)
  const defaultTagIndex = useMemo(
    () =>
      Math.max(
        0,
        ...scheme.pages
          .map(({ complete }, index) => ({ index, complete }))
          .filter(({ complete }) => complete)
          .map(({ index }) => index)
      ),
    [scheme]
  )
  const tags = useMemo(() => scheme.pages.map(({ tag }) => tag), [scheme])
  const [currentTagIndex, setCurrentTagIndex] =
    useState<number>(defaultTagIndex)
  const page = useMemo(
    () => scheme.pages[currentTagIndex],
    [scheme, currentTagIndex]
  )
  const goToPage = useCallback(
    (pageIndex: number) => {
      setCurrentTagIndex(pageIndex)
      document.getElementById(scheme.pages[pageIndex].id)?.scrollIntoView()
      window.scrollTo({ top: window.scrollY - 80 })
    },
    [page]
  )

  const goToNextPage = useCallback(() => {
    if (tags[currentTagIndex + 1]) {
      goToPage(currentTagIndex + 1)
    }
  }, [goToPage, currentTagIndex, tags])

  const handleBack = useCallback(() => {
    if (tags[currentTagIndex - 1]) {
      goToPage(currentTagIndex - 1)
    }
  }, [goToPage, currentTagIndex, tags])

  const initialValues = useMemo(() => schemeToValues(scheme), [scheme])

  useEffect(() => {
    const onStorageChange = () => setScheme(readPreviewApplication())
    window.addEventListener('storage', onStorageChange)
    return () => window.removeEventListener('storage', onStorageChange)
  }, [])

  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page, index) => ({
          ...page,
          complete: index === currentTagIndex ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }

      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        savePreviewApplication(nextScheme)
      }
    },
    [scheme]
  )

  useEffect(() => {
    const pageElements = compact(
      scheme.pages.map((page) => document.getElementById(page.id))
    )
    let direction = 'up'
    let prevYPosition = 0

    const setScrollDirection = () => {
      if (window.scrollY > prevYPosition) {
        direction = 'down'
      } else {
        direction = 'up'
      }

      prevYPosition = window.scrollY
    }
    const onScroll = () => {
      setScrollDirection()

      if (direction === 'down') {
        const pageIndex = findIndex(
          pageElements.slice().reverse(),
          (el) => el.getBoundingClientRect().top < window.innerHeight / 2
        )
        setCurrentTagIndex(pageElements.length - pageIndex - 1)
      }
      if (direction === 'up') {
        const pageIndex = findIndex(
          pageElements,
          (el) => el.getBoundingClientRect().bottom > window.innerHeight / 2
        )
        if (pageIndex !== -1) {
          setCurrentTagIndex(pageIndex)
        }
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [scheme])

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      <Form onChange={updateScheme}>
        {scheme.pages.map((page, index) => (
          <div key={page.id} id={page.id}>
            <Flex stack gap={32} className={clsx(styles.panel, 'relative')}>
              {currentTagIndex !== index && (
                <div className="absolute inset-0 opacity-60 z-1 rounded-[24px] bg-white-100" />
              )}
              <Flex stack gap={16}>
                <Flex>
                  <div className={styles.steps}>
                    Step {index + 1}/{tags.length}
                  </div>
                  <EllipsesActions
                    placement="bottom-start"
                    trigger={
                      <Flex className={styles.step} alignItems="center" gap={4}>
                        {tags[index]}
                        <Icon name={IconName.arrowDown} />
                      </Flex>
                    }
                  >
                    {tags.map((tag, index) => (
                      <EllipsesActions.Item
                        key={tag + index}
                        onSelect={() => goToPage(index)}
                      >
                        {tag}
                      </EllipsesActions.Item>
                    ))}
                  </EllipsesActions>
                </Flex>
                <Header>{page.header}</Header>
                {page.description && (
                  <Text className="text-grey-600">{page.description}</Text>
                )}
              </Flex>
              <div className={styles.labels}>
                {page.fields
                  .filter(({ enabled }) => enabled)
                  .map((field) => (
                    <ApplicationField key={field.id} field={field} />
                  ))}
              </div>
              <div className={styles.controls}>
                {isFirstPage(scheme, page.id) && (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      deletePreviewApplication()
                      window.close()
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <div />
                <div className={styles.buttons}>
                  {!isFirstPage(scheme, page.id) && (
                    <Button variant="tertiary" onClick={handleBack}>
                      ←
                    </Button>
                  )}
                  <Button type="submit" disabled={isLastPage(scheme, page.id)}>
                    {isLastPage(scheme, page.id)
                      ? 'Submit Application'
                      : 'Continue →'}
                  </Button>
                </div>
              </div>
            </Flex>
            {!isLastPage(scheme, page.id) && <div className={styles.divider} />}
          </div>
        ))}
      </Form>
    </Formik>
  )
}
export { PreviewApplication }
