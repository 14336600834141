import { useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useOffering } from 'admin/hooks/use-offerings'
import { PageLoader } from 'components/LoaderOverlay'
import { OfferingFundView } from './OfferingFundView'
import { OfferingHeader } from './OfferingHeader'
import { OfferingLoanView } from './OfferingLoanView'

function Offering() {
  const { id } = useParams() as { id: string }
  const { data: offering } = useOffering({ id })

  return (
    <MainContent>
      {offering ? (
        <>
          <OfferingHeader offering={offering.offering} />
          {offering.offering.type === 'Fund Offering' ? (
            <OfferingFundView offering={offering.offering} />
          ) : (
            <OfferingLoanView offering={offering.offering} />
          )}
        </>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Offering }
