import { Row } from 'types'
import { getReport } from '../api/reports'

const downloadUnpaidInterest = async (params): Promise<Row[]> => {
  const { unpaidInterests } = await getReport({
    type: 'unpaid-interest',
    params,
  })

  return unpaidInterests.map((item) => ({
    'Loan ID': item.number || item.loanId || item.id,
    'Loan Name': item.name,
    'Unpaid Interest': item.unpaidInterest,
  }))
}

export { downloadUnpaidInterest }
