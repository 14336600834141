import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createBorrowerAccount,
  createInvestorAccount,
} from 'borrower/services/api/users'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useCreateUserAccount = ({
  investor,
  onError,
}: {
  investor: boolean
  onError?: (error: AxiosError) => void
}) => {
  return useMutation({
    mutationFn: ({
      name,
      email,
      subdomain,
      companyName,
    }: {
      name: string
      email: string
      subdomain: string
      companyName?: string
    }) =>
      investor
        ? createInvestorAccount({ name, email, subdomain, companyName })
        : createBorrowerAccount({ name, email, subdomain, companyName }),
    onSuccess: () => {
      message.success('Account created')
    },
    onError: (error: AxiosError) => {
      if (!onError?.(error)) {
        handleErrorResponse(error)
      }
    },
  })
}

export { useCreateUserAccount }
