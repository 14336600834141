import { routerPath as adminPaths } from 'admin/path-to'
import { routerPath as borrowerPaths } from 'borrower/path-to'

export const handlePermalink = (
  routes: typeof adminPaths | typeof borrowerPaths,
  originUrl: string,
  handleClearUrl: () => void
) => {
  setTimeout(() => {
    handleClearUrl()
  }, 1000)
  return originUrl && !originUrl.includes(routes.signIn)
    ? originUrl
    : routes.root
}
