import DOMPurify from 'dompurify'
import { Flex } from 'components/Flex'
import { Modal } from 'components/Modal'
import { SentEmail } from 'types'
import { friendlyDateTime } from 'utils/date'
import { getEmailNames, getEmailAddresses } from 'utils/email'

interface Props {
  sentEmail: SentEmail
  onCancel: () => void
}

const ModalLoanSentEmail = ({ sentEmail, onCancel }: Props) => {
  const emailNames = getEmailNames(sentEmail.to)
  const emailAddresses = getEmailAddresses(sentEmail.to)

  const ccNames = getEmailNames(sentEmail.cc ?? '')
  const ccAddresses = getEmailAddresses(sentEmail.cc ?? '')

  return (
    <Modal
      title="Email Sent"
      onClose={onCancel}
      crossClose
      asChild
      className="!w-[900px] !max-w-[900px] min-w-96 pb-0 gap-0"
    >
      <div className="absolute top-[39px] right-16 text-grey-700 normal-nums">
        {friendlyDateTime(sentEmail.createdAt)}
      </div>
      <Flex stack gap={12} className="max-w-[680px] py-4 px-8">
        <Flex gap={8} alignItems="flex-start" className="text-lg leading-5">
          <div className="text-black-100">To:</div>
          <Flex gap={8} flexWrap="wrap">
            {emailNames.map((name, i) => (
              <Flex gap={4} className="px-2 rounded bg-grey-75" key={name}>
                <div className="font-bold">{name}</div>
                <div className="text-grey-700">{emailAddresses?.[i]}</div>
              </Flex>
            ))}
          </Flex>
        </Flex>
        {ccNames.length > 0 && (
          <Flex gap={8} alignItems="flex-start" className="text-lg leading-5">
            <div className="text-black-100">Cc:</div>
            <Flex gap={8} flexWrap="wrap">
              {ccNames.map((name, i) => (
                <Flex gap={4} className="px-2 rounded bg-grey-75" key={name}>
                  <div className="font-bold">{name}</div>
                  <div className="text-grey-700">{ccAddresses?.[i]}</div>
                </Flex>
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
      <div className="max-w-[900px] w-full py-1 px-4 rounded rounded-t-none bg-grey-75 border-0 border-solid border-grey-200 border-t">
        <iframe
          height={500}
          srcDoc={DOMPurify.sanitize(sentEmail.htmlBody, {
            FORBID_ATTR: ['href'],
          })}
          className="w-full border-0"
        />
      </div>
    </Modal>
  )
}

export { ModalLoanSentEmail }
