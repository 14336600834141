import clsx from 'clsx'
import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface TextProps {
  children: ReactNode
  className?: string
  variant?: 'l' | 'm' | 's' | 'xs'
}

function Text({ children, className, variant = 'm' }: TextProps) {
  return (
    <p
      className={clsx(className, styles.text, {
        [styles.l]: variant === 'l',
        [styles.m]: variant === 'm',
        [styles.s]: variant === 's',
        [styles.xs]: variant === 'xs',
      })}
    >
      {children}
    </p>
  )
}

export type { TextProps }
export default Text
