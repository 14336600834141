import { clone } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { useUpdateLoan } from 'admin/hooks/use-loans'
import { useProduct } from 'admin/hooks/use-products'
import { sections } from 'admin/pages/Loan/PanelLoanServicingTerms'
import { pathTo } from 'admin/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { useLoan } from 'hooks/use-loans'
import { Product as IProduct } from 'types'
import { Field } from 'types/field'
import { ProductContext } from './ProductContext'
import { View } from './View'

function LoanProduct() {
  const navigate = useNavigate()
  const { isOrigination } = useLoanType()
  const { id } = useParams() as { id: string }
  const { data: loan } = useLoan({ id })
  const { data: product } = useProduct({ id: loan?.productId || 'default' })
  const { mutate: save, isPending: saving } = useUpdateLoan()
  const [fields, setFields] = useState<Field[]>([])
  const [settings, setSettings] = useState({
    showBudget: true,
    showDrawRequests: true,
  })

  const termsLocked = loan?.status === 'servicing'

  const handleSettingsChange = useCallback((setting: IProduct['settings']) => {
    setSettings((currentSettings) => ({
      ...currentSettings,
      ...setting,
    }))
  }, [])

  useEffect(() => {
    setSettings({
      showBudget:
        loan?.settings?.showBudget ??
        product?.product?.settings?.showBudget ??
        true,
      showDrawRequests:
        loan?.settings?.showDrawRequests ??
        product?.product?.settings?.showDrawRequests ??
        true,
    })
  }, [loan, product])

  useEffect(() => {
    if (loan?.fields && product?.fields) {
      const productFields =
        product?.fields.map((productField) => {
          const loanField = loan?.fields.find(
            ({ id }) => id === productField.id
          )
          const order =
            loanField?.property.order ??
            loanField?.order ??
            productField.property.order ??
            productField.order
          if (loanField) {
            return {
              ...productField,
              order,
              property: {
                ...productField.property,
                order,
                value: clone(loanField.property.value),
                formula: clone(loanField.property.formula),
                enabled:
                  productField.property.enabled === 'always-on'
                    ? 'always-on'
                    : ('on' as Field['property']['enabled']), // it's 'on' because of it's part of the loan, it has to be on
              },
              viewOnly:
                productField.viewOnly ||
                (termsLocked && sections.includes(productField.section)),
            }
          }
          return {
            ...productField,
            order,
            property: {
              ...productField.property,
              order,
              enabled: 'off' as Field['property']['enabled'],
            },
          }
        }) || []
      const productFieldIds = productFields.map(({ id }) => id)
      const productAlwaysOnFieldIds = productFields
        .filter(({ property }) => property.enabled === 'always-on')
        .map(({ id }) => id)
      const loanFields = loan.fields
        .filter(({ id }) => !productFieldIds.includes(id))
        .map(
          ({
            id,
            name,
            description,
            editable,
            options,
            type,
            page,
            section,
            property: { value, formula, order },
            viewOnly,
          }) => ({
            id,
            name,
            description,
            editable,
            options,
            order,
            type,
            page,
            section,
            property: {
              value,
              formula,
              order,
              enabled: productAlwaysOnFieldIds.includes(id)
                ? 'always-on'
                : 'on',
            },
            viewOnly,
          })
        ) as unknown as Field[]
      setFields([...productFields, ...loanFields])
    }
  }, [loan, product])

  return (
    <MainContent>
      {loan && fields.length ? (
        <ProductContext.Provider value={{ loan }}>
          <View
            title={loan.name}
            breadcrumbs={{
              title: 'Loan',
              link: pathTo(isOrigination ? 'loan' : 'servicingLoan', id),
            }}
            fields={fields}
            settings={settings}
            saving={saving}
            onPageChange={(page) =>
              navigate(
                pathTo(
                  isOrigination ? 'loanProduct' : 'servicingLoanProduct',
                  id,
                  page
                )
              )
            }
            onSettingsChange={handleSettingsChange}
            onSave={(fields) => {
              const loanFields = (fields as Field[]).filter((field) =>
                ['on', 'always-on'].includes(field.property.enabled as string)
              ) as unknown as Field[]
              save(
                { id, fields: loanFields, settings },
                {
                  onSuccess: () => {
                    navigate(
                      pathTo(isOrigination ? 'loan' : 'servicingLoan', id)
                    )
                  },
                }
              )
            }}
            onCancel={() =>
              navigate(pathTo(isOrigination ? 'loan' : 'servicingLoan', id))
            }
          />
        </ProductContext.Provider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { LoanProduct }
