import { Flex } from 'components/Flex'
import { Logo } from 'components/Logo'
import { Quote } from 'types'

interface Props {
  quote: Quote
  onLogoLoaded?: () => void
}

const QuoteHeader = ({ quote, onLogoLoaded }: Props) => {
  return (
    <Flex
      className="border-0 border-b-[1px] border-solid border-grey-200 px-0 pb-5 pt-10 sm:!flex-row"
      justifyContent="space-between"
      flexDirection="column-reverse"
    >
      <Flex gap={32} stack>
        <div className="text-5xl font-normal text-grey-900">Loan Quote</div>
        <Flex stack gap={4}>
          <div className="text-xl font-bold text-grey-900">
            {quote.client?.name}
          </div>
          <div className="text-grey-700">{quote.admin?.name}</div>
          <div className="text-grey-700">{quote.admin?.login.email}</div>
          <div className="text-grey-700">{quote.admin?.phone}</div>
        </Flex>
      </Flex>
      <Logo
        to="/"
        type="statement"
        logoClassName="h-8 break-inside-avoid bg-center"
        onLoad={onLogoLoaded}
      />
    </Flex>
  )
}

export { QuoteHeader }
