import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Flex } from '../Flex'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  title?: string
  className?: string
}

export const ErrorMessage = ({
  title = 'Error',
  children,
  className,
}: Props) => {
  return (
    <Flex gap={16} className={clsx(styles.errorBox, className)}>
      <Flex gap={4} className={styles.errorTitle}>
        <Icon name={IconName.error} /> {title}
      </Flex>
      {children}
    </Flex>
  )
}
