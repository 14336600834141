import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Field } from 'types'
import { formatField } from 'utils/fields'

type Row = Record<string, string | number>

type Props = {
  unitFields: Field[]
  data: Row[]
  loading: boolean
  onRowClick: (data?: Row) => void
  onDelete: (index: number) => void
}

const TableUnitDetails = ({
  unitFields,
  data,
  loading,
  onRowClick,
  onDelete,
}: Props) => {
  const columns: ColumnDef<Row>[] = useMemo(
    () => [
      ...unitFields.map((field) => ({
        header: field.name,
        id: field.id,
        cell: ({ row }) => {
          const value = row.original[field.name]
          return value?.toString().startsWith('=')
            ? value
            : formatField({
                ...field,
                property: { ...field.property, value: [value] },
              })
        },
        meta: {
          align: ['number', 'decimal', 'currency', 'percentage'].includes(
            field.type[0]
          )
            ? 'right'
            : 'left',
        },
      })),
      {
        id: 'actions',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original._index as number)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [unitFields, onDelete]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onRowClick}
    />
  )
}

export { TableUnitDetails }
