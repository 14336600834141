import { Form, Formik } from 'formik'
import { compact } from 'lodash'
import { useCallback, useState } from 'react'
import { Button } from 'components/Button'
import { Field, Option, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { BaseField, FieldType } from 'types'
import { SelectFieldType } from './SelectFieldType'

type FormValues = {
  name: string
  type: FieldType
  description: string
  options: string
}

interface Props {
  field?: BaseField
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalField({ field, saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    name: field?.name || '',
    type: field?.type[0] || 'text',
    description: field?.description || '',
    options: field?.options
      ? field?.options.map(({ value }) => value).join('\r\n')
      : '',
  }
  const [type, setType] = useState<string>(initialValue.type)

  const handleTypeChange = useCallback(
    (option: Option) => {
      setType(option.value as FieldType)
    },
    [type]
  )

  const handleSubmit = useCallback(
    (values) => {
      let options: Option[] | undefined = undefined
      if (values.type === 'option') {
        options = values.options
          ? (compact(values.options.split(/\r?\n/)).map((value) => ({
              label: value,
              value: value,
            })) as Option[])
          : []
      }

      onSave({
        ...values,
        options,
      })
    },
    [onSave]
  )

  return (
    <Modal
      title={field ? 'Edit Custom Field' : 'Add Custom Field'}
      onClose={onCancel}
    >
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        <Form>
          <Grid columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Field Label"
                type="name"
                name="name"
                placeholder="Field name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <SelectFieldType disabled={!!field} onChange={handleTypeChange} />
            </Grid.Item>
            {type === 'option' && (
              <Grid.Item xs={12}>
                <Textarea
                  name="options"
                  label="Options"
                  placeholder="A list of options for people to choose from. Enter each option on a new line."
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12}>
              <Textarea
                name="description"
                label="Description"
                placeholder="Description"
              />
            </Grid.Item>
            <Grid.Item xs={12} className="flex justify-end gap-4">
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalField }
