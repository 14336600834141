import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

export const useDebounce = (value, wait: number, options?: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const updateDebouncedValue = useCallback(
    debounce(setDebouncedValue, wait, options),
    []
  )

  useEffect(() => updateDebouncedValue(value), [value])

  return debouncedValue
}
