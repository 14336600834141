import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { FundStatusBadge } from 'components/Badge'
import { Table } from 'components/Table'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'

type Props = {
  data?: Transaction[]
  loading: boolean
}

function TableFundingTransactions({ data = [], loading }: Props) {
  const columns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        size: 100,
        cell: ({ getValue }) => formatDate(getValue() as string),
      },
      {
        header: 'Type',
        accessorKey: 'gl',
        size: 150,
        cell: ({ row }) => getFundingType(row.original),
      },
      {
        header: 'Status',
        id: 'status',
        size: 100,
        cell: ({ row }) => {
          const { gl, datePaidout } = row.original
          return (
            row.original?.payment && (
              <FundStatusBadge
                gl={gl}
                status={row.original.payment.status}
                datePaidout={datePaidout}
              />
            )
          )
        },
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        size: 110,
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableFundingTransactions
