import { request } from 'services/request'
import { Template } from 'types'

const defaultTemplates: Template[] = [
  { id: 'payoff', name: 'Payoff' },
  { id: 'loan_documents', name: 'Loan Documents' },
  { id: 'commitment_letter', name: 'Commitment Letter' },
  { id: 'preapproval_letter', name: 'Pre-Approval Letter' },
  { id: 'rehab-budget', name: 'Budget Worksheet' },
  { id: 'investor_agreement', name: 'Investor Agreement' },
]

const getTemplateUrl = async (
  name: string
): Promise<{ url: string; uploadUrl: string }> => {
  const {
    data: { template, upload },
  } = await request.get(`/document/template/${name}`)
  return { url: template.url, uploadUrl: upload }
}

const getTemplates = async (): Promise<Template[]> => {
  const {
    data: { templates },
  } = await request.get('/document/template')
  return templates
}

const addTemplate = async (name: string): Promise<any> => {
  const {
    data: { template },
  } = await request.post('/document/template', { name })
  return template
}

const updateTemplate = async (
  id: string,
  params: { name?: string }
): Promise<any> => {
  const {
    data: { template },
  } = await request.patch(`/document/template/${id}`, params)
  return template
}

const deleteTemplate = async (id: string): Promise<any> => {
  const {
    data: { templates },
  } = await request.delete(`/document/template/${id}`)
  return templates
}

const resetTemplate = async (name: string): Promise<void> => {
  return request.delete(`/document/template/${name}`)
}

export {
  defaultTemplates,
  getTemplateUrl,
  resetTemplate,
  getTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
}
