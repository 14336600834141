import { Formik } from 'formik'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  name?: string
  loading?: boolean
  sharePersons?: { id: string; name: string; typeOfPerson: string }[]
  sharing?: boolean
  onShare: ({ message }: { message: string }) => void
  onCancel: () => void
}

function ModalShare({
  name,
  sharePersons,
  loading,
  sharing,
  onShare,
  onCancel,
}: Props) {
  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal title={`Share “${name}”`} loading={loading} onClose={onCancel}>
      <Formik initialValues={initialValue} onSubmit={onShare}>
        <Form>
          <Grid className={styles.grid} columnGap={16}>
            <Grid.Item xs={12}>
              <Text className="font-bold">Share with:</Text>
              <div className={styles.borrowersWrapper}>
                {!sharePersons?.length && (
                  <div className={styles.empty}>
                    There are no available persons to share with.
                  </div>
                )}
                {sharePersons?.map((person) => (
                  <Flex
                    className={styles.borrower}
                    justifyContent="space-between"
                    alignItems="center"
                    key={person.id + person.typeOfPerson}
                  >
                    <Flex gap={8} alignItems="center">
                      <Tooltip content={person.name}>
                        <Avatar id={person.id} name={person.name} />
                      </Tooltip>
                      <Text className="font-bold">{person.name}</Text>
                    </Flex>
                    <Text className={styles.typeOfPerson}>
                      {person.typeOfPerson}
                    </Text>
                  </Flex>
                ))}
              </div>
            </Grid.Item>
            <Grid.Item className={styles.textarea} xs={12}>
              <Textarea
                name="message"
                placeholder="Add a message if you'd like"
              />
            </Grid.Item>
            <Grid.Item className={styles.buttons} xs={12}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                disabled={!sharePersons?.length}
                loading={sharing}
                type="submit"
              >
                Share
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalShare
