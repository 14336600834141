import AddressIcon from 'images/icon-field-address.svg'
import CurrencyIcon from 'images/icon-field-currency.svg'
import DateIcon from 'images/icon-field-date.svg'
import DecimalIcon from 'images/icon-field-decimal.svg'
import DocumentIcon from 'images/icon-field-document.svg'
import DropdownIcon from 'images/icon-field-dropdown.svg'
import EmailIcon from 'images/icon-field-email.svg'
import NumberIcon from 'images/icon-field-number.svg'
import PercentageIcon from 'images/icon-field-percentage.svg'
import PhoneIcon from 'images/icon-field-phone.svg'
import SignatureIcon from 'images/icon-field-signature.svg'
import SsnIcon from 'images/icon-field-ssn.svg'
import TaxIcon from 'images/icon-field-tax.svg'
import TextIcon from 'images/icon-field-text.svg'
import YesNoIcon from 'images/icon-field-yes-no.svg'
import { CustomApplicationField } from 'types'

const fields: CustomApplicationField[] = [
  {
    type: 'text',
    name: 'Text',
    icon: TextIcon,
  },
  {
    type: 'address',
    name: 'Address',
    icon: AddressIcon,
  },
  {
    type: 'option',
    name: 'Dropdown',
    icon: DropdownIcon,
  },
  {
    type: 'currency',
    name: 'Currency',
    icon: CurrencyIcon,
  },
  {
    type: 'percentage',
    name: 'Percentage',
    icon: PercentageIcon,
  },
  {
    type: 'email',
    name: 'Email',
    icon: EmailIcon,
  },
  {
    type: 'date',
    name: 'Date',
    icon: DateIcon,
  },
  {
    type: 'number',
    name: 'Integer',
    icon: NumberIcon,
  },
  {
    type: 'decimal',
    name: 'Decimal',
    icon: DecimalIcon,
  },
  {
    type: 'phone',
    name: 'Phone',
    icon: PhoneIcon,
  },
  {
    type: 'yes-no',
    name: 'Yes or No',
    icon: YesNoIcon,
  },
  {
    type: 'signature',
    name: 'Signature',
    icon: SignatureIcon,
  },
  {
    type: 'document',
    name: 'Document',
    icon: DocumentIcon,
  },
  {
    type: 'ssn-number',
    name: 'Social Security Number',
    icon: SsnIcon,
  },
  {
    type: 'tax-number',
    name: 'Tax ID',
    icon: TaxIcon,
  },
]

export { fields }
