import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Text } from 'components/Text'
import { Quote } from 'types'
import { formatAddress } from 'utils/address'
import { formatField } from 'utils/fields'
import { QuotePersonDetails } from './QuotePersonDetails'

interface Props {
  quote: Quote
}

const QuoteDetails = ({ quote }: Props) => {
  return (
    <Grid>
      <Grid.Item xs={12} sm={7} className="pb-6 sm:pb-0 sm:pr-5">
        <Flex stack gap={20}>
          <Header variant="h4" className="text-blue-200">
            Details
          </Header>
          <Grid rowGap={8} columnGap={12}>
            <Grid.Item xs={6}>
              <Text className="text-grey-700">Address</Text>
              <Text className="mt-1 leading-5">
                {formatAddress(quote.address)}
              </Text>
            </Grid.Item>
            {quote.fields.map((field) => (
              <Grid.Item xs={6} key={field.id}>
                <Text className="text-grey-700">{field.name}</Text>
                <Text className="mt-1 leading-5">{formatField(field)}</Text>
              </Grid.Item>
            ))}
          </Grid>
        </Flex>
      </Grid.Item>
      <Grid.Item
        xs={12}
        sm={5}
        className="gap-5 border-0 border-t-[1px] border-solid border-grey-200 pt-5 sm:border-l-[1px] sm:border-t-0 sm:pl-5 sm:pt-0"
      >
        <Flex stack gap={0} className="pb-6">
          <Header variant="h4" className="text-blue-200">
            {quote.borrowers.length !== 1 ? 'Borrowers' : 'Borrower'}
          </Header>
          {quote.borrowers &&
            quote.borrowers.map((borrower) => {
              return <QuotePersonDetails person={borrower} key={borrower.id} />
            })}
        </Flex>
        {quote.guarantors && (
          <Flex
            stack
            gap={0}
            className="border-0 border-t-[1px] border-solid border-grey-200 pt-5"
          >
            <Header variant="h4" className="text-blue-200">
              {quote.guarantors.length !== 1 ? 'Guarantors' : 'Guarantor'}
            </Header>
            {quote.guarantors.map((guarantor) => {
              return (
                <QuotePersonDetails person={guarantor} key={guarantor.id} />
              )
            })}
          </Flex>
        )}
      </Grid.Item>
    </Grid>
  )
}

export { QuoteDetails }
