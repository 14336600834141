import { Formik } from 'formik'
import { useMemo } from 'react'
import { useRoles } from 'admin/hooks/use-roles'
import styles from 'admin/pages/Settings/ModalMember/styles.module.scss'
import { User } from 'admin/services/api/users'
import { Button } from 'components/Button'
import { Form, Field } from 'components/Form'
import Select from 'components/Form/Select'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { createScheme, email, mergeRules, required } from 'utils/schemas'

const Schema = createScheme({
  name: required,
  email: mergeRules(email, required),
  permission: required,
})

type FormValues = {
  name: string
  email: string
  permission: User['permission']
  roles: string[]
}

interface Props {
  user?: User
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

const permissionOptions = [
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'individual', label: 'Individual' },
]

function ModalMember({ user, saving, onSubmit, onCancel }: Props) {
  const { data: roles, isLoading } = useRoles()
  const initialValue: FormValues = {
    name: user?.name || '',
    email: user?.email || '',
    permission: user?.permission || 'admin',
    roles: user?.roles?.map(({ id }) => id) || [],
  }

  const roleOptions = useMemo(() => {
    return (
      roles?.map((role) => ({
        value: role.id,
        label: role.name,
      })) || []
    )
  }, [roles])

  return (
    <Modal
      title={user ? 'Edit Member' : 'Add New Member'}
      loading={isLoading}
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="name"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Select
                name="permission"
                label="Permission"
                options={permissionOptions}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Select
                name="roles"
                label="Roles"
                options={roleOptions}
                bottomHint="Choose which roles this teammate should have assigned to them"
                multi
                portal
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalMember }
