import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { fields as fieldTypes } from 'admin/pages/Settings/Application/fields'
import { pathTo } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { BaseField, Sort } from 'types'

interface CustomProductField extends BaseField {
  products: {
    id: string
    name: string
  }[]
}

interface Props {
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
  fields: CustomProductField[]
  onClick: (id: string) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}
function TableProductFields({
  sort,
  onSort,
  loading,
  fields,
  onClick,
  onEdit,
  onDelete,
}: Props) {
  const columns: ColumnDef<CustomProductField>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Field',
        cell: ({ row }) => {
          const type = fieldTypes.find(
            ({ type }) => type === row.original.type[0]
          )
          return (
            <Flex>
              <Flex alignItems="center">
                <img
                  className="h-6 w-6 border-0"
                  src={type?.icon}
                  alt={type?.name}
                />
              </Flex>
              <Flex flexDirection="column" gap={2}>
                <div className="text-grey-900">{row.original.name}</div>
                <div className="text-grey-700 text-sm">
                  {capitalize(row.original.type[0])}
                </div>
              </Flex>
            </Flex>
          )
        },
      },
      {
        accessorKey: 'products',
        header: 'Associated Products',
        size: 204,
        cell: ({ row }) => {
          return (
            <AssociatedBadges
              associatedWith={row.original.products}
              getUrlById={(id: string) => pathTo('product', id)}
            />
          )
        },
      },
      {
        accessorKey: 'id',
        header: '',
        size: 40,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onEdit(row.original.id as string)
                }}
              >
                <Icon name={IconName.edit} />
                Edit Field
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onDelete(row.original.id as string)
                }}
                className="text-red-100"
                disabled={!!row.original.products?.length}
              >
                <Icon name={IconName.delete} />
                Delete Field
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [onDelete, onEdit]
  )

  return (
    <Table
      columns={columns}
      data={fields}
      loading={loading}
      sort={sort}
      onSort={onSort}
      onClick={(field) => field && onClick(field.id as string)}
    />
  )
}
export { TableProductFields }
