import { request } from 'services/request'
import { LoanDocument } from 'types'

const getBorrowerDocuments = async ({
  id,
}: {
  id: string
}): Promise<LoanDocument[]> => {
  const {
    data: { documents },
  } = await request.get(`/borrower/${id}/document`)
  return documents
}

const addBorrowerDocument = async (
  id: string,
  document: Partial<LoanDocument>
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.post(`/borrower/${id}/document`, document)
  return doc
}

export { getBorrowerDocuments, addBorrowerDocument }
