import { isArray } from 'lodash'
import { FieldType } from 'types'
import { FieldValue } from 'types/field'
import { formatPercent, parsePercent } from 'utils/math'

const updateResponseValue = (value: any, type: FieldType[]): FieldValue[] => {
  if (!isArray(value)) {
    value = Array(type.length).fill(null).fill(value, 0, 1)
  }
  return value.map((v: any, index: number) =>
    v && type[index] === 'percentage' ? formatPercent(v) : v
  )
}

const updateRequestValue = (
  values: FieldValue[],
  type: FieldType[]
): FieldValue | FieldValue[] => {
  const formattedValue = values.map((value: FieldValue, index: number) => {
    if (value && type[index] === 'percentage') {
      return parsePercent(value)
    }
    if (value === '') {
      return null
    }
    return value
  })
  return formattedValue.length === 1 ? formattedValue[0] : formattedValue
}

export { updateResponseValue, updateRequestValue }
