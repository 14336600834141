import { useImportEntities } from 'admin/hooks/use-import'
import { EntityType } from 'admin/services/api/import'
import { Button } from 'components/Button'
import { devEmailDomains } from 'constants/domain'
import { useSession } from 'hooks/use-session'
import { openBrowseFile } from 'utils/file'

interface Props {
  entityType: EntityType
  loanId?: string
  setAlertData: () => void
}

export const ImportEntity = ({ entityType, loanId, setAlertData }: Props) => {
  const { user } = useSession()
  const isDevEmail = devEmailDomains.includes(
    user?.admin?.email?.split('@')[1] as string
  )
  const { mutate: importEntities, isPending } = useImportEntities(
    entityType,
    loanId
  )

  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) =>
        importEntities(files[0], {
          onSuccess: () => {
            setAlertData()
          },
        }),
      accept: '.csv',
    })
  }

  return isDevEmail ? (
    <Button onClick={handleUpload} variant="tertiary" loading={isPending}>
      Import
    </Button>
  ) : null
}
