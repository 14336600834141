import {
  autoUpdate,
  flip,
  FloatingPortal,
  FloatingNode,
  Placement,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useFloatingNodeId,
} from '@floating-ui/react'
import clsx from 'clsx'
import {
  useState,
  cloneElement,
  useEffect,
  ReactElement,
  ReactNode,
  forwardRef,
  ForwardedRef,
  memo,
} from 'react'
import { mergeRefs } from 'react-merge-refs'

interface DropdownProps {
  open?: boolean
  trigger: ReactElement
  triggerFixedWidth?: boolean
  placement?: Placement
  className?: string
  onOpenChange?: (open: boolean) => void
  children: ReactNode
}

const Dropdown = memo(
  forwardRef(
    (
      {
        open: controlledOpen,
        trigger,
        triggerFixedWidth,
        placement = 'bottom-end',
        className,
        children,
        onOpenChange,
      }: DropdownProps,
      ref: ForwardedRef<HTMLElement>
    ) => {
      const [open, setOpen] = useState(controlledOpen ?? false)
      const nodeId = useFloatingNodeId()
      const { x, y, refs, context, strategy } = useFloating({
        nodeId,
        open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [shift(), flip(), offset(4)],
      })
      const { getReferenceProps, getFloatingProps } = useInteractions([
        useClick(context),
        useRole(context, { role: 'menu' }),
        useDismiss(context),
      ])

      useEffect(() => {
        onOpenChange?.(open)
      }, [open])

      useEffect(() => {
        setOpen(controlledOpen ?? false)
      }, [controlledOpen])

      return (
        <>
          {cloneElement(trigger, {
            ...getReferenceProps({
              ref: mergeRefs([ref, refs.setReference]),
              onClick(event) {
                event.stopPropagation()
              },
            }),
          })}
          {open && (
            <FloatingPortal>
              <FloatingNode id={nodeId}>
                <div
                  {...getFloatingProps({
                    ref: refs.setFloating,
                    onClick(event) {
                      event.stopPropagation()
                    },
                    style: {
                      position: strategy,
                      top: y ?? 0,
                      left: x ?? 0,
                      minWidth:
                        refs.reference.current?.getBoundingClientRect().width,
                      width: triggerFixedWidth
                        ? refs.reference.current?.getBoundingClientRect().width
                        : 'auto',
                    },
                  })}
                  className={clsx(
                    'bg-white-100 rounded z-1 border border-solid border-grey-200 shadow-300',
                    className
                  )}
                >
                  {children}
                </div>
              </FloatingNode>
            </FloatingPortal>
          )}
        </>
      )
    }
  )
)

export type { DropdownProps }

export { Dropdown }
