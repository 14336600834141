import clsx from 'clsx'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

function Textarea({ disabled, ...props }: CustomFieldProps) {
  return (
    <Field {...props}>
      {({ meta: { touched, error }, field }: FieldChildProps) => (
        <textarea
          id={field.name}
          className={clsx(styles.input, styles.textarea, {
            [styles.errorField]: touched && error,
          })}
          placeholder={props.placeholder}
          disabled={disabled}
          {...field}
        />
      )}
    </Field>
  )
}

export default Textarea
