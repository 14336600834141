import { request, requestWithoutToken } from 'services/request'
import { LoanDocument, Meta } from 'types'

interface Comment {
  id: string
  text: string
  createdAt: string
  by: {
    id: string
    name: string
  }
}

const updateDocument = async (
  document: Partial<LoanDocument & { message?: string }>,
  { authToken }: { authToken?: string } = {}
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = authToken
    ? await requestWithoutToken.patch(`/document/${document.id}`, document, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
    : await request.patch(`/document/${document.id}`, document)
  return doc
}

const requestDocument = async (id: string): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.patch(`/document/${id}`, { status: 'requested' })
  return doc
}

const deleteDocument = async ({ id }: { id: string }) => {
  await request.delete(`/document/${id}`)
}

const deleteDocumentVersion = async ({
  id,
  versionId,
}: {
  id: string
  versionId: string
}) => {
  await request.delete(`/document/${id}/version/${versionId}`)
}

const getDocumentComments = async (
  id: string,
  { page, size }: { page: number; size: number }
): Promise<{ comments: Comment[]; total: number }> => {
  const {
    data: {
      comments,
      pagination: { total },
    },
  } = await request.get(`/document/${id}/comments`, {
    params: {
      page: { size, page },
      sort: ['created_at desc'],
    },
  })
  return { comments: comments.reverse(), total }
}

const addDocumentComment = async (
  id: string,
  data: { text: string }
): Promise<{ comments: Comment[]; meta: Meta }> => {
  const {
    data: { comments, pagination: meta },
  } = await request.post(`/document/${id}/comments`, data, {
    params: { page: { size: 25, page: 0 }, sort: ['created_at desc'] },
  })
  return { comments, meta }
}

const saveDocumentSigners = async (
  documentId: string,
  data: { signers: { id: string }[]; loanId?: string }
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.post(`/document/${documentId}/esignature`, data)
  return doc
}

const updateDocumentSignature = async (
  documentId: string,
  data: Partial<LoanDocument['esignature']>,
  { authToken }: { authToken?: string } = {}
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = authToken
    ? await requestWithoutToken.patch(
        `/document/${documentId}/esignature`,
        data,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      )
    : await request.patch(`/document/${documentId}/esignature`, data)
  return doc
}

const sendDocument = async (
  documentId: string,
  data: { subject: string; body: string }
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.put(`/document/${documentId}/esignature`, data)
  return doc
}

export {
  updateDocument,
  deleteDocument,
  deleteDocumentVersion,
  getDocumentComments,
  addDocumentComment,
  requestDocument,
  saveDocumentSigners,
  updateDocumentSignature,
  sendDocument,
}
