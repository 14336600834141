import { PropsWithChildren, useCallback, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLoanComments } from 'admin/hooks/use-loan-comments'
import { useLoanModifications } from 'admin/hooks/use-loan-modification'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { ITaskFilterName } from 'admin/pages/Tasks/LoanTasksDrawer'
import { useUpdateLoanOwners } from 'hooks/use-loans'
import { Loan, LoanModification } from 'types'
import { LoanContext } from './LoanContext'

interface Props extends PropsWithChildren {
  loan: Loan
}

const LoanProvider = ({ loan, children }: Props) => {
  const { hash } = useLocation()
  const navigate = useNavigate()
  const { isServicing, isOrigination } = useLoanType()
  const [isTimelineOpen, setIsTimelineOpen] = useState(false)
  const [tasksFilterName, setTasksFilterName] =
    useState<ITaskFilterName>('Loan Tasks')
  const [isTasksOpen, setIsTasksOpen] = useState(hash.startsWith('#tasks'))
  const [editModification, setEditModification] =
    useState<LoanModification | null>(null)
  const isCommentsOpen = hash === '#comments'

  const { data: comments } = useLoanComments(
    { loanId: loan.id },
    {
      refetchInterval: isCommentsOpen ? 10000 : 120000,
    }
  )
  const { data: modifications, isLoading: isModificationsLoading } =
    useLoanModifications(loan.id, {
      enabled: isTimelineOpen,
    })
  const { mutate: updateOwners } = useUpdateLoanOwners()
  const handleOwnersChange = useCallback(
    (owners: string[]) => {
      updateOwners({ id: loan.id, owners })
    },
    [loan.id, updateOwners]
  )

  const handleOpenComments = useCallback(() => {
    setIsTimelineOpen(false)
    setIsTasksOpen(false)
    setTasksFilterName('Loan Tasks')
    navigate('#comments', { replace: true })
  }, [navigate])

  const handleOpenTimeline = useCallback(() => {
    navigate('', { replace: true })
    setIsTasksOpen(false)
    setIsTimelineOpen(true)
    setTasksFilterName('Loan Tasks')
  }, [navigate])

  const handleOpenTasks = useCallback(
    (options?: { filterName?: ITaskFilterName }) => {
      navigate('', { replace: true })
      setIsTimelineOpen(false)
      setIsTasksOpen(true)
      setTasksFilterName(options?.filterName || 'Loan Tasks')
      navigate('#tasks', { replace: true })
    },
    [navigate]
  )

  const value = useMemo(
    () => ({
      loan,
      comments,
      isOrigination,
      isServicing,
      isTimelineOpen,
      openTimeline: handleOpenTimeline,
      closeTimeline: () => setIsTimelineOpen(false),
      editModification,
      openEditModification: (loanModification: LoanModification | null) =>
        setEditModification(loanModification),
      closeEditModification: () => setEditModification(null),
      isCommentsOpen: isCommentsOpen,
      openComments: handleOpenComments,
      closeComments: () => navigate('', { replace: true }),
      modifications: modifications?.modifications || [],
      isModificationsLoading,
      isTasksOpen,
      openTasks: handleOpenTasks,
      closeTasks: () => {
        setIsTasksOpen(false)
        navigate('', { replace: true })
      },
      handleOwnersChange,
      tasksFilterName,
      setTasksFilterName,
      settings: {
        showBudget: loan.settings?.showBudget ?? true,
        showDrawRequests: loan.settings?.showDrawRequests ?? true,
      },
    }),
    [
      loan,
      comments,
      hash,
      isTimelineOpen,
      isOrigination,
      isServicing,
      editModification,
      modifications,
      isModificationsLoading,
      isTasksOpen,
      isCommentsOpen,
      handleOwnersChange,
      handleOpenComments,
      handleOpenTimeline,
      handleOpenTasks,
      tasksFilterName,
    ]
  )

  return <LoanContext.Provider value={value}>{children}</LoanContext.Provider>
}

export { LoanProvider }
