import { useCallback, useEffect, useState } from 'react'
import { Flex } from 'components/Flex'
import { Summary } from 'components/Summary'
import { SavingIndicator } from '../InlineEdit'
import { Editor } from './Editor'
import styles from './styles.module.scss'

type Status = 'untouched' | 'saving' | 'saved'

type Props = {
  value: string
  name?: string
  save: (value: string) => Promise<unknown>
}

function InlineWysiwyg({ value, name, save }: Props) {
  const [status, setStatus] = useState<Status>('untouched')
  const [innerValue, setInnerValue] = useState(value)
  const handleBlur = useCallback(async () => {
    setStatus('saving')
    await save(innerValue)
    setStatus('saved')
  }, [save, innerValue])

  useEffect(() => {
    setInnerValue(value)
  }, [value])

  return (
    <>
      {name ? (
        <Summary
          name={
            <Flex className={styles.summaryTitle} gap={4} alignItems="center">
              {name}
              {status !== 'untouched' && <SavingIndicator status={status} />}
            </Flex>
          }
        >
          <div className={styles.wysiwyg}>
            <Editor
              value={innerValue}
              placeholder="Enter any text you'd like to include. You can add formatting and links using the toolbar at the bottom."
              onBlur={handleBlur}
              onChange={setInnerValue}
            />
          </div>
        </Summary>
      ) : (
        <Editor
          value={innerValue}
          placeholder="Enter any text you'd like to include. You can add formatting and links using the toolbar at the bottom."
          onBlur={handleBlur}
          onChange={setInnerValue}
        />
      )}
    </>
  )
}

export { InlineWysiwyg }
