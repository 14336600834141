import { request } from 'services/request'
import { Person, Meta, Filter } from 'types'
import { Search } from 'types/table'

type Investor = Person

const getLoanInvestors = async (
  loanId: string,
  params: {
    search?: Search
    filter?: Filter
  }
): Promise<{ investors: Investor[]; meta: Meta }> => {
  const {
    data: { investors, pagination },
  } = await request.get(`/loan/${loanId}/investor`, { params })
  return {
    investors,
    meta: pagination,
  }
}

export { getLoanInvestors }
