import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  loan: Loan
}

export const PanelLoanInfo = ({ loan }: Props) => (
  <Panel title="Loan Info">
    <Grid gap={16}>
      <Grid.Item sm={6}>
        <Summary name="Original Amount">{formatUsd(loan.amount)}</Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Principal Paid">{formatUsd(loan.principalPaid)}</Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Current Principal">
          {formatUsd(loan.principalBalance)}
        </Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Unpaid Interest">
          {formatUsd(loan.unpaidInterest)}
        </Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Unpaid Charges">{formatUsd(loan.unpaidCharges)}</Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Closing Date">{formatDate(loan.dateClosing)}</Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Maturity Date">{formatDate(loan.dateMaturity)}</Summary>
      </Grid.Item>
    </Grid>
  </Panel>
)
