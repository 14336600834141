import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { useMemo, useState, useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import PaymentDueStatusBadge from 'components/Badge/PaymentDueStatusBadge'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useMobile } from 'hooks/use-mobile'
import { PaymentDue } from 'types'
import { Filter } from 'types/table'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  selectedCount: number
  checkboxes: {
    master: boolean
    ids: string[]
  }
  onCheck: (id: string) => void
  filter?: Filter | undefined
  data?: PaymentDue[]
  loading?: boolean
  onRowClick: (paymentDue?: PaymentDue) => void
}

function TablePaymentsDue({
  data = [],
  checkboxes,
  selectedCount,
  onCheck,
  loading,
  onRowClick,
}: Props) {
  const [expanded, setExpanded] = useState(false)
  const { isTablet } = useMobile()

  const handleClickRow = useCallback(
    (row?: PaymentDue) => {
      if (row?.isNextPayment) {
        onRowClick(row)
      }
    },
    [onRowClick]
  )
  const columns: ColumnDef<PaymentDue>[] = useMemo(
    () => [
      {
        header: () => (
          <div className="flex gap-2 -ml-0.5">
            <Checkbox
              checked={checkboxes.master}
              indeterminate={!!checkboxes.ids.length}
              onChange={() => onCheck('master')}
            />
            {selectedCount > 0 ? `${selectedCount} Selected` : 'Due Date'}
          </div>
        ),
        accessorKey: 'dateDue',
        size: 130,
        cell: ({ getValue, row }) => {
          const { id, isNextPayment } = row.original
          return (
            <div className="flex md:gap-2 -ml-0.5">
              <Checkbox
                disabled={!isNextPayment}
                checked={
                  isNextPayment &&
                  ((checkboxes.master && !checkboxes.ids.includes(id)) ||
                    (!checkboxes.master && checkboxes.ids.includes(id)))
                }
                onChange={() => onCheck(id)}
                onClick={(e) => e.stopPropagation()}
              />
              {formatDate(getValue() as string)}
            </div>
          )
        },
        meta: {
          plainHeader: 'Due Date',
        },
      },
      {
        header: 'Loan',
        id: 'loan',
        cell: ({ row }) => {
          let hint = ''
          const { loan, isNextPayment } = row.original
          if (!isNextPayment) {
            hint = 'There is a previous due payment for this loan'
          }
          return (
            <>
              <NavLink
                to={pathTo('servicingLoanTab', loan.id, 'payments')}
                className="link"
              >
                {loan.name}
              </NavLink>
              {hint && (
                <Tooltip content={hint}>
                  <Icon
                    name={IconName.info}
                    size="md"
                    className={clsx('ml-1', styles.hint)}
                  />
                </Tooltip>
              )}
              {loan.banking && (
                <Tooltip content="Valid banking info">
                  <Icon
                    name={IconName.dollar}
                    size="md"
                    className={clsx('ml-1', styles.info)}
                  />
                </Tooltip>
              )}
            </>
          )
        },
      },
      {
        header: 'Borrower',
        id: 'borrowers',
        cell: ({ row }) =>
          row.original.borrowers?.map(({ name }) => name).join(', ') || '-',
      },
      {
        header: () => (
          <Flex gap={8} alignItems="center" className={styles.columnName}>
            Amount
            <div className={styles.icon}>
              <Icon
                name={expanded ? IconName.minus : IconName.plus}
                size="sm"
              />
            </div>
            <div
              className={styles.iconWrapper}
              onClick={() => {
                setExpanded(!expanded)
              }}
            />
          </Flex>
        ),
        accessorKey: 'amount',
        size: 150,
        cell: ({ getValue }) => (
          <Text className="font-bold">{formatUsd(getValue() as number)}</Text>
        ),
        meta: {
          align: 'right',
          plainHeader: 'Amount',
        },
      },
      ...(expanded
        ? [
            {
              header: 'Regular Payment',
              accessorKey: 'regularPayment',
              size: 130,
              cell: ({ getValue }) => (
                <Text className="font-bold">{formatUsd(getValue())}</Text>
              ),
              meta: {
                align: 'right',
              },
            },
            {
              header: 'Charges',
              accessorKey: 'charges',
              size: 130,
              cell: ({ getValue }) => (
                <Text className="font-bold">{formatUsd(getValue())}</Text>
              ),
              meta: {
                align: 'right',
              },
            },
          ]
        : []),
      {
        header: 'Status',
        accessorKey: 'status',
        size: 90,
        cell: ({ getValue }) => (
          <PaymentDueStatusBadge status={getValue() as PaymentDue['status']} />
        ),
      },
    ],
    [checkboxes, selectedCount, expanded, isTablet, onCheck]
  )

  return (
    <Table
      className={clsx({ [styles.tableExpanded]: expanded })}
      columns={columns}
      data={data}
      loading={loading}
      onClick={handleClickRow}
    />
  )
}

export default TablePaymentsDue
