import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { useSession } from 'hooks/use-session'
import { PanelAPI } from './PanelAPI'
import { PanelBankingInfo } from './PanelBankingInfo'
import { PanelGeneralSettings } from './PanelGeneralSettings'
import { PanelSpread } from './PanelSpread'
import { PanelTrust } from './PanelTrust'

function GeneralSettings() {
  const { user } = useSession()
  const isServicing = user?.client.servicing

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="General Settings"
        />
        <PanelGeneralSettings />
        <PanelSpread />
        <PanelTrust />
        {isServicing && <PanelBankingInfo />}
        <PanelAPI />
      </Flex>
    </MainContent>
  )
}

export { GeneralSettings }
