import writeXlsxFile, { SheetData } from 'write-excel-file'

const generateXlsx = async (filename, rows) => {
  const header = Object.keys(rows[0]).map((key) => ({
    value: key.replace(/[$%]$/, ''),
    fontWeight: 'bold',
    key,
  }))
  const columnWidths = {}
  const dataRows = rows.map((row) =>
    Object.entries(row).map(([key, value]) => {
      switch (
        key.endsWith('$')
          ? 'currency'
          : key.endsWith('%')
            ? 'percentage'
            : typeof value
      ) {
        case 'currency':
          return {
            type: Number,
            value,
            format: '#,##0.00',
          }
        case 'percentage':
          return {
            type: Number,
            value,
            format: '##0.000',
          }
        case 'number':
          return {
            type: Number,
            value,
          }
        case 'string':
        default:
          if (
            value &&
            (!columnWidths[key] || value?.toString().length > columnWidths[key])
          ) {
            columnWidths[key] = value?.toString().length
          }
          return {
            type: String,
            value: value?.toString(),
          }
      }
    })
  )

  await writeXlsxFile([header, ...dataRows] as SheetData, {
    fileName: `${filename}.xlsx`,
    columns: header.map((column) => ({
      width: [(columnWidths[column.key] || 0) + 5, 12, column.value.length]
        .sort((a, b) => a - b)
        .pop(),
    })),
  })
}

export { generateXlsx }
