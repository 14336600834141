import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getLoanAddressUnitDetails,
  setLoanAddressUnitDetails,
} from 'admin/services/api/loan-address'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'

const useLoanAddressUnitDetails = ({
  loanId,
  addressId,
}: {
  loanId: string
  addressId: string
}) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, 'address', addressId],
    queryFn: () => getLoanAddressUnitDetails(loanId, addressId),
  })
}

const useUpdateLoanAddressUnitDetails = ({
  loanId,
  addressId,
}: {
  loanId: string
  addressId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (unitDetailsCsv: string) =>
      setLoanAddressUnitDetails(loanId, addressId, unitDetailsCsv),
    onSuccess: (data) => {
      queryClient.setQueryData([KEY_LOANS, loanId, 'address', addressId], data)
    },
    onError: handleErrorResponse,
  })
}

export { useLoanAddressUnitDetails, useUpdateLoanAddressUnitDetails }
