import {
  useAddVendor,
  useVendors,
  useVendorLinkAccount,
  useVendorUpdateLinkAccount,
  useVendorUnlinkAccount,
} from 'admin/hooks/use-vendors'
import { VendorDetails } from 'admin/services/api/vendors'
import { PanelLinkedAccounts } from 'components/Panel/PanelLinkedAccounts'

interface Props {
  vendor: VendorDetails
}

function PanelVendorLinkedAccounts({ vendor }: Props) {
  const add = useVendorLinkAccount({ vendorId: vendor.id })
  const update = useVendorUpdateLinkAccount({ vendorId: vendor.id })
  const remove = useVendorUnlinkAccount({ vendorId: vendor.id })
  const addPerson = useAddVendor()
  const availableAccounts = useVendors({
    filter: {
      type: [vendor.type === 'individual' ? 'entity' : 'individual'],
      isFund: null,
    },
  })

  return (
    <PanelLinkedAccounts
      showTitle={false}
      person={vendor}
      availableAccounts={availableAccounts.data?.vendors || []}
      addPerson={addPerson}
      addLink={add}
      updateLink={update}
      removeLink={remove}
      type="vendor"
    />
  )
}

export default PanelVendorLinkedAccounts
