import { useMemo, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { filterIconByType } from './filter-conditions'
import { IFilterConfig } from './types'

interface IProps {
  filtersConfig: IFilterConfig[]
  onFilterSelect: (filterId: string) => void
}

export const InitialFilter = ({ filtersConfig, onFilterSelect }: IProps) => {
  const [search, setSearch] = useState<string>()
  const visibleFilters = useMemo(
    () =>
      filtersConfig.filter((filter) =>
        filter.label.toLowerCase().includes(search?.toLowerCase() || '')
      ),
    [filtersConfig, search]
  )

  return (
    <>
      <Flex
        gap={10}
        alignItems="center"
        className="py-0.5 px-1 border-solid border-0 border-b-[1px] border-grey-200"
      >
        <Search
          fieldClassName="flex-grow"
          className="!border-0 !w-full"
          placeholder="Filter by..."
          delay={0}
          autoFocus={false}
          search={search}
          onSearch={setSearch}
        />
      </Flex>
      <Flex stack gap={0} className="py-1">
        {visibleFilters.map((filter) => (
          <Flex
            key={filter.id}
            gap={6}
            alignItems="center"
            className="mx-1 py-1.5 px-2 rounded-sm hover:bg-grey-75 cursor-pointer"
            onClick={() => {
              onFilterSelect(filter.id)
            }}
          >
            <Icon
              name={filterIconByType.get(filter.type) || IconName.boldListUl}
              className="text-grey-900"
            />
            <div className="leading-5 text-grey-900">{filter.label}</div>
          </Flex>
        ))}
        {!visibleFilters.length && (
          <Flex
            stack
            alignItems="center"
            justifyContent="center"
            className="pt-12 pb-16"
          >
            <Icon
              name={IconName.magnifyingGlass}
              className="text-grey-500 w-7 h-7"
            />
            <Text variant="l">No search results</Text>
          </Flex>
        )}
      </Flex>
    </>
  )
}
