import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer, Slide } from 'react-toastify'
import { SessionProvider as AdminSessionProvider } from 'admin/pages/Session/SessionProvider'
import { TasksProvider } from 'admin/pages/Tasks/TasksProvider'
import { SessionProvider as BorrowerSessionProvider } from 'borrower/pages/Session/SessionProvider'
import { AppCustomization } from 'components/AppCustomization'
import { AppTheme } from 'components/AppTheme'
import { AppVersion } from 'components/AppVersion'
import { BugsnagErrorBoundary } from 'components/BugsnagErrorBoundary'
import Portal from 'components/Modal/Portal'
import { useAnalytics } from 'hooks/use-analytics'
import { handleErrorResponse } from 'services/request'
import { isAdminRoute } from 'utils/routes'
import Routes from './routes'
import './styles/styles.scss'

const App = () => {
  useAnalytics()
  const isAdmin = isAdminRoute()
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (error) => handleErrorResponse(error as AxiosError),
    }),
  })

  return (
    <React.StrictMode>
      <BugsnagErrorBoundary>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <AppVersion />
            {isAdmin ? (
              <AdminSessionProvider>
                <AppCustomization>
                  <TasksProvider>
                    <Routes admin />
                  </TasksProvider>
                </AppCustomization>
              </AdminSessionProvider>
            ) : (
              <BorrowerSessionProvider>
                <AppCustomization>
                  <AppTheme />
                  <Routes borrower />
                </AppCustomization>
              </BorrowerSessionProvider>
            )}
            <Portal>
              <ToastContainer
                position="bottom-left"
                transition={Slide}
                pauseOnFocusLoss={false}
                hideProgressBar
              />
            </Portal>
          </QueryClientProvider>
        </BrowserRouter>
      </BugsnagErrorBoundary>
    </React.StrictMode>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
