import clsx from 'clsx'
import { IMaskInput } from 'react-imask'
import { formatPhone, parsePhone } from 'components/InputPhone/helpers'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

function Phone(props: CustomFieldProps) {
  return (
    <Field {...props}>
      {({ form, meta: { touched, error }, field }: FieldChildProps) => (
        <IMaskInput
          id={field.name}
          unmask="typed"
          mask="(000) 000-0000"
          placeholder="(000) 000-0000"
          className={clsx(styles.input, {
            [styles.errorField]: touched && error,
          })}
          value={parsePhone(field.value)}
          name={field.name}
          autoComplete="off"
          disabled={props.disabled}
          onAccept={(value) => {
            form.setFieldValue(field.name, formatPhone(value as string))
          }}
        />
      )}
    </Field>
  )
}

export default Phone
