import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import styles from './styles.module.scss'

interface SideNavigationSettingsLinkProps {
  to?: string
  onClick?: () => void
  label: string
  hideActive?: boolean
}

function SideNavigationSettingsBlock({
  to,
  onClick = () => {},
  label,
  hideActive,
}: SideNavigationSettingsLinkProps) {
  return to ? (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.link, styles.settingsBlockLink, {
          [styles.selected]: isActive && !hideActive,
        })
      }
      to={to}
      onClick={onClick}
    >
      {label}
    </NavLink>
  ) : (
    <a
      onClick={onClick}
      className={clsx(styles.link, styles.settingsBlockLink)}
    >
      {label}
    </a>
  )
}

export default SideNavigationSettingsBlock
