import { scheme } from 'admin/pages/Settings/Application/initialScheme'
import { request, requestWithoutToken } from 'services/request'
import { BaseField, ApplicationScheme } from 'types'

const getApplicationFields = async (): Promise<BaseField[]> => {
  const {
    data: { fields },
  } = await request.get('/application/field')
  return fields
}

const getApplicationScheme = async ({
  clientId,
}: {
  clientId: string
}): Promise<ApplicationScheme> => {
  try {
    const {
      data: { template },
    } = await requestWithoutToken.get('/application/template', {
      params: { clientId },
    })
    return template.format
  } catch {
    return scheme
  }
}

const saveApplicationScheme = async (
  scheme: ApplicationScheme
): Promise<ApplicationScheme> => {
  const {
    data: { template },
  } = await request.patch('/application/template', { format: scheme })
  return template.format
}

const deleteApplicationScheme = async (): Promise<void> => {
  await request.delete('/application/template')
}

export {
  getApplicationFields,
  getApplicationScheme,
  saveApplicationScheme,
  deleteApplicationScheme,
}
