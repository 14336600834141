import { ColumnDef } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { AmortizationPayment } from 'admin/hooks/use-loan-schedule'
import AmortizationTableStatus from 'components/Badge/AmortizationTableStatus'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

type Props = {
  data?: AmortizationPayment[]
  loading: boolean
  loan: Loan
}

function TableAmortization({ data = [], loading, loan }: Props) {
  const columns: ColumnDef<AmortizationPayment>[] = useMemo(
    () => [
      {
        header: 'Payment',
        accessorKey: 'i',
        cell: ({ getValue }) => (getValue() as number) + 1,
      },
      {
        header: 'Due',
        accessorKey: 'due',
        size: 250,
        cell: ({ getValue, row }) => (
          <Flex>
            {formatDate(getValue() as string)}
            {row.original?.status === 'Paid' && row.original.datePaid && (
              <AmortizationTableStatus
                due={getValue() as string}
                paid={row.original.datePaid}
                loan={loan}
              />
            )}
          </Flex>
        ),
      },

      {
        header: 'Principal',
        accessorKey: 'principal',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
      {
        header: 'Interest',
        accessorKey: 'interest',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
      {
        header: 'Total Payment',
        accessorKey: 'regularPayment',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
      {
        header: () => (
          <Flex alignItems="center" gap={6}>
            <Text>Balance</Text>
            <Tooltip content="Balance as of the Due Date">
              <Icon name={IconName.info} size="md" className="text-grey-600" />
            </Tooltip>
          </Flex>
        ),
        accessorKey: 'principalBalance',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableAmortization
