import clsx from 'clsx'
import { forwardRef, ForwardedRef } from 'react'
import { Checkbox } from '../Checkbox'
import styles from './styles.module.scss'

interface ItemProps {
  text: string
  active: boolean
  selected: boolean
}

const Item = forwardRef(
  (
    { text, active, selected, ...rest }: ItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        role="option"
        ref={ref}
        className={clsx(styles.item, {
          [styles.active]: active,
        })}
        {...rest}
      >
        {text}
        <Checkbox checked={selected} readOnly />
      </div>
    )
  }
)

Item.displayName = 'ColumnFilter.Item'

export type { ItemProps }
export default Item
