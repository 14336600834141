import { request } from 'services/request'
import { Statement } from 'types'

const getInvestorStatements = async (
  investorId: string
): Promise<Statement[]> => {
  const {
    data: { statements },
  } = await request.get(`/investor/${investorId}/statement`)
  return statements
}

const getInvestorCashBalance = async ({
  investorId,
  date,
}: {
  investorId: string
  date?: string
}): Promise<number> => {
  const {
    data: { report },
  } = await request.post(`/report/investor/${investorId}/cash-balance`, {
    date,
  })
  return report
}

const getInvestorPrincipalInvestedBalance = async ({
  investorId,
  date,
}: {
  investorId: string
  date?: string
}): Promise<number> => {
  const {
    data: { report },
  } = await request.post(
    `report/investor/${investorId}/principal-invested-balance`,
    {
      date,
    }
  )
  return report
}

export {
  getInvestorStatements,
  getInvestorCashBalance,
  getInvestorPrincipalInvestedBalance,
}
