import { HeaderContext } from '@tanstack/react-table'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { Sort, ColumnMeta } from 'types'
import { ColumnFilter } from '../ColumnFilter'
import styles from './styles.module.scss'

interface HeadProps extends HeaderContext<any, any> {
  children: ReactNode
  sort?: Sort
}

function Head({ children, header, column, sort }: HeadProps) {
  const meta = column.columnDef.meta as ColumnMeta
  const align = meta?.align || 'left'
  const filter = meta?.filter

  return (
    <div
      className={clsx(styles.header, meta?.sortable && styles.sortable, {
        [styles.alignLeft]: align === 'left',
        [styles.alignRight]: align === 'right',
        [styles.alignCenter]: align === 'center',
      })}
    >
      {children}
      {filter && (
        <>
          {' '}
          <ColumnFilter
            key="filter"
            items={filter.options}
            filterValues={filter.selected}
            onFilterValuesChange={filter.onChange}
          />
        </>
      )}
      {sort?.[0] === header.id && sort?.[1] === 'ASC' && <>&nbsp;&uarr;</>}
      {sort?.[0] === header.id && sort?.[1] === 'DESC' && <>&nbsp;&darr;</>}

      {meta?.sortable && sort?.[0] !== header.id && (
        <span className={styles.sortArrowUp}>&nbsp;&uarr;</span>
      )}
    </div>
  )
}

export default Head
