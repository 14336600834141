import { ApplicationScheme, ApplicationSchemeField } from 'types'
import { uuid } from 'utils/uuid'

const getIndividualFields = (
  prefix: 'borrower' | 'guarantor'
): ApplicationSchemeField[] => [
  {
    id: 'name',
    label: 'Full Name',
    type: 'text',
    enabled: true,
    required: true,
    readonly: true,
    mapTo: `${prefix}-individual-name`,
  },
  {
    id: 'credit_score',
    label: 'Credit Score',
    type: 'number',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-individual-score`,
  },
  {
    id: 'citizenship',
    label: 'Citizenship',
    type: 'option',
    options: [
      { label: 'U.S. Citizen', value: 'U.S. Citizen' },
      { label: 'Permanent Resident', value: 'Permanent Resident' },
      { label: 'Foreign National', value: 'Foreign National' },
    ],
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-individual-citizenship`,
  },
  {
    id: 'num_flipped',
    label: 'Number of Flips',
    type: 'number',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-individual-flips`,
  },
  {
    id: 'phone',
    label: 'Phone',
    type: 'phone',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-individual-phone`,
  },
  {
    id: 'email',
    label: 'Email',
    type: 'email',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-individual-email`,
  },
]

const getEntityFields = (
  prefix: 'borrower' | 'guarantor'
): ApplicationSchemeField[] => [
  {
    id: 'name',
    label: 'Company Name',
    type: 'text',
    enabled: true,
    required: true,
    readonly: true,
    mapTo: `${prefix}-entity-name`,
  },
  {
    id: 'entity_type',
    label: 'Type',
    type: 'option',
    options: [
      {
        label: 'Corporation',
        value: 'Corporation',
      },
      {
        label: 'General Partnership',
        value: 'General Partnership',
      },
      {
        label: 'Limited Liability Company',
        value: 'Limited Liability Company',
      },
      {
        label: 'Limited Partnership',
        value: 'Limited Partnership',
      },
      {
        label: 'Trust',
        value: 'Trust',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-entity-type`,
  },
  {
    id: 'jurisdiction',
    label: 'Jurisdiction',
    type: 'option',
    options: [
      { label: 'United States', value: 'United States' },
      { label: 'Canada', value: 'Canada' },
      { value: 'Alabama', label: 'Alabama' },
      { value: 'Alaska', label: 'Alaska' },
      { value: 'Arizona', label: 'Arizona' },
      { value: 'Arkansas', label: 'Arkansas' },
      { value: 'California', label: 'California' },
      { value: 'Colorado', label: 'Colorado' },
      { value: 'Connecticut', label: 'Connecticut' },
      { value: 'District of Columbia', label: 'District of Columbia' },
      { value: 'Delaware', label: 'Delaware' },
      { value: 'Florida', label: 'Florida' },
      { value: 'Micronesia', label: 'Micronesia' },
      { value: 'Georgia', label: 'Georgia' },
      { value: 'Hawaii', label: 'Hawaii' },
      { value: 'Idaho', label: 'Idaho' },
      { value: 'Illinois', label: 'Illinois' },
      { value: 'Indiana', label: 'Indiana' },
      { value: 'Iowa', label: 'Iowa' },
      { value: 'Kansas', label: 'Kansas' },
      { value: 'Kentucky', label: 'Kentucky' },
      { value: 'Louisiana', label: 'Louisiana' },
      { value: 'Maine', label: 'Maine' },
      { value: 'Maryland', label: 'Maryland' },
      { value: 'Massachusetts', label: 'Massachusetts' },
      { value: 'Michigan', label: 'Michigan' },
      { value: 'Minnesota', label: 'Minnesota' },
      { value: 'Marshall Islands', label: 'Marshall Islands' },
      { value: 'Mississippi', label: 'Mississippi' },
      { value: 'Missouri', label: 'Missouri' },
      { value: 'Montana', label: 'Montana' },
      { value: 'Nebraska', label: 'Nebraska' },
      { value: 'Nevada', label: 'Nevada' },
      { value: 'New Hampshire', label: 'New Hampshire' },
      { value: 'New Jersey', label: 'New Jersey' },
      { value: 'New Mexico', label: 'New Mexico' },
      { value: 'New York', label: 'New York' },
      { value: 'North Carolina', label: 'North Carolina' },
      { value: 'North Dakota', label: 'North Dakota' },
      { value: 'Ohio', label: 'Ohio' },
      { value: 'Oklahoma', label: 'Oklahoma' },
      { value: 'Oregon', label: 'Oregon' },
      { value: 'Pennsylvania', label: 'Pennsylvania' },
      { value: 'Palau', label: 'Palau' },
      { value: 'Rhode Island', label: 'Rhode Island' },
      { value: 'South Carolina', label: 'South Carolina' },
      { value: 'South Dakota', label: 'South Dakota' },
      { value: 'Tennessee', label: 'Tennessee' },
      { value: 'Texas', label: 'Texas' },
      { value: 'Utah', label: 'Utah' },
      { value: 'Vermont', label: 'Vermont' },
      { value: 'Virginia', label: 'Virginia' },
      { value: 'Washington', label: 'Washington' },
      { value: 'West Virginia', label: 'West Virginia' },
      { value: 'Wisconsin', label: 'Wisconsin' },
      { value: 'Wyoming', label: 'Wyoming' },
      { value: 'American Samoa', label: 'American Samoa' },
      { value: 'Guam', label: 'Guam' },
      {
        value: 'Northern Mariana Islands',
        label: 'Northern Mariana Islands',
      },
      { value: 'Puerto Rico', label: 'Puerto Rico' },
      {
        value: 'United States Minor Outlying Islands',
        label: 'United States Minor Outlying Islands',
      },
      { value: 'Virgin Islands', label: 'Virgin Islands' },
      { value: 'Alberta', label: 'Alberta' },
      { value: 'British Columbia', label: 'British Columbia' },
      { value: 'Manitoba', label: 'Manitoba' },
      { value: 'New Brunswick', label: 'New Brunswick' },
      {
        value: 'Newfoundland and Labrador',
        label: 'Newfoundland and Labrador',
      },
      { value: 'Nova Scotia', label: 'Nova Scotia' },
      { value: 'Nunavut', label: 'Nunavut' },
      { value: 'Northwest Territories', label: 'Northwest Territories' },
      { value: 'Ontario', label: 'Ontario' },
      { value: 'Prince Edward Island', label: 'Prince Edward Island' },
      { value: 'Quebec', label: 'Quebec' },
      { value: 'Saskatchewan', label: 'Saskatchewan' },
      { value: 'Yukon', label: 'Yukon' },
    ],
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-entity-jurisdiction`,
  },
  {
    id: 'num_flipped',
    label: 'Number of Flips',
    type: 'number',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-entity-flips`,
  },
  {
    id: 'phone',
    label: 'Phone',
    type: 'phone',
    enabled: true,
    required: true,
    disableDropOut: true,
    mapTo: `${prefix}-entity-phone`,
  },
]

const scheme: ApplicationScheme = {
  submitted: false,
  pages: [
    {
      id: uuid(),
      tag: 'Transaction Details',
      header: 'Transaction Details',
      description: '',
      fields: [
        {
          id: uuid(),
          label: 'Use of Funds',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: 'use-of-funds',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Purchase',
              label: 'Purchase',
            },
            {
              value: 'Refinance',
              label: 'Refinance',
            },
            {
              value: 'Cashout',
              label: 'Cashout',
            },
            {
              value: 'Construction',
              label: 'Construction',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Loan Type',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: 'loan-type',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Bridge + Rehab',
              label: 'Bridge + Rehab',
            },
            {
              value: 'Rentals',
              label: 'Rentals',
            },
            {
              value: 'Bridge',
              label: 'Bridge',
            },
            {
              value: 'Construction',
              label: 'Construction',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Exit Strategy',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Hold',
              label: 'Hold',
            },
            {
              value: 'Refinance',
              label: 'Refinance',
            },
            {
              value: 'Sell',
              label: 'Sell',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Loan Amount',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: 'loan-amount',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Closing Date',
          type: 'date',
          enabled: true,
          required: true,
          mapTo: 'origination-date',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Reason For Closing Date',
          type: 'text',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          multiline: false,
        },
      ],
    },
    {
      tag: 'Property',
      header: 'Property',
      description: '',
      id: uuid(),
      fields: [
        {
          id: uuid(),
          label: 'Property Address',
          type: 'address',
          enabled: true,
          required: true,
          mapTo: 'collateral-address',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Units',
          type: 'number',
          enabled: true,
          required: true,
          mapTo: 'number-units',
          description: '',
          placeholder: '',
          min: '',
          max: '',
        },
        {
          id: uuid(),
          label: 'Property Type',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Single-Family',
              label: 'Single-Family',
            },
            {
              value: 'Multifamily (2-4 Units)',
              label: 'Multifamily (2-4 Units)',
            },
            {
              value: 'Multifamily (5+ Units)',
              label: 'Multifamily (5+ Units)',
            },
            {
              value: 'Commercial',
              label: 'Commercial',
            },
            {
              value: 'Industrial',
              label: 'Industrial',
            },
            {
              value: 'Land',
              label: 'Land',
            },
            {
              value: 'Condo',
              label: 'Condo',
            },
            {
              value: 'Mixed-Use',
              label: 'Mixed-Use',
            },
            {
              value: 'Other',
              label: 'Other',
            },
            {
              value: 'N/A',
              label: 'N/A',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Property Condition',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Uninhabitable',
              label: 'Uninhabitable',
            },
            {
              value: 'Significant Repairs',
              label: 'Significant Repairs',
            },
            {
              value: 'Worn Adequate',
              label: 'Worn Adequate',
            },
            {
              value: 'Well Maintained',
              label: 'Well Maintained',
            },
            {
              value: 'Excellent',
              label: 'Excellent',
            },
            {
              value: 'Nearly New',
              label: 'Nearly New',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Occupancy Status',
          type: 'option',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          options: [
            {
              value: 'Vacant',
              label: 'Vacant',
            },
            {
              value: 'Leased',
              label: 'Leased',
            },
            {
              value: 'Owner Occupied',
              label: 'Owner Occupied',
            },
            {
              value: 'Partially Leased',
              label: 'Partially Leased',
            },
            {
              value: 'Unknown',
              label: 'Unknown',
            },
            {
              value: 'N/A',
              label: 'N/A',
            },
          ],
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Purchase Price',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: 'purchase-price-collateral',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Purchase Date',
          type: 'date',
          enabled: true,
          required: true,
          mapTo: '2a9869f9-cf36-4245-9575-c8ffd028c030',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Existing Debt',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: '0c26f512-5d7d-403c-a00c-bb9ab842ebfe',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'ARV',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: 'arv-borrower',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Market Rent',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: '0bfb6fa0-42bf-468e-87ab-17c202615a2d',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'Property Taxes',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: 'c7688544-0174-4fd3-b1f3-2cf002b994aa',
          description: '',
          placeholder: '',
        },
        {
          id: uuid(),
          label: 'HOA Fees',
          type: 'currency',
          enabled: true,
          required: true,
          mapTo: '02b4b886-018a-49f4-801c-2b2d6feafd03',
          description: '',
          placeholder: '',
        },
      ],
    },
    {
      id: uuid(),
      tag: 'Borrower Details',
      header: 'Borrower Details',
      description: '',
      fields: [
        {
          id: 'borrower',
          label: 'Borrower',
          type: 'borrower',
          enabled: true,
          required: true,
          mapTo: '',
          value: [],
          individualFields: getIndividualFields('borrower'),
          entityFields: getEntityFields('borrower'),
        },
        {
          id: 'guarantor',
          label: 'Guarantor',
          type: 'guarantor',
          enabled: true,
          required: true,
          mapTo: '',
          individualFields: getIndividualFields('guarantor'),
          entityFields: getEntityFields('guarantor'),
        },
      ],
    },
    {
      tag: 'Declarations',
      header: 'Declarations',
      description: '',
      id: uuid(),
      fields: [
        {
          id: uuid(),
          label: 'Are there any outstanding judgments against you?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Have you been declared bankrupt in the past 7 years?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label:
            'Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Are you a party to a lawsuit?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label:
            'Have you directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label:
            'Are you presently delinquent or in default on any Federal debt or any other loan, mortgage, financial obligation, bond?',
          type: 'yes-no',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          defaultValue: '',
        },
        {
          id: uuid(),
          label: 'Signature',
          type: 'signature',
          enabled: true,
          required: true,
          mapTo: '',
          description: '',
          placeholder: '',
          signatureConfirmation:
            'I agree with the terms and certify that the information provided is true and accurate',
        },
      ],
    },
  ],
}

export { scheme }
