import { useState } from 'react'
import { Button } from 'components/Button'
import { Loan } from 'types'
import PanelVendors from './PanelVendors'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

function TabVendors({ loan }: Props) {
  const [adding, setAdding] = useState(false)

  return (
    <div className={styles.tabContent}>
      <Button
        className={styles.tabButton}
        onClick={() => setAdding(true)}
        disabled={loan.lock}
      >
        Add Vendor
      </Button>
      <PanelVendors
        loan={loan}
        adding={adding}
        setAdding={setAdding}
        showCash={false}
      />
    </div>
  )
}

export default TabVendors
