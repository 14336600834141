import { useNavigate } from 'react-router-dom'
import { ReportCard } from 'admin/pages/Reports/ReportCards'
import { pathTo } from 'admin/path-to'
import { getLoanTapeDownloadUrl } from 'admin/services/api/reports'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { message } from 'utils/message'
import styles from './styles.module.scss'

type Props = {
  data: ReportCard[]
}

function TableReportCards({ data = [] }: Props) {
  const navigate = useNavigate()

  const handleRowClick = async (item?: ReportCard) => {
    if (item?.id === 'loan-tape') {
      const uploadToast = message.upload('Loan Tape')
      uploadToast.show()
      await getLoanTapeDownloadUrl().then(({ url }) => {
        uploadToast.complete()
        location.href = url
      })
    } else if (item?.id) {
      navigate(pathTo('report', item.id))
    }
  }

  return (
    <div className={styles.items}>
      {data.map((item) => (
        <Panel
          title={item.name}
          key={item.id}
          className={styles.item}
          header={<Header variant="h4">{item.name}</Header>}
          onClick={() => handleRowClick(item)}
        >
          <div className={styles.details}>
            <Text className={styles.cardDescription}>{item.description}</Text>
          </div>
        </Panel>
      ))}
    </div>
  )
}

export default TableReportCards
