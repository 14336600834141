import { getLoanSchedule } from 'admin/services/api/loan-schedule'
import { Row } from 'types'

const downloadLoanSchedule = async (loanId: string): Promise<Row[]> => {
  const { payments } = await getLoanSchedule(loanId, {})
  return payments.map((payment) => ({
    Payment: payment.i + 1,
    Due: payment.due,
    Paid: payment.datePaid,
    Principal$: payment.principal,
    Interest$: payment.interest,
    'Total Payment$': payment.regularPayment,
    Balance$: payment.principalBalance,
  }))
}

export { downloadLoanSchedule }
