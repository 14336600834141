import { getLoans } from 'services/api/loans'
import { Filter, Row, Sort } from 'types'

const downloadLoans = async (
  filter?: Filter,
  sort?: Sort,
  search?: string
): Promise<Row[]> => {
  const { loans } = await getLoans({
    filter,
    search,
    sort,
  })
  return loans.map((loan) => ({
    ID: loan.number,
    Name: loan.name,
    Origination: loan.dateClosing,
    Borrower: loan.borrowers[0]?.name,
    'Principal Balance$': loan.principalBalance,
    Status: loan.status,
    'Maturity Date': loan.dateMaturity,
  }))
}

const downloadLoansReport = async (
  filter?: Filter,
  sort?: Sort,
  search?: string
): Promise<Row[]> => {
  const { loans } = await getLoans({
    filter,
    search,
    sort,
  })
  return loans.map((loan) => ({
    ID: loan.number,
    Name: loan.name,
    Borrower: loan.borrowers[0]?.name,
    Product: loan.product?.name,
    Amount$: loan.amount,
    'Closing Date': loan.dateClosing,
    ...((filter?.status as string[])?.includes('liquidated')
      ? { Liquidated: loan.datePaidoff }
      : {}),
  }))
}

export { downloadLoans, downloadLoansReport }
