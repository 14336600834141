import { Field as IField } from 'types'
import { getField } from 'utils/fields'
import Field, { CustomFieldProps } from './Field'
import Select from './Select'

type Props = CustomFieldProps & {
  fields: IField[]
  fieldId: string
}

function ProductField({ fields, fieldId, ...props }: Props) {
  const productField = getField(fields, fieldId)
  const label = props.label || productField?.name
  const type = productField?.type[0] === 'option' ? 'option' : 'text'

  return type === 'text' ? (
    <Field label={label} {...props} />
  ) : (
    <Select
      label={label}
      portal
      options={productField?.options || []}
      {...props}
    />
  )
}

export default ProductField
