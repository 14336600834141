import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Table } from 'components/Table'
import { Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { getFundingType } from 'utils/loan-transactions'

type Props = {
  data?: Transaction[]
  loading: boolean
}

function TableInvestmentTransactions({ data = [], loading }: Props) {
  const columns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        size: 140,
        cell: ({ getValue }) => formatDate(getValue() as string),
      },
      {
        header: 'Type',
        accessorKey: 'gl',
        size: 140,
        cell: ({ row }) => getFundingType(row.original),
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export { TableInvestmentTransactions }
