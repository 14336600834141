import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addFundClass,
  deleteFundClass,
  editFundClass,
  getFundClasses,
} from 'admin/services/api/fund-class'
import { KEY_FUNDS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Class } from 'types'
import { message } from 'utils/message'

const useFundClasses = (id: string) => {
  return useQuery({
    queryKey: [KEY_FUNDS, id, 'class'],
    queryFn: () => getFundClasses({ id }),
  })
}

const useAddFundClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fundClass: Partial<Class>) => addFundClass(id, fundClass),
    onSuccess: (classes) => {
      queryClient.setQueryData([KEY_FUNDS, id, 'class'], classes)
      message.success('Fund class saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditFundClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fundClass: Partial<Class>) => editFundClass(id, fundClass),
    onSuccess: (classes) => {
      queryClient.setQueryData([KEY_FUNDS, id, 'class'], classes)
      message.success('Fund class saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteFundClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (classId: string) => deleteFundClass(id, classId),
    onSuccess: (classes) => {
      queryClient.setQueryData([KEY_FUNDS, id, 'class'], classes)
      message.success('Fund class deleted')
    },
    onError: handleErrorResponse,
  })
}

export { useFundClasses, useAddFundClass, useEditFundClass, useDeleteFundClass }
