import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addOfferingClass,
  deleteOfferingClass,
  editOfferingClass,
  getOfferingClasses,
} from 'admin/services/api/offering-class'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Class } from 'types'
import { message } from 'utils/message'

const useOfferingClasses = (id: string) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id, 'class'],
    queryFn: () => getOfferingClasses({ id }),
  })
}

const useAddOfferingClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (offeringClass: Partial<Class>) =>
      addOfferingClass(id, offeringClass),
    onSuccess: (data) => {
      queryClient.setQueryData([KEY_OFFERINGS, id, 'class'], data)
      message.success('Offering class saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditOfferingClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (offeringClass: Partial<Class>) =>
      editOfferingClass(id, offeringClass),
    onSuccess: (data) => {
      queryClient.setQueryData([KEY_OFFERINGS, id, 'class'], data)
      message.success('Offering class saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOfferingClass = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (classId: string) => deleteOfferingClass(id, classId),
    onSuccess: (data) => {
      queryClient.setQueryData([KEY_OFFERINGS, id, 'class'], data)
      message.success('Offering class saved')
    },
    onError: handleErrorResponse,
  })
}

export {
  useOfferingClasses,
  useAddOfferingClass,
  useEditOfferingClass,
  useDeleteOfferingClass,
}
