import { request } from 'services/request'

const getLoanAddressRehab = async (
  loanId: string,
  addressId: string,
  versionId: string
) => {
  const { data } = await request.put(
    `/loan/${loanId}/address/${addressId}/rehab?version_id=${versionId}`
  )
  return data
}

const getLoanAddressRehabUrl = async (
  loanId: string,
  addressId: string
): Promise<string> => {
  const {
    data: { url },
  } = await request.get(`/loan/${loanId}/address/${addressId}/rehab`)
  return url
}

export { getLoanAddressRehab, getLoanAddressRehabUrl }
