import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
  QueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  addBorrower,
  deleteBorrower,
  getBorrowers,
  inviteEmail,
  linkAccount,
  unlinkAccount,
  updateLinkAccount,
} from 'admin/services/api/borrowers'
import { KEY_BORROWERS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Search, Filter, Pagination, Sort } from 'types/table'
import { message } from 'utils/message'

const fetchBorrowers = (
  queryClient: QueryClient,
  {
    search,
    filter,
    pagination,
  }: {
    search?: Search
    filter?: Filter
    pagination?: Pagination
  } = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return queryClient.fetchQuery({
    queryKey: [KEY_BORROWERS, search, nextFilter, pagination],
    queryFn: () =>
      getBorrowers({ search, filter: nextFilter, page: pagination }),
  })
}

const useBorrowers = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details,
}: {
  search?: Search
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_BORROWERS, search, nextFilter, pagination, sort, details],
    queryFn: () =>
      getBorrowers({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
  })
}

const useAddBorrower = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addBorrower,
    onSuccess: (data, variables) => {
      if (variables.sendInvitation) {
        inviteEmail({ id: data.id })
      }
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS] })
      message.success('Borrower saved')
    },
    onError: handleErrorResponse,
  })
}

const useLinkAccount = ({ borrowerId }: { borrowerId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: linkAccount,
    onSuccess: (borrower) => {
      if (borrowerId === borrower.id) {
        queryClient.setQueryData([KEY_BORROWERS, borrower.id], borrower)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrowerId] })
      message.success('Borrower saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLinkAccount = ({ borrowerId }: { borrowerId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateLinkAccount,
    onSuccess: (borrower) => {
      if (borrowerId === borrower.id) {
        queryClient.setQueryData([KEY_BORROWERS, borrower.id], borrower)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrowerId] })
      message.success('Borrower saved')
    },
    onError: handleErrorResponse,
  })
}

const useUnlinkAccount = ({ borrowerId }: { borrowerId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: unlinkAccount,
    onSuccess: (borrower) => {
      if (borrowerId === borrower.id) {
        queryClient.setQueryData([KEY_BORROWERS, borrower.id], borrower)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrowerId] })
      message.success('Borrower saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteBorrower = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteBorrower(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_BORROWERS],
          exact: true,
        })
        message.success('Borrower deleted')
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

export {
  fetchBorrowers,
  useBorrowers,
  useAddBorrower,
  useLinkAccount,
  useUpdateLinkAccount,
  useUnlinkAccount,
  useDeleteBorrower,
}
