import { request } from 'services/request'
import { Filter, Meta, Pagination, Transaction } from 'types'

const getTransactions = async (params: {
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Transaction[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.get(`/transaction`, { params })
  return { transactions, meta: pagination }
}

const getTransactionFilters = async (
  columns: string[]
): Promise<Record<string, any>> => {
  const { data } = await request.get(
    `/transaction/filters?columns=${columns.join(',')}`
  )
  return data
}

export type { Transaction }
export { getTransactions, getTransactionFilters }
