import { findIndex } from 'lodash'
import { useCallback, useState } from 'react'
import { useOfferingLoans } from 'admin/hooks/use-offering-loan'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import imagePlaceholder from 'images/offering-placeholder.png'
import { Offering, OfferingCollateral } from 'types'
import { formatUsd } from 'utils/currency'
import { TableCollateral } from './TableCollateral'

interface Props {
  offering: Offering
}

function TabCollateral({ offering }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedCollateralIndex, setSelectedCollateralIndex] =
    useState<number>(0)
  const { data: offeringLoans, isPending } = useOfferingLoans(offering.id)
  const selectedCollateral = offeringLoans?.[
    selectedCollateralIndex
  ] as OfferingCollateral
  const handlePrevCollateral = useCallback(() => {
    setSelectedCollateralIndex((index) => index - 1)
  }, [])
  const handleNextCollateral = useCallback(() => {
    setSelectedCollateralIndex((index) => index + 1)
  }, [])

  return (
    <>
      <TableCollateral
        data={offeringLoans as OfferingCollateral[]}
        loading={isPending}
        onClick={(collateral) => {
          setIsModalOpen(true)
          setSelectedCollateralIndex(
            findIndex(offeringLoans as OfferingCollateral[], collateral)
          )
        }}
      />
      {isModalOpen && offeringLoans && (
        <Modal
          className="p-4 max-w-[1127px] w-[80%] mx-0"
          onClose={() => setIsModalOpen(false)}
          closeOnClickOutside
          asChild
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Header variant="h3">{selectedCollateral.name}</Header>
            <Flex>
              <Button
                variant="ghost"
                disabled={selectedCollateralIndex === 0}
                onClick={handlePrevCollateral}
              >
                <Icon name={IconName.arrowLeftLong} />
              </Button>
              <Button
                variant="ghost"
                disabled={selectedCollateralIndex === offeringLoans.length - 1}
                onClick={handleNextCollateral}
              >
                <Icon name={IconName.arrowLeftLong} className="rotate-180" />
              </Button>
            </Flex>
          </Flex>
          <Flex flexWrap="wrap">
            <img
              className="flex-grow object-contain max-w-full md:max-w-[80%]"
              src={selectedCollateral.image?.url || imagePlaceholder}
            />
            <Flex stack gap={34} className="min-w-[180px] flex-shrink-0">
              <Flex stack gap={4}>
                <div>Lien Position</div>
                <Header variant="h3">{selectedCollateral.lienPosition}</Header>
              </Flex>
              <Flex stack gap={4}>
                <div>Principal Balance</div>
                <Header variant="h3">
                  {formatUsd(selectedCollateral.principalBalance)}
                </Header>
              </Flex>
              <Flex stack gap={4}>
                <div>Months Remaining</div>
                <Header variant="h3">
                  {selectedCollateral.monthsRemaining}
                </Header>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}

export { TabCollateral }
