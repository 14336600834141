import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { COMMAND_PRIORITY_EDITOR, INSERT_PARAGRAPH_COMMAND } from 'lexical'
import { useEffect } from 'react'

function SingleLinePlugin(): JSX.Element | null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    editor.registerCommand(
      INSERT_PARAGRAPH_COMMAND,
      () => {
        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor])

  return null
}

export { SingleLinePlugin }
