import clsx from 'clsx'
import { PropsWithChildren, ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Header as Title } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  title?: ReactNode
  onClose?: () => void
  className?: string
}

const Header = ({ title, children, onClose, className }: Props) => {
  return (
    <div className={clsx(styles.header, className)}>
      {title || onClose ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="h-12"
        >
          {title && (
            <Title variant="h2" className="flex-grow">
              {title}
            </Title>
          )}
          {onClose && (
            <Icon
              name={IconName.close}
              onClick={onClose}
              size="md"
              className={styles.close}
            />
          )}
        </Flex>
      ) : null}
      {children}
    </div>
  )
}

export { Header }
