import { uniqBy } from 'lodash'
import styles from 'admin/pages/Document/styles.module.scss'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'

interface Props {
  sharePersons?: { id: string; name: string; typeOfPerson: string }[]
}

const SharedBadge = ({ sharePersons }: Props) => {
  return (
    <Tooltip
      className={styles.tooltip}
      placement="bottom-start"
      content={
        sharePersons?.length ? (
          uniqBy(sharePersons, 'id').map((person) => (
            <Flex
              className={styles.person}
              alignItems="center"
              key={person.id + person.typeOfPerson}
              gap={8}
            >
              <Tooltip content={person.name}>
                <Avatar id={person.id} name={person.name} />
              </Tooltip>
              <Text className="font-bold">{person.name}</Text>
            </Flex>
          ))
        ) : (
          <Text className="font-bold">No one has access to this document</Text>
        )
      }
    >
      <div>
        <Badge color="green">
          <Flex alignItems="center" gap={8}>
            <Icon
              className={styles.sharedIcon}
              name={IconName.share}
              size="sm"
            />
            <div className={styles.badge}>Shared</div>
          </Flex>
        </Badge>
      </div>
    </Tooltip>
  )
}

export { SharedBadge }
