import { Formik } from 'formik'
import { getCountryByNameOrShortName } from 'node-countries'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { LenderOfRecord } from 'types'
import { createScheme, required } from 'utils/schemas'

interface Props {
  lender?: Partial<LenderOfRecord>
  onBack?: () => void
  onSave: (lender: Partial<LenderOfRecord>) => void
  onCancel: () => void
}

const Schema = createScheme({
  name: required,
  type: required,
  jurisdiction: required,
})

function FormEntityInfo({
  lender = {} as Partial<LenderOfRecord>,
  onSave,
  onCancel,
}: Props) {
  const initialValue: Partial<LenderOfRecord> = {
    name: lender?.name ?? '',
    type: lender?.type ?? '',
    jurisdiction: lender?.jurisdiction ?? '',
  }

  return (
    <Formik
      initialValues={initialValue}
      enableReinitialize={true}
      validationSchema={Schema}
      onSubmit={onSave}
    >
      <Form modal>
        <div>
          <Grid columnGap={24}>
            <Grid.Item xs={12}>
              <Field name="name" label="Name" placeholder="John" autoFocus />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="type"
                label="Type"
                portal
                options={[
                  {
                    label: 'Corporation',
                    value: 'Corporation',
                  },
                  {
                    label: 'General Partnership',
                    value: 'General Partnership',
                  },
                  {
                    label: 'Limited Liability Company',
                    value: 'Limited Liability Company',
                  },
                  {
                    label: 'Limited Partnership',
                    value: 'Limited Partnership',
                  },
                  {
                    label: 'Trust',
                    value: 'Trust',
                  },
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="jurisdiction"
                label="Jurisdiction"
                portal
                placeholder="Jurisdiction"
                options={[
                  {
                    label: 'Country',
                    options: [
                      { label: 'United States', value: 'United States' },
                      { label: 'Canada', value: 'Canada' },
                    ],
                  },
                  {
                    label: 'State/Province',
                    options: [
                      ...(
                        getCountryByNameOrShortName('United States')
                          ?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                      ...(
                        getCountryByNameOrShortName('Canada')?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                    ],
                  },
                ]}
                defaultValue={initialValue.jurisdiction || undefined}
              />
            </Grid.Item>
          </Grid>
        </div>
        <Flex justifyContent="flex-end">
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Next</Button>
        </Flex>
      </Form>
    </Formik>
  )
}

export { FormEntityInfo }
