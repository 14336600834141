import clsx from 'clsx'
import { useState } from 'react'
import { PersonType } from '../../../types/person'
import { Button } from '../../Button'
import { Icon, IconName } from '../../Icon'
import styles from './styles.module.scss'

interface Props {
  type: PersonType
  onNext: (type: PersonType) => void
  onCancel: () => void
}

function ChooseType({ type: defaultType, onCancel, onNext }: Props) {
  const [type, setType] = useState<PersonType>(defaultType)

  return (
    <div className={styles.content}>
      <div className={styles.chooseType}>
        <div
          className={clsx(styles.type, {
            [styles.selected]: type === 'individual',
          })}
          onClick={() => setType('individual')}
        >
          <Icon name={IconName.individual} size="xxl" />
          Personal
        </div>
        <div
          className={clsx(styles.type, {
            [styles.selected]: type === 'entity',
          })}
          onClick={() => setType('entity')}
        >
          <Icon name={IconName.entity} size="xxl" />
          Company
        </div>
      </div>
      <div className={styles.buttons}>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={() => onNext(type)}>Next</Button>
      </div>
    </div>
  )
}

export default ChooseType
