import { get } from 'lodash'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

type InvestmentAgreementValues = {
  agreementText: string
  isAgreement: boolean
}

interface Props {
  isAgreement: boolean
  onBack: () => void
  onCancel: () => void
  onNext: () => void
  toggleAgreement: (value: boolean) => void
}

const InvestmentAgreementForm = ({
  isAgreement,
  onBack,
  onCancel,
  onNext,
  toggleAgreement,
}: Props) => {
  const { user } = useSession()
  const offeringTermsOfAgreement = get(
    user,
    'client.settings.offeringTermsOfAgreement',
    ''
  )
  return (
    <div className={styles.agreementContainer}>
      <Grid className={styles.form} gap={16}>
        <Grid.Item xs={12}>
          <Text>Terms of Agreement</Text>
          <div
            className={styles.agreementTextWrapper}
            dangerouslySetInnerHTML={{ __html: offeringTermsOfAgreement }}
          ></div>
        </Grid.Item>
        <Grid.Item xs={12}>
          <Checkbox
            className="items-center"
            name="agreement"
            label="I agree with the Terms of Agreement."
            checked={isAgreement}
            onChange={() => toggleAgreement(!isAgreement)}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <div className={styles.buttonsWithBack}>
            <TextLink variant="invert" onClick={onBack}>
              <Icon name={IconName.arrowLeft} size="sm" />
              Previous
            </TextLink>
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isAgreement} onClick={onNext}>
                Next
              </Button>
            </div>
          </div>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export type { InvestmentAgreementValues }
export default InvestmentAgreementForm
