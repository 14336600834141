import { cloneDeep, orderBy, sortBy } from 'lodash'
import { useState } from 'react'
import { InlineEditGridItem } from 'admin/components/InlineEdit'
import {
  useUpdateLoanAddress,
  useUpdateLoanAddressField,
} from 'admin/hooks/use-loan-address'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { LoanFields } from 'components/Modal/LoanFields'
import { Panel } from 'components/Panel/index'
import { Summary } from 'components/Summary'
import { Address } from 'types/address'
import { getFieldsBySection, mergeFields, formatField } from 'utils/fields'
import styles from './PanelScopeOfWorkStructuralChanges.module.scss'

interface Props {
  loanId: string
  address: Address
  className?: string
  readonly?: boolean
}

function PanelScopeOfWorkStructuralChanges({
  loanId,
  address,
  className,
  readonly = false,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { mutate: updateLoanAddress, isPending: saving } = useUpdateLoanAddress(
    {
      id: loanId,
      onSuccess: () => {
        setIsModalVisible(false)
      },
    }
  )

  const { mutateAsync: updateField } = useUpdateLoanAddressField({
    id: loanId,
    addressId: address.id,
  })

  const rehabFields = getFieldsBySection(address.fields, 'Rehabbed Structure')
  const currentStructureFields = [
    ...getFieldsBySection(address.fields, 'Features'),
  ]
  const editableRehabFields = orderBy(
    cloneDeep(rehabFields.filter(({ viewOnly }) => !viewOnly)),
    ['order'],
    ['asc']
  )
  const editableStructuralFields = sortBy(
    cloneDeep(
      currentStructureFields.filter(
        ({ viewOnly, name: structureName }) =>
          !viewOnly &&
          editableRehabFields.find(({ name }) => name === structureName)
      )
    ),
    (field) => {
      const i = editableRehabFields.findIndex(({ name }) => name === field.name)
      return i === -1 ? 999 : i
    }
  )
  const isVisible = !!(editableStructuralFields.length || rehabFields.length)

  return (
    isVisible && (
      <Panel
        title="Structural Changes"
        onEdit={
          !readonly && editableRehabFields.length
            ? () => setIsModalVisible(true)
            : undefined
        }
        className={className}
        alwaysShowEdit
      >
        <div className={styles.structures}>
          <Grid className={styles.structure}>
            <Grid.Item sm={12}>
              <Header variant="h4">Current Structure</Header>
            </Grid.Item>
            {editableStructuralFields.map(
              (field) =>
                field &&
                (readonly ? (
                  <Grid.Item key={field.id} sm={12}>
                    <Summary name={field.name}>{formatField(field)}</Summary>
                  </Grid.Item>
                ) : (
                  <InlineEditGridItem
                    size={12}
                    key={field.id}
                    field={field}
                    save={(value) =>
                      updateField({
                        ...field,
                        property: {
                          ...field.property,
                          value: [value],
                        },
                      })
                    }
                  />
                ))
            )}
          </Grid>
          <div className={styles.border}></div>
          <Grid className={styles.structure}>
            <Grid.Item sm={12}>
              <Header variant="h4">Rehabbed Structure</Header>
            </Grid.Item>
            {rehabFields.map((field) =>
              readonly ? (
                <Grid.Item key={field.id} sm={12}>
                  <Summary name={field.name}>{formatField(field)}</Summary>
                </Grid.Item>
              ) : (
                <InlineEditGridItem
                  size={12}
                  key={field.id}
                  field={field}
                  save={(value) =>
                    updateField({
                      ...field,
                      property: {
                        ...field.property,
                        value: [value],
                      },
                    })
                  }
                />
              )
            )}
          </Grid>
        </div>
        {isModalVisible && (
          <LoanFields
            title="Rehabbed Structure"
            fields={editableRehabFields}
            saving={saving}
            onSave={(updatedFields) =>
              updateLoanAddress({
                id: address.id,
                fields: mergeFields(address.fields, updatedFields),
              })
            }
            onCancel={() => setIsModalVisible(false)}
          />
        )}
      </Panel>
    )
  )
}

export { PanelScopeOfWorkStructuralChanges }
