import { isNil } from 'lodash'
import mime from 'mime'

const friendlyMime = (mimeType: string | null | undefined) => {
  return mimeType ? (mime.getExtension(mimeType) || mimeType).toUpperCase() : ''
}

function friendlySize(size: number | null | undefined) {
  if (!isNil(size)) {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return `${Number((size / Math.pow(1024, i)).toFixed(2))} ${
      ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }`
  }
  return ''
}

interface OpenBrowseFileProps {
  onChoose: (files: FileList) => void
  accept?: string
}
function openBrowseFile({
  onChoose,
  accept = '.jpg, .jpeg, .png, .tif, .heic, .pdf, .xlsx, .docx, .pptx, .xls, .msg',
}: OpenBrowseFileProps) {
  const input = document.createElement('input')
  input.type = 'file'
  input.accept = accept
  input.onchange = (e) => {
    onChoose((e.target as HTMLInputElement).files as FileList)
  }
  input.click()
}

function getFileType(mimeType?: string) {
  if (mimeType === 'application/pdf') {
    return 'pdf'
  }
  if (mimeType && mimeType.split('/')[0] === 'image') {
    return 'image'
  }
  if (
    mimeType ===
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    return 'docx'
  }
  return undefined
}

function isPrintable(mimeType?: string) {
  return ['docx', 'pdf', 'image'].includes(getFileType(mimeType) as string)
}

export { getFileType, isPrintable, friendlyMime, friendlySize, openBrowseFile }
