import { useNavigate } from 'react-router-dom'
import { TablePaidOffLoans } from 'borrower/components/TablePaidOffLoans'
import { pathTo } from 'borrower/path-to'
import { useLoans } from 'hooks/use-loans'

export const PaidOffTab = () => {
  const navigate = useNavigate()
  const { data, isPending } = useLoans({
    filter: { status: ['liquidated'] },
  })
  return (
    <TablePaidOffLoans
      data={data?.loans}
      loading={isPending}
      onClick={(loan) => loan?.id && navigate(pathTo('loan', loan.id))}
    />
  )
}
