import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useSession } from 'hooks/use-session'

interface Props {
  pathTo: any
}

const SignOut = ({ pathTo }: Props) => {
  const { isSignedOut, signOut } = useSession()

  useEffect(() => {
    signOut()
  }, [])

  return isSignedOut ? <Navigate replace to={pathTo('signIn')} /> : null
}

export { SignOut }
