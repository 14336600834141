import { ColumnDef } from '@tanstack/react-table'
import { get, isNil } from 'lodash'
import { useMemo, useState } from 'react'
import { ModalMarkAsSold } from 'admin/pages/Loan/ModalMarkAsSold'
import { Borrower } from 'admin/services/api/borrowers'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { useUpdateLoan } from '../../../hooks/use-loans'

type Props = {
  data?: Loan[]
  loading: boolean
  onClick: (item?: Loan) => void
}

function SoldLoansReportTable({ data = [], loading, onClick }: Props) {
  const [editId, setEditId] = useState<string>()
  const { mutate: update, isPending: updating } = useUpdateLoan({ id: editId })

  const columns: ColumnDef<Loan>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: '',
        id: 'note',
        size: 32,
        cell: ({ row }) => {
          return row.original.meta?.sold?.note ? (
            <Tooltip content={row.original.meta?.sold?.note}>
              <Icon name={IconName.note} className="text-grey-600" />
            </Tooltip>
          ) : null
        },
      },
      {
        header: 'Borrower',
        accessorKey: 'borrowers',
        cell: ({ row }) =>
          get(row.original, 'borrowers', [])
            .map((borrower: Borrower) => borrower.name)
            .join(', '),
      },
      {
        header: 'Sold To',
        accessorKey: 'meta.sold.toName',
      },
      {
        header: 'Closing Date',
        accessorKey: 'dateClosing',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Sold On',
        accessorKey: 'meta.sold.date',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Realized Gain/Losses',
        accessorKey: 'meta.sold.amount',
        cell: ({ getValue }) => {
          const amount = getValue() as number | string | null
          return isNil(amount) || amount === '' ? '-' : formatUsd(amount)
        },
      },
      {
        header: '',
        accessorKey: 'id',
        size: 42,
        cell: ({ getValue }) => {
          const id = getValue() as string
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => setEditId(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <Table
        columns={columns}
        data={data}
        loading={loading}
        onClick={onClick}
      />
      {editId && (
        <ModalMarkAsSold
          meta={data.find((loan) => loan.id === editId)?.meta}
          saving={updating}
          onSave={(values) =>
            update(
              { meta: { ...values } },
              { onSuccess: () => setEditId(undefined) }
            )
          }
          onCancel={() => setEditId(undefined)}
        />
      )}
    </>
  )
}

export default SoldLoansReportTable
