import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { CustomReport, Sort } from 'types'
import { friendlyDate } from 'utils/date'

interface Props {
  data?: CustomReport[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
  onClick: (report?: CustomReport) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

function TableReports({
  data = [],
  sort,
  onSort,
  loading,
  onEdit,
  onDelete,
  onClick,
}: Props) {
  const columns: ColumnDef<CustomReport>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Description',
        accessorKey: 'description',
        cell: (cell) => {
          const description = (cell.getValue() as string) || ''
          return description.length > 250
            ? `${description.slice(0, 250)}…`
            : description
        },
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        size: 130,
        cell: (cell) => {
          const date = (cell.getValue() as string) || ''
          return friendlyDate(date)
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: '',
        accessorKey: 'id',
        size: 42,
        cell: ({ getValue }) => {
          const id = getValue() as string
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )
  return (
    <Table
      loading={loading}
      columns={columns}
      data={data}
      sort={sort}
      onSort={onSort}
      onClick={onClick}
    />
  )
}

export { TableReports }
