import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getOfferingFundingSources,
  addOfferingInvestor,
  editOfferingInvestor,
  deleteOfferingInvestor,
} from 'admin/services/api/offering-investor'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LoanFundingSource } from 'types'
import { message } from 'utils/message'

const useOfferingFundingSources = (id: string) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id, 'fundings'],
    queryFn: () => getOfferingFundingSources(id),
  })
}

const useAddOfferingInvestor = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (risk: Partial<LoanFundingSource>) =>
      addOfferingInvestor(id, risk),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering funding saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditOfferingInvestor = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (risk: Partial<LoanFundingSource>) =>
      editOfferingInvestor(id, risk),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering funding saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOfferingInvestor = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fundingId: string) => deleteOfferingInvestor(id, fundingId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering funding deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useOfferingFundingSources,
  useAddOfferingInvestor,
  useEditOfferingInvestor,
  useDeleteOfferingInvestor,
}
