import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useFund, useUpdateFund } from 'admin/hooks/use-funds'
import { useReport } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { PageTopFields } from 'components/PageTopFields'
import { Tabs } from 'components/Tabs'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { TabDistributions } from './TabDistributions'
import { TabDocuments } from './TabDocuments'
import { TabFunding } from './TabFunding'
import { TabGeneral } from './TabGeneral'
import { TabPortfolio } from './TabPortfolio'
import { TabSettings } from './TabSettings'
import { TabTransactions } from './TabTransactions'
import styles from './styles.module.scss'

function Fund() {
  const { id, tab } = useParams() as { id: string; tab: string }
  const { data: fund } = useFund(id)
  const { mutateAsync: updateFund } = useUpdateFund(id)
  const navigate = useNavigate()

  const { data: cashBalances } = useReport(
    `investor/${id}/cash-balances`
  ) as unknown as {
    data: { cash: string; availableCash: string }
  }

  return (
    <MainContent>
      {fund ? (
        <Flex stack gap={16}>
          <PageTop
            title={fund.fund.name}
            breadcrumbs={{ title: 'Funds', link: pathTo('funds') }}
            onEdit={(name: string) => updateFund({ name })}
          />
          <PageTopFields
            fields={[
              {
                key: 'Balance',
                value: formatUsd(
                  fund.fund.fundBalance || cashBalances?.cash || 0
                ),
              },
              {
                key: 'Available Balance',
                value: formatUsd(cashBalances?.availableCash || 0),
              },
              {
                key: 'Last Distribution',
                value: formatDate(fund.fund.lastDistributionDate) || '-',
              },
            ]}
          />
          <Tabs
            defaultActiveId={tab || 'general'}
            onTabSelect={(tabId) => navigate(pathTo('fund', id, tabId))}
            className={styles.tabs}
          >
            <Tabs.Pane tab="General" id="general">
              <TabGeneral fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Portfolio" id="portfolio">
              <TabPortfolio fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Funding" id="funding">
              <TabFunding fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Distributions" id="distributions">
              <TabDistributions fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Transactions" id="transactions">
              <TabTransactions fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Documents" id="documents">
              <TabDocuments fund={fund.fund} />
            </Tabs.Pane>
            <Tabs.Pane tab="Settings" id="settings">
              <TabSettings fund={fund.fund} />
            </Tabs.Pane>
          </Tabs>
        </Flex>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Fund }
