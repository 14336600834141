import { last } from 'lodash'
import { useEffect, useMemo } from 'react'

const useModal = ({
  onClose,
  overlay,
  closeOnClickOutside,
}: {
  onClose?: () => void
  overlay?: React.RefObject<HTMLInputElement>
  closeOnClickOutside?: boolean
}) => {
  const scrollY = useMemo(() => window.scrollY, [])

  useEffect(() => {
    const overlays = document.querySelectorAll('[data-overlay]')
    const overlayEl = overlay?.current
    const keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        const currentOverlay = last([...overlays])

        if (currentOverlay && currentOverlay === overlayEl) {
          onClose?.()
        }
      }
    }
    const clickHandler = (e: MouseEvent) =>
      e.target === overlayEl && closeOnClickOutside && onClose?.()

    if (overlayEl) {
      const rootEl = document.getElementById('root') as HTMLDivElement
      rootEl.style.top = `-${scrollY}px`
      rootEl.style.position = 'fixed'
      overlayEl.addEventListener('click', clickHandler)
      window.addEventListener('keyup', keyHandler)
    }

    return () => {
      const overlays = document.querySelectorAll('[data-overlay]')
      if (overlays.length === 0) {
        const rootEl = document.getElementById('root') as HTMLDivElement
        if (rootEl) {
          rootEl.style.position = ''
          rootEl.style.top = ''
        }
        window.scrollTo(0, scrollY)
      }
      window.removeEventListener('keyup', keyHandler)
      if (overlayEl) {
        overlayEl.removeEventListener('click', clickHandler)
      }
    }
  }, [onClose])
}

export { useModal }
