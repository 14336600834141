import { ColumnDef } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { Table } from 'components/Table'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

type Props = {
  data?: Loan[]
  loading: boolean
  onClick: (loan?: Loan) => void
}

export const TablePaidOffLoans = ({ data = [], loading, onClick }: Props) => {
  const columns: ColumnDef<Loan>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Closing Date',
        accessorKey: 'dateClosing',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Paid Off Date',
        accessorKey: 'datePaidoff',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Original Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      minWidth={500}
    />
  )
}
