import { toNumber } from 'lodash'
import { useMemo, useState, useEffect } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { Panel } from 'components/Panel'
import { Select } from 'components/Select'
import { Summary } from 'components/Summary'
import { LoanModification } from 'types'
import { formatUsd } from 'utils/currency'
import { getField } from 'utils/fields'
import { sumDecimal } from 'utils/math'

interface Props {
  modification: LoanModification
  onChange: (modification: LoanModification) => void
}

const PaymentDetails = ({ modification, onChange }: Props) => {
  const toTrustField = useMemo(
    () => getField(modification.fields, 'to-trust', true),
    [modification.fields]
  )

  const principalInterestField = useMemo(
    () => getField(modification.fields, 'principal-and-interest'),
    [modification.fields]
  )

  const [type, setType] = useState<'Calculated' | 'Fixed'>('Calculated')
  const [amount, setAmount] = useState<number>(
    modification.regularPayment.amount
  )
  const [toTrust, setToTrust] = useState<number>(
    toTrustField?.property?.value[0] as number
  )
  const regularPayment = useMemo(
    () => sumDecimal([amount, toTrust]),
    [amount, toTrust]
  )
  const frequency = useMemo(
    () =>
      modification.regularPayment.showPerDiem
        ? `${formatUsd(modification.regularPayment.perDiem)} per diem X ${modification.regularPayment.numDays} days`
        : modification.regularPayment.frequency,
    [modification.regularPayment.frequency]
  )

  useEffect(() => {
    if (type === 'Calculated') {
      setAmount(modification.regularPayment.amount)
    }
  }, [type])

  useEffect(() => {
    if (principalInterestField && !principalInterestField.property.formula) {
      setType('Fixed')

      if (principalInterestField.property.value) {
        setAmount((principalInterestField.property.value[0] || 0) as number)
      }
    }
  }, [])

  useEffect(() => {
    onChange({
      ...modification,
      fields: modification.fields.map((field) => {
        if (field.id === 'to-trust') {
          return {
            ...field,
            property: { ...field.property, value: [toTrust] },
          }
        }
        if (field.id === 'principal-and-interest') {
          return {
            ...field,
            property: {
              ...field.property,
              value: type === 'Calculated' ? [null] : [amount],
            },
          }
        }
        return field
      }),
    })
  }, [type, amount, toTrust])

  return (
    <Flex stack gap={32}>
      <Panel className="pt-4 p-0">
        <Flex stack gap={20}>
          <Flex alignItems="center" gap={20} className="px-5">
            <Summary name="Principal & Interest" className="flex-grow">
              <Select
                value={type}
                options={[
                  { label: 'Calculated', value: 'Calculated' },
                  { label: 'Fixed', value: 'Fixed' },
                ]}
                onChange={({ value }) =>
                  setType(value as 'Calculated' | 'Fixed')
                }
              />
            </Summary>
            <Icon
              name={IconName.arrowRightLong}
              size="md"
              className="text-grey-600 flex-grow-0 mt-5"
            />
            <Summary name={`Amount (${frequency})`} className="flex-grow">
              <IconInput
                type="currency"
                value={amount}
                disabled={type === 'Calculated'}
                onChange={(e) => setAmount(toNumber(e.target.value))}
              />
            </Summary>
          </Flex>
          {toTrustField && (
            <Summary name="To Trust" className="px-5">
              <IconInput
                type="currency"
                value={toTrust}
                onChange={(e) => setToTrust(toNumber(e.target.value))}
              />
              <div className="text-grey-700 mt-1.5">
                How much of the payment will go into the trust account
              </div>
            </Summary>
          )}
          <Flex
            justifyContent="space-between"
            className="py-4 px-5 rounded rounded-t-none bg-grey-75 font-bold"
          >
            <div>Updated Regular Payment Amount</div>
            <div>{formatUsd(regularPayment)}</div>
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  )
}

export { PaymentDetails }
