import { request } from 'services/request'
import { Class } from 'types'
import { formatPercent, parsePercent } from 'utils/math'

const updateClassResponse = (classItem: Class): Class => ({
  ...classItem,
  rate: formatPercent(classItem.rate) as number,
  tiers: classItem.tiers?.map((tier) => ({
    ...tier,
    percentage: formatPercent(tier.percentage) as number,
  })),
})

const updateClassRequest = (classItem: Partial<Class>): Partial<Class> => ({
  ...classItem,
  rate: parsePercent(classItem.rate) as number,
  tiers: classItem.tiers?.map((tier) => ({
    ...tier,
    percentage: parsePercent(tier.percentage) as number,
  })),
})

const getFundClasses = async ({ id }: { id: string }): Promise<Class[]> => {
  const {
    data: { classes },
  } = await request.get(`/fund/${id}/class`)
  return classes.map(updateClassResponse)
}

const addFundClass = async (
  id: string,
  payload: Partial<Class>
): Promise<Class[]> => {
  const {
    data: { classes },
  } = await request.post(`/fund/${id}/class`, updateClassRequest(payload))
  return classes.map(updateClassResponse)
}

const editFundClass = async (
  fundId: string,
  { id, ...payload }: Partial<Class>
): Promise<Class[]> => {
  const {
    data: { classes },
  } = await request.patch(
    `/fund/${fundId}/class/${id}`,
    updateClassRequest(payload)
  )
  return classes.map(updateClassResponse)
}

const deleteFundClass = async (
  fundId: string,
  classId: string
): Promise<Class[]> => {
  const {
    data: { classes },
  } = await request.delete(`/fund/${fundId}/class/${classId}`)
  return classes.map(updateClassResponse)
}

export { getFundClasses, addFundClass, editFundClass, deleteFundClass }
