import { request } from 'services/request'
import {
  Quote,
  QuoteOption,
  ExtendedQuoteOption,
  QuoteOptionEditableProps,
} from 'types'
import {
  updateResponseOption,
  updateRequestOption,
  updateResponseQuote,
} from 'utils/api/quote-parser'

const getQuoteOptions = async (quoteId: string): Promise<QuoteOption[]> => {
  const {
    data: { options },
  } = await request.get(`quote/${quoteId}/option`)
  return options.map((option) => updateResponseOption(option))
}

const getQuoteOption = async (
  quoteId: string,
  optionId: string
): Promise<ExtendedQuoteOption> => {
  const {
    data: { option },
  } = await request.get(`quote/${quoteId}/option/${optionId}`)
  return option
}

const generateQuoteOptions = async (
  quoteId: string
): Promise<QuoteOption[]> => {
  const {
    data: { options },
  } = await request.put(`quote/${quoteId}/option`)
  return options.map((option) => updateResponseOption(option))
}

const updateQuoteOption = async (
  quoteId: string,
  { optionId, ...data }: QuoteOptionEditableProps
): Promise<{ quote?: Quote; options: QuoteOption[] }> => {
  const {
    data: { quote, options },
  } = await request.patch(
    `/quote/${quoteId}/option${optionId ? `/${optionId}` : ''}`,
    updateRequestOption(data)
  )
  return {
    quote: quote ? updateResponseQuote(quote) : quote,
    options: options.map((option) => updateResponseOption(option)),
  }
}

export {
  getQuoteOptions,
  getQuoteOption,
  generateQuoteOptions,
  updateQuoteOption,
}
