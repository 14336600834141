import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuoteConvert } from 'admin/hooks/use-quotes'
import { pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Input } from 'components/Input'
import { Modal } from 'components/Modal'
import { Select } from 'components/Select'
import { Summary } from 'components/Summary'
import { Tabs } from 'components/Tabs'
import { useLoans } from 'hooks/use-loans'
import { Quote, QuoteConvertToLoanProps } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
  onCancel: () => void
}

function ModalConvertQuoteToLoan({ quote, onCancel }: Props) {
  const navigate = useNavigate()
  const [tab, setTab] = useState(quote.loanId ? 'existing' : 'new')
  const [name, setName] = useState(quote.name)
  const [selectedLoan, setSelectedLoan] = useState<string>(
    quote.loanId as string
  )
  const [selectedOption, setSelectedOption] = useState(quote.options[0].id)
  const [error, setError] = useState<string>()
  const { data, isFetching } = useLoans({
    filter: {
      status: ['servicing', 'lead', 'processing', 'underwriting'],
    },
    sort: ['name', 'ASC'],
  })
  const { mutate: convert, isPending: converting } = useQuoteConvert(quote.id)
  const handleConvert = useCallback(() => {
    if ((tab === 'new' && !name) || (tab === 'existing' && !selectedLoan)) {
      setError('The loan is required')
    } else {
      const data: QuoteConvertToLoanProps = { optionId: selectedOption }
      if (tab === 'new') {
        data.name = name
      } else if (tab === 'existing') {
        data.loanId = selectedLoan
      }
      convert(data, {
        onSuccess: (loan) => {
          navigate(pathToLoan(loan))
        },
      })
    }
  }, [convert, tab, selectedOption, selectedLoan, name])
  useEffect(() => {
    setError('')
  }, [tab])

  return (
    <Modal
      title="Convert to Loan"
      onClose={onCancel}
      className={styles.modal}
      loading={isFetching}
    >
      <Flex stack gap={32}>
        <Tabs defaultActiveId={tab} onTabSelect={setTab}>
          <Tabs.Pane tab="Create New Loan" id="new">
            <Flex stack gap={8}>
              <div>Loan name</div>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
              <div className={styles.error}>{error}</div>
            </Flex>
          </Tabs.Pane>
          <Tabs.Pane tab="Add to Existing Loan" id="existing">
            <Flex stack gap={8}>
              <div>Select existing loan</div>
              <Select
                value={selectedLoan}
                options={
                  data?.loans.map(({ id, name }) => ({
                    value: id,
                    label: name || id,
                  })) || []
                }
                portal
                onChange={(option) => setSelectedLoan(option.value as string)}
              />
              <div className={styles.error}>{error}</div>
            </Flex>
          </Tabs.Pane>
        </Tabs>
        <Flex stack gap={16}>
          <Header variant="h4">Add product to loan</Header>
          {quote.options.map((option) => (
            <Flex
              key={option.id}
              justifyContent="space-between"
              className={clsx(
                styles.option,
                selectedOption === option.id && styles.selected
              )}
              onClick={() => setSelectedOption(option.id)}
            >
              <Flex
                gap={8}
                alignItems="center"
                className="flex-shrink-1 min-w-0"
              >
                <Header variant="h4" className={styles.clientName}>
                  {option.product.client.name}
                </Header>
                <div className={styles.divider}>|</div>
                <div className={styles.optionProduct}>
                  {option.product.name}
                </div>
              </Flex>
              <Flex
                gap={8}
                alignItems="center"
                className="min-w-0 flex-shrink-0"
              >
                <Summary name="Loan Amount" className={styles.optionSummary}>
                  <div className={styles.optionValue}>
                    {formatUsd(option.amount)}
                  </div>
                </Summary>
                <Summary name="Interest Rate" className={styles.optionSummary}>
                  <div className={styles.optionValue}>
                    {formatPercent(option.rate)}
                  </div>
                </Summary>
                <Summary name="Term" className={styles.optionSummary}>
                  <div className={styles.optionValue}>
                    {option.term ? `${option.term} mo.` : '-'}
                  </div>
                </Summary>
              </Flex>
            </Flex>
          ))}
        </Flex>
        <Flex justifyContent="flex-end">
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="button" loading={converting} onClick={handleConvert}>
            {tab === 'new' ? 'Create' : 'Overwrite'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default ModalConvertQuoteToLoan
