import {
  autoUpdate,
  flip,
  FloatingPortal,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { compact, isEqual, sortBy, take } from 'lodash'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Avatar } from 'components/Avatar'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import theme from 'styles/theme'
import { Tooltip } from '../Tooltip'
import styles from './styles.module.scss'

interface Props {
  userOptions: { id: string; name: string; email: string; isRole?: boolean }[]
  roleOptions?: { id: string; name: string; email?: string; isRole: true }[]
  loading?: boolean
  variant?: 'default' | 'small'
  readOnly?: boolean
  selectedUsers?: string[]
  selectedRoles?: string[]
  onSelect?: (id: string) => void
  onClear?: () => void
  onChange?: (ids: string[]) => void
  onRoleChange?: (ids: string[]) => void
  onOpen?: (isOpen: boolean) => void
  selectOwnersText?: string
}

const VISIBLE_COUNT = 3

const OwnerSelector = memo(
  ({
    userOptions,
    roleOptions = [],
    selectedUsers,
    selectedRoles,
    loading,
    variant = 'default',
    readOnly,
    onSelect,
    onClear,
    onChange,
    onRoleChange,
    onOpen,
    selectOwnersText = 'Select one or more account owners',
  }: Props) => {
    const [search, setSearch] = useState<string>()
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState([
      ...(selectedUsers || []),
      ...(selectedRoles || []),
    ])
    const sortedUserOptions = useMemo(() => {
      return sortBy(userOptions, [(admin) => admin.name?.toLowerCase()])
        .reverse()
        .sort((a, b) => {
          if (selected.includes(a.id)) {
            return 1
          }
          if (selected.includes(b.id)) {
            return -1
          }
          return 0
        })
        .reverse()
    }, [!open && selected, !open && userOptions])
    const visibleOptions = useMemo(() => {
      return [...roleOptions, ...sortedUserOptions].filter((option) => {
        return (
          option.name?.toLowerCase().includes(search?.toLowerCase() || '') ||
          option.email?.toLowerCase()?.includes(search?.toLowerCase() || '')
        )
      })
    }, [roleOptions, sortedUserOptions, search])
    const selectedUserOptions = useMemo(() => {
      return sortedUserOptions.filter(({ id }) => selected.includes(id))
    }, [sortedUserOptions, selected])
    const selectedRoleOptions = useMemo(() => {
      return sortBy(
        (selectedRoles || []).map((id) => ({
          id: id,
          name: roleOptions.find((role) => role.id === id)?.name || id,
          isRole: true,
        })),
        [(role) => role.name?.toLowerCase()]
      )
    }, [selectedRoles, roleOptions])
    const visibleSelectedOptions = useMemo(
      () =>
        take(
          compact([selectedRoleOptions[0], ...selectedUserOptions]),
          VISIBLE_COUNT
        ).reverse(),
      [!open && selectedRoleOptions, !open && selectedUserOptions]
    )
    const otherSelectedCount = useMemo(() => {
      const hasRoles = selectedRoleOptions.length > 0
      const otherRolesCount =
        selectedRoleOptions.length - 1 < 0 ? 0 : selectedRoleOptions.length - 1
      const otherUsersCount =
        selectedUserOptions.length -
          (hasRoles ? VISIBLE_COUNT - 1 : VISIBLE_COUNT) <
        0
          ? 0
          : selectedUserOptions.length -
            (hasRoles ? VISIBLE_COUNT - 1 : VISIBLE_COUNT)
      return otherRolesCount + otherUsersCount
    }, [!open && selectedRoleOptions, !open && selectedUserOptions])
    const handleSelect = useCallback(
      (id: string) => {
        setSelected((prev) => {
          if (prev.includes(id)) {
            return prev.filter((p) => p !== id)
          }
          return [...prev, id]
        })
        onSelect?.(id)
      },
      [onSelect]
    )
    const handleClear = useCallback(() => {
      setSelected([])
      onClear?.()
    }, [onClear])
    const handleChange = useCallback(() => {
      const roleIds = roleOptions.map(({ id }) => id)
      const selectedOwners = selected.filter((id) => !roleIds.includes(id))
      const selectedOtherOwners = selected.filter((id) => roleIds.includes(id))
      if (!isEqual(selectedOwners, selectedUsers)) {
        onChange?.(selectedOwners)
      }
      if (!isEqual(selectedOtherOwners, selectedRoles || [])) {
        onRoleChange?.(selectedOtherOwners)
      }
    }, [
      onChange,
      onRoleChange,
      selected,
      selectedUsers,
      selectedRoles,
      roleOptions,
    ])

    const handleOpenChange = useCallback(
      (nextOpen: boolean) => {
        setOpen(nextOpen)
        if (!nextOpen) {
          handleChange()
        }
      },
      [handleChange]
    )

    useEffect(() => {
      setSelected([...(selectedUsers || []), ...(selectedRoles || [])])
    }, [!open && selectedUsers, !open && selectedRoles])

    useEffect(() => {
      onOpen?.(open)
      setSearch('')
    }, [open, onOpen])

    const { x, y, strategy, context, refs } = useFloating({
      open,
      onOpenChange: handleOpenChange,
      whileElementsMounted: autoUpdate,
      placement: 'bottom-end',
      middleware: [
        flip(),
        size({
          apply({ elements, availableHeight }) {
            Object.assign(elements.floating.style, {
              maxHeight: `${availableHeight}px`,
            })
          },
        }),
      ],
    })

    const { getReferenceProps, getFloatingProps, getItemProps } =
      useInteractions([
        useClick(context, { event: 'click' }),
        useDismiss(context),
        useRole(context, { role: 'tree' }),
      ])

    return (
      <>
        {visibleSelectedOptions.length > 0 && !loading && (
          <Flex
            gap={0}
            className={clsx(
              styles.avatars,
              open && styles.open,
              '!inline-flex p-0.5 rounded hover:bg-grey-100'
            )}
            {...getReferenceProps({
              ref: refs.setReference,
              onClick(e) {
                e.stopPropagation()
              },
            })}
          >
            <div className="flex flex-row-reverse">
              {!readOnly && (
                <div className={clsx(styles.handlerWrapper)}>
                  <Tooltip
                    className="!z-10"
                    content={open ? '' : 'Select Account Owners'}
                  >
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      className={clsx(
                        styles.avatar,
                        styles.arrowDown,
                        'text-grey-600 rounded-full',
                        variant === 'small' && 'w-[22px] h-[22px]'
                      )}
                    >
                      <Icon name={IconName.arrowDown} />
                    </Flex>
                  </Tooltip>
                </div>
              )}

              {otherSelectedCount > 0 && (
                <div className={clsx(styles.handlerWrapper)}>
                  <Avatar
                    name={`+${otherSelectedCount}`}
                    exactName
                    color={theme.color.grey600}
                    background={theme.color.grey50}
                    className={clsx(
                      styles.avatar,
                      variant === 'small' && 'w-[22px] h-[22px]'
                    )}
                  />
                </div>
              )}

              {visibleSelectedOptions.map(({ id, name, isRole }) => (
                <div key={id} className={clsx(styles.avatarWrapper)}>
                  <Tooltip
                    className="!z-10"
                    content={open || isRole ? '' : name}
                  >
                    {isRole ? (
                      <div
                        className={clsx(
                          styles.avatar,
                          'bg-grey-75 rounded-full px-2 font-bold text-sm w-full max-w-[130px] truncate',
                          variant === 'small' && 'h-[22px] leading-[22px]'
                        )}
                      >
                        {name}
                      </div>
                    ) : (
                      <Avatar
                        id={id}
                        name={name}
                        className={clsx(
                          styles.avatar,
                          variant === 'small' && 'w-[22px] h-[22px]'
                        )}
                      />
                    )}
                  </Tooltip>
                </div>
              ))}
            </div>
          </Flex>
        )}
        {visibleSelectedOptions.length === 0 && readOnly && !loading && (
          <div
            className={variant === 'small' ? 'w-[30px] h-[30px]' : 'w-8 h-8'}
          />
        )}
        {visibleSelectedOptions.length === 0 && !readOnly && !loading && (
          <div
            className={variant === 'small' ? 'w-[30px] h-[30px]' : 'w-8 h-8'}
            {...getReferenceProps({
              ref: refs.setReference,
              onClick(e) {
                e.stopPropagation()
              },
            })}
          >
            <Tooltip
              className="!z-10"
              content={open ? '' : 'Add an account owner'}
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                className={clsx(
                  'rounded cursor-pointer text-grey-700 hover:bg-grey-100',
                  open && 'bg-grey-100',
                  variant === 'small' ? 'w-7 h-7' : 'w-8 h-8'
                )}
              >
                <Icon name={IconName.addPerson} />
              </Flex>
            </Tooltip>
          </div>
        )}

        {open && !readOnly && (
          <FloatingPortal>
            <div
              className="bg-white-100 shadow-300 rounded w-100 pt-1 overflow-hidden"
              onClick={(e) => e.stopPropagation()}
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                  zIndex: 5,
                },
              })}
            >
              <div className="pb-0.5">
                <Search
                  className="!border-0 !w-full"
                  placeholder="Search for a team member"
                  search={search}
                  onSearch={setSearch}
                />
              </div>
              <Flex
                justifyContent="space-between"
                className="pt-3 pb-2 px-2.5 border-0 border-t border-solid border-grey-200 text-sm"
              >
                <div className="text-grey-600 font-bold">
                  {selectOwnersText}
                </div>
                {selected.length > 0 && (
                  <TextLink onClick={handleClear}>Clear all</TextLink>
                )}
              </Flex>
              <div className="px-1 max-h-85 overflow-auto">
                {visibleOptions.length === 0 && (
                  <Flex
                    stack
                    alignItems="center"
                    justifyContent="center"
                    className="pt-12 pb-16"
                  >
                    <Icon
                      name={IconName.magnifyingGlass}
                      className="text-grey-500 w-7 h-7"
                    />
                    <Text variant="l">No search results</Text>
                  </Flex>
                )}
                {visibleOptions.map((option, index) => (
                  <Flex
                    key={option.id}
                    gap={10}
                    alignItems="center"
                    className={clsx(
                      'p-2 pr-4 cursor-pointer hover:bg-grey-75 hover:rounded',
                      visibleOptions[index].isRole &&
                        !visibleOptions[index + 1]?.isRole &&
                        'border-0 border-b border-solid border-grey-100'
                    )}
                    {...getItemProps({
                      onClick: () => handleSelect(option.id),
                    })}
                  >
                    <Checkbox
                      checked={selected.includes(option.id)}
                      onChange={() => {}}
                    />
                    {option.isRole ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        className="bg-grey-100 w-7 h-7 rounded-full"
                      >
                        <Icon name={IconName.person} />
                      </Flex>
                    ) : (
                      <Avatar
                        id={option.id}
                        name={option.name}
                        className="!w-7 !h-7 !basis-7"
                      />
                    )}
                    <Flex stack gap={6} className="py-[2px]">
                      <div>{option.name}</div>
                      {!!option.email && (
                        <div className="text-sm text-grey-700">
                          {option.email}
                        </div>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </div>
            </div>
          </FloatingPortal>
        )}
      </>
    )
  }
)

OwnerSelector.displayName = 'OwnerSelector'

export { OwnerSelector }
