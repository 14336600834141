import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Loan } from 'types'
import { PanelLoanInfo } from './PanelLoanInfo'
import { PanelPayment } from './PanelPayment'
import { PanelTrust } from './PanelTrust'

interface Props {
  loan: Loan
}

export const TabGeneral = ({ loan }: Props) => {
  return (
    <Grid gap={24}>
      <Grid.Item sm={6}>
        <PanelLoanInfo loan={loan} />
      </Grid.Item>
      <Grid.Item sm={6}>
        <Flex stack gap={24}>
          <PanelPayment loan={loan} />
          <PanelTrust loan={loan} />
        </Flex>
      </Grid.Item>
    </Grid>
  )
}
