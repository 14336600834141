import { sortBy, toNumber } from 'lodash'
import { request } from 'services/request'
import {
  Filter,
  Loan,
  LoanFundingSource,
  Meta,
  Pagination,
  Sort,
  Trust,
} from 'types'
import { updateResponseLoan } from 'utils/api/loan-parser'
import { updateRequestTable } from 'utils/api/table-parser'
import { formatPercent } from 'utils/math'

const getLoans = async (params: {
  sort?: Sort
  search?: string
  filter?: Filter
  page?: Pagination
}): Promise<{ loans: Loan[]; meta: Meta }> => {
  const {
    data: { loans, pagination },
  } = await request.get('/loan', { params: updateRequestTable(params) })
  return {
    loans,
    meta: pagination,
  }
}

const getLoansAdmins = async (): Promise<{ id: string; name: string }[]> => {
  const {
    data: { admins },
  } = await request.get(`/loan/filters/admins`)
  return sortBy(
    admins.map(({ count, ...rest }) => ({ count: toNumber(count), ...rest })),
    'count'
  ).reverse()
}

const getLoan = async (
  id: string
): Promise<Loan & { fundingSources: LoanFundingSource[] }> => {
  const {
    data: { loan, fundingSources },
  } = await request.get(`/loan/${id}`)
  return {
    ...updateResponseLoan(loan),
    fundingSources: fundingSources?.map((fundingSource: LoanFundingSource) => ({
      ...fundingSource,
      rate: formatPercent(fundingSource.rate),
    })),
  }
}

const getLoanTrusts = async (id: string): Promise<Trust[]> => {
  const {
    data: { trusts },
  } = await request.get(`/loan/${id}/trust`)
  return trusts
}

export { getLoans, getLoan, getLoanTrusts, getLoansAdmins }
