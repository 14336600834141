import { useCallback, ChangeEvent, useEffect, useRef } from 'react'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'
import { Task } from 'types'
import styles from '../styles.module.scss'

interface Props {
  subtasks: Task['subtasks']
  onChange: (task: { subtasks: Task['subtasks'] }) => void
}
function Subtasks({ subtasks, onChange }: Props) {
  const subtasksRef = useRef<HTMLDivElement>(null)
  const handleDoneChange = useCallback(
    (modifiedIndex: number, done: boolean) => {
      onChange({
        subtasks: subtasks.map((subtask, index) => {
          if (index === modifiedIndex) {
            return { ...subtask, done }
          }
          return subtask
        }),
      })
    },
    [subtasks, onChange]
  )
  const handleNameChange = useCallback(
    (modifiedIndex: number, name: string) => {
      onChange({
        subtasks: subtasks.map((subtask, index) => {
          if (index === modifiedIndex) {
            return { ...subtask, name }
          }
          return subtask
        }),
      })
    },
    [subtasks, onChange]
  )
  const handleAdd = useCallback(() => {
    onChange({ subtasks: [...subtasks, { done: false, name: '' }] })
  }, [subtasks, onChange])
  const handleDelete = useCallback(
    (index: number) => {
      onChange({ subtasks: subtasks.filter((_, i) => i !== index) })
    },
    [subtasks, onChange]
  )

  const handleTextareaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    []
  )

  useEffect(() => {
    subtasksRef.current?.querySelectorAll('textarea').forEach((textarea) => {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }, 1)
  }, [subtasks])

  return (
    <div ref={subtasksRef} className="pl-2">
      <Text className="mb-4 mt-2 text-[10px] font-bold text-grey-500">
        SUBTASKS
      </Text>
      {subtasks.length > 0 && (
        <Flex
          gap={0}
          flexDirection="column"
          className="border border-solid overflow-hidden border-grey-200 rounded mb-4"
        >
          {subtasks.map(({ name, done }, index) => (
            <Flex
              key={`${name}${index}`}
              className={styles.task}
              justifyContent="space-between"
              alignItems="flex-start"
              gap={8}
            >
              <Flex alignItems="flex-start" gap={8} className="w-full">
                <Checkbox
                  checked={done}
                  variant="rounded"
                  className="mt-1.5"
                  onChange={(e) => handleDoneChange(index, e.target.checked)}
                />
                <Textarea
                  className="border-none mt-0.5"
                  fieldClassName="flex-grow"
                  rows={1}
                  autoFocus
                  defaultValue={name}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    handleTextareaChange(e)
                  }}
                  onBlur={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    handleNameChange(index, e.target.value)
                  }}
                  placeholder="Enter a subtask name"
                />
              </Flex>
              <Button
                variant="ghost"
                onClick={() => handleDelete(index)}
                className={styles.delete}
              >
                <Icon name={IconName.delete} className="w-4 h-4" />
              </Button>
            </Flex>
          ))}
        </Flex>
      )}
      <Button variant="ghost" onClick={handleAdd}>
        <Icon name={IconName.addCircle} />
        Add Subtask
      </Button>
    </div>
  )
}

export { Subtasks }
