import { Formik } from 'formik'
import { compact } from 'lodash'
import { useEffect, useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { useBorrower } from 'hooks/use-borrower'
import { Person, PersonDetails, Quote, Email } from 'types'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  subject: required,
  message: required,
})

type FormValues = {
  subject: string
  message: string
}

interface Props {
  quote: Quote
  saving: boolean
  onSave: (values: FormValues) => void
  onCancel: () => void
}

function ModalSendQuote({ quote, saving, onSave, onCancel }: Props) {
  const [borrower, setBorrower] = useState<Person | PersonDetails>(
    quote.borrowers[0]
  )
  const { data: borrowerDetails, isLoading } = useBorrower(
    { id: quote.borrowers[0].id },
    { enabled: quote.borrowers[0].type === 'entity' }
  )
  const initialValue: FormValues = {
    subject: `Quote for ${quote.name}`,
    message: '',
  }

  useEffect(() => {
    if (borrowerDetails) {
      setBorrower(borrowerDetails)
    }
  }, [borrowerDetails])

  return (
    <Modal
      title="Send Quote"
      onClose={onCancel}
      className={styles.modal}
      loading={isLoading}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Flex stack gap={16}>
                <Flex stack gap={4}>
                  <div className={styles.label}>To:</div>
                  <div>
                    {compact([
                      ...(
                        (borrower.managers?.map(({ emails }) => emails) || [])
                          .flat()
                          .filter((email) => !!email) as Email[]
                      ).map(({ email }) => email),
                      borrower.email,
                      ...quote.guarantors.map(({ email }) => email),
                    ]).join(', ')}
                  </div>
                </Flex>
                <Flex stack gap={4}>
                  <div className={styles.label}>CC:</div>
                  <div>{quote.admin?.login.email || '-'}</div>
                </Flex>
                <Field
                  label="Subject"
                  name="subject"
                  placeholder="Your subject"
                />
              </Flex>
            </Grid.Item>
            <Grid.Item xs={12}>
              <Textarea
                label="Message"
                name="message"
                placeholder="Your message"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Send
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalSendQuote
