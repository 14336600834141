import { isArray } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { useRoles } from 'admin/hooks/use-roles'
import { useUsers } from 'admin/hooks/use-users'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'

interface Props {
  selectedOwners?: { id: string; name: string }[] | null
}

const useOwnerSelector = ({ selectedOwners }: Props = {}) => {
  const { user } = useSession()
  const { data: roles } = useRoles()
  const [options, setOptions] = useState<
    { id: string; name: string; email: string }[]
  >([])
  const { data, isPending } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  const roleOptions = useMemo(
    () =>
      [
        {
          id: 'Account Owners',
          name: 'Account Owners',
          isRole: true,
        },
        ...(roles?.map(({ id, name }) => ({ id, name, isRole: true })) || []),
      ] as {
        id: string
        name: string
        email?: string
        isRole: true
      }[],
    [roles]
  )

  useEffect(() => {
    const availableOptions =
      data?.map(({ id, name, email }) => ({ id, name, email })) || []
    const missedOptions =
      (selectedOwners || []).filter(({ id }) => {
        return !availableOptions.find((option) => option.id === id)
      }) || []
    setOptions([
      ...availableOptions,
      ...missedOptions.map((option) => ({
        ...option,
        email: '-',
      })),
    ])
  }, [data])

  return {
    isVisible: isArray(selectedOwners),
    selectedOwnerIds: selectedOwners?.map(({ id }) => id) || [],
    roleOptions,
    options,
    isPending,
  }
}

export { useOwnerSelector }
