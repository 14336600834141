import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { BudgetDraw } from 'types'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: BudgetDraw['status']
  reason?: string
}

const statusToColor: Record<
  BudgetDraw['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  Draft: 'gray',
  Approved: 'green',
  Rejected: 'red',
  'In Review': 'yellow',
  Released: 'blue',
}

function BadgeBudgetDrawStatus({ status, reason }: Props) {
  if (status === 'Rejected' && reason) {
    return (
      <Tooltip content={reason}>
        <span>
          <Badge color={statusToColor[status]}>
            {status} <Icon name={IconName.info} size="sm" />
          </Badge>
        </span>
      </Tooltip>
    )
  }
  return <Badge color={statusToColor[status]}>{status}</Badge>
}

export { BadgeBudgetDrawStatus }
