import { Formik } from 'formik'
import { compact } from 'lodash'
import { useState, useMemo, useCallback } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, LoanField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import styles from 'components/Modal/AddLoan/styles.module.scss'
import { TextLink } from 'components/TextLink'
import { Field } from 'types'
import {
  fieldsToInitialValues,
  valuesToFields,
  getFieldsBySection,
  orderFields,
} from 'utils/fields'

interface Props {
  loanFields: Field[]
  loading: boolean
  saving: boolean
  submitButtonLabel?: string
  onBack?: (values: { fields: Field[] }) => void
  onCancel: () => void
  onSubmit: (values: { fields: Field[] }) => void
}

function filterFields(field): boolean {
  if (field.viewOnly) {
    return false
  }

  switch (field.id) {
    case 'principal-and-interest':
    case 'to-trust':
      return false
    default:
      return true
  }
}

const LoanTermsForm = ({
  loanFields,
  loading,
  saving,
  onCancel,
  onBack,
  onSubmit,
}: Props) => {
  const [fields, setFields] = useState<Field[]>(loanFields)

  const handleFormChange = useCallback(
    (values: Record<string, string>) => {
      setFields(valuesToFields(fields as Field[], values))
    },
    [fields]
  )

  const handleSubmit = useCallback(() => {
    onSubmit({ fields: fields })
  }, [fields, onSubmit])

  const termsFields = useMemo(
    () => getFieldsBySection(loanFields, 'Terms', 'servicing'),
    [loanFields]
  )
  const originationFields = useMemo(
    () => getFieldsBySection(loanFields, 'Origination Details', 'servicing'),
    [loanFields]
  )
  const parametersFields = useMemo(
    () => getFieldsBySection(loanFields, 'Parameters', 'servicing'),
    [loanFields]
  )

  const initialValue = useMemo(
    () =>
      fieldsToInitialValues(
        compact([...termsFields, ...parametersFields, ...originationFields])
      ),
    [termsFields, termsFields, originationFields]
  )

  return loading ? (
    <div className={styles.loader}>
      <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
    </div>
  ) : (
    <Formik
      initialValues={initialValue}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      <Form modal onChange={handleFormChange}>
        <div>
          <Flex
            stack
            gap={16}
            className="pt-1 mb-6 border-0 border-b border-solid border-grey-100"
          >
            <Grid columnGap={16}>
              {orderFields([...termsFields, ...originationFields])
                .filter(filterFields)
                .map((field) => (
                  <Grid.Item key={field.id} xs={6}>
                    <LoanField field={field} />
                  </Grid.Item>
                ))}
            </Grid>
          </Flex>
          <Flex stack gap={16} className="pt-1">
            <Grid columnGap={16}>
              {parametersFields?.filter(filterFields).map((field) => (
                <Grid.Item key={field.id} xs={6}>
                  <LoanField field={field} />
                </Grid.Item>
              ))}
            </Grid>
          </Flex>
        </div>
        <div className={styles.buttonsWithBack}>
          {onBack ? (
            <TextLink
              variant="invert"
              onClick={() => {
                onBack({ fields: fields })
              }}
            >
              <Icon name={IconName.arrowLeft} size="sm" />
              Back
            </TextLink>
          ) : (
            <div />
          )}
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} type="submit">
              Save
            </Button>
          </div>
        </div>
      </Form>
    </Formik>
  )
}

export default LoanTermsForm
