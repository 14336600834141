import { useState } from 'react'
import { useLoanFundingSources } from 'admin/hooks/use-loan-funding-sources'
import { Flex } from 'components/Flex'
import { ModalTransfer } from 'components/Modal/Transfer'
import { Loan } from 'types'
import { getFieldsBySection } from 'utils/fields'
import PanelFundingInvestors from './PanelFundingInvestors'
import PanelLoanPayments from './PanelLoanPayments'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabFunding({ loan }: Props) {
  const [adding, setAdding] = useState(false)
  const [isTransferVisible, setIsTransferVisible] = useState(false)
  const { data: fundingSources, isPending } = useLoanFundingSources({
    loanId: loan.id,
  })
  const tranches = getFieldsBySection(loan.fields, 'Tranches')
  const trancheOptions = tranches.map(({ name, property: { value } }) => ({
    label: name,
    value: name,
    rate: value[1],
  }))

  return (
    <div className={styles.tabContent}>
      <Flex stack gap={16}>
        <PanelFundingInvestors
          loan={loan}
          adding={adding}
          setAdding={setAdding}
          setIsTransferVisible={setIsTransferVisible}
        />
        <PanelLoanPayments
          loan={loan}
          title="History"
          typeIncludeFilter={['Transfer', 'Funding']}
        />
      </Flex>
      {isTransferVisible && (
        <ModalTransfer
          loanId={loan.id}
          fundingSources={fundingSources?.fundingSources || []}
          trancheOptions={trancheOptions}
          loading={isPending}
          onCancel={() => setIsTransferVisible(false)}
          minDate={loan.datePaidto}
        />
      )}
    </div>
  )
}
