import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { Flex } from 'components/Flex'
import { formatUsd } from 'utils/currency'

interface Props {
  loanAmount: number
  releasedAmount: number
  committedAmount: number
  amount: number
}

const FundingBar = ({
  loanAmount,
  releasedAmount,
  committedAmount,
  amount,
}: Props) => {
  const [initialAmount] = useState(amount)
  const unfunded = useMemo(
    () => loanAmount - committedAmount - amount,
    [loanAmount, committedAmount, amount]
  )
  const total = useMemo(
    () => Math.max(loanAmount, committedAmount + amount),
    [loanAmount, committedAmount, amount]
  )
  const releasedPercent = useMemo(
    () => (releasedAmount / total) * 100,
    [releasedAmount, total]
  )
  const committedPercent = useMemo(
    () => ((committedAmount - releasedAmount - initialAmount) / total) * 100,
    [committedAmount, releasedAmount, initialAmount, total]
  )
  const amountPercent = useMemo(() => (amount / total) * 100, [amount, total])
  const isOverfunded = amount > unfunded

  return (
    <Flex stack gap={10} className="normal-nums">
      <div>
        Loan amount:{' '}
        <span className="text-green-100 font-bold">
          {formatUsd(loanAmount, { showZero: true })}
        </span>
      </div>
      <Flex gap={0} className="h-2 bg-grey-100 rounded-[3px]">
        {releasedPercent > 0.1 && (
          <div
            style={{ width: `${releasedPercent}%` }}
            className="h-full bg-green-50 inline-block first:rounded-l-[3px]"
          />
        )}
        {committedPercent > 0.1 && (
          <div
            style={{ width: `${committedPercent}%` }}
            className="h-full bg-green-35 inline-block first:rounded-l-[3px]"
          />
        )}
        {amountPercent > 0.1 && (
          <div
            style={{ width: `${amountPercent}%` }}
            className={clsx(
              'h-full bg-grey-400 inline-block first:rounded-l-[3px]',
              isOverfunded && 'last:rounded-r-[3px]'
            )}
          />
        )}
      </Flex>
      <Flex flexWrap="wrap" gap={12}>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-50 rounded-[3px]"></div>
          {formatUsd(releasedAmount, { showZero: true })} Released
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-green-35 rounded-[3px]"></div>
          {formatUsd(committedAmount - initialAmount, { showZero: true })}{' '}
          Committed
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-400 rounded-[3px]"></div>
          {formatUsd(amount, { showZero: true })} This Funding
        </Flex>
        <Flex gap={6} alignItems="center" className="text-sm">
          <div className="w-2.5 h-2.5 bg-grey-100 rounded-[3px]"></div>
          {formatUsd(unfunded, { showZero: true })} Unfunded
        </Flex>
      </Flex>
    </Flex>
  )
}

export { FundingBar }
