import { Select } from 'components/Form'
import { Grid } from 'components/Grid'

function YesNoForm() {
  return (
    <Grid.Item xs={12}>
      <Select
        label="Default Option"
        name="defaultValue"
        options={[
          { value: '', label: 'None' },
          { value: 'Yes', label: 'Yes' },
          { value: 'No', label: 'No' },
        ]}
      />
    </Grid.Item>
  )
}

export { YesNoForm }
