import { useUpdateFund } from 'admin/hooks/use-funds'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import { Fund } from 'types'

interface Props {
  fund: Fund
}

function TabSettings({ fund }: Props) {
  const { mutate: update } = useUpdateFund(fund.id)

  const handleDropdownChange = (id: string, type: string) => {
    update({ [id]: type })
  }

  return (
    <Grid>
      <Grid.Item xs={12} lg={8}>
        <Panel title="Settings">
          <Flex stack gap={0} className="text-grey-900">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={20}
              className="py-6 px-2 border-0 border-b border-solid border-grey-200"
            >
              <Text variant="l">Dividend Calculations</Text>
              <Select
                className="lg:min-w-60"
                options={[
                  {
                    label: 'Simple Interest',
                    value: 'Simple Interest',
                  },
                  {
                    label: 'Manual',
                    value: 'Manual',
                  },
                ]}
                value={fund.distributionCalculation}
                onChange={(option) =>
                  handleDropdownChange(
                    'distributionCalculation',
                    option.value as string
                  )
                }
              />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={20}
              className="py-6 px-2 border-0 border-b border-solid border-grey-200"
            >
              <Text variant="l">Distribution Schedule</Text>
              <Select
                className="lg:min-w-60"
                options={[
                  {
                    label: 'Monthly',
                    value: 'Monthly',
                  },
                  {
                    label: 'Quarterly',
                    value: 'Quarterly',
                  },
                  {
                    label: 'Annually',
                    value: 'Annually',
                  },
                ]}
                value={fund.distributionSchedule}
                onChange={(option) =>
                  handleDropdownChange(
                    'distributionSchedule',
                    option.value as string
                  )
                }
              />
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              gap={20}
              className="py-6 px-2"
            >
              <Text variant="l">Show Investors Portfolio</Text>
              <Select
                className="lg:min-w-60"
                options={[
                  {
                    label: 'Yes',
                    value: true,
                  },
                  {
                    label: 'No',
                    value: false,
                  },
                ]}
                value={fund.showPortfolio}
                onChange={(option) =>
                  handleDropdownChange('showPortfolio', option.value as string)
                }
              />
            </Flex>
          </Flex>
        </Panel>
      </Grid.Item>
    </Grid>
  )
}

export { TabSettings }
