import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo } from 'react'
import { User } from 'admin/services/api/users'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { useSession } from 'hooks/use-session'
import { LinkedAccount, PersonDetails } from 'types'
import styles from './styles.module.scss'

type Props = {
  data?: (User | LinkedAccount | PersonDetails)[]
  loading?: boolean
  onChangePermission: (id: string, role: string) => void
  onReinvite: (id: string) => void
  onDelete: (id: string) => void
}

function TableMembers({
  data = [],
  loading,
  onChangePermission,
  onReinvite,
  onDelete,
}: Props) {
  const { user } = useSession()
  const columns: ColumnDef<User | LinkedAccount | PersonDetails>[] = useMemo(
    () => [
      {
        header: 'User',
        accessorKey: 'name',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Access Level',
        accessorKey: 'permission.role',
        size: 120,
        cell: ({ getValue, cell }) => {
          const permission = (getValue() as string) || 'viewer'
          const { id } = cell.row.original
          return (
            <div className={styles.rightAction}>
              <EllipsesActions
                className={styles.roleMenu}
                trigger={
                  <div className={styles.role}>
                    {capitalize(permission)} <Icon name={IconName.arrowDown} />
                  </div>
                }
              >
                <EllipsesActions.Item
                  onSelect={() => onChangePermission(id, 'manager')}
                >
                  <div className={styles.roleItem}>
                    <div className={styles.roleTitle}>
                      <Icon name={IconName.manager} />
                      Manager
                    </div>
                    {permission === 'manager' && (
                      <Icon name={IconName.check} className={styles.tick} />
                    )}
                  </div>
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  onSelect={() => onChangePermission(id, 'viewer')}
                >
                  <div className={styles.roleItem}>
                    <div className={styles.roleTitle}>
                      <Icon name={IconName.viewer} />
                      Viewer
                    </div>
                    {permission === 'viewer' && (
                      <Icon name={IconName.check} className={styles.tick} />
                    )}
                  </div>
                </EllipsesActions.Item>
              </EllipsesActions>
            </div>
          )
        },
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
        cell({ getValue }) {
          const isActive = (getValue() as string) === 'active'

          return (
            <Badge color={isActive ? 'green' : 'blue'}>
              {isActive ? 'Active' : 'Invited'}
            </Badge>
          )
        },
      },
      {
        header: '',
        id: 'actions',
        size: 32,
        cell: ({ cell }) => {
          const { id, email } = cell.row.original
          return (
            <div className={styles.rightAction}>
              <EllipsesActions>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onReinvite(id)}
                  disabled={user?.person?.email === email}
                >
                  <Icon name={IconName.send} />
                  Re-invite
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  onSelect={() => onDelete(id)}
                  className="text-red-100"
                >
                  <Icon name={IconName.delete} />
                  Remove
                </EllipsesActions.Item>
              </EllipsesActions>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <Table columns={columns} data={data} loading={loading} minWidth={600} />
  )
}

export { TableMembers }
