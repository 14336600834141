import { compact, without } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Pills } from 'admin/pages/SettingsAutomations/ModalAutomation/Pills'
import { Button } from 'components/Button'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { InputCurrency } from 'components/InputCurrency'
import { InputNumber } from 'components/InputNumber'
import { InputPercentage } from 'components/InputPercentage'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import {
  IActionConfig,
  ITriggerConfig,
  IConditionConfig,
  ITriggerValue,
  IConditionValue,
  IActionValue,
} from 'types'

const MenuHeader = ({
  sentence,
  disabled,
  onBack,
  onDone,
}: {
  sentence: string
  disabled?: boolean
  onBack: () => void
  onDone: () => void
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      className="p-3 pb-0"
    >
      <Flex alignItems="center" gap={4}>
        <Icon
          name={IconName.arrowLeftLong}
          className="text-grey-600 cursor-pointer"
          onClick={onBack}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: sentence.replace('{{value}}', ''),
          }}
        />
      </Flex>
      <Button disabled={disabled} onClick={onDone}>
        Done
      </Button>
    </Flex>
  )
}

interface Props {
  menuItems: ITriggerConfig[] | IConditionConfig[] | IActionConfig[]
  type?: 'condition'
  value?: ITriggerValue | IConditionValue | IActionValue
  onSelect: (
    item: ITriggerValue | IConditionValue | IActionValue | undefined
  ) => void
}

const Menu = ({ menuItems, type, value, onSelect }: Props) => {
  const [search, setSearch] = useState('')
  const [innerValue, setInnerValue] = useState<
    ITriggerValue | IConditionValue | IActionValue | undefined
  >(value)

  const visibleMenuItems = useMemo(
    () =>
      menuItems.filter((menuItem) =>
        menuItem.name.toLowerCase().includes(search.toLowerCase())
      ),
    [menuItems, search]
  )
  const selectedMenuItem = useMemo(
    () => menuItems.find((actionConfig) => actionConfig.id === innerValue?.id),
    [menuItems, innerValue]
  )

  const handleFirstLevelClick = useCallback(
    (menuItem: ITriggerConfig | IConditionConfig | IActionConfig) => {
      setInnerValue({ id: menuItem.id, config: menuItem })
      if (menuItem.type === 'single') {
        onSelect({ id: menuItem.id, config: menuItem })
      }
    },
    [onSelect]
  )
  const handleBack = useCallback(() => {
    setInnerValue(undefined)
  }, [])
  const handleDone = useCallback(() => {
    onSelect(innerValue)
  }, [onSelect, innerValue])

  const isFirstLevelVisible =
    !selectedMenuItem || selectedMenuItem?.type === 'single'

  return (
    <>
      {isFirstLevelVisible && (
        <>
          <div className="p-3 border-0 border-b border-solid border-grey-200">
            {type === 'condition' ? (
              <div className="font-bold">And</div>
            ) : (
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search..."
              />
            )}
          </div>
          <div className="p-1 max-h-[200px] overflow-y-auto">
            {visibleMenuItems.map((item) => (
              <Flex
                key={item.id}
                alignItems="center"
                justifyContent="space-between"
                className="p-2 rounded cursor-pointer hover:bg-grey-75"
                onClick={() => handleFirstLevelClick(item)}
              >
                {item.name}
                {item.type === 'options' && (
                  <Icon name={IconName.arrowRight} className="text-grey-600" />
                )}
              </Flex>
            ))}
            {!visibleMenuItems.length && (
              <Flex
                stack
                alignItems="center"
                justifyContent="center"
                className="pt-12 pb-16"
              >
                <Icon
                  name={IconName.magnifyingGlass}
                  className="text-grey-500 w-7 h-7"
                />
                <Text variant="l">No search results</Text>
              </Flex>
            )}
          </div>
        </>
      )}
      {selectedMenuItem &&
        ['currency', 'percentage', 'number'].includes(
          selectedMenuItem.type
        ) && (
          <>
            <MenuHeader
              sentence={selectedMenuItem.sentence}
              disabled={
                !(innerValue as IConditionValue)?.condition ||
                !innerValue?.value
              }
              onBack={handleBack}
              onDone={handleDone}
            />
            <Flex alignItems="center" gap={10} className="p-3">
              <Select
                className="basis-1/2"
                options={[
                  { value: 'eq', label: 'Is' },
                  { value: 'gt', label: 'Is greater than' },
                  { value: 'gte', label: 'Is greater than or equal to' },
                  { value: 'lt', label: 'Is less than' },
                  { value: 'lte', label: 'Is less than or equal to' },
                ]}
                value={(innerValue as IConditionValue)?.condition}
                onChange={(option) =>
                  setInnerValue((currInnerValue) => ({
                    ...(currInnerValue as IConditionValue),
                    condition: option.value,
                  }))
                }
              />
              {selectedMenuItem.type === 'currency' && (
                <InputCurrency
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
              {selectedMenuItem.type === 'percentage' && (
                <InputPercentage
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
              {selectedMenuItem.type === 'number' && (
                <InputNumber
                  className="basis-1/2"
                  value={innerValue?.value as string}
                  onChange={(e) => {
                    const value = e.target.value
                    setInnerValue((currInnerValue) => ({
                      ...(currInnerValue as IConditionValue),
                      value,
                    }))
                  }}
                />
              )}
            </Flex>
          </>
        )}
      {selectedMenuItem?.type === 'options' && (
        <>
          <MenuHeader
            sentence={selectedMenuItem.sentence}
            disabled={!innerValue?.value || innerValue?.value.length === 0}
            onBack={handleBack}
            onDone={handleDone}
          />
          <div className="p-3 pt-2 border-0 border-b border-solid border-grey-200">
            <Pills
              pills={compact(
                (innerValue?.value as string[])?.map((val) =>
                  selectedMenuItem.options?.find(
                    (option) => option.value === val
                  )
                )
              )}
              onRemove={(val: string) =>
                setInnerValue((currInnerValue) => ({
                  ...(currInnerValue as
                    | ITriggerValue
                    | IConditionValue
                    | IActionValue),
                  value: without(innerValue?.value as string[], val),
                }))
              }
            />
          </div>
          <div className="p-1 max-h-[200px] overflow-y-auto">
            {selectedMenuItem?.options?.map(({ value, label }) => (
              <Flex
                key={value}
                alignItems="center"
                gap={8}
                className="p-2 rounded cursor-pointer hover:bg-grey-75"
                onClick={() =>
                  setInnerValue((currInnerValue) => ({
                    ...(currInnerValue as
                      | ITriggerValue
                      | IConditionValue
                      | IActionValue),
                    value: innerValue?.value?.includes(value)
                      ? without(innerValue.value, value)
                      : compact([innerValue?.value, value].flat()),
                  }))
                }
              >
                <Checkbox
                  checked={innerValue?.value?.includes(value) || false}
                  onChange={() => {}}
                />
                {label}
              </Flex>
            ))}
          </div>
        </>
      )}
    </>
  )
}

export { Menu }
