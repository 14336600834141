import { camelCase } from 'lodash'
import { Row } from 'types'
import { getReport } from '../api/reports'

const downloadTrustBalance = async (params): Promise<Row[]> => {
  const { loans, trusts } = await getReport({
    type: 'loan-trust-balances',
    params,
  })

  return loans.map((loan) => ({
    ID: loan.number,
    Name: loan.name,
    Status: loan.status,
    Origination: loan.dateClosing,
    Borrower: loan.borrowers[0]?.name,
    ...trusts.reduce(
      (acc, { id, name }) => ({
        ...acc,
        [`${name}$`]: loan.trusts?.[camelCase(id)],
      }),
      {}
    ),
  }))
}

export { downloadTrustBalance }
