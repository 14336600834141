import { Borrower } from '../../../admin/services/api/borrowers'
import { Investor } from '../../../admin/services/api/investors'
import { FormBorrowerEntity } from '../../FormBorrowerEntity'
import { FormBorrowerIndividual } from '../../FormBorrowerIndividual'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalBorrowerProps {
  personType: 'borrower' | 'investor' | 'fund'
  person: Borrower | Investor
  saving?: boolean
  exclude?: string[]
  include?: string[]
  title?: string
  onSave: (borrower: Borrower) => void
  onCancel: () => void
}

function ModalPersonInfo({
  personType,
  person,
  saving,
  onSave,
  onCancel,
  exclude,
  include,
  title,
}: ModalBorrowerProps) {
  return (
    <Modal
      title={title || 'Information'}
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      {person.type === 'individual' ? (
        <FormBorrowerIndividual
          include={[
            ...(personType === 'borrower'
              ? ['isAccount', 'creditScore']
              : ['isAccount']),
            ...(include || []),
          ]}
          exclude={exclude}
          saving={saving}
          borrower={person}
          onSave={(data) =>
            onSave({
              ...data,
              socialSecurityNumber: data.socialSecurityNumber || null,
            })
          }
          onCancel={onCancel}
        />
      ) : (
        <FormBorrowerEntity
          saving={saving}
          borrower={person}
          include={include}
          exclude={exclude}
          onSave={(data) =>
            onSave({
              ...data,
              socialSecurityNumber: data.socialSecurityNumber || null,
            })
          }
          onCancel={onCancel}
        />
      )}
    </Modal>
  )
}

export default ModalPersonInfo
