import clsx from 'clsx'
import {
  ChangeEvent,
  FocusEvent,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
} from 'react'
import { IMaskInput } from 'react-imask'
import styles from '../Form/styles.module.scss'
import { formatPhone, parsePhone } from './helpers'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

const InputPhone = forwardRef(
  (
    {
      className,
      value,
      onChange,
      onBlur,
      autoComplete = 'off',
      placeholder = '(000) 000-0000',
      ...rest
    }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    return (
      <IMaskInput
        ref={ref}
        type="text"
        unmask="typed"
        mask="(000) 000-0000"
        className={clsx(styles.input, className)}
        value={parsePhone(value as string)}
        autoComplete={autoComplete}
        placeholder={placeholder}
        onAccept={(value, mask, event) => {
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = formatPhone(value as string)
            onChange &&
              onChange(event as unknown as ChangeEvent<HTMLInputElement>)
            target.value = currentValue
          }
        }}
        onBlur={(event) => {
          if (event) {
            const target = event.target as HTMLInputElement
            const currentValue = target.value
            target.value = formatPhone(target.value as string)
            onBlur && onBlur(event as unknown as FocusEvent<HTMLInputElement>)
            target.value = currentValue === '$' ? '' : currentValue
          }
        }}
        {...rest}
      />
    )
  }
)
InputPhone.displayName = 'InputPhone'

export { InputPhone }
