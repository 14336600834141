import { Formik } from 'formik'
import { toString } from 'lodash'
import { useMemo } from 'react'
import { Button } from 'components/Button'
import { Form, FieldIcon, Select, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { workTypes, scales } from 'constants/budget'
import { useAddBudgetItem, useUpdateBudgetItem } from 'hooks/use-loan-budget'
import { BudgetItem } from 'types'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

const EmailSchema = createScheme({
  name: required,
  scale: required,
  amount: required,
})

type FormValues = {
  name: string
  description: string
  scale: string
  amount: string
}

interface ModalAddEmailProps {
  loanId: string
  addressId: string
  budgetItem?: BudgetItem
  onCancel: () => void
}

function ModalAddBudget({
  loanId,
  addressId,
  budgetItem,
  onCancel,
}: ModalAddEmailProps) {
  const { mutate: add, isPending: adding } = useAddBudgetItem(loanId, addressId)
  const { mutate: edit, isPending: editing } = useUpdateBudgetItem(
    loanId,
    addressId
  )
  const handleSubmit = (values) => {
    const save = (budgetItem ? edit : add) as any
    save(
      {
        id: budgetItem?.id,
        ...values,
        amount: parseFloat(values.amount),
      },
      {
        onSuccess: () => onCancel(),
      }
    )
  }

  const initialValue: FormValues = useMemo(
    () => ({
      name: budgetItem?.name || '',
      description: budgetItem?.description || '',
      scale: budgetItem?.scale || '',
      amount: toString(budgetItem?.amount) || '',
    }),
    [budgetItem]
  )

  return (
    <Modal title="Add Budget" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={EmailSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Select
                name="name"
                label="Work to be done"
                portal
                options={workTypes.map((workType) => ({
                  label: workType,
                  value: workType,
                }))}
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Textarea
                name="description"
                label="Description"
                placeholder="List materials being used. if this is a repair or a replacement, and quantities. More details are better"
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="scale"
                label="Scale"
                portal
                options={scales.map((scale) => ({
                  label: scale,
                  value: scale,
                }))}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <FieldIcon type="currency" name="amount" label="Budget" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={adding || editing} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalAddBudget }
