import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KEY_LOAN_MODIFICATION, KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LoanModification } from 'types'
import { message } from 'utils/message'
import {
  getLoanModifications,
  getLoanModification,
  addLoanModification,
  updateLoanModification,
  deleteLoanModification,
  addLoanModificationDocument,
  deleteLoanModificationDocument,
  getModificationFields,
} from '../services/api/loan-modifications'

const useLoanModifications = (
  loanId: string,
  options: { enabled?: boolean } = {}
) => {
  return useQuery({
    queryKey: [KEY_LOAN_MODIFICATION, loanId],
    queryFn: () => getLoanModifications(loanId),
    ...options,
  })
}

const useModificationFields = (options: { enabled?: boolean } = {}) => {
  return useQuery({
    queryKey: [KEY_LOAN_MODIFICATION, 'fields'],
    queryFn: () => getModificationFields(),
    ...options,
  })
}

const useLoanModification = (loanId: string, modificationId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_MODIFICATION, loanId, modificationId],
    queryFn: () => getLoanModification(loanId, modificationId),
  })
}

const useAddLoanModification = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Partial<LoanModification>) =>
      addLoanModification(loanId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_MODIFICATION, loanId],
      })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanModification = ({ loanId }: { loanId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: LoanModification) =>
      updateLoanModification(loanId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_MODIFICATION, loanId],
      })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanModification = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (modificationId: string) =>
      deleteLoanModification(loanId, modificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_MODIFICATION, loanId],
      })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanModificationDocument = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (modificationId: string) =>
      addLoanModificationDocument(loanId, modificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_MODIFICATION, loanId],
      })
      message.success('Agreement generated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanModificationDocument = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (modificationId: string) =>
      deleteLoanModificationDocument(loanId, modificationId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_MODIFICATION, loanId],
      })
      message.success('Agreement deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useLoanModifications,
  useLoanModification,
  useAddLoanModification,
  useUpdateLoanModification,
  useDeleteLoanModification,
  useAddLoanModificationDocument,
  useDeleteLoanModificationDocument,
  useModificationFields,
}
