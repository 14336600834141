import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { QuoteTermSheetPage } from 'components/QuoteTermSheetPage'
import { useQuoteVersion } from 'hooks/use-quote'
import styles from './styles.module.scss'

const QuoteTermSheet = () => {
  const { id } = useParams() as { id: string }
  const { data: quote, isPending } = useQuoteVersion(id)
  const [isLogoLoaded, setIsLogoLoaded] = useState(false)
  const isLoaded = !isPending && isLogoLoaded

  return (
    quote && (
      <div className={styles.body} data-all-loaded={isLoaded}>
        <QuoteTermSheetPage
          quote={quote}
          onLogoLoaded={() => setIsLogoLoaded(true)}
        />
      </div>
    )
  )
}

export { QuoteTermSheet }
