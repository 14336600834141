import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { once } from 'lodash'
import theme from 'styles/theme'

ChartJS.defaults.font.family = theme.font.family
ChartJS.defaults.font.size = 12
ChartJS.defaults.font.weight = 'lighter'
ChartJS.defaults.color = theme.color.black100

const registerLineChart = once(() => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
    Filler
  )
})

const lineDefaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest' as const,
    intersect: false,
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    tooltip: {
      padding: 8,
      cornerRadius: 5,
      backgroundColor: theme.color.blue25,
      titleColor: theme.color.blue200,
      titleFont: {
        weight: 450,
      },
      bodyColor: theme.color.black100,
      bodyFont: {
        weight: 450,
      },
      displayColors: false,
    },
    legend: {
      position: 'top' as const,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        boxWidth: 5,
        boxHeight: 5,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawOnChartArea: false,
      },
    },
  },
}

const datasetOptions = {
  borderWidth: 2,
  lineTension: 0.4,
  pointBackgroundColor: theme.color.white100,
}

const getGradient = (ctx) => {
  const height = ctx.canvas.offsetHeight || 300
  const gradient = ctx.createLinearGradient(0, 0, 0, height)
  gradient.addColorStop(0, 'rgba(62, 127, 232, 1)')
  gradient.addColorStop(1, 'rgba(123, 200, 255, 0.25)')
  return gradient
}

export { registerLineChart, lineDefaultOptions, datasetOptions, getGradient }
