import { useState } from 'react'
import { SelectVendor } from 'admin/components/SelectVendor'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor'
import { NewVendor, Role, Vendor } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import Modal from 'components/Modal/Modal'
import { TextLink } from 'components/TextLink'
import { Filter } from 'types'

interface Props {
  title?: string
  createLinkText?: string
  saving?: boolean
  onSave: (vendor: NewVendor) => void
  onClose: () => void
  vendor?: Partial<Omit<Vendor, 'role'>> & { role?: Role | Role[] }
}

function ChooseVendor({
  title,
  createLinkText,
  onSave,
  onClose,
  vendor,
  saving,
}: Props) {
  const [state, setState] = useState<'list' | 'form'>('list')
  const [isError, setIsError] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>()

  return (
    <Modal
      title={title || 'Add Vendor'}
      onClose={onClose}
      className="w-[564px]"
    >
      {state === 'list' && (
        <>
          <Flex gap={16}>
            <SelectVendor
              className="w-full mb-2"
              onSelect={(id: string) => {
                setSelectedId(id)
                setIsError(false)
              }}
              filter={vendor as unknown as Filter}
              onCreate={() => {
                setState('form')
              }}
            />
          </Flex>
          {isError && (
            <div className="text-red-100 mb-4">You must select a vendor</div>
          )}
          <TextLink
            onClick={() => {
              setState('form')
            }}
            className="py-2 font-bold flex alignCenter"
          >
            <Icon name={IconName.plus} size="sm" />
            {createLinkText || 'Create a new Vendor'}
          </TextLink>
          <Flex gap={16} justifyContent="flex-end">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedId) {
                  onSave({ id: selectedId as string } as unknown as NewVendor)
                } else {
                  setIsError(true)
                }
              }}
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </>
      )}
      {state === 'form' && (
        <ModalAddVendor
          saving={saving}
          onCancel={onClose}
          onSave={(vendor) => {
            onSave(vendor as NewVendor)
          }}
          vendor={vendor}
        />
      )}
    </Modal>
  )
}

export { ChooseVendor }
