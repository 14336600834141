import { capitalize } from 'lodash'
import { PersonStatus } from 'types/person'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface StatusBadgeProps {
  status: PersonStatus
}

const statusToColor: Record<
  PersonStatus,
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  draft: 'gray',
  invited: 'blue',
  active: 'green',
  inactive: 'yellow',
  closed: 'red',
}

function PersonStatusBadge({ status }: StatusBadgeProps) {
  return <Badge color={statusToColor[status]}>{capitalize(status)}</Badge>
}

export default PersonStatusBadge
