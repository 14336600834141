import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  KEY_LOANS,
  KEY_LOAN_BUDGET,
  KEY_APPLICATIONS,
} from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { getLoanAddressRehab } from 'services/api/loan-address'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useUploadLoanAddressRehabBudget = ({
  loanId,
  addressId,
  uploadUrl,
}: {
  loanId: string
  addressId: string
  uploadUrl: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (file: File) => {
      const uploadToast = message.upload('Worksheet')
      uploadToast.show()
      const { headers } = await uploadDocument(
        uploadUrl,
        file,
        (progressEvent) => {
          uploadToast.progress(progressEvent.loaded / progressEvent.total)
        }
      )
      uploadToast.processing()
      const versionId = headers['x-amz-version-id']
      const rehab = await getLoanAddressRehab(loanId, addressId, versionId)
      uploadToast.complete()
      return rehab
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_BUDGET, loanId, addressId],
      })
      queryClient.invalidateQueries({ queryKey: [KEY_APPLICATIONS, loanId] })
    },
    onError: handleErrorResponse,
  })
}

export { useUploadLoanAddressRehabBudget }
