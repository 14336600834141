import { useState } from 'react'
import {
  useLenderOfRecords,
  useDeleteLenderOfRecord,
} from 'admin/hooks/use-lender-of-record'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalLender } from 'components/Modal/Lender'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { formatAddress } from 'utils/address'
import styles from './styles.module.scss'

interface Props {
  clientId: string
}

function PanelLenderOfRecord({ clientId }: Props) {
  const { data: lenders, isPending } = useLenderOfRecords(clientId)
  const { mutate: deleteLender, isPending: saving } = useDeleteLenderOfRecord(
    clientId as string
  )
  const [editingLenderId, setEditingLenderId] = useState<string>()
  const [removingLenderId, setRemovingLenderId] = useState<string>()
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <Panel
      title="Lenders of Record"
      loading={isPending}
      className="max-w-panel"
    >
      {lenders?.length === 0 && (
        <div className={styles.empty}>None have been added yet</div>
      )}
      <Flex stack>
        {lenders?.map((lender) => (
          <div className={styles.lender} key={lender.id}>
            <EllipsesActions
              trigger={
                <div>
                  <Icon
                    name={IconName.ellipses}
                    size="md"
                    className={styles.action}
                  />
                </div>
              }
            >
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  setEditingLenderId(lender.id)
                  setIsModalVisible(true)
                }}
              >
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => setRemovingLenderId(lender.id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>

            <Grid gap={16}>
              <Grid.Item xs={6}>
                <Summary name="Name">{lender.name}</Summary>
              </Grid.Item>
              <Grid.Item xs={6}>
                <Summary name="Jurisdiction">{lender.jurisdiction}</Summary>
              </Grid.Item>
              <Grid.Item xs={6}>
                <Summary name="Type of corporation">{lender.type}</Summary>
              </Grid.Item>
              <Grid.Item xs={6}>
                <Summary name="Address">
                  {formatAddress(lender.address)}
                </Summary>
              </Grid.Item>
            </Grid>
          </div>
        ))}
      </Flex>
      <div className={styles.link}>
        <TextLink onClick={() => setIsModalVisible(true)}>
          <Icon name={IconName.plus} size="sm" />
          Add Lender
        </TextLink>
      </div>
      {isModalVisible && (
        <ModalLender
          clientId={clientId as string}
          lender={lenders?.find(({ id }) => editingLenderId === id)}
          onCancel={() => {
            setIsModalVisible(false)
            setEditingLenderId(undefined)
          }}
        />
      )}
      {removingLenderId && (
        <ModalDelete
          resource="lender of record"
          loading={saving}
          onDelete={() =>
            deleteLender(removingLenderId, {
              onSuccess: () => setRemovingLenderId(undefined),
            })
          }
          onCancel={() => setRemovingLenderId(undefined)}
        />
      )}
    </Panel>
  )
}

export { PanelLenderOfRecord }
