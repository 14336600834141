import { request } from 'services/request'
import { FundDistribution, Sort } from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

const getFundDistributions = async ({
  fundId,
  sort,
}: {
  fundId: string
  sort?: Sort
}): Promise<FundDistribution[]> => {
  const {
    data: { distributions },
  } = await request.get(`/fund/${fundId}/distribution`, {
    params: updateRequestTable({ sort }),
  })
  return distributions
}

const getFundDistribution = async (
  id: string,
  distributionId: string,
  versionId?: string
): Promise<FundDistribution> => {
  const {
    data: { distribution },
  } = await request.get(`/fund/${id}/distribution/${distributionId}`, {
    params: { wait: versionId },
  })
  return distribution
}

const addFundDistribution = async (
  id: string,
  date: string
): Promise<FundDistribution> => {
  const {
    data: { distribution },
  } = await request.post(`/fund/${id}/distribution`, {
    date,
  })
  return distribution
}

const updateFundDistribution = async (
  fundId: string,
  id: string,
  payload: Partial<FundDistribution>
): Promise<FundDistribution> => {
  const {
    data: { distribution },
  } = await request.patch(`/fund/${fundId}/distribution/${id}`, payload)
  return distribution
}

const publishFundDistribution = async (
  fundId: string,
  id: string,
  payload?: Partial<FundDistribution>
): Promise<FundDistribution> => {
  const {
    data: { distribution },
  } = await request.put(`/fund/${fundId}/distribution/${id}`, payload)
  return distribution
}

const getTemplateUploadUrl = async (
  fundId: string,
  distributionId: string
): Promise<{ url: string }> => {
  const {
    data: { uploadUrl },
  } = await request.post(
    `/fund/${fundId}/distribution/${distributionId}/template`
  )
  return { url: uploadUrl }
}

const downloadFundDistribution = async (
  fundId: string,
  distributionId: string
): Promise<{ url: string }> => {
  const {
    data: { url },
  } = await request.get(
    `/fund/${fundId}/distribution/${distributionId}/template`
  )
  return { url }
}

const deleteFundDistribution = async (
  fundId: string,
  id: string
): Promise<FundDistribution[]> => {
  const {
    data: { distributions },
  } = await request.delete(`/fund/${fundId}/distribution/${id}`)
  return distributions
}

const getFundIncome = async (params: {
  fundId: string
  dateStart: string | null
  dateEnd: string | null
}): Promise<{ report: number }> => {
  const { data } = await request.post('report/fund-income', params)
  return data
}

export {
  getFundDistributions,
  getFundDistribution,
  addFundDistribution,
  updateFundDistribution,
  getTemplateUploadUrl,
  publishFundDistribution,
  downloadFundDistribution,
  deleteFundDistribution,
  getFundIncome,
}
