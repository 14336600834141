import clsx from 'clsx'
import { compact, uniq, sortBy } from 'lodash'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalNotificationSettings } from 'components/Modal/NotificationSettings'
import { Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import { NotificationSetting } from 'types'
import styles from './styles.module.scss'

interface Props {
  editable?: boolean
  useNotificationSettings: any
  useUpdateNotificationSettings: any
}

const NotificationSettings = ({
  editable,
  useNotificationSettings,
  useUpdateNotificationSettings,
}: Props) => {
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSetting[]>()
  const { data: responseNotificationSettings, isPending } =
    useNotificationSettings()
  const { mutate: sendNotificationSettings, isPending: isSaving } =
    useUpdateNotificationSettings()
  const [editNotificationId, setEditNotificationId] = useState<
    string | undefined
  >(undefined)
  const sections = sortBy(
    uniq(notificationSettings?.map(({ section }) => section) || [])
  )
  const updateNotificationSettings = useCallback(
    (id: string, nextNotificationSetting: Partial<NotificationSetting>) => {
      const notificationSetting = notificationSettings?.find(
        (notification) => notification.id === id
      ) as NotificationSetting
      sendNotificationSettings([
        {
          id,
          enabled: notificationSetting.enabled,
          emailEnabled: notificationSetting.emailEnabled,
          pushEnabled: notificationSetting.pushEnabled,
          ...nextNotificationSetting,
        },
      ])
      setNotificationSettings(
        notificationSettings?.map((notification) => {
          if (notification.id === id) {
            return { ...notification, ...nextNotificationSetting }
          }
          return notification
        })
      )
    },
    [notificationSettings]
  )
  const handleToggleChange = useCallback(
    (id, enabled) => {
      updateNotificationSettings(id, {
        enabled,
      })
    },
    [updateNotificationSettings]
  )
  const handleDropdownChange = useCallback(
    (id, method) => {
      updateNotificationSettings(id, {
        emailEnabled: method.includes('email'),
        pushEnabled: method.includes('push'),
      })
    },
    [updateNotificationSettings]
  )

  useEffect(() => {
    setNotificationSettings(sortBy(responseNotificationSettings, 'name'))
  }, [responseNotificationSettings])

  return isPending ? (
    <PageLoader />
  ) : (
    <div>
      {sections.map((section) => (
        <Fragment key={section}>
          <div className={styles.section}>{section}</div>
          <div
            className={clsx(styles.notifications, editable && styles.editable)}
          >
            {notificationSettings
              ?.filter(
                ({ section: notificationSection }) =>
                  section === notificationSection
              )
              .map((notification) => (
                <Fragment key={notification.id}>
                  <div className={clsx(styles.cell, styles.cellToggle)}>
                    <Toggle
                      checked={notification.enabled}
                      onChange={(e) => {
                        handleToggleChange(notification.id, e.target.checked)
                      }}
                    />
                  </div>
                  <div className={clsx(styles.cell, styles.cellName)}>
                    {notification.name}
                  </div>
                  <div className={clsx(styles.cell, styles.cellDescription)}>
                    {notification.description}
                  </div>
                  <div className={clsx(styles.cell, styles.cellDropdown)}>
                    {notification.enabled && (
                      <Select
                        options={[
                          {
                            value: 'email_push',
                            label: 'Email & Notification',
                          },
                          {
                            value: 'email',
                            label: 'Email',
                          },
                          {
                            value: 'push',
                            label: 'Notification',
                          },
                        ]}
                        className={styles.dropdown}
                        value={compact([
                          notification.emailEnabled && 'email',
                          notification.pushEnabled && 'push',
                        ]).join('_')}
                        onChange={(option) =>
                          handleDropdownChange(notification.id, option.value)
                        }
                      />
                    )}
                  </div>
                  {editable && (
                    <div className={clsx(styles.cell, styles.cellActions)}>
                      <EllipsesActions>
                        <EllipsesActions.Item
                          icon
                          onSelect={() =>
                            setEditNotificationId(notification.id)
                          }
                        >
                          <Icon name={IconName.edit} />
                          Edit
                        </EllipsesActions.Item>
                      </EllipsesActions>
                    </div>
                  )}
                </Fragment>
              ))}
          </div>
        </Fragment>
      ))}
      {editNotificationId && (
        <ModalNotificationSettings
          initialNotificationSettings={
            notificationSettings?.find(
              ({ id }) => id === editNotificationId
            ) as NotificationSetting
          }
          saving={isSaving}
          onSave={(notification) => {
            updateNotificationSettings(notification.id as string, notification)
            setEditNotificationId(undefined)
          }}
          onReset={() => {}}
          onCancel={() => setEditNotificationId(undefined)}
        />
      )}
    </div>
  )
}

export { NotificationSettings }
