import { request } from 'services/request'
import { Field, Address, Valuation, Loan, Insurance } from 'types'
import { updateResponseLoan, updateRequestFields } from 'utils/api/loan-parser'

type LoanAddress = Partial<Omit<Address, 'fields'>> & {
  fields: Field[]
}

const updateLoanAddress = async (
  loanId: string,
  address: Partial<LoanAddress>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(`/loan/${loanId}/address/${address.id}`, {
    ...address,
    ...(address.fields
      ? {
          fields: updateRequestFields(address.fields as Field[]),
        }
      : {}),
  })
  return updateResponseLoan(loan)
}

const addLoanAddress = async (
  loanId: string,
  address: Partial<LoanAddress>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${loanId}/address`, {
    ...address,
    ...(address.fields
      ? {
          fields: updateRequestFields(address.fields as Field[]),
        }
      : {}),
  })
  return updateResponseLoan(loan)
}

const updateLoanAddressField = async (
  loanId: string,
  addressId: string,
  field: Field
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/field/${field.id}`,
    updateRequestFields([field])[0]
  )
  return updateResponseLoan(loan)
}

const addLoanAddressValuation = async (
  loanId: string,
  addressId: string,
  valuation: Partial<Valuation>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(
    `/loan/${loanId}/address/${addressId}/valuation`,
    valuation
  )
  return updateResponseLoan(loan)
}

const updateLoanAddressValuation = async (
  loanId: string,
  addressId: string,
  valuation: Partial<Valuation>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/valuation/${valuation.id}`,
    valuation
  )
  return updateResponseLoan(loan)
}

const deleteLoanAddressValuation = async (
  loanId: string,
  addressId: string,
  valuationId: string
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(
    `/loan/${loanId}/address/${addressId}/valuation/${valuationId}`
  )
  return updateResponseLoan(loan)
}

const addLoanAddressInsurance = async (
  loanId: string,
  addressId: string,
  insurance: Partial<Insurance>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(
    `/loan/${loanId}/address/${addressId}/insurance`,
    insurance
  )
  return updateResponseLoan(loan)
}

const updateLoanAddressInsurance = async (
  loanId: string,
  addressId: string,
  insurance: Partial<Insurance>
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/insurance/${insurance.id}`,
    insurance
  )
  return updateResponseLoan(loan)
}

const deleteLoanAddressInsurance = async (
  loanId: string,
  addressId: string,
  insuranceId: string
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(
    `/loan/${loanId}/address/${addressId}/insurance/${insuranceId}`
  )
  return updateResponseLoan(loan)
}

const deleteLoanAddress = async (
  loanId: string,
  addressId: string
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.delete(`/loan/${loanId}/address/${addressId}`)
  return updateResponseLoan(loan)
}

const getLoanAddressUnitDetails = async (
  loanId: string,
  addressId: string
): Promise<{ unitDetailsCsv: string }> => {
  const {
    data: { unitDetails },
  } = await request.get(`/loan/${loanId}/address/${addressId}/unit_details`)
  return { unitDetailsCsv: unitDetails }
}

const setLoanAddressUnitDetails = async (
  loanId: string,
  addressId: string,
  unitDetailsCsv: string
): Promise<{ unitDetailsCsv: string }> => {
  const { data } = await request.post(
    `/loan/${loanId}/address/${addressId}/unit_details`,
    { unitDetails: unitDetailsCsv }
  )
  return { unitDetailsCsv: data.unitDetails }
}

export type { LoanAddress }
export {
  updateLoanAddress,
  updateLoanAddressField,
  addLoanAddressValuation,
  updateLoanAddressValuation,
  deleteLoanAddressValuation,
  addLoanAddressInsurance,
  updateLoanAddressInsurance,
  deleteLoanAddressInsurance,
  deleteLoanAddress,
  addLoanAddress,
  getLoanAddressUnitDetails,
  setLoanAddressUnitDetails,
}
