import clsx from 'clsx'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  isLocked: boolean
  document: LoanDocument
  version?: DocumentVersion
  onUpload: () => void
  onChangeVersion: (versionId: string) => void
}

export function PanelVersionHistory({
  isLocked,
  document,
  version: currentVersion,
  onUpload,
  onChangeVersion,
}: Props) {
  return (
    <div className={styles.panelVersions}>
      {document.versions.length === 0 && (
        <div className={styles.noVersions}>No versions yet</div>
      )}
      {document.versions.map((version, index) => (
        <div
          className={clsx(styles.versionDetails, {
            [styles.selected]: currentVersion?.id === version.id,
          })}
          onClick={() => onChangeVersion(version.id)}
          key={version.id}
        >
          <Header
            variant="h4"
            className={clsx(styles.versionDetailsHeader, {
              [styles.selected]: currentVersion?.id === version.id,
            })}
          >
            {index === document.versions.length - 1
              ? 'Current Version'
              : `Version ${index + 1}`}
          </Header>
          <div className={styles.versionMeta}>
            Updated by {version.uploader?.name}
          </div>
          <div className={styles.versionMeta}>
            {friendlyDate(version.createdAt)}
          </div>
        </div>
      ))}
      <div>
        <Button
          disabled={isLocked}
          className={styles.btnUpload}
          variant="secondary"
          onClick={onUpload}
        >
          Upload a New Version
        </Button>
      </div>
    </div>
  )
}
