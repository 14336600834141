import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { KEY_MEMBERS } from 'constants/query-keys'
import {
  addMember,
  deleteMember,
  getMembers,
  reinviteMember,
  updateMember,
} from 'services/api/members'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

interface Props {
  managerId: string
  type: 'borrower' | 'investor'
}

const useMembers = (
  { managerId, type }: Props,
  options: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_MEMBERS, type, managerId],
    queryFn: () => getMembers({ type, managerId }),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useAddMember = ({ managerId, type }: Props) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      name,
      email,
      role,
    }: {
      name: string
      email: string
      role: string
    }) => addMember({ managerId, type, name, email, role }),
    onSuccess: (data) => {
      queryClient.setQueryData([KEY_MEMBERS, type, managerId], data)
      queryClient.invalidateQueries({
        queryKey: [KEY_MEMBERS, type, managerId],
      })
      message.success('Member added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateMember = ({ managerId, type }: Props) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ memberId, role }: { memberId: string; role: string }) =>
      updateMember({ managerId, type, memberId, role }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_MEMBERS, type, managerId],
      })
      message.success('Member updated')
    },
    onError: handleErrorResponse,
  })
}

const useReInviteMember = ({ managerId, type }: Props) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ memberId }: { memberId: string }) =>
      reinviteMember({ managerId, type, memberId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_MEMBERS, type, managerId],
      })
      message.success('Member re-invited')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveMember = ({ managerId, type }: Props) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ memberId }: { memberId: string }) =>
      deleteMember({ managerId, type, memberId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_MEMBERS, type, managerId],
      })
      message.success('Member removed')
    },
    onError: handleErrorResponse,
  })
}

export {
  useMembers,
  useAddMember,
  useUpdateMember,
  useRemoveMember,
  useReInviteMember,
}
