import clsx from 'clsx'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { Phase } from 'types'
import { formatDate } from 'utils/date'

interface Props {
  phases: Phase[]
  selectedPhaseIndex: number
  onSelect: (index: number) => void
  onAdd: () => void
  onDelete: (index: number) => void
}

const Phases = ({
  phases,
  selectedPhaseIndex,
  onSelect,
  onAdd,
  onDelete,
}: Props) => {
  return (
    <Flex stack gap={8} className="pt-5 basis-64 flex-shrink-0">
      <Text
        className="text-grey-700 uppercase font-bold px-3 mb-4"
        variant="xs"
      >
        Spread Timeline
      </Text>

      {phases.map(({ dateEffective }, index) => (
        <Flex
          key={index}
          gap={8}
          justifyContent="space-between"
          alignItems="center"
          className={clsx(
            'p-3 rounded cursor-pointer hover:bg-grey-100 group',
            selectedPhaseIndex === index && 'bg-grey-100'
          )}
          onClick={() => onSelect(index)}
        >
          <Flex gap={8}>
            <Icon name={IconName.calendar} />
            <Flex stack gap={8}>
              <Text variant="l" className="font-bold">
                {dateEffective[0]
                  ? formatDate(dateEffective[0])
                  : 'Origination'}
              </Text>
              <Text variant="s">
                {dateEffective[1] ? formatDate(dateEffective[1]) : 'to Forever'}
              </Text>
            </Flex>
          </Flex>
          <EllipsesActions>
            <EllipsesActions.Item
              icon
              disabled={phases.length === 1}
              onSelect={() => onDelete(index)}
              className="text-red-100"
            >
              <Icon name={IconName.delete} />
              Delete
            </EllipsesActions.Item>
          </EllipsesActions>
        </Flex>
      ))}

      <TextLink className="px-3 py-4" onClick={onAdd}>
        <Icon name={IconName.plus} size="sm" />
        Add Phase
      </TextLink>
    </Flex>
  )
}

export { Phases }
