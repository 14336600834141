import { Badge, OfferingTypeBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Summary } from 'components/Summary'
import { Offering } from 'types'
import { formatUsd } from 'utils/currency'
import { getCities, getRates } from 'utils/offering'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  onClick: (offering: Offering) => void
}

function OfferingItem({ offering, onClick }: Props) {
  const cities = getCities(offering)
  const rates = getRates(offering)
  const imageUrl = offering?.image?.url

  return (
    <div className={styles.item} onClick={() => onClick(offering)}>
      <div
        className={styles.image}
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}
      >
        <OfferingTypeBadge
          type={
            offering.percentSubscribed >= 100 ||
            (offering.type !== 'Fund Offering' && offering.amount === 0)
              ? 'closed'
              : 'open'
          }
        />
      </div>
      <Flex stack gap={8}>
        <Header variant="h3">{offering.name}</Header>
        <Flex gap={8} className={styles.location}>
          <Icon name={IconName.location} size="md" />
          {cities.length === 1 ? cities[0] : 'Multiple cities'}
        </Flex>
      </Flex>
      <Grid gap={24}>
        <Grid.Item xs={12} sm={6}>
          <Summary name="Investment Type">{offering.type}</Summary>
        </Grid.Item>
        <Grid.Item xs={12} sm={6}>
          <Summary name="Yield">
            {rates.map((rate) => formatPercent(rate)).join(' - ')}
          </Summary>
        </Grid.Item>
        <Grid.Item xs={12} sm={6}>
          <Summary name="Total Raise">{formatUsd(offering.amount)}</Summary>
        </Grid.Item>
      </Grid>
      <Flex gap={8}>
        {offering.classes?.map(({ id, name }) => (
          <Badge color="blue" key={id}>
            {name}
          </Badge>
        ))}
      </Flex>
    </div>
  )
}

export { OfferingItem }
