import Papa from 'papaparse'

const generateCsv = (rows: string[][]) => {
  return Papa.unparse(rows, { skipEmptyLines: 'greedy' })
}

const exportCsv = (filename: string, rows: string[][]) => {
  const csv = generateCsv(rows)
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  let csvURL
  if ((navigator as any).msSaveBlob) {
    csvURL = (navigator as any).msSaveBlob(csvData, filename)
  } else {
    csvURL = window.URL.createObjectURL(csvData)
  }
  const tempLink = document.createElement('a')
  tempLink.href = csvURL
  tempLink.setAttribute('download', filename)
  tempLink.click()
}

export { generateCsv, exportCsv }
