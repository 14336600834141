import clsx from 'clsx'
import { useRef, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import { mergeRefs } from 'react-merge-refs'
import styles from './styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label?: string
}

const Radio = forwardRef(
  (
    { className, label, value, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    const radioRef = useRef<HTMLInputElement>()

    return label ? (
      <label className={clsx(className, styles.label)}>
        <input
          type="radio"
          className={styles.radio}
          ref={mergeRefs([ref, radioRef])}
          checked={value as unknown as boolean}
          {...rest}
        />
        {label}
      </label>
    ) : (
      <input
        type="radio"
        className={clsx(className, styles.radio)}
        ref={mergeRefs([ref, radioRef])}
        checked={value as unknown as boolean}
        {...rest}
      />
    )
  }
)
Radio.displayName = 'Radio'

export { Radio }
