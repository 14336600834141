import DOMPurify from 'dompurify'
import { marked } from 'marked'
import TurndownService from 'turndown'
import { gfm } from 'turndown-plugin-gfm'

const turndownService = new TurndownService()
turndownService.use(gfm)

const markdownToHtml = (markdown: string) => {
  return DOMPurify.sanitize(
    marked.parse(
      /*eslint-disable no-misleading-character-class */
      (markdown ?? '').replace(/^[\u200B\u200C\u200D\u200E\u200F\uFEFF]/, '')
    ) as string
  )
}

const htmlToMarkdown = (html: string) => {
  return turndownService.turndown(html)
}

export { markdownToHtml, htmlToMarkdown }
