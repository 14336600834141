import { TableAmortization } from 'admin/components/table/TableAmortization'
import {
  useLoanSchedule,
  AmortizationPayment,
} from 'admin/hooks/use-loan-schedule'
import { downloadLoanSchedule } from 'admin/services/csv/download-loan-schedule'
import { Download } from 'components/Download'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'

interface Props {
  loan: Loan
}

function PanelAmortization({ loan }: Props) {
  const { visibleItems, result, setPagination } =
    usePagination<AmortizationPayment>({
      property: 'payments',
      useData: ({ pagination }) =>
        useLoanSchedule(loan.id, { page: pagination }),
    })

  return (
    <Panel
      title="Amortization Schedule"
      actionChildren={
        <Download
          filename="schedule"
          download={() => downloadLoanSchedule(loan.id)}
        />
      }
    >
      <TableAmortization
        data={visibleItems}
        loading={result.isPending}
        loan={loan}
      />
      <LoadMore
        loading={result.isPending}
        fetching={result.isFetching}
        count={visibleItems.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
    </Panel>
  )
}

export default PanelAmortization
