import { ColumnDef } from '@tanstack/react-table'
import { isString } from 'lodash'
import { useMemo, Dispatch, SetStateAction } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { pathTo } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { Table } from 'components/Table'
import { Filter, Sort } from 'types/table'
import { formatUsd } from 'utils/currency'
import { formatPhone } from 'utils/phone'

type VendorsTableProps = {
  onDelete: (id: string) => void
  onFilter: Dispatch<SetStateAction<Filter | undefined>>
  filters?: Record<string, string[]>
  filter: Filter | undefined
  data?: Vendor[]
  loading?: boolean
  disabled?: boolean
  showCash?: boolean
  onClick?: (vendor?: Vendor) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
}

function TableVendors({
  onDelete,
  data = [],
  filters = {},
  filter = {},
  onFilter,
  sort,
  onSort,
  loading,
  disabled,
  showCash = true,
  onClick,
}: VendorsTableProps) {
  const columns: ColumnDef<Vendor>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row }) => {
          return (
            <Flex gap={8} alignItems="center">
              <div className="inline-flex items-center justify-center bg-grey-75 text-grey-600 w-6 h-6 rounded-sm flex-shrink-0">
                <Icon
                  name={
                    row.original?.type === 'entity'
                      ? IconName.company
                      : IconName.person
                  }
                />
              </div>
              <div className="truncate">{row.original.name || '-'}</div>
            </Flex>
          )
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Associated With',
        accessorKey: 'associatedWith',
        size: 204,
        cell: ({ row }) => {
          return (
            <AssociatedBadges
              associatedWith={row.original.associatedWith}
              getUrlById={(id: string) => pathTo('vendor', id)}
            />
          )
        },
      },
      {
        header: 'Phone',
        accessorKey: 'phone',
        cell: ({ getValue }) => (
          <span>{formatPhone(getValue() as string) || '-'}</span>
        ),
      },
      {
        header: 'Email',
        accessorKey: 'email',
        cell: ({ getValue }) => {
          const email = getValue()
          return isString(email) ? LinkEmail({ email }) : '-'
        },
      },
      {
        header: 'Role',
        accessorKey: 'role',
        cell: ({ getValue }) => getValue() || '-',
        meta: {
          filter: {
            options: (filters.role || []).map((role) => ({
              value: role,
              label: role,
            })),
            selected: filter?.role,
            onChange: (role) => onFilter({ role }),
          },
        },
      },
      ...(showCash
        ? [
            {
              header: 'Cash',
              accessorKey: 'cashBalance',
              cell: ({ getValue }) => formatUsd(getValue() as string),
              meta: {
                align: 'right',
                sortable: true,
              },
            },
          ]
        : []),
      {
        header: '',
        accessorKey: 'id',
        size: 42,
        cell: ({ getValue }) => {
          return disabled ? null : (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(getValue() as string)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [filters, onFilter]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableVendors
