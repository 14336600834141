import { NavLink } from 'react-router-dom'
import { isLoanServicing, pathTo as adminPathTo } from 'admin/path-to'
import { pathTo as borrowerPathTo } from 'borrower/path-to'
import { BadgeBudgetStatus } from 'components/Badge'
import { Button } from 'components/Button'
import { Drawer } from 'components/Drawer'
import { Flex } from 'components/Flex'
import { ModalImage, useModalImage } from 'components/ModalImage'
import { BudgetDraw, Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  addressId: string
  draw: BudgetDraw
  lender?: boolean
  onConfirm: () => void
  onClose: () => void
}

const DrawerPreviewDraw = ({
  loan,
  addressId,
  draw,
  lender,
  onConfirm,
  onClose,
}: Props) => {
  const {
    isImagesModalVisible,
    imageUrls,
    imageIndex,
    handleImageClick,
    handleClose,
  } = useModalImage()
  const pathToDraw = lender
    ? (...params) =>
        adminPathTo(
          isLoanServicing(loan.status) ? 'servicingLoanDraw' : 'loanDraw',
          ...params
        )
    : (...params) => borrowerPathTo('loanDraw', ...params)

  return (
    <>
      <Drawer onClose={onClose}>
        <Drawer.Header
          title="Preview & Send"
          onClose={onClose}
          className="h-auto pb-4"
        >
          <Flex
            className={styles.requestedAmount}
            justifyContent="space-between"
          >
            <div className={styles.label}>Total Amount</div>
            <div className={styles.value}>
              {formatUsd(
                sumDecimal(draw.budgetActivities.map((a) => a.amount || 0))
              )}
            </div>
          </Flex>
        </Drawer.Header>
        <Drawer.Content>
          <Flex stack gap={24}>
            {draw.budgetActivities.map((activity) => (
              <Flex key={activity.id} stack gap={8}>
                <Flex stack gap={4}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <BadgeBudgetStatus status={activity.status} />
                  </Flex>
                  <Flex
                    className={styles.budgetItem}
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div>{activity.budgetItem?.name}</div>
                    <div>{formatUsd(activity.amount)}</div>
                  </Flex>
                  <div>{activity.description}</div>
                </Flex>
                <div className={styles.images}>
                  {activity.images.map((image) => (
                    <div
                      key={image.viewUrl}
                      style={{ backgroundImage: `url(${image.viewUrl})` }}
                      onClick={(e) =>
                        handleImageClick(
                          e,
                          activity.images.map(({ viewUrl }) => viewUrl),
                          image.viewUrl
                        )
                      }
                      className={styles.image}
                    />
                  ))}
                </div>
                <Flex gap={4} className={styles.activityDate}>
                  {friendlyDate(activity.createdAt)}
                  {activity.budgetDrawId !== draw.id && (
                    <>
                      <div>•</div>
                      <div>
                        Part of{' '}
                        <NavLink
                          className={styles.linkDraw}
                          to={pathToDraw(
                            loan.id,
                            addressId,
                            activity.budgetDrawId
                          )}
                          onClick={onClose}
                        >
                          {friendlyDate(activity.budgetDraw.dateSubmitted)}
                        </NavLink>
                      </div>
                    </>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Drawer.Content>
        <Drawer.Footer>
          <Button className={styles.confirmButton} onClick={onConfirm}>
            {lender ? 'Approve Draw' : 'Send Draw Request'}
          </Button>
        </Drawer.Footer>
      </Drawer>
      {isImagesModalVisible && (
        <ModalImage
          onClose={handleClose}
          imageUrls={imageUrls}
          index={imageIndex}
        />
      )}
    </>
  )
}

export { DrawerPreviewDraw }
