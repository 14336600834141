import { capitalize } from 'lodash'
import { PaymentDue } from 'types'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: PaymentDue['status']
}

const statusToColor: Record<
  PaymentDue['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  due: 'yellow',
  late: 'red',
}

function PaymentDueStatusBadge({ status }: Props) {
  return <Badge color={statusToColor[status]}>{capitalize(status)}</Badge>
}

export default PaymentDueStatusBadge
