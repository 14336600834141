import { requestWithoutToken } from 'services/request'
import { Quote } from 'types'
import { updateResponseQuote } from 'utils/api/quote-parser'

const getQuoteVersion = async (quoteId: string): Promise<Quote> => {
  const {
    data: { quote },
  } = await requestWithoutToken.get(`/quote/${quoteId}/version`)
  return updateResponseQuote(quote)
}

export { getQuoteVersion }
