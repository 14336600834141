import { FloatingTree } from '@floating-ui/react'
import Description from './Description'
import Item from './Item'
import Menu, { MenuProps } from './Menu'
import MenuItem from './MenuItem'
import Section from './Section'

function EllipsesActions(props: MenuProps) {
  return (
    <FloatingTree>
      <Menu {...props} />
    </FloatingTree>
  )
}

EllipsesActions.MenuItem = MenuItem
EllipsesActions.Item = Item
EllipsesActions.Section = Section
EllipsesActions.Description = Description

export default EllipsesActions
