import { useState } from 'react'
import { TableFundFunding } from 'admin/components/table/TableFundFunding'
import {
  useFundInvestors,
  useDeleteFundInvestment,
} from 'admin/hooks/use-fund-investors'
import { downloadFundFunding } from 'admin/services/csv/download-fund-funding'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalInvestmentHistory } from 'components/Modal/InvestmentHistory'
import { Panel } from 'components/Panel'
import { Fund, FundInvestor } from 'types'
import { ModalFunding } from './ModalFunding'

interface Props {
  fund: Fund
}

function TabFunding({ fund }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingFundInvestorId, setEditingFundInvestorId] = useState<string>()
  const [deletingFundInvestorId, setDeletingFundInvestorId] = useState<string>()
  const { data: investors, isPending } = useFundInvestors(fund.id)
  const { mutate: deleteFundInvestment, isPending: deleting } =
    useDeleteFundInvestment(fund.id)
  const [investorDetails, setInvestorDetails] = useState<FundInvestor>()

  return (
    <div>
      <Panel
        title="Funding Sources"
        actionChildren={
          <>
            <Download
              filename="funding"
              btnName="Export"
              variant="tertiary"
              download={() => downloadFundFunding(fund.id)}
            />
            <Button onClick={() => setIsModalVisible(true)}>Add Funding</Button>
          </>
        }
      >
        <TableFundFunding
          data={investors}
          loading={isPending}
          onClick={(fundInvestor) => {
            if (fundInvestor) {
              setInvestorDetails({
                ...fundInvestor,
                fund,
              } as FundInvestor)
            }
          }}
          onDelete={(id) => setDeletingFundInvestorId(id)}
          onEdit={(id) => {
            setEditingFundInvestorId(id)
            setIsModalVisible(true)
          }}
        />
      </Panel>
      {isModalVisible && (
        <ModalFunding
          fundId={fund.id}
          fundInvestor={
            investors?.find(
              ({ fundInvestors }) => fundInvestors.id === editingFundInvestorId
            )?.fundInvestors
          }
          onClose={() => {
            setEditingFundInvestorId(undefined)
            setIsModalVisible(false)
          }}
        />
      )}
      {investorDetails && (
        <ModalInvestmentHistory
          fundId={fund.id}
          fundInvestor={{
            name: investorDetails.name,
            id: investorDetails.id,
            investmentId: investorDetails.fundInvestors.id,
          }}
          onCancel={() => setInvestorDetails(undefined)}
        />
      )}
      {deletingFundInvestorId && (
        <ModalDelete
          resource="funding source"
          loading={deleting}
          onDelete={() =>
            deleteFundInvestment(deletingFundInvestorId, {
              onSuccess: () => {
                setDeletingFundInvestorId(undefined)
              },
            })
          }
          onCancel={() => setDeletingFundInvestorId(undefined)}
        />
      )}
    </div>
  )
}

export { TabFunding }
