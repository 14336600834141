import { useParams } from 'react-router-dom'
import { useOffering } from 'admin/hooks/use-offerings'
import { MainContent } from 'borrower/components/layout/MainContent'
import { PageLoader } from 'components/LoaderOverlay'
import { OfferingLoanView } from './OfferingLoanView'

function OfferingLoan() {
  const { offeringId: id } = useParams() as { offeringId: string }
  const { data } = useOffering({ id })
  const offering = data?.offering
  const fundingSources = data?.fundingSources

  return (
    <MainContent className="!max-w-none p-0">
      {offering ? (
        <OfferingLoanView offering={offering} fundingSources={fundingSources} />
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { OfferingLoan }
