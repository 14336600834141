import { request } from 'services/request'
import { LoanDocument } from 'types'

const getLoanDocuments = async ({
  id,
}: {
  id: string
}): Promise<LoanDocument[]> => {
  const {
    data: { documents },
  } = await request.get(`/loan/${id}/document`)
  return documents
}

export { getLoanDocuments }
