import { Formik } from 'formik'
import { useMemo, useCallback } from 'react'
import { useTemplates } from 'admin/hooks/use-templates'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Select } from 'components/Form'
import { Modal } from 'components/Modal'
import { createScheme, required } from 'utils/schemas'

const Schema = createScheme({
  templateId: required,
})

type FormValues = {
  templateId: string
}

interface Props {
  templateId: string | undefined
  saving: boolean
  onGenerate: (templateId: string) => void
  onClose: () => void
}

const ModalGenerateDocument = ({
  templateId,
  saving,
  onGenerate,
  onClose,
}: Props) => {
  const { data: customTemplates, isPending } = useTemplates()
  const options = useMemo(
    () =>
      customTemplates?.map((template) => ({
        value: template.id,
        label: template.name,
      })) || [],
    [customTemplates]
  )
  const initialValue: FormValues = {
    templateId: templateId as string,
  }
  const onSubmit = useCallback(
    (values: FormValues) => {
      onGenerate(values.templateId)
    },
    [onGenerate]
  )

  return (
    <Modal
      title="Generate Loan Modification Agreement"
      loading={isPending}
      onClose={onClose}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Flex stack gap={8}>
            <div>Template</div>
            <Select name="templateId" options={options} portal />
          </Flex>

          <Flex justifyContent="flex-end" alignItems="center">
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button loading={saving} type="submit">
              Generate Agreement
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalGenerateDocument }
