import { capitalize } from 'lodash'
import { Offering } from 'types'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface Props {
  status: Offering['status']
}

const statusToColor: Record<
  Offering['status'],
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  Draft: 'gray',
  Published: 'green',
}

function OfferingStatusBadge({ status }: Props) {
  return <Badge color={statusToColor[status]}>{capitalize(status)}</Badge>
}

export { OfferingStatusBadge }
