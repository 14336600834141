import { useState } from 'react'
import { ImportEntity } from 'admin/components/ImportEntity'
import { TableCharges } from 'admin/components/table/TableCharges'
import {
  useLoanCharges,
  useDeleteLoanCharge,
} from 'admin/hooks/use-loan-charge'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { ModalAddCharge } from 'components/Modal/AddCharge'
import { ModalChargesHistory } from 'components/Modal/ChargesHistory'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { CHARGE_IMPORT_BANNER } from 'constants/local-storage-keys'
import { Loan } from 'types'
import { Charge } from 'types/charge'
import { AlertCharges } from './AlertCharges'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

export default function TabCharges({ loan }: Props) {
  const [chargeHistory, setChargeHistory] = useState<Charge>()
  const [addingCharge, setAddingCharge] = useState(false)
  const [editingCharge, setEditingCharge] = useState<Charge>()
  const [deletingChargeId, setDeletingChargeId] = useState<string>()
  const { data: charges, isPending } = useLoanCharges(loan.id)
  const { mutate: remove, isPending: isRemoving } = useDeleteLoanCharge(loan.id)

  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(CHARGE_IMPORT_BANNER)
  )

  return (
    <div className={styles.tabContent}>
      <Panel
        title="Charges"
        actionChildren={
          !loan.lock && (
            <Flex>
              {!loan.lock && (
                <ImportEntity
                  entityType="charge"
                  loanId={loan.id}
                  setAlertData={() => setAlertData(true)}
                />
              )}
              <Button
                className={styles.btnActions}
                disabled={loan.lock}
                onClick={() => setAddingCharge(true)}
              >
                Add Charge
              </Button>
            </Flex>
          )
        }
      >
        {alertData && (
          <AlertCharges alertData={alertData} setAlertData={setAlertData} />
        )}
        <TableCharges
          charges={charges}
          loading={isPending}
          onRowClick={
            loan.lock ? undefined : (charge) => setEditingCharge(charge)
          }
          onHistory={(charge) => setChargeHistory(charge)}
          onEdit={loan.lock ? undefined : (charge) => setEditingCharge(charge)}
          onDelete={
            loan.lock ? undefined : (chargeId) => setDeletingChargeId(chargeId)
          }
        />
      </Panel>
      {chargeHistory && (
        <ModalChargesHistory
          loanId={loan.id}
          charge={chargeHistory}
          onCancel={() => setChargeHistory(undefined)}
        />
      )}
      {(addingCharge || editingCharge) && (
        <ModalAddCharge
          loanId={loan.id}
          charge={editingCharge}
          onCancel={() => {
            setAddingCharge(false)
            setEditingCharge(undefined)
          }}
        />
      )}
      {deletingChargeId && (
        <ModalDelete
          resource="charge"
          loading={isRemoving}
          onDelete={() =>
            remove(deletingChargeId, {
              onSuccess: () => {
                setDeletingChargeId(undefined)
              },
            })
          }
          onCancel={() => setDeletingChargeId(undefined)}
        />
      )}
    </div>
  )
}
