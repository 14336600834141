import clsx from 'clsx'
import { take, get, compact } from 'lodash'
import { Product } from 'admin/services/api/products'
import { EllipsesActions } from 'components/EllipsesActions'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

type Row = Product & {
  onRename: (id: string) => void
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
}

type Props = {
  data: Row[]
  onClick: (product: Product) => void
}

const getAcronym = (name: string) => {
  return take(name.split(' '), 3)
    .map((word) => word.replace(/[^a-zA-Z0-9]/gi, ''))
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
}

function TableProducts({ data = [], onClick }: Props) {
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')

  return (
    <div className={styles.products}>
      {data.map((product) => {
        const isEditable = product.clientId === clientId

        return (
          <div
            key={product.id}
            className={clsx(styles.product, !isEditable && styles.disabled)}
          >
            <div
              className={styles.icon}
              onClick={isEditable ? () => onClick(product) : undefined}
            >
              <Header variant="h1">{getAcronym(product.name)}</Header>
            </div>
            <div className={styles.details}>
              <div
                className={styles.name}
                onClick={isEditable ? () => onClick(product) : undefined}
              >
                <Icon name={IconName.documentBlank} />{' '}
                {isEditable
                  ? product.name
                  : compact([product.name, product.client?.name]).join(' - ')}
              </div>
              {isEditable && (
                <EllipsesActions>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => product.onRename(product.id)}
                  >
                    <Icon name={IconName.edit} />
                    Edit
                  </EllipsesActions.Item>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => product.onDuplicate(product.id)}
                  >
                    <Icon name={IconName.duplicate} />
                    Duplicate
                  </EllipsesActions.Item>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => product.onDelete(product.id)}
                    className="text-red-100"
                  >
                    <Icon name={IconName.delete} />
                    Delete
                  </EllipsesActions.Item>
                </EllipsesActions>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TableProducts
