import clsx from 'clsx'
import { compact, debounce, toNumber } from 'lodash'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useUpdateQuoteOption } from 'admin/hooks/use-quote-options'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { ModalQuoteFee } from 'components/Modal/QuoteFee'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Quote } from 'types'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
}

const BrokerCompensationPanel = ({ quote }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [compensation, setCompensation] = useState<Quote['compensation']>(
    quote.compensation
  )
  const { mutate: update, isPending: saving } = useUpdateQuoteOption(quote.id)
  const debouncedUpdate = debounce(update, 1000)
  const updateFee = useCallback(
    (name, value) => {
      if (
        ['compensationOriginationFee', 'compensationYieldSpread'].includes(name)
      ) {
        setCompensation({
          ...(compensation || {}),
          [name]: toNumber(value),
        })
      } else {
        const fees = compensation?.fees || []
        const fee = fees.find(({ name: feeName }) => feeName === name)
        setCompensation({
          ...(compensation || {}),
          fees: compact([
            ...(compensation?.fees || []).map(({ name: feeName, amount }) => ({
              name: feeName,
              amount: feeName === name ? value : amount,
            })),
            fee ? undefined : { name, amount: toNumber(value) },
          ]),
        })
      }
    },
    [compensation]
  )
  const removeFee = useCallback(
    (feeName) => {
      setCompensation({
        ...(compensation || {}),
        fees: compensation.fees?.filter(({ name }) => name !== feeName),
      })
    },
    [compensation]
  )

  useEffect(() => {
    debouncedUpdate(compensation)
  }, [compensation])

  return (
    <Panel>
      <Flex stack gap={16}>
        <Header variant="h4">Broker Compensation</Header>
        <Grid gap={8} className={styles.brokerCompensationGrid}>
          <Grid.Item xs={6}>Origination Fee</Grid.Item>
          <Grid.Item xs={6}>
            <Flex alignItems="center" gap={4}>
              <IconInput
                type="percentage"
                defaultValue={compensation?.compensationOriginationFee}
                supportNegative={true}
                onChange={(e) =>
                  updateFee('compensationOriginationFee', e.target.value)
                }
              />
              <div className={clsx(styles.iconRemoveFee, styles.disabled)}>
                <Icon name={IconName.minus} size="sm" />
              </div>
            </Flex>
          </Grid.Item>
          <Grid.Item xs={6}>Yield Spread</Grid.Item>
          <Grid.Item xs={6}>
            <Flex alignItems="center" gap={4}>
              <IconInput
                type="percentage"
                defaultValue={compensation?.compensationYieldSpread}
                supportNegative={true}
                onChange={(e) =>
                  updateFee('compensationYieldSpread', e.target.value)
                }
              />
              <div className={clsx(styles.iconRemoveFee, styles.disabled)}>
                <Icon name={IconName.minus} size="sm" />
              </div>
            </Flex>
          </Grid.Item>
          {compensation?.fees?.map(({ name, amount }) => (
            <Fragment key={name}>
              <Grid.Item xs={6}>{name}</Grid.Item>
              <Grid.Item xs={6}>
                <Flex alignItems="center" gap={4}>
                  <IconInput
                    type="currency"
                    defaultValue={amount}
                    onChange={(e) => updateFee(name, e.target.value)}
                  />
                  <div
                    className={styles.iconRemoveFee}
                    onClick={() => removeFee(name)}
                  >
                    <Icon name={IconName.minus} size="sm" />
                  </div>
                </Flex>
              </Grid.Item>
            </Fragment>
          ))}
        </Grid>
        <div>
          <TextLink onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" />
            Add additional fees
          </TextLink>
        </div>
      </Flex>
      {isModalVisible && (
        <ModalQuoteFee
          saving={saving}
          onSave={({ name, amount }) => {
            updateFee(name, amount)
            setIsModalVisible(false)
          }}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}

export { BrokerCompensationPanel }
