import { toString } from 'lodash'
import { useState } from 'react'
import { useOfferingClasses } from 'admin/hooks/use-offering-class'
import {
  useOfferingFundingSources,
  useAddOfferingInvestor,
  useEditOfferingInvestor,
  useDeleteOfferingInvestor,
} from 'admin/hooks/use-offering-investor'
import { Button } from 'components/Button'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Offering } from 'types'
import { ModalFunding } from './ModalFunding'
import { TableFunding } from './TableFunding'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
}

function TabFunding({ offering }: Props) {
  const [adding, setAdding] = useState(false)
  const [editingId, setEditingId] = useState<string>()
  const [removingId, setRemovingId] = useState<string>()
  const { data: fundingSources, isPending } = useOfferingFundingSources(
    offering.id
  )
  const { mutate: add, isPending: isAdding } = useAddOfferingInvestor(
    offering.id
  )
  const { mutate: edit, isPending: isEditing } = useEditOfferingInvestor(
    offering.id
  )
  const { mutate: remove, isPending: isRemoving } = useDeleteOfferingInvestor(
    offering.id
  )
  const { data: classes } = useOfferingClasses(offering.id)
  const tranches = classes?.classes as unknown as {
    name: string
    rate: string
  }[]

  return (
    <div className={styles.tabContent}>
      <Button className={styles.tabButton} onClick={() => setAdding(true)}>
        Add Funding
      </Button>
      <Panel title="Funding">
        {isPending ? (
          <PageLoader />
        ) : (
          <>
            <TableFunding
              data={fundingSources}
              loading={isPending}
              onDelete={(fundingId) => setRemovingId(fundingId)}
            />
            <div className={styles.addFunding}>
              <TextLink onClick={() => setAdding(true)}>
                <Icon name={IconName.plus} size="sm" /> Add Funding
              </TextLink>
            </div>
          </>
        )}
      </Panel>
      {(adding || editingId) && (
        <ModalFunding
          fundingSource={fundingSources?.find(({ id }) => id === editingId)}
          tranches={tranches}
          tranchesWeightedAverage={toString(classes?.weightedAverage)}
          onSave={(values) => {
            editingId
              ? edit(
                  { id: editingId, ...values },
                  {
                    onSuccess: () => {
                      setEditingId(undefined)
                    },
                  }
                )
              : add(values, {
                  onSuccess: () => {
                    setAdding(false)
                  },
                })
          }}
          onCancel={() => {
            setAdding(false)
            setEditingId(undefined)
          }}
          saving={isAdding || isEditing}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="funding"
          loading={isRemoving}
          onDelete={() =>
            remove(removingId, {
              onSuccess: () => {
                setRemovingId(undefined)
              },
            })
          }
          onCancel={() => setRemovingId(undefined)}
        />
      )}
    </div>
  )
}

export { TabFunding }
