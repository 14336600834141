import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { CLEAR_EDITOR_COMMAND } from 'lexical'
import { useEffect } from 'react'

interface Props {
  text: string
}

const ClearPlugin = ({ text }: Props) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    if (!text) {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
    }
  }, [text])

  return <ClearEditorPlugin />
}

export { ClearPlugin }
