import { useNavigate } from 'react-router-dom'
import { OfferingItem } from 'borrower/components/OfferingItem'
import { PageTop } from 'borrower/components/PageTop'
import { MainContent } from 'borrower/components/layout/MainContent'
import { useOfferings } from 'borrower/hooks/use-offerings'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { LoadMore } from 'components/LoadMore'
import { PageLoader } from 'components/LoaderOverlay'
import { usePagination } from 'hooks/use-pagination'
import { Offering } from 'types'
import styles from './styles.module.scss'

function Offerings() {
  const navigate = useNavigate()
  const {
    visibleItems: openVisibleItems,
    result: openResult,
    setPagination: setOpenPagination,
  } = usePagination<Offering>({
    property: 'offerings',
    useData: (params) => useOfferings({ type: 'open', ...params }),
  })
  const {
    visibleItems: closedVisibleItems,
    result: closedResult,
    setPagination: setClosedPagination,
  } = usePagination<Offering>({
    property: 'offerings',
    useData: (params) => useOfferings({ type: 'closed', ...params }),
  })
  const handleClick = (offering: Offering) => {
    const url = pathTo(
      offering.type === 'Fund Offering' ? 'offeringFund' : 'offeringLoan',
      offering.id
    )
    navigate(url)
  }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Offerings" hideTopMenu={true} />
        <Flex stack gap={40}>
          <Flex stack gap={32}>
            {openResult.isPending && (
              <div className={styles.container}>
                <PageLoader />
              </div>
            )}
            {!openResult.isPending && !!openVisibleItems.length && (
              <Grid gap={24}>
                {openVisibleItems.map((offering) => (
                  <Grid.Item key={offering.id} xs={12} sm={6} md={4}>
                    <OfferingItem
                      key={offering.id}
                      offering={offering}
                      onClick={handleClick}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            )}
            {!openResult.isPending && !openVisibleItems.length && (
              <div className={styles.container}>
                There are no offerings available
              </div>
            )}
            <LoadMore
              hidePagination
              loading={openResult.isPending}
              fetching={openResult.isFetching}
              count={openVisibleItems.length || 0}
              meta={openResult.data?.meta}
              onLoadMore={setOpenPagination}
            />
          </Flex>

          <Flex stack gap={32}>
            <Header variant="h2">Previous Offerings</Header>
            {closedResult.isPending && (
              <div className={styles.container}>
                <PageLoader />
              </div>
            )}
            {!closedResult.isPending && !!closedVisibleItems.length && (
              <Grid gap={24}>
                {closedVisibleItems.map((offering) => (
                  <Grid.Item key={offering.id} xs={12} sm={6} md={4}>
                    <OfferingItem
                      key={offering.id}
                      offering={offering}
                      onClick={handleClick}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            )}
            {!closedResult.isPending && !closedVisibleItems.length && (
              <div className={styles.empty}>
                There are no offerings available
              </div>
            )}
            <LoadMore
              hidePagination
              loading={closedResult.isPending}
              fetching={closedResult.isFetching}
              count={closedVisibleItems.length || 0}
              meta={closedResult.data?.meta}
              onLoadMore={setClosedPagination}
            />
          </Flex>
        </Flex>
      </Flex>
    </MainContent>
  )
}

export { Offerings }
