import {
  useQuery,
  keepPreviousData,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query'
import {
  getCustomReport,
  getCustomReports,
  addCustomReport,
  updateCustomReport,
  removeCustomReport,
} from 'admin/services/api/custom-report'
import { KEY_CUSTOM_REPORT } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'
import { message } from 'utils/message'

interface Props {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
}

const useCustomReports = ({ pagination, sort }: Props = {}) => {
  return useQuery({
    queryKey: [KEY_CUSTOM_REPORT, pagination, sort],
    queryFn: () => getCustomReports({ page: pagination, sort }),
    placeholderData: keepPreviousData,
  })
}

const useCustomReport = (id: string) => {
  return useQuery({
    queryKey: [KEY_CUSTOM_REPORT, id],
    queryFn: () => getCustomReport(id),
  })
}

const useAddCustomReport = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addCustomReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_REPORT] })
      message.success('Report saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateCustomReport = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      id,
      ...payload
    }: {
      id: string
      name?: string
      description?: string
      config?: string
    }) => updateCustomReport(id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_REPORT] })
      message.success('Report saved')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveCustomReport = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeCustomReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_REPORT] })
      message.success('Report deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useCustomReports,
  useCustomReport,
  useAddCustomReport,
  useUpdateCustomReport,
  useRemoveCustomReport,
}
