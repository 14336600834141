import { request } from 'services/request'
import { LoanDocument } from 'types'

const getInvestorDocuments = async ({
  id,
  isBorrower,
}: {
  id: string
  isBorrower?: boolean
}): Promise<LoanDocument[]> => {
  const userRole = isBorrower ? 'borrower' : 'investor'
  const {
    data: { documents },
  } = await request.get(`/${userRole}/${id}/document`)
  return documents
}

const addInvestorDocument = async (
  id: string,
  document: Partial<LoanDocument>
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.post(`/investor/${id}/document`, document)
  return doc
}

export { getInvestorDocuments, addInvestorDocument }
