import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Role } from 'types'
import { pluralize } from 'utils/pluralize'

type Props = {
  data?: Role[]
  loading?: boolean
  onEdit: (id: string) => void
  onDelete: (id: string) => void
}

function TableRoles({ data = [], loading, onEdit, onDelete }: Props) {
  const columns: ColumnDef<Role>[] = useMemo(
    () => [
      {
        header: 'Role',
        accessorKey: 'name',
        cell: ({ getValue }) => <b>{getValue() as string}</b>,
      },
      {
        header: 'Assigned to',
        accessorKey: 'admins',
        cell: ({ row }) =>
          row.original.admins ? (
            <Badge>
              {pluralize(row.original.admins.length, 'Team Member')}
            </Badge>
          ) : null,
      },

      {
        header: '',
        accessorKey: 'actions',
        size: 40,
        cell: ({ row }) => {
          const { id } = row.original
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!!row.original.admins?.length}
                variant="danger"
                onSelect={() => onDelete(id)}
              >
                <Icon name={IconName.delete} />
                Remove
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
        meta: {
          align: 'right',
        },
      },
    ],
    [onEdit, onDelete]
  )

  return (
    <Table
      columns={columns}
      data={data}
      onClick={(role) => onEdit(role?.id as string)}
      loading={loading}
    />
  )
}

export { TableRoles }
