import { findIndex } from 'lodash'
import { useCallback, useState } from 'react'
import { useFundPortfolio } from 'admin/hooks/use-funds'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { PageLoader } from 'components/LoaderOverlay'
import { Modal } from 'components/Modal'
import { usePagination } from 'hooks/use-pagination'
import imagePlaceholder from 'images/offering-placeholder.png'
import { FundPortfolio } from 'types'
import { formatDate } from 'utils/date'
import { formatPercent as transformPercent } from 'utils/math'
import { formatPercent } from 'utils/percent'
import { TableFundPortfolio } from './TableFundPortfolio'

interface Props {
  fundId: string
}

function TabPortfolio({ fundId }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedPortfolioIndex, setSelectedPortfolioIndex] =
    useState<number>(0)
  const { visibleItems, result, sort, setPagination, setSort } =
    usePagination<FundPortfolio>({
      property: 'portfolio',
      useData: (params) => useFundPortfolio({ fundId, ...params }),
    })
  const selectedPortfolio = visibleItems?.[
    selectedPortfolioIndex
  ] as FundPortfolio
  const handlePrevPortfolio = useCallback(() => {
    setSelectedPortfolioIndex((index) => index - 1)
  }, [])
  const handleNextPortfolio = useCallback(() => {
    setSelectedPortfolioIndex((index) => index + 1)
  }, [])

  return result.isLoading ? (
    <PageLoader />
  ) : (
    <>
      <TableFundPortfolio
        data={visibleItems}
        sort={sort}
        onSort={setSort}
        loading={result.isPending}
        onClick={(portfolio) => {
          setIsModalOpen(true)
          setSelectedPortfolioIndex(findIndex(visibleItems, portfolio))
        }}
      />
      <LoadMore
        loading={result.isPending}
        fetching={result.isFetching}
        count={visibleItems.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {isModalOpen && selectedPortfolio && (
        <Modal
          className="p-4 max-w-[1127px] w-[80%] mx-0"
          onClose={() => setIsModalOpen(false)}
          closeOnClickOutside
          asChild
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Header variant="h3">{selectedPortfolio.name}</Header>
            <Flex>
              <Button
                variant="ghost"
                disabled={selectedPortfolioIndex === 0}
                onClick={handlePrevPortfolio}
              >
                <Icon name={IconName.arrowLeftLong} />
              </Button>
              <Button
                variant="ghost"
                disabled={selectedPortfolioIndex === visibleItems.length - 1}
                onClick={handleNextPortfolio}
              >
                <Icon name={IconName.arrowLeftLong} className="rotate-180" />
              </Button>
            </Flex>
          </Flex>
          <Flex flexWrap="wrap">
            <img
              className="flex-grow object-contain max-w-full md:max-w-[80%]"
              src={selectedPortfolio.image?.url || imagePlaceholder}
            />
            <Flex stack gap={34} className="min-w-[180px] flex-shrink-0">
              <Flex stack gap={4}>
                <div>Loan Type</div>
                <Header variant="h3">
                  {selectedPortfolio.loanType || '-'}
                </Header>
              </Flex>
              <Flex stack gap={4}>
                <div>LTV Ratio</div>
                <Header variant="h3">
                  {formatPercent(transformPercent(selectedPortfolio.ltv || 0))}
                </Header>
              </Flex>
              <Flex stack gap={4}>
                <div>Maturity Date</div>
                <Header variant="h3">
                  {formatDate(selectedPortfolio.dateMaturity)}
                </Header>
              </Flex>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}

export { TabPortfolio }
