import { isNil } from 'lodash'
import { useState, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  useLoanAddressUnitDetails,
  useUpdateLoanAddressUnitDetails,
} from 'admin/hooks/use-loan-address-unit-details'
import { pathToLoan } from 'admin/path-to'
import { generateCsv } from 'admin/services/csv/generate-csv'
import { parseCsv } from 'admin/services/csv/parse-csv'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { LoanFields } from 'components/Modal/LoanFields'
import { Search } from 'components/Search'
import { TextLink } from 'components/TextLink'
import { Address, Field, Loan } from 'types'
import { getFieldsBySection } from 'utils/fields'
import { formatPercent, parsePercent } from 'utils/math'
import { TableUnitDetails } from './TableUnitDetails'

interface Props {
  loan: Loan
  address: Address
}

const UnitDetails = ({ loan, address }: Props) => {
  const navigate = useNavigate()
  const [search, setSearch] = useState<string>()
  const [isUnitModalVisible, setIsUnitModalVisible] = useState(false)
  const [editingRowIndex, setEditingRowIndex] = useState<number>()
  const [deletingRowIndex, setDeletingRowIndex] = useState<number>()
  const { data: csv, isLoading } = useLoanAddressUnitDetails({
    loanId: loan.id,
    addressId: address.id,
  })
  const { mutate: updateCsv, isPending: saving } =
    useUpdateLoanAddressUnitDetails({
      loanId: loan.id,
      addressId: address.id,
    })
  const unitFields = useMemo(
    () => getFieldsBySection(loan.fields, 'Unit Details'),
    [loan.fields]
  )
  const jsonCsv = useMemo(
    () => (csv?.unitDetailsCsv ? parseCsv(csv.unitDetailsCsv) : null),
    [csv?.unitDetailsCsv]
  )
  const rows = useMemo(() => {
    const percentFieldNames = unitFields
      .filter((field) => field.type.includes('percentage'))
      .map((field) => field.name)
    if (jsonCsv) {
      return jsonCsv?.rows
        .filter((row) =>
          Object.values(row).some((value) =>
            search
              ? value?.toString().toLowerCase().includes(search?.toLowerCase())
              : true
          )
        )
        .map((row, index) => ({
          ...row,
          ...Object.fromEntries(
            percentFieldNames.map((name) => [
              name,
              row[name] ? formatPercent(row[name]) : '',
            ])
          ),
          _index: index,
        })) as Record<string, string | number>[]
    }
    return []
  }, [jsonCsv, unitFields, search])

  const handleAddUnit = useCallback(() => setIsUnitModalVisible(true), [])
  const handleOpenWorkout = useCallback(
    () => navigate(pathToLoan(loan, 'loanUnitSheet', address.id)),
    [loan, address, navigate]
  )
  const handleDelete = useCallback(() => {
    const percentFieldNames = unitFields
      .filter((field) => field.type.includes('percentage'))
      .map((field) => field.name)
    const header = unitFields.map((field) => field.name)
    const nextRows = rows.filter((_, i) => i !== deletingRowIndex)

    updateCsv(
      generateCsv([
        header,
        ...nextRows.map(
          (row) =>
            Object.values({
              ...row,
              ...Object.fromEntries(
                percentFieldNames.map((name) => [
                  name,
                  row[name] ? parsePercent(row[name]) : '',
                ])
              ),
            }) as string[]
        ),
      ]),
      {
        onSuccess: () => {
          setDeletingRowIndex(undefined)
        },
      }
    )
  }, [rows, deletingRowIndex, unitFields, updateCsv])
  const handleSave = useCallback(
    (updatedFields: Field[]) => {
      const percentFieldNames = unitFields
        .filter((field) => field.type.includes('percentage'))
        .map((field) => field.name)
      const header = updatedFields.map((field) => field.name)
      const row = Object.fromEntries(
        updatedFields.map((field) => [field.name, field.property.value[0]])
      )
      const nextCsvRows = isNil(editingRowIndex)
        ? [...rows, row]
        : [
            ...rows.map((originalRow, index) =>
              index === editingRowIndex ? row : originalRow
            ),
          ]
      updateCsv(
        generateCsv([
          header,
          ...nextCsvRows.map(
            (row) =>
              Object.values({
                ...row,
                ...Object.fromEntries(
                  percentFieldNames.map((name) => [
                    name,
                    row[name] ? parsePercent(row[name]) : '',
                  ])
                ),
              }) as string[]
          ),
        ]),
        {
          onSuccess: () => {
            setEditingRowIndex(undefined)
            setIsUnitModalVisible(false)
          },
        }
      )
    },
    [jsonCsv, rows, unitFields, editingRowIndex]
  )

  return (
    <>
      <Flex stack gap={20} className="mt-5">
        <Header variant="h4">Unit Details</Header>
        {jsonCsv?.rows.length === 0 ? (
          <Flex
            justifyContent="center"
            alignItems="center"
            gap={10}
            className="border border-dashed border-grey-200 rounded bg-grey-50 py-8"
          >
            <Button variant="secondary" onClick={handleAddUnit}>
              Add Unit
            </Button>
            <Button variant="secondary" onClick={handleOpenWorkout}>
              Open Worksheet
              <Icon name={IconName.linkExternal} className="text-grey-600" />
            </Button>
          </Flex>
        ) : (
          <Flex stack gap={16}>
            <Flex
              gap={10}
              className="border-0 border-b border-solid border-grey-200 pb-4"
            >
              <Search
                onSearch={setSearch}
                search={search}
                className="w-full"
                fieldClassName="w-full"
                autoFocus={false}
              />
              <Button variant="secondary" onClick={handleAddUnit}>
                Add Unit
              </Button>
              <Button variant="secondary" onClick={handleOpenWorkout}>
                Open Worksheet
                <Icon name={IconName.linkExternal} className="text-grey-600" />
              </Button>
            </Flex>
            <TableUnitDetails
              unitFields={unitFields}
              data={rows}
              loading={isLoading}
              onRowClick={(row) => {
                setEditingRowIndex((row?._index as number) || 0)
                setIsUnitModalVisible(true)
              }}
              onDelete={setDeletingRowIndex}
            />
            <TextLink onClick={handleAddUnit}>
              <Icon name={IconName.plusCircle} className="text-grey-600" />
              Add Unit
            </TextLink>
          </Flex>
        )}
      </Flex>

      {isUnitModalVisible && (
        <LoanFields
          title="Unit Details"
          description={
            !isNil(editingRowIndex)
              ? `Unit ${editingRowIndex + 1} of ${rows?.length}`
              : undefined
          }
          fields={unitFields.map((field) => ({
            ...field,
            property: {
              ...field.property,
              value: [
                !isNil(editingRowIndex)
                  ? rows[editingRowIndex]?.[field.name] || ''
                  : field.property.value[0],
              ],
            },
          }))}
          saving={saving}
          onSave={handleSave}
          onCancel={() => {
            setEditingRowIndex(undefined)
            setIsUnitModalVisible(false)
          }}
        />
      )}
      {!isNil(deletingRowIndex) && (
        <ModalDelete
          resource="unit"
          loading={saving}
          onDelete={handleDelete}
          onCancel={() => setDeletingRowIndex(undefined)}
        />
      )}
    </>
  )
}

export { UnitDetails }
