import { request } from 'services/request'
import { Fund, FundInvestor, Transaction } from 'types'
import { formatPercent, parsePercent } from 'utils/math'

const getFundInvestors = async (id): Promise<FundInvestor[]> => {
  const {
    data: { investors },
  } = await request.get(`/fund/${id}/investor`)
  return investors.map((investor) => ({
    ...investor,
    fundInvestors: {
      ...investor.fundInvestors,
      rate: formatPercent(investor.fundInvestors.rate),
    },
  }))
}

const getFundInvestment = async (
  id: string,
  investmentId: string
): Promise<{ investor: FundInvestor; transactions: Transaction[] }> => {
  const { data } = await request.get(`/fund/${id}/investment/${investmentId}`)
  return data
}

const addFundInvestor = async (
  fundId: string,
  payload: Partial<FundInvestor['fundInvestors']>
): Promise<Fund> => {
  const {
    data: { fund },
  } = await request.post(`/fund/${fundId}/investor`, {
    ...payload,
    rate: parsePercent(payload.rate),
  })
  return {
    ...fund,
    investors: fund.investors.map(({ fundInvestors, ...investor }) => ({
      ...investor,
      fundInvestors: {
        ...fundInvestors,
        rate: formatPercent(fundInvestors.rate),
      },
    })),
  }
}

const editFundInvestor = async (
  fundId: string,
  fundInvestorId: string,
  payload: Partial<FundInvestor['fundInvestors']>
): Promise<FundInvestor['fundInvestors']> => {
  const {
    data: { investor },
  } = await request.patch(`/fund/${fundId}/investor/${fundInvestorId}`, {
    ...payload,
    rate: parsePercent(payload.rate),
  })
  return {
    ...investor,
    rate: formatPercent(investor.rate),
  }
}

const deleteFundInvestment = async (
  fundId: string,
  id: string
): Promise<FundInvestor[]> => {
  const {
    data: { investors },
  } = await request.delete(`/fund/${fundId}/investment/${id}`)
  return investors
}

export {
  getFundInvestors,
  getFundInvestment,
  addFundInvestor,
  editFundInvestor,
  deleteFundInvestment,
}
