import { PropsWithChildren, useEffect } from 'react'
import { useClient } from 'hooks/use-client'
import { useSession } from 'hooks/use-session'

const changeFavicon = (faviconURL) => {
  let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
  if (!link) {
    link = document.createElement('link')
    link.rel = 'icon'
    document.head.appendChild(link)
  }
  link.href = faviconURL
}

const AppCustomization = ({ children }: PropsWithChildren) => {
  const { user } = useSession()
  const { data } = useClient({
    enabled: !user,
  })
  const client = user?.client || data

  useEffect(() => {
    if (client?.name) {
      document.title = client.name
    }
    if (client?.image?.logoUrl) {
      changeFavicon(client.image.logoUrl)
    }
  }, [client])
  return children
}

export { AppCustomization }
