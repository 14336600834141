import clsx from 'clsx'
import { EllipsesActions } from 'components/EllipsesActions'
import { useMobile } from 'hooks/use-mobile'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface Props {
  name: string
  activeId?: string
  tabs: { id: string; disabled: boolean; tab: string }[]
  actions?: {
    label: string
    icon: string
    disabled?: boolean
    variant?: 'default' | 'danger'
    onSelect: () => void
  }[]
  onSelect: (id: string) => void
}

function TabDropdown({ name, tabs, actions, activeId, onSelect }: Props) {
  const { isMobile } = useMobile()
  const activeTab = tabs.find(({ id }) => id === activeId)

  return (
    <EllipsesActions
      trigger={
        isMobile ? (
          <div
            className={clsx(styles.tab, styles.tabMore, {
              [styles.selected]:
                tabs.findIndex(({ id }) => id === activeId) !== -1,
            })}
          >
            {name}
            {name && activeTab ? ' - ' : ''}
            {activeTab ? activeTab.tab : ''}
            <Icon name={IconName.arrowDown} />
          </div>
        ) : (
          <div className={clsx(styles.tab, styles.tabMore)}>
            <Icon name={IconName.ellipses} />
          </div>
        )
      }
    >
      {
        tabs.map((tab, index) => (
          <EllipsesActions.Item
            key={tab.id}
            disabled={tab.disabled}
            onSelect={() => !tab.disabled && onSelect(tab.id)}
            divider={index === tabs.length - 1 && !!actions?.length}
          >
            {tab.tab}
          </EllipsesActions.Item>
        )) as any
      }
      {
        actions?.map((action) => (
          <EllipsesActions.Item
            key={action.label}
            icon
            disabled={action.disabled}
            variant={action.variant}
            onSelect={action.onSelect}
          >
            <Icon
              name={action.icon as IconName}
              className={clsx(!action.variant && 'text-grey-600')}
            />
            {action.label}
          </EllipsesActions.Item>
        )) as any
      }
    </EllipsesActions>
  )
}

export default TabDropdown
