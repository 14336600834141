import { Formik } from 'formik'
import {
  createScheme,
  email,
  mergeRules,
  required,
} from '../../../utils/schemas'
import { Button } from '../../Button'
import { Form, Field, Checkbox } from '../../Form'
import { Grid } from '../../Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

const EmailSchema = createScheme({
  email: mergeRules(email, required),
})

type FormValues = {
  email: string
  sendVerification: boolean
}

interface ModalAddEmailProps {
  saving: boolean
  isInvited: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

function ModalAddEmail({
  saving,
  isInvited,
  onSubmit,
  onCancel,
}: ModalAddEmailProps) {
  const initialValue: FormValues = {
    email: '',
    sendVerification: false,
  }

  return (
    <Modal title="Email" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={EmailSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field type="email" name="email" placeholder="Enter email" />
            </Grid.Item>
            {isInvited ? (
              <Grid.Item xs={12}>
                <Checkbox
                  name="sendVerification"
                  label="Send verification"
                  className="items-center"
                />
              </Grid.Item>
            ) : undefined}
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalAddEmail
