import {
  AdvancedMarker,
  APIProvider,
  Map,
  RenderingType,
} from '@vis.gl/react-google-maps'
import { compact } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getGeocode, getLatLng } from 'use-places-autocomplete'
import { InlineEditGridItem } from 'admin/components/InlineEdit'
import {
  useUpdateLoanAddress,
  useUpdateLoanAddressField,
} from 'admin/hooks/use-loan-address'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { LoanFields } from 'components/Modal/LoanFields'
import { LoanPropertyDetails } from 'components/Modal/LoanPropertyDetails'
import { AddressSummary } from 'components/Summary'
import { Field, Loan } from 'types'
import { formatAddress } from 'utils/address'
import { getFieldsBySection, mergeFields } from 'utils/fields'
import { message } from 'utils/message'

interface Props {
  loan: Loan
  addressIndex: number
  onOpenAddress: (addressId: string) => void
  onRemove: () => void
}

const getPosition = async (
  address: string
): Promise<{ lat: number; lng: number }> => {
  const [geocoderResult] = await getGeocode({ address: address })
  return getLatLng(geocoderResult)
}

export const PropertyDetails = ({
  loan,
  addressIndex,
  onOpenAddress,
  onRemove,
}: Props) => {
  const [position, setPosition] = useState<{ lat: number; lng: number }>()
  const [zoom, setZoom] = useState<number>(10)
  const [isMarkerVisible, setIsMarkerVisible] = useState(false)
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
  const [isFieldsModalVisible, setIsFieldModalVisible] = useState(false)
  const address = useMemo(
    () => loan.addresses[addressIndex],
    [loan.addresses, addressIndex]
  )

  const fields = getFieldsBySection(
    address?.fields.filter(({ name }) => name !== 'Legal Description'),
    'Property Details'
  )
  const legalDescriptionField = useMemo(
    () => address?.fields.find(({ name }) => name === 'Legal Description'),
    [address]
  )
  const { mutateAsync: updateField } = useUpdateLoanAddressField({
    id: loan.id,
    addressId: address.id,
  })
  const { mutate: updateLoanAddress, isPending: saving } = useUpdateLoanAddress(
    {
      id: loan.id,
      silent: true,
    }
  )

  const openNextAddress = useCallback(() => {
    const nextAddressIndex = addressIndex + 1
    onOpenAddress(loan.addresses[nextAddressIndex]?.id || loan.addresses[0].id)
  }, [onOpenAddress, addressIndex, loan.addresses])

  useEffect(() => {
    setPosition(undefined)
    ;(async () => {
      try {
        const position = await getPosition(formatAddress(address))
        setPosition(position)
        setZoom(10)
        setIsMarkerVisible(true)
      } catch {
        // show US if there is an error
        setPosition({ lat: 39, lng: -95 })
        setZoom(2.8)
        setIsMarkerVisible(false)
      }
    })()
  }, [formatAddress(address)])

  return (
    <>
      <Flex gap={32}>
        <div className="w-[300px] h-[230px] flex-shrink-0">
          {position && (
            <APIProvider
              apiKey={import.meta.env.VITE_APP_GOOGLE_PLACES_API_KEY}
            >
              <Map
                defaultCenter={position}
                defaultZoom={zoom}
                disableDefaultUI
                renderingType={RenderingType.RASTER}
                mapId="loan-address"
              >
                {isMarkerVisible && <AdvancedMarker position={position} />}
              </Map>
            </APIProvider>
          )}
        </div>
        <Flex stack gap={0} className="flex-grow">
          <Flex justifyContent="space-between" alignItems="center">
            <Header variant="h4">Property Details</Header>
            <Flex gap={10}>
              <EllipsesActions>
                <EllipsesActions.Item
                  icon
                  disabled={loan.lock}
                  onSelect={() => setIsFieldModalVisible(true)}
                >
                  <Icon name={IconName.edit} />
                  Edit
                </EllipsesActions.Item>
                <EllipsesActions.Item
                  icon
                  variant="danger"
                  disabled={loan.lock}
                  onSelect={onRemove}
                >
                  <Icon name={IconName.delete} />
                  Remove Property
                </EllipsesActions.Item>
              </EllipsesActions>
              {loan.addresses.length > 1 && (
                <Button variant="secondary" onClick={openNextAddress}>
                  Next
                  <Icon name={IconName.arrowRightLong} />
                </Button>
              )}
            </Flex>
          </Flex>
          {loan.addresses.length > 1 && (
            <div className="text-grey-700">
              Property {addressIndex + 1} of {loan.addresses.length}
            </div>
          )}
          <Grid gap={16} className="mt-5">
            <Grid.Item xs={4}>
              <AddressSummary
                address={address}
                onClick={() => setIsAddressModalVisible(true)}
              />
            </Grid.Item>
            {fields.map((field) => (
              <Grid.Item xs={4} key={field.id}>
                <InlineEditGridItem
                  field={field}
                  save={(value) =>
                    updateField({
                      ...field,
                      property: {
                        ...field.property,
                        value: [value],
                      },
                    })
                  }
                />
              </Grid.Item>
            ))}
            {legalDescriptionField && (
              <Grid.Item xs={12}>
                <InlineEditGridItem
                  field={{ ...legalDescriptionField, type: ['multiline'] }}
                  save={(value) =>
                    updateField({
                      ...legalDescriptionField,
                      property: {
                        ...legalDescriptionField.property,
                        value: [value],
                      },
                    })
                  }
                />
              </Grid.Item>
            )}
          </Grid>
        </Flex>
      </Flex>
      {isAddressModalVisible && (
        <LoanPropertyDetails
          address={address}
          saving={saving}
          onSave={(address) =>
            updateLoanAddress(address, {
              onSuccess: () => {
                setIsAddressModalVisible(false)
                message.success('Loan updated')
              },
            })
          }
          onCancel={() => setIsAddressModalVisible(false)}
        />
      )}
      {isFieldsModalVisible && (
        <LoanFields
          title="Property Details"
          fields={compact([...fields, legalDescriptionField])}
          saving={saving}
          onSave={(updatedFields) =>
            updateLoanAddress(
              {
                id: address.id,
                fields: mergeFields(
                  address.fields,
                  updatedFields
                ) as unknown as Field[],
              },
              {
                onSuccess: () => {
                  setIsFieldModalVisible(false)
                },
              }
            )
          }
          onCancel={() => setIsFieldModalVisible(false)}
        />
      )}
    </>
  )
}
