import { orderBy } from 'lodash'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Badge } from './Badge'

interface Props {
  associatedWith?: { id: string; name: string }[]
  maxWidth?: number
  getUrlById?: (id: string) => string
}

const badgesGap = 8

const AssociatedBadges = ({
  associatedWith = [],
  maxWidth = 188,
  getUrlById,
}: Props) => {
  const associatedList = useMemo(
    () =>
      orderBy(
        associatedWith?.map(({ id, name }) => ({
          id,
          name,
          url: getUrlById?.(id),
        })) || [],
        'name',
        'asc'
      ),
    [associatedWith]
  )

  if (associatedList.length === 1) {
    return (
      <Flex style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
      </Flex>
    )
  }
  if (associatedList.length === 2) {
    return (
      <Flex gap={badgesGap} alignItems="center" style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
        <Badge url={associatedList[1].url}>{associatedList[1].name}</Badge>
      </Flex>
    )
  }
  if (associatedList.length > 2) {
    return (
      <Flex gap={badgesGap} alignItems="center" style={{ maxWidth }}>
        <Badge url={associatedList[0].url}>{associatedList[0].name}</Badge>
        <Badge associatedList={associatedList} className="flex-shrink-0">
          +{associatedList.length - 1}
        </Badge>
      </Flex>
    )
  }

  return null
}

export { AssociatedBadges }
