import { Flex } from 'components/Flex'
import { Option, Select } from 'components/Form'
import { fields } from './ProductFields'

interface Props {
  disabled?: boolean
  onChange: (option: Option) => void
}

export const SelectFieldType = ({ disabled, onChange }: Props) => {
  return (
    <Select
      label="Field type"
      name="type"
      portal
      options={fields.map(({ name, type, icon }) => ({
        label: (
          <Flex alignItems="center" gap={8}>
            <img className="h-5 w-5 flex-shrink-0" src={icon} alt="" /> {name}
          </Flex>
        ),
        value: type,
      }))}
      onChange={onChange}
      disabled={disabled}
    />
  )
}
