import { request } from 'services/request'
import { LoanDocument } from 'types'

const getFundDocuments = async ({
  id,
}: {
  id: string
}): Promise<LoanDocument[]> => {
  const { data } = await request.get(`/fund/${id}/document`)
  return data.documents
}

const addFundDocument = async (
  id: string,
  document: Partial<LoanDocument>
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.post(`/fund/${id}/document`, document)
  return doc
}

export { getFundDocuments, addFundDocument }
