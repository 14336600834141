import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { KEY_NOTIFICATIONS } from 'constants/query-keys'
import { getNotifications } from 'services/api/notification'

const useNotifications = (key?: string) => {
  return useQuery({
    queryKey: [KEY_NOTIFICATIONS, key],
    queryFn: () => getNotifications({ pagination: { page: 0, size: 10 } }),
    placeholderData: keepPreviousData,
    refetchInterval: 30 * 60 * 1000,
  })
}

export { useNotifications }
