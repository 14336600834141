import { useState } from 'react'
import { useAddBorrower } from 'admin/hooks/use-borrowers'
import {
  useAddQuoteBorrower,
  useUpdateQuotePerson,
  useRemoveQuoteBorrower,
} from 'admin/hooks/use-quote-person'
import { Borrower } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { ModalChooseBorrower } from 'components/Modal/ChooseBorrower'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Person, Quote } from 'types'
import { includeAttributes } from 'utils/includeAttributes'
import { EntityDetails } from './EntitiyDetails'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
  borrowers: Person[]
}

const PanelBorrower = ({ quote, borrowers }: Props) => {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isAddingPerson, setIsAddingPerson] = useState(false)
  const [personName, setPersonName] = useState('')
  const [addedPersonId, setAddedPersonId] = useState('')

  const addPerson = useAddBorrower()

  const { mutate: addBorrower, isPending: saving } = useAddQuoteBorrower(
    quote.id
  )
  const { mutate: deleteBorrower, isPending: deleting } =
    useRemoveQuoteBorrower(quote.id)
  const { mutate: updatePerson, isPending: savingPerson } =
    useUpdateQuotePerson(quote.id)

  const handleAddBorrower = () => {
    setIsModalVisible(true)
  }

  const handleCreatePerson = (personName?: string) => {
    setIsModalVisible(false)
    setPersonName(personName ?? '')
    setIsAddingPerson(true)
  }

  const entityIncludes: string[] = includeAttributes({
    loanType: quote.loanType,
  })

  return (
    <Panel title="Borrower">
      <div className={styles.entities}>
        {borrowers.length === 0 && <div>Please add a borrower</div>}
        {borrowers.map((borrower) => (
          <div key={borrower.id}>
            <div className={styles.entityInfoHeader}>
              <Header variant="h4">Primary Borrower</Header>
              <Flex gap={8}>
                <Button
                  size="small"
                  onClick={() => setIsInfoModalVisible(true)}
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  variant="tertiary"
                  disabled={deleting}
                  onClick={() =>
                    deleteBorrower(
                      { id: borrower.id },
                      {
                        onSuccess: () => {
                          setIsModalVisible(false)
                        },
                      }
                    )
                  }
                >
                  Remove
                </Button>
              </Flex>
            </div>
            <EntityDetails entity={borrower} include={entityIncludes} />
          </div>
        ))}
      </div>
      {borrowers.length === 0 && (
        <div className={styles.addLinkBox}>
          <TextLink onClick={() => handleAddBorrower()}>
            <Icon name={IconName.plus} size="sm" />
            Add Borrower
          </TextLink>
        </div>
      )}
      {isModalVisible && (
        <ModalChooseBorrower
          saving={saving}
          include={entityIncludes}
          onSave={({ borrower }) =>
            addBorrower(
              {
                id: borrower,
              },
              {
                onSuccess: () => {
                  setIsModalVisible(false)
                },
              }
            )
          }
          onCancel={() => setIsModalVisible(false)}
          handleAddBorrower={(personName?: string) =>
            handleCreatePerson(personName)
          }
          addedPersonId={addedPersonId}
        />
      )}
      {isAddingPerson && (
        <ModalAddPerson
          saving={addPerson.isPending}
          personName={personName}
          include={['creditScore', ...entityIncludes]}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            addPerson.mutate(borrower, {
              onSuccess: (person) => {
                setAddedPersonId(person.id)
                setIsAddingPerson(false)
                setIsModalVisible(true)
              },
            })
          }
          onCancel={() => {
            setIsAddingPerson(false)
            setIsModalVisible(true)
          }}
        />
      )}
      {isInfoModalVisible && (
        <ModalPersonInfo
          personType="borrower"
          person={borrowers[0]}
          saving={savingPerson}
          include={entityIncludes}
          onSave={(values) => {
            updatePerson(
              { ...values, id: borrowers[0].id },
              {
                onSuccess: () => {
                  setIsInfoModalVisible(false)
                },
              }
            )
          }}
          onCancel={() => setIsInfoModalVisible(false)}
        />
      )}
    </Panel>
  )
}

export { PanelBorrower }
