import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { PageTopFields } from 'components/PageTopFields'
import styles from './styles.module.scss'

interface Props {
  title?: string
  fields: { key: string; value?: string; tooltip?: string }[]
  className?: string
  fieldsClassName?: string
}

export const MetaFields = ({
  title,
  fields,
  className,
  fieldsClassName,
}: Props) => (
  <Flex stack gap={8} className={clsx(styles.loanDetails, className)}>
    {title && <Header variant="h4">{title}</Header>}
    <PageTopFields className={fieldsClassName} fields={fields} />
  </Flex>
)
