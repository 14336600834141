import { HeaderGroup } from '@tanstack/react-table'
import { memo } from 'react'
import { HeaderRowCell } from 'components/Table/HeaderRowCell'
import { Sort } from 'types'

interface Props<TData> {
  headerGroup: HeaderGroup<TData>
  sort?: Sort
  onSort?: (sort: Sort | string | undefined) => void
}

const HeaderRowComponent = <TData,>({
  headerGroup,
  sort,
  onSort,
}: Props<TData>) => (
  <tr>
    {headerGroup.headers.map((header) => (
      <HeaderRowCell
        key={header.id}
        header={header}
        sort={sort}
        onSort={onSort}
      />
    ))}
  </tr>
)

export const HeaderRow = memo(HeaderRowComponent) as typeof HeaderRowComponent
