import { request } from 'services/request'
import { LenderOfRecord } from 'types'

const getLenderOfRecords = async (
  clientId: string
): Promise<LenderOfRecord[]> => {
  const {
    data: { lenderOfRecords },
  } = await request.get(`/client/${clientId}/lenderofrecord`)
  return lenderOfRecords
}

const addLenderOfRecord = async (
  clientId: string,
  payload: Partial<LenderOfRecord>
): Promise<LenderOfRecord[]> => {
  const {
    data: { lenderOfRecords },
  } = await request.post(`/client/${clientId}/lenderofrecord`, payload)
  return lenderOfRecords
}

const updateLenderOfRecord = async (
  clientId: string,
  { id, ...payload }: Partial<LenderOfRecord>
): Promise<LenderOfRecord[]> => {
  const {
    data: { lenderOfRecords },
  } = await request.patch(`/client/${clientId}/lenderofrecord/${id}`, payload)
  return lenderOfRecords
}

const deleteLenderOfRecord = async (
  clientId: string,
  lenderOfRecordId: string
): Promise<LenderOfRecord[]> => {
  const {
    data: { lenderOfRecords },
  } = await request.delete(
    `/client/${clientId}/lenderofrecord/${lenderOfRecordId}`
  )
  return lenderOfRecords
}

const getLoanLenderOfRecord = async (
  loanId: string
): Promise<LenderOfRecord> => {
  const {
    data: { lenderOfRecord },
  } = await request.get(`/loan/${loanId}/lenderofrecord`)
  return lenderOfRecord
}

export {
  getLenderOfRecords,
  addLenderOfRecord,
  updateLenderOfRecord,
  deleteLenderOfRecord,
  getLoanLenderOfRecord,
}
