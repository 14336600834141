import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ImportEntity } from 'admin/components/ImportEntity'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddInvestor, useInvestors } from 'admin/hooks/use-investors'
import { pathTo } from 'admin/path-to'
import { Investor } from 'admin/services/api/investors'
import { downloadInvestors } from 'admin/services/csv/download-investors'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { INVESTOR_IMPORT_BANNER } from 'constants/local-storage-keys'
import { usePagination } from 'hooks/use-pagination'
import { AlertInvestors } from './AlertInvestors'
import { EmptyInvestors } from './EmptyInvestors'
import InvestorsTable from './TableInvestors'

const Investors = () => {
  const {
    visibleItems,
    result,
    search,
    sort,
    isEmpty,
    setSearch,
    setPagination,
    setSort,
    updateItem,
  } = usePagination<Investor>({
    property: 'investors',
    useData: (params) =>
      params.search
        ? useInvestors({
            ...params,
            filter: { ...params.filter, isFund: null },
            details: true,
          })
        : useInvestors({
            ...params,
            filter: { isAccount: [true], isFund: null },
            details: true,
          }),
  })
  const [adding, setAdding] = useState(false)
  const navigate = useNavigate()
  const add = useAddInvestor()
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(INVESTOR_IMPORT_BANNER)
  )

  const handleRowClick = (investor?: Investor) => {
    if (investor?.id) {
      navigate(pathTo('investor', investor.id))
    }
  }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Investors" />
        {alertData && (
          <AlertInvestors alertData={alertData} setAlertData={setAlertData} />
        )}
        {isEmpty ? (
          <EmptyInvestors
            onClick={() => setAdding(true)}
            onImport={() => setAlertData(true)}
          />
        ) : (
          <Panel>
            <div className="flex justify-center gap-2.5 pb-4 sm:justify-end">
              <Search search={search} onSearch={setSearch} />
              <ImportEntity
                entityType="investor"
                setAlertData={() => setAlertData(true)}
              />
              <Download
                filename="investors"
                download={() => downloadInvestors(sort, search)}
              />
              <Button variant="primary" onClick={() => setAdding(true)}>
                Add Investor
              </Button>
            </div>
            <InvestorsTable
              data={visibleItems}
              loading={result.isPending}
              sort={sort}
              onClick={handleRowClick}
              onSort={setSort}
              onUpdateItem={updateItem}
            />
            <LoadMore
              loading={result.isPending}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>
      {adding ? (
        <ModalAddPerson
          saving={add.isPending}
          onSave={(investor: Omit<Investor, 'id'>) => {
            add.mutate(investor, {
              onSuccess: ({ id }) => {
                navigate(pathTo('investor', id))
              },
            })
          }}
          onCancel={() => {
            setAdding(false)
          }}
        />
      ) : null}
    </MainContent>
  )
}

export { Investors }
