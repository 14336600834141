import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addLoanField,
  updateLoanField,
  removeLoanField,
} from 'admin/services/api/loan-fields'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Field } from 'types'

const useAddLoanField = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (field: Field) => addLoanField(id, field),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanField = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (field: Field) => updateLoanField(id, field),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanField = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (fieldId: string) => removeLoanField(id, fieldId),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
    },
    onError: handleErrorResponse,
  })
}

export { useAddLoanField, useUpdateLoanField, useDeleteLoanField }
