import qs from 'qs'
import { request } from 'services/request'
import {
  Filter,
  Search,
  Meta,
  Pagination,
  Address,
  Person,
  PersonRole,
  PersonTitle,
  PersonDetails,
  Sort,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

type Borrower = Person
type BorrowerDetails = PersonDetails

const getBorrowers = async ({
  details,
  ...params
}: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ borrowers: Borrower[]; meta: Meta }> => {
  const searchParams = qs.stringify({ details }, { addQueryPrefix: true })
  const {
    data: { borrowers, pagination },
  } = await request.get(`/borrower${searchParams}`, {
    params: updateRequestTable(params),
  })
  return {
    borrowers,
    meta: pagination,
  }
}

const getBorrower = async (
  id: string,
  params?: { secure?: boolean }
): Promise<BorrowerDetails> => {
  const {
    data: { borrower },
  } = await request.get(`/borrower/${id}`, { params })
  return borrower
}

const updateBorrower = async (borrower: Borrower): Promise<BorrowerDetails> => {
  const { data } = await request.patch(`/borrower/${borrower.id}`, borrower)
  return data.borrower
}

const addBorrower = async (
  borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
): Promise<BorrowerDetails> => {
  const { data } = await request.post('/borrower', borrower)
  return data.borrower
}

const addAddress = ({ id, address }: { id: string; address: Address }) => {
  return request.post(`/borrower/${id}/address`, address)
}

const updateAddress = ({
  id,
  addressId,
  address,
}: {
  id: string
  addressId: string
  address: Address
}) => {
  return request.patch(`/borrower/${id}/address/${addressId}`, address)
}

const updateOwners = async ({
  id,
  owners,
}: {
  id: string
  owners: string[]
}): Promise<Person> => {
  const {
    data: { borrower },
  } = await request.put(`/borrower/${id}/owner`, { owners })

  return borrower
}

const inviteEmail = ({ id }: { id: string }) => {
  return request.put(`/borrower/${id}/login`)
}

const linkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role: PersonRole
  title: PersonTitle
}): Promise<Borrower> => {
  const {
    data: { borrower },
  } = await request.put(`/borrower/${entityId}/manager`, {
    id: individualId,
    role,
    title,
  })
  return borrower
}

const updateLinkAccount = async ({
  individualId,
  entityId,
  role,
  title,
}: {
  individualId: string
  entityId: string
  role?: PersonRole
  title?: PersonTitle
}): Promise<Borrower> => {
  const {
    data: { borrower },
  } = await request.patch(`/borrower/${entityId}/manager/${individualId}`, {
    role,
    title,
  })
  return borrower
}

const unlinkAccount = async ({
  individualId,
  entityId,
}: {
  individualId: string
  entityId: string
}): Promise<Borrower> => {
  const {
    data: { borrower },
  } = await request.delete(`/borrower/${entityId}/manager/${individualId}`)
  return borrower
}

const deleteBorrower = (id: string) => {
  return request.delete(`/borrower/${id}`)
}

export type { Borrower, BorrowerDetails }
export {
  getBorrowers,
  getBorrower,
  addBorrower,
  updateBorrower,
  addAddress,
  updateAddress,
  inviteEmail,
  linkAccount,
  updateLinkAccount,
  unlinkAccount,
  deleteBorrower,
  updateOwners,
}
