import { TableLoans } from 'admin/components/table/TableLoans'
import { Panel } from 'components/Panel'
import { Loan } from 'types'

function TabLoans({ loans }: { loans: Loan[] }) {
  return (
    <Panel>
      <TableLoans data={loans} />
    </Panel>
  )
}

export default TabLoans
