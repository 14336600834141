import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getTemplateUrl,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  resetTemplate,
  getTemplates,
} from 'admin/services/api/templates'
import { KEY_TEMPLATE } from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useTemplates = () => {
  return useQuery({
    queryKey: [KEY_TEMPLATE],
    queryFn: getTemplates,
  })
}

const useAddTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (name: string) => addTemplate(name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TEMPLATE] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, params }: { id: string; params: { name?: string } }) =>
      updateTemplate(id, params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TEMPLATE] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteTemplate(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TEMPLATE] })
    },
    onError: handleErrorResponse,
  })
}

const useDownloadTemplate = () => {
  return useMutation({
    mutationFn: (name: string) => getTemplateUrl(name),
    onSuccess: ({ url }) => {
      window.location.href = url
    },
    onError: handleErrorResponse,
  })
}

const useUploadTemplate = () => {
  return useMutation({
    mutationFn: async ({
      id,
      name,
      file,
    }: {
      id: string
      name: string
      file: File
    }) => {
      const uploadToast = message.upload(name)
      uploadToast.show()
      const { uploadUrl } = await getTemplateUrl(id)
      await uploadDocument(uploadUrl, file, (progressEvent) => {
        uploadToast.progress(progressEvent.loaded / progressEvent.total)
      })
      uploadToast.complete()
      return true
    },
    onError: handleErrorResponse,
  })
}

const useResetTemplate = () => {
  return useMutation({
    mutationFn: (name: string) => resetTemplate(name),
    onSuccess: () => {
      message.success('Template reset')
    },
    onError: handleErrorResponse,
  })
}

export {
  useDownloadTemplate,
  useUploadTemplate,
  useResetTemplate,
  useTemplates,
  useAddTemplate,
  useUpdateTemplate,
  useRemoveTemplate,
}
