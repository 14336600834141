import { capitalize } from 'lodash'
import { getLoans } from 'services/api/loans'
import { Filter, Row } from 'types'

const downloadPipelineLoans = async (
  filter?: Filter,
  search?: string
): Promise<Row[]> => {
  const { loans } = await getLoans({
    filter,
    search,
  })
  return loans.map((loan) => ({
    ID: loan.number,
    Name: loan.name,
    Origination: loan.dateClosing,
    Borrower: loan.borrowers[0]?.name,
    'Loan Amount$': loan.amount,
    Pipeline: capitalize(loan.status),
    Status: loan.substatus,
    'Use of Funds': loan.useOfFunds,
    Product: loan.product?.name,
    Admin: loan.admin?.name,
  }))
}

export { downloadPipelineLoans }
