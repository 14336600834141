import { Button } from 'components/Button'
import { CopyToClipboard } from 'components/CopyToClipboard'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Indicator } from 'components/Indicator'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { message } from 'utils/message'
import styles from '../styles.module.scss'

interface Props {
  name: string
  subdomain: string
  paymentsEnabled?: boolean
}
function BusinessProfileDomain({ name, subdomain, paymentsEnabled }: Props) {
  const { user } = useSession()
  const isServicing = user?.client.servicing

  return (
    <Grid gap={24}>
      <Grid.Item sm={12}>
        <Summary name="Name">{name}</Summary>
      </Grid.Item>
      <Grid.Item sm={12}>
        <Summary name="Domain">{subdomain}</Summary>
      </Grid.Item>
      {isServicing && (
        <Grid.Item sm={12}>
          <Summary name="Borrower Payments">
            <Flex gap={8}>
              <Text className={styles.substatus}>
                <Indicator color={paymentsEnabled ? 'green' : 'yellow'} />
                {paymentsEnabled ? 'Active' : 'Inactive'}
              </Text>
            </Flex>
          </Summary>
        </Grid.Item>
      )}
      <Grid.Item sm={12}>
        <Summary name="Borrower Sign-up Page">
          <div className={styles.linkContainer}>
            <a
              className="link"
              href={`https://${subdomain}/create`}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://{subdomain}/create
            </a>
            <CopyToClipboard
              text={`https://${subdomain}/create`}
              onCopy={() => message.success('Link copied')}
            >
              <Button>Copy URL</Button>
            </CopyToClipboard>
          </div>
        </Summary>
      </Grid.Item>
      <Grid.Item sm={12}>
        <Summary name="Investor Sign-up Page">
          <div className={styles.linkContainer}>
            <a
              className="link"
              href={`https://${subdomain}/create-investor`}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://{subdomain}/create-investor
            </a>
            <CopyToClipboard
              text={`https://${subdomain}/create-investor`}
              onCopy={() => message.success('Link copied')}
            >
              <Button>Copy URL</Button>
            </CopyToClipboard>
          </div>
        </Summary>
      </Grid.Item>
      <Grid.Item sm={12}>
        <Summary name="Public Application (No Sign-up)">
          <div className={styles.linkContainer}>
            <a
              className="link"
              href={`https://${subdomain}/application`}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://{subdomain}/application
            </a>
            <CopyToClipboard
              text={`https://${subdomain}/application`}
              onCopy={() => message.success('Link copied')}
            >
              <Button>Copy URL</Button>
            </CopyToClipboard>
          </div>
        </Summary>
      </Grid.Item>
    </Grid>
  )
}

export { BusinessProfileDomain }
