import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import {
  updateQuoteOption,
  getQuoteOptions,
  getQuoteOption,
  generateQuoteOptions,
} from 'admin/services/api/quote-options'
import { KEY_QUOTES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { QuoteOptionEditableProps } from 'types'

const useQuoteOptions = (quoteId: string, refreshInterval?: number) => {
  return useQuery({
    queryKey: [KEY_QUOTES, quoteId, 'options'],
    queryFn: () => getQuoteOptions(quoteId),
    refetchInterval: refreshInterval,
    placeholderData: keepPreviousData,
  })
}

const useQuoteOption = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (optionId: string) => getQuoteOption(quoteId, optionId),
    onSuccess: (option) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId, 'option'], option)
    },
    onError: handleErrorResponse,
  })
}

const useGenerateQuoteOptions = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => generateQuoteOptions(quoteId),
    onSuccess: (options) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId, 'options'], options)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateQuoteOption = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: QuoteOptionEditableProps) =>
      updateQuoteOption(quoteId, data),
    onSuccess: ({ quote, options }) => {
      if (quote) {
        queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
      }
      queryClient.setQueryData([KEY_QUOTES, quoteId, 'options'], options)
    },
    onError: handleErrorResponse,
  })
}

export {
  useQuoteOptions,
  useQuoteOption,
  useUpdateQuoteOption,
  useGenerateQuoteOptions,
}
