import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import {
  getQuoteLenders,
  updateQuoteLenders,
} from 'admin/services/api/quote-lender'
import { KEY_QUOTES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Quote } from 'types'

const useQuoteLenders = ({
  transactionType,
  loanType,
}: {
  transactionType: Quote['transactionType']
  loanType: Quote['loanType']
}) => {
  return useQuery({
    queryKey: [KEY_QUOTES, 'lender', transactionType, loanType],
    queryFn: () => getQuoteLenders({ transactionType, loanType }),
    placeholderData: keepPreviousData,
    initialData: [],
  })
}

const useUpdateQuoteLenders = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ lenderId }: { lenderId: string[] }) =>
      updateQuoteLenders({ quoteId, lenderId }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quote.id], quote)
    },
    onError: handleErrorResponse,
  })
}

export { useQuoteLenders, useUpdateQuoteLenders }
