import { useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import { useLoanType } from 'admin/hooks/use-loan-type'
import { LoanProvider } from 'admin/pages/Loan/LoanProvider'
import { PageLoader } from 'components/LoaderOverlay'
import { useLoan } from 'hooks/use-loans'
import { LoanHeader } from './LoanHeader'
import { LoanViewOriginator } from './LoanViewOriginator'
import { LoanViewServicing } from './LoanViewServicing'

function Loan() {
  const { id } = useParams() as { id: string }
  const { data: loan } = useLoan({ id })
  const { isOrigination } = useLoanType()

  return (
    <MainContent>
      {loan ? (
        <LoanProvider loan={loan}>
          <LoanHeader loan={loan} />
          {isOrigination ? <LoanViewOriginator /> : <LoanViewServicing />}
        </LoanProvider>
      ) : (
        <PageLoader />
      )}
    </MainContent>
  )
}

export { Loan }
