import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  KEY_LOAN_CHARGES,
  KEY_LOAN_PAYMENT_CALCULATE,
} from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Charge } from 'types/charge'
import { message } from 'utils/message'
import {
  getLoanCharges,
  getLoanCharge,
  addLoanCharge,
  updateLoanCharge,
  deleteLoanCharge,
} from '../services/api/loan-charges'

const useLoanCharges = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_CHARGES, loanId],
    queryFn: () => getLoanCharges(loanId),
  })
}

const useLoanCharge = (loanId: string, chargeId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_CHARGES, loanId, chargeId],
    queryFn: () => getLoanCharge(loanId, chargeId),
  })
}

const useAddLoanCharge = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (
      data: Pick<
        Charge,
        'personId' | 'name' | 'dateCharged' | 'dateDue' | 'amount'
      >
    ) => addLoanCharge(loanId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_CHARGES, loanId] })
      queryClient.invalidateQueries({
        queryKey: [KEY_LOAN_PAYMENT_CALCULATE, loanId, 'charges'],
      })
      message.success('Charge created')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanCharge = ({
  loanId,
  chargeId,
}: {
  loanId: string
  chargeId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (
      data: Pick<
        Charge,
        'personId' | 'name' | 'dateCharged' | 'dateDue' | 'amount'
      >
    ) => updateLoanCharge(loanId, chargeId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_CHARGES, loanId] })
      message.success('Charge updated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanCharge = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (chargeId: string) => deleteLoanCharge(loanId, chargeId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_CHARGES, loanId] })
      message.success('Charge deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useLoanCharges,
  useLoanCharge,
  useAddLoanCharge,
  useUpdateLoanCharge,
  useDeleteLoanCharge,
}
