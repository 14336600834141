import { Button } from 'components/Button'
import styles from './styles.module.scss'

interface Props {
  onClick: () => void
}

function Undo({ onClick }: Props) {
  return (
    <Button
      className={styles.undo}
      variant="tertiary"
      size="small"
      tabIndex={-1}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
    >
      Undo
    </Button>
  )
}

export default Undo
