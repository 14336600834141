import { useQueryClient } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { useInvestors, fetchInvestors } from 'admin/hooks/use-investors'
import {
  fetchLoanInvestors,
  useLoanInvestors,
} from 'admin/hooks/use-loan-investors'
import { fetchVendors, useVendors } from 'admin/hooks/use-vendors'
import { Investor } from 'admin/services/api/investors'
import { Vendor } from 'admin/services/api/vendors'
import { Icon, IconName } from 'components/Icon'
import { Filter } from 'types/table'

interface Props {
  value?: any
  filter?: Filter
  className?: string
  loanId?: string
  includeAllInvestors?: boolean
  onSelect: (id: string, name?: string) => void
  onCreate: (name: string) => void
}

const vendorsToOptions = (vendors: Vendor[]) =>
  vendors.map(({ name, id, associatedWith }) => ({
    label: <AssociatedLabels name={name} associatedWith={associatedWith} />,
    value: id,
    name,
  }))

const investorsToOptions = (investors: Investor[]) =>
  investors.map(({ name, id, associatedWith }) => ({
    label: <AssociatedLabels name={name} associatedWith={associatedWith} />,
    value: id,
    name,
  }))

export const SelectVendor = ({
  value,
  filter,
  className,
  loanId,
  includeAllInvestors,
  onSelect,
  onCreate,
}: Props) => {
  const [open, setOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState<any>()
  const queryClient = useQueryClient()
  const { data } = useVendors({
    filter,
    pagination: { size: 100, page: 0 },
    details: true,
  })
  const investors = includeAllInvestors
    ? useInvestors({ pagination: { size: 100, page: 0 }, details: true })
    : useLoanInvestors(loanId as string, {
        enabled: !!loanId,
      })

  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      if (loanId) {
        const promiseVendors = fetchVendors(queryClient, {
          filter,
          search: { name: inputValue },
          details: true,
        })
        const promiseInvestors = includeAllInvestors
          ? fetchInvestors(queryClient, {
              search: { name: inputValue },
              details: true,
            })
          : fetchLoanInvestors(queryClient, loanId, {
              search: { name: inputValue },
            })
        Promise.all([promiseVendors, promiseInvestors]).then((values) => {
          const vendors = vendorsToOptions(values[0].vendors)
          const investors = investorsToOptions(values[1].investors)
          callback([...investors, ...vendors])
        })
      } else {
        fetchVendors(queryClient, {
          filter,
          search: { name: inputValue },
          details: true,
        }).then(({ vendors }) => callback(vendorsToOptions(vendors)))
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (value && value !== selectedOption?.value) {
      Promise.all([
        fetchVendors(queryClient, {
          filter: { id: value },
        }),
        fetchInvestors(queryClient, {
          filter: { id: value },
          details: true,
        }),
      ]).then(([{ vendors }, { investors }]) => {
        if (vendors.length) {
          setSelectedOption(vendorsToOptions(vendors)[0])
        }
        if (investors.length) {
          setSelectedOption(investorsToOptions(investors)[0])
        }
      })
    }
  }, [value])

  return (
    <AsyncSelect
      classNamePrefix="formFieldSelect"
      className={className}
      value={selectedOption}
      placeholder="Select..."
      menuIsOpen={open}
      onMenuOpen={() => setOpen(true)}
      onMenuClose={() => setOpen(false)}
      menuShouldBlockScroll
      menuPortalTarget={document.getElementById('select-portal')}
      loadOptions={loadSuggestedOptions}
      defaultOptions={[
        ...investorsToOptions(investors.data?.investors || []),
        ...vendorsToOptions(data?.vendors || []),
      ]}
      noOptionsMessage={({ inputValue }) => (
        <a
          className="link"
          onClick={() => {
            setOpen(false)
            onCreate(inputValue)
          }}
        >
          <Icon name={IconName.plus} size="sm" /> Create vendor
        </a>
      )}
      onChange={(option) => {
        setSelectedOption(option)
        onSelect(option?.value as string, option?.name as string)
      }}
    />
  )
}
