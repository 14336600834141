import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { BankingData } from 'types'
import { message } from 'utils/message'
import { updateLoanBanking } from '../services/api/loan-banking'

export const useUpdateLoanBanking = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (banking: BankingData) => updateLoanBanking(id, banking),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, id], loan)
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, id] })
      message.success('Loan updated')
    },
    onError: handleErrorResponse,
  })
}
