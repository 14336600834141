import { isBefore, isToday } from 'date-fns'
import { Payout } from 'types'

type IPayoutStatus = 'Paid Out' | 'Pending' | 'Scheduled'

const getCompletedPayoutStatus = (
  payout: Payout,
  { autoInvestorAccounting }: { autoInvestorAccounting: boolean }
): IPayoutStatus => {
  if (payout.isPaidout || (!autoInvestorAccounting && !payout.banking)) {
    return 'Paid Out'
  }
  if (isToday(payout.datePaidout) || isBefore(payout.datePaidout, new Date())) {
    return 'Pending'
  }
  return 'Scheduled'
}

export { getCompletedPayoutStatus }
