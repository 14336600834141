import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { useMobile } from 'hooks/use-mobile'
import { Statement } from 'types'

type Props = {
  statements?: Statement[]
  loading: boolean
  onClick: (id: string) => void
  onDownload: (id: string) => void
}

function TableStatements({
  statements = [],
  loading,
  onClick,
  onDownload,
}: Props) {
  const { isTablet } = useMobile()

  const columns: ColumnDef<Statement>[] = useMemo(
    () => [
      {
        header: 'Name',
        id: 'name',
        cell: ({ row }) => (
          <TextLink onClick={() => onClick(row.original.id)}>
            {row.original.name}
          </TextLink>
        ),
      },
      {
        header: '',
        id: 'download',
        size: 110,
        cell: ({ row }) => (
          <div className={`${isTablet ? '' : 'justify-content-end flex'}`}>
            <TextLink onClick={() => onDownload(row.original.id)}>
              <Icon name={IconName.download} /> Download
            </TextLink>
          </div>
        ),
      },
    ],
    [onClick, onDownload, isTablet]
  )

  return <Table columns={columns} data={statements} loading={loading} />
}

export default TableStatements
