import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { PageLoader } from 'components/LoaderOverlay'
import { TableDocuments } from 'components/TableDocuments'
import { useDownloadDocument } from 'hooks/use-document'
import { LoanDocument } from 'types'

interface Props {
  offeringType: 'offering-fund' | 'offering-loan' | 'individual-fund'
  offeringId: string
  documents: LoanDocument[] | undefined
  loading: boolean
}

function TabDocuments({ offeringType, offeringId, documents, loading }: Props) {
  const navigate = useNavigate()
  const { mutate: downloadDocument } = useDownloadDocument()
  const handleDownload = (id: string) => downloadDocument(id)
  const handleOpen = (id: string) => {
    navigate(pathTo('offeringDocument', offeringType, offeringId, id))
  }

  return loading ? (
    <PageLoader />
  ) : (
    <TableDocuments
      data={documents?.map((doc) => ({
        ...doc,
        isCustom: false,
      }))}
      hideStatus
      hideEllipsesMenu
      showDownload
      onDownload={handleDownload}
      onOpen={handleOpen}
    />
  )
}

export { TabDocuments }
