import { request } from 'services/request'
import { Filter, Search, SentEmail } from 'types'

const getLoanEmails = async ({
  id,
  ...params
}: {
  id: string
  search?: Search
  filter?: Filter
}): Promise<{ emails: SentEmail[] }> => {
  const { data } = await request.get(`/loan/${id}/email/sent`, { params })
  return data
}

export { getLoanEmails }
