import { Formik } from 'formik'
import { debounce, isEmpty, toNumber } from 'lodash'
import { useCallback } from 'react'
import { useAddQuoteAddress } from 'admin/hooks/use-quote-address'
import { useUpdateQuoteField } from 'admin/hooks/use-quotes'
import { Flex } from 'components/Flex'
import { Form, LoanField } from 'components/Form'
import { FormAddress } from 'components/FormAddress'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Field, Quote } from 'types'
import { fieldsToInitialValues } from 'utils/fields'
import { parsePercent } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
}

const Property = ({ quote }: Props) => {
  const { mutate: updateAddress } = useAddQuoteAddress(quote.id)
  const { mutate: updateField } = useUpdateQuoteField(quote.id)
  const debouncedUpdateAddress = debounce(updateAddress, 500)
  let debouncedUpdateField = debounce(updateField, 500)
  let idForDebouncedUpdateField = ''

  const handleAddressChange = useCallback(
    (address) => debouncedUpdateAddress({ address }),
    [updateAddress]
  )

  const handleFieldChange = useCallback(
    (field: Field, event: any) => {
      const id = field.id
      const type = field.type[0]
      let value = event?.target?.value
      if (['currency', 'number'].includes(type)) {
        value = isEmpty(value) ? value : toNumber(value)
      } else if (type === 'percentage') {
        value = isEmpty(value) ? value : parsePercent(value)
      } else if (type === 'option') {
        value = event.value
      }

      if (idForDebouncedUpdateField !== id) {
        idForDebouncedUpdateField = id
        debouncedUpdateField = debounce(updateField, 500)
      }
      debouncedUpdateField({ id, quoteProperty: { value } })
    },
    [updateField]
  )

  const initialFieldValues = fieldsToInitialValues(quote.fields)
  const initialValues = quote.address || {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
  }
  return (
    <Flex stack gap={16} className={styles.panel}>
      <Panel title="Property address">
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={() => {}}
          validateOnChange={false}
        >
          <Form onChange={handleAddressChange}>
            <FormAddress className={styles.propertyAddress} />
          </Form>
        </Formik>
      </Panel>
      {quote.fields.length > 0 && (
        <Panel title="Details">
          <Formik initialValues={initialFieldValues} onSubmit={() => {}}>
            <Form>
              <Grid columnGap={24} className={styles.fields}>
                {quote.fields.map((field) => (
                  <Grid.Item key={field.id} xs={6}>
                    <LoanField
                      field={field}
                      onChange={(value) => handleFieldChange(field, value)}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            </Form>
          </Formik>
        </Panel>
      )}
    </Flex>
  )
}

export { Property }
