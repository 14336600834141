import { isEqual } from 'lodash'
import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { useEditTask } from 'admin/hooks/use-tasks'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DatePicker'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Input } from 'components/Input'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'
import { Task, TaskActivity } from 'types'
import { StatusSelect } from '../StatusSelect'
import { Activities } from './Activities'
import { LoanSelect } from './LoanSelect'
import { Subtasks } from './Subtasks'

interface Props {
  loanId?: string
  task: Partial<Task>
  activities?: TaskActivity[]
  onChange: (task: Partial<Task>) => void
  onDelete: (id: string) => void
  backButtonText?: string
  onBack?: () => void
}

function TaskForm({
  loanId,
  task,
  activities,
  onChange,
  onDelete,
  backButtonText,
  onBack,
}: Props) {
  const descriptionRef = useRef<HTMLTextAreaElement>(null)
  const { options: ownersOptions } = useOwnerSelector()
  const { mutate: update } = useEditTask()
  const selectedOwners = task.owners || []
  const missedOptions =
    selectedOwners?.filter(({ id }) => {
      return !ownersOptions.find((option) => option.id === id)
    }) || []
  const handleOwnerChange = useCallback(
    (owners) => {
      const ownersList =
        owners?.map((id) => {
          return {
            id,
            name: ownersOptions.find(({ id: optionId }) => optionId === id)
              ?.name,
          }
        }) || []

      onChange({ owners: ownersList as Task['owners'] })
    },
    [ownersOptions, onChange]
  )
  const handleTextareaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      event.target.style.height = 'auto'
      event.target.style.height = `${event.target.scrollHeight}px`
      onChange({ description: event.target.value })
    },
    [onChange]
  )

  const handleSubtaskChange = useCallback(
    ({ subtasks }: Partial<Task>) => {
      const shouldMarkDone =
        subtasks?.length && subtasks?.every(({ done }) => done)
      onChange({ subtasks, status: shouldMarkDone ? 'Done' : task.status })
      if (task.id && !isEqual(subtasks, task.subtasks)) {
        update({
          id: task.id,
          subtasks: subtasks?.filter(({ name }) => !!name),
          status: shouldMarkDone ? 'Done' : task.status,
        })
      }
    },
    [onChange, task]
  )

  const handleStatusChange = useCallback(
    (status: string) => {
      onChange({ status: status as Task['status'] })
      if (task.id) {
        update({
          id: task.id,
          status: status as Task['status'],
        })
      }
    },
    [task, onChange]
  )

  useEffect(() => {
    setTimeout(() => {
      if (descriptionRef.current) {
        descriptionRef.current.style.height = 'auto'
        descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight}px`
      }
    }, 1)
  }, [])

  return (
    <Flex justifyContent="space-between" gap={32}>
      <Flex flexDirection="column" className="mt-2 flex-grow">
        <div>
          {loanId ? (
            <Button variant="ghost" onClick={onBack} className="h-7 pl-2 mb-6">
              <Icon
                name={IconName.arrowLeftLong}
                className="text-grey-600"
                size="md"
              />
              <Text className="text-grey-900 pl-1">
                {backButtonText ?? 'Tasks'}
              </Text>
            </Button>
          ) : null}
          <Input
            name="name"
            value={task.name}
            onChange={(e) => onChange({ name: e.target.value })}
            placeholder="Add name for your task"
            className="text-4xl border-none placeholder:font-bold font-bold"
          />
        </div>
        <Textarea
          ref={descriptionRef}
          name="description"
          value={task.description}
          onChange={handleTextareaChange}
          placeholder="Add a description..."
          fieldClassName="h-auto"
          className="border-none text-lg h-8"
        />
        <Subtasks
          subtasks={task.subtasks as Task['subtasks']}
          onChange={handleSubtaskChange}
        />
        <div className="flex-grow" />
        {!!activities?.length && <Activities activities={activities} />}
      </Flex>
      <Flex
        className="w-[200px] flex-shrink-0"
        flexDirection="column"
        alignItems="start"
      >
        <Flex
          justifyContent="space-between"
          alignItems="center"
          className="w-full pt-0.5 pb-3"
        >
          <StatusSelect
            className="w-full"
            size="large"
            status={task.status}
            onSelect={handleStatusChange}
          />
          {task.id && (
            <EllipsesActions
              trigger={
                <Button variant="ghost" className="w-8 h-8">
                  <Icon name={IconName.ellipses} size="md" />
                </Button>
              }
            >
              <EllipsesActions.Item
                icon
                onSelect={() => {
                  onDelete(task.id as string)
                }}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete Task
              </EllipsesActions.Item>
            </EllipsesActions>
          )}
        </Flex>
        <div className="w-full">
          <Text className="text-grey-700 font-normal">Assignee(s)</Text>
          <OwnerSelector
            variant="small"
            selectOwnersText="Select one or more assignees"
            selectedUsers={task.owners?.map(({ id }) => id) || []}
            userOptions={[
              ...ownersOptions,
              ...missedOptions.map((option) => ({ ...option, email: '-' })),
            ]}
            onChange={handleOwnerChange}
          />
        </div>
        <div className="w-full">
          <Text className="text-grey-700 font-normal">Loan</Text>
          <LoanSelect loanId={task?.loanId} onChange={onChange} />
        </div>
        <div>
          <Text className="text-grey-700 font-normal">Due Date</Text>
          <DatePicker
            className="!border-none !pl-0 cursor-pointer"
            value={task.dateDue}
            placeholderText="Add a date"
            hideIcon
            onChange={(date) => onChange({ dateDue: date })}
          />
        </div>
      </Flex>
    </Flex>
  )
}

export { TaskForm }
