import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addQuoteBorrower,
  addQuoteGuarantor,
  removeQuoteBorrower,
  removeQuoteGuarantor,
} from 'admin/services/api/quote-person'
import { KEY_BORROWERS, KEY_QUOTES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from '../../utils/message'
import { updateBorrower } from '../services/api/borrowers'

const useAddQuoteBorrower = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      addQuoteBorrower({ quoteId, personId: id }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
    },
    onError: handleErrorResponse,
  })
}

const useUpdateQuotePerson = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateBorrower,
    onSuccess: (borrower) => {
      queryClient.invalidateQueries({ queryKey: [KEY_BORROWERS, borrower.id] })
      queryClient.invalidateQueries({ queryKey: [KEY_QUOTES, quoteId] })
      message.success('Borrower saved')
    },
    onError: handleErrorResponse,
  })
}

const useAddQuoteGuarantor = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      addQuoteGuarantor({ quoteId, personId: id }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
    },
    onError: handleErrorResponse,
  })
}

const useRemoveQuoteBorrower = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      removeQuoteBorrower({ quoteId, personId: id }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
    },
    onError: handleErrorResponse,
  })
}

const useRemoveQuoteGuarantor = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id }: { id: string }) =>
      removeQuoteGuarantor({ quoteId, personId: id }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddQuoteBorrower,
  useUpdateQuotePerson,
  useAddQuoteGuarantor,
  useRemoveQuoteBorrower,
  useRemoveQuoteGuarantor,
}
