import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { FundingInvestorStatusBadge } from 'components/Badge'
import { Checkbox } from 'components/Checkbox'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { LoanStatus } from 'constants/loan-status'
import { LoanFundingSource } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

type Props = {
  data?: LoanFundingSource[]
  total?: { committed: number; invested: number }
  loading: boolean
  selectedCount: number
  loanStatus?: LoanStatus
  onClick?: (fundingSource?: LoanFundingSource) => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onGenerateAgreement?: (id: string) => void
  wrapperClassName?: string
  masterChecked?: boolean
  selectableIds: string[]
  checkboxes?: string[]
  onCheck?: (id: string) => void
  onFundSelected?: (id: string) => void
}

function TableFundingInvestors({
  data = [],
  total,
  loading,
  loanStatus,
  selectedCount,
  onClick,
  onEdit,
  onDelete,
  onGenerateAgreement,
  masterChecked,
  selectableIds,
  wrapperClassName,
  checkboxes = [],
  onCheck = () => {},
  onFundSelected,
}: Props) {
  const navigate = useNavigate()
  const columns: ColumnDef<LoanFundingSource>[] = useMemo(
    () => [
      {
        header: () => (
          <div className="flex gap-2 -ml-0.5">
            <Checkbox
              disabled={!selectableIds.length}
              checked={masterChecked}
              indeterminate={!masterChecked && !!checkboxes.length}
              onChange={() => onCheck('master')}
            />

            {selectedCount > 0 ? `${selectedCount} Selected` : 'Investor'}
          </div>
        ),
        accessorKey: 'investor.name',
        cell: ({ getValue, row }) => {
          const { id } = row.original

          return (
            <div className="flex gap-2 -ml-0.5">
              <Checkbox
                disabled={!selectableIds.includes(id)}
                checked={checkboxes.includes(id)}
                onChange={() => onCheck(id)}
                onClick={(e) => e.stopPropagation()}
              />
              <div>
                <TextLink
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    navigate(
                      pathTo(
                        row.original.investor?.isFund ? 'fund' : 'investor',
                        row.original.investorId
                      )
                    )
                  }}
                >
                  {(getValue() as string) || '-'}
                </TextLink>
              </div>
            </div>
          )
        },
        meta: {
          plainHeader: 'Investor',
          getTopCellValue: () => <div className="pl-7 font-bold">Total</div>,
        },
      },
      {
        header: 'Yield',
        accessorKey: 'class',
        size: 120,
        cell: ({ row, getValue }) => (
          <Flex stack gap={4}>
            <div>
              {formatPercent(row.original.rate as number, { maxDecimals: 3 })}
            </div>
            <div className={styles.minor}>{getValue() as string}</div>
          </Flex>
        ),
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        header: 'Invested / Committed',
        id: 'invested-committed',
        size: 205,
        cell: ({ row }) => {
          const investedPercent =
            row.original.investedBalance && row.original.committedAmount
              ? (row.original.investedBalance /
                  Math.max(
                    row.original.investedBalance,
                    row.original.committedAmount
                  )) *
                100
              : 0

          return (
            <Flex stack gap={8} className="normal-nums">
              <Flex justifyContent="space-between" gap={8}>
                <Text className="truncate">
                  {formatUsd(row.original.investedBalance, { showZero: true })}
                </Text>
                <Text className="truncate">
                  {formatUsd(row.original.committedAmount, { showZero: true })}
                </Text>
              </Flex>
              <Flex gap={0} className="h-1.5 bg-grey-100 rounded-[3px]">
                <div
                  style={{ width: `${investedPercent}%` }}
                  className="h-full bg-green-50 inline-block rounded-[3px]"
                />
              </Flex>
            </Flex>
          )
        },
        meta: {
          getTopCellValue: () => {
            const investedPercent =
              total?.invested && total?.committed
                ? (total.invested / Math.max(total.invested, total.committed)) *
                  100
                : 0

            return (
              <Flex stack gap={8} className="normal-nums">
                <Flex justifyContent="space-between" gap={8}>
                  <Text className="font-bold truncate">
                    {formatUsd(total?.invested, {
                      showZero: true,
                    })}
                  </Text>
                  <Text className="font-bold truncate">
                    {formatUsd(total?.committed, { showZero: true })}
                  </Text>
                </Flex>
                <Flex gap={0} className="h-1.5 bg-grey-100 rounded-[3px]">
                  <div
                    style={{ width: `${investedPercent}%` }}
                    className="h-full bg-green-50 inline-block rounded-[3px]"
                  />
                </Flex>
              </Flex>
            )
          },
        },
      },
      {
        header: 'Returned',
        accessorKey: 'principalBalance',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          getTopCellValue: ({ rows }) => {
            const total = sumDecimal(
              rows.map((row) => row.original.principalBalance)
            )
            return <div className="font-bold">{formatUsd(total)}</div>
          },
        },
      },
      {
        header: 'Balance',
        accessorKey: 'amount',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          getTopCellValue: ({ rows }) => {
            const total = sumDecimal(rows.map((row) => row.original.amount))
            return <div className="font-bold">{formatUsd(total)}</div>
          },
        },
      },
      {
        header: 'Income',
        accessorKey: 'interestBalance',
        size: 130,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          getTopCellValue: ({ rows }) => {
            const total = sumDecimal(
              rows.map((row) => row.original.interestBalance)
            )
            return <div className="font-bold">{formatUsd(total)}</div>
          },
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const { dateFunded, amount } = row.original
          return (
            <FundingInvestorStatusBadge
              dateFunded={dateFunded}
              amount={amount}
              loanStatus={loanStatus}
            />
          )
        },
        meta: {
          getTopCellValue: () => <div />,
        },
      },
      {
        header: '',
        id: 'actions',
        size: 40,
        cell: ({ row }) => {
          const id = row.original.id as string

          return (
            <EllipsesActions>
              {onGenerateAgreement ? (
                <EllipsesActions.Item
                  icon
                  onSelect={() => onGenerateAgreement(id)}
                >
                  <Icon name={IconName.edit} />
                  Generate Agreement
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              <EllipsesActions.Item icon onSelect={() => onEdit(id)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              {onFundSelected ? (
                <EllipsesActions.Item
                  disabled={
                    row.original.committedAmount ===
                    row.original.investedBalance
                  }
                  icon
                  onSelect={() => onFundSelected(id)}
                >
                  <Icon name={IconName.fund} />
                  Release Funds
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
        meta: {
          getTopCellValue: () => <div />,
        },
      },
    ],
    [masterChecked, onCheck, checkboxes, selectedCount, total]
  )

  return (
    <Table
      className={styles.table}
      wrapperClassName={wrapperClassName}
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
    />
  )
}

export default TableFundingInvestors
