import { DocusealBuilder } from '@docuseal/react'
import { memo, useCallback, useMemo } from 'react'
import { updateDocument as patchDocument } from 'admin/services/api/document'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalFullSize } from 'components/ModalFullSize'
import { useInvalidateDocument } from 'hooks/use-document'
import { Person, LoanAdmin } from 'types'
import { friendlyMime } from 'utils/file'

interface Props {
  signers: (Person | LoanAdmin)[]
  documentId: string
  documentName: string
  documentType: string | null
  documentEsignatureToken: string | undefined
  onEditSigners: () => void
  onSend: () => void
  onClose: () => void
}

const fieldTypes = [
  'signature',
  'initials',
  'date',
  'text',
  'number',
  'checkbox',
  'select',
]

const styles = `
  #fields_list_container {
    background: #fff;
    padding-top: 14px;
    padding: 10px;
    margin-top: 0;
    border-left: 1px solid var(--color-grey-200);
    color: var(--color-grey-900);
  }
  
  
  /* Style for Signers */
  
  #fields_list_container .roles-dropdown {
    margin-bottom: 20px;
    font-size: 13px;
  }
  #fields_list_container .roles-dropdown:before {
    content: 'SIGNERS';
    display: block;
    padding-left: 8px;
    padding-bottom: 6px;
    font-size: 11px;
    font-weight: 700;
    color: var(--color-grey-600);
  }
  #fields_list_container .roles-dropdown > label {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #fields_list_container .roles-dropdown .group\\/contenteditable svg {
    display: none;
  }
  #fields_list_container .dropdown-content{
    border-radius: 8px;
  }
  #fields_list_container .roles-dropdown .active {
    background: transparent;
    color: var(--color-grey-900);
  }
  #fields_list_container .roles-dropdown li a {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  #fields_list_container .roles-dropdown li a:hover {
    background: var(--color-grey-100);
    border-radius: 8px;
  }
  
  
  /* Style for added fields list */
  
  #fields_list_container .fields:not(:empty) {
    margin-bottom: 24px;
  }
  #fields_list_container .fields:not(:empty):before {
    content: 'FOR SIGNER TO FILL';
    display: block;
    padding-left: 8px;
    padding-bottom: 6px;
    font-size: 11px;
    font-weight: 700;
    color: var(--color-grey-600);
  }
  #fields_list_container .fields .list-field {
    color: var(--color-grey-600);
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0;
  }
  #fields_list_container .fields .list-field > div {
    border-radius: 0;
    border-bottom: 0;
  }
  #fields_list_container .fields .list-field:first-child > div {
    border-bottom: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  #fields_list_container .fields .list-field:last-child > div {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid var(--color-grey-200);
  }
  #fields_list_container .fields .list-field > div:first-child {
   padding: 4px;
  }
  #fields_list_container .fields .group\\/contenteditable {
    color: var(--color-grey-900);
  }
  #fields_list_container .fields svg {
    width: 16px;
    height: 16px;
  }
  #fields_list_container .fields .group\\/contenteditable svg {
    position: relative;
    top: -2px;
    color: var(--color-grey-600);
  }
  #fields_list_container .fields .group:hover .group-hover\\:text-base-content {
    color: var(--color-grey-600);
  }
  #fields_list_container .fields .dropdown-content {
    font-weight: normal;
  }
  
  /* Style for fields list */
  
  #fields_list_container .grid:before {
    content: 'FIELDS';
    display: block;
    padding-left: 8px;
    padding-bottom: 6px;
    font-size: 11px;
    font-weight: 700;
    color: var(--color-grey-600);
  }
  #fields_list_container .grid {
    gap: 6px;
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
  }
  #fields_list_container .field-type-button {
    background: var(--color-grey-75) !important;
    border: 0;
    justify-content: flex-start;
  }
  #fields_list_container .field-type-button:hover {
    background: var(--color-grey-100) !important;
  }
  #fields_list_container .field-type-button > div:first-child {
    left: auto;
    right: 8px;
    color: var(--color-grey-600);
    display: none;
  }
  #fields_list_container .field-type-button:hover > div:first-child {
    display: flex;
  }
  #fields_list_container .field-type-button > div:last-child {
    align-items: center;
    flex-direction: row;
    gap: 8px;
    color: var(--color-grey-600);
  }
  #fields_list_container .field-type-button > div:last-child svg {
    width: 16px;
    height: 16px;
  }
  #fields_list_container .field-type-button > div:last-child span {
    margin-top:0;
    font-size: 13px;
    font-weight: 600;
    color: var(--color-grey-900);
  }
`

const ModalSignature = memo(
  ({
    documentId,
    documentName,
    documentType,
    documentEsignatureToken,
    signers,
    onEditSigners,
    onSend,
    onClose,
  }: Props) => {
    const invalidateDocument = useInvalidateDocument(documentId)
    const signerNames = useMemo(() => {
      invalidateDocument()
      return signers.map(({ name }) => name)
    }, [signers])
    const esignatureToken = useMemo(
      () => documentEsignatureToken as string,
      [signers]
    )
    const handleSave = useCallback(
      (detail) =>
        patchDocument({ id: documentId, esignatureSlug: detail.slug }),
      [patchDocument, documentId]
    )

    return (
      <ModalFullSize
        className="!p-0 relative !bg-grey-50"
        contentClassName="max-w-none"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="p-4 bg-white-100 border-0 border-b border-solid border-grey-200"
        >
          <Flex alignItems="center" gap={10}>
            <Button
              variant="ghost"
              className="h-8 w-8 flex-shrink-0 text-grey-600"
              onClick={onClose}
            >
              <Icon name={IconName.close} size="md" className="h-4 w-4" />
            </Button>
            <TextHeader variant="h3">{documentName}</TextHeader>
            <div className="text-grey-600">{friendlyMime(documentType)}</div>
          </Flex>

          <Flex alignItems="center" gap={10}>
            <Button variant="ghost" icon onClick={onEditSigners}>
              <Icon name={IconName.addPerson} />
              Add/Edit Signers
            </Button>
            <Button variant="tertiary" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSend}>
              <Icon name={IconName.send} />
              Send
            </Button>
          </Flex>
        </Flex>

        <DocusealBuilder
          token={esignatureToken}
          roles={signerNames}
          fieldTypes={fieldTypes}
          customCss={styles}
          withTitle={false}
          withSendButton={false}
          withUploadButton={false}
          withAddPageButton={false}
          withSignYourselfButton={false}
          withDocumentsList={false}
          onSave={handleSave}
          onLoad={handleSave}
        />
      </ModalFullSize>
    )
  }
)

ModalSignature.displayName = 'ModalSignature'

export { ModalSignature }
