import { Field } from 'components/Form'
import { Grid } from 'components/Grid'

function PercentageForm() {
  return (
    <Grid.Item xs={12}>
      <Field
        label="Placeholder"
        type="text"
        name="placeholder"
        placeholder="e.g. 7%"
        bottomHint="Include an example inside the field."
      />
    </Grid.Item>
  )
}

export { PercentageForm }
