import clsx from 'clsx'
import { format, isValid, parseISO } from 'date-fns'
import { isArray } from 'lodash'
import { ForwardedRef, forwardRef, useRef, useState } from 'react'
import ReactDatePicker, { DatePickerProps } from 'react-datepicker'
import { IMaskInput, IMask } from 'react-imask'
import styles from 'components/Form/styles.module.scss'
import { Icon, IconName } from 'components/Icon'

type Props = Omit<DatePickerProps, 'onChange' | 'selectsRange'> & {
  selectsRange?: boolean
  selectsMultiple?: any
  showMonthYearDropdown?: any
  onChange?: (date: string) => void
  fieldClassName?: string
  hideIcon?: boolean
}

const DatePicker = forwardRef(
  (
    {
      className,
      name,
      onChange,
      value,
      placeholderText,
      hideIcon,
      selectsRange,
      ...rest
    }: Props,
    ref: ForwardedRef<Props | null>
  ) => {
    const calendarEl = useRef<ReactDatePicker>(null)
    const [open, setOpen] = useState(false)

    return (
      <div className={clsx(styles.iconWrapper)}>
        <ReactDatePicker
          ref={calendarEl}
          id={name}
          name={name}
          selected={
            value && !isArray(value) && isValid(parseISO(value as string))
              ? parseISO(value as string)
              : undefined
          }
          className={clsx(styles.input, styles.inputWithIcon, className)}
          dateFormat="MM/dd/yyyy"
          placeholderText={
            placeholderText ||
            (selectsRange ? 'MM/DD/YYYY - MM/DD/YYYY' : 'MM/DD/YYYY')
          }
          onChange={(date) => {
            if (onChange && selectsRange && isArray(date)) {
              onChange([
                date[0] && isValid(date[0])
                  ? format(date[0], 'yyyy-MM-dd')
                  : '',
                date[1] && isValid(date[1])
                  ? format(date[1], 'yyyy-MM-dd')
                  : '',
              ] as unknown as string)
            } else if (onChange && !selectsRange && !isArray(date)) {
              onChange(date && isValid(date) ? format(date, 'yyyy-MM-dd') : '')
            }
          }}
          autoComplete="off"
          showPopperArrow={false}
          portalId="calendar-portal"
          onKeyDown={(e: any) => {
            if (e.key === 'Tab') {
              setTimeout(() => calendarEl.current?.setOpen(false), 1)
            }
          }}
          selectsRange={selectsRange as any}
          startDate={
            selectsRange &&
            isArray(value) &&
            isValid(parseISO(value[0] as string))
              ? parseISO(value[0] as string)
              : undefined
          }
          endDate={
            selectsRange &&
            isArray(value) &&
            isValid(parseISO(value[1] as string))
              ? parseISO(value[1] as string)
              : undefined
          }
          customInput={
            !selectsRange ? (
              <IMaskInput
                ref={ref}
                mask="MM/DD/YYYY"
                autoComplete="off"
                blocks={{
                  DD: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'DD',
                    from: 1,
                    to: 31,
                    maxLength: 2,
                  } as any,
                  MM: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'MM',
                    from: 1,
                    to: 12,
                    maxLength: 2,
                  } as any,
                  YYYY: {
                    mask: IMask.MaskedRange,
                    placeholderChar: 'YYYY',
                    from: 1900,
                    to: 2200,
                    maxLength: 4,
                  } as any,
                }}
              />
            ) : undefined
          }
          onCalendarOpen={() => setOpen(true)}
          onCalendarClose={() => setOpen(false)}
          {...rest}
        />
        {!hideIcon && (
          <Icon
            name={IconName.calendar}
            size="md"
            className={clsx(styles.icon, { [styles.active]: open })}
            onClick={() => {
              calendarEl.current?.setOpen(true)
              ;(calendarEl.current as any)?.input?.element?.focus()
            }}
          />
        )}
      </div>
    )
  }
)

DatePicker.displayName = 'DatePicker'

export default DatePicker
