import { useCallback } from 'react'
import { Button } from 'components/Button'
import { FiltersListCondition } from 'components/Filter/FiltersListCondition'
import { FiltersListValue } from 'components/Filter/FiltersListValue'
import { getDefaultConditionByType } from 'components/Filter/filter-conditions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'
import { filterIconByType } from './filter-conditions'
import { IFilterType, IFilterValue, IFilterConfig } from './types'

interface IProps {
  filtersConfig: IFilterConfig[]
  selectedFilter: IFilterValue
  onFilterChange: (value: IFilterValue) => void
  onFilterApply: () => void
}

export const FiltersList = ({
  filtersConfig,
  selectedFilter,
  onFilterChange,
  onFilterApply,
}: IProps) => {
  const handleChangeFilterId = useCallback(
    (index: number, nextId: string) => {
      onFilterChange(
        selectedFilter.map((filter, i) => {
          const type = filtersConfig.find(({ id }) => id === nextId)
            ?.type as IFilterType
          return i === index
            ? {
                id: nextId,
                condition: getDefaultConditionByType(type),
                value: type === 'date' ? null : '',
              }
            : filter
        })
      )
    },
    [onFilterChange, selectedFilter]
  )

  const handleChangeFilterCondition = useCallback(
    (index: number, condition: string) => {
      onFilterChange(
        selectedFilter.map((filter, i) => {
          return i === index ? { ...filter, condition } : filter
        })
      )
    },
    [onFilterChange, selectedFilter]
  )

  const handleChangeFilterValue = useCallback(
    (index: number, value: string) => {
      onFilterChange(
        selectedFilter.map((filter, i) => {
          return i === index ? { ...filter, value } : filter
        })
      )
    },
    [onFilterChange, selectedFilter]
  )

  const handleDeleteFilter = useCallback(
    (index: number) => {
      onFilterChange(selectedFilter.filter((_, i) => i !== index))
    },
    [onFilterChange, selectedFilter]
  )

  const handleAddFilter = useCallback(() => {
    const newFilterId = filtersConfig[0].id
    const type = filtersConfig.find(({ id }) => id === newFilterId)
      ?.type as IFilterType

    onFilterChange([
      ...selectedFilter,
      {
        id: newFilterId,
        condition: getDefaultConditionByType(type),
        value: type === 'date' ? null : '',
      },
    ])
  }, [onFilterChange, selectedFilter])

  const handleResetFilter = useCallback(() => {
    onFilterChange([])
  }, [])

  return (
    <>
      <Flex stack gap={16} className="py-1">
        {selectedFilter.map(
          ({ id: selectedFilterId, condition, value }, index) => {
            const selectedFilterConfig = filtersConfig.find(
              ({ id }) => id === selectedFilterId
            )
            return (
              <Flex
                key={`${selectedFilterId}_${index}`}
                gap={10}
                alignItems="flex-start"
              >
                <Select
                  className="w-44"
                  options={filtersConfig.map(({ label, id, type }) => ({
                    label: (
                      <Flex alignItems="center" gap={6}>
                        <Icon
                          name={
                            filterIconByType.get(type) || IconName.boldListUl
                          }
                          className="text-grey-900 flex-shrink-0"
                        />
                        {label}
                      </Flex>
                    ),
                    value: id,
                  }))}
                  value={selectedFilterId}
                  onChange={(option) =>
                    handleChangeFilterId(index, option.value as string)
                  }
                />
                <FiltersListCondition
                  type={selectedFilterConfig?.type as IFilterType}
                  value={condition}
                  onChange={(value) =>
                    handleChangeFilterCondition(index, value)
                  }
                />
                <FiltersListValue
                  type={selectedFilterConfig?.type as IFilterType}
                  inRange={condition === 'between'}
                  value={value}
                  options={selectedFilterConfig?.options}
                  onChange={(value) =>
                    handleChangeFilterValue(index, value as string)
                  }
                />
                <Icon
                  name={IconName.delete}
                  onClick={() => handleDeleteFilter(index)}
                  className="cursor-pointer flex-shrink-0 text-grey-600 hover:text-red-100 mt-2.5"
                />
              </Flex>
            )
          }
        )}

        <Flex justifyContent="space-between">
          <Flex
            gap={8}
            alignItems="center"
            className="mx-1 py-1.5 px-2 rounded hover:bg-grey-75 cursor-pointer"
            onClick={handleAddFilter}
          >
            <Icon name={IconName.plus} size="sm" className="text-grey-600" />
            <div className="text-grey-900 font-bold">New Filter</div>
          </Flex>
          <Flex>
            <Button variant="secondary" onClick={handleResetFilter}>
              Reset
            </Button>
            <Button onClick={onFilterApply}>Apply</Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}
