import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useAutomations,
  useEditAutomation,
  useRemoveAutomation,
} from 'admin/hooks/use-automation'
import { Empty } from 'admin/pages/SettingsAutomations/Empty'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { IAutomation } from 'types'
import { ModalAutomation } from './ModalAutomation'

const SettingsAutomations = () => {
  const navigate = useNavigate()
  const [isAutomationModalOpen, setIsAutomationModalOpen] = useState(false)
  const [editingAutomation, setEditingAutomation] = useState<IAutomation>()
  const [removingId, setRemovingId] = useState<string>()
  const [search, setSearch] = useState<string>()
  const { data } = useAutomations({ search })
  const { mutate: editAutomation } = useEditAutomation()
  const { mutate: removeAutomation, isPending } = useRemoveAutomation()

  return (
    <MainContent>
      {!data ? (
        <PageLoader />
      ) : (
        <Flex stack gap={16}>
          <PageTop title="Automations" />
          <Panel>
            <Flex stack gap={16}>
              <Flex justifyContent="flex-end" alignItems="center">
                <Search onSearch={setSearch} search={search} />
                <Button
                  variant="secondary"
                  onClick={() => navigate(pathTo('settingsAutomationsLog'))}
                >
                  <Icon name={IconName.watch} className="text-grey-600" />
                  Automation Log
                </Button>
                <Button onClick={() => setIsAutomationModalOpen(true)}>
                  <Icon name={IconName.automations} />
                  New Automation
                </Button>
              </Flex>
              {data.automations.length === 0 && (
                <Empty onClick={() => setIsAutomationModalOpen(true)} />
              )}
              {data.automations.length > 0 && (
                <Grid gap={16}>
                  {data.automations.map((automation) => (
                    <Grid.Item
                      onClick={() => {
                        setEditingAutomation(automation)
                        setIsAutomationModalOpen(true)
                      }}
                      key={automation.id}
                      sm={12}
                      md={6}
                      className="p-4 border border-solid border-grey-200 rounded cursor-pointer"
                    >
                      <Flex stack gap={16}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Flex alignItems="center" gap={8}>
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              className="bg-olive-25 text-olive-100 p-1.5 rounded"
                            >
                              <Icon name={IconName.pipeline} />
                            </Flex>
                            <Icon
                              name={IconName.arrowRightLong}
                              className="text-grey-600"
                            />
                            <Flex
                              alignItems="center"
                              justifyContent="center"
                              className="bg-olive-25 text-olive-100 p-1.5 rounded"
                            >
                              <Icon name={IconName.task} />
                            </Flex>
                          </Flex>
                          <Flex alignItems="center" gap={8}>
                            {!automation.isActive && (
                              <Flex
                                alignItems="center"
                                gap={4}
                                className="text-grey-700 -mt-0.5"
                              >
                                <Icon
                                  name={IconName.pause}
                                  className="text-grey-600"
                                />
                                Paused
                              </Flex>
                            )}
                            <EllipsesActions triggerClassName="text-grey-600">
                              {automation.isActive ? (
                                <EllipsesActions.Item
                                  icon
                                  onSelect={() =>
                                    editAutomation({
                                      id: automation.id,
                                      isActive: false,
                                    })
                                  }
                                >
                                  <Icon name={IconName.pause} />
                                  Pause Automation
                                </EllipsesActions.Item>
                              ) : (
                                <EllipsesActions.Item
                                  icon
                                  onSelect={() =>
                                    editAutomation({
                                      id: automation.id,
                                      isActive: true,
                                    })
                                  }
                                >
                                  <Icon name={IconName.play} />
                                  Resume Automation
                                </EllipsesActions.Item>
                              )}
                              <EllipsesActions.Item
                                icon
                                onSelect={() => {
                                  setEditingAutomation(automation)
                                  setIsAutomationModalOpen(true)
                                }}
                              >
                                <Icon name={IconName.edit} />
                                Edit
                              </EllipsesActions.Item>
                              <EllipsesActions.Item
                                icon
                                onSelect={() => setRemovingId(automation.id)}
                                className="text-red-100"
                              >
                                <Icon name={IconName.delete} />
                                Delete
                              </EllipsesActions.Item>
                            </EllipsesActions>
                          </Flex>
                        </Flex>
                        <Flex stack gap={4}>
                          <div className="font-bold">{automation.name}</div>
                          <div className="line-clamp-2">
                            {automation.description}
                          </div>
                        </Flex>
                      </Flex>
                    </Grid.Item>
                  ))}
                </Grid>
              )}
            </Flex>
          </Panel>
        </Flex>
      )}
      {isAutomationModalOpen && (
        <ModalAutomation
          automation={editingAutomation}
          onClose={() => {
            setIsAutomationModalOpen(false)
            setEditingAutomation(undefined)
          }}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="automation"
          loading={isPending}
          onDelete={() =>
            removeAutomation(removingId, {
              onSuccess: () => setRemovingId(undefined),
            })
          }
          onCancel={() => setRemovingId(undefined)}
        />
      )}
    </MainContent>
  )
}

export { SettingsAutomations }
