import { useEffect, useState } from 'react'

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
type Dimension = {
  size: Breakpoint
  width: number
}

enum breakpoints {
  xs = 0,
  sm = 600,
  md = 900,
  lg = 1200,
  xl = 1536,
}

function getSize(width: number, availableSizes: Breakpoint[] = []): Breakpoint {
  if (width >= breakpoints.xl && availableSizes.includes('xl')) {
    return 'xl'
  } else if (width >= breakpoints.lg && availableSizes.includes('lg')) {
    return 'lg'
  } else if (width >= breakpoints.md && availableSizes.includes('md')) {
    return 'md'
  } else if (width >= breakpoints.sm && availableSizes.includes('sm')) {
    return 'sm'
  }
  return 'xs'
}

function useResolutions(
  availableSizes: Breakpoint[] = []
): [Breakpoint, number] {
  const [dimensions, setDimensions] = useState<Dimension>({
    size: getSize(window.innerWidth, availableSizes),
    width: window.innerWidth,
  })
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        size: getSize(window.innerWidth, availableSizes),
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => removeEventListener('resize', handleResize)
  }, [])

  return [dimensions.size, dimensions.width]
}

export type { Breakpoint, Dimension }
export { useResolutions }
