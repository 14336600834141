import { memo } from 'react'
import styles from './styles.module.scss'

const HeaderLoader = memo(() => (
  <tr>
    <th className={styles.th}>
      <div className={styles.header}>&nbsp;</div>
    </th>
  </tr>
))

HeaderLoader.displayName = 'HeaderLoader'

export { HeaderLoader }
