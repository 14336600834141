import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getOfferingRisks,
  addOfferingRisk,
  editOfferingRisk,
  deleteOfferingRisk,
} from 'admin/services/api/offering-risk'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Risk } from 'types'
import { message } from 'utils/message'

const useOfferingRisks = (id: string) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id, 'risk'],
    queryFn: () => getOfferingRisks(id),
    gcTime: 300,
  })
}

const useAddOfferingRisk = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (risk: Partial<Risk>) => addOfferingRisk(id, risk),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id, 'risk'] })
      message.success('Offering risk saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditOfferingRisk = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (risk: Partial<Risk>) => editOfferingRisk(id, risk),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id, 'risk'] })
      message.success('Offering risk saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOfferingRisk = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (riskId: string) => deleteOfferingRisk(id, riskId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id, 'risk'] })
      message.success('Offering risk deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useOfferingRisks,
  useAddOfferingRisk,
  useEditOfferingRisk,
  useDeleteOfferingRisk,
}
