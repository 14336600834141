import { useState, useEffect } from 'react'

export const useMobile = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [isTablet, setIsTablet] = useState(false)

  useEffect(() => {
    const resize = () => {
      setIsMobile(document.documentElement.clientWidth <= 600)
      setIsTablet(document.documentElement.clientWidth <= 900)
    }
    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return { isMobile, isTablet }
}
