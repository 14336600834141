import { ReactNode, useState } from 'react'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface SideNavigationSettingsBlock {
  children: ReactNode
}

function SideNavigationSettingsBlock({
  children,
}: SideNavigationSettingsBlock) {
  const [expanded, expand] = useState(false)

  return (
    <div className={styles.settingsBlock}>
      <div
        className={styles.linkSettings}
        onClick={() => {
          expand(!expanded)
        }}
      >
        <div className={styles.linkSettingsLabel}>
          <Icon name={IconName.settings} size="md" />
          Settings
        </div>
        <Icon name={IconName.arrowDown} size="md" />
      </div>
      {expanded && (
        <div className={styles.settingItemsWrapper}>
          <div
            className={styles.linkSettings}
            onClick={() => {
              expand(!expanded)
            }}
          >
            <div className={styles.linkSettingsLabel}>
              <Icon name={IconName.settings} size="md" />
              Settings
            </div>
            <Icon name={IconName.arrowDown} size="md" />
          </div>
          {children}
        </div>
      )}
    </div>
  )
}

export default SideNavigationSettingsBlock
