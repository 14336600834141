import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListNode, ListItemNode } from '@lexical/list'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import clsx from 'clsx'
import { $getRoot, type LexicalEditor } from 'lexical'
import { uniqueId } from 'lodash'
import { useCallback, useState } from 'react'
import { VariableNode } from './nodes/VariableNode'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import { ContextPlugin } from './plugins/ContextPlugin'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import { FocusPlugin } from './plugins/FocusPlugin'
import { SingleLinePlugin } from './plugins/SingleLinePlugin'
import { VariablePlugin } from './plugins/VariablePlugin'
import styles from './styles.module.scss'

const theme = {
  link: 'editorLink',
  quote: 'editorQuote',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  text: {
    bold: 'editorTextBold',
  },
}

interface Props {
  value: string
  namespace?: string
  placeholder?: string
  className?: string
  singleLine?: boolean
  onChange?: (html: string) => void
  onBlur?: (editor: LexicalEditor) => void
  onFocus?: (editor: LexicalEditor) => void
  onCreate?: (editor: LexicalEditor) => void
}

function VariableEditor({
  className,
  namespace = uniqueId('editor-'),
  placeholder,
  value,
  singleLine,
  onChange,
  onCreate,
  onBlur,
  onFocus,
}: Props) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null)
  const initialConfig = {
    namespace,
    theme,
    onError: () => {},
    editorState: (editor) => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(
        `<div>${value}</div>` || '<p></p>',
        'text/html'
      )
      const nodes = $generateNodesFromDOM(editor, dom)
      $getRoot().append(...nodes)
    },
    nodes: [
      AutoLinkNode,
      LinkNode,
      ListNode,
      ListItemNode,
      HeadingNode,
      QuoteNode,
      VariableNode,
    ],
  }
  const handleChange = useCallback(
    (editorState, editor) => {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null)
        onChange?.(htmlString)
      })
    },
    [onChange]
  )

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      {onCreate && <ContextPlugin onCreate={onCreate} />}
      {(onFocus || onBlur) && <FocusPlugin onFocus={onFocus} onBlur={onBlur} />}
      <RichTextPlugin
        contentEditable={
          <div className={styles.editorContent} ref={onRef}>
            <ContentEditable
              data-placeholder={placeholder}
              className={clsx(
                styles.editorInput,
                styles.variableInput,
                className
              )}
            />
          </div>
        }
        placeholder={<></>}
        ErrorBoundary={LexicalErrorBoundary}
      />
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <AutoLinkPlugin />
      <LinkPlugin />
      <ListPlugin />
      <VariablePlugin />
      <AutoFocusPlugin defaultSelection="rootEnd" />
      {singleLine && <SingleLinePlugin />}
      {floatingAnchorElem && (
        <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
      )}
    </LexicalComposer>
  )
}

export { VariableEditor }
