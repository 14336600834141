import { request } from 'services/request'
import {
  Filter,
  Meta,
  Pagination,
  Search,
  Sort,
  Task,
  TaskActivity,
} from 'types'
import { updateRequestTable } from 'utils/api/table-parser'

const getTasks = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ tasks: Task[]; meta: Meta }> => {
  const {
    data: { tasks, pagination },
  } = await request.get('/task', {
    params: updateRequestTable(params),
  })
  return {
    tasks: tasks.map((task: Task) => ({ ...task, owners: task.owners || [] })),
    meta: pagination,
  }
}

const getTask = async (taskId: string): Promise<Task> => {
  const {
    data: { task },
  } = await request.get(`/task/${taskId}`)
  return task
}

const getTaskActivities = async (taskId: string): Promise<TaskActivity[]> => {
  const {
    data: { activities },
  } = await request.get(`/task/${taskId}/activity`)
  return activities
}

const addTask = async (payload: Partial<Task>): Promise<Task> => {
  const {
    data: { task },
  } = await request.post('/task', payload)
  return task
}

const editTask = async (payload: Partial<Task>): Promise<Task> => {
  const {
    data: { task },
  } = await request.patch(`/task/${payload.id}`, payload)
  return task
}

const removeTask = async (id: string) => {
  return request.delete(`/task/${id}`)
}

export { getTasks, getTask, getTaskActivities, addTask, editTask, removeTask }
