import { FloatingNode, useFloatingNodeId } from '@floating-ui/react'
import clsx from 'clsx'
import { ReactNode, forwardRef, ForwardedRef } from 'react'
import styles from './styles.module.scss'

interface ItemProps {
  children: ReactNode
  variant?: 'default' | 'danger'
  className?: string
  disabled?: boolean
  icon?: boolean
  divider?: boolean
  onSelect: () => void
}

const Item = forwardRef(
  (
    {
      children,
      icon,
      className,
      divider,
      variant = 'default',
      ...rest
    }: ItemProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const nodeId = useFloatingNodeId()
    return (
      <FloatingNode id={nodeId}>
        <div
          role="option"
          ref={ref}
          className={clsx(
            styles.item,
            { [styles.icon]: icon },
            variant === 'default' && 'hover:bg-grey-75',
            variant === 'danger' && 'text-red-100 hover:!bg-red-25',
            className
          )}
          {...rest}
        >
          {children}
        </div>
        {divider && (
          <div className="border-t border-0 border-solid border-grey-200 my-1.5 -mx-1" />
        )}
      </FloatingNode>
    )
  }
)

Item.displayName = 'EllipsesActions.Item'

export type { ItemProps }
export default Item
