import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { fields } from 'admin/pages/Settings/Application/fields'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import styles from 'components/Table/styles.module.scss'
import { CustomApplicationField, PersonField as IPersonField } from 'types'
import {
  PERSON_FIELD_CONTEXT_INDIVIDUAL,
  PERSON_FIELD_CONTEXT_ENTITY,
  PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
} from './constants'

interface Props {
  field: IPersonField
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onDrop: (field: IPersonField, target: IPersonField) => void
  onSelect: (field: IPersonField, value: number) => void
}

const contextOptions = [
  { value: PERSON_FIELD_CONTEXT_INDIVIDUAL, label: 'Individuals Only' },
  { value: PERSON_FIELD_CONTEXT_ENTITY, label: 'Corporations Only' },
  {
    value: PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
    label: 'Corporations and Individuals',
  },
]

function PersonField({ field, onEdit, onDelete, onDrop, onSelect }: Props) {
  const ref = useRef<HTMLTableRowElement>(null)
  const fieldDetails = fields.find(
    ({ type }) => type === field.type[0]
  ) as CustomApplicationField

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: ['item'],
      drop: (item: IPersonField, monitor) => {
        if (monitor.canDrop()) {
          onDrop(item, field)
        }
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [onDrop, field]
  )
  const [{ isDragging }, drag] = useDrag(
    {
      type: 'item',
      item: () => field,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [field]
  )

  drag(drop(ref))

  return (
    <tr
      ref={ref}
      className={clsx(
        'border-grey-100 border-solid border-0 border-b',
        isOver && '!border-blue-100 border-b-[3px]',
        isDragging && 'hidden'
      )}
    >
      <td className={clsx(styles.cell, styles.td)}>
        <Flex alignItems="center" className="cursor-pointer">
          <Icon
            name={IconName.dndHandler}
            className="flex-shrink-0 text-grey-500"
          />
          <Flex alignItems="center">
            <img
              className="h-6 w-6 border-0"
              src={fieldDetails.icon}
              alt={fieldDetails.name}
            />
          </Flex>
          <Flex flexDirection="column" gap={2}>
            <div className="text-grey-900">{field.name}</div>
            <div className="text-grey-700 text-sm">
              {capitalize(field.type[0])}
            </div>
          </Flex>
        </Flex>
      </td>

      <td className={clsx(styles.cell, styles.td)}>
        <div className="inline-block">
          <EllipsesActions
            trigger={
              <Flex
                alignItems="center"
                gap={6}
                className="rounded p-1.5 text-grey-900 hover:bg-grey-100"
              >
                <div className="whitespace-nowrap font-bold">
                  {contextOptions.find(({ value }) => value === field.context)
                    ?.label ??
                    contextOptions.find(
                      ({ value }) =>
                        value === PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY
                    )?.label}
                </div>
                <Icon
                  name={IconName.arrowDownFilled}
                  className="flex-shrink-0"
                />
              </Flex>
            }
          >
            {contextOptions.map(
              ({ value, label }: { value: number; label: string }) => (
                <EllipsesActions.Item
                  key={value}
                  onSelect={() => onSelect(field, value)}
                >
                  {label}
                </EllipsesActions.Item>
              )
            )}
          </EllipsesActions>
        </div>
      </td>
      <td className={clsx(styles.cell, styles.td, 'w-9')}>
        <EllipsesActions>
          <EllipsesActions.Item
            icon
            onSelect={() => {
              onEdit(field.id as string)
            }}
          >
            <Icon name={IconName.edit} />
            Edit Field
          </EllipsesActions.Item>
          <EllipsesActions.Item
            icon
            onSelect={() => {
              onDelete(field.id as string)
            }}
            className="text-red-100"
          >
            <Icon name={IconName.delete} />
            Delete Field
          </EllipsesActions.Item>
        </EllipsesActions>
      </td>
    </tr>
  )
}

export { PersonField }
