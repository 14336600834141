import {
  autoUpdate,
  flip,
  offset,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  shift,
} from '@floating-ui/react'
import { cloneDeep, size } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import { Button } from 'components/Button'
import { FiltersList } from 'components/Filter/FiltersList'
import { filterConditionsByType } from 'components/Filter/filter-conditions'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { InitialFilter } from './InitialFilter'
import { IFilterValue, IFilterConfig, IFilterType } from './types'

interface Props {
  config: IFilterConfig[]
  value: IFilterValue
  onApply: (value: IFilterValue) => void
}

export const Filter = ({ config: filtersConfig, value, onApply }: Props) => {
  const [open, setOpen] = useState(false)
  const [isInitialView, setIsInitialView] = useState(!size(value))
  const [selectedFilter, setSelectedFilter] = useState<IFilterValue>(
    cloneDeep(value)
  )

  const handleOpenChange = useCallback(
    (nextOpen: boolean) => {
      if (!nextOpen) {
        setSelectedFilter(cloneDeep(value))
      }
      setIsInitialView(!size(value))
      setOpen(nextOpen)
    },
    [value]
  )

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [shift(), flip(), offset(8)],
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context, { event: 'click' }),
    useDismiss(context),
    useRole(context, { role: 'tree' }),
  ])

  useEffect(() => {
    setSelectedFilter(cloneDeep(value))
  }, [value])

  useEffect(() => {
    if (selectedFilter.length > 0) {
      setIsInitialView(false)
    }
  }, [selectedFilter])

  return (
    <>
      <Flex
        gap={8}
        alignItems="center"
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        <Button variant="ghost">
          <Text>Filter</Text>
          {!!size(value) && (
            <Flex
              alignItems="center"
              justifyContent="center"
              className="w-5 h-5 rounded-full bg-grey-100"
            >
              <Text variant="xs" className="leading-4">
                {size(value)}
              </Text>
            </Flex>
          )}
        </Button>
      </Flex>
      {open && isInitialView && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[240px] py-1 shadow-300 rounded border-solid border border-grey-200 bg-white-100"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1,
              },
            })}
          >
            <InitialFilter
              filtersConfig={filtersConfig}
              onFilterSelect={(filterId: string) => {
                const selectedFilterType = filtersConfig.find(
                  (filter) => filter.id === filterId
                )?.type as IFilterType
                const initialCondition =
                  filterConditionsByType[selectedFilterType][0].value
                setSelectedFilter([
                  ...selectedFilter,
                  {
                    id: filterId,
                    condition: initialCondition,
                    value: selectedFilterType === 'date' ? null : '',
                  },
                ])
              }}
            />
          </Flex>
        </FloatingPortal>
      )}
      {open && !isInitialView && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[624px] p-4 shadow-300 rounded border-solid border border-grey-200 bg-white-100"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1,
              },
            })}
          >
            <FiltersList
              filtersConfig={filtersConfig}
              selectedFilter={selectedFilter}
              onFilterChange={(filter) => setSelectedFilter(filter)}
              onFilterApply={() => {
                onApply(selectedFilter)
                setOpen(false)
              }}
            />
          </Flex>
        </FloatingPortal>
      )}
    </>
  )
}
