import clsx from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'
import { Icon, IconName } from 'components/Icon'
import Portal from 'components/Modal/Portal'
import { useModal } from 'hooks/use-modal'
import { Content } from './Content'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './styles.module.scss'

interface Props {
  children: ReactNode
  onClose: () => void
  title?: string
  className?: string
  asChild?: boolean
  loading?: boolean
  transparentOverlay?: boolean
  closeOnClickOutside?: boolean
}

function Drawer({
  children,
  className,
  loading,
  onClose,
  transparentOverlay,
  closeOnClickOutside = true,
}: Props) {
  const overlay = useRef<HTMLInputElement>(null)
  const modal = useRef<HTMLInputElement>(null)
  useModal({ onClose, overlay, closeOnClickOutside })

  // focus first input in the drawer
  useEffect(() => {
    const { current: modalEl } = modal
    if (modalEl && !loading) {
      const inputEl = modalEl.querySelector(
        'input:not([placeholder="MM/DD/YYYY"]), textarea'
      ) as HTMLInputElement
      setTimeout(() => inputEl?.focus(), 1)
    }
  }, [loading])

  return (
    <Portal className={styles.portal}>
      <div
        ref={overlay}
        data-esc-closable={true}
        data-overlay={true}
        className={clsx(
          styles.overlay,
          transparentOverlay && styles.transparent
        )}
      />
      {loading ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
        </div>
      ) : (
        <div ref={modal} className={clsx(className, styles.container)}>
          {children}
        </div>
      )}
    </Portal>
  )
}

Drawer.Header = Header
Drawer.Content = Content
Drawer.Footer = Footer

export { Drawer }
