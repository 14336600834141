import { useQuery } from '@tanstack/react-query'
import { getClient, getLogoUrl } from '../services/api/client'

const subdomain =
  import.meta.env.VITE_APP_SUBDOMAIN_OVERRIDE || location.hostname

const useClient = (options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['client', subdomain],
    queryFn: () => getClient(subdomain),
    ...options,
  })
}

const useLogoUrl = (options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: ['client', subdomain, 'logo'],
    queryFn: () => getLogoUrl(subdomain),
    ...options,
  })
}

export { useLogoUrl, useClient }
