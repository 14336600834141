import { omitBy, size } from 'lodash'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TableInvestments } from 'admin/components/table'
import { pathToLoan, pathTo } from 'admin/path-to'
import { InvestorDetails } from 'admin/services/api/investors'
import { ModalFundingHistory } from 'components/Modal/FundingHistory'
import { ModalInvestmentHistory } from 'components/Modal/InvestmentHistory'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { useInvestorFundingSources } from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { LoanFundingSource } from 'types'

function TabInvestments({ investor }: { investor: InvestorDetails }) {
  const { result, filter, sort, setFilter, setSort } = usePagination({
    property: 'fundingSources',
    useData: (params) =>
      useInvestorFundingSources({
        investorId: investor.id,
        params: { filter: params.filter, sort: params.sort },
      }),
  })
  const navigate = useNavigate()
  const [transactionDetails, setTransactionDetails] =
    useState<LoanFundingSource | null>()

  return (
    <Panel>
      <TableInvestments
        data={result.data}
        loading={result.isPending}
        filter={filter}
        sort={sort}
        onSort={setSort}
        onFilter={(newFilter) => {
          setFilter(
            omitBy({ ...filter, ...newFilter }, (value) => !size(value))
          )
        }}
        formatName={(fundingSource) => {
          let path
          if (fundingSource.loan) {
            path = pathToLoan(fundingSource.loan, 'loanFunding')
          } else if (fundingSource.fund) {
            path = pathTo('fund', fundingSource.fund.id, 'funding')
          }
          return (
            <TextLink onClick={() => navigate(path)}>
              {fundingSource.loan?.name || fundingSource.fund?.name || ''}
            </TextLink>
          )
        }}
        onClick={(fundingSource) => {
          if (fundingSource) {
            setTransactionDetails({
              ...fundingSource,
              investor,
            } as LoanFundingSource)
          }
        }}
      />
      {transactionDetails && transactionDetails.loan && (
        <ModalFundingHistory
          title={transactionDetails.loan.name}
          loanId={transactionDetails.loan.id}
          fundingSource={transactionDetails}
          onCancel={() => setTransactionDetails(undefined)}
        />
      )}
      {transactionDetails && transactionDetails.fund && (
        <ModalInvestmentHistory
          fundId={transactionDetails.fund.id}
          fundInvestor={{
            name: transactionDetails.fund.name,
            id: investor.id,
            investmentId: transactionDetails.fund.investmentId,
          }}
          onCancel={() => setTransactionDetails(undefined)}
        />
      )}
    </Panel>
  )
}

export default TabInvestments
