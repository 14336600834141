import { useCallback } from 'react'
import { useInvestorStatements } from 'borrower/hooks/use-investor-statements'
import { pathTo } from 'borrower/path-to'
import { TableStatements } from 'components/TableStatements'
import { useMobile } from 'hooks/use-mobile'
import { downloadPdf } from 'utils/pdf'

interface Props {
  investorId: string
}

export function TabStatements({ investorId }: Props) {
  const { data: statements, isPending } = useInvestorStatements(investorId)
  const { isTablet } = useMobile()
  const handleClick = useCallback(
    (id) => {
      if (isTablet) {
        downloadPdf({ url: pathTo('investorStatement', investorId, id) })
      } else {
        window.open(`${pathTo('investorStatement', investorId, id)}`, '_blank')
      }
    },
    [isTablet]
  )

  return (
    <TableStatements
      statements={statements}
      loading={isPending}
      onClick={handleClick}
      onDownload={(id) =>
        downloadPdf({ url: pathTo('investorStatement', investorId, id) })
      }
    />
  )
}
