import { Notification, Pagination } from 'types'
import { request } from '../request'

const getNotifications = async ({
  pagination,
}: {
  pagination?: Pagination
}): Promise<Notification[]> => {
  const {
    data: { notifications },
  } = await request.get('/notification', { params: { page: pagination } })
  return notifications
}

export { getNotifications }
