import { compact } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { NotificationEditor } from 'admin/components/InlineWysiwyg/NotificationEditor'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'
import { Toggle } from 'components/Toggle'
import {
  useNotificationTemplate,
  useUpdateNotificationTemplate,
  useResetNotificationTemplate,
} from 'hooks/use-notification-settings'
import { NotificationSetting, NotificationSettingTemplate } from 'types'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  saving: boolean
  initialNotificationSettings: NotificationSetting
  onSave: (notification: Partial<NotificationSetting>) => void
  onReset?: () => void
  onCancel: () => void
}

const editorToTemplate = (body: string): string =>
  body?.replace(/<span data-lexical-variable="(.*?)">(.*?)<\/span>/gi, '{{$1}}')

const templateToEditor = (body: string): string =>
  body?.replace(/{{(.*?)}}/gi, '<span data-lexical-variable="$1">$1</span>')

function ModalNotificationSettings({
  initialNotificationSettings,
  saving,
  onSave,
  onCancel,
}: Props) {
  const [notificationSettings, setNotificationSettings] =
    useState<NotificationSetting>({
      ...initialNotificationSettings,
    })
  const [notificationSettingsTemplate, setNotificationSettingsTemplate] =
    useState<NotificationSettingTemplate>()

  const { data: template, isLoading } = useNotificationTemplate(
    notificationSettings.id
  )
  const { mutate: updateTemplate } = useUpdateNotificationTemplate(
    notificationSettings.id
  )
  const { mutate: resetTemplate, isPending: isResetting } =
    useResetNotificationTemplate(notificationSettings.id)

  const handleSave = useCallback(() => {
    updateTemplate(notificationSettingsTemplate as NotificationSettingTemplate)
    onSave(notificationSettings)
  }, [notificationSettingsTemplate, notificationSettings])
  const handleReset = useCallback(() => {
    resetTemplate()
  }, [])

  useEffect(() => {
    setNotificationSettings({
      ...initialNotificationSettings,
    })
  }, [initialNotificationSettings])

  useEffect(() => {
    setNotificationSettingsTemplate(template)
  }, [template])

  return (
    <Modal
      title={
        <span className="text-4xl">
          <span>Edit Notification:</span>{' '}
          <span className="font-normal text-grey-700">
            {initialNotificationSettings.name}
          </span>
        </span>
      }
      loading={isLoading || isResetting}
      onClose={onCancel}
      className={styles.modal}
    >
      <Flex stack gap={16}>
        <Flex stack gap={8}>
          <div>Send as</div>
          <Select
            options={[
              {
                value: '',
                label: 'None',
              },
              {
                value: 'email_push',
                label: 'Email & Notification',
              },
              {
                value: 'email',
                label: 'Email',
              },
              {
                value: 'push',
                label: 'Notification',
              },
            ]}
            value={compact([
              notificationSettings.emailEnabled && 'email',
              notificationSettings.pushEnabled && 'push',
            ]).join('_')}
            onChange={(option) => {
              const isEmailEnabled = (option.value as string).includes('email')
              const isPushEnabled = (option.value as string).includes('push')
              setNotificationSettings({
                ...notificationSettings,
                enabled: isEmailEnabled || isPushEnabled,
                emailEnabled: isEmailEnabled,
                pushEnabled: isPushEnabled,
              })
            }}
          />
        </Flex>
        <NotificationEditor
          subject={templateToEditor(template?.subject as string)}
          body={templateToEditor(template?.body as string)}
          variables={
            template?.params?.map((param) => ({
              value: param,
              label: param,
            })) || []
          }
          onChangeSubject={(subject) => {
            setNotificationSettingsTemplate({
              ...(notificationSettingsTemplate as NotificationSettingTemplate),
              subject: editorToTemplate(subject),
            })
          }}
          onChangeBody={(body) => {
            setNotificationSettingsTemplate({
              ...(notificationSettingsTemplate as NotificationSettingTemplate),
              body: editorToTemplate(body),
            })
          }}
        />
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className="cursor-pointer"
          onClick={() => {}}
        >
          <Toggle
            label="Enabled"
            checked={notificationSettings.enabled}
            onChange={() =>
              setNotificationSettings({
                ...notificationSettings,
                emailEnabled: !notificationSettings.enabled,
                pushEnabled: !notificationSettings.enabled,
                enabled: !notificationSettings.enabled,
              })
            }
          />
          <Flex gap={4} alignItems="center" onClick={handleReset}>
            <Icon name={IconName.restore} />
            Restore to default
          </Flex>
        </Flex>
        <Flex gap={8} justifyContent="flex-end" alignItems="center">
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" loading={saving} onClick={handleSave}>
            Save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export { ModalNotificationSettings }
