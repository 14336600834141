import clsx from 'clsx'
import { compact } from 'lodash'
import { useEffect } from 'react'
import { Line } from 'react-chartjs-2'
import { useChartReport } from 'admin/hooks/use-report'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import theme from 'styles/theme'
import { ReportChartData, ReportChartType } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import {
  lineDefaultOptions,
  datasetOptions,
  registerLineChart,
  getGradient,
} from './chart'
import styles from './styles.module.scss'

interface Params {
  id: ReportChartType
  title: string
  className?: string
  enabled?: boolean
  variant?: 'secondary'
  valueGetter: (row) => number
  onLoad?: () => void
  onClick?: () => void
}

function WidgetChartCurrency({
  id,
  title,
  className,
  valueGetter,
  enabled = true,
  variant,
  onLoad,
  onClick,
}: Params) {
  const {
    data: report,
    isPending,
    isSuccess,
  } = useChartReport(id, {
    enabled,
  })
  useEffect(() => {
    if (onLoad && isSuccess) {
      onLoad()
    }
  }, [isSuccess])

  useEffect(() => {
    registerLineChart()
  }, [])

  const data = {
    labels: (report as unknown as ReportChartData)?.map(({ month }) =>
      formatDate(month, 'MMM yy')
    ),
    datasets: [
      {
        data: (report as unknown as ReportChartData)?.map(valueGetter),
        borderColor: theme.color.blue200,
        fill: true,
        backgroundColor: (context) => getGradient(context.chart.ctx),
        ...datasetOptions,
      },
    ],
  }
  const isEmptyChart = !compact(
    (report as unknown as ReportChartData)?.map(valueGetter)
  ).length
  const beginAtZero = isEmptyChart
  const max = isEmptyChart ? 10 : undefined

  return (
    <div
      className={clsx(styles.widget, onClick && styles.clickable, className)}
      onClick={onClick}
    >
      {isPending ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} className="spinner" size="xl" />
        </div>
      ) : (
        <Flex stack gap={24} className={styles.widgetChart}>
          {variant ? (
            <div className={styles[`title${variant}`]}>{title}</div>
          ) : (
            <Header variant="h2">{title}</Header>
          )}
          <div className={styles.chart}>
            <Line
              options={{
                ...lineDefaultOptions,
                plugins: {
                  ...lineDefaultOptions.plugins,
                  tooltip: {
                    ...lineDefaultOptions.plugins.tooltip,
                    callbacks: {
                      title: (data) => formatUsd(data[0].raw as string),
                      label: (data) => data.label,
                    },
                  },
                  legend: { display: false },
                },
                scales: {
                  ...lineDefaultOptions.scales,
                  y: {
                    ...lineDefaultOptions.scales.y,
                    beginAtZero,
                    max,
                    ticks: {
                      callback: (tickValue) => {
                        return formatUsd(tickValue, {
                          ignoreCents: true,
                          showZero: true,
                        })
                      },
                    },
                  },
                },
              }}
              data={data}
            />
          </div>
        </Flex>
      )}
    </div>
  )
}

export { WidgetChartCurrency }
