import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addQuoteAddress } from 'admin/services/api/quote-address'
import { KEY_QUOTES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Address } from 'types'

const useAddQuoteAddress = (quoteId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ address }: { address: Address }) =>
      addQuoteAddress({ quoteId, address }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quoteId], quote)
    },
    onError: handleErrorResponse,
  })
}

export { useAddQuoteAddress }
