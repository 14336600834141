import { DocusealForm } from '@docuseal/react'
import { PrintTypes } from 'print-js'
import { useEffect, useState } from 'react'
import { useUpdateDocumentSignature } from 'admin/hooks/use-document'
import { ModalCompleteSigning } from 'borrower/components/ModalCompleteSigning'
import { ModalDeclineSigning } from 'borrower/components/ModalDeclineSigning'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalFullSize } from 'components/ModalFullSize'
import {
  useDocument,
  useDownloadDocument,
  useDownloadDocumentAsPdf,
} from 'hooks/use-document'
import { useSession } from 'hooks/use-session'
import {
  declineDocumentWithAuthToken,
  declineDocument,
} from 'services/api/document'
import { LoanDocument } from 'types'
import { friendlyMime, getFileType } from 'utils/file'
import { message } from 'utils/message'

interface Props {
  isPublic?: boolean
  authToken?: string
  documentId?: string
  document?: LoanDocument
  onClose: () => void
}

const ModalSignature = ({
  isPublic,
  authToken,
  documentId,
  document: externalDocument,
  onClose,
}: Props) => {
  const { user } = useSession()
  const [document, setDocument] = useState<LoanDocument | undefined>(
    externalDocument
  )
  const [isDeclining, setIsDeclining] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const { mutate: update } = useUpdateDocumentSignature({ authToken })
  const { data } = useDocument(
    {
      id: documentId as string,
    },
    { enabled: !!documentId }
  )

  useEffect(() => {
    if (data) {
      setDocument(data)
    }
  }, [data])

  const { mutate: downloadDocument } = useDownloadDocument({
    versionId: document?.versions?.[0]?.id,
  })

  const handleDownload = async () => {
    const type = getFileType(document?.versions?.[0]?.type) as PrintTypes
    if (!isPublic && ['image', 'pdf'].includes(type)) {
      downloadDocument(document?.versions?.[0]?.id as string)
    } else {
      useDownloadDocumentAsPdf({
        id: document?.id as string,
        versionId: document?.versions?.[0]?.id,
      })
    }
  }

  const handleDecline = async ({ message: reason }) => {
    message.success('Declined to sign the document')
    if (authToken) {
      await declineDocumentWithAuthToken(
        document?.id as string,
        reason || 'Unknown',
        authToken as string
      )
    } else {
      await declineDocument(document?.id as string, reason || 'Unknown')
    }
    onClose()
  }

  return (
    <ModalFullSize
      loading={!document}
      className="!p-0 relative !bg-grey-50"
      contentClassName="max-w-none"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        className="p-4 bg-white-100 border-0 border-b border-solid border-grey-200"
      >
        <Flex alignItems="center" gap={10}>
          <Button
            variant="ghost"
            className="h-8 w-8 flex-shrink-0 text-grey-600"
            onClick={onClose}
          >
            <Icon name={IconName.close} size="md" className="h-4 w-4" />
          </Button>
          <TextHeader variant="h3">{document?.name}</TextHeader>
          <div className="text-grey-600">{friendlyMime(document?.type)}</div>
        </Flex>

        <Flex alignItems="center" gap={10}>
          <Button variant="ghost" onClick={() => setIsDeclining(true)}>
            Decline
          </Button>
          <Button variant="secondary" onClick={handleDownload}>
            <Icon name={IconName.download} />
            Download
          </Button>
        </Flex>
      </Flex>

      <DocusealForm
        src={`https://docuseal.co/${document?.esignature?.currentSlug ? `r/${document?.esignature?.currentSlug}` : `d/${document?.esignatureSlug}`}`}
        customCss={`
          #expand_form_button,
          #submit_form_button {
            background: var(--color-lime-100);
            border-radius: 8px;
            border: 0;
            text-transform: capitalize;
          }
          #submit_form_button[disabled] {
            background: var(--color-lime-25);
            color: var(--color-lime-200);
            opacity: 0.5;
          }
          #expand_form_button svg {
            color: var(--color-white-100);
            display: none;
          }
          #expand_form_button svg:first-child {
            display: block;
            width: 16px;
            height: 16px;
          }
          #form_container {
            background: var(--color-white-100);
          }
          canvas,
          textarea {
            border-radius: 8px !important;
          }
          .btn {
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.16);
            background: var(--color-grey-25);
            box-shadow: var(--box-shadow-100);
            text-transform: capitalize;
          }
          .btn svg {
            width: 16px;
            height: 16px; 
            color: var(--color-grey-600);
          }
          .base-button {
            border-radius: 8px;
          }
          form > div:last-child::before {
            content: 'By clicking “Sign and Complete”, you agree to the Electronic Signature Disclosure';
            font-size: 14px;
            color: var(--color-grey-700);
            display: flex;
            justify-content: center;
            position: relative;
            top: -8px;
            margin-bottom: 16px;
          }
          #signature_text_input {
            outline: none;
            border-radius: 8px;
          }
          #signature_text_input::placeholder {
            font-weight: 400;
            font-size: 16px;
            color: var(--color-grey-600);
            top: -4px;
            position: relative;
          }
          #signature_text_input:focus {
            border: 1px solid var(--color-blue-100);
          }
        `}
        email={user?.person?.email}
        externalId={user?.person?.id}
        onComplete={() => {
          update(
            {
              documentId: document?.id as string,
              currentSigned: true,
            },
            {
              onSuccess: () => {
                setIsComplete(true)
              },
            }
          )
        }}
        sendCopyEmail={false}
        withTitle={false}
        withDecline={false}
        withDownloadButton={false}
        withSendCopyButton={false}
        logo={`${import.meta.env.VITE_APP_API_URL}/client/${user?.client?.id}/image.png`}
        i18n={{ form_has_been_completed: 'Signing completed!' }}
      />
      {isDeclining && (
        <ModalDeclineSigning
          onCancel={() => setIsDeclining(false)}
          onDecline={handleDecline}
        />
      )}
      {isComplete && <ModalCompleteSigning onClose={onClose} />}
    </ModalFullSize>
  )
}

export { ModalSignature }
