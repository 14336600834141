import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo, pathToLoan } from 'admin/path-to'
import { downloadLoansReport } from 'admin/services/csv/download-loans'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { useLoans } from 'hooks/use-loans'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'
import SoldLoansReportTable from './SoldLoansReportTable'

function SoldLoansReport() {
  const navigate = useNavigate()

  const { visibleItems, result, setPagination } = usePagination<Loan>({
    property: 'loans',
    useData: (params) =>
      useLoans(
        {
          ...params,
          filter: { status: ['sold'] },
        },
        {
          keepPreviousData: true,
        }
      ),
  })

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Sold Loans"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download
            filename="loans"
            download={() =>
              downloadLoansReport({
                status: ['sold'],
              })
            }
          />
        </PageTop>
        <Panel>
          <SoldLoansReportTable
            data={visibleItems}
            loading={result.isPending}
            onClick={(loan) => {
              if (loan) {
                navigate(pathToLoan(loan))
              }
            }}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { SoldLoansReport }
