import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { getLoanActivities } from 'admin/services/api/loan-activities'
import { KEY_ACTIVITIES } from 'constants/query-keys'
import { Filter, Pagination } from 'types'

const useLoanActivities = ({
  loanId,
  search,
  filter,
  pagination,
}: {
  loanId: string
  search?: string
  filter?: Filter
  pagination?: Pagination
}) => {
  return useQuery({
    queryKey: [KEY_ACTIVITIES, loanId, search, filter, pagination],
    queryFn: () =>
      getLoanActivities({ loanId, search, filter, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

export { useLoanActivities }
