import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getFundDistributions,
  getFundDistribution,
  addFundDistribution,
  updateFundDistribution,
  getTemplateUploadUrl,
  downloadFundDistribution,
  publishFundDistribution,
  deleteFundDistribution,
  getFundIncome,
} from 'admin/services/api/fund-distribution'
import { KEY_FUNDS } from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { FundDistribution, Sort } from 'types'
import { message } from 'utils/message'

const useFundDistributions = ({
  fundId,
  params,
}: {
  fundId: string
  params?: { sort?: Sort }
}) => {
  return useQuery({
    queryKey: [KEY_FUNDS, fundId, 'distributions', params?.sort],
    queryFn: () => getFundDistributions({ fundId, ...params }),
  })
}

const useFundDistribution = (id: string, distributionId: string) => {
  return useQuery({
    queryKey: [KEY_FUNDS, id, 'distributions', distributionId],
    queryFn: () => getFundDistribution(id, distributionId),
  })
}

const useAddFundDistribution = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (date: string) => addFundDistribution(id, date),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_FUNDS, id, 'distributions'],
      })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateFundDistribution = (fundId: string, distributionId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload: Partial<FundDistribution>) =>
      updateFundDistribution(fundId, distributionId, payload),
    onSuccess: (distribution) => {
      queryClient.setQueryData(
        [KEY_FUNDS, fundId, 'distributions', distributionId],
        distribution
      )
    },
    onError: handleErrorResponse,
  })
}

const usePublishFundDistribution = (fundId: string, distributionId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => publishFundDistribution(fundId, distributionId),
    onSuccess: (distribution) => {
      queryClient.setQueryData(
        [KEY_FUNDS, fundId, 'distributions', distributionId],
        distribution
      )
      message.success('Distribution published')
    },
    onError: handleErrorResponse,
  })
}

const useUploadFundDistribution = (
  fundId: string,
  distributionId: string,
  onImport: () => void
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (file: File) => {
      const name = file.name.replace(/\.[^/.]+$/, '')
      const uploadToast = message.upload(name)
      uploadToast.show()
      const { url } = await getTemplateUploadUrl(fundId, distributionId)
      const { headers } = await uploadDocument(url, file, (progressEvent) => {
        uploadToast.progress(progressEvent.loaded / progressEvent.total)
      })
      uploadToast.processing()
      const versionId = headers['x-amz-version-id']
      const updatedDistributions = await getFundDistribution(
        fundId,
        distributionId,
        versionId
      )
      uploadToast.complete()
      return updatedDistributions
    },
    onSuccess: (data) => {
      onImport()
      queryClient.setQueryData(
        [KEY_FUNDS, fundId, 'distributions', distributionId],
        data
      )
    },
    onError: handleErrorResponse,
  })
}

const useDownloadFundDistribution = (
  fundId: string,
  distributionId: string
) => {
  return useMutation({
    mutationFn: () => downloadFundDistribution(fundId, distributionId),
    onSuccess: ({ url }) => {
      window.location.href = url
    },
    onError: handleErrorResponse,
  })
}

const useDeleteFundDistribution = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (distributionId: string) =>
      deleteFundDistribution(fundId, distributionId),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_FUNDS, fundId, 'distributions'],
        })
      }, 1)
      message.success('Distribution deleted')
    },
    onError: handleErrorResponse,
  })
}

const useFundIncome = ({
  fundId,
  options,
  params,
}: {
  fundId: string
  options: {
    enabled: boolean
  }
  params: {
    dateStart: string | null
    dateEnd: string | null
  }
}) => {
  return useQuery({
    queryKey: [
      KEY_FUNDS,
      fundId,
      'fundIncome',
      params.dateStart,
      params.dateEnd,
    ],
    queryFn: () => getFundIncome({ fundId, ...params }),
    ...options,
  })
}

export {
  useFundDistributions,
  useFundDistribution,
  useAddFundDistribution,
  useUpdateFundDistribution,
  usePublishFundDistribution,
  useUploadFundDistribution,
  useDownloadFundDistribution,
  useDeleteFundDistribution,
  useFundIncome,
}
