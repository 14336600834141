import clsx from 'clsx'
import { DragEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PanelDrawRequests } from 'components/Panel/PanelDrawRequests'
import { PanelRehabBudget } from 'components/Panel/PanelRehabBudget'
import { PanelScopeOfWorkStructuralChanges } from 'components/Panel/PanelScopeOfWorkStructuralChanges'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { AddressSummary } from 'components/Summary'
import { TabNavigation } from 'components/TabNavigation'
import { useUploadLoanAddressRehabBudget } from 'hooks/use-loan-address'
import { useBudget } from 'hooks/use-loan-budget'
import { getLoanAddressRehabUrl } from 'services/api/loan-address'
import { Loan, Address } from 'types'
import { openBrowseFile } from 'utils/file'
import { message } from 'utils/message'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  isDrawRequestVisible?: boolean
}

function TabScopeOfWork({ loan, isDrawRequestVisible }: Props) {
  const navigate = useNavigate()
  const { entityId: addressId = loan.addresses[0]?.id } = useParams()

  const [isDragActive, setIsDragActive] = useState(false)
  const address = loan.addresses.find(({ id }) => id === addressId) as Address
  const { data: budget, isPending: budgetLoading } = useBudget(
    loan.id,
    address.id
  )
  const { mutate: upload } = useUploadLoanAddressRehabBudget({
    loanId: loan.id,
    addressId,
    uploadUrl: address.rehabUploadUrl,
  })
  const handleDownload = async () => {
    const hideToast = message.progress('Downloading worksheet...', 20000)
    const url = await getLoanAddressRehabUrl(loan.id, addressId)
    hideToast()
    window.location.href = url
  }
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => upload(files[0]),
      accept: '.xlsx',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files[0])
  }

  return (
    <div
      className={styles.tabContent}
      onDragEnter={loan.lock ? () => {} : handleDrag}
    >
      <EllipsesActions
        trigger={
          <div className={clsx(styles.tabButton, styles.withNavigation)}>
            <Button iconRight={<Icon name={IconName.arrowDown} />}>
              Worksheet
            </Button>
          </div>
        }
      >
        <EllipsesActions.Item
          icon
          disabled={loan.lock || budgetLoading || budget?.status === 'Approved'}
          onSelect={() => handleUpload()}
        >
          <Icon name={IconName.upload} />
          Upload
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          onSelect={() => handleDownload()}
          disabled={budgetLoading}
        >
          <Icon name={IconName.download} />
          Download
        </EllipsesActions.Item>
      </EllipsesActions>

      {isDragActive &&
        !(loan.lock || budgetLoading || budget?.status === 'Approved') && (
          <div className={styles.dropZone}>
            <DragDropFile
              onLeave={() => setIsDragActive(false)}
              onDrop={(files) => handleDrop(files)}
              accept={['xlsx']}
            />
          </div>
        )}

      <Flex stack gap={16}>
        {loan.addresses.length > 1 ? (
          <TabNavigation>
            {loan.addresses.map((address) => (
              <TabNavigation.Item
                key={address.id}
                label={address.street1}
                active={address.id === addressId}
                onClick={() =>
                  navigate(
                    pathTo(
                      'loanTabEntityId',
                      loan.id,
                      'scopeOfWork',
                      address.id
                    )
                  )
                }
              />
            ))}
          </TabNavigation>
        ) : (
          <div />
        )}
        <Grid gap={16}>
          <Grid.Item sm={6} className={styles.panels}>
            <PanelLoanFields
              title="Rehab Overview"
              sections={['Rehab Overview']}
              loan={loan}
              address={address}
              includeAtTop={
                <Grid.Item sm={6} key={address.id}>
                  <AddressSummary address={address} />
                </Grid.Item>
              }
              readonly
            />
          </Grid.Item>
          <Grid.Item sm={6} className={styles.panels}>
            <PanelScopeOfWorkStructuralChanges
              loanId={loan.id}
              address={address}
              readonly
            />
          </Grid.Item>
          <Grid.Item sm={12}>
            <PanelRehabBudget loan={loan} address={address} borrower />
          </Grid.Item>
          {isDrawRequestVisible && (
            <Grid.Item sm={12}>
              <PanelDrawRequests loan={loan} address={address} borrower />
            </Grid.Item>
          )}
        </Grid>
      </Flex>
    </div>
  )
}

export { TabScopeOfWork }
