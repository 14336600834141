import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import {
  addAutomation,
  editAutomation,
  getAutomation,
  getAutomations,
  removeAutomation,
  handleAutomationLogAction,
  getAutomationsLogs,
  getTriggerConfigs,
  getConditionConfigs,
  getActionConfigs,
} from 'admin/services/api/automation'
import { KEY_AUTOMATIONS, KEY_AUTOMATIONS_LOG } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Pagination, Search } from 'types'

const useAutomations = ({ search }: { search?: Search }) => {
  return useQuery({
    queryKey: [KEY_AUTOMATIONS, search],
    queryFn: () => getAutomations({ search }),
  })
}

const useAutomationConfig = () => {
  return useQueries({
    queries: [
      { queryKey: ['trigger-configs'], queryFn: () => getTriggerConfigs() },
      { queryKey: ['condition-configs'], queryFn: () => getConditionConfigs() },
      { queryKey: ['action-configs'], queryFn: () => getActionConfigs() },
    ],
    combine: ([triggerResult, conditionResult, actionResult]) => {
      return {
        triggerConfigs: triggerResult?.data?.triggers,
        conditionConfigs: conditionResult?.data?.conditions,
        actionConfigs: actionResult?.data?.actions,
        pending: [triggerResult, conditionResult, actionResult].some(
          (result) => result.isPending
        ),
      }
    },
  })
}

const useAutomation = (automationId: string | undefined) => {
  return useQuery({
    queryKey: [KEY_AUTOMATIONS, automationId],
    queryFn: () => getAutomation(automationId as string),
    enabled: !!automationId,
  })
}

const useAddAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addAutomation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_AUTOMATIONS] })
    },
    onError: handleErrorResponse,
  })
}

const useEditAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editAutomation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_AUTOMATIONS] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveAutomation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeAutomation,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_AUTOMATIONS] })
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

const useHandleAutomationLogAction = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: handleAutomationLogAction,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_AUTOMATIONS_LOG] })
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

const useAutomationsLogs = ({
  search,
  pagination = { page: 0, size: 500 },
}: {
  search?: Search
  pagination?: Pagination
}) => {
  return useQuery({
    queryKey: [KEY_AUTOMATIONS_LOG, search, pagination],
    queryFn: () => getAutomationsLogs({ search, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

export {
  useAutomationConfig,
  useAutomations,
  useAutomation,
  useAddAutomation,
  useEditAutomation,
  useRemoveAutomation,
  useHandleAutomationLogAction,
  useAutomationsLogs,
}
