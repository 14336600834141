import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addLoanDocument,
  generateLoanDocument,
} from 'admin/services/api/loan-documents'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import {
  uploadDocument,
  getDocumentUploadUrl,
  getDocumentVersion,
} from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { LoanDocument } from 'types'
import { message } from 'utils/message'

const useAddLoanDocument = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (document: Partial<LoanDocument>) =>
      addLoanDocument(id, document),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Document created')
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanDocuments = ({ id }: { id: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      files,
      ...document
    }: Partial<LoanDocument> & { files: FileList }) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          const newDocument = await addLoanDocument(id, {
            ...document,
            name,
          })
          const { url } = await getDocumentUploadUrl({
            id: newDocument.id,
            filename: file.name,
            type: file.type,
          })
          const { headers } = await uploadDocument(
            url,
            file,
            (progressEvent) => {
              uploadToast.progress(progressEvent.loaded / progressEvent.total)
            }
          )
          uploadToast.processing()
          const versionId = headers['x-amz-version-id']
          const version = await getDocumentVersion(
            newDocument.id,
            versionId,
            true
          )
          uploadToast.complete()
          return version
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
    },
    onError: handleErrorResponse,
  })
}

const useGenerateLoanDocument = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({
      loanId,
      templateId,
      templateName,
      payload,
    }: {
      loanId: string
      templateId: string
      templateName: string
      payload?: { date?: string; fundingSourceId?: string }
    }) => {
      const dismissToast = message.progress(`Generating ${templateName}`, 20000)
      try {
        const { document, versionId } = await generateLoanDocument(
          loanId,
          templateId,
          payload
        )
        await getDocumentVersion(document.id, versionId, true)
        dismissToast()
        return document
      } catch (e) {
        dismissToast()
        throw e
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
    },
    onError: handleErrorResponse,
  })
}

export { useAddLoanDocument, useAddLoanDocuments, useGenerateLoanDocument }
