import { BorrowerDetails } from 'admin/services/api/borrowers'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'

interface Props {
  user: BorrowerDetails
}

function PanelEmail({ user }: Props) {
  return (
    <Panel title="Email">
      <Summary name="Email">{user.email}</Summary>
    </Panel>
  )
}

export { PanelEmail }
