import { request } from 'services/request'
import { CurrentUser } from '../../../services/api/session'

interface User {
  id: string
  firstName: string | null
  lastName: string | null
  middleName: string | null
  email: string
  name: string
  login: {
    email: string
    dateInvited: string
    dateAccepted: string
    status: 'active'
  }
  permission: 'admin' | 'member' | 'individual'
  roles: { id: string; name: string }[] | null
}

const getUsers = async (clientId: string): Promise<User[]> => {
  const {
    data: { users },
  } = await request.get(`/client/${clientId}/user`)
  return users
}

const addUser = async ({
  clientId,
  name,
  email,
  permission,
  roles,
}: {
  clientId: string
  name: string
  email: string
  permission: User['permission']
  roles: string[]
}) => {
  const {
    data: { user },
  } = await request.post(`/client/${clientId}/user`, {
    name,
    email,
    login: { email },
    permission,
    roles,
  })
  return user
}

const updateUser = async ({
  clientId,
  userId,
  name,
  email,
  permission,
  roles,
}: {
  clientId: string
  userId: string
  name?: string
  email?: string
  permission: User['permission']
  roles?: string[]
}): Promise<User> => {
  const {
    data: { user },
  } = await request.patch(`/client/${clientId}/user/${userId}`, {
    permission,
    name,
    email,
    ...(email ? { login: { email } } : {}),
    roles,
  })
  return user
}

const deleteUser = async ({
  clientId,
  userId,
}: {
  clientId: string
  userId: string
}): Promise<void> => {
  return request.delete(`/client/${clientId}/user/${userId}`)
}

const updateCurrentUser = async (data: {
  name?: string
  email?: string
  password?: string
}): Promise<CurrentUser> => {
  const {
    data: { user },
  } = await request.patch(`/login/current`, data)
  return user
}

const updateCurrentClient = async ({
  clientId,
  name,
  subdomain,
  settings,
}: {
  clientId: string
  name?: string
  subdomain?: string
  settings?: any
}): Promise<User> => {
  const {
    data: { user },
  } = await request.patch(`/client/${clientId}`, { name, subdomain, settings })
  return user
}

export type { User }

export {
  getUsers,
  addUser,
  updateUser,
  deleteUser,
  updateCurrentUser,
  updateCurrentClient,
}
