import { useQueryClient } from '@tanstack/react-query'
import { debounce, get } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import {
  components,
  GroupBase,
  MenuProps,
  SingleValueProps,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import { AssociatedLabels } from 'admin/components/AssociatedLabels'
import { fetchInvestors } from 'admin/hooks/use-investors'
import { useReport } from 'admin/hooks/use-report'
import { Investor } from 'admin/services/api/investors'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Option } from 'components/Select'
import { CreateOption } from 'components/Select/CreateOption'
import { useSession } from 'hooks/use-session'
import { formatUsd } from 'utils/currency'

interface Props {
  defaultOptions: Investor[] | undefined
  excludeFunds?: boolean
  value?: any
  className?: string
  disabled?: boolean
  onSelect: (id: string) => void
  onCreate: (name: string) => void
}

const investorsToOptions = (investors: Investor[]) =>
  investors.map(({ name, id, associatedWith }) => ({
    label: <AssociatedLabels name={name} associatedWith={associatedWith} />,
    value: id,
  }))

export const SelectInvestor = ({
  defaultOptions,
  excludeFunds,
  value,
  disabled,
  className,
  onSelect,
  onCreate,
}: Props) => {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })

  const showDetails = !!defaultOptions?.find((option) => option.associatedWith)

  const showBalance = settings.autoInvestorAccounting !== 'Yes'

  const [inputValue, setInputValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<any>()
  const queryClient = useQueryClient()
  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      fetchInvestors(queryClient, {
        filter: excludeFunds ? { isFund: null } : undefined,
        search: { name: inputValue },
        details: showDetails,
      }).then(({ investors }) => callback(investorsToOptions(investors)))
    }, 500),
    []
  )
  const { data: availableCash, isLoading: isAvailableCashLoading } = useReport(
    `investor/${selectedOption?.value}/available-cash-balance`,
    {
      enabled: !!selectedOption?.value,
    }
  )

  useEffect(() => {
    if (value && value !== selectedOption?.value) {
      fetchInvestors(queryClient, {
        filter: { id: value },
      }).then(({ investors }) => {
        setSelectedOption(investorsToOptions(investors)[0])
      })
    }
  }, [value])

  const Menu = useCallback(
    (props: MenuProps<unknown, boolean, GroupBase<unknown>>) => (
      <div className="absolute top-full w-full z-1">
        <components.Menu className="!relative" {...props}>
          {props.children}
        </components.Menu>
        <CreateOption onClick={() => onCreate(inputValue)}>
          <Flex
            gap={4}
            alignItems="center"
            className="py-2 font-bold text-blue-200 hover:text-black-100"
          >
            <Icon name={IconName.plus} size="sm" />
            Create a new investor
          </Flex>
        </CreateOption>
      </div>
    ),
    [inputValue]
  )

  const SingleValue = ({ children, ...props }: SingleValueProps<unknown>) => (
    <components.SingleValue {...props}>
      <Flex stack gap={4} className="py-1.5">
        <div className="font-bold text-grey-900">{children}</div>
        {showBalance ? (
          <div className="text-grey-700 text-sm">
            {isAvailableCashLoading
              ? '...'
              : `${formatUsd(availableCash, { showZero: true })} Available Cash`}
          </div>
        ) : undefined}
      </Flex>
    </components.SingleValue>
  )

  return (
    <AsyncSelect
      classNamePrefix="formFieldSelect"
      className={className}
      styles={{
        input: (baseStyles) => ({
          ...baseStyles,
          alignSelf: 'flex-start',
          paddingTop: '3px',
        }),
      }}
      value={selectedOption}
      isDisabled={disabled}
      placeholder="Select..."
      menuShouldBlockScroll
      menuPortalTarget={document.getElementById('select-portal')}
      loadOptions={loadSuggestedOptions}
      defaultOptions={investorsToOptions(defaultOptions || [])}
      components={{ Menu, SingleValue }}
      noOptionsMessage={() => <>None found</>}
      onInputChange={(newValue) => setInputValue(newValue)}
      onChange={(option) => {
        setSelectedOption(option)
        onSelect((option as Option)?.value as string)
      }}
    />
  )
}
