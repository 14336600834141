import { useMutation } from '@tanstack/react-query'
import { handleErrorResponse } from 'services/request'
import { downloadPdf } from 'utils/pdf'

const useDownloadPdf = () => {
  return useMutation({
    mutationFn: downloadPdf,
    onError: handleErrorResponse,
  })
}

export { useDownloadPdf }
