import { request } from 'services/request'

const getBillingUrl = async (clientId): Promise<string> => {
  const {
    data: { url },
  } = await request.post(`/client/${clientId}/billing`)
  return url
}

const getPaymentSetupUrl = async (clientId): Promise<string> => {
  const {
    data: { url },
  } = await request.put(`/client/${clientId}/payment`)
  return url
}

const getLoanPaymentUrl = async (loanId): Promise<string> => {
  const {
    data: { url },
  } = await request.put(`/loan/${loanId}/payment`)
  return url
}

export { getBillingUrl, getPaymentSetupUrl, getLoanPaymentUrl }
