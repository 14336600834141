import { Flex } from 'components/Flex'
import { Header } from 'components/Header'

interface Props {
  title: string
}

export const Title = ({ title }: Props) => {
  return (
    <Flex className="mt-3" justifyContent="center">
      <Header className="max-w-115 leading-5" variant="h4">
        {title}
      </Header>
    </Flex>
  )
}
