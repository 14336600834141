import { compact } from 'lodash'
import { ReactElement, ReactNode, useCallback } from 'react'
import ReactSelect, {
  components as defaultComponents,
  Props,
  MenuProps,
  GroupBase,
  OptionProps,
} from 'react-select'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { CreateOptionProps } from 'components/Select/CreateOption'

type Option = {
  label: ReactNode
  value?: string | boolean
  options?: Option[]
}

interface SelectProps extends Omit<Props, 'options' | 'onChange'> {
  portal?: boolean
  createOption?: ReactElement<CreateOptionProps>
  onChange?: (value: Option) => void
  options: Option[]
}

function Select({
  options,
  portal,
  onChange = () => {},
  createOption,
  value,
  isMulti,
  components,
  ...rest
}: SelectProps) {
  const Menu = useCallback(
    (props: MenuProps<unknown, boolean, GroupBase<unknown>>) => (
      <div className="absolute top-full w-full z-1">
        <defaultComponents.Menu className="!relative" {...props}>
          {props.children}
        </defaultComponents.Menu>
        {createOption}
      </div>
    ),
    [createOption]
  )

  const Option = useCallback(
    (props: OptionProps) => {
      return isMulti ? (
        <defaultComponents.Option {...props}>
          <Flex gap={6} alignItems="center">
            <Checkbox checked={props.isSelected} onChange={() => {}} />
            {props.children}
          </Flex>
        </defaultComponents.Option>
      ) : (
        defaultComponents.Option(props)
      )
    },
    [isMulti]
  )

  return (
    <ReactSelect
      classNamePrefix="formFieldSelect"
      menuShouldBlockScroll
      menuPortalTarget={
        portal ? document.getElementById('select-portal') : null
      }
      options={options}
      value={(() => {
        if (Array.isArray(value)) {
          return compact(
            value.map((value) =>
              options.find((option) => option.value === value)
            )
          )
        }
        return options ? options.find((option) => option.value === value) : ''
      })()}
      isClearable={false}
      onChange={(option) => {
        onChange(option as Option)
      }}
      components={{ Menu, Option, ...components }}
      isMulti={isMulti}
      hideSelectedOptions={false}
      closeMenuOnSelect={!isMulti}
      {...rest}
    />
  )
}

export type { Option }
export default Select
