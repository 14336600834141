import clsx from 'clsx'
import { useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { LoanStatus } from 'constants/loan-status'
import { LoanFundingSource } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  fundingSource: LoanFundingSource
  index: number
}

const Badge = ({
  dateFunded,
  amount,
  loanStatus,
}: {
  dateFunded: string
  amount: number
  loanStatus: LoanStatus
}) => {
  type Status = 'Active' | 'Pending' | 'Liquidated'

  const getStatus = ({ dateFunded, amount, loanStatus }): Status => {
    if (dateFunded && amount !== 0 && loanStatus !== 'liquidated') {
      return 'Active'
    }
    if (dateFunded) {
      return 'Liquidated'
    }
    return 'Pending'
  }

  const status = getStatus({ dateFunded, amount, loanStatus })
  return (
    <Flex gap={8} alignItems="center" className="font-bold text-grey-900">
      <Indicator
        color="green"
        className={clsx(
          'w-2.5 h-2.5 flex-shrink-0 rounded-[3px]',
          status === 'Active' && 'bg-green-50',
          status === 'Pending' && 'bg-yellow-100',
          status === 'Liquidated' && 'bg-grey-300'
        )}
      />
      {status}
    </Flex>
  )
}

function PanelMyInvestmentsItem({ fundingSource, index }: Props) {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Flex
      stack
      gap={20}
      key={fundingSource.id}
      className="border border-solid border-grey-200 rounded px-4 py-5"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <div className="text-2xl font-bold">
          {fundingSource.class ?? `Investment ${++index}`}
        </div>

        <Badge
          dateFunded={fundingSource.dateFunded}
          amount={fundingSource.amount}
          loanStatus={fundingSource.loan?.status}
        />
      </Flex>
      <table className={styles.classTable}>
        <tbody>
          <tr>
            <td>
              <Flex stack gap={6}>
                <div className="text-sm text-grey-600">Investment Balance</div>
                <div className="font-bold">
                  {formatUsd(fundingSource.amount, { showZero: true })}
                </div>
              </Flex>
            </td>
            <td>
              <Flex stack gap={6}>
                <div className="text-sm text-grey-600">Principal Invested</div>
                <div className="font-bold">
                  {formatUsd(fundingSource.originalAmount, {
                    showZero: true,
                  })}
                </div>
              </Flex>
            </td>
            <td>
              <Flex stack gap={6}>
                <div className="text-sm text-grey-600">Income</div>
                <div className="font-bold">
                  {formatUsd(fundingSource.interestBalance, { showZero: true })}
                </div>
              </Flex>
            </td>
            <td>
              <Flex stack gap={6}>
                <div className="text-sm text-grey-600">Projected Yield</div>
                <div className="font-bold">
                  {formatPercent(fundingSource.rate)}
                </div>
              </Flex>
            </td>
          </tr>
        </tbody>
      </table>
      <Flex stack gap={16}>
        {isExpanded && (
          <div className="text-grey-700 text-sm">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="border-0 border-b border-grey-200 border-solid py-2"
            >
              <div>Class</div>
              <div>{fundingSource.class}</div>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="border-0 border-b border-grey-200 border-solid py-2"
            >
              <div>Principal Invested</div>
              <div>
                {formatUsd(fundingSource.originalAmount, { showZero: true })}
              </div>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="border-0 border-b border-grey-200 border-solid py-2"
            >
              <div>Principal Returned</div>
              <div>
                {formatUsd(fundingSource.principalBalance, { showZero: true })}
              </div>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="border-0 border-b border-grey-200 border-solid py-2"
            >
              <div>Date Invested</div>
              <div>
                {fundingSource.dateInvested
                  ? formatDate(fundingSource.dateInvested)
                  : '-'}
              </div>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="py-2"
            >
              <div>Projected Yield</div>
              <div>{formatPercent(fundingSource.rate)}</div>
            </Flex>
          </div>
        )}
        <Flex
          className="font-bold cursor-pointer"
          alignItems="center"
          gap={8}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? 'Show less' : 'Show more'}
          <Icon
            name={IconName.arrowDown}
            className={clsx(isExpanded && 'rotate-180')}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export { PanelMyInvestmentsItem }
