import { ColumnDef } from '@tanstack/react-table'
import { isUndefined } from 'lodash'
import { useMemo, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { FundingInvestorStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { LoanFundingSource, Filter, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

type Props = {
  loading: boolean
  filter?: Filter
  data?: LoanFundingSource[]
  sort?: Sort | undefined
  onClick?: (fundingSource?: LoanFundingSource) => void
  onFilter?: Dispatch<SetStateAction<Filter>>
  onSort?: (sort: Sort | string | undefined) => void
}

const getLinkToOffering = (fundingSource: LoanFundingSource) => {
  if (
    fundingSource.offering?.id &&
    fundingSource.offering?.status === 'Published' &&
    fundingSource.offering?.type === 'Fund Offering'
  ) {
    return pathTo('offeringFund', fundingSource.offeringId as string)
  }
  if (
    fundingSource.offering?.id &&
    fundingSource.offering?.status === 'Published' &&
    fundingSource.offering?.type === 'Loan Offering'
  ) {
    return pathTo('offeringLoan', fundingSource.offeringId as string)
  }
  if (fundingSource.fund?.id) {
    return pathTo('offeringIndividualFund', fundingSource.fund?.id as string)
  }
  if (fundingSource.loan?.id) {
    return pathTo('offeringIndividualLoan', fundingSource.loan?.id as string)
  }
  return undefined
}

function TableInvestments({
  data = [],
  loading,
  filter,
  sort,
  onClick,
  onFilter,
  onSort,
}: Props) {
  const navigate = useNavigate()

  const columns: ColumnDef<LoanFundingSource>[] = useMemo(
    () => [
      {
        header: 'Name',
        id: 'name',
        cell: ({ row }) => {
          const url = getLinkToOffering(row.original)
          return url ? (
            <TextLink onClick={() => navigate(url)}>
              {row.original.name}
            </TextLink>
          ) : (
            row.original.name
          )
        },
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Yield',
        id: 'rate',
        size: 120,
        meta: {
          sortable: true,
        },
        cell: ({ row }) => (
          <Flex stack gap={4}>
            <div>
              {formatPercent(row.original.rate as number, {
                blankPlaceholder: '',
              })}
            </div>
            <div className={styles.minor}>{row.original.class}</div>
          </Flex>
        ),
      },
      {
        header: (() => {
          const isAtLeastOneRowShowingMeter = data.some(
            (fundingSource) =>
              fundingSource.investedBalance !== fundingSource.committedAmount
          )
          return isAtLeastOneRowShowingMeter
            ? 'Invested / Committed'
            : 'Invested'
        })(),
        id: 'invested',
        size: 205,
        meta: { sortable: true },
        cell: ({ row }) => {
          if (
            isUndefined(row.original.investedBalance) ||
            isUndefined(row.original.committedAmount)
          ) {
            return (
              <Text className="truncate normal-nums">
                {formatUsd(row.original.originalAmount, { showZero: true })}
              </Text>
            )
          }
          if (row.original.investedBalance === row.original.committedAmount) {
            return (
              <Text className="truncate normal-nums">
                {formatUsd(row.original.investedBalance, { showZero: true })}
              </Text>
            )
          }
          const investedPercent =
            row.original.investedBalance && row.original.committedAmount
              ? (row.original.investedBalance /
                  Math.max(
                    row.original.investedBalance,
                    row.original.committedAmount
                  )) *
                100
              : 0

          return (
            <Flex stack gap={8} className="normal-nums">
              <Flex justifyContent="space-between" gap={8}>
                <Text className="truncate">
                  {formatUsd(row.original.investedBalance, {
                    showZero: true,
                  })}
                </Text>
                <Text className="truncate">
                  {formatUsd(row.original.committedAmount, {
                    showZero: true,
                  })}
                </Text>
              </Flex>
              <Flex gap={0} className="h-1.5 bg-grey-100 rounded-[3px]">
                <div
                  style={{ width: `${investedPercent}%` }}
                  className="h-full bg-green-50 inline-block rounded-[3px]"
                />
              </Flex>
            </Flex>
          )
        },
      },
      {
        header: 'Returned',
        accessorKey: 'principalBalance',
        size: 110,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Balance',
        size: 110,
        accessorKey: 'amount',
        meta: {
          sortable: true,
        },
        cell: ({ getValue }) => formatUsd(getValue() as string),
      },
      {
        header: 'Income',
        accessorKey: 'interestBalance',
        size: 110,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 100,
        meta: onFilter
          ? {
              filter: {
                options: [
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Pending',
                    label: 'Pending',
                  },
                ],
                selected: filter?.status,
                onChange: (status) => onFilter({ status }),
              },
            }
          : undefined,
        cell: ({ row }) => {
          const { dateFunded, amount, loan, fund } = row.original
          return (
            <FundingInvestorStatusBadge
              dateFunded={dateFunded}
              amount={amount}
              loanStatus={loan?.status || (fund && 'Active')}
            />
          )
        },
      },
    ],
    [filter, data]
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onClick={onClick}
      onSort={onSort}
    />
  )
}

export { TableInvestments }
