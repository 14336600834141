import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { Quote } from 'types'

interface Props {
  quote: Quote
  onBackClick: () => void
}

const QuoteInfoPanel = ({ quote, onBackClick }: Props) => {
  return (
    <Panel>
      <Flex stack gap={8}>
        <Flex justifyContent="space-between">
          <Header variant="h4">Loan Info</Header>
          <Button variant="tertiary" size="small" onClick={onBackClick}>
            Edit
          </Button>
        </Flex>
        <Header variant="h3">{quote.address.street1}</Header>
        <Text>{quote.borrowers[0].name}</Text>
      </Flex>
    </Panel>
  )
}

export { QuoteInfoPanel }
