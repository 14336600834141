import { createContext, PropsWithChildren } from 'react'

const LayoutContext = createContext<{
  isTopNavigation: boolean
}>({ isTopNavigation: false })

interface LayoutProviderProps extends PropsWithChildren {
  isTopNavigation: boolean
}

function LayoutProvider({
  children,
  isTopNavigation = false,
}: LayoutProviderProps) {
  return (
    <LayoutContext.Provider value={{ isTopNavigation }}>
      {children}
    </LayoutContext.Provider>
  )
}

export { LayoutContext, LayoutProvider }
