import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { toString } from 'lodash'
import { useMemo } from 'react'
import formStyles from 'components/Form/styles.module.scss'
import { InputCurrency } from 'components/InputCurrency'
import { Table } from 'components/Table'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import styles from './styles.module.scss'
import { ICalculatedCharge, IDistribution } from './types'

interface ITableChargesRow extends ICalculatedCharge {
  onChange: (rowId: string, value: string) => void
}

type Props = {
  chargesItems?: IDistribution['charges']['items']
  onChange: (rowId: string, value: string) => void
  loading: boolean
}

export const TableCharges = ({
  chargesItems = [],
  onChange,
  loading,
}: Props) => {
  const rows = chargesItems?.map((item) => ({
    ...item,
    amount: toString(item?.amount),
    onChange,
  })) as unknown as ITableChargesRow[]

  const columns: ColumnDef<ITableChargesRow>[] = useMemo(
    () => [
      {
        header: 'Description',
        accessorKey: 'name',
      },
      {
        header: 'Due Date',
        accessorKey: 'dateDue',
        cell: ({ getValue }) => formatDate(getValue() as string),
      },
      {
        header: 'Amount Due',
        accessorKey: 'amountDue',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Pay',
        accessorKey: 'amount',
        size: 156,
        cell: ({ row }) => {
          const { amountDue, amount } = row.original
          const isError = amount > amountDue
          return (
            <InputCurrency
              className={clsx(
                styles.alignRight,
                isError && formStyles.errorField
              )}
              value={amount}
              onChange={(e) =>
                row.original.onChange(row.original.id, e.target.value)
              }
            />
          )
        },
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={rows}
      loading={loading}
    />
  )
}
