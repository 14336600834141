import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import {
  getSizerDownloadUrl,
  getSizerUploadUrl,
  runSizer,
  deleteSizer,
  getSizerRuns,
} from 'admin/services/api/sizer'
import { KEY_LOANS, KEY_PRODUCTS } from 'constants/query-keys'
import { uploadDocument } from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useDownloadSizer = () => {
  return useMutation({
    mutationFn: (productId: string) => getSizerDownloadUrl(productId),
    onSuccess: ({ url }) => {
      window.location.href = url
    },
    onError: handleErrorResponse,
  })
}

const useDeleteSizer = () => {
  return useMutation({
    mutationFn: (productId: string) => deleteSizer(productId),
    onSuccess: () => {
      message.success('Sizer reset')
    },
    onError: handleErrorResponse,
  })
}

const useUploadSizer = () => {
  return useMutation({
    mutationFn: async ({
      productId,
      file,
    }: {
      productId: string
      file: File
    }) => {
      const uploadToast = message.upload('sizer')
      uploadToast.show()
      const { url } = await getSizerUploadUrl(productId)
      await uploadDocument(url, file, (progressEvent) => {
        uploadToast.progress(progressEvent.loaded / progressEvent.total)
      })
      uploadToast.complete()
      return true
    },
    onError: handleErrorResponse,
  })
}

const useRunSizer = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ loanId }: { loanId: string }) => {
      const dismissToast = message.progress('Running sizer', 10000)

      await runSizer(loanId)

      dismissToast()
      message.success('Sizer complete')
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      return true
    },
    onError: handleErrorResponse,
  })
}

const useSizerRunHistory = ({ id }: { id: string | undefined }) => {
  if (!id) {
    return { data: [], isLoading: true }
  }
  return useQuery({
    queryKey: [KEY_PRODUCTS, id, 'sizer', 'runs'],
    queryFn: () => getSizerRuns(id),
  })
}

export {
  useDownloadSizer,
  useUploadSizer,
  useRunSizer,
  useDeleteSizer,
  useSizerRunHistory,
}
