import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size, upperCase } from 'lodash'
import { KEY_PAYMENTS_DUE } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination } from 'types/table'
import { message } from '../../utils/message'
import {
  getPaymentsDue,
  paymentsDueCount,
  getPaymentsDueBatchId,
  processPayments,
  getPaymentFileUrl,
} from '../services/api/payments-due'

const usePaymentsDue = ({
  search,
  filter,
  pagination,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PAYMENTS_DUE, search, nextFilter, pagination],
    queryFn: () =>
      getPaymentsDue({ search, filter: nextFilter, page: pagination }),
    placeholderData: keepPreviousData,
  })
}

const usePaymentsDueCount = (
  params: {
    search?: string
    filter?: Filter
    include: string[]
    exclude: string[]
  },
  options: { enabled: boolean }
) => {
  const nextFilter = size(params.filter) ? params.filter : undefined
  return useQuery({
    queryKey: [
      KEY_PAYMENTS_DUE,
      params.search,
      nextFilter,
      params.include.join(''),
      params.exclude.join(''),
      'count',
    ],
    queryFn: () => paymentsDueCount(params),
    placeholderData: keepPreviousData,
    ...options,
  })
}

const useBatchId = (params: {
  search?: string
  filter?: Filter
  include: string[]
  exclude: string[]
}) => {
  const nextFilter = size(params.filter) ? params.filter : undefined
  return useQuery({
    queryKey: [
      KEY_PAYMENTS_DUE,
      params.search,
      nextFilter,
      params.include.join(''),
      params.exclude.join(''),
      'batch',
    ],
    queryFn: () => getPaymentsDueBatchId(params),
  })
}

const useGetPaymentFile = () => {
  return useMutation({
    mutationFn: (params: {
      batchId: string
      type: 'csv' | 'nacha' | 'pdf'
    }) => {
      message.success(`Generating ${upperCase(params.type)} file...`)
      return getPaymentFileUrl(params)
    },
    onSuccess: ({ download }) => {
      if (download) {
        window.location.href = download
      }
    },
    onError: handleErrorResponse,
  })
}

const useProcessPayments = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (params: { batchId: string; pad: boolean }) =>
      processPayments(params.batchId, params.pad),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_PAYMENTS_DUE] })
      message.success('Processing payments')
    },
    onError: handleErrorResponse,
  })
}

export {
  usePaymentsDue,
  usePaymentsDueCount,
  useBatchId,
  useGetPaymentFile,
  useProcessPayments,
}
