import { useCallback, useState } from 'react'
import {
  useCustomFields,
  useEditCustomField,
  useRemoveCustomField,
  useAddCustomField,
} from 'admin/hooks/use-custom-fields'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { BaseField, FieldType } from 'types'
import { ModalField } from './ModalField'
import { TableProductFields } from './TableProductFields'

interface CustomProductField extends BaseField {
  products: {
    id: string
    name: string
  }[]
}

function ProductPanel() {
  const {
    visibleItems,
    result,
    search,
    sort,
    setSort,
    setSearch,
    setPagination,
    resetPagination,
  } = usePagination<CustomProductField>({
    property: 'fields',
    useData: useCustomFields,
  })
  const [adding, setAdding] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: add, isPending: isAdding } = useAddCustomField()
  const { mutate: update, isPending: isUpdating } = useEditCustomField()
  const { mutate: remove, isPending: isRemoving } = useRemoveCustomField()

  const handleSubmit = useCallback(
    (field) => {
      if (editingId) {
        update(
          {
            fieldId: editingId,
            data: {
              ...field,
              type: [field.type] as FieldType[],
              default: {},
            },
          },
          {
            onSuccess: () => {
              resetPagination()
              setEditingId(null)
            },
          }
        )
      } else {
        add(
          { ...field, type: [field.type] as FieldType[], default: {} },
          {
            onSuccess: () => {
              resetPagination()
              setAdding(false)
            },
          }
        )
      }
    },
    [update, add, editingId, resetPagination]
  )

  return (
    <>
      <Panel className="pb-6 max-w-panel">
        <Flex gap={12} justifyContent="space-between" className="pb-3">
          <Header className="text-2xl">Custom Product Fields</Header>
          <Flex>
            <Search
              search={search}
              onSearch={setSearch}
              placeholder="Search custom fields"
            />
            <Button variant="primary" onClick={() => setAdding(true)}>
              Add Custom Field
            </Button>
          </Flex>
        </Flex>
        <TableProductFields
          loading={result.isPending}
          onDelete={setRemovingId}
          onEdit={setEditingId}
          fields={visibleItems}
          onClick={setEditingId}
          sort={sort}
          onSort={setSort}
        />
        <LoadMore
          loading={result.isPending}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
      </Panel>
      {removingId && (
        <ModalDelete
          resource="field"
          loading={isRemoving}
          onDelete={() => {
            remove(removingId, {
              onSuccess: () => {
                resetPagination()
                setRemovingId(null)
              },
            })
          }}
          onCancel={() => setRemovingId(null)}
        />
      )}
      {adding || editingId ? (
        <ModalField
          field={(visibleItems || []).find(({ id }) => id === editingId)}
          onCancel={() => {
            setAdding(false)
            setEditingId(null)
          }}
          saving={isAdding || isUpdating}
          onSave={handleSubmit}
        />
      ) : null}
    </>
  )
}
export { ProductPanel }
