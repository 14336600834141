import { createContext, useContext } from 'react'
import { Loan } from 'types'

interface IProductContext {
  loan?: Loan
  productId?: string
}

const ProductContext = createContext<IProductContext>({} as IProductContext)

const useProductContext = () => useContext(ProductContext)

export { ProductContext, useProductContext }
