import { Field } from 'components/Form'
import { Grid } from 'components/Grid'

function SignatureForm() {
  return (
    <Grid.Item xs={12}>
      <Field
        label="Checkbox Confirmation Text"
        type="text"
        name="signatureConfirmation"
      />
    </Grid.Item>
  )
}

export { SignatureForm }
