import clsx from 'clsx'
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import styles from '../Form/styles.module.scss'

const Input = forwardRef(
  (
    {
      className,
      autoComplete = 'off',
      ...rest
    }: InputHTMLAttributes<HTMLInputElement>,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => (
    <input
      className={clsx(styles.input, className)}
      ref={ref}
      autoComplete={autoComplete}
      {...rest}
    />
  )
)
Input.displayName = 'Input'

export default Input
