import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import styles from './styles.module.scss'

interface Props {
  onClick: () => void
}

const NoOptionsSelected = ({ onClick }: Props) => {
  return (
    <Flex stack gap={24} alignItems="center">
      <Flex stack gap={8} alignItems="center">
        <Header variant="h2">No products selected</Header>
        <div className={styles.alignCenter}>
          Start by selecting one or <br />
          more loan products
        </div>
      </Flex>
      <div>
        <Button onClick={onClick}>Select products</Button>
      </div>
    </Flex>
  )
}

export { NoOptionsSelected }
