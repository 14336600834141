import clsx from 'clsx'
import { HTMLProps, ReactNode } from 'react'
import Item from './Item'
import styles from './styles.module.scss'

interface GridProps extends HTMLProps<HTMLDivElement> {
  cols?: number
  gap?: number
  rowGap?: number
  columnGap?: number
  className?: string
  children: ReactNode
}

function Grid({
  cols = 12,
  gap,
  rowGap,
  columnGap,
  className,
  children,
  ...props
}: GridProps) {
  return (
    <div
      className={clsx(styles.grid, className)}
      style={{
        gridTemplateColumns: `repeat(${cols}, 1fr)`,
        ...(gap ? { gap: `${gap}px` } : {}),
        ...(rowGap ? { rowGap: `${rowGap}px` } : {}),
        ...(columnGap ? { columnGap: `${columnGap}px` } : {}),
      }}
      {...props}
    >
      {children}
    </div>
  )
}

Grid.Item = Item

export default Grid
