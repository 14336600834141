import { isString } from 'lodash'
import { useState, useEffect, useMemo } from 'react'
import { useAddBorrower, useBorrowers } from 'admin/hooks/use-borrowers'
import { useModificationFields } from 'admin/hooks/use-loan-modification'
import { useProduct, useProducts } from 'admin/hooks/use-products'
import { Borrower } from 'admin/services/api/borrowers'
import { Loan } from 'admin/services/api/loans'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { Field } from 'types/field'
import { getFieldValue } from 'utils/fields'
import { Modal } from '../index'
import LoanAddressesForm, {
  initialFormValues as addressFormValues,
  AddressFormValues,
} from './LoanAddressesForm'
import LoanForm, {
  initialFormValues as loanFormValues,
  LoanFormValues,
} from './LoanForm'
import LoanTermsForm from './LoanTermsForm'
import styles from './styles.module.scss'

interface Props {
  include?: ('useOfFunds' | 'product')[]
  saving: boolean
  onSave: (loan) => void
  onCancel: () => void
}

interface FormValues {
  loan: LoanFormValues
  addresses: AddressFormValues[]
  fields: Field[]
}

function ModalAddLoan({ saving, include = [], onSave, onCancel }: Props) {
  const isServicing = include.length === 1
  const [step, setStep] = useState<number | 'adding-person'>(1)
  const [personName, setPersonName] = useState<string>()
  const [createType, setCreateType] = useState<string>('borrowers')
  const [data, setData] = useState<FormValues>({
    loan: loanFormValues,
    addresses: [],
    fields: [],
  })
  const { data: modificationFields, isPending: isLoadingModificationFields } =
    useModificationFields({ enabled: isServicing })
  const { data: products } = useProducts()
  const { data: borrowers } = useBorrowers({
    filter: { is_account: [true] },
  })
  const { data: product, isPending: isProductLoading } = useProduct({
    id: include?.includes('product')
      ? data.loan.productId
      : products?.products[0].id,
  })

  const addBorrower = useAddBorrower()

  useEffect(() => {
    if (product?.fields) {
      setData({
        ...data,
        addresses: [
          {
            ...addressFormValues,
            addressDetails: {
              units: getFieldValue(
                product?.fields,
                'number-units'
              ) as unknown as string,
              propertyType: getFieldValue(
                product?.fields,
                'property-type'
              ) as unknown as string,
            },
          },
        ],
      })
    }
  }, [product?.fields])

  const handleNext = (formValues: unknown | { fields: Field[] }) => {
    if (step === 1) {
      setData({ ...data, loan: formValues as LoanFormValues })
      setStep(2)
    } else if (step === 2) {
      setData({
        ...data,
        ...(formValues as { addresses: AddressFormValues[] }),
      })
      if (isServicing) {
        setStep(3)
      } else {
        onSave({
          ...data.loan,
          ...(formValues as { addresses: AddressFormValues[] }),
        } as unknown as Loan)
      }
    } else if (step === 3) {
      setData({ ...data, ...(formValues as { fields: Field[] }) })
      onSave({
        ...data,
        loan: undefined,
        ...data.loan,
        ...(formValues as { fields: Field[] }),
      } as unknown as Loan)
    }
  }

  const handleNewBorrowerOrGuarantor = (
    formValues: unknown,
    type: string,
    personName?: string
  ) => {
    setPersonName(personName || '')
    setData({ ...data, loan: formValues as LoanFormValues })
    setCreateType(type)
    setStep('adding-person')
  }

  const handleBack = (formValues?) => {
    if (step === 3) {
      setData({ ...data, ...formValues })
    }
    setStep(step ? (step as number) - 1 : 1)
  }

  const title = useMemo(() => {
    switch (step) {
      case 2:
        return 'Enter Property Details'
      case 3:
        return 'Enter Terms'
      default:
        return 'Add New Loan'
    }
  }, [step])

  useEffect(() => {
    if (modificationFields) {
      setData((prevState) => ({
        ...prevState,
        fields: modificationFields,
      }))
    }
  }, [modificationFields])

  return (
    <Modal
      title={title}
      step={{
        steps: isServicing ? 3 : 2,
        step: isString(step) ? 1 : step,
      }}
      asChild
      className={styles.modal}
      onClose={onCancel}
    >
      {step === 1 && (
        <LoanForm
          initialValues={data.loan}
          include={include}
          products={products?.products || []}
          borrowers={borrowers?.borrowers || []}
          onCancel={onCancel}
          onSubmit={handleNext}
          onAddPerson={(formValues, type, personName) =>
            handleNewBorrowerOrGuarantor(formValues, `${type}s`, personName)
          }
        />
      )}

      {step === 2 && (
        <LoanAddressesForm
          initialValues={data.addresses}
          productFields={product?.fields}
          loading={!data.addresses.length || isProductLoading}
          saving={saving}
          submitButtonLabel={isServicing ? 'Next' : 'Save'}
          onBack={handleBack}
          onCancel={onCancel}
          onSubmit={handleNext}
        />
      )}

      {step === 3 && (
        <LoanTermsForm
          loanFields={data.fields}
          loading={
            !data.addresses.length ||
            isProductLoading ||
            isLoadingModificationFields
          }
          saving={saving}
          onBack={handleBack}
          onCancel={onCancel}
          onSubmit={handleNext}
        />
      )}

      {step === 'adding-person' && (
        <ModalAddPerson
          saving={addBorrower.isPending}
          personName={personName}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            addBorrower.mutate(borrower, {
              onSuccess: (person) => {
                setData((prevState) => ({
                  ...prevState,
                  loan: {
                    ...prevState.loan,
                    ...{
                      [createType]: [...prevState.loan[createType], person.id],
                    },
                  },
                }))
                setStep(1)
              },
            })
          }
          onCancel={() => {
            setStep(1)
          }}
        />
      )}
    </Modal>
  )
}

export default ModalAddLoan
