import { ReactNode } from 'react'
import styles from './styles.module.scss'

interface KeyProps {
  children: ReactNode
}

function Key({ children }: KeyProps) {
  return <div className={styles.key}>{children}</div>
}

export type { KeyProps }
export default Key
