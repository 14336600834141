import { useState } from 'react'
import { ModalAddMember } from 'borrower/components/Modal/AddMember'
import { TableMembers } from 'borrower/components/TableMembers'
import { Button } from 'components/Button'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import {
  useAddMember,
  useMembers,
  useReInviteMember,
  useRemoveMember,
  useUpdateMember,
} from 'hooks/use-members'
import { useSession } from 'hooks/use-session'

function PanelMembers() {
  const { user } = useSession()
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)
  const [removingId, setRemovingId] = useState<string | null>(null)
  const managerId = user?.actor?.id as string

  const type = user?.actor?.isInvestor ? 'investor' : 'borrower'
  const { data, isPending } = useMembers(
    { managerId, type },
    { enabled: !!managerId }
  )
  const { mutate: add, isPending: isAdding } = useAddMember({
    managerId,
    type,
  })
  const { mutate: update } = useUpdateMember({
    managerId,
    type,
  })
  const { mutate: reinvite } = useReInviteMember({
    managerId,
    type,
  })
  const { mutate: remove, isPending: isRemoving } = useRemoveMember({
    managerId,
    type,
  })

  return (
    <Panel
      title="Members"
      actionChildren={
        <Button variant="primary" onClick={() => setIsAddModalVisible(true)}>
          Add Member
        </Button>
      }
    >
      <TableMembers
        data={(data?.borrower || data?.investor || {}).managers ?? []}
        loading={isPending}
        onChangePermission={(memberId, role) => {
          update({ memberId, role })
        }}
        onDelete={(id) => setRemovingId(id)}
        onReinvite={(memberId) => reinvite({ memberId })}
      />
      {isAddModalVisible && (
        <ModalAddMember
          saving={isAdding}
          onSubmit={(user) =>
            add(user, {
              onSuccess: () => {
                setIsAddModalVisible(false)
              },
            })
          }
          onCancel={() => setIsAddModalVisible(false)}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="member"
          loading={isRemoving}
          onDelete={() =>
            remove(
              { memberId: removingId },
              {
                onSuccess: () => {
                  setRemovingId(null)
                },
              }
            )
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </Panel>
  )
}

export { PanelMembers }
