import clsx from 'clsx'
import {
  ReactNode,
  HTMLAttributes,
  PropsWithChildren,
  forwardRef,
  ForwardedRef,
  memo,
} from 'react'
import styles from './styles.module.scss'
import type { Property } from 'csstype'

interface Props extends HTMLAttributes<HTMLDivElement>, PropsWithChildren {
  gap?: number
  justifyContent?: Property.JustifyContent
  alignItems?: Property.AlignItems
  flexDirection?: Property.FlexDirection
  flexWrap?: Property.FlexWrap
  flexGrow?: Property.FlexGrow
  className?: string
  stack?: boolean
  children: ReactNode
}

const Flex = memo(
  forwardRef(
    (
      {
        gap = 16,
        justifyContent = 'normal',
        alignItems = 'normal',
        flexGrow,
        flexDirection,
        flexWrap,
        stack,
        className,
        children,
        style,
        ...rest
      }: Props,
      ref: ForwardedRef<HTMLDivElement | null>
    ) => {
      return (
        <div
          className={clsx(styles.flex, className)}
          style={{
            justifyContent,
            alignItems,
            flexDirection: flexDirection
              ? flexDirection
              : stack
                ? 'column'
                : 'row',
            ...(flexGrow ? { flexGrow } : {}),
            ...(flexWrap ? { flexWrap } : {}),
            ...(gap ? { gap: `${gap}px` } : {}),
            ...style,
          }}
          ref={ref}
          {...rest}
        >
          {children}
        </div>
      )
    }
  )
)

Flex.displayName = 'Flex'

export default Flex
