import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getTask,
  getTasks,
  getTaskActivities,
  addTask,
  editTask,
  removeTask,
} from 'admin/services/api/tasks'
import { KEY_TASKS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'

const useTasks = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details = false,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_TASKS, search, nextFilter, pagination, sort, details],
    queryFn: () =>
      getTasks({ search, filter: nextFilter, page: pagination, sort, details }),
    placeholderData: keepPreviousData,
  })
}

const useTask = (taskId: string | undefined) => {
  return useQuery({
    queryKey: [KEY_TASKS, taskId],
    queryFn: () => getTask(taskId as string),
    enabled: !!taskId,
    staleTime: 0,
    gcTime: 0,
  })
}

const useTaskActivities = (taskId: string | undefined) => {
  return useQuery({
    queryKey: [KEY_TASKS, taskId, 'activity'],
    queryFn: () => getTaskActivities(taskId as string),
    enabled: !!taskId,
  })
}

const useAddTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TASKS] })
    },
    onError: handleErrorResponse,
  })
}

const useEditTask = (
  { invalidateQueries }: { invalidateQueries: boolean } = {
    invalidateQueries: true,
  }
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editTask,
    onSuccess: () => {
      if (invalidateQueries) {
        queryClient.invalidateQueries({ queryKey: [KEY_TASKS] })
      }
    },
    onError: handleErrorResponse,
  })
}

const useRemoveTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeTask,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_TASKS] })
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

export {
  useTasks,
  useTask,
  useTaskActivities,
  useAddTask,
  useEditTask,
  useRemoveTask,
}
