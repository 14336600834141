import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getCustomFields,
  getCustomField,
  addCustomField,
  editCustomField,
  deleteCustomTask,
} from 'admin/services/api/custom-fields'
import { KEY_CUSTOM_FIELDS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort } from 'types'
import { message } from 'utils/message'

const useCustomFields = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details = false,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_CUSTOM_FIELDS,
      search,
      nextFilter,
      pagination,
      sort,
      details,
    ],
    queryFn: () =>
      getCustomFields({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
  })
}

const useCustomField = (fieldId: string | undefined) => {
  return useQuery({
    queryKey: [KEY_CUSTOM_FIELDS, fieldId],
    queryFn: () => getCustomField(fieldId as string),
  })
}

const useAddCustomField = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addCustomField,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_FIELDS] })
      message.success('Custom field saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditCustomField = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editCustomField,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_FIELDS] })
      message.success('Custom field saved')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveCustomField = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteCustomTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_CUSTOM_FIELDS] })
      message.success('Custom field deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useCustomFields,
  useCustomField,
  useAddCustomField,
  useEditCustomField,
  useRemoveCustomField,
}
