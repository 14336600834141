import { Row } from 'types'
import { formatDate } from 'utils/date'
import { getReport } from '../api/reports'

const downloadDrawRequests = async (params): Promise<Row[]> => {
  const { draws } = await getReport({ type: 'draw-requests', params })

  return draws.map((draw) => ({
    Date: formatDate(draw.dateSubmitted),
    'Loan Name': draw.loan.name,
    Address: draw.address.street1,
    Requested: draw.amountRequested,
    Approved: draw.balance,
    Status: draw.status,
  }))
}

export { downloadDrawRequests }
