import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Field, Form, Phone } from 'components/Form'
import { Grid } from 'components/Grid'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface FormValues {
  email: string
  name: string
  phone: string
}

interface Props {
  initialValues: FormValues
  saving: boolean
  onClose: () => void
  onSubmit: (values: FormValues) => void
}

function ModalEditPersonalInfo({
  onClose,
  onSubmit,
  saving,
  initialValues,
}: Props) {
  const Schema = createScheme({
    email: mergeRules(email, required),
    name: required,
  })

  return (
    <Modal title="Edit Personal Information" onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="text"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}
export { ModalEditPersonalInfo }
