import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {
  CLEAR_EDITOR_COMMAND,
  KEY_ENTER_COMMAND,
  COMMAND_PRIORITY_HIGH,
} from 'lexical'
import { useEffect } from 'react'

interface Props {
  onSubmit?: () => void
}

const EnterPlugin = ({ onSubmit = () => {} }: Props) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent) => {
        const { shiftKey, key } = event

        if (key === 'Enter' && !shiftKey) {
          event.preventDefault()
          onSubmit()
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
        }

        return true
      },
      COMMAND_PRIORITY_HIGH
    )
  }, [editor, onSubmit])

  return null
}

export { EnterPlugin }
