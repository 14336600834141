const loanTypes: { name: string; description: string }[] = [
  {
    name: 'Bridge + Rehab',
    description:
      'A financing option that combines short-term bridge funding with funds for property rehabilitation or renovation.',
  },
  {
    name: 'Rentals',
    description:
      "Debt Service Coverage Ratio (DSCR) loan, where the borrower's ability to cover loan payments from property income is a key consideration.",
  },
  {
    name: 'Bridge',
    description:
      'A temporary loan providing interim financing typically used to bridge a financial gap until longer-term financing is secured.',
  },
  {
    name: 'Construction',
    description:
      'Financing specifically designed to fund the construction of a new property or building.',
  },
]

const transactionTypes = ['Purchase', 'Refinance', 'Cashout', 'Construction']

export { loanTypes, transactionTypes }
