import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Table } from 'components/Table'
import { ChargeTransaction } from 'types/charge'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

type Props = {
  data?: ChargeTransaction[]
  loading: boolean
}

export const TableChargesHistory = ({ data = [], loading }: Props) => {
  const columns: ColumnDef<ChargeTransaction>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        size: 140,
        cell: ({ getValue }) => formatDate(getValue() as string),
      },
      {
        header: 'Payment Method',
        id: 'payment.method',
        size: 130,
        cell: ({ row }) => row.original.payment?.method || '-',
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 100,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}
