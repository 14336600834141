import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import imagePlaceholder from 'images/offering-placeholder.png'
import { OfferingCollateral } from 'types'
import { formatUsd } from 'utils/currency'

function TableCollateral({
  data = [],
  loading,
  onClick,
}: {
  data?: OfferingCollateral[]
  loading: boolean
  onClick: (collateral?: OfferingCollateral) => void
}) {
  const columns: ColumnDef<OfferingCollateral>[] = useMemo(
    () => [
      {
        header: 'Loan',
        key: 'name',
        cell: ({ row }) => {
          const image = row.original.image?.url || imagePlaceholder
          return (
            <Flex alignItems="center" gap={10}>
              <img src={image} className="w-9 h-9 rounded-sm object-cover" />
              <div className="font-bold">{row.original.name}</div>
            </Flex>
          )
        },
      },
      {
        header: 'Lien Position',
        accessorKey: 'lienPosition',
        cell: ({ getValue }) => getValue() || '-',
      },
      {
        header: 'Principal Balance',
        accessorKey: 'principalBalance',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
        size: 140,
      },
      {
        header: 'Months Remaining',
        accessorKey: 'monthsRemaining',
        cell: ({ getValue }) => getValue() as string,
        meta: {
          align: 'right',
        },
        size: 140,
      },
    ],
    []
  )

  return (
    <Table columns={columns} data={data} loading={loading} onClick={onClick} />
  )
}

export { TableCollateral }
