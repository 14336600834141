import { request } from 'services/request'
import { Phase } from 'types'

const parseSpread = (spread: Phase): Phase => ({
  ...spread,
  people: spread.people?.map((person) => ({
    ...person,
    percentage: person.percentage * 100,
  })),
})

const formatSpread = (spread: Phase): Phase => ({
  ...spread,
  people: spread.people?.map((person) => ({
    ...person,
    percentage: person.percentage / 100,
  })),
})

const getLoanSpreads = async (loanId: string): Promise<Phase[]> => {
  const {
    data: { spreads },
  } = await request.get(`/loan/${loanId}/spread`)
  return spreads.map((spread: Phase) => parseSpread(spread))
}

const getLoanSpreadCurrent = async (loanId: string): Promise<Phase> => {
  try {
    const {
      data: { spread },
    } = await request.get(`/loan/${loanId}/spread/current`)
    return parseSpread(spread)
  } catch (error: any) {
    if ([404, 410].includes(error?.response?.status)) {
      return {
        id: undefined,
        dateEffective: [null, null],
        defaultRecipientId: undefined,
        people: [],
      }
    }
    throw error
  }
}

const addLoanSpread = async (
  loanId: string,
  payload: Phase
): Promise<Phase> => {
  const {
    data: { spread },
  } = await request.post(`/loan/${loanId}/spread`, formatSpread(payload))
  return parseSpread(spread)
}

const updateLoanSpread = async (
  loanId: string,
  spreadId: string,
  payload: Phase
): Promise<Phase> => {
  const {
    data: { spread },
  } = await request.patch(
    `/loan/${loanId}/spread/${spreadId}`,
    formatSpread(payload)
  )
  return parseSpread(spread)
}

const deleteLoanSpread = async (
  loanId: string,
  spreadId: string
): Promise<void> => {
  await request.delete(`/loan/${loanId}/spread/${spreadId}`)
}

export {
  getLoanSpreads,
  getLoanSpreadCurrent,
  addLoanSpread,
  updateLoanSpread,
  deleteLoanSpread,
}
