import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  onClick: () => void
}

export const Empty = ({ onClick }: Props) => {
  return (
    <Flex
      stack
      className="pt-[44px] pb-20 max-w-[460px] mx-auto"
      justifyContent="center"
      gap={24}
    >
      <Flex
        className="w-20 h-20 bg-olive-25 m-auto rounded-[20px]"
        gap={16}
        alignItems="center"
        justifyContent="center"
      >
        <Icon
          className="w-12 h-12 text-olive-100"
          name={IconName.automations}
        />
      </Flex>
      <Flex
        gap={12}
        className="mt-2 leading-5"
        justifyContent="center"
        alignItems="center"
        stack
      >
        <div className="text-grey-900 text-2xl font-bold">
          Create your first automation
        </div>
        <div className="text-center text-lg text-grey-700">
          Automate everything from creating tasks, to generating documents and
          sending notifications.
        </div>
      </Flex>

      <Flex>
        <Button className="m-auto inline p-2" onClick={onClick}>
          Create Automation
        </Button>
      </Flex>
    </Flex>
  )
}
