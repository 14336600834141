import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { Avatar } from 'components/Avatar'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Table } from 'components/Table'
import { PhaseRecipient } from 'types'
import { formatPercent } from 'utils/percent'

type Props = {
  data: (PhaseRecipient & { isDefault?: boolean })[]
}

function TableSpreadAllocation({ data = [] }: Props) {
  const columns: ColumnDef<PhaseRecipient & { isDefault?: boolean }>[] =
    useMemo(
      () => [
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ getValue, row }) => (
            <Flex gap={8} alignItems="center">
              <Avatar name={getValue() as string} id={row.original?.id} />
              <div>{getValue() as string}</div>
              {row.original?.isDefault && (
                <Badge color="gray">Surplus and Deficit</Badge>
              )}
            </Flex>
          ),
        },
        {
          header: data.length ? 'Rate' : '',
          accessorKey: 'percentage',
          size: 100,
          cell: ({ row }) =>
            formatPercent(row.original?.percentage, {
              maxDecimals: 3,
              showZero: true,
            }),
          meta: {
            align: 'right',
          },
        },
      ],
      [data]
    )

  return <Table columns={columns} data={data} className="w-full" />
}

export { TableSpreadAllocation }
