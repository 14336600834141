import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getLoanSpreads,
  getLoanSpreadCurrent,
  addLoanSpread,
  updateLoanSpread,
  deleteLoanSpread,
} from 'admin/services/api/loan-spread'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Phase } from 'types'

const useLoanSpreads = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, 'spread'],
    queryFn: () => getLoanSpreads(loanId),
  })
}

const useLoanSpreadCurrent = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, 'spread', 'current'],
    queryFn: () => getLoanSpreadCurrent(loanId),
  })
}

const useInvalidateSpreadCurrent = (loanId: string) => {
  const queryClient = useQueryClient()

  return () =>
    queryClient.invalidateQueries({
      queryKey: [KEY_LOANS, loanId, 'spread', 'current'],
    })
}

const useAddLoanSpread = (loanId: string) => {
  return useMutation({
    mutationFn: (payload: Phase) => addLoanSpread(loanId, payload),
    onError: handleErrorResponse,
  })
}

const useUpdateLoanSpread = (loanId: string) => {
  return useMutation({
    mutationFn: ({ id, ...payload }: Phase) =>
      updateLoanSpread(loanId, id as string, payload),
    onError: handleErrorResponse,
  })
}

const useDeleteLoanSpread = (loanId: string) => {
  return useMutation({
    mutationFn: (id: string) => deleteLoanSpread(loanId, id),
    onError: handleErrorResponse,
  })
}

const useInvalidateLoanSpreads = (loanId: string) => {
  const queryClient = useQueryClient()
  return () =>
    queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId, 'spread'] })
}

export {
  useLoanSpreads,
  useLoanSpreadCurrent,
  useAddLoanSpread,
  useUpdateLoanSpread,
  useDeleteLoanSpread,
  useInvalidateLoanSpreads,
  useInvalidateSpreadCurrent,
}
