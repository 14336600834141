import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { Person } from 'types'

interface Props {
  fundConfiguration?: {
    distributionCalculation: Person['distributionCalculation']
    distributionSchedule: Person['distributionSchedule']
  }
  saving: boolean
  onBack: (draftData: any) => void
  onSave: (values: {
    distributionCalculation: Person['distributionCalculation']
    distributionSchedule: Person['distributionSchedule']
  }) => void
  onCancel: () => void
}

function FundConfiguration({
  fundConfiguration,
  saving,
  onBack,
  onSave,
  onCancel,
}: Props) {
  return (
    <Formik
      initialValues={{
        distributionCalculation:
          fundConfiguration?.distributionCalculation || 'Manual',
        distributionSchedule:
          fundConfiguration?.distributionSchedule || 'Monthly',
      }}
      enableReinitialize={true}
      onSubmit={onSave}
      validateOnChange={false}
    >
      {({ values }) => (
        <Form modal>
          <div>
            <Grid>
              <Grid.Item>
                <Select
                  name="distributionCalculation"
                  label="Dividend Calculations"
                  portal
                  fieldClassName="mb-1.5"
                  options={[
                    {
                      label: 'Simple Interest',
                      value: 'Simple Interest',
                    },
                    {
                      label: 'Manual',
                      value: 'Manual',
                    },
                  ]}
                />
                <Text className="mb-5 text-grey-700">
                  How should dividends be calculated for each distribution?
                </Text>
              </Grid.Item>
              <Grid.Item>
                <Select
                  name="distributionSchedule"
                  label="Distribution Schedule"
                  portal
                  options={[
                    {
                      label: 'Monthly',
                      value: 'Monthly',
                    },
                    {
                      label: 'Quarterly',
                      value: 'Quarterly',
                    },
                    {
                      label: 'Annually',
                      value: 'Annually',
                    },
                  ]}
                />
              </Grid.Item>
            </Grid>
          </div>
          <Flex justifyContent="space-between">
            <TextLink variant="invert" onClick={() => onBack(values)}>
              <Icon name={IconName.arrowLeft} size="sm" />
              Back
            </TextLink>
            <Flex>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Flex>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export { FundConfiguration }
