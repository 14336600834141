import { compact, last, max, min, uniq } from 'lodash'
import { Offering } from 'types'

const getCities = (offering?: Offering): string[] =>
  uniq(
    offering?.offeringLoans
      ?.map(({ loan }) => loan?.addresses)
      .filter(Boolean)
      .flat()
      .map(({ city }) => city) || []
  )

const getRates = (offering?: Offering): number[] => {
  const offeringRates = offering?.fundId
    ? offering?.fund?.classes?.map(({ rate }) => rate)
    : offering?.classes?.map(({ rate }) => rate)
  return uniq(compact([min(offeringRates), max(offeringRates)]))
}

const getTerm = (offering?: Offering): number =>
  max(offering?.offeringLoans?.map(({ loan }) => loan?.term)) || 0

const getMaturityDate = (offering?: Offering): string =>
  last(
    offering?.offeringLoans
      ?.map(({ loan }) => loan?.dateMaturity)
      .filter(Boolean)
      ?.sort()
  ) || ''

export { getCities, getRates, getTerm, getMaturityDate }
