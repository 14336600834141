import clsx from 'clsx'
import { useState, useRef, useEffect } from 'react'
import { Icon, IconName } from 'components/Icon'
import Portal from 'components/Modal/Portal'
import { useModal } from 'hooks/use-modal'
import styles from './styles.module.scss'

interface Props {
  onClose: () => void
  imageUrls: string[]
  index?: number
  className?: string
  closeOnClickOutside?: boolean
}

function ModalImage({
  onClose,
  imageUrls,
  index: defaultIndex = 0,
  className,
  closeOnClickOutside,
}: Props) {
  const [index, setIndex] = useState(defaultIndex)
  const overlay = useRef<HTMLInputElement>(null)
  const modal = useRef<HTMLInputElement>(null)
  const isMultipleImages = imageUrls.length > 1
  useModal({ onClose, overlay, closeOnClickOutside })

  const handlePrev = () => {
    index !== 0 && setIndex(index - 1)
  }

  const handleNext = () => {
    index !== imageUrls.length - 1 && setIndex(index + 1)
  }

  useEffect(() => {
    if (imageUrls[index + 1]) {
      const img = new Image()
      img.src = imageUrls[index + 1]
    }
  }, [index])

  return (
    <Portal className={styles.portal}>
      <div
        ref={overlay}
        data-esc-closable={true}
        data-overlay={true}
        className={styles.overlay}
      />
      <div
        ref={modal}
        className={clsx(className, styles.container, {
          [styles.multipleContainer]: isMultipleImages,
        })}
      >
        <div
          className={clsx(styles.contentWrapper, {
            [styles.multipleContentWrapper]: isMultipleImages,
          })}
        >
          <div
            className={clsx(styles.content, {
              [styles.multipleContent]: isMultipleImages,
            })}
          >
            <div
              className={clsx(styles.icon, styles.iconCloseWrapper)}
              onClick={onClose}
            >
              <Icon name={IconName.close} size="md" />
            </div>
            {imageUrls.length > 1 && (
              <>
                <div
                  className={clsx(styles.icon, styles.iconPrevWrapper, {
                    [styles.notAllowed]: index === 0,
                  })}
                  onClick={handlePrev}
                >
                  <Icon name={IconName.arrowLeft} size="md" />
                </div>
                <div
                  className={clsx(styles.icon, styles.iconNextWrapper, {
                    [styles.notAllowed]: index === imageUrls.length - 1,
                  })}
                  onClick={handleNext}
                >
                  <Icon name={IconName.arrowRight} size="md" />
                </div>
              </>
            )}
            <img
              className={clsx(styles.image, {
                [styles.multipleImage]: isMultipleImages,
              })}
              src={imageUrls[index]}
              alt="No image"
            />
          </div>
        </div>
      </div>
    </Portal>
  )
}

export { ModalImage }
