import { request } from 'services/request'
import { LoanFundingSource } from 'types'

const getOfferingFundingSources = async (
  offeringId: string
): Promise<LoanFundingSource[]> => {
  const {
    data: { fundingSources },
  } = await request.get(`/offering/${offeringId}/funding_source`)

  return fundingSources.map(({ rate, ...rest }) => ({
    rate: rate * 100,
    ...rest,
  }))
}

const addOfferingInvestor = async (
  offeringId: string,
  payload: Partial<LoanFundingSource>
): Promise<LoanFundingSource> => {
  const {
    data: { fundings },
  } = await request.post(`/offering/${offeringId}/investor`, {
    ...payload,
    rate: payload.rate ? (payload.rate as number) / 100 : payload.rate,
  })
  return fundings
}

const editOfferingInvestor = async (
  offeringId: string,
  { id, ...payload }: Partial<LoanFundingSource>
): Promise<LoanFundingSource> => {
  const {
    data: { fundings },
  } = await request.patch(`/offering/${offeringId}/investor/${id}`, {
    ...payload,
    rate: (payload.rate as number) / 100,
  })
  return fundings
}

const deleteOfferingInvestor = async (
  offeringId: string,
  investmentId: string
): Promise<unknown> => {
  return request.delete(`/offering/${offeringId}/investment/${investmentId}`)
}

export {
  getOfferingFundingSources,
  addOfferingInvestor,
  editOfferingInvestor,
  deleteOfferingInvestor,
}
