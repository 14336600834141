import { request } from 'services/request'
import { BudgetActivity, BudgetDraw } from 'types'

const getBudgetDraws = async (
  loanId: string,
  addressId: string
): Promise<BudgetDraw[]> => {
  const {
    data: { draws },
  } = await request.get(`/loan/${loanId}/address/${addressId}/budget/draw`)
  return draws
}

const getBudgetDraw = async (
  loanId: string,
  addressId: string,
  drawId: string
): Promise<BudgetDraw> => {
  const {
    data: { draw },
  } = await request.get(
    `/loan/${loanId}/address/${addressId}/budget/draw/${drawId}`
  )
  return draw
}

const addBudgetDraw = async (
  loanId: string,
  addressId: string
): Promise<BudgetDraw> => {
  const {
    data: { draw },
  } = await request.post(`/loan/${loanId}/address/${addressId}/budget/draw`)
  return draw
}

const updateBudgetDraw = async (
  loanId: string,
  addressId: string,
  { id: drawId, ...payload }: Partial<BudgetDraw & { message?: string }>
): Promise<BudgetDraw> => {
  const {
    data: { draw },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/budget/draw/${drawId}`,
    payload
  )
  return draw
}

const submitBudgetDraw = async (
  loanId: string,
  addressId: string,
  drawId: string
): Promise<BudgetDraw> => {
  const {
    data: { draw },
  } = await request.put(
    `/loan/${loanId}/address/${addressId}/budget/draw/${drawId}`
  )
  return draw
}

const deleteBudgetDraw = async (
  loanId: string,
  addressId: string,
  drawId: string
): Promise<void> => {
  return request.delete(
    `/loan/${loanId}/address/${addressId}/budget/draw/${drawId}`
  )
}

const getBudgetItemActivities = async (
  loanId: string,
  addressId: string,
  budgetItemId: string
): Promise<BudgetActivity[]> => {
  const {
    data: { activities },
  } = await request.get(
    `/loan/${loanId}/address/${addressId}/budget/${budgetItemId}/activity`
  )
  return activities
}

const addBudgetItemActivity = async (
  loanId: string,
  addressId: string,
  budgetItemId: string,
  payload?: Partial<BudgetActivity>
): Promise<BudgetActivity> => {
  const {
    data: { activity },
  } = await request.post(
    `/loan/${loanId}/address/${addressId}/budget/${budgetItemId}/activity`,
    payload
  )
  return activity
}

const updateBudgetItemActivity = async (
  loanId: string,
  addressId: string,
  { id, ...payload }: Partial<BudgetActivity>
): Promise<BudgetActivity> => {
  const {
    data: { activity },
  } = await request.patch(
    `/loan/${loanId}/address/${addressId}/budget/activity/${id}`,
    payload
  )
  return activity
}

const getBudgetItemActivity = async (
  loanId: string,
  addressId: string,
  activityId: string
): Promise<BudgetActivity> => {
  const {
    data: { activity, images },
  } = await request.get(
    `/loan/${loanId}/address/${addressId}/budget/activity/${activityId}`
  )
  return {
    ...activity,
    images,
  }
}

const getBudgetItemActivityUploadUrl = async (
  loanId: string,
  addressId: string,
  activityId: string
): Promise<string> => {
  const {
    data: { uploadUrl },
  } = await request.get(
    `/loan/${loanId}/address/${addressId}/budget/activity/${activityId}/image`
  )
  return uploadUrl
}

const deleteBudgetItemActivityImage = async (
  loanId: string,
  addressId: string,
  activityId: string,
  imageId: string
): Promise<void> => {
  return request.delete(
    `/loan/${loanId}/address/${addressId}/budget/activity/${activityId}/image/${imageId}`
  )
}

const deleteBudgetItemActivity = async (
  loanId: string,
  addressId: string,
  activityId: string
): Promise<void> => {
  return request.delete(
    `/loan/${loanId}/address/${addressId}/budget/activity/${activityId}`
  )
}

export {
  getBudgetDraws,
  getBudgetDraw,
  addBudgetDraw,
  submitBudgetDraw,
  updateBudgetDraw,
  deleteBudgetDraw,
  getBudgetItemActivities,
  addBudgetItemActivity,
  getBudgetItemActivity,
  getBudgetItemActivityUploadUrl,
  updateBudgetItemActivity,
  deleteBudgetItemActivity,
  deleteBudgetItemActivityImage,
}
