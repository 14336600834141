import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { useLoanTrusts } from 'hooks/use-loans'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'

interface Props {
  loan: Loan
  alwaysShowEdit?: boolean
}

export const PanelTrust = ({ loan, alwaysShowEdit = false }: Props) => {
  const { data: trusts, isPending } = useLoanTrusts({ id: loan.id })

  return (
    <Panel title="Trusts" loading={isPending} alwaysShowEdit={alwaysShowEdit}>
      <Grid gap={16}>
        {trusts?.map(({ id, name, balance }) => (
          <Grid.Item sm={6} key={id}>
            <Summary name={name}>{formatUsd(balance as number)}</Summary>
          </Grid.Item>
        ))}
      </Grid>
    </Panel>
  )
}
