import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import { useState, useCallback } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'

interface Props {
  onView: () => void
  onRegenerate: () => void
  onDownload: () => void
  onDelete: () => void
}

const ModificationDocumentMenu = ({
  onView,
  onRegenerate,
  onDownload,
  onDelete,
}: Props) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-end',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  return (
    <>
      <Flex
        className="w-8 h-8 rounded cursor-pointer hover:bg-grey-100"
        {...getReferenceProps({
          ref: refs.setReference,
          onClick(e) {
            e.stopPropagation()
          },
        })}
      >
        <Icon
          name={IconName.ellipses}
          size="md"
          className="m-auto text-grey-700"
        />
      </Flex>
      {open && (
        <FloatingPortal>
          <Flex
            stack
            gap={0}
            className="min-w-[240px] p-1 bg-white-100 shadow-300 rounded py-1 border-solid border-[1px] border-grey-200 "
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x + 13,
                zIndex: 5,
              },
            })}
          >
            <Flex
              gap={8}
              alignItems="center"
              justifyContent="space-between"
              className="py-1.5 px-2 rounded-sm cursor-pointer hover:bg-grey-100"
              {...getItemProps({
                onClick: () => {
                  setOpen(false)
                  onView()
                },
              })}
            >
              <div className="leading-5 text-grey-900">View Agreement</div>
              <Icon
                name={IconName.linkExternal}
                size="md"
                className="text-grey-600"
              />
            </Flex>
            <Flex
              gap={8}
              alignItems="center"
              className="py-1.5 px-2 rounded-sm cursor-pointer hover:bg-grey-100"
              {...getItemProps({
                onClick: () => {
                  setOpen(false)
                  onRegenerate()
                },
              })}
            >
              <Icon
                name={IconName.twoSheets}
                size="md"
                className="text-grey-600"
              />
              <div className="leading-5 text-grey-900">
                Regenerate Agreement
              </div>
            </Flex>
            <Flex
              gap={8}
              alignItems="center"
              className="py-1.5 px-2 rounded-sm cursor-pointer hover:bg-grey-100"
              {...getItemProps({
                onClick: () => {
                  setOpen(false)
                  onDownload()
                },
              })}
            >
              <Icon
                name={IconName.download}
                size="md"
                className="text-grey-600"
              />
              <div className="leading-5 text-grey-900">Download</div>
            </Flex>
            <Flex
              gap={8}
              alignItems="center"
              className="py-1.5 px-2 rounded-sm cursor-pointer hover:bg-grey-100"
              {...getItemProps({
                onClick: () => {
                  setOpen(false)
                  onDelete()
                },
              })}
            >
              <Icon name={IconName.delete} size="md" className="text-red-100" />
              <div className="leading-5 text-red-100">Delete</div>
            </Flex>
          </Flex>
        </FloatingPortal>
      )}
    </>
  )
}

export { ModificationDocumentMenu }
