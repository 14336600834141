import { get, set } from 'lodash'
import { InlineSummary } from 'admin/components/InlineEdit'
import { InlineWysiwyg } from 'admin/components/InlineWysiwyg/InlineWysiwyg'
import { useUpdateCurrentClientSettings } from 'admin/hooks/use-users'
import { Grid } from 'components/Grid'
import { Panel } from 'components/Panel'
import { useSession } from 'hooks/use-session'

export const PanelPortalSettings = () => {
  const { user } = useSession()
  const { mutateAsync: update } = useUpdateCurrentClientSettings()
  const clientId = get(user, 'client.id', '')
  const settings = get(user, 'client.settings', {
    quoteFooter: '',
    investorStatementFooter: '',
    borrowerStatementFooter: '',
    offeringTermsOfAgreement: '',
    quoteShowProduct: 'No',
  })

  return (
    <Panel title="Settings" className="max-w-panel">
      <Grid gap={24}>
        <Grid.Item xs={12}>
          <InlineSummary
            type="option"
            name="Show product names and logos on term sheet?"
            value={settings.quoteShowProduct}
            options={[
              { value: 'Yes', label: 'Yes' },
              { value: 'No', label: 'No' },
            ]}
            hideEmptyOption
            save={(value) => {
              const updatedSettings = {
                ...settings,
                quoteShowProduct: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineWysiwyg
            name="Term Sheet Footer"
            value={settings.quoteFooter || ''}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                quoteFooter: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineWysiwyg
            name="Investor Statement Footer"
            value={settings.investorStatementFooter || ''}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                investorStatementFooter: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineWysiwyg
            name="Borrower Statement Footer"
            value={settings.borrowerStatementFooter || ''}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                borrowerStatementFooter: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
        <Grid.Item xs={12}>
          <InlineWysiwyg
            name="Offering Terms of Agreement"
            value={settings.offeringTermsOfAgreement || ''}
            save={(value) => {
              const updatedSettings = {
                ...settings,
                offeringTermsOfAgreement: value as string,
              }
              set(user as any, 'client.settings', updatedSettings)
              return update({
                clientId,
                settings: updatedSettings,
              })
            }}
          />
        </Grid.Item>
      </Grid>
    </Panel>
  )
}
