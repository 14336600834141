import clsx from 'clsx'
import Color from 'color'
import { isString, take, compact } from 'lodash'
import { forwardRef, ForwardedRef, memo } from 'react'
import uniqolor from 'uniqolor'
import styles from './styles.module.scss'

interface Props {
  id?: string
  name?: string
  exactName?: boolean
  background?: string
  color?: string
  className?: string
  tooltip?: boolean
}

const getAcronym = (name?: string): string => {
  if (name) {
    return compact(
      take(
        name
          .replace(/[^a-z0-9 ]/gi, '')
          .split(/\s+/)
          .map(([firstChar]) =>
            isString(firstChar) ? firstChar.toLocaleUpperCase() : ''
          ),
        2
      )
    ).join('')
  }
  return ''
}

const getColor = (id?: string) =>
  id ? uniqolor(id).color : uniqolor.random().color

const Avatar = memo(
  forwardRef(
    (
      { id, name, exactName, className, background, color }: Props,
      ref: ForwardedRef<HTMLDivElement | null>
    ) => {
      const avatarBackground = background || getColor(id)
      const avatarColor =
        color ||
        (Color(avatarBackground).luminosity() > 0.9
          ? Color({ r: 0, g: 0, b: 0 }).string()
          : Color({ r: 255, g: 255, b: 255 }).string())

      return (
        <div
          className={clsx(styles.avatar, className)}
          style={{ color: avatarColor, backgroundColor: avatarBackground }}
          ref={ref}
        >
          {exactName ? name : getAcronym(name)}
        </div>
      )
    }
  )
)
Avatar.displayName = 'Avatar'

export { getAcronym, getColor }
export default Avatar
