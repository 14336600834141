import { request } from 'services/request'
import { NewOfferingLoan, OfferingCollateral, OfferingLoan } from 'types'
import { formatPercent } from 'utils/math'

const getOfferingLoans = async (
  offeringId: string
): Promise<OfferingLoan[] | OfferingCollateral[]> => {
  const {
    data: { offeringLoans, loans },
  } = await request.get(`/offering/${offeringId}/loan`)
  return (
    offeringLoans ||
    loans?.map(({ rate, ...rest }) => ({ rate: formatPercent(rate), ...rest }))
  )
}

const addOfferingLoan = async (
  offeringId: string,
  payload: NewOfferingLoan
): Promise<OfferingLoan> => {
  const {
    data: { offeringLoan },
  } = await request.post(`/offering/${offeringId}/loan`, payload)
  return offeringLoan
}

const editOfferingLoan = async (
  offeringId: string,
  { id, ...payload }: Partial<OfferingLoan>
): Promise<OfferingLoan> => {
  const {
    data: { offeringLoan },
  } = await request.patch(`/offering/${offeringId}/loan/${id}`, payload)
  return offeringLoan
}

const deleteOfferingLoan = async (
  offeringId: string,
  loanId: string
): Promise<unknown> => {
  return request.delete(`/offering/${offeringId}/loan/${loanId}`)
}

export {
  getOfferingLoans,
  addOfferingLoan,
  editOfferingLoan,
  deleteOfferingLoan,
}
