import { ImportEntity } from 'admin/components/ImportEntity'
import { Button } from 'components/Button'
import { EmptyView } from 'components/EmptyView'
import { Flex } from 'components/Flex'
import emptyViewBorrowersImg from 'images/empty-view-borrowers.png'

interface Props {
  onClick: () => void
  onImport: () => void
}

export const EmptyBorrowers = ({ onClick, onImport }: Props) => {
  return (
    <EmptyView>
      <EmptyView.Icon
        icon={
          <div
            className={`center h-50 w-50 !bg-cover !bg-center !bg-no-repeat`}
            style={{ background: `url(${emptyViewBorrowersImg})` }}
          />
        }
      />
      <EmptyView.Title title="Add your borrowers" />
      <EmptyView.Description description="Add borrowers to connect them with their loans and track all of their information in one place." />
      <Flex className="mt-6" justifyContent="center">
        <ImportEntity entityType="borrower" setAlertData={onImport} />
        <Button onClick={onClick}>Add Borrower</Button>
      </Flex>
    </EmptyView>
  )
}
