import { request } from 'services/request'
import { Meta, Search, PersonField, Field } from 'types'
import {
  updateRequestFields,
  updateResponseFields,
} from 'utils/api/person-parser'

type IPersonType = 'borrower' | 'investor' | 'vendor'

const getPersonFields = async ({
  personType,
  search,
}: {
  search?: Search
  personType: IPersonType
}): Promise<{ fields: PersonField[]; meta: Meta }> => {
  const {
    data: { fields, meta },
  } = await request.get(`application/field/custom/${personType}`, {
    params: { search },
  })
  return { fields, meta }
}

const getFieldsByPersonId = async ({
  personType,
  personId,
}: {
  personType: IPersonType
  personId: string
}): Promise<Field[]> => {
  const {
    data: { fields },
  } = await request.get(`${personType}/${personId}/field`)
  return updateResponseFields(fields)
}

const updateFieldsByPersonId = async (
  personId: string,
  personType: IPersonType,
  payload: Field[]
): Promise<Field[]> => {
  const {
    data: { fields },
  } = await request.patch(`${personType}/${personId}/field`, {
    fields: updateRequestFields(payload),
  })
  return updateResponseFields(fields)
}

const addPersonField = async (
  personType: IPersonType,
  payload: Partial<PersonField>
): Promise<any> => {
  const {
    data: { field },
  } = await request.post(`/application/field/custom/${personType}`, payload)
  return field
}

const updatePersonField = async ({
  fieldId,
  payload,
}: {
  payload: Partial<PersonField>
  fieldId: string
}): Promise<any> => {
  const {
    data: { field },
  } = await request.patch(`/application/field/${fieldId}`, payload)
  return field
}

const deletePersonField = async (fieldId: string) => {
  return request.delete(`/application/field/${fieldId}`)
}

export {
  getPersonFields,
  addPersonField,
  updatePersonField,
  deletePersonField,
  getFieldsByPersonId,
  updateFieldsByPersonId,
}
