import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  className?: string
}

function MainContent({ children, className }: Props) {
  return <div className={clsx(styles.content, className)}>{children}</div>
}

export { MainContent }
