import { NotificationSetting, NotificationSettingTemplate } from 'types'
import { request } from '../request'

const getClientNotificationSettings = async (
  clientId: string
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.get(`client/${clientId}/notification`)
  return notifications
}

const updateClientNotificationSettings = async (
  clientId: string,
  payload: Partial<NotificationSetting>[]
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.patch(`client/${clientId}/notification`, {
    notifications: payload,
  })
  return notifications
}

const getLoanNotificationSettings = async (
  loanId: string
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.get(`loan/${loanId}/notification`)
  return notifications
}

const updateLoanNotificationSettings = async (
  loanId: string,
  payload: Partial<NotificationSetting>[]
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.patch(`loan/${loanId}/notification`, {
    notifications: payload,
  })
  return notifications
}

const getUserNotificationSettings = async (
  clientId: string,
  userId: string
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.get(`client/${clientId}/user/${userId}/notification`)
  return notifications
}

const updateUserNotificationSettings = async (
  clientId: string,
  userId: string,
  payload: Partial<NotificationSetting>[]
): Promise<NotificationSetting[]> => {
  const {
    data: { notifications },
  } = await request.patch(`client/${clientId}/user/${userId}/notification`, {
    notifications: payload,
  })
  return notifications
}

const getNotificationTemplate = async (
  id: string
): Promise<NotificationSettingTemplate> => {
  const {
    data: { template },
  } = await request.get(`notification/template/${encodeURIComponent(id)}`)
  return template
}

const updateNotificationTemplate = async (
  id: string,
  payload: NotificationSettingTemplate
): Promise<NotificationSettingTemplate> => {
  const {
    data: { template },
  } = await request.patch(
    `notification/template/${encodeURIComponent(id)}`,
    payload
  )
  return template
}

const resetNotificationTemplate = async (
  id: string
): Promise<NotificationSettingTemplate> => {
  const {
    data: { template },
  } = await request.delete(`notification/template/${encodeURIComponent(id)}`)
  return template
}

export {
  getClientNotificationSettings,
  updateClientNotificationSettings,
  getLoanNotificationSettings,
  updateLoanNotificationSettings,
  getUserNotificationSettings,
  updateUserNotificationSettings,
  getNotificationTemplate,
  updateNotificationTemplate,
  resetNotificationTemplate,
}
