import { isString, isNil } from 'lodash'

const intlNumber = new Intl.NumberFormat('en-US')
const intlFloat = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 20,
})

interface Options {
  showZero?: boolean
}

const formatNumber = (value: string | number | null, options?: Options) => {
  const showZero = options?.showZero || false
  if (!isNil(value) && (value !== 0 || showZero)) {
    return intlNumber.format(isString(value) ? parseFloat(value) : value)
  }
  return '-'
}

const formatFloat = (value: string | number | null, options?: Options) => {
  const showZero = options?.showZero || false
  if (!isNil(value) && (value !== 0 || showZero)) {
    return intlFloat.format(isString(value) ? parseFloat(value) : value)
  }
  return '-'
}

export { formatNumber, formatFloat }
