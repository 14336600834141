import { request } from 'services/request'
import { LoanDocument } from 'types'

const addLoanDocument = async (
  id: string,
  document: Partial<LoanDocument>
): Promise<LoanDocument> => {
  const { data } = await request.post(`/loan/${id}/document`, document)
  return data.document
}

const generateLoanDocument = async (
  loanId: string,
  templateName: string,
  payload?: { date?: string; fundingSourceId?: string }
): Promise<{ document: LoanDocument; versionId: string }> => {
  const {
    data: { document, versionId },
  } = await request.post(
    `/loan/${loanId}/document/generate/${templateName}`,
    payload
  )
  return { document, versionId }
}

export { addLoanDocument, generateLoanDocument }
