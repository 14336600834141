import clsx from 'clsx'
import { isNil } from 'lodash'
import { Fragment, useState } from 'react'
import { ErrorMessage } from 'components/ErrorMessage'
import { Flex } from 'components/Flex'
import { LoanTranches } from 'components/Modal/LoanTranches'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Class } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  isLoading?: boolean
  lock?: boolean
  loanRate?: number | null
  average?: number | null
  classes?: Class[]
  save: (classes: Partial<Class>[]) => Promise<unknown>
}

function PanelClasses({
  isLoading,
  lock,
  loanRate,
  average = 0,
  classes = [],
  save,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <Panel
      title="Classes"
      onEdit={lock ? undefined : () => setIsModalVisible(true)}
      loading={isLoading}
    >
      {classes.length && !isLoading ? (
        <Flex stack gap={16}>
          <div className={styles.tableBlock}>
            <div className={styles.tranchesTable}>
              <div className={styles.header}>Name</div>
              <div className={clsx(styles.header, styles.alignRight)}>
                Amount
              </div>
              <div className={clsx(styles.header, styles.alignRight)}>Rate</div>
              {classes.map(({ name, amount, rate }, index) => (
                <Fragment key={index}>
                  <div>{name}</div>
                  <div className={styles.alignRight}>{formatUsd(amount)}</div>
                  <div className={styles.alignRight}>
                    {formatPercent(rate, { maxDecimals: 3 })}
                  </div>
                </Fragment>
              ))}
              {!isNil(average) && (
                <>
                  <div className="font-bold">Weighted Average</div>
                  <div />
                  <div className={clsx('font-bold', styles.alignRight)}>
                    {formatPercent(average, { maxDecimals: 3 })}
                  </div>
                </>
              )}
            </div>
          </div>
          {!isNil(loanRate) && (average || 0) > loanRate && (
            <ErrorMessage>
              The class rate is greater than the loan rate
            </ErrorMessage>
          )}
        </Flex>
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          className={styles.empty}
        >
          <TextLink onClick={() => setIsModalVisible(true)}>
            Setup your first class
          </TextLink>
        </Flex>
      )}
      {isModalVisible && (
        <LoanTranches
          classes={classes}
          onSave={save}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}

export { PanelClasses }
