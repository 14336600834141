import { request } from 'services/request'
import { Search, Filter, Meta, Pagination, Payout, Transaction } from 'types'

const getPayouts = async (params: {
  search?: Search
  filter?: Filter
  page?: Pagination
}): Promise<{ transactions: Payout[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.get(
    `/payout${(params.filter?.completed as boolean[])?.includes(true) ? '/completed' : ''}`,
    {
      params,
    }
  )
  return { transactions, meta: pagination }
}

const reversePayout = async (id: string) => {
  const { data } = await request.post(`/payout/${id}`, { status: 'cleared' })
  return { data }
}

const updatePayout = async ({
  search,
  filter,
  include,
  exclude,
  payout,
}: {
  search?: Search
  filter?: Filter
  include?: string[]
  exclude?: string[]
  payout: Partial<Payout>
}): Promise<Payout[]> => {
  const {
    data: { transactions },
  } = await request.patch(
    `/payout/completed`,
    { include, exclude, ...payout },
    { params: { search, filter } }
  )
  return transactions
}

const getPayoutFilters = async (
  columns: string[]
): Promise<Record<string, any>> => {
  const { data } = await request.get(
    `/payout/filters?columns=${columns.join(',')}`
  )
  return data
}

const payoutsCount = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ amount: number; count: number; transactions: Transaction[] }> => {
  const { data } = await request.put(
    '/payout',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

const releasePayouts = async ({
  search,
  filter,
  ...rest
}: {
  date: string
  search?: Search
  filter?: Filter
  include?: string[]
  exclude?: string[]
  transactions?: { id: string; bankingId: string | undefined | null }[]
}): Promise<{ transactions: Payout[]; meta: Meta }> => {
  const {
    data: { transactions, pagination },
  } = await request.post('/payout', rest, { params: { search, filter } })
  return { transactions, meta: pagination }
}

const getPayoutsNachaFileUrl = async ({
  search,
  filter,
  include,
  exclude,
  completed = true,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
  completed?: boolean
}): Promise<{ download: string }> => {
  const { data } = await request.post(
    completed ? '/payout/completed/nacha' : '/payout/nacha',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

const getPayoutsCsvFileUrl = async ({
  search,
  filter,
  include,
  exclude,
}: {
  search?: Search
  filter?: Filter
  include: string[]
  exclude: string[]
}): Promise<{ download: string }> => {
  const { data } = await request.post(
    '/payout/csv',
    { include, exclude },
    { params: { search, filter } }
  )
  return data
}

export {
  getPayouts,
  updatePayout,
  reversePayout,
  getPayoutFilters,
  payoutsCount,
  releasePayouts,
  getPayoutsNachaFileUrl,
  getPayoutsCsvFileUrl,
}
