import { ReactNode, forwardRef, ForwardedRef } from 'react'

const Description = forwardRef(
  (
    { children }: { children: ReactNode },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return <div ref={ref}>{children}</div>
  }
)
Description.displayName = 'EllipsesActions.Description'

export default Description
