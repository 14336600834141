import { Flex } from 'components/Flex'
import { Quote } from 'types'
import { PanelBorrower } from './PanelBorrower'
import { PanelGuarantor } from './PanelGuarantor'
import styles from './styles.module.scss'

interface Props {
  quote: Quote
}

const Borrowers = ({ quote }: Props) => {
  return (
    <Flex stack gap={16} className={styles.panel}>
      <PanelBorrower quote={quote} borrowers={quote.borrowers} />
      <PanelGuarantor quote={quote} guarantors={quote.guarantors} />
    </Flex>
  )
}

export { Borrowers }
