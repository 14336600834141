import { takeRight } from 'lodash'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Loan } from 'types'

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
  banking: Loan['banking']
}

const ACHPayment = ({ banking, checked, onChange }: Props) => {
  return (
    <Flex stack gap={12} className="bg-grey-50 rounded p-5">
      <Header variant="h4">ACH Payment</Header>
      <Checkbox
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        label={
          <Flex stack gap={4}>
            <div className="text-grey-900">
              Collect this payment from the borrower’s bank account ending with
              *{takeRight(banking?.accountNumber || '', 4).join('')}
            </div>
            <div className="text-grey-700">
              This loan has pre-authorized debit information and is eligible for
              ACH Payments.
            </div>
          </Flex>
        }
      />
    </Flex>
  )
}

export { ACHPayment }
