import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from '@bugsnag/plugin-react'
import React from 'react'
import { version } from 'utils/version'

Bugsnag.start({
  apiKey: import.meta.env.VITE_APP_BUGSNAG as string,
  appVersion: version,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
  onError: function (event) {
    if (
      event?.errors?.[0]?.errorMessage?.includes('Minified React error #300') ||
      event?.errors?.[0]?.errorMessage?.includes(
        'Importing a module script failed.'
      ) ||
      event?.errors?.[0]?.errorMessage?.includes(
        'ResizeObserver loop completed with undelivered notifications'
      )
    ) {
      return false
    }
  },
})

export const BugsnagErrorBoundary = (
  Bugsnag.getPlugin('react') as BugsnagPluginReactResult
).createErrorBoundary(React)
