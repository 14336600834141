import clsx from 'clsx'
import { toString } from 'lodash'
import { useEffect } from 'react'
import { useReport } from 'admin/hooks/use-report'
import { Icon, IconName } from 'components/Icon'
import { ReportNumberType } from 'types'
import styles from './styles.module.scss'

interface Params {
  title: string
  type: ReportNumberType
  format?: (value: string | number | null) => string
  enabled?: boolean
  variant?: 'secondary'
  onLoad?: () => void
  onClick?: () => void
}

function WidgetNumber({
  title,
  type,
  format = (value?) => toString(value),
  enabled = true,
  variant,
  onLoad,
  onClick,
}: Params) {
  const { data, isPending, isSuccess } = useReport(type, {
    enabled,
  })
  useEffect(() => {
    if (onLoad && isSuccess) {
      onLoad()
    }
  }, [isSuccess])

  return (
    <div
      className={clsx(styles.widget, onClick && styles.clickable)}
      onClick={onClick}
    >
      {isPending ? (
        <div className={styles.loader}>
          <Icon name={IconName.loaderSpinner} className="spinner" size="xl" />
        </div>
      ) : (
        <>
          <div className={styles[`title${variant || ''}`]}>{title}</div>
          <div className={styles.value}>{format(data as number)}</div>
        </>
      )}
    </div>
  )
}

export { WidgetNumber }
