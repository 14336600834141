import { isAfter } from 'date-fns'
import { useMemo } from 'react'
import { useLoanModifications } from 'admin/hooks/use-loan-modification'
import { Alert } from 'components/Alert'
import { Flex } from 'components/Flex'
import { IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { friendlyDate } from 'utils/date'

interface Props {
  loanId: string
}

export const AlertModification = ({ loanId }: Props) => {
  const { data } = useLoanModifications(loanId)
  const modifications = useMemo(
    () =>
      data?.modifications.filter(
        (modification) =>
          !modification.isOrigination &&
          modification?.dateEffective &&
          isAfter(modification?.dateEffective, new Date())
      ),
    [data]
  )

  return (
    <>
      {!!modifications?.length && (
        <Alert iconName={IconName.calendar} className="mb-4">
          <Flex className="w-full" justifyContent="space-between">
            <Text className="text-base text-grey-900">
              {modifications.length === 1
                ? `Loan modification scheduled for ${friendlyDate(modifications[modifications.length - 1].dateEffective)}`
                : `Next loan modification scheduled for ${friendlyDate(modifications[modifications.length - 1].dateEffective)}`}
            </Text>
          </Flex>
        </Alert>
      )}
    </>
  )
}
