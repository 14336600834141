import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  saving: boolean
  onRecall: ({ message }: { message: string }) => void
  onCancel: () => void
}

function ModalRecallDocument({ saving, onRecall, onCancel }: Props) {
  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal
      title="Recall Document"
      onClose={onCancel}
      className={styles.modal}
      crossClose={true}
    >
      <Formik initialValues={initialValue} onSubmit={onRecall}>
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Textarea
                name="message"
                label="Notify the signers why the document is being recalled"
                autoFocus
                placeholder="Provide a reason"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button
                type="submit"
                loading={saving}
                variant="tertiary"
                color="negative"
                className="w-full"
              >
                Recall
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalRecallDocument }
