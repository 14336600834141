import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { TaskActivity } from 'types'
import { friendlyDateTime } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  activities: TaskActivity[]
}

const Activities = ({ activities }: Props) => {
  return (
    <Flex
      stack
      gap={20}
      className="ml-2 pt-5 border-0 border-t border-solid border-grey-200"
    >
      <Text className="text-[10px] font-bold text-grey-500 uppercase tracking-[.7px]">
        Activity
      </Text>
      <Flex stack gap={28}>
        {activities?.map((activity) => (
          <Flex stack gap={6} key={activity.id} className={styles.activity}>
            <div dangerouslySetInnerHTML={{ __html: activity.text }} />
            <div className="text-sm text-grey-700">
              {friendlyDateTime(activity.createdAt)}
            </div>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}

export { Activities }
