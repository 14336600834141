import { useState } from 'react'
import {
  useAddFundAddress,
  useUpdateFund,
  useUpdateFundAddress,
} from 'admin/hooks/use-funds'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalAddress } from 'components/Modal/Address'
import { ModalPersonInfo } from 'components/Modal/PersonInfo'
import { Panel } from 'components/Panel'
import PanelEntityInformation from 'components/Panel/PanelEntityInformation'
import { AddressSummary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { Fund } from 'types'
import { Address } from 'types/address'
import { PanelClass } from './PanelClasses'
import styles from './styles.module.scss'

interface Props {
  fund: Fund
}

const isAddressDefined = (address: Address): boolean => {
  return Boolean(
    address.street1 ||
      address.street2 ||
      address.city ||
      address.state ||
      address.zipcode ||
      address.country
  )
}

function TabGeneral({ fund }: Props) {
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = useUpdateFund(fund.id)
  const { mutate: addAddress, isPending: isAddingAddress } = useAddFundAddress(
    fund.id
  )
  const { mutate: updateAddress, isPending: isUpdatingAddress } =
    useUpdateFundAddress(fund.id)

  const saveAddresses = (addresses: Record<'primary' | 'mailing', Address>) => {
    const currentPrimary = fund.addresses?.find(
      ({ type }) => type === 'primary'
    )
    const currentMailing = fund.addresses?.find(
      ({ type }) => type === 'mailing'
    )

    if (currentPrimary) {
      updateAddress(
        {
          addressId: currentPrimary.id,
          address: addresses.primary,
        },
        {
          onSuccess: () => setIsAddressModalVisible(false),
        }
      )
    } else {
      addAddress(
        { ...addresses.primary, type: 'primary' },
        {
          onSuccess: () => setIsAddressModalVisible(false),
        }
      )
    }

    if (currentMailing) {
      updateAddress(
        {
          addressId: currentMailing.id,
          address: addresses.mailing,
        },
        {
          onSuccess: () => setIsAddressModalVisible(false),
        }
      )
    } else {
      addAddress(
        { ...addresses.mailing, type: 'mailing' },
        {
          onSuccess: () => setIsAddressModalVisible(false),
        }
      )
    }
  }

  const addresses = fund.addresses
    ?.sort((a) => (a.type === 'primary' ? -1 : 1))
    .filter((address) => isAddressDefined(address))

  return (
    <div>
      <Grid gap={16}>
        <Grid.Item sm={12} md={6}>
          <Flex stack gap={16}>
            <PanelEntityInformation
              person={fund}
              onEdit={() => {
                setIsInfoModalVisible(true)
              }}
            />
            <Panel
              title="Address"
              onEdit={() => setIsAddressModalVisible(true)}
            >
              <Grid>
                {addresses?.length ? (
                  addresses.map((address) => (
                    <Grid.Item sm={6} key={address.id}>
                      <AddressSummary address={address} />
                    </Grid.Item>
                  ))
                ) : (
                  <Grid.Item sm={12}>
                    <div className={styles.link}>
                      <TextLink onClick={() => setIsAddressModalVisible(true)}>
                        <Icon name={IconName.plus} size="sm" />
                        Add Address
                      </TextLink>
                    </div>
                  </Grid.Item>
                )}
              </Grid>
            </Panel>
          </Flex>
        </Grid.Item>
        <Grid.Item sm={12} md={6}>
          <PanelClass fund={fund} />
        </Grid.Item>
        {isInfoModalVisible && (
          <ModalPersonInfo
            personType="fund"
            person={fund}
            saving={isUpdating}
            onSave={(values) =>
              update({ ...values } as Partial<Fund>, {
                onSuccess: () => setIsInfoModalVisible(false),
              })
            }
            onCancel={() => setIsInfoModalVisible(false)}
          />
        )}
        {isAddressModalVisible && (
          <ModalAddress
            addresses={fund.addresses || []}
            saving={isAddingAddress || isUpdatingAddress}
            onSave={(addresses) => saveAddresses(addresses)}
            onCancel={() => setIsAddressModalVisible(false)}
          />
        )}
      </Grid>
    </div>
  )
}

export { TabGeneral }
