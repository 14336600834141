import clsx from 'clsx'
import { without } from 'lodash'
import { useEffect } from 'react'
import { useQuoteLenders } from 'admin/hooks/use-quote-lenders'
import { Flex } from 'components/Flex'
import { PageLoader } from 'components/LoaderOverlay'
import { Quote } from 'types'
import styles from './styles.module.scss'

interface Props {
  loanType: Quote['loanType']
  transactionType: Quote['transactionType']
  selectedLenders: string[]
  setSelectedLenders: (ids: string[]) => void
}

const Lenders = ({
  loanType,
  transactionType,
  selectedLenders,
  setSelectedLenders,
}: Props) => {
  const { data: lenders, isFetching } = useQuoteLenders({
    transactionType,
    loanType,
  })
  useEffect(() => {
    if (!isFetching) {
      setSelectedLenders(
        lenders.filter(({ isEnabled }) => isEnabled).map(({ id }) => id)
      )
    }
  }, [isFetching, lenders])
  const handleLenderClick = (id: string) => {
    if (selectedLenders.includes(id)) {
      setSelectedLenders(without(selectedLenders, id))
    } else {
      setSelectedLenders([...selectedLenders, id])
    }
  }

  return (
    <Flex stack gap={16}>
      <Flex justifyContent="space-between" alignItems="center">
        <div className="font-bold">Lender</div>
        <div
          className="link"
          onClick={() =>
            setSelectedLenders(
              lenders.filter(({ isEnabled }) => isEnabled).map(({ id }) => id)
            )
          }
        >
          Select all
        </div>
      </Flex>
      {isFetching ? (
        <PageLoader />
      ) : (
        <Flex flexWrap="wrap">
          {lenders.map(({ id, name, image, isEnabled }) => (
            <Flex
              key={id}
              className={clsx(
                styles.lender,
                !isEnabled && styles.disabled,
                selectedLenders.includes(id) && styles.selected
              )}
              justifyContent="center"
              alignItems="center"
              onClick={() => isEnabled && handleLenderClick(id)}
            >
              <Flex stack gap={4}>
                {image?.url && (
                  <Flex alignItems="center" justifyContent="center">
                    <img src={image?.url} className={styles.lenderLogo} />
                  </Flex>
                )}
                <div className={styles.lenderName}>{name}</div>
              </Flex>
            </Flex>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export { Lenders }
