import { flexRender, Cell } from '@tanstack/react-table'
import clsx from 'clsx'
import { memo } from 'react'
import TableCell from 'components/Table/Cell'
import styles from 'components/Table/styles.module.scss'
import { ColumnMeta } from 'types'

interface Props<TData> {
  cell: Cell<TData, unknown>
  rowClassName?: string
  hover: boolean
  active?: boolean
  onActive?: (active: boolean) => void
}

const BodyRowCellComponent = <TData,>({
  rowClassName,
  hover,
  cell,
  active,
  onActive,
}: Props<TData>) => {
  const meta = cell.column.columnDef.meta as ColumnMeta

  return (
    <td
      data-label={
        typeof cell.column.columnDef.header === 'string'
          ? cell.column.columnDef.header
          : meta?.plainHeader || ''
      }
      className={clsx(styles.td, rowClassName)}
    >
      <TableCell {...cell.getContext()}>
        {flexRender(cell.column.columnDef.cell, {
          ...cell.getContext(),
          hover,
          onActive,
          active,
        })}
      </TableCell>
    </td>
  )
}

export const BodyRowCell = memo(
  BodyRowCellComponent
) as typeof BodyRowCellComponent
