import { useCallback, useState } from 'react'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { APPLICATION_HOWTO_HIDDEN } from 'constants/local-storage-keys'
import promoElementImg from 'images/promo-element.png'

function PromoPanel() {
  const [isVisiblePanel, setIsVisible] = useState(
    localStorage.getItem(APPLICATION_HOWTO_HIDDEN) !== '1'
  )
  const closePanel = useCallback(() => {
    localStorage.setItem(APPLICATION_HOWTO_HIDDEN, '1')
    setIsVisible(false)
  }, [])

  return (
    <>
      {isVisiblePanel && (
        <Panel className="relative pt-5">
          <Icon
            onClick={closePanel}
            name={IconName.close}
            size="md"
            className="absolute right-2 top-2 cursor-pointer p-0.5 md:right-5 md:top-5"
          />
          <Flex gap={40} className="!flex-col lg:!flex-row">
            <div
              className="h-44 w-[382px] flex-shrink-0 rounded !bg-contain !bg-no-repeat"
              style={{ background: `url(${promoElementImg})` }}
            />
            <Flex flexDirection="column" justifyContent="center" gap={16}>
              <Header variant="h4">Drag and Drop to Customize</Header>
              <Text variant="l" className="max-w-[348px] leading-5">
                Simply drag and drop from the list of question types on the
                right to add them to your application.
              </Text>
            </Flex>
          </Flex>
        </Panel>
      )}
    </>
  )
}

export { PromoPanel }
