import { useQuery } from '@tanstack/react-query'
import { size } from 'lodash'
import { getLoanEmails } from 'admin/services/api/loan-emails'
import { KEY_LOANS } from 'constants/query-keys'
import { Filter } from 'types'

const useLoanEmails = ({
  id,
  search,
  filter,
}: {
  id: string
  search?: string
  filter?: Filter
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_LOANS, id, 'emails', search, nextFilter],
    queryFn: () =>
      getLoanEmails({
        id,
        search,
        filter: nextFilter,
      }),
  })
}

export { useLoanEmails }
