import { request } from 'services/request'
import { LoanDocument } from 'types'

const getOfferingDocuments = async ({
  id,
}: {
  id: string
}): Promise<LoanDocument[]> => {
  const {
    data: { documents },
  } = await request.get(`/offering/${id}/document`)
  return documents
}

const addOfferingDocument = async (
  id: string,
  document: Partial<LoanDocument>
): Promise<LoanDocument> => {
  const {
    data: { document: doc },
  } = await request.post(`/offering/${id}/document`, document)
  return doc
}

export { getOfferingDocuments, addOfferingDocument }
