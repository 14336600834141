import clsx from 'clsx'
import { ReactNode } from 'react'
import { useDrag } from 'react-dnd'
import styles from './styles.module.scss'

interface ItemProps {
  id: string
  columnId: string
  children: ReactNode
}

function Item({ id, columnId, children }: ItemProps) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'item',
      item: { id, columnId },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      options: {
        dropEffect: 'move',
      },
    }),
    []
  )
  return (
    <div
      ref={drag}
      className={clsx(styles.item, { [styles.dragging]: isDragging })}
    >
      {children}
    </div>
  )
}

export default Item
