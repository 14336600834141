import { Loan } from 'types'
import PanelAmortization from './PanelAmortization'

interface Props {
  loan: Loan
}

function TabAmortization({ loan }: Props) {
  return <PanelAmortization loan={loan} />
}

export default TabAmortization
