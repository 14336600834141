import {
  useAddBorrower,
  useBorrowers,
  useLinkAccount,
  useUpdateLinkAccount,
  useUnlinkAccount,
} from 'admin/hooks/use-borrowers'
import { BorrowerDetails } from 'admin/services/api/borrowers'
import { PanelLinkedAccounts } from 'components/Panel/PanelLinkedAccounts'

interface Props {
  borrower: BorrowerDetails
}

function PanelBorrowerLinkedAccounts({ borrower }: Props) {
  const add = useLinkAccount({ borrowerId: borrower.id })
  const update = useUpdateLinkAccount({ borrowerId: borrower.id })
  const remove = useUnlinkAccount({ borrowerId: borrower.id })
  const addPerson = useAddBorrower()
  const availableAccounts = useBorrowers({
    filter: {
      type: [borrower.type === 'individual' ? 'entity' : 'individual'],
    },
  })

  return (
    <PanelLinkedAccounts
      person={borrower}
      availableAccounts={availableAccounts.data?.borrowers || []}
      addPerson={addPerson}
      addLink={add}
      updateLink={update}
      removeLink={remove}
    />
  )
}

export default PanelBorrowerLinkedAccounts
