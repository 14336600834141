import { memo } from 'react'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  colSpan?: number
}

const BodyLoader = memo(({ colSpan = 1 }: Props) => (
  <tr>
    <td colSpan={colSpan}>
      <div className={styles.tableBodyContent}>
        <Icon name={IconName.loaderSpinner} className="spinner" />
        Loading...
      </div>
    </td>
  </tr>
))

BodyLoader.displayName = 'BodyLoader'

export { BodyLoader }
