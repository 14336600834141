import { memo } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Badge } from './index'

interface AccountTypeBadgeProps {
  type: 'individual' | 'entity'
  isAccount?: boolean
}

const AccountTypeBadge = memo(({ type, isAccount }: AccountTypeBadgeProps) => {
  const title =
    isAccount === false ? 'Profile' : type === 'entity' ? 'Company' : 'Personal'

  return (
    <Badge color="gray">
      <Flex gap={6} flexDirection="row" alignItems="center">
        <Icon
          name={type === 'entity' ? IconName.company : IconName.person}
          size="sm"
        />
        {title}
      </Flex>
    </Badge>
  )
})

AccountTypeBadge.displayName = 'AccountTypeBadge'

export default AccountTypeBadge
