import { size, toNumber } from 'lodash'
import { subDecimal, sumDecimal, isZero } from 'utils/math'
import {
  IDistribution,
  IPaymentInfo,
  ISource,
  SubmitPaymentData,
} from './types'

export const getInitialSourceState = (): ISource => ({
  borrower: { amount: '' },
  trust: { amount: '', requestAmount: '', items: [] },
  investments: { amount: '', requestAmount: '', items: [] },
})

export const getInitialDistributionState = (): IDistribution => ({
  trust: { amount: '', requestAmount: '', items: [] },
  charges: { amount: '', requestAmount: '', items: [] },
  fundingSources: {
    principalAmount: '',
    requestPrincipalAmount: '',
    interestAmount: '',
    requestInterestAmount: '',
    items: [],
  },
  borrower: { amount: '' },
})

export const calculatedSourceToState = (nextSource): ISource => ({
  ...nextSource,
  trust: {
    amount: nextSource.trusts?.amount || '',
    requestAmount: nextSource.trusts?.amount || '',
    items:
      nextSource.trusts?.trusts?.map(({ id, amount }) => ({
        id: id,
        amount: toNumber(amount),
      })) || [],
  },
  investments: {
    amount: nextSource.investments?.amount || '',
    requestAmount: nextSource.investments?.amount || '',
    items:
      nextSource.investments?.investments?.map(
        ({ fundingSource, amount, remainingCommitment }) => ({
          id: fundingSource.id,
          fundingSource,
          amount: toNumber(amount),
          remainingCommitment: toNumber(remainingCommitment),
        })
      ) || [],
  },
})

export const calculatedDistributionToState = (
  nextDistribution
): IDistribution => ({
  trust: {
    amount: nextDistribution.trusts?.amount || '',
    requestAmount: nextDistribution.trusts?.amount || '',
    items:
      nextDistribution.trusts?.trusts?.map(({ id, amount }) => ({
        id: id,
        amount: toNumber(amount),
      })) || [],
  },
  charges: {
    amount: nextDistribution.charges?.amount || '',
    requestAmount: nextDistribution.charges?.amount || '',
    items: nextDistribution.charges?.charges || [],
  },
  fundingSources: {
    principalAmount: nextDistribution.principalInterest?.principalAmount,
    requestPrincipalAmount: nextDistribution.principalInterest?.principalAmount,
    interestAmount: nextDistribution.principalInterest?.interestAmount,
    requestInterestAmount: nextDistribution.principalInterest?.interestAmount,
    items: nextDistribution.principalInterest?.fundingSources.map(
      ({
        fundingSource,
        spread,
        principalAmount,
        interestAmount,
        unpaidInterestAmount,
      }) => ({
        id: fundingSource?.id,
        fundingSource,
        spread,
        principalAmount,
        interestAmount,
        unpaidInterestAmount,
      })
    ),
  },
  borrower: {
    amount: nextDistribution.borrower?.amount || '',
  },
})

export const getBalance = (
  source: ISource,
  distribution: IDistribution
): number =>
  subDecimal(
    sumDecimal([
      source.borrower?.amount,
      source.trust?.amount,
      source.investments?.amount,
    ]),
    sumDecimal([
      distribution.trust?.amount,
      distribution.charges?.amount,
      distribution.fundingSources?.interestAmount,
      distribution.fundingSources?.principalAmount,
      distribution.borrower?.amount,
    ])
  )

export const isBalanced = (
  source: ISource,
  distribution: IDistribution
): boolean => isZero(getBalance(source, distribution))

export const stateToPayload = (
  paymentInfo: IPaymentInfo,
  source: ISource,
  distribution: IDistribution
): SubmitPaymentData => ({
  ...paymentInfo,
  source: {
    investments: source.investments.items.map((item) => ({
      id: item.id,
      amount: toNumber(item.amount),
    })),
    trusts: source.trust.items.map((item) => ({
      ...item,
      amount: toNumber(item.amount),
    })),
    borrower: {
      amount: toNumber(source.borrower?.amount || 0),
    },
  },
  distribution: {
    fundingSources: distribution.fundingSources.items?.map((item) => ({
      ...item,
      principalAmount: item.id ? toNumber(item.principalAmount) : undefined,
      interestAmount: toNumber(item.interestAmount),
      unpaidInterestAmount: toNumber(item.unpaidInterestAmount),
    })),
    trusts: size(distribution.trust.items)
      ? distribution.trust.items.map((item) => ({
          ...item,
          amount: toNumber(item.amount),
        }))
      : undefined,
    charges: size(distribution.charges.items)
      ? distribution.charges.items.map((item) => ({
          ...item,
          amount: toNumber(item.amount),
        }))
      : undefined,
    borrower: { amount: toNumber(distribution.borrower.amount) },
  },
})
