import { capitalize, orderBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  useAddPersonField,
  usePersonFields,
  useUpdatePersonField,
  useRemovePersonField,
} from 'admin/hooks/use-person-fields'
import { PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY } from 'admin/pages/Settings/CustomFields/constants'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { Text } from 'components/Text'
import { usePagination } from 'hooks/use-pagination'
import { FieldType, PersonField } from 'types'
import { EmptyPersonFieldPanel } from './EmptyPersonFieldPanel'
import { ModalField } from './ModalField'
import { TablePersonFields } from './TablePersonFields'

type IPersonType = 'borrower' | 'investor' | 'vendor'

function PersonPanel({ personType }: { personType: IPersonType }) {
  const [isLoading, setIsLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [fields, setFields] = useState<PersonField[]>([])
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: add, isPending: isAdding } = useAddPersonField({
    personType,
  })
  const { mutate: update, isPending: isUpdating } = useUpdatePersonField({
    personType,
  })
  const { mutate: updateSilent } = useUpdatePersonField({
    personType,
    silent: true,
  })
  const { mutate: remove, isPending: isRemoving } = useRemovePersonField({
    personType,
  })
  const { result, search, setSearch, isEmpty } = usePagination({
    property: 'fields',
    useData: (params) =>
      usePersonFields({
        search: params.search,
        personType,
      }),
  })

  const handleSubmit = useCallback(
    (field: { name: string; type: FieldType; description: string }) => {
      if (editingId) {
        update(
          {
            fieldId: editingId,
            payload: {
              ...field,
              type: [field.type],
            },
          },
          {
            onSuccess: () => {
              setEditingId(null)
            },
          }
        )
      } else {
        add(
          {
            ...field,
            type: [field.type],
            context: PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
            default: {},
          },
          {
            onSuccess: () => {
              setAdding(false)
            },
          }
        )
      }
    },
    [update, add, editingId]
  )

  const handleDrop = useCallback(
    (field: PersonField, target: PersonField) => {
      if (field && target) {
        setFields((fields) => {
          const newFields = [...fields]
          const fieldIndex = newFields.findIndex(({ id }) => id === field.id)
          newFields.splice(fieldIndex, 1)
          const targetIndex = newFields.findIndex(({ id }) => id === target.id)
          newFields.splice(targetIndex + 1, 0, field)
          return newFields.map((f, index) => {
            updateSilent({ fieldId: f.id, payload: { ...f, order: index } })
            return { ...f, order: index }
          })
        })
      }
    },
    [updateSilent]
  )

  const handleSelect = useCallback(
    (field: PersonField, value: number) => {
      setFields((fields) =>
        fields.map((f) => (f.id === field.id ? { ...f, context: value } : f))
      )
      update({ fieldId: field.id, payload: { ...field, context: value } })
    },
    [update]
  )

  useEffect(() => {
    if (result?.data?.fields) {
      setFields(orderBy(result.data.fields, 'order'))
    }
    setIsLoading(result.isLoading)
  }, [result?.data?.fields])

  return (
    <>
      {!isEmpty ? (
        <Panel className="pb-6 max-w-panel">
          <Flex gap={12} justifyContent="space-between" className="pb-3">
            <div className="stack">
              <Header className="text-2xl mb-2">
                Custom {capitalize(personType)} Fields
              </Header>
              <Text className="text-grey-700 text-lg leading-5">
                Add Custom {capitalize(personType)} Fields below and arrange
                their order in your {personType} details.
              </Text>
            </div>
            <Search
              className="w-64"
              search={search}
              onSearch={setSearch}
              placeholder="Search custom fields"
            />
          </Flex>
          <DndProvider backend={HTML5Backend}>
            <TablePersonFields
              fields={fields}
              loading={isLoading}
              onEdit={setEditingId}
              onDelete={setRemovingId}
              onDrop={handleDrop}
              onSelect={handleSelect}
            />
          </DndProvider>
          <Button
            className="mt-3"
            variant="ghost"
            onClick={() => setAdding(true)}
          >
            <Icon name={IconName.addCircle} />
            Add Custom Field
          </Button>
          {removingId && (
            <ModalDelete
              resource="field"
              loading={isRemoving}
              onDelete={() => {
                remove(removingId, {
                  onSuccess: () => {
                    setRemovingId(null)
                  },
                })
              }}
              onCancel={() => setRemovingId(null)}
            />
          )}
        </Panel>
      ) : (
        <EmptyPersonFieldPanel
          personType={personType}
          onClick={() => setAdding(true)}
        />
      )}
      {adding || editingId ? (
        <ModalField
          field={fields?.find(({ id }) => id === editingId)}
          onCancel={() => {
            setAdding(false)
            setEditingId(null)
          }}
          saving={isAdding || isUpdating}
          onSave={handleSubmit}
        />
      ) : null}
    </>
  )
}

export { PersonPanel }
