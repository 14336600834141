import { Formik } from 'formik'
import { pick, omit, toString, toNumber } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Button } from 'components/Button'
import { FieldIcon, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import { TextLink } from 'components/TextLink'
import { QuoteOption as QuoteOptionType, QuoteOptionEditableProps } from 'types'
import { createScheme, required } from 'utils/schemas'
import { ModalQuoteFee } from '../QuoteFee'
import styles from './styles.module.scss'

interface Props {
  option: QuoteOptionType
  saving: boolean
  onSave: (option: QuoteOptionEditableProps) => void
  onCancel: () => void
}

const Schema = createScheme({
  amount: required,
  term: required,
})

function QuoteOption({ option, saving, onSave, onCancel }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [additionalFees, setAdditionalFees] = useState<
    { name: string; amount: number }[]
  >([])

  const initialValue = useMemo(
    () => ({
      amount: toString(option?.amount),
      term: toString(option?.term),
      compensationYieldSpread: toString(option?.compensationYieldSpread),
      compensationOriginationFee: toString(option?.compensationOriginationFee),
      ...[...(option?.fees || []), ...additionalFees].reduce(
        (acc, { name, amount }) => ({
          ...acc,
          [name]: amount,
        }),
        {}
      ),
    }),
    [option, additionalFees]
  )
  const addAdditionalFee = useCallback(
    ({ name, amount }) => {
      setAdditionalFees([...additionalFees, { name, amount }])
    },
    [additionalFees]
  )
  const onSubmit = useCallback(
    (values) => {
      const defaultPropNames = [
        'amount',
        'term',
        'compensationYieldSpread',
        'compensationOriginationFee',
      ]
      const result = Object.keys(pick(values, defaultPropNames)).reduce(
        (acc, key) => ({
          ...acc,
          [key]: toNumber(values[key]),
        }),
        {}
      )
      const fees = Object.keys(omit(values, defaultPropNames)).reduce(
        (acc: { name: string; amount: number }[], key) => [
          ...acc,
          { name: key, amount: toNumber(values[key]) },
        ],
        []
      )
      onSave({
        ...result,
        fees,
      })
    },
    [onSave]
  )

  return (
    <Modal title="Edit Option" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={6}>
              <FieldIcon type="currency" name="amount" label="Loan Amount" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                type="number"
                name="term"
                label="Term"
                mask="# months"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                type="percentage"
                name="compensationOriginationFee"
                label="Origination Fee"
                supportNegative
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                type="percentage"
                name="compensationYieldSpread"
                label="Yield Spread"
                supportNegative
              />
            </Grid.Item>
            {[...(option.fees || []), ...additionalFees].map(
              ({ name }, index) => (
                <Grid.Item key={`${name}-${index}`} xs={6}>
                  <FieldIcon type="currency" name={name} label={name} />
                </Grid.Item>
              )
            )}
            <Grid.Item xs={12}>
              <div>
                <TextLink onClick={() => setIsModalVisible(true)}>
                  <Icon name={IconName.plus} size="sm" />
                  Add additional fees
                </TextLink>
              </div>
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
      {isModalVisible && (
        <ModalQuoteFee
          saving={saving}
          onSave={({ name, amount }) => {
            addAdditionalFee({ name, amount })
            setIsModalVisible(false)
          }}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Modal>
  )
}

export default QuoteOption
