import { DocumentStatusBadge } from 'components/Badge'
import { Button } from 'components/Button'
import { ButtonGroup } from 'components/ButtonGroup'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header as TextHeader } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { friendlyMime, isPrintable } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
  version?: DocumentVersion
  onBack: () => void
  onDownload: () => void
  onPrint: () => void
}

export function Header({
  document,
  version,
  onBack,
  onDownload,
  onPrint,
}: Props) {
  return (
    <div className={styles.header}>
      <div className={styles.details}>
        <Button variant="ghost" icon onClick={onBack}>
          <Icon name={IconName.arrowLeftLong} />
        </Button>
        <div className={styles.fileInfo}>
          <Flex alignItems="center">
            <TextHeader variant="h3">{document.name}</TextHeader>
            <div className="text-grey-600">{friendlyMime(document.type)}</div>
          </Flex>
        </div>
      </div>
      <div className={styles.actions}>
        {document.uploadedDate && (
          <div className={styles.metadata}>
            <div>Last Updated {friendlyDate(document.uploadedDate)}</div>
          </div>
        )}

        <DocumentStatusBadge
          className={styles.btnStatus}
          status={document.status}
        />

        {!document?.esignatureSlug && (
          <ButtonGroup>
            <Button
              variant="secondary"
              disabled={!version}
              onClick={onDownload}
            >
              <Icon name={IconName.download} />
              Download
            </Button>
            <EllipsesActions
              trigger={
                <Button variant="secondary" icon>
                  <Icon name={IconName.arrowDownFilled} />
                </Button>
              }
            >
              <EllipsesActions.Item
                icon
                disabled={!isPrintable(version?.type)}
                onSelect={onPrint}
              >
                <Icon name={IconName.print} />
                Print
              </EllipsesActions.Item>
            </EllipsesActions>
          </ButtonGroup>
        )}
      </div>
    </div>
  )
}
