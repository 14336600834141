import { request } from 'services/request'
import { Class } from 'types'

const getOfferingClasses = async ({
  id,
}: {
  id: string
}): Promise<{ classes: Class[]; weightedAverage: number }> => {
  const {
    data: { classes, weightedAverage },
  } = await request.get(`/offering/${id}/class`)
  return {
    classes: classes.map(({ rate, ...cls }) => ({ rate: rate * 100, ...cls })),
    weightedAverage: weightedAverage * 100,
  }
}

const addOfferingClass = async (
  id: string,
  payload: Partial<Class>
): Promise<{ classes: Class[]; weightedAverage: number }> => {
  const {
    data: { classes, weightedAverage },
  } = await request.post(`/offering/${id}/class`, {
    ...payload,
    rate: (payload.rate as number) / 100,
  })
  return {
    classes: classes.map(({ rate, ...cls }) => ({ rate: rate * 100, ...cls })),
    weightedAverage: weightedAverage * 100,
  }
}

const editOfferingClass = async (
  offeringId: string,
  { id, ...payload }: Partial<Class>
): Promise<{ classes: Class[]; weightedAverage: number }> => {
  const {
    data: { classes, weightedAverage },
  } = await request.patch(`/offering/${offeringId}/class/${id}`, {
    ...payload,
    rate: (payload.rate as number) / 100,
  })
  return {
    classes: classes.map(({ rate, ...cls }) => ({ rate: rate * 100, ...cls })),
    weightedAverage: weightedAverage * 100,
  }
}

const deleteOfferingClass = async (
  offeringId: string,
  classId: string
): Promise<{ classes: Class[]; weightedAverage: number }> => {
  const {
    data: { classes, weightedAverage },
  } = await request.delete(`/offering/${offeringId}/class/${classId}`)
  return {
    classes: classes.map(({ rate, ...cls }) => ({ rate: rate * 100, ...cls })),
    weightedAverage: weightedAverage * 100,
  }
}

export {
  getOfferingClasses,
  addOfferingClass,
  editOfferingClass,
  deleteOfferingClass,
}
