import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { size } from 'lodash'
import { getPersons } from 'admin/services/api/persons'
import { KEY_PERSONS } from 'constants/query-keys'
import { Filter, Pagination, Sort } from 'types/table'

const usePersons = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PERSONS, search, nextFilter, pagination, sort],
    queryFn: () =>
      getPersons({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
      }),
    placeholderData: keepPreviousData,
  })
}

export { usePersons }
