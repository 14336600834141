import { compact } from 'lodash'
import { Address } from 'types'

const isAddressDefined = (address: Address): boolean => {
  return Boolean(
    address.street1 ||
      address.street2 ||
      address.city ||
      address.state ||
      address.zipcode ||
      address.country
  )
}

const formatAddress = (address?: Address, params?: { multiline?: boolean }) => {
  if (address && params?.multiline) {
    const multiline = [
      address.street1,
      address.street2,
      [
        [address.city, address.state].filter(Boolean).join(', '),
        address.zipcode,
      ]
        .filter(Boolean)
        .join(' '),
      address.country,
    ]
      .filter(Boolean)
      .join('\n')
    return multiline
  } else if (address) {
    const addressLine = compact([
      `${address.street1 || ''} ${address.street2 || ''}`.trim(),
      address.city,
      address.state,
      address.country,
    ]).join(', ')
    return `${addressLine} ${address.zipcode || ''}`.trim() || '-'
  }
  return '-'
}

export { isAddressDefined, formatAddress }
