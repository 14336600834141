import { DragEvent, useState } from 'react'
import {
  useOfferingImage,
  useAddOfferingImage,
  useDeleteOfferingImage,
} from 'admin/hooks/use-offering-image'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { Offering } from 'types'
import { openBrowseFile } from 'utils/file'

interface Props {
  offering: Offering
  header: string
  description: string
}

function PanelImage({ offering, header, description }: Props) {
  const [isDragActive, setIsDragActive] = useState(false)
  const { data: image, isPending } = useOfferingImage(offering.id)
  const { mutate: upload } = useAddOfferingImage(
    offering.id,
    image?.uploadUrl as string,
    image?.images?.[0]?.id
  )
  const { mutate: deleteOfferingImage } = useDeleteOfferingImage(
    offering.id,
    image?.images?.[0]?.id
  )
  const imageUrl = image?.images?.[0]?.url
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => {
        upload(files)
      },
      accept: '.png, .jpg, .jpeg',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files)
  }

  return (
    <Panel loading={isPending}>
      <Header variant="h4">{header}</Header>
      <Text className="mt-1 text-grey-700 leading-5">{description}</Text>
      <div
        className="h-50 mt-4 relative rounded bg-cover bg-center bg-no-repeat"
        onDragEnter={handleDrag}
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {!imageUrl && (
          <Flex
            alignItems="center"
            justifyContent="center"
            className="absolute bg-grey-50 rounded border border-dashed border-grey-200 inset-[-1px]"
          >
            <Button variant="secondary" onClick={handleUpload}>
              Add image
            </Button>
          </Flex>
        )}
        {isDragActive && (
          <DragDropFile
            onLeave={() => setIsDragActive(false)}
            onDrop={handleDrop}
            accept={['png', 'jpg', 'jpeg']}
            className="rounded"
          >
            <Flex stack gap={24} alignItems="center">
              <Icon
                name={IconName.uploadCloud}
                size="xl"
                className="text-blue-200"
              />
              <Header variant="h3">Drop a file</Header>
            </Flex>
          </DragDropFile>
        )}
      </div>
      {imageUrl && (
        <Flex justifyContent="space-between" className="mt-4">
          <TextLink onClick={handleUpload}>Update Image</TextLink>
          <TextLink onClick={() => deleteOfferingImage()}>
            Remove Image
          </TextLink>
        </Flex>
      )}
    </Panel>
  )
}

export { PanelImage }
