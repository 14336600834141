import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { useMemo } from 'react'
import {
  getLoanPayment,
  editLoanPayment,
  deleteLoanPayment,
  getLoanPaymentCalculate,
  submitLoanPayment,
} from 'admin/services/api/loan-payment'
import { IPaymentInfo, SubmitPaymentData } from 'components/Modal/Payment/types'
import {
  KEY_LOAN_PAYMENT_CALCULATE,
  KEY_LOAN_PAYMENTS,
  KEY_LOANS,
  KEY_PAYMENTS_DUE,
  KEY_PAYMENTS,
  KEY_LOAN_BUDGET,
} from 'constants/query-keys'
import { useDebounce } from 'hooks/use-debounce'
import { handleErrorResponse } from 'services/request'
import { Payment } from 'types'
import { message } from 'utils/message'

const useLoanPayment = ({
  loanId,
  paymentId,
  showDetails,
}: {
  loanId: string
  paymentId: string
  showDetails?: boolean
}) => {
  return useQuery({
    queryKey: [KEY_LOAN_PAYMENTS, loanId, paymentId, showDetails],
    queryFn: () => getLoanPayment(loanId, paymentId, showDetails),
  })
}

const useDeleteLoanPayment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async ({ id, loanId }: { id: string; loanId: string }) => {
      await deleteLoanPayment(loanId, id)
      return { loanId }
    },
    onSuccess: ({ loanId }) => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_PAYMENTS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_PAYMENTS] })
      message.success('Payment deleted')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanPayment = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, ...data }: Partial<Payment>) =>
      editLoanPayment(data.loanId as string, id as string, data),
    onSuccess: ({ loanId }) => {
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_PAYMENTS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_PAYMENTS] })
      message.success('Payment updated')
    },
    onError: handleErrorResponse,
  })
}

const useLoanPaymentCalculate = (
  {
    loanId,
    budgetDrawId,
    type,
    amount,
    principalAmount,
    interestAmount,
    paymentType,
    context,
    dateReceived,
    selectedFundingSources,
  }: {
    loanId: string
    budgetDrawId?: string
    type:
      | 'interest'
      | 'principal'
      | 'charges'
      | 'trust'
      | 'principal_interest'
      | 'regular_payment'
      | 'payoff'
      | 'to_borrower'
      | 'investments'
      | 'funding'
    amount?: number
    principalAmount?: number
    interestAmount?: number
    paymentType: IPaymentInfo['type']
    context?: 'source' | 'distribution'
    dateReceived?: string
    selectedFundingSources?: string[]
  },
  options: {
    keepPreviousData?: boolean
    enabled?: boolean
  },
  params?: {
    debounce: boolean
  }
) => {
  const key = useMemo(
    () => [
      KEY_LOAN_PAYMENT_CALCULATE,
      loanId,
      type,
      amount,
      principalAmount,
      interestAmount,
      dateReceived,
      budgetDrawId,
      selectedFundingSources,
    ],
    [
      loanId,
      budgetDrawId,
      type,
      amount,
      principalAmount,
      interestAmount,
      dateReceived,
      selectedFundingSources,
    ]
  )
  const queryKey = params?.debounce ? useDebounce(key, 500) : key

  return useQuery({
    queryKey,
    queryFn: () =>
      getLoanPaymentCalculate({
        loanId,
        budgetDrawId,
        type,
        amount,
        principalAmount,
        interestAmount,
        paymentType,
        context,
        dateReceived,
        selectedFundingSources,
      }),
    placeholderData: options.keepPreviousData ? keepPreviousData : undefined,
    gcTime: 0,
    enabled: options.enabled,
  })
}

const useSubmitLoanPayment = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: SubmitPaymentData) => submitLoanPayment(loanId, data),
    onSuccess: ({ type }) => {
      if (['Special Payment', 'Regular Payment'].includes(type)) {
        queryClient.invalidateQueries({ queryKey: [KEY_PAYMENTS_DUE] })
      }
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_PAYMENTS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_BUDGET, loanId] })
      message.success('Payment submitted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useLoanPayment,
  useUpdateLoanPayment,
  useDeleteLoanPayment,
  useLoanPaymentCalculate,
  useSubmitLoanPayment,
}
