import { ColumnDef } from '@tanstack/react-table'
import { startCase } from 'lodash'
import { useMemo, Dispatch, SetStateAction } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Table } from 'components/Table'
import { Transaction } from 'types'
import { Filter } from 'types/table'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  availableFilters?: Record<string, string[]>
  onFilter: Dispatch<SetStateAction<Filter | undefined>>
  filter?: Filter | undefined
  data?: Transaction[]
  loading?: boolean
}

function TablePayouts({
  data = [],
  availableFilters = {},
  filter = {},
  onFilter,
  loading,
}: Props) {
  const GLs = {
    BORROWER_LOAN: '0001',
    PAYMENTS_RECEIVED: '0004',
    PAYMENTS_SENT: '1200',
    CHARGES: '0008',
    CHARGES_MADE: '0018',
    BORROWER_RESERVE_BALANCE: '0007',
    INVESTOR_BALANCE: '1001',
    INVESTOR_SENIOR_INTEREST_RESERVE: '1004',
    SPREAD_MADE: '1006',
    INVESTOR_INTEREST_BALANCE: '1007',
    INVESTOR_SENIOR_PRINCIPAL_RESERVE: '1008',
    INVESTOR_PRINCIPAL_BALANCE: '1010',
    SPREAD_RESERVE: '1011',
    UNPAID_INTEREST: '1100',
    UNPAID_INTEREST_BALANCE: '1101',
    FEES_MADE: '0005',
  }

  const columns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        size: 120,
        cell: ({ getValue }) => formatDate(getValue() as string),
      },
      {
        id: 'loan',
        cell: ({ row }) => {
          const { loan } = row.original
          return (
            loan && (
              <NavLink
                to={pathTo('servicingLoanTab', loan.id, 'payments')}
                className="link"
              >
                {loan.name}
              </NavLink>
            )
          )
        },
        header: 'Loan',
        meta: {
          filter: {
            options: availableFilters?.loanName?.map((loanName) => ({
              value: loanName,
              label: loanName,
            })),
            selected: filter?.['loans.name'],
            onChange: (values) => onFilter({ 'loans.name': values }),
          },
        },
      },
      {
        accessorKey: 'person',
        cell: ({ row }) => {
          const { person } = row.original
          return (
            person && (
              <NavLink
                to={pathTo(
                  person.isBorrower ? 'borrowerTab' : 'investorTab',
                  person.id
                )}
                className="link"
              >
                {person.name}
              </NavLink>
            )
          )
        },
        header: 'Person',
        meta: {
          filter: {
            options: availableFilters?.personName?.map((personName) => ({
              value: personName,
              label: personName,
            })),
            selected: filter?.['people.name'],
            onChange: (values) => onFilter({ 'people.name': values }),
          },
        },
      },
      {
        header: 'Type',
        id: 'type',
        size: 200,
        cell: ({ row }) => {
          const glMap = Object.entries(GLs).reduce((acc, [key, value]) => {
            acc[value] = startCase(key.toLowerCase())
            return acc
          }, {})

          return glMap[row.original.gl] || row.original.gl
        },
        meta: {
          filter: {
            options: Object.entries(GLs).map(([key, value]) => ({
              value: value,
              label: startCase(key.toLowerCase()),
            })),
            selected: filter?.gl,
            onChange: (gl) => onFilter({ gl }),
          },
        },
      },
      {
        accessorKey: 'charge.name',
        header: 'Charge',
        meta: {
          filter: {
            options: availableFilters?.chargeName?.map((charge) => ({
              value: charge,
              label: charge,
            })),
            selected: filter?.['charges.name'],
            onChange: (values) => onFilter({ 'charges.name': values }),
          },
        },
      },
      {
        accessorKey: 'trust.name',
        header: 'Trust',
        meta: {
          filter: {
            options: availableFilters?.trustName?.map((trustName) => ({
              value: trustName,
              label: trustName,
            })),
            selected: filter?.['trust.name'],
            onChange: (values) => onFilter({ 'trust.name': values }),
          },
        },
      },
      {
        accessorKey: 'payment.type',
        header: 'Payment',
        meta: {
          filter: {
            options: availableFilters?.paymentType?.map((paymentType) => ({
              value: paymentType,
              label: paymentType,
            })),
            selected: filter?.['payments.type'],
            onChange: (values) => onFilter({ 'payments.type': values }),
          },
        },
      },
      {
        accessorKey: 'payment.method',
        header: 'Method',
        meta: {
          filter: {
            options: availableFilters?.paymentMethod?.map((paymentMethod) => ({
              value: paymentMethod,
              label: paymentMethod,
            })),
            selected: filter?.paymentMethod,
            onChange: (paymentMethod) => onFilter({ paymentMethod }),
          },
        },
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 150,
        cell: ({ getValue }) => formatUsd(getValue() as number),
        meta: {
          align: 'right',
        },
      },
    ],
    [filter, onFilter]
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TablePayouts
