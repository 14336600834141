import { UseQueryResult } from '@tanstack/react-query'
import { isArray, isEqual, isUndefined } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Filter, Pagination, Sort } from 'types'

const defaultPagination = {
  page: 0,
  size: 50,
}

interface Props {
  property: string
  defaultFilter?: Filter
  search?: string | undefined
  useData: ({
    sort,
    search,
    filter,
    pagination,
  }: {
    sort?: Sort
    search?: string
    filter?: Filter
    pagination?: Pagination
  }) => UseQueryResult<any>
}

export const usePagination = <T>({
  property,
  defaultFilter,
  search: externalSearch,
  useData,
}: Props) => {
  const [visibleItems, setVisibleItems] = useState<T[]>([])
  const [search, setSearch] = useState<string | undefined>()
  const [filter, setFilter] = useState<Filter | undefined>(defaultFilter)
  const [initialFilter] = useState<Filter | undefined>(defaultFilter)
  const [sort, setSort] = useState<Sort | undefined>()
  const [pagination, setPagination] = useState<Pagination>(defaultPagination)
  const result = useData({
    sort,
    search: externalSearch ?? search,
    filter,
    pagination,
  })
  const isEmpty = useMemo(
    () =>
      !result.isFetching &&
      pagination.page === 0 &&
      !search &&
      !externalSearch &&
      isEqual(initialFilter || {}, filter || {}) &&
      (result.data?.[property] || []).length === 0,
    [
      result.isFetching,
      result.data,
      pagination.page,
      search,
      externalSearch,
      initialFilter,
      filter,
    ]
  )
  const handleResetPagination = useCallback(
    () => setPagination(defaultPagination),
    []
  )
  const handleUpdateItem = useCallback((updatedItem: any) => {
    setVisibleItems((visibleItems) =>
      visibleItems.map((item: any) =>
        !isUndefined(item?.id) &&
        !isUndefined(updatedItem?.id) &&
        item.id === updatedItem.id
          ? updatedItem
          : item
      )
    )
  }, [])

  useEffect(() => {
    if (pagination.page > 0) {
      setPagination(defaultPagination)
    }
  }, [search, externalSearch, filter])

  useEffect(() => {
    const nextVisibleItems = result.data?.[property] || []
    if ((result.data?.meta?.page || 0) === 0) {
      setVisibleItems(nextVisibleItems)
    } else {
      setVisibleItems([...visibleItems, ...nextVisibleItems])
    }
  }, [result.data])

  return {
    visibleItems,
    result,
    search: externalSearch ?? search,
    filter,
    pagination,
    sort,
    isEmpty,
    setSearch,
    setFilter,
    setPagination,
    setSort: (nextSort: Sort | string | undefined) => {
      if (isArray(nextSort)) {
        setSort(nextSort)
      } else if (isUndefined(nextSort)) {
        setSort(nextSort)
      } else if (sort?.[0] === nextSort) {
        setSort([sort[0], sort[1] === 'ASC' ? 'DESC' : 'ASC'])
      } else {
        setSort([nextSort, 'ASC'])
      }
      setPagination(defaultPagination)
    },
    resetPagination: handleResetPagination,
    updateItem: handleUpdateItem,
  }
}
