import clsx from 'clsx'
import { ReactNode, useEffect, useState } from 'react'
import { SavingIndicator } from 'admin/components/InlineEdit'
import { TopMenu } from 'borrower/components/TopMenu'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Input } from 'components/Input'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import styles from './styles.module.scss'

interface PageTopProps {
  title: ReactNode
  className?: string
  breadcrumbs?: { title: string; link: string }
  children?: ReactNode
  onEdit?: (name: string) => Promise<unknown>
  hideTopMenu?: boolean
  maxLength?: number
}

type State = 'idle' | 'editing' | 'saving' | 'saved'

function PageTop({
  title: defaultTitle,
  className,
  breadcrumbs,
  hideTopMenu,
  children,
  onEdit,
  maxLength,
}: PageTopProps) {
  const { isAdmin } = useSession()
  const { isTablet } = useMobile()
  const [title, setTitle] = useState(defaultTitle)
  const [state, setState] = useState<State>('idle')

  const save = async (nextTitle) => {
    if (nextTitle.trim() && nextTitle !== title) {
      setTitle(nextTitle)
      setState('saving')
      await onEdit?.(nextTitle)
      setState('saved')
    } else {
      setState('idle')
    }
  }

  useEffect(() => {
    setTitle(defaultTitle)
  }, [defaultTitle])

  return (
    <div className={clsx(styles.pageTop, className)}>
      <Flex
        justifyContent="space-between"
        className={clsx(isAdmin && 'md:-mx-4')}
      >
        {breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : <div />}
        {!isTablet && !hideTopMenu && <TopMenu />}
      </Flex>
      <div className={styles.headerLine}>
        {onEdit && state === 'editing' ? (
          <Input
            type="text"
            defaultValue={title as string}
            onBlur={(e) => {
              const nextTitle = e.target.value
              save(nextTitle)
            }}
            onKeyDown={(e) => {
              if (e.code === 'Escape') {
                setState('idle')
              }
              if (e.code === 'Enter') {
                save((e.target as HTMLInputElement).value)
              }
            }}
            className={styles.titleInput}
            maxLength={maxLength}
            autoFocus
          />
        ) : (
          <Flex gap={8}>
            <Header
              variant="h1"
              className={clsx({ [styles.editableHeader]: onEdit })}
              onClick={() => onEdit && setState('editing')}
            >
              {title ?? '-'}
            </Header>
            {state === 'saving' && <SavingIndicator status="saving" />}
          </Flex>
        )}
        <div className={styles.actions}>{children}</div>
      </div>
    </div>
  )
}

export type { PageTopProps }
export default PageTop
