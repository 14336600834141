import { useCallback, useState } from 'react'
import { NewVendor, Role, Vendor } from 'admin/services/api/vendors'
import { Modal } from 'components/Modal'
import ChooseType from 'components/Modal/AddPerson/ChooseType'
import { PersonType } from 'types'
import { FormVendor } from './FormVendor'

interface Props {
  saving?: boolean
  onCancel: () => void
  onSave: (vendor: Partial<NewVendor>) => void
  vendor?: Partial<Omit<Vendor, 'role'>> & { role?: Role | Role[] }
}

type Stage = 'choose-type' | 'information'
const getTitle = (stage: Stage, type: PersonType) => {
  switch (stage) {
    case 'choose-type':
      return 'Choose Type'
    case 'information':
      return type === 'individual'
        ? 'Personal Information'
        : 'Company Information'
  }
}

function ModalAddVendor({ onCancel, onSave, vendor, saving }: Props) {
  const [stage, setStage] = useState<Stage>('choose-type')
  const [type, setType] = useState<PersonType>('individual')

  const handleNext = useCallback((data: any) => {
    setType(data)
    setStage('information')
  }, [])

  const handleBack = useCallback(() => {
    setStage('choose-type')
  }, [])

  return (
    <Modal title={getTitle(stage, type)} onClose={onCancel} asChild>
      {stage === 'choose-type' && (
        <ChooseType
          type={type}
          onNext={(type: PersonType) => handleNext(type)}
          onCancel={onCancel}
        />
      )}
      {stage === 'information' && (
        <FormVendor
          saving={saving}
          vendor={vendor}
          type={type}
          onCancel={onCancel}
          onBack={handleBack}
          onSave={onSave}
        />
      )}
    </Modal>
  )
}

export { ModalAddVendor }
