import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToId = ({ enabled }: { enabled: boolean }) => {
  const scrolledRef = useRef(false)
  const { hash } = useLocation()

  useEffect(() => {
    if (hash && enabled && !scrolledRef.current) {
      const id = hash.replace('#', '')
      const element = document.getElementById(id)
      if (element) {
        element.scrollIntoView({ behavior: 'instant' })
        scrolledRef.current = true
      }
    }
  }, [hash, enabled])
}
