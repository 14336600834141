import { request } from 'services/request'

type Image = {
  images: any[]
  uploadUrl: string
}

const getOfferingImage = async (offeringId: string): Promise<Image> => {
  const { data } = await request.get(`/offering/${offeringId}/image`)
  return data
}

const removeOfferingImage = (
  offeringId: string,
  imageId: string
): Promise<Image> => {
  return request.delete(`/offering/${offeringId}/image/${imageId}`)
}

export { getOfferingImage, removeOfferingImage }
