import clsx from 'clsx'
import { useState, PropsWithChildren } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Logo } from 'components/Logo'
import { useCreateCompanyAccount, useSwitchAccount } from 'hooks/use-borrower'
import { useMobile } from 'hooks/use-mobile'
import { BorrowerAccount, CurrentPerson } from 'services/api/session'
import { ModalAddCompanyAccount } from '../Modal/AddCompanyAccount'
import { SelectBorrowerAccount, TopBorrowerAccount } from '../Switcher'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  collapsed?: boolean
  borrowerAccounts?: BorrowerAccount[]
  actor: CurrentPerson | any
  person: CurrentPerson | any
  pathTo: any
  setIsMobileNavVisible: (visible: boolean) => void
}

export const SideNavigationContainer = ({
  collapsed,
  children,
  borrowerAccounts,
  actor = {},
  person = {},
  pathTo,
  setIsMobileNavVisible,
}: Props) => {
  const { isTablet } = useMobile()
  const [isAddCompany, setIsAddCompany] = useState(false)
  const { isPending: isCompanyLoading, mutate: addCompany } =
    useCreateCompanyAccount()
  const { isPending: isSwitching, mutate: switchAccount } = useSwitchAccount()
  const handleAddCompany = ({ name }: { name: string }) => {
    addCompany(
      { name },
      {
        onSuccess: ({ borrower }) => {
          switchAccount(
            { personId: borrower.id },
            { onSuccess: () => setIsAddCompany(false) }
          )
        },
      }
    )
  }

  return (
    <div
      className={clsx(styles.nav, {
        [styles.collapsed]: collapsed,
        [styles.mobile]: isTablet,
      })}
    >
      <div className={styles.navContent}>
        <Flex alignItems="center" justifyContent="space-between">
          <Logo
            icon={collapsed}
            wrapperClassName={collapsed ? 'mx-1 my-2' : 'm-4'}
            logoClassName={clsx(styles.logo, {
              [styles.collapsed]: collapsed,
              [styles.mobile]: isTablet,
            })}
            to={pathTo('dashboard')}
          />
          {isTablet && (
            <div
              onClick={() => setIsMobileNavVisible(false)}
              className={styles.closeIcon}
            >
              <Icon name={IconName.close} size="lg" />
            </div>
          )}
        </Flex>
        {isTablet && !collapsed && (
          <TopBorrowerAccount
            borrowerAccounts={borrowerAccounts}
            className="mx-2 my-4"
            actor={actor as unknown as CurrentPerson}
            person={person as unknown as CurrentPerson}
            onAddCompany={() => setIsAddCompany(true)}
          />
        )}
        {!isTablet && !collapsed && (
          <SelectBorrowerAccount
            borrowerAccounts={borrowerAccounts}
            actor={actor}
            person={person}
            onAddCompany={() => setIsAddCompany(true)}
          />
        )}
        <div className={styles.menus}>{children}</div>
      </div>
      {isAddCompany && (
        <ModalAddCompanyAccount
          saving={isCompanyLoading || isSwitching}
          onCancel={() => setIsAddCompany(false)}
          onSubmit={handleAddCompany}
        />
      )}
    </div>
  )
}
