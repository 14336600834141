import clsx from 'clsx'
import { Field, FieldArray, Formik } from 'formik'
import { toString } from 'lodash'
import { useState } from 'react'
import * as yup from 'yup'
import { useAddInvestor, useInvestors } from 'admin/hooks/use-investors'
import { useAddLoanTransfer } from 'admin/hooks/use-loan-funding-sources'
import { Investor } from 'admin/services/api/investors'
import { LoanTransfer } from 'admin/services/api/loan-funding-sources'
import { Button } from 'components/Button'
import { ErrorMessage } from 'components/ErrorMessage'
import { Flex } from 'components/Flex'
import { FieldIcon, Form, Option, Select } from 'components/Form'
import DateField from 'components/Form/Date'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { InputCurrency } from 'components/InputCurrency'
import { InputPercentage } from 'components/InputPercentage'
import { Modal } from 'components/Modal'
import tableStyles from 'components/Table/styles.module.scss'
import { TextLink } from 'components/TextLink'
import { FieldValue, LoanFundingSource } from 'types'
import { required } from 'utils/schemas'
import { ModalAddPerson } from '../AddPerson'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  loading: boolean
  fundingSources: LoanFundingSource[]
  trancheOptions: (Option & { rate: FieldValue })[]
  minDate?: string
  onCancel: () => void
}

type FormValues = {
  dateInvested: string
  from: {
    fundingSourceId: string
    amount: string
  }[]
  to: {
    investorId: string
    class: string
    rate: string
    amount: string
  }[]
}

const schema = yup.object().shape({
  dateInvested: required,
  from: yup.array().of(
    yup.object().shape({
      fundingSourceId: yup.string().required('Required'),
      amount: yup.string().required('Required'),
    })
  ),
  to: yup.array().of(
    yup.object().shape({
      investorId: yup.string().required('Required'),
      rate: yup.string().required('Required'),
      amount: yup.string().required('Required'),
    })
  ),
})

function Transfer({
  loanId,
  fundingSources,
  trancheOptions,
  loading,
  minDate,
  onCancel,
}: Props) {
  const investors = useInvestors()
  const [formError, setFormError] = useState('')
  const [newInvestor, setNewInvestor] = useState<{
    index: number
    name: string
  }>()
  const { mutate: transfer, isPending: saving } = useAddLoanTransfer({
    loanId,
  })
  const { mutate: addInvestor, isPending: addingInvestor } = useAddInvestor()
  const initialValue: FormValues = {
    dateInvested: '',
    from: [
      {
        fundingSourceId: '',
        amount: '',
      },
    ],
    to: [
      {
        investorId: '',
        class: '',
        rate: '',
        amount: '',
      },
    ],
  }
  const onSubmit = (values: FormValues) => {
    setFormError('')
    transfer(values as unknown as LoanTransfer, {
      onSuccess: () => {
        onCancel()
      },
      onError: (e) => {
        setFormError(e.response?.data as string)
      },
    })
  }
  const toInvestorsOptions = (investors.data?.investors || []).map(
    ({ id, name }) => ({
      label: name,
      value: id,
    })
  )
  const fromInvestorOptions = fundingSources.map(
    ({ id, investor: { name } }) => ({
      label: name,
      value: id,
    })
  )
  const getFundingSource = (foundingSourceId: string) =>
    fundingSources.find(
      ({ id }) => id === foundingSourceId
    ) as LoanFundingSource
  const headerClassName = clsx(tableStyles.header, tableStyles.small)
  const cellClassName = clsx(tableStyles.cell, tableStyles.small)

  return (
    <Modal
      asChild
      loading={loading || investors.isPending}
      title="Funding Transfer"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        <Form modal>
          <div>
            <Grid className={styles.form} columnGap={16}>
              <Grid.Item xs={4}>
                <DateField
                  label="Effective Date"
                  name="dateInvested"
                  minDate={minDate}
                />
              </Grid.Item>
            </Grid>
            <Flex stack gap={32}>
              <FieldArray
                name="from"
                render={({ form, push, remove }) => (
                  <Flex stack gap={8}>
                    <Header variant="h4">From</Header>
                    <table className={tableStyles.table}>
                      <thead>
                        <tr>
                          <th className={tableStyles.th}>
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignLeft
                              )}
                            >
                              Investor
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '124px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignRight
                              )}
                            >
                              Current Principal
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '72px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignRight
                              )}
                            >
                              Yield
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '120px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignRight
                              )}
                            >
                              Transfer
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '20px' }}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {form.values.from.map(({ fundingSourceId }, index) => {
                          const fundingSource =
                            getFundingSource(fundingSourceId)
                          return (
                            <tr key={index}>
                              <td className={clsx(styles.td, tableStyles.td)}>
                                <div
                                  className={clsx(
                                    tableStyles.small,
                                    tableStyles.alignLeft,
                                    'px-1 py-[7px] md:!pl-0 sm:px-2'
                                  )}
                                >
                                  <Select
                                    name={`from.${index}.fundingSourceId`}
                                    fieldClassName={styles.noMargin}
                                    options={fromInvestorOptions}
                                    portal
                                  />
                                </div>
                              </td>
                              <td className={clsx(styles.td, tableStyles.td)}>
                                <div
                                  className={clsx(
                                    cellClassName,
                                    tableStyles.alignRight
                                  )}
                                >
                                  <InputCurrency
                                    key={fundingSource?.amount}
                                    value={fundingSource?.amount}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td className={clsx(styles.td, tableStyles.td)}>
                                <div
                                  className={clsx(
                                    cellClassName,
                                    tableStyles.alignRight
                                  )}
                                >
                                  <InputPercentage
                                    key={fundingSource?.rate}
                                    value={fundingSource?.rate}
                                    disabled
                                  />
                                </div>
                              </td>
                              <td className={clsx(styles.td, tableStyles.td)}>
                                <div
                                  className={clsx(
                                    cellClassName,
                                    tableStyles.alignRight
                                  )}
                                >
                                  <FieldIcon
                                    type="currency"
                                    fieldClassName={styles.noMargin}
                                    name={`from.${index}.amount`}
                                  />
                                </div>
                              </td>
                              <td
                                className={clsx(
                                  styles.removeIconTd,
                                  tableStyles.td
                                )}
                              >
                                <div
                                  className={clsx(styles.removeIcon, {
                                    [styles.disabled]:
                                      form.values.from.length === 1,
                                  })}
                                  onClick={() =>
                                    form.values.from.length === 1
                                      ? () => {}
                                      : remove(index)
                                  }
                                >
                                  <Icon name={IconName.minus} size="sm" />
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                    <TextLink
                      onClick={() => push({ fundingSourceId: '', amount: '' })}
                    >
                      <Icon name={IconName.plus} size="sm" /> Add Investor
                    </TextLink>
                  </Flex>
                )}
              />
              <FieldArray
                name="to"
                render={({ form, push, remove }) => (
                  <Flex stack gap={8}>
                    <Header variant="h4">To</Header>
                    <table className={tableStyles.table}>
                      <thead>
                        <tr>
                          <th className={tableStyles.th}>
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignLeft
                              )}
                            >
                              Investor
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '124px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignLeft
                              )}
                            >
                              Class
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '72px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignRight
                              )}
                            >
                              Yield
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '120px' }}
                          >
                            <div
                              className={clsx(
                                headerClassName,
                                tableStyles.alignRight
                              )}
                            >
                              Transfer
                            </div>
                          </th>
                          <th
                            className={tableStyles.th}
                            style={{ width: '20px' }}
                          />
                        </tr>
                      </thead>
                      <tbody>
                        {form.values.to.map((formRow, index) => (
                          <tr key={index}>
                            <td className={clsx(styles.td, tableStyles.td)}>
                              <div
                                className={clsx(
                                  tableStyles.small,
                                  tableStyles.alignLeft,
                                  'px-1 py-[7px] md:!pl-0 sm:px-2'
                                )}
                              >
                                <Select
                                  name={`to.${index}.investorId`}
                                  fieldClassName={styles.noMargin}
                                  options={toInvestorsOptions}
                                  portal
                                  noOptionsMessage={({ inputValue }) => (
                                    <a
                                      className="link"
                                      onClick={() => {
                                        if (
                                          document.activeElement instanceof
                                          HTMLElement
                                        ) {
                                          document.activeElement.blur()
                                        }
                                        setNewInvestor({
                                          index,
                                          name: inputValue,
                                        })
                                      }}
                                    >
                                      <Icon name={IconName.plus} size="sm" />{' '}
                                      Create a new investor
                                    </a>
                                  )}
                                />
                              </div>
                            </td>
                            <td className={clsx(styles.td, tableStyles.td)}>
                              <div
                                className={clsx(
                                  cellClassName,
                                  tableStyles.alignLeft
                                )}
                              >
                                <Select
                                  name={`to.${index}.class`}
                                  fieldClassName={clsx(
                                    styles.noMargin,
                                    styles.classSelect
                                  )}
                                  options={trancheOptions}
                                  portal
                                  onChange={(option) => {
                                    form.setFieldValue(
                                      `to.${index}.rate`,
                                      toString((option as any).rate)
                                    )
                                  }}
                                />
                              </div>
                            </td>
                            <td className={clsx(styles.td, tableStyles.td)}>
                              <div
                                className={clsx(
                                  cellClassName,
                                  tableStyles.alignRight
                                )}
                              >
                                <FieldIcon
                                  type="percentage"
                                  fieldClassName={styles.noMargin}
                                  name={`to.${index}.rate`}
                                />
                              </div>
                            </td>
                            <td className={clsx(styles.td, tableStyles.td)}>
                              <div
                                className={clsx(
                                  cellClassName,
                                  tableStyles.alignRight
                                )}
                              >
                                <FieldIcon
                                  type="currency"
                                  fieldClassName={styles.noMargin}
                                  name={`to.${index}.amount`}
                                />
                              </div>
                            </td>
                            <td
                              className={clsx(
                                styles.removeIconTd,
                                tableStyles.td
                              )}
                            >
                              <div
                                className={clsx(styles.removeIcon, {
                                  [styles.disabled]:
                                    form.values.to.length === 1,
                                })}
                                onClick={() =>
                                  form.values.to.length === 1
                                    ? () => {}
                                    : remove(index)
                                }
                              >
                                <Icon name={IconName.minus} size="sm" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <TextLink
                      className={styles.link}
                      onClick={() =>
                        push({
                          investorId: '',
                          class: '',
                          rate: '',
                          amount: '',
                        })
                      }
                    >
                      <Icon name={IconName.plus} size="sm" /> Add Investor
                    </TextLink>
                  </Flex>
                )}
              />
            </Flex>
          </div>
          {formError && (
            <ErrorMessage className={styles.error}>{formError}</ErrorMessage>
          )}
          <Grid>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
          {newInvestor ? (
            <Field>
              {({ form }) => (
                <ModalAddPerson
                  personName={newInvestor?.name}
                  saving={addingInvestor}
                  onSave={(investor: Omit<Investor, 'id'>) => {
                    addInvestor(investor, {
                      onSuccess: ({ id }) => {
                        form.setFieldValue(
                          `to.${newInvestor?.index}.investorId`,
                          id
                        )
                        setNewInvestor(undefined)
                      },
                      onSettled: () => {
                        setNewInvestor(undefined)
                      },
                    })
                  }}
                  onCancel={() => {
                    setNewInvestor(undefined)
                  }}
                />
              )}
            </Field>
          ) : null}
        </Form>
      </Formik>
    </Modal>
  )
}

export default Transfer
