import clsx from 'clsx'
import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import styles from './styles.module.scss'

interface Props {
  fields: {
    key?: ReactNode
    value?: ReactNode
    tooltip?: string
  }[]
  className?: string
}

function PageTopFields({ fields, className }: Props) {
  return (
    <div className={clsx(styles.fields, className)}>
      {fields
        .filter(({ key, value }) => key && value)
        .map(({ key, value, tooltip }, index) => (
          <div className={styles.field} key={index}>
            <div className={styles.key}>{key}</div>
            <Flex gap={4} alignItems="center" className={styles.value}>
              {value}
              {tooltip && (
                <Tooltip content={tooltip}>
                  <Icon
                    name={IconName.info}
                    size="sm"
                    className={styles.hint}
                  />
                </Tooltip>
              )}
            </Flex>
          </div>
        ))}
    </div>
  )
}

export default PageTopFields
