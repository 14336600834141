import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getOfferingLoans,
  addOfferingLoan,
  editOfferingLoan,
  deleteOfferingLoan,
} from 'admin/services/api/offering-loan'
import { KEY_OFFERINGS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { NewOfferingLoan, OfferingLoan } from 'types'
import { message } from 'utils/message'

const useOfferingLoans = (id: string) => {
  return useQuery({
    queryKey: [KEY_OFFERINGS, id, 'loans'],
    queryFn: () => getOfferingLoans(id),
  })
}

const useAddOfferingLoan = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (loan: NewOfferingLoan) => addOfferingLoan(id, loan),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering loan saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditOfferingLoan = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (loan: Partial<OfferingLoan>) => editOfferingLoan(id, loan),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering loan saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteOfferingLoan = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (loanId: string) => deleteOfferingLoan(id, loanId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_OFFERINGS, id] })
      message.success('Offering loan deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useOfferingLoans,
  useAddOfferingLoan,
  useEditOfferingLoan,
  useDeleteOfferingLoan,
}
