import { IconName } from 'components/Icon'
import { IFilterType } from './types'

export const filterIconByType = new Map([
  ['select', IconName.boldListUl],
  ['date', IconName.calendar],
  ['number', IconName.number],
  ['percentage', IconName.percentage],
  ['currency', IconName.dollar],
])

export const filterConditionsByType: {
  [key in IFilterType]: {
    value: string
    label: string
  }[]
} = {
  select: [
    { value: 'eq', label: 'Is' },
    { value: 'not', label: 'Is not' },
  ],
  selectOneOf: [
    { value: 'in', label: 'Is' },
    { value: 'notIn', label: 'Is not' },
  ],
  date: [
    { value: 'eq', label: 'Is' },
    { value: 'lt', label: 'Is before' },
    { value: 'gt', label: 'Is after' },
    { value: 'lte', label: 'Is on or before' },
    { value: 'gte', label: 'Is on or after' },
    { value: 'between', label: 'Is between' },
  ],
  number: [
    { value: 'eq', label: 'Is' },
    { value: 'gt', label: 'Is greater than' },
    { value: 'gte', label: 'Is greater than or equal to' },
    { value: 'lt', label: 'Is less than' },
    { value: 'lte', label: 'Is less than or equal to' },
  ],
  percentage: [
    { value: 'eq', label: 'Is' },
    { value: 'gt', label: 'Is greater than' },
    { value: 'gte', label: 'Is greater than or equal to' },
    { value: 'lt', label: 'Is less than' },
    { value: 'lte', label: 'Is less than or equal to' },
  ],
  currency: [
    { value: 'eq', label: 'Is' },
    { value: 'gt', label: 'Is greater than' },
    { value: 'gte', label: 'Is greater than or equal to' },
    { value: 'lt', label: 'Is less than' },
    { value: 'lte', label: 'Is less than or equal to' },
  ],
}

export const getDefaultConditionByType = (type: IFilterType) => {
  return filterConditionsByType[type][0].value
}
