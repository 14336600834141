import clsx from 'clsx'
import { Flex } from 'components/Flex'
import styles from './styles.module.scss'

interface Props {
  steps: string[]
  step: string
  className?: string
  onlyPassedClickable?: boolean
  onClick?: (step: string) => void
}

const Steps = ({
  steps,
  step,
  className,
  onlyPassedClickable,
  onClick,
}: Props) => {
  const isStepPassed = (requestedStep: string) =>
    steps.findIndex((s) => s === requestedStep) <
    steps.findIndex((s) => s === step)
  const isStepClickable = (requestedStep: string) =>
    !!onClick && (onlyPassedClickable ? isStepPassed(requestedStep) : true)

  return (
    <Flex gap={8} className={clsx(styles.steps, className)}>
      {steps.map((name, index) => (
        <Flex
          key={name}
          gap={8}
          alignItems="center"
          className={clsx(
            styles.step,
            isStepClickable(name) && styles.clickable
          )}
          onClick={() => isStepClickable(name) && onClick && onClick(name)}
        >
          <div>
            <div
              className={clsx(
                styles.icon,
                name === step && styles.selected,
                isStepPassed(name) && styles.passed
              )}
            />
          </div>
          <div
            key={name}
            className={clsx(
              styles.name,
              name === step && styles.selected,
              isStepPassed(name) && styles.passed
            )}
          >
            {name}
          </div>
          {index !== steps.length - 1 && <div className={styles.line} />}
        </Flex>
      ))}
    </Flex>
  )
}

export { Steps }
