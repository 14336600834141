import clsx from 'clsx'
import { ReactNode } from 'react'
import { Button } from 'components/Button'
import { Header } from 'components/Header'
import { PageLoader } from 'components/LoaderOverlay'
import styles from './styles.module.scss'

interface PanelProps {
  title?: ReactNode
  onEdit?: () => void
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  loading?: boolean
  children?: ReactNode
  actionChildren?: ReactNode
  alwaysShowEdit?: boolean
  className?: string
  header?: ReactNode
}

function Panel({
  title,
  onEdit,
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  loading,
  actionChildren,
  alwaysShowEdit,
  className,
  header,
}: PanelProps) {
  return (
    <div
      className={clsx(styles.panel, className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {(title || !!actionChildren) && (
        <div className={styles.header}>
          {header ? header : <Header variant="h4">{title}</Header>}
          <div className={styles.actions}>
            {onEdit ? (
              <Button
                size="small"
                variant="tertiary"
                className={styles.action}
                onClick={onEdit}
                tabIndex={-1}
              >
                Edit
              </Button>
            ) : (
              alwaysShowEdit && (
                <Button
                  size="small"
                  disabled
                  variant="tertiary"
                  className={styles.actionReadOnly}
                  onClick={onEdit}
                >
                  Read-only
                </Button>
              )
            )}
            {actionChildren}
          </div>
        </div>
      )}
      {loading ? <PageLoader /> : children}
    </div>
  )
}

export type { PanelProps }
export default Panel
