import { useMemo } from 'react'
import { useTemplates } from 'admin/hooks/use-templates'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { Select } from 'components/Select'
import { Text } from 'components/Text'
import { Field, LoanModification } from 'types'
import { formatField } from 'utils/fields'

interface Props {
  previousModification: LoanModification | undefined
  modification: LoanModification
  template?: string
  generate: boolean
  onGenerateChange: (generate: boolean) => void
  onTemplateChange: (templateId: string | undefined) => void
}

const sectionsOrder = ['Terms', 'Parameters', 'Payment']

const ConfirmTermsAndPayment = ({
  previousModification,
  modification,
  template,
  generate,
  onGenerateChange,
  onTemplateChange,
}: Props) => {
  const { data: customTemplates } = useTemplates()
  const options = useMemo(
    () =>
      customTemplates?.map((template) => ({
        value: template.id,
        label: template.name,
      })) || [],
    [customTemplates]
  )

  const fields = useMemo(
    () =>
      modification.fields
        .filter(({ property }) => property.isModification)
        .map((field) => ({
          ...field,
          sectionServicing:
            field.id === 'loan-amount' ? 'Terms' : field.sectionServicing,
          previousField: previousModification?.fields.find(
            ({ id }) => id === field.id
          ),
        })),
    [modification.fields, previousModification?.fields]
  )
  const sections = useMemo(() => {
    return fields.reduce<Record<string, Field[]>>((acc, field) => {
      if (field.sectionServicing) {
        if (!acc[field.sectionServicing]) {
          acc[field.sectionServicing] = []
        }
        acc[field.sectionServicing].push(field)
      }
      return acc
    }, {})
  }, [fields])

  return (
    <Flex stack className="text-grey-900">
      {fields.length > 0 && (
        <Panel>
          <Flex stack gap={24}>
            <Header variant="h4" className="text-black-100">
              Term Edits
            </Header>
            {sectionsOrder.map((section) =>
              sections[section]?.length ? (
                <Flex key={section} stack gap={12}>
                  <Text
                    variant="xs"
                    className="font-bold text-grey-600 uppercase"
                  >
                    {section}
                  </Text>
                  <div className="rounded border border-solid border-grey-200">
                    {sections[section].map((field) => (
                      <Flex
                        key={field.id}
                        justifyContent="space-between"
                        className="py-4 px-3 [&:not(:first-child)]:border-t border-0 border-solid border-grey-200"
                      >
                        <Text>{field.name}</Text>
                        <Flex>
                          {(field as any).previousField && (
                            <>
                              <Text className="text-grey-700">
                                {formatField(
                                  (field as any).previousField as Field
                                )}
                              </Text>
                              <Icon
                                name={IconName.arrowRightLong}
                                size="md"
                                className="text-grey-600"
                              />
                            </>
                          )}
                          <Text className="font-bold">
                            {formatField(field)}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </div>
                </Flex>
              ) : null
            )}
          </Flex>
        </Panel>
      )}

      {!modification.isOrigination && (
        <Panel>
          <Flex stack gap={20}>
            <Header variant="h4" className="text-black-100">
              Loan Agreement
            </Header>
            <Flex stack>
              <Checkbox
                label="Generate a loan modification agreement"
                checked={generate}
                className="flex"
                onChange={(e) => onGenerateChange(e.target.checked)}
              />
              {generate && (
                <Flex stack gap={8}>
                  <div>Select Loan Agreement Template</div>
                  <Select
                    value={template}
                    options={options}
                    placeholder="Select a Template"
                    onChange={({ value }) => onTemplateChange(value as string)}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Panel>
      )}
    </Flex>
  )
}

export { ConfirmTermsAndPayment }
