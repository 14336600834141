import { useFormikContext } from 'formik'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { Select, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { BaseField } from 'types'

interface Props {
  mapToField?: BaseField
}

function DropdownForm({ mapToField }: Props) {
  const form = useFormikContext<{ options?: string }>()
  const defaultOptions = useMemo(
    () =>
      form.values.options
        ? compact(form.values.options.split(/\r?\n/)).map((value) => ({
            value,
            label: value,
          }))
        : [],
    [form.values.options]
  )

  return (
    <>
      <Grid.Item xs={12}>
        <Textarea
          name="options"
          label="Options"
          bottomHint="A list of options for users to choose from. Enter each option on a new
          line."
          className="h-32"
          disabled={mapToField?.type.includes('option')}
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Select
          label="Default Option"
          name="defaultValue"
          options={[{ value: '', label: 'None' }, ...defaultOptions]}
          portal
        />
      </Grid.Item>
    </>
  )
}

export { DropdownForm }
