import { ColumnDef } from '@tanstack/react-table'
import { toString } from 'lodash'
import { useMemo } from 'react'
import { InputCurrency } from 'components/InputCurrency'
import { Table } from 'components/Table'
import { formatUsd } from 'utils/currency'
import styles from './styles.module.scss'
import { ICalculatedTrust, IDistribution, ISource } from './types'

interface ITableTrustRow extends ICalculatedTrust {
  onChange: (rowId: string, value: string) => void
}

type Props = {
  type: 'source' | 'distribution'
  trust?: ICalculatedTrust[]
  trustItems?: ISource['trust']['items'] | IDistribution['trust']['items']
  onChange: (rowId: string, value: string) => void
  loading: boolean
}

export const TableTrust = ({
  type,
  trust = [],
  trustItems = [],
  onChange,
  loading,
}: Props) => {
  const rows = (trust as ICalculatedTrust[])?.map(({ id, ...rest }) => ({
    ...rest,
    id,
    amount: toString(
      trustItems.find(({ id: trustItemId }) => id === trustItemId)?.amount
    ),
    onChange,
  })) as unknown as ITableTrustRow[]

  const columns: ColumnDef<ITableTrustRow>[] = useMemo(
    () => [
      {
        header: 'Account Name',
        accessorKey: 'name',
      },
      {
        header: 'Current Balance',
        accessorKey: 'balance',
        size: 200,
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: type === 'source' ? 'Withdraw' : 'Deposit',
        accessorKey: 'amount',
        size: 200,
        cell: ({ row, getValue }) => (
          <InputCurrency
            className={styles.alignRight}
            value={getValue() as string}
            onChange={(e) =>
              row.original.onChange(row.original.id, e.target.value)
            }
          />
        ),
        meta: {
          align: 'right',
        },
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={rows}
      loading={loading}
    />
  )
}
