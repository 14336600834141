import { Formik } from 'formik'
import { useCallback } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Date as DateField } from 'components/Form'
import { Icon, IconName } from 'components/Icon'
import { Panel } from 'components/Panel'
import { createScheme, required } from 'utils/schemas'

const FormSchema = createScheme({
  date: required,
})

type FormValues = {
  date: string
}

interface Props {
  minDate: string | undefined
  values: FormValues
  onChange: (values: FormValues) => void
  onNext: () => void
  onBack: () => void
}

const StepPayoutDate = ({
  minDate,
  values,
  onBack,
  onChange,
  onNext,
}: Props) => {
  const initialValues: FormValues = {
    date: values.date,
  }
  const handleSubmit = useCallback((values: FormValues) => {
    onChange(values)
    onNext()
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <Flex stack gap={32}>
          <Panel title="Payout Info">
            <DateField name="date" label="Payout Date" minDate={minDate} />
          </Panel>
          <Flex justifyContent="flex-end" gap={10}>
            <Button variant="secondary" size="large" onClick={onBack}>
              Cancel
            </Button>
            <Button variant="primary" size="large" type="submit">
              Next
              <Icon name={IconName.arrowRightLong} />
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Formik>
  )
}

export { StepPayoutDate }
