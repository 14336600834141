import clsx from 'clsx'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { TopMenu } from 'borrower/components/TopMenu'
import { pathTo } from 'borrower/path-to'
import { Flex } from 'components/Flex'
import { Logo } from 'components/Logo'
import { useCreateCompanyAccount, useSwitchAccount } from 'hooks/use-borrower'
import { useSession } from 'hooks/use-session'
import { CurrentPerson } from 'services/api/session'
import { ModalAddCompanyAccount } from '../Modal/AddCompanyAccount'
import { TopBorrowerAccount } from '../Switcher'
import styles from './styles.module.scss'

function TopNavigation() {
  const { borrowerAccounts, user } = useSession()
  const isInvestor = user?.actor?.isInvestor
  const isBorrower = user?.actor?.isBorrower

  const [isAddCompany, setIsAddCompany] = useState(false)
  const { isPending: isCompanyLoading, mutate: addCompany } =
    useCreateCompanyAccount()
  const { isPending: isSwitching, mutate: switchAccount } = useSwitchAccount()
  const handleAddCompany = ({ name }: { name: string }) => {
    const handleSuccess = (personId: string) =>
      switchAccount({ personId }, { onSuccess: () => setIsAddCompany(false) })
    isBorrower
      ? addCompany(
          { entity: 'borrower', name },
          {
            onSuccess: ({ borrower }) => handleSuccess(borrower.id),
          }
        )
      : addCompany(
          { entity: 'investor', name },
          {
            onSuccess: ({ investor }) => handleSuccess(investor.id),
          }
        )
  }

  return (
    <div className={styles.header}>
      <div className={styles.container}>
        <Logo logoClassName={styles.logo} to={pathTo('dashboard')} />

        {isInvestor && (
          <div className={styles.links}>
            <NavLink
              to={pathTo('dashboard')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Dashboard"
            >
              Dashboard
            </NavLink>
            <NavLink
              to={pathTo('transactions')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Transactions"
            >
              Transactions
            </NavLink>
            <NavLink
              to={pathTo('offerings')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Offerings"
            >
              Offerings
            </NavLink>
            <NavLink
              to={pathTo('documents')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Documents"
            >
              Documents
            </NavLink>
          </div>
        )}

        {isBorrower && (
          <div className={styles.links}>
            <NavLink
              to={pathTo('applications')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Applications"
            >
              Applications
            </NavLink>
            <NavLink
              to={pathTo('loans')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Loans"
            >
              Loans
            </NavLink>

            <NavLink
              to={pathTo('documents')}
              className={({ isActive }) =>
                clsx(styles.link, {
                  [styles.selected]: isActive,
                })
              }
              title="Documents"
            >
              Documents
            </NavLink>
          </div>
        )}

        <Flex gap={24}>
          <TopBorrowerAccount
            borrowerAccounts={borrowerAccounts}
            actor={user?.actor as unknown as CurrentPerson}
            person={user?.person as unknown as CurrentPerson}
            onAddCompany={() => setIsAddCompany(true)}
          />

          <TopMenu />
        </Flex>
      </div>
      {isAddCompany && (
        <ModalAddCompanyAccount
          saving={isCompanyLoading || isSwitching}
          onCancel={() => setIsAddCompany(false)}
          onSubmit={handleAddCompany}
        />
      )}
    </div>
  )
}

export default TopNavigation
