const getEmailNames = (value: string) => {
  return (
    value.match(/"(\\.|[^"\\])*"/g)?.map((name) => name.replace(/"/g, '')) || []
  )
}

const getEmailAddresses = (value: string) => {
  return value.match(/<(\\.|[^"\\])*>/g)
}

export { getEmailNames, getEmailAddresses }
