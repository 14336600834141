import { compact } from 'lodash'
import { useState } from 'react'
import { useAddInvestor } from 'admin/hooks/use-investors'
import { useAddVendor } from 'admin/hooks/use-vendors'
import { FormVendor } from 'admin/pages/Vendors/ModalAddVendor/FormVendor'
import { Investor } from 'admin/services/api/investors'
import { Vendor } from 'admin/services/api/vendors'
import { FormBorrowerEntity } from 'components/FormBorrowerEntity'
import { FormBorrowerIndividual } from 'components/FormBorrowerIndividual'
import { Modal } from 'components/Modal'
import { PersonType } from 'types'
import { StepChooseType } from './StepChooseType'
import { Recipient, Step } from './types'

interface Props {
  onAdd: ({ id, name }: Recipient) => void
  onClose: () => void
}

const ModalCreateRecipient = ({ onAdd, onClose }: Props) => {
  const [step, setStep] = useState<Step>('choose-type')
  const [recipientType, setRecipientType] = useState<PersonType | undefined>()
  const { mutate: addVendor, isPending: isVendorAdding } = useAddVendor()
  const { mutate: addInvestor, isPending: isInvestorAdding } = useAddInvestor()

  return (
    <Modal title="Create Recipient" onClose={onClose} asChild>
      {step === 'choose-type' && (
        <StepChooseType
          onCloseClick={onClose}
          onNextClick={({ type, personType }) => {
            let nextStep = ''
            if (type === 'vendor') {
              nextStep = compact(['create', type]).join('-')
            } else {
              nextStep = compact(['create', type, personType]).join('-')
            }
            setRecipientType(personType)
            setStep(nextStep as Step)
          }}
        />
      )}
      {step === 'create-vendor' && (
        <FormVendor
          saving={isVendorAdding}
          onSave={(vendor) => {
            addVendor(vendor as Omit<Vendor, 'id'>, {
              onSuccess: ({ id, name }) => {
                onAdd({ id, name: name as string })
              },
            })
          }}
          onCancel={() => setStep('choose-type')}
          type={recipientType as PersonType}
          cancelText="Back"
          submitText="Next"
        />
      )}
      {step === 'create-investor-individual' && (
        <FormBorrowerIndividual
          saving={isInvestorAdding}
          include={['email']}
          cancelText="Back"
          saveText="Next"
          onSave={(investor: Investor) =>
            addInvestor(investor, {
              onSuccess: ({ id, name }) => {
                onAdd({ id, name: name as string })
              },
            })
          }
          onCancel={() => setStep('choose-type')}
        />
      )}
      {step === 'create-investor-entity' && (
        <FormBorrowerEntity
          saving={isInvestorAdding}
          include={[]}
          cancelText="Back"
          saveText="Next"
          onSave={(investor: Investor) =>
            addInvestor(investor, {
              onSuccess: ({ id, name }) => {
                onAdd({ id, name: name as string })
              },
            })
          }
          onCancel={() => setStep('choose-type')}
        />
      )}
    </Modal>
  )
}

export { ModalCreateRecipient }
