import { request } from 'services/request'
import { Loan, BankingData } from 'types'
import { updateResponseLoan } from 'utils/api/loan-parser'

const updateLoanBanking = async (
  loanId: string,
  payload: BankingData
): Promise<Loan> => {
  const {
    data: { loan },
  } = await request.post(`/loan/${loanId}/banking`, payload)
  return updateResponseLoan(loan)
}

export { updateLoanBanking }
