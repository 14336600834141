import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  addFund,
  getFund,
  getFunds,
  updateFund,
  addFundAddress,
  updateFundAddress,
  deleteFund,
  getFundPortfolio,
} from 'admin/services/api/funds'
import { KEY_FUNDS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Address, Filter, Fund, Pagination, Person, Search, Sort } from 'types'
import { message } from 'utils/message'

const useFunds = () => {
  return useQuery({
    queryKey: [KEY_FUNDS],
    queryFn: () => getFunds(),
  })
}

const useFund = (id: string) => {
  return useQuery({
    queryKey: [KEY_FUNDS, id],
    queryFn: () => getFund(id),
  })
}

const useFundPortfolio = ({
  fundId,
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
}: {
  fundId?: string
  search?: Search
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_FUNDS,
      fundId,
      'portfolio',
      search,
      nextFilter,
      pagination,
      sort,
    ],
    queryFn: () =>
      getFundPortfolio(fundId as string, {
        search,
        filter: nextFilter,
        page: pagination,
        sort,
      }),
    placeholderData: keepPreviousData,
    enabled: !!fundId,
  })
}

const useAddFund = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (person: Partial<Person>) => addFund(person),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS] })
      message.success('Fund added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateFund = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Partial<Fund>) => updateFund(fundId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS, fundId] })
      message.success('Information saved')
    },
    onError: handleErrorResponse,
  })
}

const useAddFundAddress = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (address: Address) => addFundAddress(fundId, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS, fundId] })
      message.success('Address saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateFundAddress = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      addressId,
      address,
    }: {
      addressId: string
      address: Address
    }) => updateFundAddress(fundId, addressId, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS, fundId] })
      message.success('Address saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteFund = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteFund(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_FUNDS],
          exact: true,
        })
        message.success('Fund deleted')
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

export {
  useFunds,
  useFund,
  useFundPortfolio,
  useAddFund,
  useUpdateFund,
  useAddFundAddress,
  useUpdateFundAddress,
  useDeleteFund,
}
