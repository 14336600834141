import { ColumnDef } from '@tanstack/react-table'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathToLoan } from 'admin/path-to'
import { Table } from 'components/Table'
import { Sort, Transaction } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  data?: Transaction[]
  sort?: Sort | undefined
  showLoan?: boolean
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableTrustTransactions({
  data = [],
  sort,
  showLoan = false,
  onSort,
  loading,
}: Props) {
  const columns: ColumnDef<Transaction>[] = useMemo(
    () =>
      compact([
        {
          accessorKey: 'date',
          header: 'Date',
          size: 140,
          cell: ({ getValue }) => formatDate(getValue() as string),
        },
        ...(showLoan
          ? [
              {
                id: 'loan',
                header: 'Loan',
                cell: ({ row }) => {
                  const { loan } = row.original
                  return loan ? (
                    <NavLink to={pathToLoan(loan)} className="link">
                      {loan.name}
                    </NavLink>
                  ) : (
                    '-'
                  )
                },
              },
            ]
          : []),
        {
          accessorKey: 'payment.type',
          header: 'Type',
        },
        {
          header: 'Amount',
          accessorKey: 'amount',
          size: 150,
          cell: ({ getValue }) => formatUsd(getValue() as number),
          meta: {
            align: 'right',
          },
        },
        {
          header: 'Balance',
          accessorKey: 'balance',
          size: 150,
          cell: ({ getValue }) => formatUsd(getValue() as number),
          meta: {
            align: 'right',
          },
        },
      ]),
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export { TableTrustTransactions }
