import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addLoanFundingSource,
  deleteLoanFundingSource,
  getLoanFundingSources,
  updateLoanFundingSource,
  LoanTransfer,
  addLoanTransfer,
  getLoanTransactions,
} from 'admin/services/api/loan-funding-sources'
import {
  KEY_LOAN_FUNDING_SOURCES,
  KEY_LOAN_PAYMENTS,
  KEY_LOANS,
} from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LoanFundingSource } from 'types'
import { message } from 'utils/message'

const useLoanFundingSources = ({ loanId }: { loanId: string }) => {
  return useQuery({
    queryKey: [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
    queryFn: () => getLoanFundingSources(loanId),
    enabled: !!loanId,
  })
}

const useAddLoanFundingSource = ({ loanId }: { loanId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (foundingSource: Partial<LoanFundingSource>) =>
      addLoanFundingSource(loanId, foundingSource),
    onSuccess: (loanFoundingSources) => {
      queryClient.setQueryData(
        [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
        loanFoundingSources
      )
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      message.success('Funding source added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanFundingSource = ({ loanId }: { loanId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (foundingSource: LoanFundingSource) =>
      updateLoanFundingSource(loanId, foundingSource),
    onSuccess: (loanFoundingSources) => {
      queryClient.setQueryData(
        [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
        loanFoundingSources
      )
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      message.success('Funding source updated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanFundingSource = ({ loanId }: { loanId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (foundingSourceId: string) =>
      deleteLoanFundingSource(loanId, foundingSourceId),
    onSuccess: (loanFundingSources) => {
      queryClient.setQueryData(
        [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
        loanFundingSources
      )
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      message.success('Funding source deleted')
    },
    onError: handleErrorResponse,
  })
}

const useAddLoanTransfer = ({ loanId }: { loanId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (loanTransfer: LoanTransfer) =>
      addLoanTransfer(loanId, loanTransfer),
    onSuccess: (loanFoundingSources) => {
      queryClient.setQueryData(
        [KEY_LOANS, loanId, KEY_LOAN_FUNDING_SOURCES],
        loanFoundingSources
      )
      queryClient.invalidateQueries({ queryKey: [KEY_LOANS, loanId] })
      queryClient.invalidateQueries({ queryKey: [KEY_LOAN_PAYMENTS, loanId] })
      message.success('Transfer added')
    },
    onError: handleErrorResponse,
  })
}

const useLoanTransactions = ({
  loanId,
  fundingSourceId,
}: {
  loanId: string
  fundingSourceId: string
}) => {
  return useQuery({
    queryKey: [
      KEY_LOANS,
      loanId,
      KEY_LOAN_FUNDING_SOURCES,
      fundingSourceId,
      'transactions',
    ],
    queryFn: () => getLoanTransactions(loanId, fundingSourceId),
  })
}

export {
  useLoanFundingSources,
  useAddLoanFundingSource,
  useUpdateLoanFundingSource,
  useDeleteLoanFundingSource,
  useAddLoanTransfer,
  useLoanTransactions,
}
