import { compact } from 'lodash'
import { Payment } from 'types'
import { sumDecimal } from './math'

const getCalculations = (payment?: Payment, collapseInterest?: boolean) => {
  const isFunding =
    payment?.type && ['Funding', 'Transfer'].includes(payment?.type)

  const sources = compact([
    payment?.source.borrower
      ? {
          name: 'Borrower',
          value: payment.source.borrower,
        }
      : undefined,
  ])
  const distributions = isFunding
    ? []
    : compact([
        payment?.distribution.principal
          ? {
              name: 'Principal',
              value: payment.distribution.principal,
            }
          : undefined,
        payment?.distribution.interest ||
        (payment?.distribution.spread && collapseInterest)
          ? {
              name: 'Interest',
              value: sumDecimal([
                payment.distribution.interest || 0,
                collapseInterest ? payment?.distribution?.spread || 0 : 0,
              ]),
            }
          : undefined,
        payment?.distribution.spread && !collapseInterest
          ? {
              name: 'Spread',
              value: payment.distribution.spread,
            }
          : undefined,
        payment?.type === 'To Borrower' && payment?.distribution.trusts
          ? {
              name: 'To Borrower',
              value: payment.distribution.trusts * -1,
            }
          : undefined,
        payment?.distribution.unpaidInterest
          ? {
              name: 'Unpaid Interest',
              value: payment.distribution.unpaidInterest,
            }
          : undefined,
      ])
  payment?.transactions?.forEach(({ credit, debit, trust, charge, person }) => {
    if ((trust || charge || (isFunding && person)) && debit) {
      sources.push({
        name: (trust?.name || charge?.name || person?.name) as string,
        value: debit,
      })
    }
    if ((trust || charge || (isFunding && person)) && credit) {
      distributions.push({
        name: (trust?.name ||
          charge?.name ||
          (person?.name || person?.companyName
            ? compact([person?.companyName, person?.name]).join(' - ')
            : undefined)) as string,
        value: credit,
      })
    }
  })

  return { sources, distributions }
}

export { getCalculations }
