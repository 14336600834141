import { Grid } from 'components/Grid'
import { Loan } from 'types'
import PanelBorrower from './PanelBorrowers'
import PanelGuarantor from './PanelGuarantors'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

function TabLoanTerms({ loan }: Props) {
  return (
    <Grid gap={16}>
      <Grid.Item sm={6} className={styles.panels}>
        <PanelBorrower loan={loan} />
      </Grid.Item>
      <Grid.Item sm={6} className={styles.panels}>
        <PanelGuarantor loan={loan} />
      </Grid.Item>
    </Grid>
  )
}

export default TabLoanTerms
