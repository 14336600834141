import { omitBy, size } from 'lodash'
import { useEffect, useState } from 'react'
import { Panel } from 'components/Panel'
import { Tabs } from 'components/Tabs'
import { useInvestorFundingSources } from 'hooks/use-investor-transactions'
import { usePagination } from 'hooks/use-pagination'
import { LoanFundingSource } from 'types'
import { TableInvestments } from './TableInvestments'
import styles from './styles.module.scss'

interface Props {
  investorId: string
  onLoad: () => void
}

function InvestmentsWidget({ investorId, onLoad }: Props) {
  const [performingInvestments, setPerformingInvestments] = useState<
    LoanFundingSource[]
  >([])
  const [liquidatedInvestments, setLiquidatedInvestments] = useState<
    LoanFundingSource[]
  >([])
  const { result, filter, sort, setFilter, setSort } = usePagination({
    property: 'fundingSources',
    useData: (params) =>
      useInvestorFundingSources({
        investorId,
        params: { filter: params.filter, sort: params.sort },
      }),
  })

  useEffect(() => {
    if (result.isSuccess) {
      onLoad()
    }
  }, [result.isSuccess])

  useEffect(() => {
    setLiquidatedInvestments(
      result.data?.filter(
        ({ dateFunded, amount, spread, loan }) =>
          (dateFunded && amount === 0 && !spread) ||
          loan?.status === 'liquidated'
      ) || []
    )
  }, [result.data])

  useEffect(() => {
    setPerformingInvestments(
      result.data?.filter(
        (investment) => !liquidatedInvestments.includes(investment)
      ) || []
    )
  }, [liquidatedInvestments, result.data])

  return (
    <Panel>
      <Tabs defaultActiveId="performing">
        <Tabs.Pane id="performing" tab="Performing" className={styles.tab}>
          <TableInvestments
            data={performingInvestments}
            loading={result.isPending}
            filter={filter}
            sort={sort}
            onSort={setSort}
            onFilter={(newFilter) => {
              setFilter(
                omitBy({ ...filter, ...newFilter }, (value) => !size(value))
              )
            }}
          />
        </Tabs.Pane>
        <Tabs.Pane id="liquidated" tab="Liquidated" className={styles.tab}>
          <TableInvestments
            data={liquidatedInvestments}
            loading={result.isPending}
            filter={filter}
            sort={sort}
            onSort={setSort}
          />
        </Tabs.Pane>
      </Tabs>
    </Panel>
  )
}

export { InvestmentsWidget }
