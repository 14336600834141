import { Formik } from 'formik'
import { toNumber } from 'lodash'
import { Button } from 'components/Button'
import { FieldIcon, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

interface Props {
  saving: boolean
  onSave: (fee: { name: string; amount: number }) => void
  onCancel: () => void
}

const Schema = createScheme({
  name: required,
  amount: required,
})

function QuoteFee({ saving, onSave, onCancel }: Props) {
  const initialValue = {
    name: '',
    amount: '',
  }

  return (
    <Modal
      title="Add additional fee"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={(values) => {
          onSave({
            name: values.name,
            amount: toNumber(values.amount),
          })
        }}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={6}>
              <FieldIcon type="text" name="name" label="Name" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="currency" name="amount" label="Amount" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default QuoteFee
