import { Formik } from 'formik'
import { Valuation } from '../../../types/person'
import { parseInitValues } from '../../../utils/form'
import { createScheme, required } from '../../../utils/schemas'
import { Button } from '../../Button'
import { Form, Select, Date, FieldIcon } from '../../Form'
import { Grid } from '../../Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  valuation?: Valuation
  saving?: boolean
  onSave: (valuation: Partial<Valuation>) => void
  onCancel: () => void
}

const Schema = createScheme({
  name: required,
  appraiser: required,
  reportDate: required,
  value: required,
  valuationType: required,
})

function ModalAddressValuation({ valuation, saving, onSave, onCancel }: Props) {
  const initialValue: Partial<Valuation> = {
    name: '',
    appraiser: '',
    reportDate: '',
    value: undefined,
    valuationType: '',
    marketRent: undefined,
    source: '',
    ...parseInitValues(valuation || {}),
  }

  return (
    <Modal
      title={valuation ? 'Edit Valuation' : 'Add New Valuation'}
      onClose={onCancel}
      className={styles.modal}
      asChild
    >
      <Formik
        initialValues={initialValue}
        enableReinitialize={true}
        validationSchema={Schema}
        onSubmit={onSave}
        validateOnChange={false}
      >
        <Form modal>
          <Grid className={styles.form} columnGap={24}>
            <Grid.Item xs={6}>
              <FieldIcon type="text" name="name" label="Name" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon type="text" name="appraiser" label="Appraiser Name" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Date name="reportDate" label="Report Date" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon name="value" type="currency" label="Value" />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="valuationType"
                label="Type"
                options={[
                  { label: 'As Is', value: 'As Is' },
                  { label: 'ARV', value: 'ARV' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <FieldIcon
                name="marketRent"
                type="currency"
                label="Market Rent"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <Select
                name="source"
                label="Source"
                options={[
                  { label: 'Appraisal', value: 'Appraisal' },
                  { label: 'Hybrid Appraisal', value: 'Hybrid Appraisal' },
                  { label: 'BPO', value: 'BPO' },
                  { label: 'CDA', value: 'CDA' },
                  { label: 'Others', value: 'Others' },
                ]}
                portal
              />
            </Grid.Item>
          </Grid>
          <Grid.Item xs={12} className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} type="submit">
              Save
            </Button>
          </Grid.Item>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalAddressValuation
