import clsx from 'clsx'
import { ReactNode, forwardRef, ForwardedRef } from 'react'

const Section = forwardRef(
  (
    {
      children,
      className,
      ...props
    }: { children: ReactNode; className?: string },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={clsx('font-bold px-1.5 leading-[27px]', className)}
        {...props}
      >
        {children}
      </div>
    )
  }
)
Section.displayName = 'EllipsesActions.Section'

export default Section
