import { addDays, format, parseISO } from 'date-fns'
import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import {
  useLoanModifications,
  useAddLoanModification,
} from 'admin/hooks/use-loan-modification'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Field, Loan } from 'types'
import { formatField, getField, getFieldsBySection } from 'utils/fields'
import { useLoanContext } from './LoanContext'
import { TabGeneralServicingModifyMenu } from './TabGeneralServicingModifyMenu'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

const sections = ['Terms', 'Payment', 'Parameters']

function PanelLoanServicingTerms({ loan }: Props) {
  const { openEditModification } = useLoanContext()
  const [allowLoadModifications, setAllowLoadModifications] = useState(false)
  const fieldGroups: Field[][] = useMemo(
    () =>
      sections.map((section) => {
        if (section === 'Payment') {
          return compact([
            getField(loan.fields, 'principal-and-interest', true),
            getField(loan.fields, 'to-trust', true),
            getField(loan.fields, 'regular-payment', true),
          ])
        }
        return getFieldsBySection(loan.fields, section, 'servicing')
      }),
    [loan]
  )
  const hasFields = useMemo(() => fieldGroups.flat().length > 0, [fieldGroups])
  const { openTimeline } = useLoanContext()
  const { data: modifications, isPending } = useLoanModifications(loan.id, {
    enabled: allowLoadModifications,
  })
  const { mutate: addModification } = useAddLoanModification(loan.id)

  const handleAddModification = useCallback(() => {
    const lastModification = modifications?.modifications[0]
    addModification(
      {
        dateEffective: format(
          addDays(
            lastModification?.dateEffective
              ? parseISO(lastModification.dateEffective)
              : new Date(),
            1
          ),
          'yyyy-MM-dd'
        ),
      },
      {
        onSuccess: (modification) =>
          openEditModification({ ...modification, isNew: true }),
      }
    )
  }, [modifications, addModification])
  const handleEditModification = useCallback(
    (id: string) =>
      openEditModification(
        modifications?.modifications.find((m) => m.id === id) || null
      ),
    [modifications]
  )

  return hasFields ? (
    <Panel>
      {fieldGroups.map((fields, index) => (
        <Grid gap={24} key={`section-${sections[index]}-${fields[0]?.id}`}>
          {index > 0 && (
            <Grid.Item sm={12}>
              <div className={styles.divider} />
            </Grid.Item>
          )}
          <Grid.Item sm={12}>
            <Flex alignItems="center" justifyContent="space-between">
              <Header variant="h4">{sections[index]}</Header>
              {index === 0 && (
                <Flex alignItems="center">
                  <div
                    className="font-bold cursor-pointer"
                    onClick={() => {
                      openTimeline()
                      setAllowLoadModifications(true)
                    }}
                  >
                    Timeline
                  </div>
                  <TabGeneralServicingModifyMenu
                    modifications={modifications?.modifications || []}
                    loading={isPending}
                    onOpen={() => setAllowLoadModifications(true)}
                    onEditModification={handleEditModification}
                    onAddModification={handleAddModification}
                  />
                </Flex>
              )}
            </Flex>
          </Grid.Item>
          {fields.map((field) => (
            <Grid.Item key={field.id} sm={6}>
              <Summary name={field.name}>{formatField(field)}</Summary>
            </Grid.Item>
          ))}
        </Grid>
      ))}
    </Panel>
  ) : null
}

export { PanelLoanServicingTerms, sections }
