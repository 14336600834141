import { request } from 'services/request'
import { Sort } from 'types'
import { Filter, Meta, Pagination } from 'types/table'
import { updateRequestTable } from 'utils/api/table-parser'
import { Vendor } from './vendors'

const getLoanVendors = async ({
  loanId,
  ...params
}: {
  loanId: string
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
  details?: boolean
}): Promise<{ vendors: Vendor[]; meta: Meta }> => {
  const {
    data: { vendors, pagination },
  } = await request.get(`/loan/${loanId}/vendor`, {
    params: updateRequestTable(params),
  })
  return { vendors, meta: pagination }
}

const createLoanVendor = async (
  loanId: string,
  payload: Omit<Vendor, 'id'>
): Promise<{ vendors: Vendor[]; vendor: Vendor }> => {
  const {
    data: { vendors, vendor },
  } = await request.post(`/loan/${loanId}/vendor`, payload)
  return { vendors, vendor }
}

const addLoanVendor = async (
  loanId: string,
  vendor: { id: string }
): Promise<Vendor[]> => {
  const {
    data: { vendors },
  } = await request.put(`/loan/${loanId}/vendor`, vendor)
  return vendors
}

const removeLoanVendor = async (loanId: string, vendorId: string) => {
  return request.delete(`/loan/${loanId}/vendor/${vendorId}`)
}

export { getLoanVendors, addLoanVendor, createLoanVendor, removeLoanVendor }
