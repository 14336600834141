import { ColumnDef } from '@tanstack/react-table'
import { take, drop } from 'lodash'
import { useMemo } from 'react'
import { Flex } from 'components/Flex'
import { ModalImage, useModalImage } from 'components/ModalImage'
import { Progress } from 'components/Progress'
import { Table } from 'components/Table'
import { BudgetActivity, BudgetItem } from 'types'
import { formatUsd } from 'utils/currency'
import { divDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  data?: BudgetItem[]
  onClick: (item: BudgetItem) => void
}

function TableDrawBudgetItems({ data = [], onClick }: Props) {
  const {
    isImagesModalVisible,
    imageUrls,
    imageIndex,
    handleImageClick,
    handleClose,
  } = useModalImage()
  const columns: ColumnDef<BudgetItem>[] = useMemo(
    () => [
      {
        header: 'Work',
        accessorKey: 'name',
      },
      {
        header: 'Budget',
        accessorKey: 'amount',
        size: 130,
        cell: (cell) => formatUsd(cell.getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Draw Balance',
        id: 'balance-percent',
        size: 120,
        cell: ({ row }) => {
          const budgetItem = row.original
          const drawBalancePercent =
            divDecimal(budgetItem.balance, budgetItem.amount) * 100
          return <Progress value={drawBalancePercent} />
        },
        meta: {
          align: 'right',
        },
      },
      {
        header: '',
        id: 'balance',
        size: 100,
        cell: ({ row }) => {
          const budgetItem = row.original
          return formatUsd(budgetItem.balance)
        },
      },
      {
        header: 'Requested',
        accessorKey: 'requestedAmount',
        size: 130,
        cell: (cell) => formatUsd(cell.getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Activity',
        accessorKey: 'images',
        size: 162,
        cell: (cell) => {
          const images = cell.getValue() as BudgetActivity['images']
          const isMoreVisible = images.length > 4
          const visibleImages = take(images, isMoreVisible ? 3 : 4)
          const otherImages = drop(images, isMoreVisible ? 3 : 4)

          return (
            <Flex gap={8}>
              {visibleImages.map((image) => (
                <img
                  key={image.id}
                  src={image.viewUrl}
                  className={styles.image}
                  onClick={(e) =>
                    handleImageClick(
                      e,
                      images.map(({ viewUrl }) => viewUrl),
                      image.viewUrl
                    )
                  }
                />
              ))}
              {isMoreVisible && (
                <div
                  className={styles.more}
                  onClick={(e) => handleImageClick(e, images)}
                >
                  +{otherImages.length}
                </div>
              )}
            </Flex>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      <Table
        className={styles.table}
        columns={columns}
        data={data}
        onClick={(item) => onClick(item as BudgetItem)}
      />
      {isImagesModalVisible && (
        <ModalImage
          onClose={handleClose}
          imageUrls={imageUrls}
          index={imageIndex}
        />
      )}
    </>
  )
}

export { TableDrawBudgetItems }
