import { useCallback } from 'react'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportUnpaidInterest } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { downloadUnpaidInterest } from 'admin/services/csv/download-unpaid-interest'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import TableUnpaidInterest from './TableUnpaidInterest'

function UnpaidInterestReport() {
  const { visibleItems, result, filter, sort, search, setSort, setPagination } =
    usePagination<any>({
      property: 'unpaidInterests',
      useData: (tableProps) =>
        useReportUnpaidInterest('unpaid-interest', tableProps),
    })
  const handleDownload = useCallback(
    () =>
      downloadUnpaidInterest({
        filter,
        sort,
        search,
      }),
    [filter, sort, search]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Unpaid Interest"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        >
          <Download filename="unpaid-interest" download={handleDownload} />
        </PageTop>
        <Panel>
          <TableUnpaidInterest
            data={visibleItems}
            loading={result.isPending}
            sort={sort}
            onSort={setSort}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { UnpaidInterestReport }
