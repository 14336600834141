import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addRole,
  deleteRole,
  getRoles,
  updateRole,
} from 'admin/services/api/roles'
import { KEY_ROLES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from 'utils/message'

const useRoles = () => {
  return useQuery({
    queryKey: [KEY_ROLES],
    queryFn: getRoles,
  })
}

const useAddRole = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_ROLES] })
      message.success('Role added')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateRole = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_ROLES] })
      message.success('Role updated')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveRole = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_ROLES] })
      message.success('Role removed')
    },
    onError: handleErrorResponse,
  })
}

export { useRoles, useAddRole, useUpdateRole, useRemoveRole }
