import { BorrowerDetails } from 'admin/services/api/borrowers'
import { InvestorDetails } from 'admin/services/api/investors'
import { Loan, LoanDocument } from 'types'

interface Props {
  loanId?: string
  personType?: string
  loan?: Loan
  investor?: InvestorDetails
  borrower?: BorrowerDetails
  data?: LoanDocument
  isLoading: boolean
}

export const useSharePerson = ({
  loanId,
  personType,
  loan,
  investor,
  borrower,
  data,
  isLoading,
}: Props) => {
  let sharePersons: {
    id: string
    name: string
    typeOfPerson: string
  }[] = []

  if (!isLoading) {
    if (loanId && !data?.borrowerId && !data?.guarantorId) {
      const borrowers = loan?.borrowers.map((borrower) => ({
        id: borrower.id,
        name: borrower.name,
        typeOfPerson: 'Borrower',
      }))
      const guarantors = loan?.guarantors.map((guarantor) => ({
        id: guarantor.id,
        name: guarantor.name,
        typeOfPerson: 'Guarantor',
      }))
      sharePersons = [...(borrowers || []), ...(guarantors || [])]
    } else if (loanId && data?.borrowerId) {
      const borrower = loan?.borrowers.find(
        (borrower) => borrower.id === data.borrowerId
      )
      borrower &&
        (sharePersons = [
          {
            id: borrower.id,
            name: borrower.name,
            typeOfPerson: 'Borrower',
          },
        ])
    } else if (loanId && data?.guarantorId) {
      const guarantor = loan?.guarantors.find(
        (guarantor) => guarantor.id === data.guarantorId
      )
      guarantor &&
        (sharePersons = [
          {
            id: guarantor.id,
            name: guarantor.name,
            typeOfPerson: 'Guarantor',
          },
        ])
    } else if (personType === 'investors' && investor) {
      sharePersons = [
        {
          id: investor.id,
          name: investor.name,
          typeOfPerson: 'Investor',
        },
      ]
    } else if (personType === 'borrowers' && borrower) {
      sharePersons = [
        {
          id: borrower.id,
          name: borrower.name,
          typeOfPerson: 'Borrower',
        },
      ]
    }

    const documentPeople = [
      data?.borrower && {
        ...data?.borrower,
        ...(data?.borrower.isBorrower
          ? { typeOfPerson: 'Borrower' }
          : { typeOfPerson: 'Investor' }),
      },
      data?.guarantor && { ...data?.guarantor, typeOfPerson: 'Guarantor' },
    ].filter(Boolean)
    documentPeople.forEach((person) => {
      const personExist = sharePersons.find(
        (sharePerson) => sharePerson.id === person?.id
      )
      !personExist &&
        sharePersons.push({
          id: person?.id || '',
          name: person?.name || '',
          typeOfPerson: person?.typeOfPerson || '',
        })
    })
  }
  return sharePersons
}
