import clsx from 'clsx'
import { isNil } from 'lodash'
import { useMemo, useState } from 'react'
import { useDeleteLoanModification } from 'admin/hooks/use-loan-modification'
import { ModificationDocumentMenu } from 'admin/pages/Loan/DrawerModifications/ModificationDocumentMenu'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Indicator } from 'components/Indicator'
import { ModalDelete } from 'components/Modal/Delete'
import { LoanModification as ILoanModification } from 'types'
import { formatRelativeDate, friendlyDate } from 'utils/date'
import { formatField } from 'utils/fields'
import { ModificationMenu } from './ModificationMenu'

interface Props {
  loanId: string
  previousModification: ILoanModification
  modification: ILoanModification
  onEditModification: (id: string) => void
  onGenerateDocument: (id: string) => void
  onDeleteDocument: (id: string) => void
}

const LoanModification = ({
  loanId,
  previousModification,
  modification,
  onGenerateDocument,
  onEditModification,
  onDeleteDocument,
}: Props) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const { mutate: deleteModification, isPending } = useDeleteLoanModification(
    modification.loanId as string
  )
  const fields = useMemo(
    () =>
      modification.fields
        .filter(({ property }) => property.isModification)
        .map((field) => ({
          ...field,
          previousField: previousModification?.fields.find(
            ({ id }) => id === field.id
          ),
        }))
        .map((field) => {
          if (field.id === 'principal-and-interest') {
            if (
              field.previousField &&
              isNil(field.previousField.property.value[0])
            ) {
              field.previousField.property.value = [
                previousModification.regularPayment.amount,
              ]
            }
            if (isNil(field.property.value[0])) {
              field.property.value = [modification.regularPayment.amount]
            }
          }
          return field
        }),
    [modification.fields, previousModification?.fields]
  )

  return (
    <>
      <Flex className="pt-5">
        <Indicator
          color="green"
          className={clsx(
            'w-2.5 h-2.5 m-1.5 mt-2 flex-shrink-0 rounded-[3px]',
            modification.isActive
              ? 'bg-green-50'
              : 'w-3 h-3 bg-white-100 border-solid border-[2px] border-grey-400'
          )}
        />
        <Flex
          stack
          gap={20}
          className="w-full pb-5 border-solid border-0 border-b border-grey-200 cursor-pointer"
          onClick={() => setIsInfoOpen((state) => !state)}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex stack gap={0} className="leading-[18px]">
              <Flex gap={8}>
                <div className="font-bold">
                  Loan{' '}
                  {modification.isOrigination ? 'Origination' : 'Modification'}
                </div>
                {!!modification.documentId && (
                  <Icon
                    name={IconName.paperClip}
                    size="md"
                    className="text-grey-700"
                  />
                )}
              </Flex>
              <div className="text-sm">
                {friendlyDate(modification.dateEffective)}
              </div>
            </Flex>
            <Flex gap={0}>
              <ModificationMenu
                hasDocument={!!modification.document}
                onGenerateDocument={
                  !modification.isOrigination
                    ? () => onGenerateDocument(modification.id)
                    : undefined
                }
                onEdit={() => onEditModification(modification.id)}
                onDelete={
                  !modification.isOrigination
                    ? () => setIsDeleteModalOpen(true)
                    : undefined
                }
              />
              <Flex
                onClick={(e) => {
                  e.stopPropagation()
                  setIsInfoOpen((state) => !state)
                }}
                className="w-8 h-8 rounded cursor-pointer hover:bg-grey-100"
              >
                <Icon
                  name={IconName.arrowDown}
                  className={clsx(
                    'm-auto text-grey-700',
                    isInfoOpen && 'rotate-180'
                  )}
                />
              </Flex>
            </Flex>
          </Flex>

          {isInfoOpen && (
            <Flex stack gap={12} className="mb-1 text-grey-900">
              {fields.length > 0 ? (
                <div className="rounded border-solid border-[1px] border-grey-200">
                  {fields.map((field) => (
                    <Flex
                      key={field.id}
                      justifyContent="space-between"
                      className="p-3 border-solid border-0 border-b-[1px] border-grey-200 last:border-b-0"
                    >
                      <div>{field.name}</div>
                      <Flex alignItems="center" gap={16}>
                        {field.previousField && (
                          <>
                            <div className="text-grey-700">
                              {formatField(field.previousField)}
                            </div>
                            <Icon
                              name={IconName.arrowRightLong}
                              size="md"
                              className="text-grey-600 font-slim"
                            />
                          </>
                        )}
                        <div className="font-bold">{formatField(field)}</div>
                      </Flex>
                    </Flex>
                  ))}
                </div>
              ) : (
                <div className="text-base">
                  There are no changes in the modification
                </div>
              )}

              {!!modification.document && (
                <Flex
                  justifyContent="space-between"
                  className="py-2.5 px-3 rounded border-solid border-[1px] border-grey-200"
                >
                  <Flex gap={10} alignItems="center">
                    <Icon
                      name={IconName.documentBlank}
                      size="md"
                      className="m-auto"
                    />
                    <div className="leading-[18px]">
                      <div>{modification.document.name}</div>
                      <div>
                        Generated{' '}
                        {formatRelativeDate(
                          modification.document.versions?.[0]?.createdAt ||
                            modification.document.createdAt
                        )}
                      </div>
                    </div>
                  </Flex>

                  <Flex gap={10} alignItems="center">
                    {modification.isDocumentOutdated && (
                      <Badge color="gray" className="!text-red-50">
                        Outdated
                      </Badge>
                    )}
                    <ModificationDocumentMenu
                      onView={() => {
                        window.open(
                          pathTo(
                            'servicingLoanDocument',
                            loanId,
                            modification.document?.id as string
                          )
                        )
                      }}
                      onRegenerate={() => onGenerateDocument(modification.id)}
                      onDownload={() =>
                        window.open(modification.document?.versions?.[0].url)
                      }
                      onDelete={() => onDeleteDocument(modification.id)}
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      {isDeleteModalOpen && (
        <ModalDelete
          resource="modification"
          loading={isPending}
          onDelete={() =>
            deleteModification(modification.id, {
              onSuccess: () => setIsDeleteModalOpen(false),
            })
          }
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </>
  )
}

export { LoanModification }
