const PREVIEW_APPLICATION_KEY = 'preview-application'
const PREVIEW_APPLICATION_DATE_KEY = 'preview-application-date'

const savePreviewApplication = (application: any) => {
  localStorage.setItem(PREVIEW_APPLICATION_DATE_KEY, Date.now().toString())
  localStorage.setItem(PREVIEW_APPLICATION_KEY, JSON.stringify(application))
}

const readPreviewApplication = () => {
  const application = localStorage.getItem(PREVIEW_APPLICATION_KEY)
  return application ? JSON.parse(application) : undefined
}

const deletePreviewApplication = () => {
  localStorage.removeItem(PREVIEW_APPLICATION_DATE_KEY)
  localStorage.removeItem(PREVIEW_APPLICATION_KEY)
}

export {
  savePreviewApplication,
  readPreviewApplication,
  deletePreviewApplication,
}
