import { useState } from 'react'
import { ImportEntity } from 'admin/components/ImportEntity'
import {
  useUpdateLoanPayment,
  useDeleteLoanPayment,
} from 'admin/hooks/use-loan-payment'
import { pathTo } from 'admin/path-to'
import { downloadLoanPayments } from 'admin/services/csv/download-loan-payments'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalPaymentDetails } from 'components/Modal/PaymentDetails'
import { ModalReversePayment } from 'components/Modal/ReversePayment'
import { Panel } from 'components/Panel'
import { TablePayments } from 'components/TablePayments'
import { PAYMENT_IMPORT_BANNER } from 'constants/local-storage-keys'
import { useLoanPayments } from 'hooks/use-loan-payment'
import { useDownloadPdf } from 'hooks/use-pdf'
import { Loan, Payment } from 'types'
import { getFieldValue } from 'utils/fields'
import { AlertPayments } from '../Payments/AlertPayments'

interface Props {
  loan: Loan
  showDistributions?: boolean
  onCreate?: () => void
  title?: string
  typeIncludeFilter?: string[]
  typeExcludeFilter?: string[]
}

export default function PanelLoanPayments({
  loan,
  onCreate,
  showDistributions = false,
  title = 'Payments',
  typeIncludeFilter,
  typeExcludeFilter = ['Transfer'],
}: Props) {
  const [viewId, setViewId] = useState<string>()
  const [removingId, setRemovingId] = useState<string | null>(null)
  const [reversePayment, setReversePayment] = useState<Partial<Payment> | null>(
    null
  )
  const [alertData, setAlertData] = useState<string | null | boolean>(
    localStorage.getItem(PAYMENT_IMPORT_BANNER)
  )
  const { data: payments, isPending: loading } = useLoanPayments(loan.id)
  const { mutate: update, isPending: updating } = useUpdateLoanPayment()
  const { mutate: remove, isPending: removing } = useDeleteLoanPayment()
  const { mutate: generatePdf } = useDownloadPdf()

  const paymentsFiltered = payments?.filter((payment) => {
    if (typeIncludeFilter) {
      return typeIncludeFilter.includes(payment.type)
    } else if (typeExcludeFilter) {
      return !typeExcludeFilter.includes(payment.type)
    }
  })

  return (
    <Panel
      title={title}
      actionChildren={
        <Flex>
          {!(loan.lock && loan.status !== 'liquidated') && onCreate && (
            <ImportEntity
              entityType="payment"
              loanId={loan.id}
              setAlertData={() => setAlertData(true)}
            />
          )}
          <Download
            filename="payments"
            download={() =>
              downloadLoanPayments(
                loan.id,
                typeIncludeFilter,
                typeExcludeFilter
              )
            }
          />

          {!(loan.lock && loan.status !== 'liquidated') && onCreate && (
            <Button onClick={onCreate}>Add Payment</Button>
          )}
        </Flex>
      }
    >
      {alertData && (
        <AlertPayments alertData={alertData} setAlertData={setAlertData} />
      )}
      <TablePayments
        loading={loading}
        data={paymentsFiltered}
        showDistributions={showDistributions}
        onClickRow={(payment) => setViewId(payment?.id)}
        onEdit={({ id }, data) => {
          if (data.status === 'reversed') {
            setReversePayment({ id, loanId: loan.id, ...data })
          } else {
            update({ id, loanId: loan.id, ...data })
          }
        }}
        onDownload={({ id }) => {
          generatePdf({ url: pathTo('receipt', loan.id, id) })
        }}
        onDelete={({ id }) => setRemovingId(id)}
      />

      {viewId && (
        <ModalPaymentDetails
          loanId={loan.id}
          paymentId={viewId}
          onCancel={() => setViewId(undefined)}
        />
      )}
      {reversePayment && (
        <ModalReversePayment
          defaultNsf={
            (getFieldValue(loan.fields, 'nsf-fee', 0, true) as string) ||
            undefined
          }
          payment={paymentsFiltered?.find(({ id }) => id === reversePayment.id)}
          saving={updating}
          onSave={(nsf) => {
            update(
              { ...reversePayment, nsf },
              {
                onSuccess: () => {
                  setReversePayment(null)
                },
              }
            )
          }}
          onCancel={() => setReversePayment(null)}
        />
      )}
      {removingId && (
        <ModalDelete
          resource="payment"
          loading={removing}
          onDelete={() =>
            remove(
              { id: removingId, loanId: loan.id },
              { onSuccess: () => setRemovingId(null) }
            )
          }
          onCancel={() => setRemovingId(null)}
        />
      )}
    </Panel>
  )
}
