import { getVendors } from 'admin/services/api/vendors'
import { Row, Sort } from 'types'

const downloadVendors = async (
  sort?: Sort,
  search?: string
): Promise<Row[]> => {
  const { vendors } = await getVendors({
    search,
    filter: { isAccount: [true] },
  })
  return vendors.map((vendor) => ({
    Company: vendor.companyName,
    Name: vendor.name,
    Email: vendor.email,
    Phone: vendor.phone,
    Role: vendor.role,
  }))
}

export { downloadVendors }
