import { ReactNode } from 'react'
import { toast, Icons, ToastOptions } from 'react-toastify'

const message = {
  upload: (name: string) => {
    return {
      show: () => {
        toast(`Uploading: ${name}`, {
          toastId: name,
          hideProgressBar: false,
          progress: 0.01,
          progressClassName: 'Toastify__progress-bar--info',
          autoClose: false,
        })
      },
      progress: (progress: number) => {
        toast.update(name, {
          progress: progress >= 1 ? 0.99 : progress,
        })
      },
      processing: () => {
        toast.update(name, {
          icon: Icons.spinner,
          render: `Processing: ${name}`,
          hideProgressBar: true,
        })
      },
      complete: () => {
        toast.update(name, {
          type: 'success',
          icon: Icons.success,
          render: `Complete: ${name}`,
          hideProgressBar: true,
        })
        setTimeout(() => {
          toast.dismiss(name)
        }, 2000)
      },
    }
  },
  progress: (message: string, delay: number) => {
    toast(message, {
      toastId: message,
      hideProgressBar: false,
      pauseOnHover: false,
      progressClassName: 'Toastify__progress-bar--info',
      autoClose: delay,
    })
    return () => toast.dismiss(message)
  },
  default: (message: ReactNode, options: ToastOptions = {}) => {
    toast(message, {
      toastId: message?.toString(),
      ...options,
    })
  },
  success: (message: ReactNode, options: ToastOptions = {}) => {
    toast.success(message, {
      toastId: message?.toString(),
      ...options,
    })
  },
  warning: (message: ReactNode, options: ToastOptions = {}) => {
    toast.warning(message, {
      toastId: message?.toString(),
      ...options,
    })
  },
  error: (message: ReactNode, options: ToastOptions = {}) => {
    toast.error(message, {
      toastId: message?.toString(),
      ...options,
    })
  },
}

export { message, toast }
