import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { DistributionStatusBadge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { FundDistribution, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

type ActivitiesTableProps = {
  data?: FundDistribution[]
  loading: boolean
  sort?: Sort | undefined
  onClick: (fundDistribution?: FundDistribution) => void
  onEdit: (fundDistribution: FundDistribution) => void
  onSort?: (sort: Sort | string | undefined) => void
  onDelete: (fundDistribution: FundDistribution) => void
}

function TableDistributions({
  data = [],
  loading,
  sort,
  onClick,
  onEdit,
  onSort,
  onDelete,
}: ActivitiesTableProps) {
  const columns: ColumnDef<FundDistribution>[] = useMemo(
    () => [
      {
        header: 'Date',
        accessorKey: 'date',
        cell: ({ getValue }) => formatDate(getValue() as string),
        meta: {
          sortable: true,
        },
      },
      {
        header: 'Charges',
        accessorKey: 'chargesAmount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Returned Capital',
        accessorKey: 'capitalReturn',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Income',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'DRIP',
        accessorKey: 'reinvested',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
          sortable: true,
        },
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          return <DistributionStatusBadge status={row.original.status} />
        },
      },
      {
        accessorKey: 'id',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item icon onSelect={() => onEdit(row.original)}>
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onClick={onClick}
      onSort={onSort}
    />
  )
}

export { TableDistributions }
