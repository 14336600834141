import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  KEY_DOCUMENTS,
  KEY_APPLICATIONS,
  KEY_DRAFT_APPLICATIONS,
} from 'constants/query-keys'
import {
  deleteApplication,
  deleteDraftApplication,
  getApplication,
  getDraftApplications,
  updateApplication,
  getApplications,
  signApplication,
  getApplicationDocuments,
} from 'services/api/application'
import { handleErrorResponse } from 'services/request'
import { Application } from 'types'
import { message } from 'utils/message'

const useApplications = () => {
  return useQuery({
    queryKey: [KEY_APPLICATIONS],
    queryFn: () => getApplications(),
  })
}

const useDraftApplications = () => {
  return useQuery({
    queryKey: [KEY_DRAFT_APPLICATIONS],
    queryFn: () => getDraftApplications(),
  })
}

const useApplication = (
  { id }: { id: string },
  options?: { enabled: boolean; staleTime?: number }
) => {
  return useQuery({
    queryKey: [KEY_APPLICATIONS, id],
    queryFn: () => getApplication(id),
    ...options,
  })
}

const useUpdateApplication = ({
  id,
  silent,
  dontSetQueryData,
  isArchived,
  onSuccess,
}: {
  id?: string
  silent?: boolean
  dontSetQueryData?: boolean
  isArchived?: boolean
  onSuccess?: () => void
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: Partial<Application>) =>
      updateApplication({ id, ...data }),
    onSuccess: (application) => {
      if (!dontSetQueryData) {
        queryClient.setQueryData([KEY_APPLICATIONS, id], application)
      }
      !silent &&
        message.success(`Application ${isArchived ? 'archived' : 'updated'}`)
      queryClient.invalidateQueries({ queryKey: [KEY_APPLICATIONS] })
      queryClient.invalidateQueries({ queryKey: [KEY_DRAFT_APPLICATIONS] })
      onSuccess && onSuccess()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteApplication = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteApplication(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_APPLICATIONS],
          exact: true,
        })
        message.success('Application deleted')
      }, 150)
    },
    onError: handleErrorResponse,
  })
}

const useDeleteDraftApplication = ({
  isArchived,
}: {
  isArchived?: boolean
} = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteDraftApplication(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_DRAFT_APPLICATIONS],
          exact: true,
        })
        message.success(`Application ${isArchived ? 'archived' : 'deleted'}`)
      }, 150)
    },
    onError: handleErrorResponse,
  })
}

const useSignApplication = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => signApplication({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_APPLICATIONS],
        exact: true,
      })
      message.success('Application submitted')
    },
    onError: handleErrorResponse,
  })
}

const useApplicationDocuments = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS],
    queryFn: () => getApplicationDocuments({ id }),
  })
}

export {
  useApplication,
  useDraftApplications,
  useUpdateApplication,
  useDeleteApplication,
  useDeleteDraftApplication,
  useApplications,
  useSignApplication,
  useApplicationDocuments,
}
