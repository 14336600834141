import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TableActivities } from 'admin/components/table/TableActivities'
import { useLoanActivities } from 'admin/hooks/use-loan-activities'
import { LoanChangeLog } from 'admin/services/api/loan-activities'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { Loan } from 'types'

interface Props {
  loan: Loan
}

const filterConfig: IFilterConfig[] = [
  {
    id: 'createdAt',
    type: 'date',
    label: 'Date',
  },
]

function PanelActivities({ loan }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )
  const { visibleItems, result, search, setSearch, setPagination } =
    usePagination<LoanChangeLog>({
      property: 'logs',
      useData: (params) =>
        useLoanActivities({
          loanId: loan.id,
          ...params,
          filter: filterValueToTableFilter(filtersValue),
        }),
    })

  const handleFilterChange = (value: IFilterValue) => {
    setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
  }

  return (
    <Panel
      title="Change Log"
      actionChildren={
        <Flex gap={8} alignItems="center">
          <Filter
            config={filterConfig}
            value={filtersValue}
            onApply={handleFilterChange}
          />
          <Search search={search} onSearch={setSearch} />
        </Flex>
      }
    >
      <TableActivities data={visibleItems} loading={result.isPending} />
      <LoadMore
        loading={result.isPending}
        fetching={result.isFetching}
        count={visibleItems.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
    </Panel>
  )
}

export default PanelActivities
