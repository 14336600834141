import clsx from 'clsx'
import styles from './styles.module.scss'

export interface ItemProps {
  active?: boolean
  label: string
  onClick?: () => void
}

function Item({ active, label, onClick }: ItemProps) {
  return (
    <>
      <div className={styles.item}>
        <a
          title={label}
          onClick={onClick}
          className={clsx(styles.itemLink, { [styles.active]: active })}
        >
          {label}
        </a>
      </div>
    </>
  )
}

export default Item
