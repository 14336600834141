import { sortBy, indexOf } from 'lodash'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { Field, QuoteOption as QuoteOptionType } from 'types'
import { formatUsd } from 'utils/currency'
import { formatField } from 'utils/fields'
import { sumDecimal } from 'utils/math'

interface Props {
  option: QuoteOptionType
  isHeaderVisible?: boolean
}

const enforcedFieldOrder = ['Loan Amount', 'Term', 'Est. Payment', ' Rate']

const QuoteOfferedTerms = ({ option, isHeaderVisible = true }: Props) => {
  const mainFields = sortBy(
    [
      ...option.fields.filter(
        ({ section }) => !['Statistics', 'Fees'].includes(section)
      ),
      {
        id: 'payment',
        name: 'Est. Payment',
        property: {
          value: [option.payment],
        },
        type: ['currency'],
        order: 0,
      } as Field,
    ],
    function (field) {
      const order = indexOf(enforcedFieldOrder, field.name)
      return order === -1
        ? (field.order || 0) + enforcedFieldOrder.length
        : order
    }
  )

  const statisticFields = option.fields.filter(
    ({ section }) => section === 'Statistics'
  )
  const chargesFields = option.fields.filter(
    ({ section }) => section === 'Fees'
  )

  const fees = [
    ...(option.fees || []),
    ...chargesFields.map(({ name, property: { value } }) => ({
      name,
      amount: value ? (value[0] as number | null) : 0,
    })),
  ]

  const totalFees = sumDecimal(fees.map(({ amount }) => amount))

  return (
    <div className="break-inside-avoid">
      <div className="rounded-sm rounded-b-none border-[1px] border-solid border-grey-200 px-5 pb-3 pt-5">
        {isHeaderVisible && (
          <Flex gap={8} alignItems="center">
            {option.product.client.image?.url && (
              <img
                src={option.product.client.image.url}
                className="max-h-6 max-w-10"
              />
            )}
            <Text variant="xs">{option.product.client.name}</Text>
            <Text variant="xs" className="text-grey-200">
              |
            </Text>
            <Text variant="xs" className="text-grey-700">
              {option.product.name}
            </Text>
          </Flex>
        )}
        <Grid rowGap={16} className="pb-4 pt-5">
          {mainFields.map((field) => (
            <Grid.Item key={field.id} xs={6} sm={4} lg={2}>
              <Summary name={field.name} className="gap-1">
                <Header variant="h4">{formatField(field)}</Header>
              </Summary>
            </Grid.Item>
          ))}
        </Grid>
      </div>
      <Grid
        cols={16}
        className="rounded-sm rounded-t-none border-[1px] border-t-0 border-solid border-grey-200 bg-grey-25 p-5"
      >
        <Grid.Item xs={16} sm={10} className="pb-6 sm:pb-0">
          <Flex stack gap={12}>
            <Header variant="h4">Metrics</Header>
            <Grid rowGap={16}>
              {statisticFields.map((field) => (
                <Grid.Item key={field.id} xs={6} sm={4}>
                  <Summary name={field.name} className="gap-1">
                    <Text variant="l">{formatField(field)}</Text>
                  </Summary>
                </Grid.Item>
              ))}
            </Grid>
          </Flex>
        </Grid.Item>
        <Grid.Item
          xs={16}
          sm={6}
          className="border-0 border-t-[1px] border-solid border-grey-200 pt-5 sm:border-l-[1px] sm:border-t-0 sm:pl-5 sm:pt-0"
        >
          <Flex stack gap={12}>
            <Header variant="h4">Estimated Closing Costs</Header>
            <Flex stack gap={8}>
              {fees?.map(({ name, amount }, index) => (
                <Flex justifyContent="space-between" key={name + index}>
                  <Text className="text-grey-700">{name}</Text>
                  <Text className="text-grey-900">{formatUsd(amount)}</Text>
                </Flex>
              ))}
              <Flex justifyContent="space-between">
                <Text className="text-grey-700">Total Estimated Costs</Text>
                <Text className="text-grey-900">{formatUsd(totalFees)}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export { QuoteOfferedTerms }
