import { useContext } from 'react'
import { SwitcherContext } from 'borrower/components/Switcher'
import { pathTo } from 'borrower/path-to'
import { IconName } from 'components/Icon'
import SideNavigationLink from 'components/SideNavigation/SideNavigationLink'
import SideNavigationSettingLink from 'components/SideNavigation/SideNavigationSettingLink'
import SideNavigationSettingsBlock from 'components/SideNavigation/SideNavigationSettingsBlock'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import { SideNavigationContainer } from './SideNavigationContainer'
import styles from './styles.module.scss'

interface Props {
  collapsed?: boolean
  isMobileNavVisible: boolean
  setIsMobileNavVisible: (visible: boolean) => void
}

function SideNavigation({
  collapsed,
  isMobileNavVisible,
  setIsMobileNavVisible,
}: Props) {
  const { isTablet } = useMobile()
  const { open: isBorrowerSelectionOpen } = useContext(SwitcherContext)
  const { borrowerAccounts, user } = useSession()
  const isSubheaderVisible = user?.actor?.isBorrower && user?.actor?.isInvestor
  const isCollapsed = !isMobileNavVisible && collapsed

  const isTopNav = !(user?.actor?.isBorrower && user?.actor?.isInvestor)

  return (
    <SideNavigationContainer
      borrowerAccounts={borrowerAccounts}
      actor={user?.actor}
      person={user?.person}
      pathTo={pathTo}
      setIsMobileNavVisible={setIsMobileNavVisible}
      collapsed={isCollapsed}
    >
      <div>
        <SideNavigationLink
          to={pathTo('dashboard')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Dashboard"
          icon={IconName.dashboard}
          collapsed={isCollapsed}
          hideActive={isBorrowerSelectionOpen}
        />
        {user?.actor?.isBorrower && (
          <>
            {!isCollapsed && isSubheaderVisible && (
              <div className={styles.subHeader}>BORROWER</div>
            )}
            <SideNavigationLink
              to={pathTo('applications')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Applications"
              icon={IconName.borrower}
              collapsed={isCollapsed}
              hideActive={isBorrowerSelectionOpen}
            />
            <SideNavigationLink
              to={pathTo('loans')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Loans"
              icon={IconName.loan}
              collapsed={isCollapsed}
              hideActive={isBorrowerSelectionOpen}
            />
          </>
        )}
        {user?.actor?.isInvestor && (
          <>
            {!isCollapsed && isSubheaderVisible && (
              <div className={styles.subHeader}>INVESTOR</div>
            )}
            <SideNavigationLink
              to={pathTo('transactions')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Transactions"
              icon={IconName.documentDollar}
              collapsed={isCollapsed}
              hideActive={isBorrowerSelectionOpen}
            />
            <SideNavigationLink
              to={pathTo('offerings')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Offerings"
              icon={IconName.offering}
              collapsed={isCollapsed}
              hideActive={isBorrowerSelectionOpen}
            />
            <SideNavigationLink
              to={pathTo('documents')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Documents"
              icon={IconName.documentBlank}
              collapsed={isCollapsed}
              hideActive={isBorrowerSelectionOpen}
            />
          </>
        )}
      </div>
      {(isTablet || !isTopNav) && (
        <div>
          {!isCollapsed && (
            <SideNavigationSettingsBlock>
              <SideNavigationSettingLink
                to={pathTo('settings')}
                onClick={() => setIsMobileNavVisible(false)}
                label="Account Settings"
                hideActive={isBorrowerSelectionOpen}
              />
            </SideNavigationSettingsBlock>
          )}
          <SideNavigationLink
            to={pathTo('logout')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Log out"
            icon={IconName.logout}
            collapsed={isCollapsed}
          />
        </div>
      )}
    </SideNavigationContainer>
  )
}

export default SideNavigation
