const GUEST_APPLICATION_KEY = 'draft-guest-custom-application'
const GUEST_APPLICATION_DATE_KEY = 'draft-guest-custom-application-date'

const saveDraftGuestApplication = (application: any) => {
  localStorage.setItem(GUEST_APPLICATION_DATE_KEY, Date.now().toString())
  localStorage.setItem(GUEST_APPLICATION_KEY, JSON.stringify(application))
}

const readDraftGuestApplication = () => {
  const applicationDate = localStorage.getItem(GUEST_APPLICATION_DATE_KEY)
  if (applicationDate) {
    const diffHours = (Date.now() - Number(applicationDate)) / 1000 / 60 / 60
    if (diffHours >= 24) {
      deleteDraftGuestApplication()
    }
  }
  const application = localStorage.getItem(GUEST_APPLICATION_KEY)
  return application ? JSON.parse(application) : undefined
}

const deleteDraftGuestApplication = () => {
  localStorage.removeItem(GUEST_APPLICATION_DATE_KEY)
  localStorage.removeItem(GUEST_APPLICATION_KEY)
}

export {
  saveDraftGuestApplication,
  readDraftGuestApplication,
  deleteDraftGuestApplication,
}
