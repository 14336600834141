import {
  autoUpdate,
  flip,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  size,
} from '@floating-ui/react'
import clsx from 'clsx'
import {
  ReactNode,
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
} from 'react'
import { mergeRefs } from 'react-merge-refs'
import { useNavigate } from 'react-router-dom'
import { Avatar } from 'components/Avatar'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'

export const Badge = ({
  children,
  className,
  maxWidth,
  url,
  associatedList,
}: {
  children: ReactNode
  className?: string
  maxWidth?: number
  url?: string
  associatedList?: { id: string; name: string; url?: string }[]
}) => {
  const navigate = useNavigate()
  const [isTruncated, setIsTruncated] = useState(false)
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const tooltipContent = useMemo(() => {
    if (!url && !open) {
      return 'Show all'
    }
    if (isTruncated) {
      return children
    }
    return ''
  }, [url, isTruncated, open])

  const handleNavigate = useCallback(() => {
    if (url) {
      navigate(url)
    }
  }, [navigate, url])

  const handleOpenChange = useCallback((nextOpen: boolean) => {
    setOpen(nextOpen)
  }, [])

  const { x, y, strategy, context, refs } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement: 'top-start',
    middleware: [
      flip(),
      size({
        apply({ elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
          })
        },
      }),
    ],
  })

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'click' }),
      useDismiss(context),
      useRole(context, { role: 'tree' }),
    ]
  )

  useEffect(() => {
    setIsTruncated(
      !!ref.current && ref.current.scrollWidth > ref.current.clientWidth
    )
  }, [children])

  return (
    <>
      <div
        className={clsx(
          'bg-purple-50 text-purple-300 text-sm font-bold rounded-sm leading-5 px-1 truncate cursor-pointer hover:bg-purple-200 hover:text-white-100',
          open && 'bg-purple-100 text-white-100',
          className
        )}
        style={{ maxWidth }}
        {...getReferenceProps({
          ref: mergeRefs([ref, refs.setReference]),
          onClick(e) {
            handleNavigate()
            e.stopPropagation()
          },
        })}
      >
        <Tooltip content={tooltipContent} className="!z-10">
          <span>{children}</span>
        </Tooltip>
      </div>
      {open && (
        <FloatingPortal>
          <div
            className="bg-white-100 shadow-300 rounded w-100 py-1 overflow-hidden"
            onClick={(e) => e.stopPropagation()}
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y - 8,
                left: x - 12,
                zIndex: 5,
              },
            })}
          >
            <div className="py-2 px-2.5 text-grey-600 text-sm font-bold">
              Associated With
            </div>
            <div className="px-1 max-h-85 overflow-auto">
              {associatedList?.map((el) => (
                <Flex
                  key={el.id}
                  gap={10}
                  alignItems="center"
                  className="group p-2 cursor-pointer hover:bg-grey-75 hover:rounded"
                  {...getItemProps({
                    onClick: () => el.url && navigate(el.url),
                  })}
                >
                  <Avatar
                    id={el.id}
                    name={el.name}
                    className="!w-7 !h-7 !basis-7"
                  />
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    className="w-full py-[2px]"
                  >
                    <Flex stack gap={6}>
                      <div>{el.name}</div>
                    </Flex>
                    <Icon
                      name={IconName.arrowRight}
                      className="text-grey-600 invisible group-hover:visible"
                    />
                  </Flex>
                </Flex>
              ))}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
