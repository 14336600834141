import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'

export const EmptyView = () => (
  <Flex
    stack
    gap={0}
    alignItems="center"
    justifyContent="center"
    className="min-h-96"
  >
    <Flex
      className="p-4 rounded-xl bg-lime-25"
      alignItems="center"
      justifyContent="center"
    >
      <Icon name={IconName.task} className="w-12 h-12 text-lime-100" />
    </Flex>
    <div className="mt-8 text-2xl text-grey-900 font-bold">
      Create and assign tasks
    </div>
    <Text
      variant="l"
      className="max-w-[460px] mt-3 leading-[20px] text-grey-700 text-center"
    >
      Create tasks or add them from a template to help your team stay organized
      and on top of this loan.
    </Text>
  </Flex>
)
