import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getFundDocuments,
  addFundDocument,
} from 'admin/services/api/fund-documents'
import { KEY_DOCUMENTS } from 'constants/query-keys'
import {
  getDocumentUploadUrl,
  getDocumentVersion,
  uploadDocument,
} from 'services/api/document'
import { handleErrorResponse } from 'services/request'
import { LoanDocument } from 'types'
import { message } from 'utils/message'

const useFundDocuments = (
  { id }: { id: string },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_DOCUMENTS, 'fund', id],
    queryFn: () => getFundDocuments({ id }),
    ...options,
  })
}

const useAddFundDocument = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (document: Partial<LoanDocument>) =>
      addFundDocument(id, document),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS, 'fund', id] })
      message.success('Document created')
    },
    onError: handleErrorResponse,
  })
}

const useAddFundDocuments = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (files: FileList) => {
      return Promise.all(
        Array.from(files).map(async (file) => {
          const name = file.name.replace(/\.[^/.]+$/, '')
          const uploadToast = message.upload(name)
          uploadToast.show()
          const newDocument = await addFundDocument(id, { name })
          const { url } = await getDocumentUploadUrl({
            id: newDocument.id,
            filename: file.name,
            type: file.type,
          })
          const { headers } = await uploadDocument(
            url,
            file,
            (progressEvent) => {
              uploadToast.progress(progressEvent.loaded / progressEvent.total)
            }
          )
          uploadToast.processing()
          const versionId = headers['x-amz-version-id']
          const version = await getDocumentVersion(
            newDocument.id,
            versionId,
            true
          )
          uploadToast.complete()
          return version
        })
      )
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS, 'fund', id] })
      message.success('Documents added')
    },
    onError: handleErrorResponse,
  })
}

export { useFundDocuments, useAddFundDocument, useAddFundDocuments }
