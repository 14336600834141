import { Formik } from 'formik'
import { useState } from 'react'
import * as yup from 'yup'
import { Button } from 'components/Button'
import { Form, Select, FieldIcon } from 'components/Form'
import { Grid } from 'components/Grid'
import { Text } from 'components/Text'
import { Class } from 'types'
import { formatUsd } from 'utils/currency'
import { formatPercent } from 'utils/percent'
import { createScheme, required } from 'utils/schemas'
import styles from './styles.module.scss'

type InvestmentAmountValues = {
  investmentClassId: string
  amount: string
}

interface Props {
  initialValues: InvestmentAmountValues
  investmentClass: Class[]
  cashBalance: number
  onCancel: () => void
  onSubmit: (values: InvestmentAmountValues) => void
}

const amountComparison = (amountRemaining: number) =>
  yup
    .number()
    .required('The field is required')
    .moreThan(0, `The amount must be more than 0`)
    .max(
      amountRemaining,
      'The amount must be less than what remains in the class'
    )

const InvestmentAmountForm = ({
  initialValues,
  investmentClass,
  cashBalance,
  onCancel,
  onSubmit,
}: Props) => {
  const [idInvestmentClass, setIdInvestmentClass] = useState<string>(
    initialValues.investmentClassId
  )
  const FormSchema = createScheme({
    investmentClassId: required,
    ...(idInvestmentClass
      ? {
          amount: amountComparison(
            (investmentClass.find((el) => el.id === idInvestmentClass) as Class)
              ?.amountRemaining
          ),
        }
      : {}),
  })

  const label = (name: string, rate?: number) => (
    <>
      <span className="text-grey-900 font-bold">{name}</span>
      {rate && (
        <span className="text-grey-600 pl-2">
          {formatPercent(rate, { decimals: 2 })}
        </span>
      )}
    </>
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={FormSchema}
      onSubmit={onSubmit}
    >
      <Form modal>
        <Grid className={styles.form} gap={16}>
          <Grid.Item xs={12} sm={6}>
            <Select
              name="investmentClassId"
              label="Investment Class"
              portal
              fieldClassName={styles.select}
              onChange={(e) => setIdInvestmentClass(e.value as string)}
              options={investmentClass.map(({ id, name, rate }) => ({
                value: id,
                label: label(name, rate),
              }))}
            />
          </Grid.Item>
          <Grid.Item xs={12} sm={6}>
            <FieldIcon
              fieldClassName={styles.currency}
              type="currency"
              name="amount"
              label="Amount"
            />
            <Text className={styles.balance} variant="s">
              {`Account Balance: ${formatUsd(cashBalance, { showZero: true })}`}
            </Text>
          </Grid.Item>
          <Grid.Item xs={12} className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Next</Button>
          </Grid.Item>
        </Grid>
      </Form>
    </Formik>
  )
}

export type { InvestmentAmountValues }
export default InvestmentAmountForm
