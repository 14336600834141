import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Field, Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { createScheme, email, mergeRules, required } from 'utils/schemas'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
  email: mergeRules(email, required),
  role: required,
})

type FormValues = {
  name: string
  email: string
  role: string
}

interface Props {
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

function ModalAddMember({ saving, onSubmit, onCancel }: Props) {
  const initialValue: FormValues = {
    name: '',
    email: '',
    role: 'viewer',
  }

  return (
    <Modal title="Add New Member" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="name"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                type="email"
                label="Email"
                name="email"
                placeholder="Enter email"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Select
                name="role"
                label="Role"
                options={[
                  { value: 'manager', label: 'Manager' },
                  { value: 'viewer', label: 'Viewer' },
                ]}
                portal
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalAddMember
