import { useState, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useLoanEmails } from 'admin/hooks/use-loan-emails'
import { TableLoanEmails } from 'admin/pages/Loan/TableLoanEmails'
import {
  Filter,
  filterValueToTableFilter,
  filterValueToUrl,
  IFilterConfig,
  IFilterValue,
  urlToFilterValue,
} from 'components/Filter'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import { Loan, SentEmail } from 'types'
import { ModalLoanSentEmail } from './ModalSentEmail'

interface Props {
  loan: Loan
}

const filterConfig: IFilterConfig[] = [
  {
    id: 'date',
    type: 'date',
    label: 'Date',
  },
]

function TabEmailLog({ loan }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [infoSentEmail, setInfoSentEmail] = useState<SentEmail>()

  const filtersValue = useMemo(
    () =>
      searchParams.get('filter')
        ? urlToFilterValue(searchParams.get('filter') as string)
        : [],
    [searchParams]
  )

  const { visibleItems, result, setPagination } = usePagination<SentEmail>({
    property: 'emails',
    useData: (params) =>
      useLoanEmails({
        ...params,
        filter: filterValueToTableFilter(filtersValue),
        id: loan.id,
      }),
  })

  const handleFilterChange = (value: IFilterValue) => {
    setSearchParams({ filter: filterValueToUrl(value) }, { replace: true })
  }

  return (
    <div>
      <Panel
        title="Email Log"
        actionChildren={
          <>
            <Filter
              config={filterConfig}
              value={filtersValue}
              onApply={handleFilterChange}
            />
          </>
        }
      >
        <TableLoanEmails
          data={visibleItems}
          loading={result.isPending}
          hasFilterUrl={!!filtersValue.length}
          onRowClick={(sentEmail) => setInfoSentEmail(sentEmail)}
        />
        <LoadMore
          loading={result.isPending}
          fetching={result.isFetching}
          count={visibleItems.length}
          meta={result.data?.meta}
          onLoadMore={setPagination}
        />
        {infoSentEmail && (
          <ModalLoanSentEmail
            sentEmail={infoSentEmail}
            onCancel={() => setInfoSentEmail(undefined)}
          />
        )}
      </Panel>
    </div>
  )
}

export default TabEmailLog
