import {
  RowData,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFacetedUniqueValues,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { Icon, IconName } from 'components/Icon'
import Cell from './Cell'
import Head from './Head'
import styles from './styles.module.scss'

function EditableTable<T extends RowData>({
  loading,
  className,
  minWidth = 0,
  data = [],
  columns,
}: {
  loading?: boolean
  className?: string
  minWidth?: number
  columns: Array<ColumnDef<T>>
  data?: Array<T>
}) {
  const table = useReactTable({
    data,
    columns: columns.map(
      (column) =>
        ({
          size: 'auto',
          ...column,
        }) as ColumnDef<T>
    ),
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <div className={styles.tableWrapper}>
      <table className={clsx(styles.table, className)} style={{ minWidth }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    className={styles.th}
                    style={header.getSize() ? { width: header.getSize() } : {}}
                  >
                    <EditableTable.Head {...header.getContext()}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, {
                            ...header.getContext(),
                          })}
                    </EditableTable.Head>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody onDragLeave={(e) => e.stopPropagation()}>
          {!loading && table.getRowModel().rows.length === 0 && (
            <tr>
              <td colSpan={columns.length}>
                <div className={styles.tableBodyContent}>
                  None have been created yet
                </div>
              </td>
            </tr>
          )}
          {loading && table.getRowModel().rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>
                <div className={styles.tableBodyContent}>
                  <Icon name={IconName.loaderSpinner} className="spinner" />
                  Loading...
                </div>
              </td>
            </tr>
          ) : (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className={clsx(styles.td)}>
                    <EditableTable.Cell {...cell.getContext()}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </EditableTable.Cell>
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  )
}

EditableTable.Head = Head
EditableTable.Cell = Cell

export { EditableTable }
