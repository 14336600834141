import clsx from 'clsx'
import { Formik } from 'formik'
import { isEqual, isUndefined } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Success } from 'borrower/components/DraftApplication/Success'
import {
  schemeToValues,
  schemeToValidationSchema,
} from 'borrower/components/DraftApplication/helpers'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Form } from 'components/Form'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import {
  useAddDraftApplication,
  useSignGuestApplication,
} from 'hooks/use-application'
import {
  saveDraftGuestApplication,
  deleteDraftGuestApplication,
} from 'services/storage'
import { ApplicationScheme } from 'types'
import { ApplicationField } from './ApplicationField'
import styles from './styles.module.scss'

interface Props {
  clientId: string
  scheme: ApplicationScheme
}

function GuestApplication({ clientId, scheme: initialScheme }: Props) {
  const navigate = useNavigate()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [scheme, setScheme] = useState<ApplicationScheme>(initialScheme)
  const defaultTagIndex = useMemo(
    () =>
      Math.max(
        0,
        ...scheme.pages
          .map(({ complete }, index) => ({ index, complete }))
          .filter(({ complete }) => complete)
          .map(({ index }) => index)
      ),
    [scheme]
  )
  const tags = useMemo(() => scheme.pages.map(({ tag }) => tag), [scheme])
  const [currentTagIndex, setCurrentTagIndex] =
    useState<number>(defaultTagIndex)
  const { mutate: save } = useAddDraftApplication(clientId, true)
  const { mutate: submit } = useSignGuestApplication(clientId)
  const maxAvailableTagIndex = useMemo(
    () =>
      Math.max(
        Number(currentTagIndex),
        ...scheme.pages
          .map(({ complete }, index) => ({ index, complete }))
          .filter(({ complete }) => complete)
          .map(({ index }) => index)
      ),
    [currentTagIndex, scheme.pages]
  )
  const page = useMemo(
    () => scheme.pages[currentTagIndex],
    [scheme, currentTagIndex]
  )
  const goToPage = useCallback(
    (pageIndex: number) => {
      if (isUndefined(scheme.pages[pageIndex])) {
        setIsSubmitting(true)
        save(
          { ...scheme, submitted: true },
          {
            onSuccess: ({ id }) => {
              submit(
                { applicationId: id },
                {
                  onSettled: () => {
                    setIsSubmitting(false)
                  },
                  onSuccess: () => {
                    deleteDraftGuestApplication()
                    setIsSubmitted(true)
                  },
                }
              )
            },
          }
        )
      } else {
        setCurrentTagIndex(pageIndex)
      }
    },
    [save, scheme, currentTagIndex]
  )
  const goToNextPage = useCallback(() => {
    goToPage(currentTagIndex + 1)
  }, [goToPage, currentTagIndex])
  const handleBack = useCallback(() => {
    goToPage(currentTagIndex - 1)
  }, [goToPage, currentTagIndex])
  const initialValues = useMemo(
    () => schemeToValues(scheme, currentTagIndex),
    [currentTagIndex]
  )
  const Scheme = useMemo(
    () => schemeToValidationSchema(scheme, currentTagIndex),
    [currentTagIndex]
  )
  const updateScheme = useCallback(
    (values, complete = false) => {
      const nextScheme = {
        ...scheme,
        pages: scheme.pages.map((page, index) => ({
          ...page,
          complete: index === currentTagIndex ? complete : page.complete,
          fields: page.fields.map((field) => ({
            ...field,
            ...(field.type === 'signature' &&
            !isUndefined(values[`${field.id}_checkbox`])
              ? {
                  signatureConfirmationCheckbox: values[`${field.id}_checkbox`]
                    ? new Date().toISOString()
                    : '',
                }
              : {}),
            value: isUndefined(values[field.id])
              ? field.value
              : values[field.id],
          })),
        })),
      }
      if (!isEqual(nextScheme, scheme)) {
        setScheme(nextScheme)
        saveDraftGuestApplication(nextScheme)
      }
    },
    [scheme]
  )

  return isSubmitted ? (
    <Success>
      <Button onClick={() => navigate(pathTo('signIn'))}>Back to Login</Button>
    </Success>
  ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={Scheme}
      enableReinitialize
      onSubmit={(values) => {
        updateScheme(values, true)
        goToNextPage()
      }}
    >
      <Form onChange={updateScheme}>
        <Flex stack gap={32} className={styles.panel}>
          <Flex stack gap={16}>
            <Flex>
              <div className={styles.steps}>
                Step {currentTagIndex + 1}/{tags.length}
              </div>
              <EllipsesActions
                placement="bottom-start"
                trigger={
                  <Flex className={styles.step} alignItems="center" gap={4}>
                    {page.tag} <Icon name={IconName.arrowDown} />
                  </Flex>
                }
              >
                {tags.map((tag, index) => (
                  <EllipsesActions.Item
                    key={tag + index}
                    disabled={index > maxAvailableTagIndex}
                    onSelect={() => goToPage(index)}
                  >
                    {tag}
                  </EllipsesActions.Item>
                ))}
              </EllipsesActions>
            </Flex>
            <Header>{page.header}</Header>
            {page.description && (
              <Text className="text-grey-600">{page.description}</Text>
            )}
          </Flex>
          <div className={styles.labels}>
            {page.fields
              .filter(({ enabled }) => enabled)
              .map((field) => (
                <ApplicationField key={field.id} field={field} />
              ))}
          </div>
          <div className={styles.controls}>
            <div />
            <div className={styles.buttons}>
              {currentTagIndex !== 0 && (
                <Button
                  variant="tertiary"
                  className={clsx('h-11 w-11 rounded-full text-xl')}
                  onClick={handleBack}
                >
                  ←
                </Button>
              )}
              <Button
                type="submit"
                loading={isSubmitting}
                className={clsx('h-11 rounded-full px-6 text-xl shadow-0')}
              >
                {currentTagIndex + 1 === tags.length ? 'Submit' : 'Continue →'}
              </Button>
            </div>
          </div>
        </Flex>
      </Form>
    </Formik>
  )
}

export { GuestApplication }
