import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  className?: string
}

const Footer = ({ children, className }: Props) => {
  return <div className={clsx(styles.footer, className)}>{children}</div>
}

export { Footer }
