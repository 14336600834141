import { omit } from 'lodash'
import { Field, PersonField } from 'types'
import { updateRequestValue, updateResponseValue } from 'utils/api/field-parser'
import { orderFields } from 'utils/fields'

const updateResponseFields = (fields: PersonField[]): Field[] => {
  return orderFields(
    fields.map(
      ({ personProperty, ...rest }) =>
        ({
          ...rest,
          property: {
            ...personProperty,
            value: updateResponseValue(personProperty.value, rest.type),
          },
        }) as Field
    )
  )
}

const updateRequestFields = (fields: Field[]): PersonField[] => {
  return fields.map(({ property, ...field }) => ({
    ...omit(field, ['sectionServicing', 'sectionOrigination']),
    personProperty: {
      ...property,
      value: updateRequestValue(property.value, field.type),
    },
  }))
}

export { updateResponseFields, updateRequestFields }
