import { compact } from 'lodash'
import { useMemo } from 'react'
import { useFundIncome } from 'admin/hooks/use-fund-distribution'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Text } from 'components/Text'
import { Fund, FundDistribution } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'

interface Props {
  fund: Fund
  distribution: FundDistribution
  cashBalances: { cash: string; availableCash: string }
}

function Kpis({ fund, distribution, cashBalances }: Props) {
  const { data: fundIncome } = useFundIncome({
    fundId: fund?.id,
    options: {
      enabled:
        !!fund?.id && fund?.distributionCalculation === 'Simple Interest',
    },
    params: {
      dateStart: distribution.periodStart,
      dateEnd: distribution.periodEnd,
    },
  })

  const kpis = useMemo(() => {
    const income = sumDecimal(
      distribution?.investors.map(({ amount }) => amount)
    )
    const capitalReturn = sumDecimal(
      distribution?.investors.map(({ capitalReturn }) => capitalReturn)
    )

    return compact([
      {
        name: 'Fund Balance',
        value: formatUsd(fund?.fundBalance || cashBalances?.cash || 0),
      },
      {
        name: 'Available Balance',
        value: formatUsd(cashBalances?.availableCash || 0),
      },
      fund?.distributionCalculation === 'Simple Interest'
        ? {
            name: 'Fund Income (for Period)',
            value: formatUsd(fundIncome?.report),
          }
        : null,
      {
        name: 'Distribution Total',
        value: formatUsd(
          sumDecimal([capitalReturn, income, distribution?.chargesAmount || 0])
        ),
      },
      {
        name: 'Returned Capital',
        value: formatUsd(capitalReturn),
      },
      {
        name: 'Income',
        value: formatUsd(income),
      },
      {
        name: 'DRIP',
        value: formatUsd(
          sumDecimal(
            distribution?.investors.map(({ reinvested }) => reinvested)
          )
        ),
      },
    ])
  }, [
    fund?.fundBalance,
    distribution?.chargesAmount,
    distribution?.investors,
    fundIncome?.report,
  ])

  return (
    <Panel className="my-6 p-1">
      <Flex gap={0} className="flex-wrap lg:flex-nowrap">
        {kpis.map(({ name, value }) => (
          <Flex
            key={name}
            stack
            gap={6}
            className="py-3 px-4 basis-full sm:basis-6/12 md:basis-3/12 lg:basis-1/5"
          >
            <Text variant="s" className="text-grey-600">
              {name}
            </Text>
            <Header variant="h4" className="truncate text-grey-900">
              {value}
            </Header>
          </Flex>
        ))}
      </Flex>
    </Panel>
  )
}

export { Kpis }
