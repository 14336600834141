import { ColumnDef } from '@tanstack/react-table'
import { get, isNil } from 'lodash'
import { Borrower } from 'admin/services/api/borrowers'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

type Props = {
  data?: Loan[]
  loading: boolean
  statusFilter: string
  onClick: (item?: Loan) => void
}

function LoansReportTable({
  data = [],
  loading,
  statusFilter,
  onClick,
}: Props) {
  const columns: ColumnDef<Loan>[] = [
    {
      header: 'Name',
      accessorKey: 'name',
    },
    {
      header: '',
      id: 'note',
      size: 32,
      cell: ({ row }) => {
        return row.original.meta?.note ? (
          <Tooltip content={row.original.meta?.note}>
            <Icon name={IconName.note} className="text-grey-600" />
          </Tooltip>
        ) : null
      },
    },
    {
      header: 'Borrower',
      accessorKey: 'borrowers',
      cell: ({ row }) =>
        get(row.original, 'borrowers', [])
          .map((borrower: Borrower) => borrower.name)
          .join(', '),
    },
    {
      header: 'Product',
      accessorKey: 'product.name',
    },
    {
      header: 'Amount',
      accessorKey: 'amount',
      cell: ({ getValue }) => {
        const amount = getValue() as number | null
        return isNil(amount) ? '-' : formatUsd(amount)
      },
    },
    {
      header: 'Closing Date',
      accessorKey: 'dateClosing',
      cell: ({ getValue }) => friendlyDate(getValue() as string),
    },
    ...(statusFilter === 'liquidated'
      ? [
          {
            header: 'Liquidated',
            accessorKey: 'datePaidoff',
            cell: ({ getValue }) => friendlyDate(getValue() as string),
          },
        ]
      : []),
  ]

  return (
    <Table columns={columns} data={data} loading={loading} onClick={onClick} />
  )
}

export default LoansReportTable
