import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
  QueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  addInvestor,
  deleteInvestor,
  getInvestor,
  getInvestors,
  linkAccount,
  unlinkAccount,
  updateLinkAccount,
  updateOwners,
} from 'admin/services/api/investors'
import { KEY_INVESTORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort, Search } from 'types/table'
import { message } from 'utils/message'

const fetchInvestors = (
  queryClient: QueryClient,
  {
    search,
    filter,
    pagination,
    details,
  }: {
    search?: Search
    filter?: Filter
    pagination?: Pagination
    details?: boolean
  } = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return queryClient.fetchQuery({
    queryKey: [KEY_INVESTORS, search, nextFilter, pagination],
    queryFn: () =>
      getInvestors({ search, filter: nextFilter, page: pagination, details }),
  })
}

const useInvestors = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details,
}: {
  search?: Search
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_INVESTORS, search, nextFilter, pagination, sort, details],
    queryFn: () =>
      getInvestors({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
  })
}

const useInvestor = (
  {
    id,
    params,
  }: {
    id: string
    params?: { secure?: boolean }
  },
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_INVESTORS, id, params?.secure],
    queryFn: () => getInvestor(id, params),
    ...options,
  })
}

const useAddInvestor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addInvestor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS] })
      message.success('Investor saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteInvestor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteInvestor(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: [KEY_INVESTORS],
          exact: true,
        })
        message.success('Investor deleted')
      }, 50)
    },
    onError: handleErrorResponse,
  })
}

const useInvestorLinkAccount = ({ investorId }: { investorId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: linkAccount,
    onSuccess: (investor) => {
      if (investorId === investor.id) {
        queryClient.setQueryData([KEY_INVESTORS, investor.id], investor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investorId] })
      message.success('Investor saved')
    },
    onError: handleErrorResponse,
  })
}

const useInvestorUpdateLinkAccount = ({
  investorId,
}: {
  investorId: string
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateLinkAccount,
    onSuccess: (investor) => {
      if (investorId === investor.id) {
        queryClient.setQueryData([KEY_INVESTORS, investor.id], investor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investorId] })
      message.success('Investor saved')
    },
    onError: handleErrorResponse,
  })
}

const useInvestorUnlinkAccount = ({ investorId }: { investorId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: unlinkAccount,
    onSuccess: (investor) => {
      if (investorId === investor.id) {
        queryClient.setQueryData([KEY_INVESTORS, investor.id], investor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investorId] })
      message.success('Investor saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateInvestorOwners = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ id, owners }: { id: string; owners: string[] }) =>
      updateOwners({ id, owners }),
    onError: handleErrorResponse,
    onSuccess: (investor) => {
      queryClient.invalidateQueries({ queryKey: [KEY_INVESTORS, investor?.id] })
    },
  })
}

export {
  fetchInvestors,
  useInvestors,
  useAddInvestor,
  useInvestor,
  useDeleteInvestor,
  useInvestorLinkAccount,
  useInvestorUnlinkAccount,
  useInvestorUpdateLinkAccount,
  useUpdateInvestorOwners,
}
