import { Formik } from 'formik'
import { isArray } from 'lodash'
import { Vendor, roles } from 'admin/services/api/vendors'
import { Button } from 'components/Button'
import { Field, Form, Phone, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { parseInitValues } from 'utils/form'
import { createScheme, required } from 'utils/schemas'
interface Props {
  vendor: Vendor
  loading?: boolean
  saving?: boolean
  disabled?: (keyof Vendor)[]
  onSave: (vendor: Partial<Vendor>) => void
  onCancel: () => void
}

type FormValues = {
  name: string
  companyName: string
  phone: string | null
  role: string | undefined
}

function ModalInformation({
  vendor,
  loading,
  saving,
  disabled,
  onSave,
  onCancel,
}: Props) {
  const initialValue: FormValues = {
    name: vendor.name,
    companyName: vendor.companyName,
    phone: vendor.phone,
    role: vendor.role,
    ...parseInitValues(vendor || {}),
  }

  const filteredRoles =
    vendor?.role && isArray(vendor.role)
      ? roles.filter((role) => vendor.role?.includes(role))
      : roles

  const FormSchema = createScheme({
    role: required,
  })

  return (
    <Modal title="Edit Vendor" onClose={onCancel} loading={loading}>
      <Formik
        initialValues={initialValue}
        enableReinitialize
        onSubmit={onSave}
        validationSchema={FormSchema}
      >
        <Form>
          <div>
            <Grid columnGap={24}>
              <Grid.Item xs={6}>
                <Field
                  name="name"
                  label={
                    vendor.type === 'entity' ? 'Company name' : 'Full Name'
                  }
                  placeholder={
                    vendor.type === 'entity'
                      ? 'The Rock Lending Inc.'
                      : 'The Rock'
                  }
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Phone name="phone" label="Phone" />
              </Grid.Item>

              <Grid.Item xs={6}>
                <Select
                  name="role"
                  label="Role"
                  disabled={disabled?.includes('role')}
                  portal
                  options={filteredRoles.map((role) => ({
                    value: role,
                    label: role,
                  }))}
                />
              </Grid.Item>
            </Grid>
          </div>
          <Grid.Item xs={12} className="flex justify-end gap-4">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={saving} type="submit">
              Save
            </Button>
          </Grid.Item>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalInformation
