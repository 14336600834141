import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useReportDrawRequests } from 'admin/hooks/use-report'
import { pathTo } from 'admin/path-to'
import { downloadDrawRequests } from 'admin/services/csv/download-draw-requests'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { Panel } from 'components/Panel'
import { usePagination } from 'hooks/use-pagination'
import TableDrawRequests from './TableDrawRequests'

function DrawRequestsReport() {
  const {
    visibleItems,
    result,
    filter = {},
    sort,
    search,
    setSort,
    setPagination,
    setFilter,
  } = usePagination<any>({
    property: 'draws',
    useData: (tableProps) =>
      useReportDrawRequests('draw-requests', {
        ...tableProps,
      }),
  })

  const navigate = useNavigate()
  const { tab = 'review' } = useParams() as { id: string; tab: string }

  const handleFilterChange = useCallback(
    ({ status }) => {
      setFilter(status ? { status: [status] } : {})
    },
    [filter]
  )

  useEffect(() => {
    switch (tab) {
      case 'approved':
        handleFilterChange({ status: 'Approved' })
        break
      case 'released':
        handleFilterChange({ status: 'Released' })
        break
      case 'review':
        handleFilterChange({ status: 'In Review' })
        break
      default:
        handleFilterChange({ status: undefined })
    }
  }, [tab])

  const handleDownload = useCallback(
    () =>
      downloadDrawRequests({
        filter,
        sort,
        search,
      }),
    [filter, sort, search]
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          title="Draw Requests"
          breadcrumbs={{ title: 'Reports', link: pathTo('reports') }}
        ></PageTop>
        <Panel>
          <Flex
            gap={4}
            justifyContent="space-between"
            flexWrap="wrap"
            className="pb-4"
          >
            <Flex gap={8}>
              {[
                { id: 'review', title: 'In Review' },
                { id: 'approved', title: 'Approved' },
                { id: 'released', title: 'Released' },
              ].map(({ id, title }) => (
                <Button
                  key={id}
                  active={tab === id}
                  variant="panel"
                  onClick={() => navigate(pathTo('drawRequestsReportTab', id))}
                >
                  {title}
                </Button>
              ))}
            </Flex>
            <Flex gap={8}>
              <Download filename="draw-requests" download={handleDownload} />
            </Flex>
          </Flex>
          <TableDrawRequests
            data={visibleItems}
            loading={result.isPending}
            sort={sort}
            onSort={setSort}
          />
          <LoadMore
            loading={result.isPending}
            fetching={result.isFetching}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { DrawRequestsReport }
