import { Select } from 'components/Select'
import { useLoans } from 'hooks/use-loans'

interface Props {
  loanId: string | undefined
  onChange: ({ loanId }: { loanId: string }) => void
}
function LoanSelect({ loanId, onChange }: Props) {
  const { data: loans, isPending } = useLoans({
    filter: {
      status: ['lead', 'processing', 'underwriting', 'approved', 'servicing'],
    },
    sort: ['name', 'ASC'],
  })

  return (
    <Select
      portal
      value={loanId}
      options={
        loans?.loans?.map(({ id, name }) => ({
          value: id,
          label: name,
        })) || []
      }
      onChange={({ value }) => onChange({ loanId: value as string })}
      classNames={{
        control: (state) =>
          `${state.isFocused ? '' : '[&]:!border-transparent'} -ml-3 group !cursor-pointer`,
        dropdownIndicator: (state) => {
          return state.selectProps.menuIsOpen
            ? 'visible'
            : 'invisible group-hover:visible'
        },
        loadingIndicator: () => 'absolute left-1.5',
      }}
      isLoading={isPending}
      placeholder={isPending ? '' : 'Add a loan'}
    />
  )
}

export { LoanSelect }
