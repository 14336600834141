export type ReportCard = {
  id: string
  name: string
  description: string
}

export const reportsCards = [
  {
    id: 'payments',
    name: 'Payment History',
    description: 'All payments performed across all loans.',
  },
  {
    id: 'archived-loans',
    name: 'Archived Loans',
    description:
      'A summary of all archived loans. From these report you will be able to restore or access the details.',
  },
  {
    id: 'sold-loans',
    name: 'Sold Loans',
    description:
      'A summary of all sold loans. From these report you will be able to restore or access the details.',
  },
  {
    id: 'liquidated-loans',
    name: 'Liquidated Loans',
    description: 'A summary of all loans that have been liquidated.',
  },
  {
    id: 'servicing-loans',
    name: 'Sent to Servicing',
    description:
      'A summary of all loans that have been transferred to servicing. From these report you will be able to restore or access the details.',
  },
  {
    id: 'insurance',
    name: 'Insurance Expiration',
    description: 'A list of all insurance on loans in servicing.',
  },
  {
    id: 'trust-balance',
    name: 'Trust Balance',
    description: 'A list of all loans with a trust balance.',
  },
  {
    id: 'trust-transactions',
    name: 'Trust Transactions',
    description: 'All the movement of funds within a loan.',
  },
  {
    id: '1098',
    name: '1098 Tax Report',
    description: 'Borrower report of all interest paid in the tax year.',
  },
  {
    id: '1099',
    name: '1099 Tax Report',
    description: 'Investor report of all interest paid in the tax year.',
  },
  {
    id: 'loan-tape',
    name: 'Loan Tape',
    description: 'A list of all loans and their fields.',
  },
  {
    id: 'draw-requests',
    name: 'Draw Requests',
    description: 'A list of all draw requests on all loans.',
  },
  {
    id: 'paid-charges',
    name: 'Paid Charges',
    description: 'A list of all paid charges on all loans.',
  },
  {
    id: 'unpaid-charges',
    name: 'Unpaid Charges',
    description: 'A list of all unpaid charges on all loans.',
  },
  {
    id: 'unpaid-interest',
    name: 'Unpaid Interest',
    description: 'A list of all unpaid interests on all loans.',
  },
]
