import Papa from 'papaparse'
import { requestWithoutToken } from 'services/request'

const parseCsv = (
  csvString: string
): { headers: string[]; rows: Record<string, number | string>[] } => {
  const json = Papa.parse(csvString, {
    dynamicTyping: true,
    skipEmptyLines: true,
  })

  if (!json?.data || !json.data.length) {
    return {
      headers: [],
      rows: [],
    }
  }

  const [headers, ...rows] = json.data

  return {
    headers: headers as string[],
    rows: (rows as (number | string)[][]).map((row) =>
      Object.fromEntries(row.map((v, i) => [(headers as string[])[i], v]))
    ),
  }
}

const parseCsvFromUrl = async (url: string) => {
  const { data: csvString } = await requestWithoutToken.get(url)

  return parseCsv(csvString)
}

export { parseCsv, parseCsvFromUrl }
