import clsx from 'clsx'
import { Flex } from 'components/Flex'
import Field, { CustomFieldProps, FieldChildProps } from 'components/Form/Field'
import { Radio } from 'components/Radio'
import { Text } from 'components/Text'

export const YesNoField = ({
  name,
  label,
  bottomHint,
  disabled,
}: CustomFieldProps) => {
  return (
    <div className="py-4 border-0 border-b-[1px] border-solid border-grey-200">
      <Field name={name} bottomHint={bottomHint} fieldClassName="m-0">
        {({ form, meta: { touched, error }, field }: FieldChildProps) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            gap={10}
            className="text-grey-900 leading-5"
          >
            <Text
              className={clsx({
                'text-red-100': touched && error,
              })}
            >
              {label}
            </Text>
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Radio
                  name={field.name}
                  defaultValue="Yes"
                  checked={field.value === 'Yes'}
                  disabled={disabled}
                  label="Yes"
                  onChange={(e) =>
                    form.setFieldValue(field.name, e.target.value)
                  }
                />
              </Flex>
              <Flex alignItems="center">
                <Radio
                  name={field.name}
                  defaultValue="No"
                  checked={field.value === 'No'}
                  disabled={disabled}
                  label="No"
                  onChange={(e) =>
                    form.setFieldValue(field.name, e.target.value)
                  }
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Field>
    </div>
  )
}
