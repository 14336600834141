import { omit } from 'lodash'
import { ProductField, Field } from 'types/field'
import { updateResponseValue, updateRequestValue } from './field-parser'

const updateResponseFields = (fields: ProductField[]): Field[] => {
  return fields.map(
    ({ default: _default, ...rest }) =>
      ({
        ...rest,
        property: {
          ..._default,
          value: updateResponseValue(_default.value, rest.type),
        },
      }) as Field
  )
}

const updateRequestFields = (fields: Field[]) => {
  return fields.map(({ property, ...field }) => ({
    ...omit(field, ['sectionServicing', 'sectionOrigination']),
    default: {
      ...property,
      value: updateRequestValue(property.value, field.type),
    },
  }))
}

export { updateResponseFields, updateRequestFields }
