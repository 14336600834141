import clsx from 'clsx'
import { isBefore } from 'date-fns'
import { Formik } from 'formik'
import { isEqual, toNumber, toString } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useDeleteFundDistribution,
  useFundDistribution,
  usePublishFundDistribution,
  useUpdateFundDistribution,
} from 'admin/hooks/use-fund-distribution'
import { useFundInvestors } from 'admin/hooks/use-fund-investors'
import { useFund } from 'admin/hooks/use-funds'
import { useReport } from 'admin/hooks/use-report'
import { ModalFunding } from 'admin/pages/Fund/ModalFunding'
import { pathTo } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Date, FieldIcon } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { useEffectOnceWhen } from 'hooks/use-hook-once'
import {
  Fund,
  FundDistribution as FundDistributionType,
  FundDistributionInvestment,
} from 'types'
import { createScheme, required } from 'utils/schemas'
import { ImportDistribution } from './ImportDistribution'
import { Kpis } from './Kpis'
import { TableInvestors } from './TableInvestors'
import styles from './styles.module.scss'

const Schema = createScheme({
  date: required,
})

function FundDistribution() {
  const navigate = useNavigate()
  const { id, distributionId } = useParams() as {
    id: string
    distributionId: string
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [isPublishModalVisible, setIsPublishModalVisible] = useState(false)
  const [investments, setInvestments] = useState<FundDistributionInvestment[]>(
    []
  )
  const [saveRequired, setSaveRequired] = useState(false)
  const [investmentsUpdateRequired, setInvestmentsUpdateRequired] =
    useState(false)
  const { data: fund } = useFund(id)
  const isSimpleInterest =
    fund?.fund?.distributionCalculation === 'Simple Interest'
  const { data: investors } = useFundInvestors(id)
  const { data: distribution, isPending } = useFundDistribution(
    id,
    distributionId
  )
  const { data: cashBalances } = useReport(
    `investor/${id}/cash-balances`
  ) as unknown as {
    data: { cash: string; availableCash: string }
  }

  const { mutate: updateDistribution, isPending: saving } =
    useUpdateFundDistribution(id, distributionId)
  const { mutate: publishDistribution, isPending: publishing } =
    usePublishFundDistribution(id, distributionId)
  const { mutate: deleteDistribution, isPending: deleting } =
    useDeleteFundDistribution(id)
  const handleFormChange = useCallback(
    ({
      date = distribution?.date,
      chargesAmount = distribution?.chargesAmount,
      periodStart = distribution?.periodStart,
      periodEnd = distribution?.periodEnd,
      isInvestmentsUpdateRequired = false,
    }) => {
      if (
        date &&
        (date !== distribution?.date ||
          chargesAmount !== distribution?.chargesAmount ||
          periodStart !== distribution.periodStart ||
          periodEnd !== distribution.periodEnd)
      ) {
        updateDistribution({
          date,
          chargesAmount,
          periodStart,
          periodEnd,
        })
        setSaveRequired(false)
        setInvestmentsUpdateRequired(isInvestmentsUpdateRequired)
      }
    },
    [
      distribution?.date,
      distribution?.chargesAmount,
      distribution?.periodStart,
      distribution?.periodEnd,
    ]
  )

  const handleTableChange = useCallback((data, save) => {
    setInvestments((investments) =>
      investments.map((investment) => ({
        ...investment,
        amount: investment.amount ?? '',
        reinvested: investment.reinvested ?? '',
        isReinvested: investment.isReinvested ?? false,
        capitalReturn: investment.capitalReturn ?? '',
        ...(investment.id === data.id ? data : {}),
      }))
    )
    setSaveRequired(save)
  }, [])

  const handleTableMasterChange = useCallback((checked) => {
    setInvestments((investments) =>
      investments.map((investment) => ({
        ...investment,
        amount: investment.amount ?? '',
        isReinvested: checked,
        capitalReturn: investment.capitalReturn ?? '',
      }))
    )
    setSaveRequired(true)
  }, [])

  const handleClose = useCallback(() => setIsModalVisible(false), [])
  const isPublished = distribution?.status === 'Published'

  // update investments if investor is added
  useEffect(() => {
    if (!isPending && investors && investors.length !== investments.length) {
      setInvestments([
        ...investors.map(({ name, fundInvestors, balance }) => {
          const investment = distribution?.investors.find(
            ({ id }) => id === fundInvestors.id
          )
          return {
            id: fundInvestors.id,
            name,
            class: fundInvestors.class,
            date: fundInvestors.dateInvested,
            balance,
            amount: toString(investment?.amount || ''),
            reinvested: toString(investment?.reinvested || ''),
            isReinvested: investment?.isReinvested || false,
            capitalReturn: toString(investment?.capitalReturn || ''),
          }
        }),
      ])
    }
  }, [isPending, investors, investments, distribution?.investors])

  // initial on load
  useEffectOnceWhen(
    () => {
      setTimeout(() => {
        const input = document.querySelector(
          'table input[type="text"]'
        ) as HTMLInputElement
        input?.focus()
      }, 1)
    },
    [],
    () => !isPending && !!investors && !investments.length
  )

  // save on change
  useEffect(() => {
    const savedInvestors =
      distribution?.investors.map(
        ({ id, amount, isReinvested, capitalReturn }) => ({
          id,
          amount: amount ? toNumber(amount) : '',
          isReinvested: isReinvested || false,
          capitalReturn: capitalReturn ? toNumber(capitalReturn) : '',
        })
      ) || []
    const updatedInvestors = investments.map(
      ({ id, amount, isReinvested, capitalReturn }) => ({
        id,
        amount: amount ? toNumber(amount) : '',
        isReinvested: isReinvested || false,
        capitalReturn: capitalReturn ? toNumber(capitalReturn) : '',
      })
    )
    if (
      saveRequired &&
      !isPending &&
      !isEqual(savedInvestors, updatedInvestors) &&
      updatedInvestors.length
    ) {
      updateDistribution({
        investors: updatedInvestors as FundDistributionType['investors'],
      })
      setSaveRequired(false)
    }
  }, [isPending, distribution?.investors, investments, saveRequired])

  // update investments after changing period date
  useEffect(() => {
    if (investmentsUpdateRequired) {
      const updatedInvestments: FundDistributionInvestment[] = investments.map(
        (investment) => {
          const investor = distribution?.investors.find(
            ({ id }) => id === investment.id
          )
          return {
            ...investment,
            amount: toString(investor?.amount || ''),
            reinvested: toString(investor?.reinvested || ''),
            isReinvested: investor?.isReinvested || false,
            capitalReturn: toString(investor?.capitalReturn || ''),
          }
        }
      )
      setInvestments(updatedInvestments)
      setInvestmentsUpdateRequired(false)
    }
  }, [distribution])

  return (
    <MainContent className="relative px-0 pt-0 pb-4 md:pb-16">
      {isPending ? (
        <PageLoader />
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            className={styles.header}
          >
            <Breadcrumbs
              breadcrumbs={{
                title: 'Back',
                link: pathTo('fund', id, 'distributions'),
              }}
            />
            <Flex alignItems="center" gap={36}>
              <Flex alignItems="center" gap={8}>
                <div
                  className={clsx(styles.cloud, !saving && styles.checked)}
                />
                {saving ? 'Saving...' : 'Saved to cloud'}
              </Flex>
              {isPublished ? (
                <Button
                  color="negative"
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  Delete Record
                </Button>
              ) : (
                <Button
                  onClick={() => setIsPublishModalVisible(true)}
                  loading={publishing}
                >
                  Publish
                </Button>
              )}
            </Flex>
          </Flex>
          <div className="px-4 md:px-16">
            <Kpis
              fund={fund?.fund as Fund}
              distribution={distribution as FundDistributionType}
              cashBalances={cashBalances}
            />
            <Flex stack>
              <Panel title={!isSimpleInterest && 'Distribution Record'}>
                {!isPublished && !isSimpleInterest && (
                  <ImportDistribution
                    fundId={id}
                    distributionId={distributionId}
                    onImport={() => {
                      setSaveRequired(false)
                      setInvestments([])
                    }}
                  />
                )}
                <Formik
                  initialValues={{
                    periodStart: distribution?.periodStart,
                    periodEnd: distribution?.periodEnd,
                    date: distribution?.date,
                    chargesAmount: distribution?.chargesAmount,
                  }}
                  validationSchema={Schema}
                  enableReinitialize
                  onSubmit={() => {}}
                >
                  <Form>
                    <Grid
                      gap={16}
                      className={
                        isSimpleInterest
                          ? 'border-0 border-b border-solid border-grey-200'
                          : ''
                      }
                    >
                      {isSimpleInterest && distribution && (
                        <>
                          <Grid.Item xs={12} sm={6} md={3} lg={2}>
                            <Date
                              name="periodStart"
                              label="Period Start"
                              disabled={isPublished}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleFormChange({
                                  periodStart: e.target.value,
                                  periodEnd: isBefore(
                                    distribution.periodEnd || '',
                                    e.target.value
                                  )
                                    ? e.target.value
                                    : distribution.periodEnd,
                                  isInvestmentsUpdateRequired: true,
                                })
                              }
                            />
                          </Grid.Item>
                          <Grid.Item xs={12} sm={6} md={3} lg={2}>
                            <Date
                              name="periodEnd"
                              label="Period End"
                              disabled={isPublished}
                              minDate={distribution.periodStart || undefined}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ) =>
                                handleFormChange({
                                  periodEnd: e.target.value,
                                  isInvestmentsUpdateRequired: true,
                                })
                              }
                            />
                          </Grid.Item>
                        </>
                      )}
                      <Grid.Item xs={12} sm={6} md={3} lg={2}>
                        <Date
                          name="date"
                          label="Distribution Date"
                          disabled={isPublished}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleFormChange({ date: e.target.value })
                          }
                        />
                      </Grid.Item>
                      <Grid.Item xs={12} sm={6} md={3} lg={2}>
                        <FieldIcon
                          type="currency"
                          name="chargesAmount"
                          label="Total Charges"
                          disabled={isPublished}
                          onBlur={(e) =>
                            handleFormChange({ chargesAmount: e.target.value })
                          }
                        />
                      </Grid.Item>
                    </Grid>
                  </Form>
                </Formik>

                {isSimpleInterest && (
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    className="mt-8 mb-6"
                  >
                    <Header variant="h4" className="text-grey-900">
                      Enter drip and return of capital in the table below.
                    </Header>
                    {!isPublished ? (
                      <ImportDistribution
                        fundId={id}
                        distributionId={distributionId}
                        isSimpleInterest={true}
                        onImport={() => {
                          setSaveRequired(false)
                          setInvestments([])
                        }}
                      />
                    ) : (
                      <div />
                    )}
                  </Flex>
                )}

                <TableInvestors
                  data={investments}
                  onChange={handleTableChange}
                  onMasterChange={handleTableMasterChange}
                  disabled={isPublished}
                />

                {!isPublished && (
                  <TextLink
                    className={styles.addFunding}
                    onClick={() => setIsModalVisible(true)}
                  >
                    <Icon name={IconName.plus} size="sm" />
                    Add Funding
                  </TextLink>
                )}
              </Panel>

              <Flex justifyContent="space-between">
                <div />
                <Button
                  color="negative"
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  Delete Record
                </Button>
              </Flex>
            </Flex>
            {isModalVisible && (
              <ModalFunding fundId={id} onClose={handleClose} />
            )}
            {isDeleteModalVisible && (
              <ModalDelete
                resource="distribution"
                loading={deleting}
                onDelete={() =>
                  deleteDistribution(distributionId, {
                    onSuccess: () => {
                      setIsDeleteModalVisible(false)
                      navigate(pathTo('fund', id, 'distributions'))
                    },
                  })
                }
                onCancel={() => setIsDeleteModalVisible(false)}
              />
            )}
            {isPublishModalVisible && (
              <ModalConfirm
                title="Publish Record?"
                text="Publishing this record will allow investors in this fund to see their income distribution and any return of their capital in the investor portal."
                onConfirm={() => {
                  publishDistribution(undefined, {
                    onSuccess: () => {
                      navigate(pathTo('fund', id, 'distributions'))
                    },
                  })
                  setIsPublishModalVisible(false)
                }}
                onCancel={() => setIsPublishModalVisible(false)}
              />
            )}
          </div>
        </>
      )}
    </MainContent>
  )
}

export { FundDistribution }
