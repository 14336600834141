import clsx from 'clsx'
import { DragEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { DragDropFile } from 'components/DragDropFile'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { PanelRehabBudget } from 'components/Panel/PanelRehabBudget'
import { PanelScopeOfWorkStructuralChanges } from 'components/Panel/PanelScopeOfWorkStructuralChanges'
import { PanelLoanFields } from 'components/PanelLoanFields'
import { AddressSummary } from 'components/Summary'
import { TabNavigation } from 'components/TabNavigation'
import { useUploadLoanAddressRehabBudget } from 'hooks/use-loan-address'
import { useBudget } from 'hooks/use-loan-budget'
import { getLoanAddressRehabUrl } from 'services/api/loan-address'
import { Loan, Address, Application } from 'types'
import { openBrowseFile } from 'utils/file'
import { message } from 'utils/message'
import styles from './styles.module.scss'

interface Props {
  application: Application
}

function TabScopeOfWork({ application }: Props) {
  const navigate = useNavigate()
  const { entityId: addressId = application.addresses[0]?.id } = useParams()
  const [isDragActive, setIsDragActive] = useState(false)
  const address = application.addresses.find(
    ({ id }) => id === addressId
  ) as Address
  const { data: budget, isPending: budgetLoading } = useBudget(
    application.id,
    address.id
  )
  const { mutate: upload } = useUploadLoanAddressRehabBudget({
    loanId: application.id,
    addressId,
    uploadUrl: address.rehabUploadUrl,
  })
  const handleDownload = async () => {
    const downloadToast = message.progress('Download rehab template', 20000)
    const url = await getLoanAddressRehabUrl(application.id, addressId)
    downloadToast()
    window.location.href = url
  }
  const handleUpload = () => {
    openBrowseFile({
      onChoose: (files) => upload(files[0]),
      accept: '.xlsx',
    })
  }
  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true)
    } else if (e.type === 'dragleave') {
      setIsDragActive(false)
    }
  }
  const handleDrop = (files: FileList) => {
    setIsDragActive(false)
    upload(files[0])
  }

  return (
    <div className={styles.tabContent} onDragEnter={handleDrag}>
      <EllipsesActions
        trigger={
          <div className={clsx(styles.worksheetButton, styles.withNavigation)}>
            <Button
              className={styles.btnActions}
              iconRight={<Icon name={IconName.arrowDown} />}
            >
              Worksheet
            </Button>
          </div>
        }
      >
        <EllipsesActions.Item
          icon
          onSelect={() => handleUpload()}
          disabled={budgetLoading || budget?.status === 'Approved'}
        >
          <Icon name={IconName.upload} size="sm" />
          Upload
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          onSelect={() => handleDownload()}
          disabled={budgetLoading}
        >
          <Icon name={IconName.download} size="sm" />
          Download
        </EllipsesActions.Item>
      </EllipsesActions>

      {isDragActive && (
        <div className={styles.dropZone}>
          <DragDropFile
            onLeave={() => setIsDragActive(false)}
            onDrop={(files) => handleDrop(files)}
            accept={['xlsx']}
          />
        </div>
      )}

      <Flex stack gap={16}>
        {application.addresses.length > 1 ? (
          <TabNavigation>
            {application.addresses.map((address) => (
              <TabNavigation.Item
                key={address.id}
                label={address.street1}
                active={address.id === addressId}
                onClick={() =>
                  navigate(
                    pathTo(
                      'applicationSubmitted',
                      application.id,
                      'scopeOfWork',
                      address.id
                    )
                  )
                }
              />
            ))}
          </TabNavigation>
        ) : (
          <div />
        )}
        <div>
          <Grid gap={24}>
            <Grid.Item sm={6} className={styles.panels}>
              <PanelLoanFields
                title="Rehab Overview"
                sections={['Rehab Overview']}
                loan={application as unknown as Loan}
                address={address}
                includeAtTop={
                  <Grid.Item sm={6} key={address.id}>
                    <AddressSummary address={address} />
                  </Grid.Item>
                }
                className={styles.panel}
              />
            </Grid.Item>
            <Grid.Item sm={6} className={styles.panels}>
              <PanelScopeOfWorkStructuralChanges
                loanId={application.id}
                address={address}
                className={styles.panel}
              />
            </Grid.Item>
          </Grid>
          <PanelRehabBudget
            loan={application as unknown as Loan}
            address={address}
            borrower
          />
        </div>
      </Flex>
    </div>
  )
}

export { TabScopeOfWork }
