import { isString, isNil } from 'lodash'

interface Options {
  ignoreCents?: boolean
  showZero?: boolean
}

const formatUsd = (
  value: string | number | undefined | null,
  options: Options = {}
) => {
  const ignoreCents = options.ignoreCents || false
  const showZero = options.showZero || false
  const intl = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: ignoreCents ? 0 : 2,
  })
  if (!isNil(value) && value !== 0) {
    return intl.format(isString(value) ? parseFloat(value) : value)
  }
  return showZero ? '$0' : '-'
}

export { formatUsd }
