import {
  autoUpdate,
  FloatingPortal,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import clsx from 'clsx'
import { Fragment, useCallback, useState } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Task } from 'types'

const statuses: Task['status'][] = ['To Do', 'In Progress', 'Done', 'Waived']

interface Props {
  className?: string
  size?: 'medium' | 'large'
  status?: Task['status']
  onSelect: (status: Task['status']) => void
  onClick?: (event: any) => void
}

function StatusSelect({
  className,
  size = 'medium',
  status = 'To Do',
  onSelect,
  onClick,
}: Props) {
  const [open, setOpen] = useState(false)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [shift()],
  })
  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [useClick(context), useRole(context, { role: 'menu' }), useDismiss(context)]
  )

  const handleSelect = useCallback(
    (status: Task['status']) => {
      onSelect(status)
      setOpen(false)
    },
    [onSelect]
  )

  return (
    <div className={clsx('inline-block', className)} onClick={onClick}>
      <Flex
        className={clsx(
          'rounded pl-2 pr-0.5 py-1 cursor-pointer',
          size === 'large' && 'pl-3 pr-1.5 py-2',
          status === 'To Do' && 'bg-orange-25',
          status === 'In Progress' && 'bg-blue-25',
          status === 'Done' && 'bg-lime-25',
          status === 'Waived' && 'bg-grey-100'
        )}
        justifyContent="space-between"
        alignItems="center"
        gap={6}
        {...getReferenceProps({ ref: refs.setReference })}
      >
        <Flex alignItems="center" gap={6}>
          <div
            className={clsx(
              'w-2.5 h-2.5 rounded-[3px] flex-shrink-0',
              status === 'To Do' && 'bg-orange-100',
              status === 'In Progress' && 'bg-blue-100',
              status === 'Done' && 'bg-lime-100',
              status === 'Waived' && 'border-solid border-grey-400'
            )}
          />
          <div className="whitespace-nowrap font-bold">{status}</div>
        </Flex>
        <Icon name={IconName.arrowDownFilled} className="flex-shrink-0" />
      </Flex>
      {open && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
            className="z-2 border-solid border p-1 bg-white-100 rounded border-grey-100 cursor-pointer min-w-[173px] shadow-300"
          >
            {statuses.map((status) => {
              return (
                <Fragment key={status}>
                  {status === 'Waived' && (
                    <hr className="h-[1px] my-1.5 bg-grey-100 border-0" />
                  )}

                  <Flex
                    justifyContent="left"
                    alignItems="center"
                    gap={8}
                    className={clsx(
                      'px-2 py-2 rounded',
                      status === 'To Do' && 'hover:bg-orange-25',
                      status === 'In Progress' && 'hover:bg-blue-25',
                      status === 'Done' && 'hover:bg-lime-25',
                      status === 'Waived' && 'hover:bg-grey-100'
                    )}
                    {...getItemProps({
                      onClick: () => handleSelect(status as Task['status']),
                    })}
                  >
                    <div
                      className={clsx(
                        'w-2.5 h-2.5 rounded-[3px] flex-shrink-0',
                        status === 'To Do' && 'bg-orange-100',
                        status === 'In Progress' && 'bg-blue-100',
                        status === 'Done' && 'bg-lime-100',
                        status === 'Waived' && 'border-solid border-grey-400'
                      )}
                    />
                    <div className="whitespace-nowrap">{status}</div>
                  </Flex>
                </Fragment>
              )
            })}
          </div>
        </FloatingPortal>
      )}
    </div>
  )
}

export { StatusSelect }
