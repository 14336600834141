import { compact } from 'lodash'
import { getPayments, TO_BORROWER_PAYMENT_TYPE } from 'services/api/payment'
import { Filter, Row } from 'types'
import { sumDecimal } from 'utils/math'

interface Props {
  filter?: Filter
  search?: string
}
const downloadPayments = async ({ filter, search }: Props = {}): Promise<
  Row[]
> => {
  const { payments } = await getPayments({
    full: true,
    filter,
    search,
    page: {
      size: 1000,
      page: 0,
    },
  })
  return payments.map((payment) => ({
    Date: payment.date,
    'Loan ID': payment.loan?.number,
    Loan: payment.loan?.name,
    Type: payment.type,
    Status: payment.status,
    Collected: payment.isCollected ? 'Yes' : 'No',
    Principal: payment.distribution.principal,
    Interest: sumDecimal([
      payment.distribution.interest,
      payment.distribution.spread,
    ]),
    Charges: payment.distribution.charges,
    Trust:
      payment.type === TO_BORROWER_PAYMENT_TYPE
        ? payment.source.trusts * -1
        : payment.distribution.trusts,
    Borrower: payment.loan?.borrowers?.[0]?.name,
    Address: compact([
      payment.loan?.addresses?.[0]?.street1,
      payment.loan?.addresses?.[0]?.street2,
      payment.loan?.addresses?.[0]?.city,
      payment.loan?.addresses?.[0]?.state,
      payment.loan?.addresses?.[0]?.country,
      payment.loan?.addresses?.[0]?.zipcode,
    ]).join(', '),
  }))
}

export { downloadPayments }
