import clsx from 'clsx'
import { useContext, PropsWithChildren } from 'react'
import { LayoutContext } from 'components/layout/LayoutProvider'
import styles from './styles.module.scss'

interface Props extends PropsWithChildren {
  className?: string
}

function MainContent({ children, className }: Props) {
  const { isTopNavigation } = useContext(LayoutContext)

  return (
    <div
      className={clsx(
        styles.content,
        isTopNavigation && styles.topNavContainer,
        className
      )}
    >
      {children}
    </div>
  )
}

export { MainContent }
