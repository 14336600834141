import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  addVendor,
  getVendor,
  editVendor,
  getVendors,
  getVendorFilters,
  removeVendor,
  linkAccount,
  updateLinkAccount,
  unlinkAccount,
  addAddress,
  updateAddress,
} from 'admin/services/api/vendors'
import { KEY_VENDORS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Person } from 'types'
import { Search, Filter, Pagination, Sort } from 'types/table'
import { message } from 'utils/message'

const fetchVendors = (
  queryClient: QueryClient,
  {
    search,
    filter,
    pagination,
    details,
  }: {
    search?: Search
    filter?: Filter
    pagination?: Pagination
    details?: boolean
  } = {}
) => {
  const nextFilter = size(filter) ? filter : undefined
  return queryClient.fetchQuery({
    queryKey: [KEY_VENDORS, search, nextFilter, pagination],
    queryFn: () =>
      getVendors({ search, filter: nextFilter, page: pagination, details }),
  })
}

const useVendors = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details = false,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_VENDORS, search, nextFilter, pagination, sort, details],
    queryFn: () =>
      getVendors({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
  })
}

const useVendor = (id?: null | string) => {
  return useQuery({
    queryKey: [KEY_VENDORS, id],
    queryFn: () => getVendor(id as string),
    enabled: !!id,
  })
}

const useVendorFilters = (columns: string[]) => {
  return useQuery({
    queryKey: [KEY_VENDORS, 'filters'],
    queryFn: () => getVendorFilters(columns),
  })
}

const useAddVendor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addVendor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useEditVendor = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editVendor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useRemoveVendor = ({ exact }: { exact?: boolean }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeVendor,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS], exact })
      message.success('Vendor deleted')
    },
    onError: handleErrorResponse,
  })
}

const useVendorLinkAccount = ({ vendorId }: { vendorId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: linkAccount,
    onSuccess: (vendor: Person) => {
      if (vendorId === vendor.id) {
        queryClient.setQueryData([KEY_VENDORS, vendor.id], vendor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS, vendorId] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useVendorUpdateLinkAccount = ({ vendorId }: { vendorId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateLinkAccount,
    onSuccess: (vendor: Person) => {
      if (vendorId === vendor.id) {
        queryClient.setQueryData([KEY_VENDORS, vendor.id], vendor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS, vendorId] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useVendorUnlinkAccount = ({ vendorId }: { vendorId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: unlinkAccount,
    onSuccess: (vendor: Person) => {
      if (vendorId === vendor.id) {
        queryClient.setQueryData([KEY_VENDORS, vendor.id], vendor)
      }
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS, vendorId] })
      message.success('Vendor saved')
    },
    onError: handleErrorResponse,
  })
}

const useAddVendorAddress = (vendorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS, vendorId] })
    },
  })
}

const useUpdateVendorAddress = (vendorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateAddress,
    onError: handleErrorResponse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_VENDORS, vendorId] })
    },
  })
}

export {
  fetchVendors,
  useVendors,
  useVendor,
  useAddVendor,
  useEditVendor,
  useRemoveVendor,
  useVendorFilters,
  useVendorLinkAccount,
  useVendorUpdateLinkAccount,
  useVendorUnlinkAccount,
  useAddVendorAddress,
  useUpdateVendorAddress,
}
