import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Textarea } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from '../index'
import styles from './styles.module.scss'

type FormValues = {
  message: string
}

interface Props {
  saving: boolean
  onReject: ({ message }: { message: string }) => void
  onCancel: () => void
}

function ModalRejectBudgetItem({ saving, onReject, onCancel }: Props) {
  const initialValue: FormValues = {
    message: '',
  }

  return (
    <Modal
      title="Reason for Rejecting"
      onClose={onCancel}
      className={styles.modal}
    >
      <Formik initialValues={initialValue} onSubmit={onReject}>
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Textarea name="message" label="Reason:" autoFocus />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Reject
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalRejectBudgetItem }
