import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useEffect } from 'react'
import type { LexicalEditor } from 'lexical'

type Props = {
  onCreate: (editor: LexicalEditor) => void
}

function ContextPlugin({ onCreate }: Props): null {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    onCreate(editor)
  }, [editor, onCreate])

  return null
}

export { ContextPlugin }
