import { useQuery } from '@tanstack/react-query'
import { KEY_LOAN_PAYMENTS } from 'constants/query-keys'
import { getLoanPayments } from 'services/api/loan-payment'

const useLoanPayments = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_LOAN_PAYMENTS, loanId],
    queryFn: () => getLoanPayments(loanId),
  })
}

export { useLoanPayments }
