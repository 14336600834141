import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addDocumentComment,
  deleteDocument,
  deleteDocumentVersion,
  getDocumentComments,
  requestDocument,
  updateDocument,
  sendDocument,
  saveDocumentSigners,
  updateDocumentSignature,
} from 'admin/services/api/document'
import { KEY_DOCUMENT_COMMENTS, KEY_DOCUMENTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { LoanDocument } from 'types'
import { message } from 'utils/message'

const commentsPerPage = 25

const useUpdateDocument = ({ authToken }: { authToken?: string } = {}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (document: Partial<LoanDocument & { message?: string }>) =>
      updateDocument(document, { authToken }),
    onSuccess: (document) => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS], exact: true })
      queryClient.setQueryData([KEY_DOCUMENTS, document.id], document)
      message.success('Document updated')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteDocument = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteDocument({ id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS], exact: true })
      message.success('Document deleted')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteDocumentVersion = (documentId?: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (versionId: string) =>
      deleteDocumentVersion({ id: documentId as string, versionId }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_DOCUMENTS, documentId],
        exact: true,
      })
      message.success('Version deleted')
    },
    onError: handleErrorResponse,
  })
}

const useRequestDocument = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => requestDocument(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS] })
      message.success('Document requested')
    },
    onError: handleErrorResponse,
  })
}

const useDocumentComments = (
  {
    documentId,
    params = { page: 0, size: commentsPerPage },
  }: { documentId: string; params?: { page: number; size: number } },
  options: {
    enabled?: boolean
    refetchInterval?: number
  } = {}
) => {
  return useQuery({
    queryKey: [KEY_DOCUMENT_COMMENTS, documentId, params.page, params.size],
    queryFn: () => getDocumentComments(documentId, params),
    ...options,
  })
}

const useAddDocumentComment = (documentId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (text: string) => addDocumentComment(documentId, { text }),
    onSuccess: ({ comments, meta }) => {
      queryClient.setQueryData(
        [KEY_DOCUMENT_COMMENTS, documentId, meta.page, meta.pageSize],
        { comments, total: meta.total }
      )
    },
    onError: handleErrorResponse,
  })
}

const useSaveDocumentSigners = ({ documentId }: { documentId: string }) => {
  return useMutation({
    mutationFn: (data: { signers: { id: string }[]; loanId?: string }) =>
      saveDocumentSigners(documentId, data),
    onError: handleErrorResponse,
  })
}

const useUpdateDocumentSignature = ({
  authToken,
}: { authToken?: string } = {}) => {
  return useMutation({
    mutationFn: ({
      documentId,
      ...esignature
    }: { documentId: string } & Partial<LoanDocument['esignature']>) =>
      updateDocumentSignature(documentId, esignature, { authToken }),
    onError: handleErrorResponse,
  })
}

const useSendDocument = ({ documentId }: { documentId: string }) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: { subject: string; body: string }) =>
      sendDocument(documentId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_DOCUMENTS, documentId] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useUpdateDocument,
  useDeleteDocument,
  useDeleteDocumentVersion,
  useDocumentComments,
  useAddDocumentComment,
  useRequestDocument,
  useSaveDocumentSigners,
  useUpdateDocumentSignature,
  useSendDocument,
  commentsPerPage,
}
