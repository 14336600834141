import { Formik } from 'formik'
import { capitalize, omit } from 'lodash'
import { useState } from 'react'
import { BorrowerDetails } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import {
  Date,
  Field,
  FieldIcon,
  Form,
  Phone,
  Select,
  SocialSecurityNumber,
} from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import styles from 'components/Modal/AddEmail/styles.module.scss'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { useUpdateBorrower } from 'hooks/use-borrower'
import { useUpdateInvestor } from 'hooks/use-investor'
import { useSession } from 'hooks/use-session'
import { formatDate } from 'utils/date'
import { formatPhone } from 'utils/phone'
import { createScheme, required } from 'utils/schemas'

interface FormValues {
  name: string
  gender: string
  dateBirth: string
  citizenship: string
  creditScore: string
  maritalStatus: string
  phone: string
  socialSecurityNumber: string
  numFlipped?: string
}

interface Props {
  user: BorrowerDetails
  isLoading: boolean
  setSecure: () => void
}

interface ModalProps {
  initialValues: FormValues
  saving: boolean
  isLoading: boolean
  isBorrower: boolean
  onClose: () => void
  onSubmit: (values: FormValues) => void
}

function ModalEditPersonalInfo({
  onClose,
  onSubmit,
  isLoading,
  saving,
  initialValues,
  isBorrower,
}: ModalProps) {
  const updatedInitialValues = isBorrower
    ? initialValues
    : omit(initialValues, 'numFlipped')
  const Schema = createScheme({
    name: required,
  })

  return (
    <Modal
      title="Edit Personal Information"
      onClose={onClose}
      loading={isLoading}
    >
      <Formik
        initialValues={updatedInitialValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                name="name"
                label="Name"
                placeholder="Full Name"
                required
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="gender"
                label="Gender"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                  { label: 'Other', value: 'other' },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Date name="dateBirth" label="Date of Birth" />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="citizenship"
                label="Citizenship"
                options={[
                  { label: 'U.S. Citizen', value: 'U.S. Citizen' },
                  { label: 'Permanent Resident', value: 'Permanent Resident' },
                  { label: 'Foreign National', value: 'Foreign National' },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Field name="creditScore" label="Credit Score" type="number" />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="maritalStatus"
                label="Marital Status"
                options={[
                  { label: 'Married', value: 'married' },
                  { label: 'Unmarried', value: 'unmarried' },
                ]}
              />
            </Grid.Item>
            {isBorrower && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  label="Number of Flips"
                  name="numFlipped"
                  mask="# projects"
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>
            <Grid.Item xs={12} sm={isBorrower ? 6 : 12}>
              <SocialSecurityNumber
                label="Social Security Number"
                name="socialSecurityNumber"
                mask="00-0000000"
              />
            </Grid.Item>

            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

function PanelPersonalInformation({ user, isLoading, setSecure }: Props) {
  const { user: sessionUser, refreshUser } = useSession()
  const isBorrower = !!sessionUser?.actor?.isBorrower
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const { mutate: update, isPending: isUpdating } = (
    user.isInvestor ? useUpdateInvestor : useUpdateBorrower
  )()

  return (
    <>
      <Panel
        title="My Personal Account Info"
        onEdit={() => {
          setSecure()
          setIsEditModalVisible(true)
        }}
      >
        <Grid gap={24}>
          <Grid.Item sm={6}>
            <Summary name="Name">{user.name}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Gender">{capitalize(user.gender || '')}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Date of Birth">
              {user.dateBirth && formatDate(user.dateBirth)}
            </Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Citizenship">{user.citizenship}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Credit Score">{user.creditScore}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Marital Status">
              {capitalize(user.maritalStatus || '')}
            </Summary>
          </Grid.Item>
          {isBorrower && (
            <Grid.Item sm={6}>
              <Summary name="Number of Flips">{user.numFlipped}</Summary>
            </Grid.Item>
          )}
          <Grid.Item sm={6}>
            <Summary name="Phone">{formatPhone(user.phone)}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Social Security Number">
              <div className="h-4">
                {user.isSsnSet && user?.socialSecurityNumber}
                {user.isSsnSet && !user?.socialSecurityNumber ? (
                  <span className="cursor-pointer" onClick={setSecure}>
                    ••••••••• <Icon name={IconName.view} size="md" />
                  </span>
                ) : undefined}
              </div>
            </Summary>
          </Grid.Item>
        </Grid>
      </Panel>
      {isEditModalVisible && (
        <ModalEditPersonalInfo
          initialValues={{
            name: user.name,
            gender: user.gender || '',
            dateBirth: user.dateBirth || '',
            citizenship: user.citizenship || '',
            creditScore: user.creditScore || '',
            maritalStatus: user.maritalStatus || '',
            numFlipped: user.numFlipped as unknown as string,
            phone: user.phone || '',
            socialSecurityNumber: user.socialSecurityNumber || '',
          }}
          isLoading={isLoading}
          isBorrower={isBorrower}
          saving={isUpdating}
          onClose={() => setIsEditModalVisible(false)}
          onSubmit={(data) =>
            update({ id: user.id, ...data } as unknown as BorrowerDetails, {
              onSuccess: () => {
                setIsEditModalVisible(false)
                user.name !== data.name && refreshUser()
              },
            })
          }
        />
      )}
    </>
  )
}

export { PanelPersonalInformation }
