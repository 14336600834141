import { ColumnDef } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { LinkedAccount, PersonRole, PersonTitle } from 'types'
import styles from './styles.module.scss'

type Row = LinkedAccount & {
  personType: 'borrower' | 'investor' | 'vendor'
}

type Props = {
  data?: Row[]
  showTitle?: boolean
  onChangeRole: (id: string, role: PersonRole) => void
  onChangeTitle: (id: string, title: PersonTitle) => void
  onDelete: (id: string) => void
}

const titles: PersonTitle[] = [
  'Manager',
  'Managing Member',
  'President',
  'Authorized Member',
  'Member',
  'Vice President',
  'General Partner',
  'Trustee',
  'Co-trustee',
]

function TableLinkedAccounts({
  data = [],
  showTitle = true,
  onChangeRole,
  onChangeTitle,
  onDelete,
}: Props) {
  const navigate = useNavigate()
  const columns: ColumnDef<Row>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
        cell: ({ row, cell }) => (
          <TextLink
            onClick={() =>
              navigate(pathTo(row.original.personType, row.original.id))
            }
          >
            {cell.getValue() as string}
          </TextLink>
        ),
      },
      ...(showTitle
        ? [
            {
              header: 'Title',
              accessorKey: 'permission.title',
              size: 165,
              cell: ({ getValue, cell }) => {
                const [title, setTitle] = useState(
                  (getValue() as string) || 'None'
                )
                const { id } = cell.row.original

                return (
                  <EllipsesActions
                    className={styles.menu}
                    trigger={
                      <div className={styles.action}>
                        {capitalize(title)} <Icon name={IconName.arrowDown} />
                      </div>
                    }
                  >
                    {titles.map((titleType) => (
                      <EllipsesActions.Item
                        key={titleType}
                        onSelect={() => {
                          setTitle(titleType)
                          onChangeTitle(id, titleType)
                        }}
                      >
                        <div className={styles.item}>
                          <div className={styles.title}>
                            {capitalize(titleType)}
                          </div>
                        </div>
                        {title === titleType && (
                          <Icon name={IconName.check} className={styles.tick} />
                        )}
                      </EllipsesActions.Item>
                    ))}
                  </EllipsesActions>
                )
              },
            },
          ]
        : []),
      {
        header: 'Role',
        accessorKey: 'permission.role',
        size: 100,
        cell: ({ getValue, cell }) => {
          const role = (getValue() as string) || 'manager'
          const { id } = cell.row.original

          return (
            <EllipsesActions
              className={styles.menu}
              trigger={
                <div className={styles.action}>
                  {capitalize(role)} <Icon name={IconName.arrowDown} />
                </div>
              }
            >
              <EllipsesActions.Item
                onSelect={() => onChangeRole(id, 'manager')}
              >
                <div className={styles.item}>
                  <div className={styles.title}>
                    <Icon name={IconName.manager} />
                    Manager
                  </div>
                  {role === 'manager' && (
                    <Icon name={IconName.check} className={styles.tick} />
                  )}
                </div>
              </EllipsesActions.Item>
              <EllipsesActions.Item onSelect={() => onChangeRole(id, 'viewer')}>
                <div className={styles.item}>
                  <div className={styles.title}>
                    <Icon name={IconName.viewer} />
                    Viewer
                  </div>
                  {role === 'viewer' && (
                    <Icon name={IconName.check} className={styles.tick} />
                  )}
                </div>
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(id)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} />
}

export default TableLinkedAccounts
