import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import {
  getTrusts,
  addTrust,
  editTrust,
  deleteTrust,
} from 'admin/services/api/trusts'
import { KEY_TRUSTS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { message } from '../../utils/message'

const useTrusts = () => {
  return useQuery({
    queryKey: [KEY_TRUSTS],
    queryFn: getTrusts,
    placeholderData: keepPreviousData,
  })
}

const useAddTrust = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addTrust,
    onSuccess: (trusts) => {
      queryClient.setQueryData([KEY_TRUSTS], trusts)
      message.success('Trust added')
    },
    onError: handleErrorResponse,
  })
}

const useEditTrust = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editTrust,
    onSuccess: (trusts) => {
      queryClient.setQueryData([KEY_TRUSTS], trusts)
      message.success('Trust saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteTrust = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteTrust,
    onSuccess: (trusts) => {
      queryClient.setQueryData([KEY_TRUSTS], trusts)
      message.success('Trust deleted')
    },
    onError: handleErrorResponse,
  })
}

export { useTrusts, useAddTrust, useEditTrust, useDeleteTrust }
