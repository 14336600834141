import clsx from 'clsx'
import { ForwardedRef, forwardRef, InputHTMLAttributes } from 'react'
import styles from './styles.module.scss'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  label?: string
}

const Toggle = forwardRef(
  (
    { className, label, value, ...rest }: Props,
    ref: ForwardedRef<HTMLInputElement | null>
  ) => {
    return (
      <label
        className={clsx(className, styles.toggle)}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          type="checkbox"
          className={styles.toggleCheckbox}
          ref={ref}
          checked={value as unknown as boolean}
          {...rest}
        />
        <div className={styles.toggleSwitch}></div>
        {label && <span className={styles.toggleLabel}>{label}</span>}
      </label>
    )
  }
)
Toggle.displayName = 'Toggle'

export default Toggle
