import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

function PageLoader() {
  return (
    <div className={styles.pageLoader}>
      <div className={styles.loader}>
        <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
      </div>
    </div>
  )
}

export default PageLoader
