import { gtag, install } from 'ga-gtag'
import { useCallback, useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { devDomains, rootDomain, devEmailDomains } from 'constants/domain'
import { useSession } from './use-session'

export const useAnalytics = () => {
  const { user, isLoading } = useSession()
  const isGAEnabled =
    !!import.meta.env.VITE_APP_GA && window.location.hostname !== 'localhost'
  const isDebugMode = devDomains.includes(rootDomain)
  const isDevEmail = devEmailDomains.includes(
    user?.admin?.email?.split('@')[1] as string
  )
  const isHotJarEnabled =
    !!import.meta.env.VITE_APP_HJID &&
    !!import.meta.env.VITE_APP_HJSV &&
    window.location.hostname !== 'localhost' &&
    !isDevEmail

  const pageView = useCallback(
    (url = window.location.pathname + window.location.search) => {
      if (!isLoading && isGAEnabled) {
        gtag('event', 'page_view', url)
      }
      if (!isLoading && isHotJarEnabled && hotjar.initialized()) {
        hotjar.stateChange(url)
      }
    },
    [isLoading, isGAEnabled, isHotJarEnabled]
  )

  useEffect(() => {
    if (!isLoading && isHotJarEnabled && !hotjar.initialized()) {
      hotjar.initialize({
        id: import.meta.env.VITE_APP_HJID,
        sv: import.meta.env.VITE_APP_HJSV,
      })
      hotjar.identify(user?.admin?.id || user?.person?.id || null, {
        email: user?.admin?.email || user?.person?.email,
      })
    }
    if (!isLoading && isGAEnabled) {
      install(import.meta.env.VITE_APP_GA as string, {
        debug_mode: isDebugMode,
      })
      if (isDevEmail) {
        gtag('set', { traffic_type: 'internal' })
      }
      pageView()
    }
  }, [isLoading, isGAEnabled, isHotJarEnabled, pageView])

  return {
    pageView,
  }
}
