import { pathTo } from 'admin/path-to'
import { IconName } from 'components/Icon'
import { useIntercom } from 'hooks/use-intercom'
import { useMobile } from 'hooks/use-mobile'
import { useSession } from 'hooks/use-session'
import { SideNavigationAccordionLink } from './SideNavigationAccordionLink'
import SideNavigationComponent from './SideNavigationComponent'
import SideNavigationLink from './SideNavigationLink'

interface Props {
  isContactsOpen: boolean
  setIsMobileNavVisible: (visible: boolean) => void
  setIsContactsOpen: (open: boolean) => void
}

function AdminSideNavigation({
  isContactsOpen,
  setIsMobileNavVisible,
  setIsContactsOpen,
}: Props) {
  const { user, isIndividual, ability } = useSession()
  const { toggleIntercom } = useIntercom()
  const { isTablet } = useMobile()

  const isOrigination = user?.client.origination
  const isServicing = user?.client.servicing
  const showInvestors = ability.can('read', 'investors')
  const showReports = ability.can('read', 'reports')
  const showVendors = ability.can('read', 'vendors')

  return (
    <SideNavigationComponent
      pathTo={pathTo}
      setIsMobileNavVisible={setIsMobileNavVisible}
    >
      <div>
        <SideNavigationLink
          to={pathTo('dashboard')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Dashboard"
          icon={IconName.dashboard}
        />
        <SideNavigationLink
          to={pathTo('tasks', 'tasks')}
          onClick={() => setIsMobileNavVisible(false)}
          label="Tasks"
          icon={IconName.approvedCircle}
        />
        <SideNavigationAccordionLink
          to={pathTo('contacts')}
          onClick={() => {
            setIsMobileNavVisible(false)
            setIsContactsOpen(!isContactsOpen)
          }}
          onOpenContacts={() => setIsContactsOpen(!isContactsOpen)}
          label="Contacts"
          icon={IconName.person}
          arrowIcon={
            isContactsOpen ? IconName.arrowUpFilled : IconName.arrowDownFilled
          }
        />
        {isContactsOpen && (
          <div className="ml-8">
            <SideNavigationLink
              to={pathTo('borrowers')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Borrowers"
            />
            {showInvestors && (
              <SideNavigationLink
                to={pathTo('investors')}
                onClick={() => setIsMobileNavVisible(false)}
                label="Investors"
              />
            )}
            {showVendors && (
              <SideNavigationLink
                to={pathTo('vendors')}
                onClick={() => setIsMobileNavVisible(false)}
                label="Vendors"
              />
            )}
          </div>
        )}

        {isOrigination && (
          <>
            <SideNavigationLink
              to={pathTo('quotes')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Quotes"
              icon={IconName.quote}
            />
            <SideNavigationLink
              to={pathTo('loans')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Pipeline"
              icon={IconName.pipeline}
            />
          </>
        )}
        {isServicing && showInvestors && (
          <>
            <SideNavigationLink
              to={pathTo('servicing')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Loans"
              icon={IconName.loanNavigation}
            />
            <SideNavigationLink
              to={pathTo('payments', 'due')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Payments"
              icon={IconName.paymentNavigation}
            />
            <SideNavigationLink
              to={pathTo('payouts')}
              onClick={() => setIsMobileNavVisible(false)}
              label="Payouts"
              icon={IconName.payout}
            />
          </>
        )}
        {!isIndividual && showReports && (
          <SideNavigationLink
            to={pathTo('reports')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Reports"
            icon={IconName.reportNavigation}
          />
        )}
        {showInvestors && (
          <SideNavigationLink
            to={pathTo('funds')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Funds"
            icon={IconName.fundNavigation}
          />
        )}
        {showInvestors && (
          <SideNavigationLink
            to={pathTo('offerings')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Offerings"
            icon={IconName.offering}
          />
        )}
      </div>

      <div>
        {!isIndividual && (
          <SideNavigationLink
            onClick={() => {
              setIsMobileNavVisible(false)
              toggleIntercom()
            }}
            label="Get help"
            icon={IconName.support}
          />
        )}
      </div>
      {isTablet && (
        <div>
          <SideNavigationLink
            to={pathTo('settings')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Settings"
            icon={IconName.settings}
          />
          {user && (
            <SideNavigationLink
              onClick={() => {
                setIsMobileNavVisible(false)
                toggleIntercom()
              }}
              label="Support"
              icon={IconName.support}
            />
          )}
          <SideNavigationLink
            to={pathTo('logout')}
            onClick={() => setIsMobileNavVisible(false)}
            label="Log out"
            icon={IconName.logout}
          />
        </div>
      )}
    </SideNavigationComponent>
  )
}

export default AdminSideNavigation
