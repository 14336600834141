import { Application } from 'types'

type ApplicationStatus = 'draft' | 'submitted' | 'processing' | 'approved'

export const getApplicationStatus = (
  status: Application['status'],
  isDraft: boolean
): ApplicationStatus => {
  if (isDraft) {
    return 'draft'
  }
  if (status === 'lead') {
    return 'submitted'
  }
  if (status === 'processing' || status === 'underwriting') {
    return 'processing'
  }
  if (status === 'approved' || status === 'closed') {
    return 'approved'
  }
  return 'draft'
}

export const bgColor = {
  draft: 'bg-grey-75',
  submitted: 'bg-green-25',
  processing: 'bg-yellow-50',
  approved: 'bg-green-25',
}

export const textColor = {
  draft: 'text-grey-700',
  submitted: 'text-green-100',
  processing: 'text-yellow-200',
  approved: 'text-green-100',
}
