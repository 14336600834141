import { Row } from 'types'
import { formatDate } from 'utils/date'
import { getReport } from '../api/reports'

const download1098TaxReport = async (params, year): Promise<Row[]> => {
  const { loans } = await getReport({
    type: '1098-tax',
    params,
    data: { year },
  })

  return loans.map((loan) => ({
    'Borrower ID': loan.borrowerId,
    Borrower: loan.borrowerName,
    'Borrower Email': loan.borrowerEmail,
    'Borrower Address': loan.borrowerAddress,
    'ID Type': loan.borrowerTaxIdType,
    'ID Number': loan.borrowerTaxId,
    'Loan ID': loan.loanNumber ?? loan.loanId,
    'Loan Address': loan.loanAddress,
    'Interest Paid in 2023': loan.interestPaid,
    'Outstanding Principal': loan.principalBalance,
    'Origination Date': formatDate(loan.originationDate),
    Properties: loan.loanNumProperties,
  }))
}

const download1099TaxReport = async (params, year): Promise<Row[]> => {
  const { investors } = await getReport({
    type: '1099-tax',
    params,
    data: { year },
  })

  return investors.map((investor) => ({
    'Investor ID': investor.investorId,
    Investor: investor.investorName,
    'Investor Email': investor.investorEmail,
    Address: investor.investorAddress,
    'ID Type': investor.investorTaxIdType,
    'ID Number': investor.investorTaxId,
    'Interest Income': investor.interestIncome,
    'Spread Income': investor.spreadIncome,
    'Charges Income': investor.chargesIncome,
    'Total Income': investor.totalIncome,
  }))
}

export { download1098TaxReport, download1099TaxReport }
