import clsx from 'clsx'
import { Formik, Field, ErrorMessage } from 'formik'
import { SelectBorrower } from 'admin/components/SelectBorrower'
import { useBorrowers } from 'admin/hooks/use-borrowers'
import { Button } from 'components/Button'
import { Checkbox, Form } from 'components/Form'
import formStyles from 'components/Form/styles.module.scss'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalAddLoanProps {
  entity?: string
  saving: boolean
  onSave: (borrower: { borrower: string; primary: boolean }) => void
  onCancel: () => void
  handleAddBorrower: (personName?: string) => void
  addedPersonId: string
  include?: string[]
}

type FormValues = {
  borrower: string
  primary: boolean
}

const FormSchema = createScheme({
  borrower: required,
})

const initialValues: FormValues = {
  borrower: '',
  primary: false,
}

function ModalChooseBorrower({
  entity = 'Borrower',
  saving,
  onSave,
  onCancel,
  handleAddBorrower,
  addedPersonId,
  include = [],
}: ModalAddLoanProps) {
  const { data, isPending } = useBorrowers({
    pagination: { page: 0, size: 100 },
    details: true,
  })

  return (
    <Modal
      title={`Add ${entity}`}
      loading={isPending}
      asChild
      className={styles.modal}
      onClose={onCancel}
    >
      <Formik
        initialValues={{
          ...initialValues,
          borrower: addedPersonId,
        }}
        validationSchema={FormSchema}
        enableReinitialize
        onSubmit={(data) => {
          onSave(data)
        }}
      >
        <Form modal>
          <div className={formStyles.inputWithLabel}>
            <Field name="borrower">
              {({ meta: { touched, error }, form, field }) => (
                <>
                  <label
                    htmlFor="borrower"
                    className={clsx(formStyles.label, {
                      [formStyles.errorLabel]: touched && error,
                    })}
                  >
                    {entity}
                  </label>
                  <SelectBorrower
                    defaultOptions={data?.borrowers}
                    value={field.value}
                    className={clsx({
                      [formStyles.errorField]: touched && error,
                    })}
                    entity={entity}
                    onSelect={(id) => form.setFieldValue('borrower', id)}
                    onCreate={(name) => handleAddBorrower(name)}
                  />
                  <ErrorMessage
                    component="div"
                    name="borrower"
                    className={formStyles.errorMessage}
                  />
                </>
              )}
            </Field>
            <TextLink onClick={() => handleAddBorrower()}>
              <Icon name={IconName.plus} size="sm" /> Create a new {entity}
            </TextLink>
            {include?.includes('primary') && (
              <div className={styles.primary}>
                <Checkbox
                  className="items-center"
                  name="primary"
                  label="Primary"
                />
              </div>
            )}
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalChooseBorrower
