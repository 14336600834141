import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Date, Form } from 'components/Form'
import { Grid } from 'components/Grid'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const FormSchema = createScheme({
  date: required,
})

type FormValues = {
  date: string
}

interface Props {
  saving: boolean
  onSave: ({ date }: { date: string }) => void
  onCancel: () => void
}

function ModalDistribution({ saving, onSave, onCancel }: Props) {
  const initialValue: FormValues = {
    date: '',
  }

  return (
    <Modal title="Add Distribution" onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        validationSchema={FormSchema}
        onSubmit={onSave}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Date label="Distribution Date" name="date" />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalDistribution }
