import clsx from 'clsx'
import { useState } from 'react'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Select } from 'components/Select'
import { PersonType } from 'types'

type Type = 'investor' | 'vendor'

interface Props {
  onCloseClick?: () => void
  onBackClick?: () => void
  onNextClick: ({
    type,
    personType,
  }: {
    type: Type
    personType: PersonType
  }) => void
}

const StepChooseType = ({ onBackClick, onCloseClick, onNextClick }: Props) => {
  const [type, setType] = useState<Type>()
  const [personType, setPersonType] = useState<PersonType | undefined>()

  return (
    <Flex stack className="px-8">
      <Flex stack gap={6}>
        <div>Recipient type</div>
        <Select
          options={[
            { label: 'Investor', value: 'investor' },
            { label: 'Vendor', value: 'vendor' },
          ]}
          portal
          onChange={(option) => setType(option.value as 'investor' | 'vendor')}
        />
      </Flex>
      {type && (
        <Flex>
          <Flex
            stack
            alignItems="center"
            justifyContent="center"
            className={clsx(
              'border border-solid border-grey-200 rounded cursor-pointer flex-grow flex-shrink w-1/2 py-8 px-6 hover:border-grey-600',
              {
                ['!border-blue-200']: personType === 'individual',
              }
            )}
            onClick={() => setPersonType('individual')}
          >
            <Icon name={IconName.individual} size="xxl" />
            Personal
          </Flex>
          <Flex
            stack
            alignItems="center"
            justifyContent="center"
            className={clsx(
              'border border-solid border-grey-200 rounded cursor-pointer flex-grow flex-shrink w-1/2 py-8 px-6 hover:border-grey-600',
              {
                ['!border-blue-200']: personType === 'entity',
              }
            )}
            onClick={() => setPersonType('entity')}
          >
            <Icon name={IconName.entity} size="xxl" />
            Company
          </Flex>
        </Flex>
      )}
      <Flex justifyContent="flex-end" gap={8}>
        {onCloseClick && (
          <Button variant="secondary" onClick={onCloseClick}>
            Cancel
          </Button>
        )}
        {onBackClick && (
          <Button variant="secondary" onClick={onBackClick}>
            Back
          </Button>
        )}
        <Button
          disabled={!type || !personType}
          onClick={() =>
            onNextClick({
              type: type as Type,
              personType: personType as PersonType,
            })
          }
        >
          Next
        </Button>
      </Flex>
    </Flex>
  )
}

export { StepChooseType }
