import { useNavigate, useParams } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Tabs } from 'components/Tabs'
import { PersonPanel } from './PersonPanel'
import { ProductPanel } from './ProductPanel'

function CustomFields() {
  const navigate = useNavigate()
  const { tab } = useParams() as { tab: 'product' | 'borrower' }

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Custom Data Fields" />
        <Tabs
          tab={tab}
          defaultActiveId="product"
          onTabSelect={(tab) => {
            navigate(pathTo('settingsCustomFields', tab), { replace: true })
          }}
        >
          <Tabs.Pane tab="Product" id="product" className="pt-5">
            <ProductPanel />
          </Tabs.Pane>
          <Tabs.Pane tab="Borrower" id="borrower" className="pt-5">
            <PersonPanel personType="borrower" />
          </Tabs.Pane>
          <Tabs.Pane tab="Investor" id="investor" className="pt-5">
            <PersonPanel personType="investor" />
          </Tabs.Pane>
          <Tabs.Pane tab="Vendor" id="vendor" className="pt-5">
            <PersonPanel personType="vendor" />
          </Tabs.Pane>
        </Tabs>
      </Flex>
    </MainContent>
  )
}

export { CustomFields }
