import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useUpdateDocument } from 'admin/hooks/use-document'
import { DatePicker } from 'components/DatePicker'
import { Grid } from 'components/Grid'
import { Summary } from 'components/Summary'
import { DocumentVersion, LoanDocument } from 'types'
import { friendlyDate } from 'utils/date'
import { friendlySize } from 'utils/file'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
  version?: DocumentVersion
  isLocked: boolean
}

export function PanelDocumentInfo({ document, version, isLocked }: Props) {
  const [expiredDate, setExpiredDate] = useState(
    document.expiredDate || undefined
  )
  const { mutate: updateDocument } = useUpdateDocument()

  useEffect(() => {
    if (document.expiredDate) {
      setExpiredDate(document.expiredDate)
    }
  }, [document.expiredDate])

  return (
    <Grid gap={16} className={styles.documentInfo}>
      <Grid.Item>
        <Summary name="File Name">{get(version, 'filename')}</Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Expiry date">
          <DatePicker
            value={expiredDate}
            disabled={isLocked}
            onChange={(date) => {
              setExpiredDate(date)
              updateDocument({
                id: document.id,
                expiredDate: date || null,
              })
            }}
          />
        </Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Name">{document.name}</Summary>
      </Grid.Item>
      {document.summary ? (
        <Grid.Item>
          <Summary name="Summary">{document.summary}</Summary>
        </Grid.Item>
      ) : null}
      <Grid.Item>
        <Summary name="Size">{friendlySize(get(version, 'size'))}</Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Created Timestamp">
          {friendlyDate(get(version, 'createdAt') as string)}
        </Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Modified Timestamp">
          {friendlyDate(get(version, 'updatedAt') as string)}
        </Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Page #">{get(version, 'pages')}</Summary>
      </Grid.Item>
      <Grid.Item>
        <Summary name="Uploaded By">{get(version, 'uploader.name')}</Summary>
      </Grid.Item>
    </Grid>
  )
}
