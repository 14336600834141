import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShareLoan, useTransferLoan } from 'admin/hooks/use-loans'
import { pathTo, pathToLoan } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { ModalTransferLoan } from 'components/Modal/TransferLoan'
import { useSession } from 'hooks/use-session'
import { Loan } from 'types'
import { message } from 'utils/message'
import styles from './LoanHeaderNotification.module.scss'

interface Props {
  loan: Loan
}

const LoanHeaderNotification = ({ loan }: Props) => {
  const { user } = useSession()
  const navigate = useNavigate()
  const [isTransferVisible, setIsTransferVisible] = useState(false)
  const [isAcceptButtonLoading, setIsAcceptButtonLoading] = useState(false)
  const [isRejectButtonLoading, setIsRejectButtonLoading] = useState(false)
  const { mutate: share } = useShareLoan(loan.id)
  const { mutate: transfer, isPending: transferring } = useTransferLoan(loan.id)
  const isTransfer = !!loan.transfer
  const isShared = !!loan.shared?.length || isTransfer
  const shareDetails = loan.shared?.[0]
  const transferDetails = loan.transfer
  const isBroker = isShared && loan.clientId === user?.client.id
  const isLender = isShared && loan.clientId !== user?.client.id

  let text = ''
  switch (true) {
    case isBroker && isTransfer && !!transferDetails?.dateAccepted:
      text = `${shareDetails?.name} wants to transfer this loan. The loan will be copied to their account and you will no longer be able to edit it.`
      break
    case isBroker && isShared && !!shareDetails?.dateAccepted:
      text = `This loan has been shared with ${shareDetails?.name}. They can edit and view its contents.`
      break
    case isBroker && isShared && !shareDetails?.dateAccepted:
      text = `An invitation has been sent to share this loan with ${shareDetails?.name}.`
      break
    case isLender && isTransfer && !transferDetails?.dateAccepted:
      text = `${shareDetails?.name} wants to transfer this loan to you. The loan will be copied to your account and you will no longer be able to edit it.`
      break
    case isLender && isTransfer && !!transferDetails?.dateAccepted:
      text = `An invitation has been sent to transfer this loan to ${
        shareDetails?.name || loan.admin?.name
      }.`
      break
    case isLender && isShared && !shareDetails?.dateAccepted:
      text = `${
        shareDetails?.name || loan.admin?.name
      } wants you to collaborate on this loan. You both will be able to edit and view its contents.`
      break
    case isLender && isShared && !!shareDetails?.dateAccepted:
      text = `This loan has been shared with ${shareDetails?.name}. They can edit and view its contents.`
      break
  }

  let rejectButtonText = 'Reject'
  if (isBroker && !transferDetails?.dateAccepted) {
    rejectButtonText = 'Revoke Access'
  }

  let acceptButtonText = 'Accept'
  if (isBroker && !transferDetails?.dateAccepted) {
    acceptButtonText = 'Transfer Ownership'
  }
  if (
    isLender &&
    ((!isTransfer && shareDetails?.dateAccepted) ||
      transferDetails?.dateAccepted)
  ) {
    acceptButtonText = 'Request Transfer'
  }

  const handleReject = () => {
    setIsRejectButtonLoading(true)
    if (isTransfer) {
      transfer(
        { accepted: false },
        {
          onSettled: () => {
            setIsRejectButtonLoading(false)
          },
        }
      )
    } else {
      share(
        { accepted: false },
        {
          onSettled: () => {
            setIsRejectButtonLoading(false)
          },
        }
      )
    }
    if (isLender) {
      navigate(pathTo('servicing'), { replace: true })
    }
  }
  const handleAccept = () => {
    switch (true) {
      case isBroker && isTransfer && !!transferDetails?.dateAccepted:
        setIsAcceptButtonLoading(true)
        transfer(
          { accepted: true },
          {
            onSuccess: () => {
              message.success('Loan has been transferred')
            },
            onSettled: () => {
              setIsAcceptButtonLoading(false)
            },
          }
        )
        break
      case isBroker && isShared:
        setIsTransferVisible(true)
        break

      case isLender && isTransfer && !!transferDetails?.dateAccepted:
        setIsTransferVisible(true)
        break
      case isLender && isTransfer && !transferDetails?.dateAccepted:
        setIsAcceptButtonLoading(true)
        transfer(
          { accepted: true },
          {
            onSuccess: (loan) => {
              message.success('Loan has been transferred')
              navigate(pathToLoan(loan), { replace: true })
            },
            onSettled: () => {
              setIsAcceptButtonLoading(false)
            },
          }
        )
        break
      case isLender && isShared && !!shareDetails?.dateAccepted:
        setIsTransferVisible(true)
        break
      case isLender && isShared && !shareDetails?.dateAccepted:
        setIsAcceptButtonLoading(true)
        share(
          { accepted: true },
          {
            onSuccess: () => {
              message.success('Loan has been shared')
            },
            onSettled: () => {
              setIsAcceptButtonLoading(false)
            },
          }
        )
        break
    }
  }

  return (
    isShared && (
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.notification}
        >
          <div>{text}</div>
          <Flex gap={16}>
            <Button
              variant="secondary"
              color="negative"
              onClick={handleReject}
              loading={isRejectButtonLoading}
            >
              {rejectButtonText}
            </Button>
            <Button onClick={handleAccept} loading={isAcceptButtonLoading}>
              {acceptButtonText}
            </Button>
          </Flex>
        </Flex>
        {isTransferVisible && (
          <ModalTransferLoan
            loan={loan}
            saving={transferring}
            onSave={({ message: transferMessage }) => {
              transfer(
                { message: transferMessage },
                {
                  onSuccess: () => {
                    setIsTransferVisible(false)
                    message.success('Sent request to transfer the loan')
                  },
                }
              )
            }}
            onCancel={() => setIsTransferVisible(false)}
          />
        )}
      </>
    )
  )
}

export { LoanHeaderNotification }
