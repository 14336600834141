import { useLocation } from 'react-router-dom'

interface Response {
  isServicing: boolean
  isOrigination: boolean
}

export const useLoanType = (): Response => {
  const location = useLocation()
  const isServicing = location.pathname.includes('servicing')

  return {
    isServicing,
    isOrigination: !isServicing,
  }
}
