import clsx from 'clsx'
import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import styles from './styles.module.scss'

interface Props {
  severity?: 'info' | 'warning'
  iconName?: IconName
  className?: string
  children: ReactNode
  onClose?: () => void
}

export const Alert = ({
  severity = 'info',
  iconName = IconName.info,
  className,
  children,
  onClose,
}: Props) => {
  const classNames = clsx(styles.alert, className, {
    [styles.info]: severity === 'info',
    [styles.warning]: severity === 'warning',
  })

  return (
    <Flex className={classNames} alignItems="center" gap={8}>
      <Icon className={styles.icon} name={iconName} size="md" />
      <Flex
        gap={8}
        justifyContent="space-between"
        alignItems="center"
        className="w-full"
      >
        <div className="flex-grow">{children}</div>
        {onClose && (
          <div className="cursor-pointer" onClick={onClose}>
            <Icon
              className={styles.iconClose}
              name={IconName.close}
              size="sm"
            />
          </div>
        )}
      </Flex>
    </Flex>
  )
}
