import { useCallback } from 'react'
import {
  OptionProps,
  components,
  MultiValueProps,
  ValueContainerProps,
} from 'react-select'
import { Avatar } from 'components/Avatar'
import { Checkbox } from 'components/Checkbox'
import { Flex } from 'components/Flex'
import { Option, Select } from 'components/Select'

interface Props {
  options: Option[]
  value: string[]
  onChange: (value: string[]) => void
}

function SelectUser({ options, value, onChange }: Props) {
  const Option = useCallback((props: OptionProps) => {
    const { value, label, email } = props.data as any
    return (
      <components.Option {...props}>
        <Flex gap={10} alignItems="center">
          <Checkbox checked={props.isSelected} onChange={() => {}} />
          <Avatar name={label} id={value} />
          <Flex stack gap={2}>
            <div>{label}</div>
            <div className="text-sm text-grey-700">{email}</div>
          </Flex>
        </Flex>
      </components.Option>
    )
  }, [])

  const MultiValue = useCallback((props: MultiValueProps) => {
    const { value, label } = props.data as any

    return (
      <components.MultiValue {...props}>
        <Flex gap={10} alignItems="center">
          <Avatar name={label} id={value} />
          <Flex stack gap={2}>
            <div>{label}</div>
          </Flex>
        </Flex>
      </components.MultiValue>
    )
  }, [])

  const ValueContainer = useCallback(
    (props: ValueContainerProps) => (
      <components.ValueContainer className="!px-2 !py-2" {...props}>
        {props.children}
      </components.ValueContainer>
    ),
    []
  )

  return (
    <Select
      className="w-full"
      options={options}
      onChange={(options) =>
        onChange(
          (options as unknown as Option[]).map(
            (option) => option.value as string
          )
        )
      }
      value={value}
      isMulti
      portal
      placeholder="Select users"
      components={{ Option, MultiValue, ValueContainer }}
    />
  )
}

export { SelectUser }
