import { createContext, useContext } from 'react'
import { Task } from 'types'
import { ITaskFilterName } from './LoanTasksDrawer/LoanTasksDrawer'

interface ITasksContext {
  openTask: (
    taskId?: string,
    options?: {
      loanId?: string
      onBack?: () => void
      backButtonText?: ITaskFilterName
      onSave?: (task: Task) => void
      onDelete?: (id: string) => void
    }
  ) => void
  isTaskOpen: boolean
  taskId: string | undefined
  loanId: string | undefined
  closeTask: () => void
}

const TasksContext = createContext<ITasksContext>({} as ITasksContext)

const useTasksContext = () => useContext(TasksContext)

export { TasksContext, useTasksContext }
