import { capitalize } from 'lodash'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import { Badge } from './index'

interface Props {
  status: boolean
  info?: string
}

function BankingStatusBadge({ status, info }: Props) {
  return (
    <Badge color={status ? 'green' : info ? 'red' : 'gray'}>
      {capitalize(status ? 'Active' : 'Disabled')}
      {info && (
        <>
          {' '}
          <Tooltip content={info}>
            <Icon name={IconName.info} size="sm" />
          </Tooltip>
        </>
      )}
    </Badge>
  )
}

export default BankingStatusBadge
