import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useAddLoanVendor, useLoanVendors } from 'admin/hooks/use-loan-vendors'
import { pathTo } from 'admin/path-to'
import { Vendor } from 'admin/services/api/vendors'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { LinkEmail } from 'components/LinkEmail'
import { ChooseVendor } from 'components/Modal/ChooseVendor'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { Text } from 'components/Text'
import { TextLink } from 'components/TextLink'
import { Loan } from 'types'
import { formatPhone } from 'utils/phone'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
}

const closingAgentRoles = ['Closing Assistant', 'Escrow Office', 'Title Agent']

export default function PanelClosingAgent({ loan }: Props) {
  const [adding, setAdding] = useState(false)
  const { data: vendors, isPending } = useLoanVendors({
    loanId: loan.id,
    filter: { role: closingAgentRoles },
  })
  const add = useAddLoanVendor()

  return (
    <Panel title="Closing Agent">
      {isPending && (
        <div className={styles.loadingClosingAgent}>
          <Icon name={IconName.loaderSpinner} className="spinner" />
        </div>
      )}
      {(vendors?.vendors || []).length === 0 && !isPending && (
        <Text className={styles.noClosingAgent}>
          This loan doesn&apos;t currently have a closing agent.
        </Text>
      )}
      <Flex stack gap={32}>
        {(vendors?.vendors || []).map(
          ({ id, name, companyName, email, phone, associatedWith, type }) => (
            <Grid key={id} gap={24} className={styles.closingAgent}>
              {type === 'entity' ? (
                <>
                  <Grid.Item xs={6}>
                    <Summary name="Name">
                      <NavLink to={pathTo('vendor', id)} className="link">
                        {name}
                      </NavLink>
                    </Summary>
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Summary name="Phone">{formatPhone(phone)}</Summary>
                  </Grid.Item>
                </>
              ) : (
                <>
                  <Grid.Item xs={6}>
                    <Summary name="Name">
                      <NavLink to={pathTo('vendor', id)} className="link">
                        {name}
                      </NavLink>
                    </Summary>
                  </Grid.Item>
                  {companyName || associatedWith?.[0] ? (
                    <Grid.Item xs={6}>
                      <Summary name="Company">
                        {companyName ||
                          (associatedWith?.[0] ? (
                            <NavLink
                              to={pathTo('vendor', associatedWith[0].id)}
                              className="link-normal"
                            >
                              {associatedWith[0].name}
                            </NavLink>
                          ) : (
                            ''
                          ))}
                      </Summary>
                    </Grid.Item>
                  ) : undefined}
                  <Grid.Item xs={6}>
                    <Summary name="Phone">{formatPhone(phone)}</Summary>
                  </Grid.Item>
                  <Grid.Item xs={6}>
                    <Summary name="Email">
                      {email ? (
                        <LinkEmail email={email} className="link-normal" />
                      ) : (
                        '-'
                      )}
                    </Summary>
                  </Grid.Item>
                </>
              )}
            </Grid>
          )
        )}
      </Flex>
      {!loan.lock && (
        <div className={styles.addClosingAgent}>
          <TextLink onClick={() => setAdding(true)}>
            <Icon name={IconName.plus} size="sm" /> Add a closing agent
          </TextLink>
        </div>
      )}
      {adding && (
        <ChooseVendor
          title="Add Closing Agent"
          createLinkText="Create a new Closing Agent"
          onSave={(vendor) => {
            add.mutate(
              { loanId: loan.id, vendor: vendor as Vendor },
              {
                onSuccess: () => {
                  setAdding(false)
                },
              }
            )
          }}
          onClose={() => {
            setAdding(false)
          }}
          vendor={{ role: closingAgentRoles }}
        />
      )}
    </Panel>
  )
}
