import { ReactNode } from 'react'
import { Button, ButtonProps } from '../../Button'
import { Text } from '../../Text'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  title: string
  text: ReactNode
  loading?: boolean
  color?: ButtonProps['color']
  buttonText?: string
  onConfirm: () => void
  onCancel: () => void
}

function ModalConfirm({
  title,
  text,
  loading,
  color = 'default',
  buttonText = 'Confirm',
  onConfirm,
  onCancel,
}: Props) {
  return (
    <Modal title={title} onClose={onCancel}>
      <div className={styles.content}>
        <Text>{text}</Text>
        <div className={styles.buttons}>
          <Button variant="tertiary" onClick={onCancel}>
            Cancel
          </Button>
          <Button loading={loading} color={color} onClick={onConfirm}>
            {buttonText}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalConfirm
