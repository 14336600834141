import { request } from 'services/request'
import { Filter, Meta, Offering, Pagination, Sort } from 'types'
import { updateResponseOffering } from 'utils/api/offering-parse'
import { updateRequestTable } from 'utils/api/table-parser'

const getOfferings = async ({
  type,
  ...params
}: {
  type: 'open' | 'closed'
  search?: string
  filter?: Filter
  page?: Pagination
  sort?: Sort
}): Promise<{ offerings: Offering[]; meta: Meta }> => {
  const {
    data: { offerings, pagination },
  } = await request.get(`/offering/${type}`, {
    params: updateRequestTable(params),
  })
  return {
    offerings: offerings.map((offering) => updateResponseOffering(offering)),
    meta: pagination,
  }
}

export { getOfferings }
