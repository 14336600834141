import clsx from 'clsx'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Avatar } from 'components/Avatar'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { NotificationsIcon } from 'components/Notifications'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import { useIntercom } from 'hooks/use-intercom'
import { useSession } from 'hooks/use-session'
import { DropdownItem } from './DropdownItem'
import styles from './TopMenu.module.scss'

interface Props {
  owners?: { id: string; name: string }[] | null
  onOwnersChange?: (owners: string[]) => void
  onHistoryClick?: () => void
  onTasksClick?: () => void
  messagesCount?: number
  onCommentsClick?: () => void
  readOnly?: boolean
}

const TopMenu = ({
  owners,
  onOwnersChange,
  onHistoryClick,
  onTasksClick,
  messagesCount = 0,
  onCommentsClick,
  readOnly,
}: Props) => {
  const navigate = useNavigate()
  const { user } = useSession()
  const { toggleIntercom } = useIntercom()
  const [menuOpen, setMenuOpen] = useState(false)
  const id = user?.admin?.id ?? user?.person?.id
  const name = user?.admin?.name ?? user?.person?.name
  const email = user?.admin?.email ?? user?.admin?.email
  const { isVisible, isPending, options, selectedOwnerIds } = useOwnerSelector({
    selectedOwners: owners,
  })
  const isDividerVisible =
    isVisible || !!onTasksClick || !!onHistoryClick || !!onCommentsClick

  return (
    <Flex alignItems="center" gap={4} className="p-1 pr-0.5 mr-2.5 h-12">
      {isVisible && (
        <OwnerSelector
          loading={isPending}
          userOptions={options}
          selectedUsers={selectedOwnerIds}
          onChange={onOwnersChange}
          readOnly={readOnly}
        />
      )}

      {onTasksClick && (
        <Flex
          onClick={onTasksClick}
          className="w-8 h-8 rounded cursor-pointer hover:bg-grey-100"
        >
          <Icon
            name={IconName.task}
            size="md"
            className="m-auto text-grey-700"
          />
        </Flex>
      )}

      {onHistoryClick && (
        <Tooltip content="History">
          <Flex
            onClick={onHistoryClick}
            className="w-8 h-8 rounded cursor-pointer hover:bg-grey-100"
          >
            <Icon
              name={IconName.watch}
              size="md"
              className="m-auto text-grey-700"
            />
          </Flex>
        </Tooltip>
      )}

      {onCommentsClick && (
        <Tooltip content="Comments">
          <Flex
            onClick={onCommentsClick}
            className={clsx(
              'w-8 h-8 rounded cursor-pointer hover:bg-grey-100',
              !!messagesCount && styles.hasMessages
            )}
          >
            <Icon
              name={IconName.message}
              size="md"
              className="m-auto text-grey-700"
            />
          </Flex>
        </Tooltip>
      )}

      {isDividerVisible && (
        <hr className="h-8 border-0 border-l border-solid border-grey-100" />
      )}

      <NotificationsIcon
        buttonClassName="w-8 h-8 rounded hover:bg-grey-100"
        iconClassName="text-grey-700"
        portalClassName="mt-1.5 z-10"
      />

      <Dropdown
        open={menuOpen}
        onOpenChange={(nextOpen) => {
          setMenuOpen(nextOpen)
        }}
        className="min-w-80"
        trigger={
          <div className="ml-1.5 p-0.5 rounded hover:bg-grey-100">
            <Tooltip content={menuOpen ? '' : 'My Profile and Settings'}>
              <Avatar
                id={id}
                name={name}
                className="h-7 w-7 rounded-full text-base cursor-pointer"
              />
            </Tooltip>
          </div>
        }
      >
        <Flex
          gap={10}
          alignItems="center"
          className="p-3 leading-[18px] border-0 border-b border-solid border-grey-100"
        >
          <div className="rounded-full">
            <Tooltip content={menuOpen ? '' : 'My Profile and Settings'}>
              <Avatar
                id={id}
                name={name}
                className="h-8 w-8 text-base cursor-pointer"
              />
            </Tooltip>
          </div>
          <Flex gap={0} stack>
            <div>{name}</div>
            <Text variant="s" className="text-grey-700">
              {email}
            </Text>
          </Flex>
        </Flex>

        <div className="p-1">
          <DropdownItem
            itemName="My Account"
            iconName={IconName.person}
            onSelect={() => {
              navigate(pathTo('account'))
              setMenuOpen(false)
            }}
          />
          <DropdownItem
            itemName="Get help"
            iconName={IconName.support}
            onSelect={() => {
              toggleIntercom()
              setMenuOpen(false)
            }}
          />
          <DropdownItem
            itemName="Log Out"
            iconName={IconName.logout}
            onSelect={() => {
              navigate(pathTo('logout'))
              setMenuOpen(false)
            }}
          />
        </div>
      </Dropdown>
    </Flex>
  )
}

export { TopMenu }
