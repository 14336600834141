import { filterConditionsByType } from 'components/Filter/filter-conditions'
import { IFilterType } from 'components/Filter/types'
import { Select } from 'components/Select'

interface IProps {
  type: IFilterType
  value: string
  onChange: (value: string) => void
}

export const FiltersListCondition = ({ type, value, onChange }: IProps) => {
  return (
    <Select
      className="w-40"
      options={filterConditionsByType[type]}
      value={value}
      onChange={(option) => onChange(option.value as string)}
    />
  )
}
