import { useState } from 'react'
import { PersonType, Address } from 'types'
import { Borrower } from '../../../admin/services/api/borrowers'
import { BorrowerAddress } from '../../BorrowerAddress'
import { FormBorrowerEntity } from '../../FormBorrowerEntity'
import { FormBorrowerIndividual } from '../../FormBorrowerIndividual'
import { Modal } from '../index'
import ChooseType from './ChooseType'
import styles from './styles.module.scss'

interface ModalAddEmailProps {
  type?: PersonType
  include?: string[]
  saving: boolean
  onSave: (values: Omit<Borrower, 'id'> & { sendInvitation?: boolean }) => void
  onCancel: () => void
  personName?: string
}

type Stage = 'choose-type' | 'information' | 'addresses'
const getTitle = (stage: Stage, type: PersonType) => {
  switch (stage) {
    case 'choose-type':
      return 'Choose Type'
    case 'information':
      return type === 'individual'
        ? 'Personal Information'
        : 'Company Information'
    case 'addresses':
      return 'Addresses'
  }
}
const getStep = (stage: Stage) => {
  switch (stage) {
    case 'choose-type':
      return 0
    case 'information':
      return 1
    case 'addresses':
      return 2
  }
}

function ModalAddPerson({
  type: selectedType,
  include = [],
  saving,
  onSave,
  onCancel,
  personName,
}: ModalAddEmailProps) {
  const [stage, setStage] = useState<Stage>(
    selectedType ? 'information' : 'choose-type'
  )
  const [type, setType] = useState<PersonType>(selectedType || 'individual')
  const [details, setDetails] = useState<Borrower>()
  const [draftAddresses, setDraftAddresses] = useState<Address[]>()

  const handleNext = (data: any) => {
    if (stage === 'choose-type') {
      setType(data)
      setStage('information')
    } else if (stage === 'information') {
      setDetails({ ...data, status: data.sendInvitation ? 'invited' : 'draft' })
      setStage('addresses')
    } else {
      onSave({
        ...details,
        emails: details?.email
          ? [
              {
                email: details.email,
                primary: true,
              },
            ]
          : [],
        type,
        addresses: data.filter((address: Address) => address.street1),
      } as Borrower)
    }
  }
  const handleBack = (draftData?: any) => {
    if (stage === 'information') {
      setStage('choose-type')
    } else if (stage === 'addresses') {
      draftData && setDraftAddresses(draftData)
      setStage('information')
    }
  }

  return (
    <Modal
      title={getTitle(stage, type)}
      onClose={onCancel}
      className={styles.modal}
      step={
        stage !== 'choose-type'
          ? {
              step: getStep(stage),
              steps: 2,
            }
          : undefined
      }
      asChild
    >
      {stage === 'choose-type' && (
        <ChooseType
          type={type}
          onNext={(type: PersonType) => handleNext(type)}
          onCancel={onCancel}
        />
      )}
      {stage === 'information' && type === 'individual' && (
        <FormBorrowerIndividual
          borrower={details}
          include={['email', ...include]}
          saveText="Next"
          onBack={handleBack}
          onSave={(borrower: Borrower) => handleNext(borrower)}
          onCancel={onCancel}
          personName={personName}
        />
      )}
      {stage === 'information' && type === 'entity' && (
        <FormBorrowerEntity
          borrower={details}
          include={include}
          saveText="Next"
          onBack={handleBack}
          onSave={(borrower: Borrower) => handleNext(borrower)}
          onCancel={onCancel}
          personName={personName}
        />
      )}
      {stage === 'addresses' && (
        <BorrowerAddress
          saving={saving}
          onBack={handleBack}
          onSave={(addresses: Record<'primary' | 'mailing', Address>) => {
            handleNext([addresses.primary, addresses.mailing])
          }}
          onCancel={onCancel}
          addresses={draftAddresses}
        />
      )}
    </Modal>
  )
}

export default ModalAddPerson
