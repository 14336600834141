import { FundDistributionStatus } from 'types'
import { ValueOf } from 'utils/typescript'
import { Badge, BadgeProps } from './index'

interface StatusBadgeProps {
  status: FundDistributionStatus
}

const statusToColor: Record<
  FundDistributionStatus,
  ValueOf<Pick<BadgeProps, 'color'>>
> = {
  Draft: 'gray',
  Published: 'green',
}

export function DistributionStatusBadge({ status }: StatusBadgeProps) {
  return <Badge color={statusToColor[status]}>{status}</Badge>
}
