import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addLoanAddressValuation,
  updateLoanAddressValuation,
  deleteLoanAddressValuation,
} from 'admin/services/api/loan-address'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Valuation } from 'types/person'
import { message } from 'utils/message'

const useAddLoanAddressValuation = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (valuation: Partial<Valuation>) =>
      addLoanAddressValuation(loanId, addressId, valuation),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanAddressValuation = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (valuation: Partial<Valuation>) =>
      updateLoanAddressValuation(loanId, addressId, valuation),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanAddressValuation = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (valuationId: string) =>
      deleteLoanAddressValuation(loanId, addressId, valuationId),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddLoanAddressValuation,
  useUpdateLoanAddressValuation,
  useDeleteLoanAddressValuation,
}
