import { message } from 'utils/message'

interface Props {
  children: React.ReactNode
  text: string
  onCopy: () => void
}

function CopyToClipboard({ children, text, onCopy }: Props) {
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text)
      onCopy()
    } catch {
      message.error('Clipboard write failed')
    }
  }

  return <div onClick={handleCopyToClipboard}>{children}</div>
}

export { CopyToClipboard }
