import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, Field } from 'components/Form'
import { Grid } from 'components/Grid'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

const Schema = createScheme({
  name: required,
})

type FormValues = {
  name: string
}

interface Props {
  title: string
  template?: FormValues
  saving: boolean
  onSubmit: (values: FormValues) => void
  onCancel: () => void
}

function ModalTemplateName({
  title,
  template = { name: '' },
  saving,
  onSubmit,
  onCancel,
}: Props) {
  return (
    <Modal title={title} onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={template}
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                type="name"
                label="Name"
                name="name"
                placeholder="Enter name"
              />
            </Grid.Item>
            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalTemplateName }
