import { isArray } from 'lodash'
import { customSnakeCase } from '../keys'

const updateRequestTable = (
  params: Record<string, unknown> | undefined
): Record<string, unknown> | undefined => {
  if (params && isArray(params.sort)) {
    return {
      ...params,
      sort: [`${customSnakeCase(params.sort[0])} ${params.sort[1]}`],
    }
  }
  return params
}

export { updateRequestTable }
