import { Formik } from 'formik'
import { isEqual, omit } from 'lodash'
import { useState } from 'react'
import { Address } from 'types'
import { parseInitValues } from 'utils/form'
import { Button } from '../Button'
import { Checkbox } from '../Checkbox'
import { Form } from '../Form'
import { FormAddress } from '../FormAddress'
import { Grid } from '../Grid'
import { Header } from '../Header'
import { Icon, IconName } from '../Icon'
import { TextLink } from '../TextLink'
import styles from './styles.module.scss'

interface ModalBorrowerProps {
  addresses?: Address[]
  saving?: boolean
  saveText?: string
  onBack?: (draftData?: any) => void
  onSave: (addresses: Record<'primary' | 'mailing', Address>) => void
  onCancel: () => void
}

const formatAddresses = (values, sameAsPrimary) => ({
  primary: { ...values.primary, type: 'primary' },
  mailing: sameAsPrimary
    ? { ...values.primary, id: undefined, type: 'mailing' }
    : { ...values.mailing, type: 'mailing' },
})

function BorrowerAddress({
  addresses = [],
  saving,
  saveText = 'Save',
  onBack,
  onSave,
  onCancel,
}: ModalBorrowerProps) {
  const defaultAddress = {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: 'United States',
  }
  const primaryAddress =
    addresses.find(({ type }) => type === 'primary') || defaultAddress
  const mailingAddress =
    addresses.find(({ type }) => type === 'mailing') || defaultAddress
  const [sameAsPrimary, setSameAsPrimary] = useState(
    isEqual(
      omit(primaryAddress, 'id', 'type', 'createdAt', 'updatedAt', 'deletedAt'),
      omit(mailingAddress, 'id', 'type', 'createdAt', 'updatedAt', 'deletedAt')
    )
  )

  const handleSubmit = (values: Record<'primary' | 'mailing', Address>) => {
    onSave(formatAddresses(values, sameAsPrimary))
  }

  return (
    <Formik
      initialValues={{
        primary: parseInitValues(primaryAddress),
        mailing: parseInitValues(mailingAddress),
      }}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({ values }) => (
        <Form modal>
          <div>
            <Grid columnGap={24}>
              <Grid.Item xs={12}>
                <Header variant="h4" className={styles.subheader}>
                  Primary Address
                </Header>
              </Grid.Item>
              <Grid.Item xs={12}>
                <FormAddress prefix="primary" autoFocus />
              </Grid.Item>
              <Grid.Item xs={12}>
                <Header variant="h4" className={styles.subheader}>
                  Mailing Address
                </Header>
              </Grid.Item>
              <Grid.Item xs={12}>
                <Checkbox
                  label="Same as Primary Address"
                  checked={sameAsPrimary}
                  onChange={() => setSameAsPrimary(!sameAsPrimary)}
                  className={styles.field}
                />
              </Grid.Item>
              {!sameAsPrimary && (
                <Grid.Item xs={12}>
                  <FormAddress prefix="mailing" />
                </Grid.Item>
              )}
            </Grid>
          </div>
          <div className={styles.buttonsWithBack}>
            {onBack && (
              <TextLink
                variant="invert"
                onClick={() =>
                  onBack([
                    formatAddresses(values, sameAsPrimary).primary,
                    formatAddresses(values, sameAsPrimary).mailing,
                  ])
                }
              >
                <Icon name={IconName.arrowLeft} size="sm" />
                Back
              </TextLink>
            )}
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                {saveText}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default BorrowerAddress
