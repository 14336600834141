import { get } from 'lodash'
import { useCallback, useState } from 'react'
import { usePayouts } from 'admin/hooks/use-payout'
import { Alert } from 'components/Alert'
import { Flex } from 'components/Flex'
import { useSession } from 'hooks/use-session'
import { KEY_PENDING_PAYOUTS_ALERT } from 'services/storage/keys'

const AlertPendingPayouts = () => {
  const { user } = useSession()
  const settings = get(user, 'client.settings', {
    autoInvestorAccounting: undefined,
  })
  const isAutoInvestorAccounting = settings.autoInvestorAccounting === 'Yes'
  const [isDismissed, setIsDismissed] = useState(
    localStorage.getItem(KEY_PENDING_PAYOUTS_ALERT)
  )
  const handleDismiss = useCallback(() => {
    localStorage.setItem(KEY_PENDING_PAYOUTS_ALERT, '1')
    setIsDismissed('1')
  }, [])
  const { data: pendingPayouts } = usePayouts(
    {
      filter: {
        completed: [true],
        isPaidout: [false],
        ...(!isAutoInvestorAccounting ? { 'bankings.enabled': [true] } : {}),
      },
    },
    { enabled: !isDismissed }
  )

  return !isDismissed && pendingPayouts?.transactions?.length ? (
    <Alert severity="info" className="mb-4">
      <Flex
        className="w-full"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>
          <b>
            Awaiting confirmation of {pendingPayouts.transactions.length}{' '}
            {isAutoInvestorAccounting ? '' : 'manual '}
            payouts.
          </b>{' '}
          Confirm by marking the {isAutoInvestorAccounting
            ? ''
            : ' manual'}{' '}
          payouts as “Paid Out” once you receive confirmation from your bank.
        </div>
        <div
          className="font-bold cursor-pointer whitespace-nowrap"
          onClick={handleDismiss}
        >
          Got it
        </div>
      </Flex>
    </Alert>
  ) : null
}

export { AlertPendingPayouts }
