import { Formik } from 'formik'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import * as yup from 'yup'
import { fields } from 'admin/pages/Settings/Application/fields'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Field, Checkbox, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Modal } from 'components/Modal'
import { ApplicationSchemeField, BaseField, FieldType } from 'types'
import { createScheme, required } from 'utils/schemas'
import { CurrencyForm } from './CurrencyForm'
import { DecimalForm } from './DecimalForm'
import { DropdownForm } from './DropdownForm'
import { EmailForm } from './EmailForm'
import { NumberForm } from './NumberForm'
import { PercentageForm } from './PercentageForm'
import { SignatureForm } from './SignatureForm'
import { TextForm } from './TextForm'
import { YesNoForm } from './YesNoForm'

const Schema = createScheme({
  label: required,
  max: yup
    .number()
    .test(
      'greaterThanMin',
      'The value must be greater than or equal to the minimum value',
      (max, context) =>
        max && context.parent.min ? max >= context.parent.min : true
    ),
})

type FormValues = {
  label: string
  description: string
  required: boolean
  mapTo: string
  placeholder: string
  options?: any
}

interface Props {
  mapToFields: BaseField[]
  field: ApplicationSchemeField
  onSave: (values: FormValues) => void
  onCancel: () => void
}

const getFieldsByType = (type?: string, mapToField?: BaseField): ReactNode => {
  switch (type) {
    case 'text':
      return <TextForm />
    case 'option':
      return <DropdownForm mapToField={mapToField} />
    case 'email':
      return <EmailForm />
    case 'currency':
      return <CurrencyForm />
    case 'percentage':
      return <PercentageForm />
    case 'number':
      return <NumberForm />
    case 'decimal':
      return <DecimalForm />
    case 'yes-no':
      return <YesNoForm />
    case 'signature':
      return <SignatureForm />
  }
  return null
}

const getFieldValues = ({ type, ...field }: ApplicationSchemeField) => {
  switch (type) {
    case 'text':
      return {
        multiline: field.multiline || false,
      }
    case 'option':
      return {
        options: field.options || '',
        defaultValue: field.defaultValue || '',
      }
    case 'currency':
    case 'decimal':
    case 'number':
      return {
        allowMinMax: !!field.min || !!field.max || false,
        min: field.min || '',
        max: field.max || '',
      }
    case 'yes-no':
      return {
        defaultValue: field.defaultValue || '',
      }
    case 'signature':
      return {
        signatureConfirmation:
          field.signatureConfirmation ||
          'I agree with the terms and certify that the information provided is true and accurate.',
      }
  }
  return {}
}

function ModalCustomApplicationField({
  mapToFields,
  field,
  onSave,
  onCancel,
}: Props) {
  const [mapToField, setMapToField] = useState<BaseField>()
  const [initialValues, setInitialValues] = useState<FormValues>({
    label: field.label || '',
    description: field.description || '',
    required: field.required || false,
    mapTo: field.mapTo || '',
    placeholder: field.placeholder || '',
    ...getFieldValues(field),
  })
  const options = useMemo(
    () =>
      mapToFields
        .filter(({ type }) => {
          return type.includes(field.type as FieldType)
        })
        .map(({ id, section, name }) => ({
          value: id,
          label: section ? `${section} - ${name}` : name,
        })),
    [mapToFields]
  )
  const applicationField = useMemo(
    () => fields.find(({ type }) => field.type === type),
    [field.type]
  )
  const handleFormChange = useCallback((values: FormValues) => {
    setInitialValues({ ...values })
    if (values.mapTo) {
      const mapToField = mapToFields.find(({ id }) => id === values.mapTo)
      setMapToField(mapToField)
      if (mapToField?.type.includes('option')) {
        setInitialValues({
          ...values,
          options:
            mapToField.options?.map(({ value }) => value).join('\r\n') || '',
        })
      }
    } else {
      setMapToField(undefined)
    }
  }, [])

  useEffect(() => {
    setMapToField(mapToFields.find(({ id }) => id === field.mapTo))
  }, [])

  return (
    <Modal
      title={
        <Flex gap={12} alignItems="center">
          <img src={applicationField?.icon} />
          {applicationField?.name} Properties
        </Flex>
      }
      onClose={onCancel}
      className="w-modal"
      asChild
    >
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={onSave}
        enableReinitialize
        validateOnBlur={false}
      >
        <Form modal onChange={handleFormChange}>
          <Grid className="mt-4" columnGap={16}>
            <Grid.Item xs={12}>
              <Field
                label="Question"
                type="text"
                name="label"
                bottomHint="Type how you want the question to appear above the field."
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Field
                label="Description"
                type="text"
                name="description"
                bottomHint="An optional short description below the field"
              />
            </Grid.Item>
            <Grid.Item xs={12}>
              <Checkbox label="This a required field" name="required" />
            </Grid.Item>
            {getFieldsByType(field?.type, mapToField)}
            <Grid.Item xs={12}>
              <Select
                name="mapTo"
                label="Map to"
                options={[{ label: '(blank)', value: '' }, ...options]}
                portal
              />
            </Grid.Item>
          </Grid>
          <Flex justifyContent="flex-end" alignItems="center">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalCustomApplicationField }
