import { toString } from 'lodash'
import { PanelClasses as DefaultPanelClasses } from 'admin/components/PanelClasses/PanelClasses'
import {
  useAddOfferingClass,
  useDeleteOfferingClass,
  useEditOfferingClass,
  useOfferingClasses,
} from 'admin/hooks/use-offering-class'
import { Offering } from 'types'

interface Props {
  offering: Offering
}

function PanelClasses({ offering }: Props) {
  const { data, isPending } = useOfferingClasses(offering.id)
  const { mutateAsync: addClass } = useAddOfferingClass(offering.id)
  const { mutateAsync: editClass } = useEditOfferingClass(offering.id)
  const { mutateAsync: deleteClass } = useDeleteOfferingClass(offering.id)

  return (
    <DefaultPanelClasses
      isLoading={isPending}
      classes={data?.classes.map((cls) => ({ ...cls, editable: true }))}
      average={data?.weightedAverage}
      save={(classes) =>
        Promise.all(
          classes.map((cls) => {
            if (toString(cls.amount) === '0') {
              return cls.id ? deleteClass(cls.id as string) : Promise.resolve()
            }
            return cls.id ? editClass(cls) : addClass(cls)
          })
        )
      }
    />
  )
}

export { PanelClasses }
