import { useState, useEffect } from 'react'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { useAddBorrower } from 'admin/hooks/use-borrowers'
import { usePersons } from 'admin/hooks/use-persons'
import {
  TableAll,
  TableCompanies,
  TablePeople,
} from 'admin/pages/Borrowers/Table'
import { ModalAddVendor } from 'admin/pages/Vendors/ModalAddVendor/ModalAddVendor'
import { pathTo } from 'admin/path-to'
import { Borrower } from 'admin/services/api/borrowers'
import { Investor } from 'admin/services/api/investors'
import { NewVendor } from 'admin/services/api/vendors'
import { downloadPeople } from 'admin/services/csv/download-people'
import { Button } from 'components/Button'
import { Download } from 'components/Download'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { ModalAddPerson } from 'components/Modal/AddPerson'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { Filter, Person } from 'types'
import { EmptyContacts } from './EmptyContacts'

const getFilterByTab = (tab: 'all' | 'companies' | 'people') => {
  switch (tab) {
    case 'companies':
      return { type: ['entity'] }
    case 'people':
      return { type: ['individual'] }
    default:
      return {}
  }
}

function Contacts() {
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const tab = (searchParams.get('tab') || 'all') as
    | 'all'
    | 'companies'
    | 'people'
  const {
    visibleItems,
    result,
    search,
    sort,
    isEmpty,
    setSearch,
    setPagination,
    setSort,
    resetPagination,
    updateItem,
  } = usePagination<Person>({
    property: 'people',
    useData: (params) =>
      params.search
        ? usePersons({
            ...params,
            filter: {
              ...(params.filter || {}),
              ...getFilterByTab(tab),
            } as Filter,
          })
        : usePersons({
            ...params,
            filter: {
              isAccount: [true],
              ...getFilterByTab(tab),
            } as Filter,
          }),
  })

  const [addingBorrower, setAddingBorrower] = useState(false)
  const [addingInvestor, setAddingInvestor] = useState(false)
  const [addingVendor, setAddingVendor] = useState(false)

  const navigate = useNavigate()
  const handleRowClick = (borrower?: Borrower) => {
    if (borrower?.id) {
      navigate(
        pathTo(
          borrower.isBorrower
            ? 'borrower'
            : borrower.isInvestor
              ? 'investor'
              : 'vendor',
          borrower.id
        )
      )
    }
  }
  const add = useAddBorrower()

  useEffect(() => {
    setLoading(true)
    resetPagination()
  }, [resetPagination, tab])

  useEffect(() => {
    if (!result.isFetching) {
      setLoading(false)
    }
  }, [result.isFetching])

  const createButton = (
    <EllipsesActions
      trigger={
        <Button variant="primary">
          Add Contacts
          <Icon name={IconName.arrowDown} />
        </Button>
      }
    >
      <EllipsesActions.Item icon onSelect={() => setAddingBorrower(true)}>
        <Icon name={IconName.borrower} size="md" />
        Add Borrower
      </EllipsesActions.Item>
      <EllipsesActions.Item icon onSelect={() => setAddingInvestor(true)}>
        <Icon name={IconName.investor} size="md" />
        Add Investor
      </EllipsesActions.Item>
      <EllipsesActions.Item icon onSelect={() => setAddingVendor(true)}>
        <Icon name={IconName.vendor} size="md" />
        Add Vendor
      </EllipsesActions.Item>
    </EllipsesActions>
  )

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop title="Contacts" />
        {isEmpty ? (
          <EmptyContacts>{createButton}</EmptyContacts>
        ) : (
          <Panel>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              className="pb-4"
            >
              <Flex gap={8}>
                {[
                  { id: 'all', title: 'All' },
                  { id: 'companies', title: 'Companies' },
                  { id: 'people', title: 'People' },
                ].map(({ id, title }) => (
                  <Button
                    key={id}
                    active={tab === id}
                    variant="panel"
                    onClick={() =>
                      navigate({
                        pathname: pathTo('contacts'),
                        search: createSearchParams({ tab: id }).toString(),
                      })
                    }
                  >
                    {title}
                  </Button>
                ))}
              </Flex>
              <div className="flex justify-center gap-2.5 sm:justify-end">
                <Search search={search} onSearch={setSearch} />
                <Download
                  filename="contacts"
                  download={() =>
                    downloadPeople(sort, search, {
                      isAccount: [true],
                      ...getFilterByTab(tab),
                    } as Filter)
                  }
                />

                {createButton}
              </div>
            </Flex>

            {tab === 'all' && (
              <TableAll
                key="all"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                showType
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            {tab === 'companies' && (
              <TableCompanies
                key="companies"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                showType
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            {tab === 'people' && (
              <TablePeople
                key="people"
                data={loading ? [] : visibleItems}
                loading={loading}
                sort={sort}
                showType
                onClick={handleRowClick}
                onSort={setSort}
                onUpdateItem={updateItem}
              />
            )}
            <LoadMore
              loading={loading}
              fetching={result.isFetching}
              count={visibleItems.length}
              meta={result.data?.meta}
              onLoadMore={setPagination}
            />
          </Panel>
        )}
      </Flex>

      {addingBorrower ? (
        <ModalAddPerson
          saving={add.isPending}
          include={['sendInvitation', 'creditScore']}
          onSave={(
            borrower: Omit<Borrower, 'id'> & { sendInvitation?: boolean }
          ) =>
            add.mutate(borrower, {
              onSuccess: ({ id }) => {
                navigate(pathTo('borrower', id))
              },
            })
          }
          onCancel={() => {
            setAddingBorrower(false)
          }}
        />
      ) : null}

      {addingInvestor ? (
        <ModalAddPerson
          saving={add.isPending}
          onSave={(investor: Omit<Investor, 'id'>) => {
            add.mutate(investor, {
              onSuccess: ({ id }) => {
                navigate(pathTo('investor', id))
              },
            })
          }}
          onCancel={() => {
            setAddingInvestor(false)
          }}
        />
      ) : null}

      {addingVendor ? (
        <ModalAddVendor
          onSave={(vendor) => {
            add.mutate(vendor as NewVendor, {
              onSuccess: ({ id }) => {
                navigate(pathTo('vendor', id))
              },
            })
          }}
          onCancel={() => {
            setAddingVendor(false)
          }}
        />
      ) : null}
    </MainContent>
  )
}

export { Contacts }
