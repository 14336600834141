import clsx from 'clsx'
import { ReactNode, useMemo } from 'react'
import { useDrop } from 'react-dnd'
import { Flex } from 'components/Flex'
import { Text } from 'components/Text'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface ColumnProps {
  id: string
  title: string
  loans?: Loan[]
  children?: ReactNode
  onDrop: (id: string) => void
}

function Column({ id, title, loans, children, onDrop }: ColumnProps) {
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: 'item',
      canDrop: (item) => id !== item.columnId,
      drop: (item: { id: string; columnId: string }) => onDrop(item.id),
      collect: (monitor) => {
        return {
          isOver: monitor.canDrop() && monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    []
  )
  const amount = useMemo(
    () => sumDecimal(loans?.map(({ amount }) => amount)),
    [loans]
  )

  return (
    <div ref={drop} className={styles.column}>
      <Flex
        stack
        alignItems="flex-start"
        gap={8}
        className={styles.columnTitle}
      >
        <Text className="font-bold">{title}</Text>
        {loans?.length ? (
          <Text variant="s" className="text-grey-900 normal-nums">
            {loans?.length} total - {formatUsd(amount, { showZero: true })}
          </Text>
        ) : (
          <div />
        )}
      </Flex>
      <div className={clsx(styles.items, { [styles.over]: isOver })}>
        {children}
      </div>
    </div>
  )
}

export default Column
