import { uniqBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CommentEditor,
  clearHTML,
  isEmpty,
} from 'admin/components/InlineWysiwyg/CommentEditor'
import {
  useDocumentComments,
  useAddDocumentComment,
  commentsPerPage,
} from 'admin/hooks/use-document'
import {
  useAddLoanComment,
  useLoanComments,
} from 'admin/hooks/use-loan-comments'
import { useUsers } from 'admin/hooks/use-users'
import { Button } from 'components/Button'
import { Comments } from 'components/Comments'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { PageLoader } from 'components/LoaderOverlay'
import { Text } from 'components/Text'
import { useSession } from 'hooks/use-session'
import { CurrentUser } from 'services/api/session'
import { Comment } from 'types'
import styles from './styles.module.scss'

export function PanelComments({
  loanId,
  documentId,
  isLocked,
}: {
  loanId?: string
  documentId: string
  isLocked: boolean
}) {
  const { user } = useSession()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const userId = user?.admin?.id
  const [page, setPage] = useState(0)
  const [text, setText] = useState('')
  const [visibleComments, setVisibleComments] = useState<Comment[]>([])

  const { data: newComments, isLoading } = loanId
    ? useLoanComments(
        { loanId },
        {
          refetchInterval: 10000,
        }
      )
    : useDocumentComments(
        { documentId },
        {
          refetchInterval: 10000,
        }
      )
  const { data: previousComments, isFetching: isFetchingPreviousComments } =
    loanId
      ? useLoanComments(
          {
            loanId,
            params: { page, size: commentsPerPage },
          },
          { enabled: page > 0 }
        )
      : useDocumentComments(
          {
            documentId,
            params: { page, size: commentsPerPage },
          },
          { enabled: page > 0 }
        )

  const { mutate: comment, isPending: posting } = loanId
    ? useAddLoanComment(loanId)
    : useAddDocumentComment(documentId)
  const { data: users } = useUsers({
    user,
    clientId: (user as CurrentUser)?.client?.id,
  })

  const mentionUsers = useMemo(
    () =>
      users?.map(({ id, name, email }) => ({ id, value: name, email })) || [],
    [users]
  )
  const handleScroll = useCallback(
    (e) => {
      const hasScroll = e.target.scrollHeight > e.target.clientHeight
      const isScrollingToTop = e.target.scrollTop < 400
      const hasMore = (newComments?.total || 0) > visibleComments.length

      if (
        hasScroll &&
        isScrollingToTop &&
        !isFetchingPreviousComments &&
        hasMore
      ) {
        setPage((page) => page + 1)
      }
    },
    [isFetchingPreviousComments, visibleComments, newComments]
  )
  const handlePost = () => {
    if (!isEmpty(text)) {
      comment(clearHTML(text))
      setText('')
      messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
    }
  }
  useEffect(() => {
    const nextComments = uniqBy(
      [
        ...visibleComments,
        ...(newComments?.comments || []),
        ...(previousComments?.comments || []),
      ].sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      ),
      'id'
    )
    setVisibleComments(nextComments)
    if (visibleComments.length === 0 && nextComments.length > 0) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
      }, 1)
    } else {
      const lastScrollHeight = messagesEndRef.current?.scrollHeight || 0
      const lastScrollTop = messagesEndRef.current?.scrollTop || 0
      setTimeout(() => {
        const nextScrollHeight = messagesEndRef.current?.scrollHeight || 0
        if (lastScrollHeight === 0) {
          messagesEndRef.current?.scrollIntoView({ behavior: 'instant' })
        } else if (lastScrollHeight !== nextScrollHeight) {
          messagesEndRef.current?.scrollTo(
            0,
            nextScrollHeight + lastScrollTop - lastScrollHeight
          )
        }
      }, 1)
    }
  }, [newComments, previousComments])

  return (
    <div className={styles.commentsPanel}>
      <div className={styles.comments} onScroll={handleScroll}>
        {isLoading && <PageLoader />}
        {!isLoading && visibleComments?.length === 0 && (
          <div className={styles.noComments}>No comments yet</div>
        )}
        <Comments comments={visibleComments || []} userId={userId} />
        <div ref={messagesEndRef} />
      </div>
      <div className={styles.sendPanel}>
        <Flex stack gap={12} className="p-3 w-full">
          <Flex gap={4}>
            <Icon name={IconName.message} className="text-black-100" />
            <Text>Comment</Text>
          </Flex>
          <CommentEditor
            disabled={isLocked}
            users={mentionUsers}
            placeholder="Write a comment and @mention teammates."
            value={text}
            onChange={setText}
            onEnter={handlePost}
          />
          <Flex justifyContent="flex-end">
            <Button
              onClick={handlePost}
              loading={posting}
              variant={isEmpty(text) ? 'tertiary' : 'primary'}
              disabled={isEmpty(text)}
              className="p-2"
            >
              <Icon name={IconName.moveUp} />
            </Button>
          </Flex>
        </Flex>
      </div>
    </div>
  )
}
