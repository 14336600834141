import clsx from 'clsx'
import { ReactNode } from 'react'
import { Panel } from 'components/Panel'
import { Description } from './Description'
import { EmptyIcon } from './EmptyIcon'
import { Title } from './Title'

interface Props {
  className?: string
  children: ReactNode
}

export const EmptyView = ({ className, children }: Props) => {
  return (
    <Panel className={clsx('px-5 pb-20 pt-16', className)}>{children}</Panel>
  )
}

EmptyView.Icon = EmptyIcon
EmptyView.Title = Title
EmptyView.Description = Description
