import { TableInvestmentTransactions } from 'admin/components/table/TableInvestmentTransactions'
import { useFundInvestment } from 'admin/hooks/use-fund-investors'
import { MetaFields } from 'components/MetaFields'
import { Modal } from 'components/Modal'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  fundId: string
  fundInvestor: {
    name: string
    id: string
    investmentId: string
  }
  onCancel: () => void
}

function ModalInvestmentHistory({ fundId, fundInvestor, onCancel }: Props) {
  const { data, isPending } = useFundInvestment(
    fundId,
    fundInvestor.investmentId
  )
  const investor = data?.investor

  return (
    <Modal
      asChild
      loading={isPending}
      title={fundInvestor.name}
      onClose={onCancel}
      className={styles.modal}
      crossClose
    >
      <MetaFields
        fieldsClassName={styles.metaFields}
        fields={[
          {
            key: 'Class',
            value: investor?.fundInvestors.class || '-',
          },
          {
            key: 'Invested',
            value: `${formatUsd(investor?.fundInvestors.amount)} (${formatDate(
              investor?.fundInvestors.dateInvested
            )})`,
          },
          {
            key: 'Returned',
            value: formatUsd(investor?.capitalReturn),
          },
          {
            key: 'Balance',
            value: formatUsd(investor?.balance),
          },
          {
            key: 'Income',
            value: formatUsd(investor?.income),
          },
        ]}
      />
      <div className={styles.container}>
        <TableInvestmentTransactions
          data={data?.transactions.map((t) => ({
            ...t,
            amount: t.amount * -1,
          }))}
          loading={isPending}
        />
      </div>
    </Modal>
  )
}

export { ModalInvestmentHistory }
