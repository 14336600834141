import { addDays, parseISO, subDays } from 'date-fns'
import { Formik } from 'formik'
import { compact } from 'lodash'
import { useCallback, useMemo } from 'react'
import { DatePicker } from 'components/DatePicker'
import { Flex } from 'components/Flex'
import { Form, LoanField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { LoanModification } from 'types'
import {
  fieldsToInitialValues,
  getField,
  getFieldsBySection,
  valuesToFields,
} from 'utils/fields'

interface Props {
  previousModification: LoanModification | undefined
  nextModification: LoanModification | undefined
  modification: LoanModification
  onChange: (modification: LoanModification) => void
}

const StepFields = ({
  modification,
  previousModification,
  nextModification,
  onChange,
}: Props) => {
  const loanAmountField = useMemo(
    () => getField(modification.fields, 'loan-amount', true),
    [modification.fields]
  )
  const rateTypeField = useMemo(
    () => modification.fields.find(({ name }) => name === 'Rate Type'),
    [modification.fields]
  )
  const termsFields = useMemo(
    () =>
      compact(
        getFieldsBySection(modification.fields, 'Terms', 'servicing')
          .map((field) =>
            field.id === 'rate' ? [field, rateTypeField] : field
          )
          .flat()
      ),
    [modification.fields, rateTypeField]
  )
  const originationFields = useMemo(
    () =>
      getFieldsBySection(
        modification.fields,
        'Origination Details',
        'servicing'
      ).filter(({ id }) => !['loan-amount', 'origination-date'].includes(id)),
    [modification.fields]
  )
  const parametersFields = useMemo(
    () =>
      getFieldsBySection(modification.fields, 'Parameters', 'servicing').filter(
        ({ name }) => name !== 'Rate Type'
      ),
    [modification.fields]
  )
  const initialValue = fieldsToInitialValues(
    compact([
      loanAmountField,
      ...termsFields,
      ...originationFields,
      ...parametersFields,
    ])
  )
  const handleFormChange = useCallback(
    (values: Record<string, string>) => {
      onChange({
        ...modification,
        fields: valuesToFields(modification.fields, values),
      })
    },
    [modification, onChange]
  )

  return (
    <Flex stack gap={16}>
      <Panel>
        <Summary
          name={
            modification.isOrigination ? 'Origination Date' : 'Effective Date'
          }
        >
          <DatePicker
            minDate={
              previousModification?.dateEffective
                ? addDays(parseISO(previousModification.dateEffective), 1)
                : undefined
            }
            maxDate={
              nextModification?.dateEffective
                ? subDays(parseISO(nextModification.dateEffective), 1)
                : undefined
            }
            value={modification.dateEffective}
            onChange={(dateEffective) =>
              onChange({
                ...modification,
                dateEffective: dateEffective || modification.dateEffective,
              })
            }
          />
        </Summary>
      </Panel>
      <Panel className="pb-0">
        <Formik initialValues={initialValue} onSubmit={() => {}}>
          <Form onChange={handleFormChange}>
            <Flex stack gap={16} className="pt-1">
              <Header variant="h4">General Terms</Header>
              <Grid columnGap={16}>
                {loanAmountField && (
                  <Grid.Item key={loanAmountField.id} xs={12}>
                    <LoanField field={loanAmountField} />
                  </Grid.Item>
                )}
                {termsFields.map((field) => (
                  <Grid.Item key={field.id} xs={6}>
                    <LoanField
                      field={field}
                      disabled={field.viewOnly}
                      portal={false}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            </Flex>
            <div className="border-b border-0 border-solid border-grey-200 -mx-5 mb-5" />
            {modification.isOrigination && (
              <>
                <Flex stack gap={16} className="pt-1">
                  <Header variant="h4">Origination Details</Header>
                  <Grid columnGap={16}>
                    {originationFields.map((field) => (
                      <Grid.Item key={field.id} xs={6}>
                        <LoanField
                          field={field}
                          disabled={field.viewOnly}
                          portal={false}
                        />
                      </Grid.Item>
                    ))}
                  </Grid>
                </Flex>
                <div className="border-b border-0 border-solid border-grey-200 -mx-5 mb-5" />
              </>
            )}
            <Flex stack gap={16}>
              <Header variant="h4">Parameters</Header>
              <Grid columnGap={16}>
                {parametersFields.map((field) => (
                  <Grid.Item key={field.id} xs={6}>
                    <LoanField
                      field={field}
                      disabled={field.viewOnly}
                      portal={false}
                    />
                  </Grid.Item>
                ))}
              </Grid>
            </Flex>
          </Form>
        </Formik>
      </Panel>
    </Flex>
  )
}

export { StepFields }
