import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathToLoan } from 'admin/path-to'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { OfferingLoan } from 'types'
import { formatUsd } from 'utils/currency'

function TableOfferingLoans({
  data = [],
  loading,
  onDelete,
}: {
  data?: OfferingLoan[]
  loading: boolean
  onDelete: (loan: OfferingLoan) => void
}) {
  const navigate = useNavigate()
  const columns: ColumnDef<OfferingLoan>[] = useMemo(
    () => [
      {
        header: 'Loan',
        id: 'loanName',
        cell: ({ row }) => (
          <TextLink
            onClick={() =>
              navigate(pathToLoan(row.original.loan, 'loanFunding'))
            }
          >
            {row.original.loan?.name || ''}
          </TextLink>
        ),
      },
      {
        header: 'Funding Source',
        id: 'name',
        cell: ({ row }) =>
          row.original.fundingSource ? (
            <TextLink
              onClick={() =>
                navigate(pathToLoan(row.original.loan, 'loanFunding'))
              }
            >
              {row.original.fundingSource?.investor?.name || '-'}
            </TextLink>
          ) : (
            'New Funding'
          ),
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        cell: ({ getValue }) => formatUsd(getValue() as string),
        meta: {
          align: 'right',
        },
        size: 140,
      },
      {
        id: 'actions',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return <Table columns={columns} data={data} loading={loading} />
}

export default TableOfferingLoans
