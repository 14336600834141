import { QueryClient, useQuery, keepPreviousData } from '@tanstack/react-query'
import { size } from 'lodash'
import { getLoanInvestors } from 'admin/services/api/loan-investors'
import { KEY_LOAN_INVESTORS } from 'constants/query-keys'
import { Filter, Search } from 'types/table'

const fetchLoanInvestors = (
  queryClient: QueryClient,
  loanId: string,
  params: {
    filter?: Filter
    search?: Search
  }
) => {
  const nextFilter = size(params.filter) ? params.filter : undefined
  return queryClient.fetchQuery({
    queryKey: [KEY_LOAN_INVESTORS, loanId, params.search, nextFilter],
    queryFn: () =>
      getLoanInvestors(loanId, {
        search: params.search,
        filter: nextFilter,
      }),
  })
}

const useLoanInvestors = (loanId: string, options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: [KEY_LOAN_INVESTORS, loanId],
    queryFn: () => getLoanInvestors(loanId, {}),
    placeholderData: keepPreviousData,
    ...options,
  })
}

export { fetchLoanInvestors, useLoanInvestors }
