import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import styles from './styles.module.scss'

interface Props {
  onClick: () => void
}

const NoOptions = ({ onClick }: Props) => {
  return (
    <Flex stack gap={24} alignItems="center">
      <Flex stack gap={8} alignItems="center">
        <Header variant="h2">No options were available</Header>
        <div className={styles.alignCenter}>
          None of the sizers were able to find a<br />
          loan product that meets your requirements
        </div>
      </Flex>
      <div>
        <Button onClick={onClick}>Choose another type</Button>
      </div>
    </Flex>
  )
}

export { NoOptions }
