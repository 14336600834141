import clsx from 'clsx'
import { FieldProps } from 'formik'
import { isString } from 'lodash'
import { useEffect, useRef } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Field, Checkbox } from 'components/Form'
import { ApplicationSchemeField } from 'types'
import styles from './styles.module.scss'

interface Props {
  field: ApplicationSchemeField
  disabled?: boolean
}

export const SignatureField = ({ field: schemeField, disabled }: Props) => {
  const signature = useRef<SignatureCanvas | null>(null)

  useEffect(() => {
    if (
      schemeField.value &&
      isString(schemeField.value) &&
      signature.current?.isEmpty()
    ) {
      signature.current?.fromDataURL(schemeField.value)
    }
    if (disabled) {
      signature.current?.off()
    } else {
      signature.current?.on()
    }
  }, [disabled, signature.current, schemeField.value])

  return (
    <>
      <div className="mb-6 mt-4">
        <Checkbox
          name={`${schemeField.id}_checkbox`}
          label={schemeField.signatureConfirmation}
          disabled={disabled}
        />
      </div>
      <Field name={schemeField.id} label={schemeField.label}>
        {({ form, meta: { touched, error } }: FieldProps) => (
          <div key={schemeField.id} className={styles.signatureBlock}>
            <Flex stack gap={16}>
              <SignatureCanvas
                ref={(ref) => (signature.current = ref)}
                onEnd={() =>
                  form.setFieldValue(
                    schemeField.id,
                    signature.current?.toDataURL()
                  )
                }
                canvasProps={{
                  className: clsx(
                    styles.signature,
                    touched && error && 'border-red-100'
                  ),
                }}
              />
            </Flex>
            {!disabled && (
              <div className={styles.clear}>
                <Button
                  variant="tertiary"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    form.setFieldValue(schemeField.id, '')
                    signature.current?.clear()
                  }}
                >
                  Clear
                </Button>
              </div>
            )}
          </div>
        )}
      </Field>
    </>
  )
}
