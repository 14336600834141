import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { AutomationStatusBadge } from 'components/Badge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { IAutomationLog, Sort } from 'types'
import { friendlyDateTime } from 'utils/date'

type AutomationsLogTableProps = {
  data?: IAutomationLog[]
  loading: boolean
  sort?: Sort | undefined
  onClick: (log: any) => void
  onEdit: () => void
  onSort?: (sort: Sort | string | undefined) => void
  onDelete?: (id: string) => void
}

function TableAutomationsLog({
  data = [],
  loading,
  sort,
  onClick,
  onSort,
}: AutomationsLogTableProps) {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: 'Automation',
        accessorKey: 'name',
        cell: ({ row }) => {
          return (
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap={8}>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="bg-olive-25 text-olive-100 p-1.5 rounded"
                >
                  <Icon name={IconName.pipeline} />
                </Flex>
                <Icon name={IconName.arrowRightLong} />
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  className="bg-olive-25 text-green-100 p-1.5 rounded"
                >
                  <Icon name={IconName.task} />
                </Flex>
                {row.original.name}
              </Flex>
            </Flex>
          )
        },
      },
      {
        header: 'Actions',
        accessorKey: 'actions',
        cell: ({ row }) => {
          return row.original.actions?.length || 0
        },
      },
      {
        header: 'Date',
        accessorKey: 'createdAt',
        cell: ({ getValue }) => friendlyDateTime(getValue() as string),
      },
      {
        header: 'Status',
        id: 'status',
        size: 120,
        cell: ({ row }) => {
          const status = row.original.actions?.some(
            (action) => !action.config.success
          )
            ? 'failed'
            : 'success'
          return row.original.actions?.length ? (
            <AutomationStatusBadge status={status} />
          ) : (
            <></>
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onClick={onClick}
      onSort={onSort}
    />
  )
}

export { TableAutomationsLog }
