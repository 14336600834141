import {
  autoUpdate,
  flip,
  FloatingPortal,
  shift,
  offset,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  Placement,
} from '@floating-ui/react'
import clsx from 'clsx'
import { ReactElement, ReactNode, cloneElement, useState, memo } from 'react'
import styles from './styles.module.scss'

interface TooltipProps {
  content?: ReactNode
  className?: string
  placement?: Placement
  children: ReactElement
}

function Tooltip({
  children,
  className,
  placement = 'top',
  content,
}: TooltipProps) {
  const [open, setOpen] = useState(false)
  const { x, y, refs, context, strategy } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [shift(), flip(), offset(8)],
  })
  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ])

  return (
    <>
      {cloneElement(children, {
        role: 'tooltip',
        ...getReferenceProps({ ref: refs.setReference }),
      })}

      {open && !!content && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                zIndex: 1,
              },
            })}
            className={clsx(styles.tooltip, className)}
          >
            {content}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export default memo(Tooltip)
