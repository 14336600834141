import clsx from 'clsx'
import { useCallback, useState } from 'react'
import { Menu } from 'admin/pages/SettingsAutomations/ModalAutomation/Menu'
import { MenuTrigger } from 'admin/pages/SettingsAutomations/ModalAutomation/MenuTrigger'
import { Dropdown } from 'components/Dropdown'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { ITriggerConfig, ITriggerValue } from 'types'

interface Props {
  triggersConfig: ITriggerConfig[]
  rule?: ITriggerValue
  hasConditions: boolean
  onChange: (value: ITriggerValue | undefined) => void
}

export function Trigger({
  triggersConfig,
  rule,
  hasConditions,
  onChange,
}: Props) {
  const [open, setOpen] = useState(false)
  const handleSelect = useCallback(
    (value: ITriggerValue | undefined) => {
      onChange(value)
      setOpen(false)
    },
    [onChange]
  )

  return (
    <Flex stack gap={8}>
      <div>When any of the following occurs:</div>
      <Dropdown
        open={open}
        onOpenChange={setOpen}
        triggerFixedWidth
        trigger={
          !rule ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              gap={8}
              className="min-h-16 border border-solid border-grey-200 rounded font-bold cursor-pointer"
            >
              <Icon name={IconName.addCircle} />
              Add trigger
            </Flex>
          ) : (
            <MenuTrigger
              className={clsx(hasConditions && 'rounded-b-none')}
              itemConfig={
                (triggersConfig.find((config) => config.id === rule.id) ||
                  rule.config) as ITriggerConfig
              }
              value={rule}
              onDelete={() => {
                onChange(undefined)
              }}
            />
          )
        }
      >
        <Menu menuItems={triggersConfig} value={rule} onSelect={handleSelect} />
      </Dropdown>
    </Flex>
  )
}
