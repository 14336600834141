import { CellContext, ColumnDef } from '@tanstack/react-table'
import { compact, get } from 'lodash'
import { useMemo } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { useUpdateInvestorOwners } from 'admin/hooks/use-investors'
import { pathTo } from 'admin/path-to'
import { Investor } from 'admin/services/api/investors'
import PersonStatusBadge from 'components/Badge/PersonStatusBadge'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { OwnerSelector, useOwnerSelector } from 'components/OwnerSelector'
import { Table } from 'components/Table'
import { useSession } from 'hooks/use-session'
import { PersonStatus, Sort } from 'types'
import { formatUsd } from 'utils/currency'
import { formatRelativeDate } from 'utils/date'

function InvestorsTable({
  data = [],
  loading,
  sort,
  onClick,
  onSort,
  onUpdateItem,
}: {
  data?: Investor[]
  loading: boolean
  sort?: Sort | undefined
  onClick: (person?: Investor) => void
  onSort?: (sort: Sort | string | undefined) => void
  onUpdateItem: (investor: Investor) => void
}) {
  const { user } = useSession()
  const { options } = useOwnerSelector()
  const { mutate: updateOwners } = useUpdateInvestorOwners()
  const isCashColumnVisible = useMemo(
    () =>
      (get(user, 'client.settings.autoInvestorAccounting', 'No') as
        | 'Yes'
        | 'No') !== 'Yes',
    [user]
  )

  const columns: ColumnDef<Investor>[] = useMemo(
    () =>
      compact([
        {
          header: 'Name',
          accessorKey: 'name',
          cell: ({ row }) => {
            return (
              <Flex gap={8} alignItems="center">
                <div className="inline-flex items-center justify-center bg-grey-75 text-grey-600 w-6 h-6 rounded-sm flex-shrink-0">
                  <Icon
                    name={
                      row.original?.type === 'entity'
                        ? IconName.company
                        : IconName.person
                    }
                  />
                </div>
                <div className="truncate">{row.original.name}</div>
              </Flex>
            )
          },
          meta: {
            sortable: true,
          },
        },
        {
          header: 'Associated With',
          accessorKey: 'associatedWith',
          size: 204,
          cell: ({ row }) => {
            return (
              <AssociatedBadges
                associatedWith={row.original.associatedWith}
                getUrlById={(id: string) => pathTo('investor', id)}
              />
            )
          },
        },
        {
          header: 'Invested',
          accessorKey: 'investedBalance',
          cell: ({ getValue }) => formatUsd(getValue() as string),
          meta: {
            align: 'right',
            sortable: true,
          },
        },
        isCashColumnVisible
          ? {
              header: 'Cash',
              accessorKey: 'cashBalance',
              cell: ({ getValue }) => formatUsd(getValue() as string),
              meta: {
                align: 'right',
                sortable: true,
              },
            }
          : undefined,
        {
          header: 'Account Owner',
          accessorKey: 'owners',
          size: 120,
          cell: (cellContext) => {
            const { row, hover, active, onActive } = cellContext as CellContext<
              Investor,
              unknown
            > & {
              hover: boolean
              active: boolean
              onActive: (active: boolean) => void
            }
            const selectedOwners = row.original.owners || []
            const missedOptions =
              selectedOwners?.filter(({ id }) => {
                return !options.find((option) => option.id === id)
              }) || []

            return (
              <OwnerSelector
                variant="small"
                readOnly={!hover && !active}
                selectedUsers={row.original.owners?.map(({ id }) => id) || []}
                userOptions={[
                  ...options,
                  ...missedOptions.map((option) => ({ ...option, email: '-' })),
                ]}
                onChange={(owners) => {
                  updateOwners(
                    { id: row.original.id, owners },
                    { onSuccess: onUpdateItem }
                  )
                }}
                onOpen={onActive}
              />
            )
          },
        },
        {
          header: 'Last Activity',
          accessorKey: 'dateLastActivity',
          size: 120,
          cell: ({ row }) => {
            return row.original.dateLastActivity
              ? formatRelativeDate(row.original.dateLastActivity)
              : ''
          },
          meta: {
            sortable: true,
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          size: 90,
          cell: (cell) => {
            const status = cell.getValue() as PersonStatus
            return <PersonStatusBadge status={status} />
          },
        },
      ]),
    [options, isCashColumnVisible]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onClick={onClick}
      onSort={onSort}
    />
  )
}

export default InvestorsTable
