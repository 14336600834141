import { get } from 'lodash'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { NotificationSettings } from 'components/NotificationSettings'
import { Panel } from 'components/Panel'
import {
  useUpdateUserNotificationSettings,
  useUserNotificationSettings,
} from 'hooks/use-notification-settings'
import { useSession } from 'hooks/use-session'

function MyNotifications() {
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')
  const userId = get(user, 'admin.id', '')

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'My Account', link: pathTo('account') }}
          title="My Notifications"
        />
        <Panel className="max-w-panel">
          <NotificationSettings
            useNotificationSettings={() =>
              useUserNotificationSettings(clientId, userId)
            }
            useUpdateNotificationSettings={() =>
              useUpdateUserNotificationSettings(clientId, userId)
            }
          />
        </Panel>
      </Flex>
    </MainContent>
  )
}

export { MyNotifications }
