import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, LoanField } from 'components/Form'
import { Grid } from 'components/Grid'
import Modal from 'components/Modal/Modal'
import { Field } from 'types'
import { fieldsToInitialValues, valuesToFields } from 'utils/fields'

interface Props {
  fields: Field[]
  onSave: (values: Field[]) => void
  saving: boolean
  onCancel: () => void
}

function PersonCustomFields({ fields, onSave, saving, onCancel }: Props) {
  const initialValue = fieldsToInitialValues(fields)
  const handleSubmit = (values: Record<string, string>) => {
    onSave(valuesToFields(fields, values))
  }

  return (
    <Modal
      asChild
      title="Custom fields"
      onClose={onCancel}
      className="w-[564px]"
    >
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        <Form modal>
          <Grid columnGap={16}>
            {fields.map((field) => (
              <Grid.Item key={field.id} xs={6}>
                <LoanField field={field} />
              </Grid.Item>
            ))}
          </Grid>
          <div className="flex justify-end gap-4">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export { PersonCustomFields }
