import { ColumnDef } from '@tanstack/react-table'
import { isNil } from 'lodash'
import { useMemo } from 'react'
import { ServicingLoanStatusBadge } from 'components/Badge'
import { Table } from 'components/Table'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'

type Props = {
  data?: Loan[]
  loading: boolean
  onClick: (loan?: Loan) => void
}

export const TableActiveLoans = ({ data = [], loading, onClick }: Props) => {
  const columns: ColumnDef<Loan>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Maturity Date',
        accessorKey: 'dateMaturity',
        cell: ({ getValue }) => friendlyDate(getValue() as string),
      },
      {
        header: 'Principal Balance',
        accessorKey: 'amount',
        cell: ({ getValue }) => {
          const amount = getValue() as number | null
          return isNil(amount) ? '-' : formatUsd(amount)
        },
      },
      {
        header: 'Status',
        accessorKey: 'substatus',
        size: 180,
        cell: ({ row }) => {
          const { daysLate, daysPastDue, status, dateDefault } = row.original
          return (
            <ServicingLoanStatusBadge
              status={status}
              daysPastDue={daysPastDue}
              daysLate={daysLate}
              dateDefault={dateDefault}
            />
          )
        },
      },
    ],
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      minWidth={500}
    />
  )
}
