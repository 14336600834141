import styles from './styles.module.scss'

type Step = {
  step: number
  steps: number
}

interface Props {
  step: Step
}

function Progress({ step }: Props) {
  return (
    <div className={styles.progress}>
      <div
        className={styles.line}
        style={{
          width: `${(step.step / step.steps) * 100}%`,
        }}
      />
      <div className={styles.step}>
        Step {step.step} of {step.steps}
      </div>
    </div>
  )
}

export type { Step }
export default Progress
