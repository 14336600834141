import { orderBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { InlineEditGridItem } from 'admin/components/InlineEdit'
import { useUpdateFieldsByPersonId } from 'admin/hooks/use-person-fields'
import {
  PERSON_FIELD_CONTEXT_INDIVIDUAL,
  PERSON_FIELD_CONTEXT_ENTITY,
  PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
} from 'admin/pages/Settings/CustomFields/constants'
import { Grid } from 'components/Grid'
import { ModalPersonCustomFields } from 'components/Modal/PersonCustomFields'
import Panel from 'components/Panel/Panel'
import { Field, Person } from 'types'

interface Props {
  personRoleType: 'borrower' | 'investor' | 'vendor'
  person: Person
  fields?: Field[]
}

function PanelPersonCustom({ personRoleType, person, fields }: Props) {
  const {
    mutate: update,
    mutateAsync: updateAsync,
    isPending: updating,
  } = useUpdateFieldsByPersonId({
    personId: person.id,
    personType: personRoleType,
  })
  const [isModalVisible, setIsModalVisible] = useState(false)
  const filteredFields = useMemo(
    () =>
      orderBy(fields, 'order').filter(
        (field) =>
          (person.type === 'individual' &&
            [
              PERSON_FIELD_CONTEXT_INDIVIDUAL,
              PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
            ].includes(field.context)) ||
          (person.type === 'entity' &&
            [
              PERSON_FIELD_CONTEXT_ENTITY,
              PERSON_FIELD_CONTEXT_INDIVIDUAL_ENTITY,
            ].includes(field.context)) ||
          !field.context
      ) || [],
    [fields, person]
  )

  const handleSave = useCallback(
    (data: Field[]) => {
      update(data, { onSuccess: () => setIsModalVisible(false) })
    },
    [update]
  )

  return filteredFields.length ? (
    <Panel title="Custom" onEdit={() => setIsModalVisible(true)}>
      <Grid gap={24}>
        {filteredFields.map((field) => (
          <InlineEditGridItem
            key={field.id}
            field={field}
            save={(value) =>
              updateAsync([
                {
                  ...field,
                  property: {
                    ...field.property,
                    value: [value],
                  },
                },
              ])
            }
          />
        ))}
      </Grid>
      {isModalVisible && (
        <ModalPersonCustomFields
          fields={filteredFields}
          onSave={handleSave}
          saving={updating}
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  ) : null
}

export { PanelPersonCustom }
