import clsx from 'clsx'
import { NavLink } from 'react-router-dom'
import { Icon, IconName } from '../Icon'
import styles from './styles.module.scss'

interface SideNavigationLinkProps {
  icon: IconName
  to: string
  onClick?: () => void
  label: string
  collapsed?: boolean
  hideActive?: boolean
}

function SideNavigationLink({
  icon,
  to,
  onClick = () => {},
  collapsed,
  label,
  hideActive,
}: SideNavigationLinkProps) {
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(styles.link, {
          [styles.selected]: isActive && !hideActive,
        })
      }
      to={to}
      onClick={onClick}
    >
      <Icon name={icon} size={collapsed ? 'lg' : 'md'} />
      {collapsed ? '' : label}
    </NavLink>
  )
}

export type { SideNavigationLinkProps }
export default SideNavigationLink
