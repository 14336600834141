import clsx from 'clsx'
import { ReactElement } from 'react'
import { Flex } from 'components/Flex'
import Item, { ItemProps } from './Item'
import styles from './styles.module.scss'

interface Props {
  children: (ReactElement<ItemProps> | null)[] | ReactElement | null
}

function TabNavigation({ children }: Props) {
  return (
    <div className={styles.container}>
      <Flex className={clsx(styles.content)} alignItems="center" gap={0}>
        {children}
      </Flex>
    </div>
  )
}

TabNavigation.Item = Item

export default TabNavigation
