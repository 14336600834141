import { getLoanPayments } from 'services/api/loan-payment'
import { TO_BORROWER_PAYMENT_TYPE } from 'services/api/payment'
import { Row } from 'types'
import { sumDecimal } from 'utils/math'

const downloadLoanPayments = async (
  loanId: string,
  typeIncludeFilter?: string[],
  typeExcludeFilter?: string[]
): Promise<Row[]> => {
  const payments = await getLoanPayments(loanId)
  return payments
    .filter((payment) => {
      if (typeIncludeFilter) {
        return typeIncludeFilter.includes(payment.type)
      } else if (typeExcludeFilter) {
        return !typeExcludeFilter.includes(payment.type)
      }
    })
    .map((payment) => ({
      Date: payment.date,
      Type: payment.type,
      Status: payment.status,
      Principal: payment.distribution.principal,
      Interest: sumDecimal([
        payment.distribution.interest,
        payment.distribution.spread,
      ]),
      Charges: payment.distribution.charges,
      Trust:
        payment.type === TO_BORROWER_PAYMENT_TYPE
          ? payment.source.trusts * -1
          : payment.distribution.trusts,
    }))
}

export { downloadLoanPayments }
