import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import { KEY_REPORT, KEY_VENDORS } from 'constants/query-keys'
import {
  addVendorPayment,
  deleteVendorPayment,
  getVendorTransactions,
  VendorPayment,
} from 'services/api/vendor-payment'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination } from 'types'
import { message } from 'utils/message'

const useVendorTransactions = ({
  id,
  search,
  filter,
  pagination,
}: {
  id: string
  search?: string
  filter?: Filter
  pagination?: Pagination
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_VENDORS, id, 'transactions', search, nextFilter, pagination],
    queryFn: () =>
      getVendorTransactions({
        id,
        search,
        filter: nextFilter,
        page: pagination,
      }),
    placeholderData: keepPreviousData,
  })
}

const useAddVendorPayment = (vendorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (vendorPayment: VendorPayment) =>
      addVendorPayment(vendorId, vendorPayment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_VENDORS, vendorId, 'transactions'],
      })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${vendorId}/cash-balances`],
      })
      message.success('Transaction created')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteVendorPayment = (vendorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (paymentId: string) => deleteVendorPayment(paymentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_VENDORS, vendorId, 'transactions'],
      })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${vendorId}/cash-balances`],
      })
      message.success('Payment deleted')
    },
    onError: handleErrorResponse,
  })
}
export { useVendorTransactions, useAddVendorPayment, useDeleteVendorPayment }
