import { ReactNode, Children } from 'react'
import styles from './styles.module.scss'

interface ValueProps {
  children?: ReactNode
}

function Value({ children }: ValueProps) {
  const hasValue = Children.toArray(children).filter(Boolean).length > 0
  return <div className={styles.value}>{hasValue ? children : '-'}</div>
}

export type { ValueProps }
export default Value
