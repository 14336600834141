import { ColumnDef } from '@tanstack/react-table'
import { compact, camelCase } from 'lodash'
import { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo, pathToLoan } from 'admin/path-to'
import { LoanStatusBadge } from 'components/Badge'
import { Table } from 'components/Table'
import { LoanStatus } from 'constants/loan-status'
import { Sort, Loan, Trust } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'

interface Props {
  data?: Loan[]
  trusts?: Trust[]
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  loading?: boolean
}

function TableTrustBalance({
  data = [],
  trusts = [],
  sort,
  onSort,
  loading,
}: Props) {
  const columns: ColumnDef<Loan>[] = useMemo(
    () =>
      compact([
        {
          accessorKey: 'number',
          header: 'ID',
        },
        {
          id: 'loan',
          header: 'Loan',
          cell: ({ row }) => {
            const loan = row.original
            return loan ? (
              <NavLink to={pathToLoan(loan)} className="link">
                {loan.name}
              </NavLink>
            ) : (
              '-'
            )
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          size: 120,
          cell: ({ getValue }) => (
            <LoanStatusBadge status={getValue() as LoanStatus} />
          ),
        },
        {
          accessorKey: 'dateClosing',
          header: 'Origination',
          size: 140,
          cell: ({ getValue }) => formatDate(getValue() as string),
        },
        {
          id: 'borrower',
          header: 'Borrower',
          cell: ({ row }) => {
            const borrowers = row.original.borrowers || []
            return borrowers.length
              ? borrowers.map(({ id, name }) => (
                  <NavLink
                    key={id}
                    to={pathTo('borrower', id)}
                    className="link"
                  >
                    {name}
                  </NavLink>
                ))
              : '-'
          },
        },
        ...trusts.map(({ id, name }) => ({
          id: `trusts-${id}`,
          header: name,
          size: 120,
          cell: ({ row }) => formatUsd(row.original.trusts[camelCase(id)]),
          meta: {
            align: 'right',
          },
        })),
      ]),
    [trusts]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      sort={sort}
      onSort={onSort}
    />
  )
}

export default TableTrustBalance
