import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDeleteOffering } from 'admin/hooks/use-offerings'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { ModalDelete } from 'components/Modal/Delete'
import { Offering } from 'types'
import { GeneralControls } from './GeneralControls'
import { PanelClasses } from './PanelClasses'
import { PanelImage } from './PanelImage'
import { PanelOverview } from './PanelOverview'
import { PanelRisks } from './PanelRisks'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
}

function TabLoanGeneral({ offering }: Props) {
  const navigate = useNavigate()
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const { mutate: remove, isPending: isRemove } = useDeleteOffering()

  return (
    <div className={styles.tabContent}>
      <GeneralControls
        offering={offering}
        onDelete={() => setIsDeleteVisible(true)}
      />

      <Grid gap={16}>
        <Grid.Item md={12} lg={6}>
          <Flex stack gap={16}>
            <PanelOverview offering={offering} />
            <PanelImage
              offering={offering}
              header="Featured Image"
              description="Upload a featured image for your loan offering. Optimal image size is 1000px x 700px."
            />
          </Flex>
        </Grid.Item>
        <Grid.Item md={12} lg={6}>
          <Flex stack gap={16}>
            <PanelClasses offering={offering} />
            <PanelRisks offering={offering} />
          </Flex>
        </Grid.Item>
      </Grid>
      {isDeleteVisible && (
        <ModalDelete
          resource="offering"
          loading={isRemove}
          onDelete={() =>
            remove(offering.id, {
              onSuccess: () => {
                setIsDeleteVisible(false)
                navigate(pathTo('offerings'))
              },
            })
          }
          onCancel={() => setIsDeleteVisible(false)}
        />
      )}
    </div>
  )
}

export { TabLoanGeneral }
