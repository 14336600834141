import { generatePdf } from 'services/api/pdf'
import { message } from 'utils/message'

interface Props {
  url?: string
  fileName?: string
}

const downloadPdf = async ({ url, fileName }: Props = {}) => {
  const printUrl = url || window.location.pathname
  const dismissToast = message.progress('Generating PDF…', 20000)
  const pdfUrl = await generatePdf(printUrl)

  if (fileName) {
    const file = await fetch(pdfUrl)
    const blob = await file.blob()
    dismissToast()
    const namedPdfUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = namedPdfUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(namedPdfUrl)
  } else {
    dismissToast()
    window.location.href = pdfUrl
  }
}

export { downloadPdf }
