import { Row } from 'types'
import { formatDate } from 'utils/date'
import { getReport } from '../api/reports'

const downloadPaidCharges = async (params): Promise<Row[]> => {
  const { charges } = await getReport({ type: 'paid-charges', params })

  return charges.map((charge) => ({
    'Loan ID': charge.loan.number || charge.loan.loanId || charge.loan.id,
    'Loan Name': charge.loan.name,
    'Charge Date': formatDate(charge.dateCharged),
    Description: charge.name,
    'Payable To': [charge?.person?.companyName, charge?.person?.name].join(
      ' - '
    ),
    'Date Due': formatDate(charge.dateDue),
    Amount: charge.amount,
    Status: charge.amountDue === charge.amount ? 'Unpaid' : 'Paid',
  }))
}

export { downloadPaidCharges }
