import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import styles from './styles.module.scss'

const TooltipContent = () => (
  <div className={styles.hint}>
    <p className="mt-0">
      <b>Regular Payment</b> - This payment will change the Paid Through date
      and the Next Payment date.
    </p>
    <p>
      <b>Payoff</b> - This payment will mark the loan as Paid Off.
    </p>
    <p>
      <b>Special Payment</b> - Allows you to manually specify a Payment Through
      date to indicate the date until which the loan is Paid Through.
    </p>
    <p>
      <b>Other</b> - A type of payment that is used for any miscellaneous
      payments that do not change any dates on the loan.
    </p>
    <p>
      <b>To Borrower</b> - Used to send any payments from a Trust account to the
      Borrower.
    </p>
    <p className="mb-0">
      <b>Funding</b> - Increases the principal balance by releasing capital from
      from investors to the Borrower. This is primarily used in loans where
      interest is collected based on the amount of capital released to the
      Borrower. The dates on the loan will not be changed.
    </p>
  </div>
)

export const PaymentInfoTypeHint = () => (
  <Tooltip content={<TooltipContent />}>
    <Icon name={IconName.info} size="sm" className={styles.info} />
  </Tooltip>
)
