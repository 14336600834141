import clsx from 'clsx'
import { Flex } from 'components/Flex'
import { Panel } from 'components/Panel'
import { Radio } from 'components/Radio'
import { loanTypes, transactionTypes } from 'constants/loan-types'
import { Quote } from 'types'
import { Lenders } from './Lenders'
import styles from './styles.module.scss'

interface Props {
  loanType: Quote['loanType']
  setLoanType: (loanType: Quote['loanType']) => void
  transactionType: Quote['transactionType']
  setTransactionType: (transactionType: Quote['transactionType']) => void
  selectedLenders: string[]
  setSelectedLenders: (lenders: string[]) => void
}

export const LoanDetails = ({
  loanType,
  setLoanType,
  transactionType,
  setTransactionType,
  selectedLenders,
  setSelectedLenders,
}: Props) => {
  return (
    <div className={styles.panel}>
      <Panel title="Type">
        <Flex stack gap={24}>
          <Flex stack gap={16}>
            <div className="font-bold">Use of Funds</div>
            <Flex>
              {transactionTypes.map((type) => (
                <Radio
                  key={type}
                  name="transactionType"
                  label={type}
                  checked={transactionType === type}
                  onChange={() =>
                    setTransactionType(type as Quote['transactionType'])
                  }
                />
              ))}
            </Flex>
          </Flex>
          <Flex stack gap={16}>
            <div className="font-bold">Loan</div>
            <Flex flexWrap="wrap">
              {loanTypes.map(({ name, description }) => (
                <Flex
                  key={name}
                  stack
                  gap={8}
                  className={clsx(
                    styles.loanType,
                    name === loanType && styles.selected
                  )}
                  onClick={() => setLoanType(name as Quote['loanType'])}
                >
                  <div className="font-bold">{name}</div>
                  <div className={styles.loanTypeDescription}>
                    {description}
                  </div>
                </Flex>
              ))}
            </Flex>
          </Flex>
          <Lenders
            loanType={loanType}
            transactionType={transactionType}
            selectedLenders={selectedLenders}
            setSelectedLenders={setSelectedLenders}
          />
        </Flex>
      </Panel>
    </div>
  )
}
