import clsx from 'clsx'
import { useUpdateOffering } from 'admin/hooks/use-offerings'
import { Button } from 'components/Button'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Radio } from 'components/Radio'
import { Text } from 'components/Text'
import { Offering } from 'types'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  onDelete: () => void
}

const offeringStatusName: Record<Offering['status'], string> = {
  Draft: 'Draft',
  Published: 'Published',
}

const GeneralControls = ({ offering, onDelete }: Props) => {
  const { mutate: updateOffering, isPending } = useUpdateOffering(offering.id)

  return (
    <Flex alignItems="center" gap={10} className={styles.tabButton}>
      <EllipsesActions
        className="p-4"
        trigger={
          <div className="relative">
            <Button
              variant="secondary"
              loading={isPending}
              iconLeft={
                <Icon
                  name={IconName.indicator}
                  className={clsx({
                    'text-grey-600': offering.status === 'Draft',
                    'text-green-50': offering.status === 'Published',
                  })}
                />
              }
              iconRight={<Icon name={IconName.arrowDownFilled} />}
            >
              {offeringStatusName[offering.status]}
            </Button>
          </div>
        }
      >
        <EllipsesActions.Description>
          <Text variant="s" className="relative mb-3 text-grey-600 font-bold">
            OFFERING STATUS
          </Text>
        </EllipsesActions.Description>
        <EllipsesActions.Item
          icon
          onSelect={() => updateOffering({ status: 'Draft' })}
          className="h-auto rounded pt-2 pr-7 pb-4 pl-2"
        >
          <Flex>
            <Radio defaultChecked={offering.status === 'Draft'} />
            <Flex stack gap={4}>
              <Text className="text-grey-900 font-bold">Draft</Text>
              <Text className="text-grey-700">
                This offering is hidden from your investor portal
              </Text>
            </Flex>
          </Flex>
        </EllipsesActions.Item>
        <EllipsesActions.Item
          icon
          onSelect={() => updateOffering({ status: 'Published' })}
          className="h-auto rounded pt-2 pr-7 pb-4 pl-2"
        >
          <Flex>
            <Radio defaultChecked={offering.status === 'Published'} />
            <Flex stack gap={4}>
              <Text className="text-grey-900 font-bold">Published</Text>
              <Text className="text-grey-700">
                This offering is visible on your investor portal
              </Text>
            </Flex>
          </Flex>
        </EllipsesActions.Item>
      </EllipsesActions>

      <EllipsesActions
        trigger={
          <div className="relative">
            <Button icon variant="secondary">
              <Icon name={IconName.ellipses} size="md" />
            </Button>
          </div>
        }
      >
        <EllipsesActions.Item icon onSelect={onDelete} className="text-red-100">
          <Icon name={IconName.delete} />
          Delete
        </EllipsesActions.Item>
      </EllipsesActions>
    </Flex>
  )
}

export { GeneralControls }
