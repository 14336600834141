import { ColumnDef } from '@tanstack/react-table'
import { isSameDay } from 'date-fns'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { AssociatedBadges } from 'admin/components/AssociatedBadges'
import { Badge } from 'components/Badge'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { SentEmail } from 'types'
import { friendlyDateTime } from 'utils/date'
import { getEmailNames } from 'utils/email'

type Props = {
  data: SentEmail[]
  loading: boolean
  hasFilterUrl: boolean
  onRowClick: (sentEmail?: SentEmail) => void
}

const TableLoanEmails = ({
  data,
  loading,
  hasFilterUrl,
  onRowClick,
}: Props) => {
  const columns: ColumnDef<SentEmail>[] = useMemo(
    () =>
      compact([
        {
          header: 'To',
          accessorKey: 'to',
          size: 204,
          cell: ({ row }) => {
            const emailNames = getEmailNames(row.original.to)
            return (
              <AssociatedBadges
                associatedWith={emailNames.map((name) => ({ id: name, name }))}
              />
            )
          },
        },
        {
          header: 'Subject',
          accessorKey: 'subject',
          cell: ({ row }) => row.original.subject,
        },
        {
          header: 'Sent',
          accessorKey: 'createdAt',
          size: 120,
          cell: ({ row }) => {
            return (
              <Tooltip
                content={friendlyDateTime(
                  row.original.createdAt,
                  'EEEE MMM d, y @ h:mm aaa'
                )}
              >
                <div>
                  {isSameDay(row.original.createdAt, Date.now())
                    ? friendlyDateTime(row.original.createdAt, 'h:mm aaa')
                    : friendlyDateTime(row.original.createdAt, 'MMM d')}
                </div>
              </Tooltip>
            )
          },
        },
        {
          header: 'Status',
          accessorKey: 'status',
          size: 90,
          cell: () => {
            return <Badge color="gray">Sent</Badge>
          },
        },
      ]),
    []
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      noResultsEmptyScreen={hasFilterUrl}
      onClick={onRowClick}
    />
  )
}

export { TableLoanEmails }
