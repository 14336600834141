import useReactWebsocket, { ReadyState } from 'react-use-websocket'
import { getAuthTokens } from 'services/storage/auth'

type WebSocketMessageAutomation = {
  type: 'automation'
  data: {
    message: string
    action?: string
    success: boolean
    event_id: string
  }
}

type WebSocketMessageNotification = {
  type: 'notification'
  data: {
    id: string
    html: string
    path?: string
    by?: {
      id: string
      name: string
    }
  }
}

type WebSocketMessageInvalidate = {
  type: 'invalidate'
  data: string[][]
}

type WebSocketMessage =
  | WebSocketMessageAutomation
  | WebSocketMessageNotification
  | WebSocketMessageInvalidate

const useWebSocket = () => {
  const { accessToken } = getAuthTokens()

  const { lastJsonMessage, readyState } = useReactWebsocket(
    `${import.meta.env.VITE_APP_WS_URL}?auth=${accessToken}`,
    {
      shouldReconnect: () => true,
      reconnectAttempts: 10,
      reconnectInterval: 3000,
    }
  )

  return {
    lastMessage: lastJsonMessage as WebSocketMessage,
    isReady: readyState === ReadyState.OPEN,
  }
}

export { useWebSocket }
