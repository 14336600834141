import { useQuery, keepPreviousData } from '@tanstack/react-query'
import {
  getReport,
  getReportTrustTransactions,
} from 'admin/services/api/reports'
import { KEY_REPORT } from 'constants/query-keys'
import {
  Filter,
  Pagination,
  ReportChartType,
  ReportInsuranceType,
  ReportNumberType,
  ReportTrustBalanceType,
  ReportTax1098Type,
  ReportTax1099Type,
  Sort,
  ReportDrawRequestsType,
  ReportCashBalancesType,
  ReportUnpaidChargesType,
  ReportPaidChargesType,
  ReportUnpaidInterestType,
} from 'types'

interface Props {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  year?: string
}

const useReport = (type: ReportNumberType, options?: { enabled: boolean }) => {
  return useQuery({
    queryKey: [KEY_REPORT, type],
    queryFn: () => getReport({ type }),
    ...options,
  })
}

const useCashBalancesReport = (
  type: ReportCashBalancesType,
  data?: { date?: string }
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, data?.date],
    queryFn: () => getReport({ type, data }),
  })
}

const useLoanToBorrowerKpiReport = (
  loanId: string,
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_REPORT, 'payment-kpi', loanId],
    queryFn: () =>
      getReport({ type: 'loan-to_borrower-kpis', data: { loanId } }),
    ...options,
  })
}

const useChartReport = (
  type: ReportChartType,
  options?: { enabled: boolean }
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type],
    queryFn: () => getReport({ type }),
    ...options,
  })
}

const useReportInsurance = (
  type: ReportInsuranceType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

const useReportTax1098 = (
  type: ReportTax1098Type,
  { search, filter, pagination, sort, year }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort, year],
    queryFn: () =>
      getReport({
        type,
        params: { search, filter, page: pagination, sort },
        data: { year },
      }),
    placeholderData: keepPreviousData,
  })
}
const useReportTax1099 = (
  type: ReportTax1099Type,
  { search, filter, pagination, sort, year }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort, year],
    queryFn: () =>
      getReport({
        type,
        params: { search, filter, page: pagination, sort },
        data: { year },
      }),
    placeholderData: keepPreviousData,
  })
}

const useReportTrustBalance = (
  type: ReportTrustBalanceType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

const useReportTrustTransactions = (
  data: {
    loanId?: string | null
    trustId?: string
  },
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [
      KEY_REPORT,
      ...Object.values(data),
      'loan-trust-transactions',
      search,
      filter,
      pagination,
      sort,
    ],
    queryFn: () =>
      getReportTrustTransactions(data, {
        search,
        filter,
        page: pagination,
        sort,
      }),
    placeholderData: keepPreviousData,
    enabled: data.loanId !== undefined && data.loanId !== '',
  })
}

const useReportDrawRequests = (
  type: ReportDrawRequestsType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

const useReportUnpaidCharges = (
  type: ReportUnpaidChargesType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

const useReportPaidCharges = (
  type: ReportPaidChargesType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

const useReportUnpaidInterest = (
  type: ReportUnpaidInterestType,
  { search, filter, pagination, sort }: Props = {}
) => {
  return useQuery({
    queryKey: [KEY_REPORT, type, search, filter, pagination, sort],
    queryFn: () =>
      getReport({ type, params: { search, filter, page: pagination, sort } }),
    placeholderData: keepPreviousData,
  })
}

export {
  useReport,
  useCashBalancesReport,
  useChartReport,
  useReportInsurance,
  useReportTrustBalance,
  useReportTrustTransactions,
  useReportTax1098,
  useReportTax1099,
  useReportDrawRequests,
  useLoanToBorrowerKpiReport,
  useReportUnpaidCharges,
  useReportUnpaidInterest,
  useReportPaidCharges,
}
