import { LoanDocument } from 'types'
import { markdownToHtml } from 'utils/markdown'
import styles from './styles.module.scss'

interface Props {
  document: LoanDocument
}

export function PanelChecklist({ document }: Props) {
  const html = markdownToHtml(document.description)
  return html ? (
    <div
      className={styles.checklist}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  ) : (
    <div className={styles.noChecklist}>No checklist</div>
  )
}
