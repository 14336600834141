import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListNode, ListItemNode } from '@lexical/list'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode } from '@lexical/rich-text'
import { $getRoot } from 'lexical'
import { uniqueId } from 'lodash'
import { useCallback, useState } from 'react'
import { VariableNode } from 'admin/components/InlineWysiwyg/nodes/VariableNode'
import AutoLinkPlugin from './plugins/AutoLinkPlugin'
import FloatingLinkEditorPlugin from './plugins/FloatingLinkEditorPlugin'
import { ToolbarPlugin } from './plugins/ToolbarPlugin'
import styles from './styles.module.scss'

const theme = {
  link: 'editorLink',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  text: {
    bold: 'editorTextBold',
    italic: 'editorTextItalic',
    underline: 'editorTextUnderline',
  },
}

interface Props {
  value: string
  placeholder?: string
  onChange?: (html: string) => void
  onBlur?: () => void
}

function Editor({ value, placeholder, onChange, onBlur }: Props) {
  const [floatingAnchorElem, setFloatingAnchorElem] =
    useState<HTMLDivElement | null>(null)
  const initialConfig = {
    namespace: uniqueId(),
    theme,
    onError: () => {},
    editorState: (editor) => {
      const parser = new DOMParser()
      const dom = parser.parseFromString(value || '<p></p>', 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      $getRoot().append(...nodes)
    },
    nodes: [
      AutoLinkNode,
      LinkNode,
      ListNode,
      ListItemNode,
      HeadingNode,
      VariableNode,
    ],
  }
  const handleChange = useCallback(
    (editorState, editor) => {
      editor.update(() => {
        const htmlString = $generateHtmlFromNodes(editor, null)
        onChange?.(htmlString)
      })
    },
    [onChange]
  )
  const handleBlur = useCallback(() => onBlur?.(), [onBlur])

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className={styles.editorContainer}>
        <div className={styles.editorInner}>
          <RichTextPlugin
            contentEditable={
              <div className={styles.editorContent} ref={onRef}>
                <ContentEditable
                  data-placeholder={placeholder}
                  className={styles.editorInput}
                />
              </div>
            }
            placeholder={<></>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <OnChangePlugin onChange={handleChange} />
          <HistoryPlugin />
          <AutoLinkPlugin />
          <LinkPlugin />
          <ListPlugin />
          {floatingAnchorElem && (
            <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem} />
          )}
        </div>
        <ToolbarPlugin onSave={handleBlur} />
      </div>
    </LexicalComposer>
  )
}

export { Editor }
