import { useState } from 'react'
import {
  useUpdateLoanPayment,
  useDeleteLoanPayment,
} from 'admin/hooks/use-loan-payment'
import { Alert } from 'components/Alert'
import { filterValueToTableFilter, IFilterValue } from 'components/Filter'
import { Flex } from 'components/Flex'
import { LoadMore } from 'components/LoadMore'
import { ModalConfirm } from 'components/Modal/Confirm'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalPaymentDetails } from 'components/Modal/PaymentDetails'
import { TablePayments } from 'components/TablePayments'
import { usePagination } from 'hooks/use-pagination'
import { usePayments } from 'hooks/use-payment'
import { Payment } from 'types'
import { formatUsd } from 'utils/currency'

interface Props {
  filtersValue?: IFilterValue
}

export default function PanelPayments({ filtersValue }: Props) {
  const [viewPayment, setViewPayment] = useState<Payment>()
  const [removingPayment, setRemovingPayment] = useState<Payment>()
  const [reversePayment, setReversePayment] = useState<Partial<Payment>>()
  const { mutate: update, isPending: updating } = useUpdateLoanPayment()
  const { mutate: remove, isPending: removing } = useDeleteLoanPayment()
  const {
    visibleItems: payments,
    result,
    setPagination,
  } = usePagination<Payment>({
    property: 'payments',
    useData: (params) =>
      usePayments({
        ...params,
        filter: filterValueToTableFilter([
          ...(filtersValue as IFilterValue),
          { id: 'type', condition: 'ne', value: 'Transfer' },
        ]),
      }),
  })

  return (
    <>
      <TablePayments
        loading={result.isPending}
        data={payments}
        showLoans
        showDistributions
        onClickRow={(payment) => setViewPayment(payment)}
        onEdit={(payment, data) => {
          if (data.status === 'reversed') {
            setReversePayment({ ...payment, ...data })
          } else {
            update({ ...payment, ...data })
          }
        }}
        onDelete={(payment) => setRemovingPayment(payment)}
      />
      <LoadMore
        loading={result.isPending}
        count={payments.length}
        meta={result.data?.meta}
        onLoadMore={setPagination}
      />
      {viewPayment && (
        <ModalPaymentDetails
          loanId={viewPayment.loanId}
          paymentId={viewPayment.id}
          onCancel={() => setViewPayment(undefined)}
        />
      )}
      {reversePayment && (
        <ModalConfirm
          title="Reverse payment"
          text={
            <Flex stack gap={16}>
              Are you sure you want to reverse this payment?
              {reversePayment.meta?.usio ? (
                <Alert severity="warning">
                  Note: The borrower will be credited the payment amount of{' '}
                  <b>{formatUsd(reversePayment.amount)}</b>
                </Alert>
              ) : null}
            </Flex>
          }
          loading={updating}
          onConfirm={() => {
            update(reversePayment, {
              onSuccess: () => {
                setReversePayment(undefined)
              },
            })
          }}
          onCancel={() => setReversePayment(undefined)}
        />
      )}
      {removingPayment && (
        <ModalDelete
          resource="payment"
          loading={removing}
          onDelete={() =>
            remove(removingPayment, {
              onSuccess: () => setRemovingPayment(undefined),
            })
          }
          onCancel={() => setRemovingPayment(undefined)}
        />
      )}
    </>
  )
}
