import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { BadgeBudgetStatus } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { Tooltip } from 'components/Tooltip'
import { BudgetItem } from 'types'
import { formatUsd } from 'utils/currency'
import styles from './styles.module.scss'

interface Props {
  data?: BudgetItem[]
  onRemove?: (item: BudgetItem) => void
  onEdit?: (item: BudgetItem) => void
  onAccept?: (item: BudgetItem) => void
  onReject?: (item: BudgetItem, message?: string) => void
}

const rejectReasons = [
  'Budget details incomplete',
  'Amounts mismatch with project scope',
  'Ambiguous or unclear line items',
  'Overestimated expenses',
  'Other',
]

function TableRehabBudget({
  data = [],
  onRemove,
  onEdit,
  onAccept,
  onReject,
}: Props) {
  const columns: ColumnDef<BudgetItem>[] = useMemo(
    () => [
      {
        header: 'Work To Be Done',
        accessorKey: 'name',
        size: 200,
      },
      {
        header: 'Description',
        accessorKey: 'description',
      },
      {
        header: 'Scale',
        accessorKey: 'scale',
        size: 120,
      },
      {
        header: 'Amount',
        accessorKey: 'amount',
        size: 120,
        cell: (cell) => formatUsd(cell.getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => {
          if (row.original.status === 'Rejected' && row.original.reason) {
            return (
              <Tooltip content={row.original.reason}>
                <span>
                  <BadgeBudgetStatus
                    status={row.original.status}
                    reason={row.original.reason}
                  />
                </span>
              </Tooltip>
            )
          }

          return <BadgeBudgetStatus status={row.original.status} />
        },
        size: 100,
      },
      {
        id: 'actions',
        header: '',
        size: 32,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              {onAccept ? (
                <EllipsesActions.Item
                  icon
                  onSelect={() => onAccept(row.original)}
                >
                  <Icon name={IconName.check} />
                  Accept
                </EllipsesActions.Item>
              ) : (
                <></>
              )}
              {onReject ? (
                <EllipsesActions.MenuItem
                  label={
                    <Flex gap={6} alignItems="center">
                      <Icon name={IconName.close} />
                      Reject
                    </Flex>
                  }
                >
                  {rejectReasons.map((reason) => (
                    <EllipsesActions.Item
                      key={reason}
                      onSelect={() => onReject(row.original, reason)}
                    >
                      {reason}
                    </EllipsesActions.Item>
                  ))}
                </EllipsesActions.MenuItem>
              ) : (
                <></>
              )}
              <EllipsesActions.Item
                icon
                disabled={!onEdit}
                onSelect={() => onEdit && onEdit(row.original)}
              >
                <Icon name={IconName.edit} />
                Edit
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                disabled={!onRemove}
                onSelect={() => onRemove && onRemove(row.original)}
                variant="danger"
              >
                <Icon name={IconName.delete} />
                Remove
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    []
  )

  return <Table className={styles.table} columns={columns} data={data} />
}

export default TableRehabBudget
