import { request } from 'services/request'
import { Role } from 'types'

const getRoles = async (): Promise<Role[]> => {
  const {
    data: { roles },
  } = await request.get('/role')
  return roles
}

const addRole = async (payload: Partial<Role>): Promise<Role> => {
  const {
    data: { role },
  } = await request.post('/role', payload)
  return role
}

const updateRole = async (payload: Partial<Role>): Promise<Role> => {
  const {
    data: { role },
  } = await request.patch(`/role/${payload.id}`, payload)
  return role
}

const deleteRole = async ({
  roleId,
}: {
  roleId: string
}): Promise<{ success: boolean }> => {
  return request.delete(`/role/${roleId}`)
}

export type { Role }

export { getRoles, addRole, updateRole, deleteRole }
