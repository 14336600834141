import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { size } from 'lodash'
import { KEY_PAYMENTS } from 'constants/query-keys'
import { getPayments } from 'services/api/payment'
import { Filter, Pagination } from 'types/table'

const usePayments = ({
  search,
  filter,
  pagination,
  full,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  full?: boolean
}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_PAYMENTS, search, nextFilter, pagination, full],
    queryFn: () =>
      getPayments({ filter: nextFilter, page: pagination, full, search }),
    placeholderData: keepPreviousData,
  })
}

export { usePayments }
