import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  addFundInvestor,
  editFundInvestor,
  getFundInvestors,
  getFundInvestment,
  deleteFundInvestment,
} from 'admin/services/api/fund-investors'
import { KEY_FUNDS, KEY_REPORT } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { FundInvestor } from 'types'
import { message } from 'utils/message'

const useFundInvestors = (id: string) => {
  return useQuery({
    queryKey: [KEY_FUNDS, id, 'investors'],
    queryFn: () => getFundInvestors(id),
  })
}

const useFundInvestment = (id: string, investmentId: string) => {
  return useQuery({
    queryKey: [KEY_FUNDS, id, investmentId, 'investment'],
    queryFn: () => getFundInvestment(id, investmentId),
  })
}

const useAddFundInvestor = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (investor: Partial<FundInvestor['fundInvestors']>) =>
      addFundInvestor(id, investor),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS, id] })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${id}/cash-balances`],
      })
      message.success('Funding added')
    },
    onError: handleErrorResponse,
  })
}

const useEditFundInvestor = (id: string, fundInvestorId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (investor: Partial<FundInvestor['fundInvestors']>) =>
      editFundInvestor(id, fundInvestorId, investor),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_FUNDS, id] })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${id}/cash-balances`],
      })
      message.success('Funding saved')
    },
    onError: handleErrorResponse,
  })
}

const useDeleteFundInvestment = (fundId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (investmentId: string) =>
      deleteFundInvestment(fundId, investmentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_FUNDS, fundId],
      })
      queryClient.invalidateQueries({
        queryKey: [KEY_REPORT, `investor/${fundId}/cash-balances`],
      })
      message.success('Funding deleted')
    },
    onError: handleErrorResponse,
  })
}

export {
  useFundInvestors,
  useFundInvestment,
  useAddFundInvestor,
  useDeleteFundInvestment,
  useEditFundInvestor,
}
