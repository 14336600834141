import clsx from 'clsx'
import { addDays, format, parseISO, subDays, differenceInDays } from 'date-fns'
import { compact, isArray, cloneDeep, omit } from 'lodash'
import { useMemo, useState, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainContent } from 'admin/components/layout/MainContent'
import {
  useAddLoanSpread,
  useDeleteLoanSpread,
  useLoanSpreads,
  useUpdateLoanSpread,
  useInvalidateLoanSpreads,
  useLoanSpreadCurrent,
} from 'admin/hooks/use-loan-spread'
import { Phases } from 'admin/pages/LoanSpreadAllocation/Phases'
import { Recipients } from 'admin/pages/LoanSpreadAllocation/Recipients'
import { SpreadBar } from 'admin/pages/LoanSpreadAllocation/SpreadBar'
import { pathToLoan } from 'admin/path-to'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DatePicker'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { IconInput } from 'components/IconInput'
import { PageLoader } from 'components/LoaderOverlay'
import { Panel } from 'components/Panel'
import { useLoan } from 'hooks/use-loans'
import { Loan, Phase } from 'types'
import { friendlyDate } from 'utils/date'
import { sumDecimal } from 'utils/math'
import { formatPercent } from 'utils/percent'
import { SelectRecipient } from './SelectRecipient'

const LoanSpreadAllocation = () => {
  const { id: loanId } = useParams() as { id: string }
  const { data: loan } = useLoan({ id: loanId })
  const { data: spread } = useLoanSpreads(loanId)
  const { data: currentSpread } = useLoanSpreadCurrent(loanId)
  const { mutateAsync: addPhase } = useAddLoanSpread(loanId)
  const { mutateAsync: updatePhase } = useUpdateLoanSpread(loanId)
  const { mutateAsync: deletePhase } = useDeleteLoanSpread(loanId)
  const invalidateLoanSpreads = useInvalidateLoanSpreads(loanId)
  const [saving, setSaving] = useState(false)
  const [deletedPhaseIds, setDeletedPhaseIds] = useState<string[]>([])
  const [selectedPhaseIndex, setSelectedPhaseIndex] = useState(0)
  const [phases, setPhases] = useState<Phase[]>([])
  const navigate = useNavigate()
  const breadcrumbs = useMemo(
    () => ({
      title: loan?.name as string,
      link: loan ? pathToLoan(loan as Loan) : '',
    }),
    [loan]
  )
  const selectedPhase = useMemo(
    () => phases[selectedPhaseIndex] || phases[0],
    [phases, selectedPhaseIndex]
  )
  const previousPhase = useMemo(
    () => phases[selectedPhaseIndex - 1],
    [phases, selectedPhaseIndex]
  )
  const nextPhase = useMemo(
    () => phases[selectedPhaseIndex + 1],
    [phases, selectedPhaseIndex]
  )
  const assignedPercent = useMemo(
    () => sumDecimal(selectedPhase?.people.map(({ percentage }) => percentage)),
    [selectedPhase]
  )
  const handleAddPhase = useCallback(() => {
    const lastPhase = phases[phases.length - 1]
    const lastEffectiveDate =
      phases.length > 1
        ? (lastPhase.dateEffective[0] as string)
        : format(subDays(new Date(), 1), 'yyyy-MM-dd')
    const nexPhases: Phase[] = [
      ...phases.slice(0, -1),
      {
        ...lastPhase,
        dateEffective: [lastPhase.dateEffective[0], lastEffectiveDate],
      },
      {
        ...cloneDeep(omit(lastPhase, 'id')),
        dateEffective: [
          format(addDays(parseISO(lastEffectiveDate), 1), 'yyyy-MM-dd'),
          null,
        ],
      },
    ]
    setPhases(nexPhases)
    setSelectedPhaseIndex(nexPhases.length - 1)
  }, [phases])
  const handleDeletePhase = useCallback(
    (index: number) => {
      setDeletedPhaseIds(
        compact([...deletedPhaseIds, phases[index].id as string])
      )
      if (index === selectedPhaseIndex) {
        setSelectedPhaseIndex(0)
      }
      if (index === 0) {
        setPhases(
          phases
            .map((phase, i) => ({
              ...phase,
              dateEffective: [
                i === 1 ? undefined : phase.dateEffective[0],
                phase.dateEffective[1],
              ] as [string | null, string | null],
            }))
            .filter((_, i) => i !== index)
        )
      } else if (index === phases.length - 1) {
        setPhases(
          phases
            .map((phase, i) => ({
              ...phase,
              dateEffective: [
                phase.dateEffective[0],
                i === phases.length - 2 ? null : phase.dateEffective[1],
              ] as [string | null, string | null],
            }))
            .filter((_, i) => i !== index)
        )
      } else {
        setPhases(
          phases
            .map((phase, i) => ({
              ...phase,
              dateEffective: [
                phase.dateEffective[0],
                i === index - 1
                  ? phases[index].dateEffective[1]
                  : phase.dateEffective[1],
              ] as [string | null, string | null],
            }))
            .filter((_, i) => i !== index)
        )
      }
    },
    [phases, deletedPhaseIds, selectedPhaseIndex]
  )
  const handleChangeDefaultRecipient = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      setPhases(
        phases.map((phase, i) => ({
          ...phase,
          defaultRecipient: { id, name },
          defaultRecipientId:
            i === selectedPhaseIndex ? id : phase.defaultRecipientId,
        }))
      )
    },
    [phases, selectedPhaseIndex]
  )
  const handleFromChange = useCallback(
    (date: string) => {
      const diff = differenceInDays(
        date,
        selectedPhase.dateEffective[0] as string
      )

      setPhases(
        phases.map((phase, i) => {
          if (i === selectedPhaseIndex - 1) {
            return {
              ...phase,
              dateEffective: [
                phase.dateEffective[0],
                format(
                  addDays(parseISO(phase.dateEffective[1] as string), diff),
                  'yyyy-MM-dd'
                ),
              ],
            }
          }
          if (i === selectedPhaseIndex) {
            return {
              ...phase,
              dateEffective: [date, phase.dateEffective[1]],
            }
          }
          return phase
        })
      )
    },
    [phases, selectedPhaseIndex, selectedPhase]
  )
  const handleToChange = useCallback(
    (date: string) => {
      const diff = differenceInDays(
        date,
        selectedPhase.dateEffective[1] as string
      )

      setPhases(
        phases.map((phase, i) => {
          if (i === selectedPhaseIndex) {
            return {
              ...phase,
              dateEffective: [phase.dateEffective[0], date],
            }
          }
          if (i === selectedPhaseIndex + 1) {
            return {
              ...phase,
              dateEffective: [
                format(
                  addDays(parseISO(phase.dateEffective[0] as string), diff),
                  'yyyy-MM-dd'
                ),
                phase.dateEffective[1],
              ],
            }
          }
          return phase
        })
      )
    },
    [phases, selectedPhaseIndex, selectedPhase]
  )
  const handleUpdateRecipient = useCallback(
    (id: string, percent: number) => {
      setPhases(
        phases.map((phase, phaseIndex) => ({
          ...phase,
          people:
            selectedPhaseIndex === phaseIndex
              ? phase.people.map((person) =>
                  person.id === id ? { ...person, percentage: percent } : person
                )
              : phase.people,
        }))
      )
    },
    [phases, selectedPhaseIndex]
  )
  const handleAddRecipient = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      setPhases(
        phases.map((phase, phaseIndex) => ({
          ...phase,
          people:
            phaseIndex === selectedPhaseIndex
              ? [
                  ...phase.people,
                  {
                    id,
                    name,
                    percentage: 0,
                  },
                ]
              : phase.people,
        }))
      )
    },
    [phases, selectedPhaseIndex]
  )
  const handleDeleteRecipient = useCallback(
    (id: string) => {
      setPhases(
        phases.map((phase, phaseIndex) => ({
          ...phase,
          people:
            phaseIndex === selectedPhaseIndex
              ? phase.people.filter((person) => person.id !== id)
              : phase.people,
        }))
      )
    },
    [phases, selectedPhaseIndex]
  )

  const handleSave = useCallback(async () => {
    setSaving(true)
    await Promise.all([
      ...phases.map((phase) => {
        if (phase.id) {
          return updatePhase(phase)
        }
        return addPhase(phase)
      }),
      ...deletedPhaseIds.map((id) => deletePhase(id)),
    ])
    await invalidateLoanSpreads()
    setDeletedPhaseIds([])
    setSaving(false)
    navigate(pathToLoan(loan as Loan))
  }, [loan, loanId, phases, deletedPhaseIds])

  useEffect(() => {
    if (isArray(spread) && currentSpread) {
      setPhases(
        spread.length
          ? (spread as Phase[]).map((phase) => ({
              ...phase,
              people: phase.people || [],
            }))
          : [
              {
                id: undefined,
                dateEffective: [null, null],
                defaultRecipientId: currentSpread.defaultRecipientId,
                defaultRecipient: currentSpread.defaultRecipient,
                people: [],
              },
            ]
      )
    }
  }, [loan, spread, currentSpread])

  return loan && phases.length ? (
    <Flex stack gap={32} className="flex-grow w-full bg-grey-50">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="bg-white-100 border-solid border-grey-200 border-0 border-b p-4 top-0 z-1 sm:px-16"
      >
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <Flex gap={8}>
          <Button
            variant="tertiary"
            onClick={() => navigate(pathToLoan(loan as Loan))}
          >
            Cancel
          </Button>
          <Button variant="primary" loading={saving} onClick={handleSave}>
            Save
          </Button>
        </Flex>
      </Flex>
      <div className="h-full py-0 px-4 sm:px-16">
        <Header className="mb-8">Spread Allocation</Header>
        <Flex gap={16} className="flex-wrap md:flex-nowrap">
          <Panel
            title={`
              ${selectedPhase.dateEffective[0] ? friendlyDate(selectedPhase.dateEffective[0]) : 'Origination'}
               → 
              ${selectedPhase.dateEffective[1] ? friendlyDate(selectedPhase.dateEffective[1]) : 'Forever'}`}
            className="flex-grow"
          >
            <Flex gap={24} className="mt-2 mb-5">
              <Flex stack gap={6} className="flex-grow">
                <div>Effective from</div>
                {selectedPhase.dateEffective[0] ? (
                  <DatePicker
                    value={selectedPhase.dateEffective[0]}
                    minDate={
                      previousPhase?.dateEffective[0]
                        ? addDays(parseISO(previousPhase.dateEffective[0]), 1)
                        : undefined
                    }
                    maxDate={
                      selectedPhase.dateEffective[1]
                        ? parseISO(selectedPhase.dateEffective[1])
                        : undefined
                    }
                    onChange={handleFromChange}
                  />
                ) : (
                  <IconInput type="text" value="Origination" disabled />
                )}
              </Flex>
              <Flex stack gap={6} className="flex-grow">
                <div>Effective to</div>
                {selectedPhase.dateEffective[1] ? (
                  <DatePicker
                    value={selectedPhase.dateEffective[1]}
                    minDate={
                      selectedPhase.dateEffective[0]
                        ? parseISO(selectedPhase.dateEffective[0])
                        : undefined
                    }
                    maxDate={
                      nextPhase?.dateEffective[1]
                        ? subDays(parseISO(nextPhase.dateEffective[1]), 1)
                        : undefined
                    }
                    onChange={handleToChange}
                  />
                ) : (
                  <IconInput type="text" value="Forever" disabled />
                )}
              </Flex>
            </Flex>
            <Flex
              justifyContent="flex-end"
              className={clsx(
                'font-bold text-sm mb-3',
                assignedPercent > (loan.spreadRate || 0) && 'text-red-100'
              )}
            >
              {formatPercent(assignedPercent, {
                showZero: true,
                maxDecimals: 10,
              })}{' '}
              of{' '}
              {formatPercent(loan.spreadRate || 0, {
                showZero: true,
                maxDecimals: 10,
              })}{' '}
              assigned
            </Flex>
            <SpreadBar
              recipients={selectedPhase.people}
              defaultRecipient={selectedPhase.defaultRecipient}
              assignedPercent={assignedPercent}
              percent={loan.spreadRate || 0}
            />
            <Recipients
              recipients={selectedPhase.people}
              defaultRecipient={selectedPhase.defaultRecipient}
              defaultRecipientPercent={
                Math.max(assignedPercent, loan.spreadRate || 0) -
                assignedPercent
              }
              onChange={handleUpdateRecipient}
              onAdd={handleAddRecipient}
              onDelete={handleDeleteRecipient}
            />
            <Flex stack gap={6}>
              <div>Assign surplus or deficit spread to:</div>
              <SelectRecipient
                className="w-80"
                menuPlacement="top"
                value={selectedPhase.defaultRecipientId}
                onSelect={({ id, name }) =>
                  handleChangeDefaultRecipient({ id, name })
                }
              />
            </Flex>
          </Panel>
          <Phases
            phases={phases}
            selectedPhaseIndex={selectedPhaseIndex}
            onSelect={setSelectedPhaseIndex}
            onAdd={handleAddPhase}
            onDelete={handleDeletePhase}
          />
        </Flex>
      </div>
    </Flex>
  ) : (
    <MainContent>
      <PageLoader />
    </MainContent>
  )
}

export { LoanSpreadAllocation }
