import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Badge } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { TextLink } from 'components/TextLink'
import { Quote, Sort } from 'types'
import { formatDate } from 'utils/date'

function statusColor(status: Quote['status']) {
  switch (status) {
    case 'Draft':
      return 'blue'
    case 'Sent':
      return 'green'
    default:
      return 'gray'
  }
}

function TableQuotes({
  sort,
  onSort,
  data = [],
  loading,
  onClick,
  onDelete,
  onChangeStatus,
}: {
  data?: Quote[]
  loading: boolean
  onClick: (quote?: Quote) => void
  sort?: Sort | undefined
  onSort?: (sort: Sort | string | undefined) => void
  onDelete: (quote: Quote) => void
  onChangeStatus: (quote: Quote) => void
}) {
  const navigate = useNavigate()
  const columns: ColumnDef<Quote>[] = useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'name',
      },
      {
        header: 'Type',
        accessorKey: 'loanType',
        size: 140,
        cell({ row }) {
          const type = row.original.loanType
          return type ? <Badge color="gray">{type}</Badge> : ''
        },
      },
      {
        header: 'Borrower',
        id: 'borrower',
        cell: ({ row }) =>
          row.original.borrowers.map(({ name, id }) => (
            <TextLink
              key={id}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                navigate(pathTo('borrower', id))
              }}
            >
              {name}
            </TextLink>
          )) || '-',
      },
      {
        header: 'Last Updated',
        accessorKey: 'updatedAt',
        cell: ({ getValue }) => formatDate(getValue() as string) || '-',
        size: 150,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 90,
        cell({ getValue }) {
          return (
            <Badge color={statusColor(getValue() as Quote['status'])}>
              {getValue() as Quote['status'] as string}
            </Badge>
          )
        },
      },
      {
        id: 'actions',
        header: '',
        size: 46,
        cell: ({ row }) => {
          return (
            <EllipsesActions>
              <EllipsesActions.Item
                icon
                onSelect={() => onChangeStatus(row.original)}
              >
                <Icon name={IconName.archive} />
                {row.original.status === 'Archived' ? 'Make Draft' : 'Archive'}
              </EllipsesActions.Item>
              <EllipsesActions.Item
                icon
                onSelect={() => onDelete(row.original)}
                className="text-red-100"
              >
                <Icon name={IconName.delete} />
                Delete
              </EllipsesActions.Item>
            </EllipsesActions>
          )
        },
      },
    ],
    [onChangeStatus, onDelete]
  )

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      onClick={onClick}
      sort={sort}
      onSort={onSort}
    />
  )
}

export { TableQuotes }
