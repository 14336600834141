import clsx from 'clsx'
import { FieldType } from 'types'
import { IconInput } from '../IconInput'
import Field, { CustomFieldProps, FieldChildProps } from './Field'
import styles from './styles.module.scss'

type Props = CustomFieldProps & {
  fullAccuracy?: boolean
  type: FieldType
  supportNegative?: boolean
}

function FieldIcon({
  autoFocus,
  onFocus,
  placeholder,
  disabled,
  mask,
  fullAccuracy,
  supportNegative,
  onChange,
  onBlur,
  ...props
}: Props) {
  return (
    <Field {...props}>
      {({ meta: { touched, error }, field }: FieldChildProps) => (
        <IconInput
          id={field.name}
          type={props.type}
          className={clsx({
            [styles.errorField]: touched && error,
          })}
          onFocus={onFocus}
          autoFocus={autoFocus}
          disabled={disabled}
          mask={mask}
          fullAccuracy={fullAccuracy}
          placeholder={placeholder}
          supportNegative={supportNegative}
          {...field}
          onBlur={(e) => {
            onBlur && onBlur(e)
            field.onBlur(e)
          }}
          onChange={(e) => {
            onChange && onChange(e)
            field.onChange(e)
          }}
        />
      )}
    </Field>
  )
}

export default FieldIcon
