import { Formik } from 'formik'
import { Button } from 'components/Button'
import { Form, LoanField } from 'components/Form'
import { Grid } from 'components/Grid'
import { Field } from 'types'
import { fieldsToInitialValues, valuesToFields } from 'utils/fields'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  title: string
  description?: string
  fields: Field[]
  saving: boolean
  onSave: (values: Field[]) => void
  onCancel: () => void
}

function ModalLoanFields({
  title,
  description,
  fields,
  saving,
  onSave,
  onCancel,
}: Props) {
  const initialValue = fieldsToInitialValues(fields)
  const handleSubmit = (values: Record<string, string>) => {
    onSave(valuesToFields(fields, values))
  }

  return (
    <Modal asChild title={title} onClose={onCancel} className={styles.modal}>
      <Formik initialValues={initialValue} onSubmit={handleSubmit}>
        <Form modal>
          {description && (
            <div className="-mt-2 text-grey-700">{description}</div>
          )}
          <Grid className={styles.form} columnGap={16}>
            {fields.map((field) => (
              <Grid.Item key={field.id} xs={6}>
                <LoanField field={field} />
              </Grid.Item>
            ))}
          </Grid>
          <div className={styles.buttons}>
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalLoanFields
