import { Formik } from 'formik'
import { pick, omit } from 'lodash'
import { getCountryByNameOrShortName } from 'node-countries'
import { useState } from 'react'
import { BorrowerDetails } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import {
  Field,
  FieldIcon,
  Form,
  Phone,
  Select,
  SocialSecurityNumber,
} from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Modal } from 'components/Modal'
import styles from 'components/Modal/AddEmail/styles.module.scss'
import { ModalAddress } from 'components/Modal/Address'
import { Panel } from 'components/Panel'
import { AddressSummary, Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import {
  useAddBorrowerAddress,
  useUpdateBorrower,
  useUpdateBorrowerAddress,
} from 'hooks/use-borrower'
import {
  useAddInvestorAddress,
  useUpdateInvestor,
  useUpdateInvestorAddress,
} from 'hooks/use-investor'
import { useSession } from 'hooks/use-session'
import { Address } from 'types'
import { isAddressDefined } from 'utils/address'
import { message } from 'utils/message'
import { formatPhone } from 'utils/phone'
import { createScheme, required } from 'utils/schemas'

interface Props {
  entity: BorrowerDetails
  isLoading: boolean
  setSecure: () => void
}

interface ModalProps {
  initialValues: any
  saving: boolean
  isLoading: boolean
  isBorrower: boolean
  onClose: () => void
  onSubmit: (values: any) => void
}

function ModalEditPersonalInfo({
  onClose,
  onSubmit,
  isLoading,
  saving,
  initialValues,
  isBorrower,
}: ModalProps) {
  const updatedInitialValues = isBorrower
    ? initialValues
    : omit(initialValues, 'numFlipped')
  const Schema = createScheme({
    name: required,
  })

  return (
    <Modal
      title="Edit Personal Information"
      loading={isLoading}
      onClose={onClose}
    >
      <Formik
        initialValues={updatedInitialValues}
        validationSchema={Schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid className={styles.form} columnGap={16}>
            <Grid.Item xs={12} sm={6}>
              <Field name="name" label="Company Name" placeholder="Name" />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="entityType"
                label="Type"
                portal
                options={[
                  {
                    label: 'Corporation',
                    value: 'Corporation',
                  },
                  {
                    label: 'General Partnership',
                    value: 'General Partnership',
                  },
                  {
                    label: 'Limited Liability Company',
                    value: 'Limited Liability Company',
                  },
                  {
                    label: 'Limited Partnership',
                    value: 'Limited Partnership',
                  },
                  {
                    label: 'Trust',
                    value: 'Trust',
                  },
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={12} sm={6}>
              <Select
                name="jurisdiction"
                label="Jurisdiction"
                portal
                placeholder="Jurisdiction"
                options={[
                  {
                    label: 'Country',
                    options: [
                      { label: 'United States', value: 'United States' },
                      { label: 'Canada', value: 'Canada' },
                    ],
                  },
                  {
                    label: 'State/Province',
                    options: [
                      ...(
                        getCountryByNameOrShortName('United States')
                          ?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                      ...(
                        getCountryByNameOrShortName('Canada')?.provinces || []
                      ).map((province) => ({
                        value: province.name,
                        label: province.name,
                      })),
                    ],
                  },
                ]}
              />
            </Grid.Item>
            {isBorrower && (
              <Grid.Item xs={12} sm={6}>
                <FieldIcon
                  type="number"
                  label="Number of Flips"
                  name="numFlipped"
                  mask="# projects"
                />
              </Grid.Item>
            )}
            <Grid.Item xs={12} sm={6}>
              <Phone name="phone" label="Phone" />
            </Grid.Item>
            <Grid.Item xs={12} sm={isBorrower ? 6 : 12}>
              <SocialSecurityNumber
                label="Tax ID"
                name="socialSecurityNumber"
                mask="00-0000000"
              />
            </Grid.Item>

            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

function PanelEntityInformation({ entity, isLoading, setSecure }: Props) {
  const { isManager, user, refreshUser } = useSession()
  const isBorrower = !!user?.actor?.isBorrower

  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)
  const useUpdatePerson = entity.isInvestor
    ? useUpdateInvestor
    : useUpdateBorrower
  const useAddPersonAddress = entity.isInvestor
    ? useAddInvestorAddress
    : useAddBorrowerAddress
  const useUpdatePersonAddress = entity.isInvestor
    ? useUpdateInvestorAddress
    : useUpdateBorrowerAddress
  const { mutate: update, isPending: isUpdating } = useUpdatePerson()
  const { mutate: addAddress, isPending: addingAddress } = useAddPersonAddress(
    entity.id
  )
  const { mutate: updateAddress, isPending: updatingAddress } =
    useUpdatePersonAddress(entity.id)

  const addresses = entity.addresses
    .sort((a) => (a.type === 'primary' ? -1 : 1))
    .filter((address) => isAddressDefined(address))

  const saveAddresses = (addresses: Record<'primary' | 'mailing', Address>) => {
    const currentPrimary = entity.addresses.find(
      ({ type }) => type === 'primary'
    )
    const currentMailing = entity.addresses.find(
      ({ type }) => type === 'mailing'
    )
    const options = {
      onSuccess: () => {
        message.success('Address saved')
        setIsAddressModalVisible(false)
      },
    }

    if (currentPrimary) {
      updateAddress(
        {
          id: entity.id,
          addressId: currentPrimary.id,
          address: addresses.primary,
        },
        options
      )
    } else {
      addAddress(
        {
          id: entity.id,
          address: { ...addresses.primary, type: 'primary' },
        },
        options
      )
    }

    if (currentMailing) {
      updateAddress(
        {
          id: entity.id,
          addressId: currentMailing.id,
          address: addresses.mailing,
        },
        options
      )
    } else {
      addAddress(
        {
          id: entity.id,
          address: { ...addresses.mailing, type: 'mailing' },
        },
        options
      )
    }
  }

  return (
    <Flex stack gap={24}>
      <Panel
        title="General Company Info"
        onEdit={
          isManager
            ? () => {
                setSecure()
                setIsEditModalVisible(true)
              }
            : undefined
        }
      >
        <Grid gap={24}>
          <Grid.Item sm={6}>
            <Summary name="Company Name">{entity.name}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Type">{entity.entityType}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Jurisdiction">{entity.jurisdiction}</Summary>
          </Grid.Item>
          {isBorrower && (
            <Grid.Item sm={6}>
              <Summary name="Number of Flips">{entity.numFlipped}</Summary>
            </Grid.Item>
          )}
          <Grid.Item sm={6}>
            <Summary name="Phone">{formatPhone(entity.phone)}</Summary>
          </Grid.Item>
          <Grid.Item sm={6}>
            <Summary name="Tax ID">
              <div className="h-4">
                {entity.isSsnSet && entity?.socialSecurityNumber}
                {entity.isSsnSet && !entity?.socialSecurityNumber ? (
                  <span className="cursor-pointer" onClick={setSecure}>
                    ••••••••• <Icon name={IconName.view} size="md" />
                  </span>
                ) : undefined}
              </div>
            </Summary>
          </Grid.Item>
        </Grid>
      </Panel>
      <Panel
        title="Address"
        onEdit={isManager ? () => setIsAddressModalVisible(true) : undefined}
      >
        <Grid>
          {addresses.length > 0 &&
            addresses.map((address) => (
              <Grid.Item sm={6} key={address.id}>
                <AddressSummary address={address} />
              </Grid.Item>
            ))}
          {!addresses.length && isManager && (
            <Grid.Item sm={12}>
              <div className={styles.link}>
                <TextLink onClick={() => setIsAddressModalVisible(true)}>
                  <Icon name={IconName.plus} size="sm" />
                  Add Address
                </TextLink>
              </div>
            </Grid.Item>
          )}
        </Grid>
      </Panel>
      {isEditModalVisible && (
        <ModalEditPersonalInfo
          initialValues={pick(entity, [
            'name',
            'entityType',
            'jurisdiction',
            'numFlipped',
            'phone',
            'socialSecurityNumber',
          ])}
          isLoading={isLoading}
          isBorrower={isBorrower}
          saving={isUpdating}
          onClose={() => setIsEditModalVisible(false)}
          onSubmit={(data) =>
            update(
              {
                id: entity.id,
                ...data,
              },
              {
                onSuccess: () => {
                  setIsEditModalVisible(false)
                  user?.actor?.name !== data.name && refreshUser()
                },
              }
            )
          }
        />
      )}
      {isAddressModalVisible && (
        <ModalAddress
          addresses={entity.addresses}
          saving={addingAddress || updatingAddress}
          onSave={(addresses) => saveAddresses(addresses)}
          onCancel={() => setIsAddressModalVisible(false)}
        />
      )}
    </Flex>
  )
}

export { PanelEntityInformation }
