import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  addLoanAddressInsurance,
  updateLoanAddressInsurance,
  deleteLoanAddressInsurance,
} from 'admin/services/api/loan-address'
import { KEY_LOANS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Insurance } from 'types'
import { message } from 'utils/message'

const useAddLoanAddressInsurance = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (insurance: Partial<Insurance>) =>
      addLoanAddressInsurance(loanId, addressId, insurance),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useUpdateLoanAddressInsurance = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (insurance: Partial<Insurance>) =>
      updateLoanAddressInsurance(loanId, addressId, insurance),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

const useDeleteLoanAddressInsurance = ({
  loanId,
  addressId,
  onSuccess,
}: {
  loanId: string
  addressId: string
  onSuccess?: () => void
}) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (insuranceId: string) =>
      deleteLoanAddressInsurance(loanId, addressId, insuranceId),
    onSuccess: (loan) => {
      queryClient.setQueryData([KEY_LOANS, loanId], loan)
      message.success('Loan updated')
      onSuccess?.()
    },
    onError: handleErrorResponse,
  })
}

export {
  useAddLoanAddressInsurance,
  useUpdateLoanAddressInsurance,
  useDeleteLoanAddressInsurance,
}
