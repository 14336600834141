import { FieldArray, Formik } from 'formik'
import { cloneDeep } from 'lodash'
import * as yup from 'yup'
import { Button } from 'components/Button'
import { ProductField, Form } from 'components/Form'
import { FormAddress } from 'components/FormAddress'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import styles from 'components/Modal/AddLoan/styles.module.scss'
import { TextLink } from 'components/TextLink'
import { Field } from 'types'
import { getField } from 'utils/fields'
import { createScheme, required } from 'utils/schemas'

const AddressSchema = createScheme({
  street1: required,
  city: required,
  state: required,
  zipcode: required,
  country: required,
  addressDetails: yup.object({
    units: required,
    propertyType: required,
  }),
})

const AddressesSchema = createScheme({
  addresses: yup
    .array()
    .of(AddressSchema)
    .min(1, 'Must have at least one address'),
})

type AddressFormValues = {
  street1: string
  street2: string
  city: string
  state: string
  zipcode: string
  country: string
  addressDetails: {
    units: string
    propertyType: string
  }
}

const initialFormValues: AddressFormValues = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipcode: '',
  country: 'United States',
  addressDetails: {
    units: '',
    propertyType: '',
  },
}

interface Props {
  initialValues: AddressFormValues[]
  productFields?: Field[]
  loading: boolean
  saving: boolean
  submitButtonLabel?: string
  onBack?: () => void
  onCancel: () => void
  onSubmit: (values: { addresses: AddressFormValues[] }) => void
}

const LoanAddressesForm = ({
  initialValues,
  productFields = [],
  loading,
  saving,
  submitButtonLabel = 'Save',
  onCancel,
  onBack,
  onSubmit,
}: Props) => {
  const addresses = cloneDeep(initialValues)
  if (productFields && productFields.length) {
    const productTypes = getField(productFields, 'property-type')
    if (
      productTypes &&
      productTypes.options &&
      productTypes.options.length &&
      addresses &&
      addresses.length
    ) {
      addresses[0].addressDetails.propertyType =
        productTypes.options[0].value || ''
      addresses[0].addressDetails.units = '1'
    }
  }
  const defaultAddress = cloneDeep(addresses[0])

  return loading ? (
    <div className={styles.loader}>
      <Icon name={IconName.loaderSpinner} size="xl" className="spinner" />
    </div>
  ) : (
    <Formik
      initialValues={{ addresses }}
      validationSchema={AddressesSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <Form modal>
          <div>
            <FieldArray name="addresses">
              {(addressesHelpers) => {
                return (
                  <>
                    {values.addresses.map((value, index) => (
                      <div key={index} className={styles.address}>
                        {values.addresses.length > 1 && (
                          <div className={styles.addressHeader}>
                            <Header variant="h4">Address #{index + 1}</Header>
                            <Button
                              size="small"
                              variant="tertiary"
                              onClick={() => addressesHelpers.remove(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        )}
                        <Grid gap={16}>
                          <Grid.Item xs={6}>
                            <ProductField
                              fieldId="property-type"
                              fields={productFields}
                              name={`addresses.${index}.addressDetails.propertyType`}
                            />
                          </Grid.Item>
                          <Grid.Item xs={6}>
                            <ProductField
                              fieldId="number-units"
                              fields={productFields}
                              name={`addresses.${index}.addressDetails.units`}
                            />
                          </Grid.Item>
                        </Grid>
                        <FormAddress prefix={`addresses.${index}`} />
                      </div>
                    ))}
                    <div className={styles.add}>
                      <TextLink
                        onClick={() => addressesHelpers.push(defaultAddress)}
                      >
                        <Icon name={IconName.plus} size="sm" />
                        Additional Property
                      </TextLink>
                    </div>
                  </>
                )
              }}
            </FieldArray>
          </div>
          <div className={styles.buttonsWithBack}>
            {onBack ? (
              <TextLink variant="invert" onClick={onBack}>
                <Icon name={IconName.arrowLeft} size="sm" />
                Back
              </TextLink>
            ) : (
              <div />
            )}
            <div className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button loading={saving} type="submit">
                {submitButtonLabel}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export type { AddressFormValues }
export { initialFormValues }
export default LoanAddressesForm
