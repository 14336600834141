import { ColumnDef } from '@tanstack/react-table'
import clsx from 'clsx'
import { debounce, toNumber } from 'lodash'
import { useMemo } from 'react'
import { Checkbox } from 'components/Checkbox'
import { EditableTable } from 'components/EditableTable'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { IconInput } from 'components/IconInput'
import { FundDistributionInvestment } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { sumDecimal } from 'utils/math'
import styles from './styles.module.scss'

interface Props {
  data: FundDistributionInvestment[]
  disabled: boolean
  onChange: (data: Partial<FundDistributionInvestment>, save: boolean) => void
  onMasterChange: (checked: boolean) => void
}

function TableInvestors({ data, disabled, onChange, onMasterChange }: Props) {
  const debouncedOnChange = debounce(onChange, 1000)
  const columns: ColumnDef<FundDistributionInvestment>[] = useMemo(
    () => [
      {
        header: 'Investor',
        accessorKey: 'name',
        footer: '',
      },
      {
        header: 'Class',
        accessorKey: 'class',
        cell: ({ row }) => row.original.class || '-',
        size: 100,
        footer: '',
      },
      {
        header: 'Date',
        accessorKey: 'date',
        cell: ({ row }) => formatDate(row.original.date),
        size: 120,
        footer: '',
      },
      {
        header: 'Balance',
        accessorKey: 'balance',
        size: 160,
        cell: ({ row }) => formatUsd(row.original.balance),
        footer: '',
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Returned Capital',
        accessorKey: 'capitalReturn',
        footer: ({ table }) => {
          const sum = sumDecimal(
            table.options.data.map(({ capitalReturn }) =>
              toNumber(capitalReturn)
            )
          )
          return (
            <div className={styles.footer}>
              <span className={styles.footerLabel}>Sum</span>{' '}
              <span className={styles.tableValue}>{formatUsd(sum)}</span>
            </div>
          )
        },
        cell: ({ row }) =>
          disabled ? (
            <span className={clsx(styles.tableValue, styles.cell)}>
              {row.original.capitalReturn
                ? formatUsd(row.original.capitalReturn)
                : ''}
            </span>
          ) : (
            <IconInput
              type="currency"
              value={row.original.capitalReturn}
              className={styles.tableInput}
              placeholder=""
              onChange={(e) => {
                onChange(
                  {
                    id: row.original.id,
                    capitalReturn: e.target.value,
                  },
                  false
                )
                debouncedOnChange(
                  {
                    id: row.original.id,
                    capitalReturn: e.target.value,
                  },
                  true
                )
              }}
              onBlur={(e) =>
                onChange(
                  {
                    id: row.original.id,
                    capitalReturn: e.target.value,
                  },
                  true
                )
              }
            />
          ),
        size: 140,
        meta: {
          align: 'right',
          editable: true,
        },
      },
      {
        header: 'Income',
        accessorKey: 'amount',
        footer: ({ table }) => {
          const sum = sumDecimal(
            table.options.data.map(({ amount }) => toNumber(amount))
          )
          return (
            <div className={styles.footer}>
              <span className={styles.footerLabel}>Sum</span>{' '}
              <span className={styles.tableValue}>{formatUsd(sum)}</span>
            </div>
          )
        },
        cell: ({ row }) =>
          disabled ? (
            <span className={clsx(styles.tableValue, styles.cell)}>
              {row.original.amount ? formatUsd(row.original.amount) : ''}
            </span>
          ) : (
            <IconInput
              type="currency"
              value={row.original.amount}
              className={clsx(styles.tableInput, styles.tableAmount)}
              placeholder=""
              onChange={(e) => {
                onChange(
                  {
                    id: row.original.id,
                    amount: e.target.value,
                  },
                  false
                )
                debouncedOnChange(
                  {
                    id: row.original.id,
                    amount: e.target.value,
                  },
                  true
                )
              }}
              onBlur={(e) =>
                onChange(
                  {
                    id: row.original.id,
                    amount: e.target.value,
                  },
                  true
                )
              }
            />
          ),
        size: 140,
        meta: {
          align: 'right',
          editable: true,
        },
      },
      {
        header: ({ table }) => {
          const checked = table.options.data.every(
            ({ isReinvested }) => isReinvested
          )
          const indeterminate =
            table.options.data.some(({ isReinvested }) => isReinvested) &&
            table.options.data.some(({ isReinvested }) => !isReinvested)
          return (
            <Flex gap={4} alignItems="center">
              DRIP{' '}
              {!disabled && (
                <Checkbox
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={() => onMasterChange(!(checked || indeterminate))}
                />
              )}
            </Flex>
          )
        },
        accessorKey: 'reinvested',
        size: 90,
        footer: '',
        cell: ({ row }) =>
          disabled ? (
            row.original.isReinvested ? (
              <Icon name={IconName.check} size="sm" />
            ) : (
              ''
            )
          ) : (
            <Checkbox
              className="mr-[5px]"
              checked={row.original.isReinvested}
              onChange={(e) =>
                onChange(
                  {
                    id: row.original.id,
                    isReinvested: e.target.checked,
                  },
                  true
                )
              }
            />
          ),
        meta: {
          align: 'right',
        },
      },
    ],
    [disabled, onChange]
  )

  return (
    <EditableTable className={styles.table} columns={columns} data={data} />
  )
}

export { TableInvestors }
