import { request } from 'services/request'

type Image = {
  images: any[]
  uploadUrl: string
}

const getLoanImage = async (loanId: string): Promise<Image> => {
  const { data } = await request.get(`/loan/${loanId}/image`)
  return data
}

const removeLoanImage = (loanId: string, imageId: string): Promise<Image> => {
  return request.delete(`/loan/${loanId}/image/${imageId}`)
}

export { getLoanImage, removeLoanImage }
