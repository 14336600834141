import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KEY_NOTIFICATION_SETTINGS } from 'constants/query-keys'
import {
  getClientNotificationSettings,
  updateClientNotificationSettings,
  getLoanNotificationSettings,
  updateLoanNotificationSettings,
  getUserNotificationSettings,
  updateUserNotificationSettings,
  getNotificationTemplate,
  updateNotificationTemplate,
  resetNotificationTemplate,
} from 'services/api/notification-setting'
import { handleErrorResponse } from 'services/request'
import { NotificationSetting, NotificationSettingTemplate } from 'types'
import { message } from 'utils/message'

const useClientNotificationSettings = (clientId: string) => {
  return useQuery({
    queryKey: [KEY_NOTIFICATION_SETTINGS, 'client'],
    queryFn: () => getClientNotificationSettings(clientId),
  })
}

const useUpdateClientNotificationSettings = (clientId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (notificationSettings: Partial<NotificationSetting>[]) =>
      updateClientNotificationSettings(clientId, notificationSettings),
    onSuccess: (notificationSettings) => {
      queryClient.setQueryData(
        [KEY_NOTIFICATION_SETTINGS, 'client'],
        notificationSettings
      )
      message.success('Notification settings updated')
    },
    onError: handleErrorResponse,
  })
}

const useLoanNotificationSettings = (loanId: string) => {
  return useQuery({
    queryKey: [KEY_NOTIFICATION_SETTINGS, 'loan'],
    queryFn: () => getLoanNotificationSettings(loanId),
  })
}

const useUpdateLoanNotificationSettings = (loanId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (notificationSettings: Partial<NotificationSetting>[]) =>
      updateLoanNotificationSettings(loanId, notificationSettings),
    onSuccess: (notificationSettings) => {
      queryClient.setQueryData(
        [KEY_NOTIFICATION_SETTINGS, 'loan'],
        notificationSettings
      )
      message.success('Notification settings updated')
    },
    onError: handleErrorResponse,
  })
}

const useUserNotificationSettings = (clientId: string, userId: string) => {
  return useQuery({
    queryKey: [KEY_NOTIFICATION_SETTINGS, 'user'],
    queryFn: () => getUserNotificationSettings(clientId, userId),
  })
}

const useUpdateUserNotificationSettings = (
  clientId: string,
  userId: string
) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (notificationSettings: Partial<NotificationSetting>[]) =>
      updateUserNotificationSettings(clientId, userId, notificationSettings),
    onSuccess: (notificationSettings) => {
      queryClient.setQueryData(
        [KEY_NOTIFICATION_SETTINGS, 'user'],
        notificationSettings
      )
      message.success('Notification settings updated')
    },
    onError: handleErrorResponse,
  })
}

const useNotificationTemplate = (id: string) => {
  return useQuery({
    queryKey: [KEY_NOTIFICATION_SETTINGS, 'template', id],
    queryFn: () => getNotificationTemplate(id),
  })
}

const useUpdateNotificationTemplate = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (notification: NotificationSettingTemplate) =>
      updateNotificationTemplate(id, notification),
    onSuccess: (template) => {
      queryClient.setQueryData(
        [KEY_NOTIFICATION_SETTINGS, 'template', id],
        template
      )
    },
    onError: handleErrorResponse,
  })
}

const useResetNotificationTemplate = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => resetNotificationTemplate(id),
    onSuccess: (template) => {
      queryClient.setQueryData(
        [KEY_NOTIFICATION_SETTINGS, 'template', id],
        template
      )
    },
    onError: handleErrorResponse,
  })
}

export {
  useClientNotificationSettings,
  useUpdateClientNotificationSettings,
  useLoanNotificationSettings,
  useUpdateLoanNotificationSettings,
  useUserNotificationSettings,
  useUpdateUserNotificationSettings,
  useNotificationTemplate,
  useUpdateNotificationTemplate,
  useResetNotificationTemplate,
}
