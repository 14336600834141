import { useState } from 'react'
import {
  useAddLoanAddressInsurance,
  useUpdateLoanAddressInsurance,
  useDeleteLoanAddressInsurance,
} from 'admin/hooks/use-loan-address-insurance'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { AddressInsurance } from 'components/Modal/AddressInsurance'
import { Panel } from 'components/Panel'
import { Summary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { Loan, Address } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  address: Address
}

export default function PanelInsurance({ loan, address }: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [editingId, setEditingId] = useState<string | null>(null)
  const { mutate: addInsurance, isPending: adding } =
    useAddLoanAddressInsurance({
      loanId: loan.id,
      addressId: address.id,
      onSuccess: () => {
        setIsModalVisible(false)
      },
    })
  const { mutate: updateInsurance, isPending: updating } =
    useUpdateLoanAddressInsurance({
      loanId: loan.id,
      addressId: address.id,
      onSuccess: () => {
        setEditingId(null)
        setIsModalVisible(false)
      },
    })
  const { mutate: deleteInsurance, isPending: deleting } =
    useDeleteLoanAddressInsurance({
      loanId: loan.id,
      addressId: address.id,
    })

  return (
    <Panel title="Insurance">
      <div className={styles.insurances}>
        {address.insurances.map((insurance) => (
          <Grid gap={24} key={insurance.id}>
            <Grid.Item sm={12} className={styles.insuranceHeader}>
              <Header variant="h4">{insurance.type}</Header>
              {!loan.lock && (
                <div className={styles.insuranceButtons}>
                  <Button
                    size="small"
                    onClick={() => {
                      setEditingId(insurance.id)
                      setIsModalVisible(true)
                    }}
                    disabled={deleting}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="tertiary"
                    size="small"
                    onClick={() => deleteInsurance(insurance.id)}
                    disabled={deleting}
                  >
                    Delete
                  </Button>
                </div>
              )}
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Policy Number">{insurance.policyNumber}</Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Premium">{formatUsd(insurance.premium)}</Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Effective">
                {formatDate(insurance.effective)}
              </Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Expiration">
                {formatDate(insurance.expiration)}
              </Summary>
            </Grid.Item>
            <Grid.Item sm={6}>
              <Summary name="Carrier">{insurance.carrier}</Summary>
            </Grid.Item>
          </Grid>
        ))}
      </div>
      {!address.insurances.length && (
        <div className={styles.noData}>No insurance</div>
      )}
      {!loan.lock && (
        <div className={styles.addInsurance}>
          <TextLink onClick={() => setIsModalVisible(true)}>
            <Icon name={IconName.plus} size="sm" /> Add Insurance
          </TextLink>
        </div>
      )}
      {isModalVisible && (
        <AddressInsurance
          insurance={address.insurances.find(({ id }) => editingId === id)}
          saving={adding || updating}
          onSave={(insurance) =>
            editingId ? updateInsurance(insurance) : addInsurance(insurance)
          }
          onCancel={() => setIsModalVisible(false)}
        />
      )}
    </Panel>
  )
}
