const parsePhone = (phone: string) => {
  if (phone) {
    return phone.slice(1)
  }
  return phone
}

const formatPhone = (phone: string) => {
  if (phone) {
    return (
      '1' +
      phone
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(' ', '')
        .replaceAll('-', '')
    )
  }
  return ''
}

export { parsePhone, formatPhone }
