import { ColumnDef } from '@tanstack/react-table'
import { useMemo } from 'react'
import { BadgeBudgetDrawStatus } from 'components/Badge'
import { EllipsesActions } from 'components/EllipsesActions'
import { Icon, IconName } from 'components/Icon'
import { Table } from 'components/Table'
import { BudgetDraw } from 'types'
import { formatUsd } from 'utils/currency'
import { friendlyDate } from 'utils/date'
import { isAdminRoute } from 'utils/routes'
import styles from './styles.module.scss'

interface Props {
  data?: BudgetDraw[]
  onClick: (item: BudgetDraw) => void
  onRelease: (item: BudgetDraw) => void
  onDelete: (item: BudgetDraw) => void
}

function TableDrawRequests({ data = [], onClick, onRelease, onDelete }: Props) {
  const isAdmin = isAdminRoute()

  function isActionDisabled(status) {
    return ['Approved', 'Released'].includes(status)
  }

  const columns: ColumnDef<BudgetDraw>[] = useMemo(
    () => [
      {
        header: 'Date',
        id: 'date',
        cell: ({ row }) => {
          const date = row.original.dateSubmitted || row.original.createdAt
          return friendlyDate(date)
        },
      },
      {
        header: 'Requested',
        accessorKey: 'amountRequested',
        size: 120,
        cell: (cell) => formatUsd(cell.getValue() as string),
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Approved',
        accessorKey: 'balance',
        size: 120,
        cell: ({ cell, row }) =>
          ['Approved', 'Released'].includes(row.original.status)
            ? formatUsd(cell.getValue() as string)
            : '-',
        meta: {
          align: 'right',
        },
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: ({ row }) => (
          <BadgeBudgetDrawStatus
            status={row.original.status}
            reason={row.original.reason}
          />
        ),
        size: 100,
      },
      {
        id: 'actions',
        header: '',
        size: 32,
        cell: ({ row }) => (
          <EllipsesActions>
            {isAdmin && (
              <EllipsesActions.Item
                icon
                onSelect={() => onRelease(row.original)}
                disabled={row.original.status !== 'Approved'}
              >
                <Icon name={IconName.documentDollar} size="md" />
                Mark as Released
              </EllipsesActions.Item>
            )}
            <EllipsesActions.Item
              icon
              onSelect={() => onDelete(row.original)}
              disabled={isActionDisabled(row.original.status) && !isAdmin}
              className="text-red-100"
            >
              <Icon name={IconName.delete} />
              Delete
            </EllipsesActions.Item>
          </EllipsesActions>
        ),
      },
    ],
    []
  )

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={data}
      onClick={(item) => onClick(item as BudgetDraw)}
    />
  )
}

export { TableDrawRequests }
