import { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { pathTo } from 'admin/path-to'
import { Grid } from 'components/Grid'
import { LinkEmail } from 'components/LinkEmail'
import { Summary } from 'components/Summary'
import { Person } from 'types'
import { formatPhone } from 'utils/phone'

interface Props {
  entity: Person
  include?: string[]
}

const EntityDetails = ({ entity, include }: Props) => {
  const isIndividual = !entity.type || entity.type === 'individual'
  const isEntity = !isIndividual

  return (
    <Grid gap={24}>
      <Grid.Item sm={12}>
        <Summary name={isIndividual ? 'Full Name' : 'Company Name'}>
          <NavLink to={pathTo('borrower', entity.id)} className="link">
            {entity.name}
          </NavLink>
        </Summary>
      </Grid.Item>
      {isIndividual && (
        <Grid.Item sm={6}>
          <Summary name="Credit Score">{entity.creditScore}</Summary>
        </Grid.Item>
      )}
      {isIndividual && (
        <Grid.Item sm={6}>
          <Summary name="Citizenship">{entity.citizenship}</Summary>
        </Grid.Item>
      )}
      {isEntity && (
        <Grid.Item sm={6}>
          <Summary name="Jurisdiction">{entity.jurisdiction}</Summary>
        </Grid.Item>
      )}
      {include?.includes('numFlipped') && (
        <Grid.Item sm={6}>
          <Summary name="Number of Flips">{entity.numFlipped}</Summary>
        </Grid.Item>
      )}
      {include?.includes('numConstructions') && (
        <Grid.Item sm={6}>
          <Summary name="Number of Projects">{entity.numConstructions}</Summary>
        </Grid.Item>
      )}
      <Grid.Item sm={6}>
        <Summary name="Phone">{formatPhone(entity.phone)}</Summary>
      </Grid.Item>
      <Grid.Item sm={6}>
        <Summary name="Email">
          {entity.email && (
            <Fragment key={entity.email}>
              <LinkEmail email={entity.email} />{' '}
            </Fragment>
          )}
        </Summary>
      </Grid.Item>
    </Grid>
  )
}

export { EntityDetails }
