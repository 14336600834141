import { Flex } from 'components/Flex'
import { formatPercent } from 'utils/percent'
import styles from './styles.module.scss'

interface Props {
  value: number
}

const Progress = ({ value }: Props) => {
  const barPercent = value > 100 ? 100 : value

  return (
    <Flex alignItems="center" gap={8}>
      {formatPercent(value, {
        decimals: 0,
      })}
      <div className={styles.bar}>
        <div className={styles.progress} style={{ width: `${barPercent}%` }} />
      </div>
    </Flex>
  )
}

export { Progress }
