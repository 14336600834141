export default {
  font: {
    family: '"Inter", sans-serif',
  },
  color: {
    blue200: '#0551CD',
    blue100: '#2E72E0',
    blue50: '#9CC5F6',
    blue25: '#EEF7FE',
    grey600: '#848688',
    grey50: '#f9f9fa',
    grey75: '#f3f3f5',
    green100: '#07735F',
    black100: '#1A2028',
    white100: '#FFF',
  },
}
