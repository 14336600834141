import { MainContent } from 'borrower/components/layout/MainContent'
import { useSession } from 'hooks/use-session'
import { SettingsEntity } from './SettingsEntity'
import { SettingsIndividual } from './SettingsIndividual'

function Settings() {
  const { user } = useSession()

  return (
    <MainContent>
      {user?.actor?.type === 'entity' && (
        <SettingsEntity
          actorId={user.actor.id}
          actorType={user.actor.isInvestor ? 'investor' : 'borrower'}
          personId={user.person.id}
          personType={user.person.isInvestor ? 'investor' : 'borrower'}
        />
      )}
      {user?.actor?.type === 'individual' && (
        <SettingsIndividual
          actorId={user.actor.id}
          actorType={user.actor.isInvestor ? 'investor' : 'borrower'}
        />
      )}
    </MainContent>
  )
}

export { Settings }
