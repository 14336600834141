import { isArray } from 'lodash'
import { request } from 'services/request'
import { FieldType, Filter, Meta, Pagination } from 'types'
import { formatPercent } from 'utils/math'

export type LoanChangeLog = {
  id: string
  description: string
  fieldName: string
  value: (string | number | null)[] | null
  createdAt: string
  byName: string | null
  adminId: string | null
  personId: string | null
  type: FieldType
  modificationDateEffective: string
}

const getLoanActivities = async ({
  loanId,
  search,
  filter,
  page,
}: {
  loanId: string
  search?: string
  filter?: Filter
  page?: Pagination
}): Promise<{ logs: LoanChangeLog[]; meta: Meta }> => {
  const {
    data: { logs, pagination },
  } = await request.get(`/loan/${loanId}/changelog`, {
    params: { search, filter, page },
  })
  return {
    logs: logs.map((log: LoanChangeLog) => ({
      ...log,
      value:
        log.type === 'percentage' && isArray(log.value)
          ? log.value.map((v) => formatPercent(v as number))
          : log.value,
    })),
    meta: pagination,
  }
}

export { getLoanActivities }
