import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { TableAutomationsLog } from 'admin/components/table'
import {
  useAutomationsLogs,
  useRemoveAutomation,
} from 'admin/hooks/use-automation'
import { pathTo } from 'admin/path-to'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { LoadMore } from 'components/LoadMore'
import { ModalDelete } from 'components/Modal/Delete'
import { Panel } from 'components/Panel'
import { Search } from 'components/Search'
import { usePagination } from 'hooks/use-pagination'
import { IAutomationLog } from 'types'
import { ModalAutomationLog } from './ModalAutomationLog'

const SettingsAutomationsLog = () => {
  const navigate = useNavigate()
  const [selectedAutomationLog, setSelectedAutomationLog] =
    useState<IAutomationLog>()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [removingAutomationLogId, setRemovingAutomationLogId] =
    useState<string>()
  const { mutate: removeAutomation, isPending } = useRemoveAutomation()

  const { visibleItems, result, search, setSearch, setPagination } =
    usePagination<IAutomationLog>({
      property: 'log',
      useData: ({ pagination }) => useAutomationsLogs({ pagination }),
    })

  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{
            title: 'Automations',
            link: pathTo('settingsAutomations'),
          }}
          title="Automation Log"
        />
        <Panel>
          <Flex justifyContent="flex-end" alignItems="center">
            <Search onSearch={setSearch} search={search} />
            <Button
              variant="secondary"
              onClick={() => navigate(pathTo('settingsAutomations'))}
            >
              <Icon name={IconName.automations} />
              View Automations
            </Button>
          </Flex>
          <TableAutomationsLog
            data={visibleItems}
            loading={result.isPending}
            sort={undefined}
            onClick={(automationLog) => {
              setSelectedAutomationLog(automationLog)
              setIsModalVisible(true)
            }}
            onEdit={() => null}
            onSort={() => null}
            onDelete={setRemovingAutomationLogId}
          />
          <LoadMore
            loading={result.isPending}
            count={visibleItems.length}
            meta={result.data?.meta}
            onLoadMore={setPagination}
          />
        </Panel>
        {isModalVisible && (
          <ModalAutomationLog
            automationLog={selectedAutomationLog}
            onClose={() => setIsModalVisible(false)}
          />
        )}
        {removingAutomationLogId && (
          <ModalDelete
            resource="Automation Log"
            loading={isPending}
            onDelete={() =>
              removeAutomation(removingAutomationLogId, {
                onSuccess: () => {
                  setRemovingAutomationLogId('')
                },
              })
            }
            onCancel={() => setRemovingAutomationLogId('')}
          />
        )}
      </Flex>
    </MainContent>
  )
}

export { SettingsAutomationsLog }
