import { useNavigate } from 'react-router-dom'
import { pathTo } from 'borrower/path-to'
import { Button } from 'components/Button'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { Text } from 'components/Text'
import { formatUsd } from 'utils/currency'
import styles from './styles.module.scss'

interface Props {
  investmentClassName: string
  amount: string
}

const InvestmentSuccessForm = ({ investmentClassName, amount }: Props) => {
  const navigate = useNavigate()

  return (
    <div className={styles.agreementContainer}>
      <Grid className={styles.form} gap={24}>
        <Grid.Item className={styles.confirmIconWrapper} xs={12}>
          <Icon name={IconName.investmentConfirmed} />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Text className={styles.successDescription}>
            {`Your ${formatUsd(
              amount
            )} investment in the ${investmentClassName} class is complete.
            To track the progress of your investment, please visit your
            dashboard.`}
          </Text>
        </Grid.Item>
        <Grid.Item className={styles.goDashboardBtn} xs={12}>
          <Button onClick={() => navigate(pathTo('dashboard'))}>
            Go to Dashboard
          </Button>
        </Grid.Item>
      </Grid>
    </div>
  )
}

export default InvestmentSuccessForm
