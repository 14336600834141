import { useState } from 'react'
import { Menu } from 'admin/pages/SettingsAutomations/ModalAutomation/Menu'
import { MenuTrigger } from 'admin/pages/SettingsAutomations/ModalAutomation/MenuTrigger'
import { Dropdown } from 'components/Dropdown'
import {
  ITriggerConfig,
  ITriggerValue,
  IActionConfig,
  IActionValue,
  IConditionConfig,
  IConditionValue,
} from 'types'

interface Props {
  configs: ITriggerConfig[] | IConditionConfig[] | IActionConfig[]
  value: ITriggerValue | IConditionValue | IActionValue
  className?: string
  type?: 'condition'
  onChange: (
    value: ITriggerValue | IConditionValue | IActionValue | undefined
  ) => void
}

const Rule = ({ configs, value, className, type, onChange }: Props) => {
  const [open, setOpen] = useState(false)
  const handleSelect = (
    nextValue: ITriggerValue | IConditionValue | IActionValue | undefined
  ) => {
    onChange(nextValue)
    setOpen(false)
  }

  return (
    <Dropdown
      open={open}
      onOpenChange={setOpen}
      triggerFixedWidth
      trigger={
        <MenuTrigger
          itemConfig={
            (configs.find((config) => config.id === value?.id) ||
              value.config) as IActionConfig
          }
          value={value}
          className={className}
          onSelect={() => setOpen(true)}
          onDelete={() => onChange(undefined)}
        />
      }
    >
      <Menu
        menuItems={configs}
        value={value}
        type={type}
        onSelect={handleSelect}
      />
    </Dropdown>
  )
}

export { Rule }
