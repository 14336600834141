import { request } from 'services/request'
import { Quote, QuoteLender } from 'types'
import { updateResponseQuote } from 'utils/api/quote-parser'

const getQuoteLenders = async ({
  transactionType,
  loanType,
}: {
  transactionType: Quote['transactionType']
  loanType: Quote['loanType']
}): Promise<QuoteLender[]> => {
  const {
    data: { clients },
  } = await request.post('quote/lender', { transactionType, loanType })
  return clients
}

const updateQuoteLenders = async ({
  quoteId,
  lenderId,
}: {
  quoteId: string
  lenderId: string[]
}): Promise<Quote> => {
  const {
    data: { quote },
  } = await request.put(`/quote/${quoteId}/lender`, { lenderId })
  return updateResponseQuote(quote)
}

export { getQuoteLenders, updateQuoteLenders }
