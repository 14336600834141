import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { version as usingAppVersion } from 'utils/version'

function AppVersion() {
  const isNewVersionAvailable = useRef(false)
  const location = useLocation()

  const { data } = useQuery({
    queryKey: ['meta'],
    queryFn: async () => {
      try {
        const meta = await fetch(`/meta.json?_=${Date.now()}`)
        return await meta.json()
      } catch {
        return null
      }
    },
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 30, // 30 mins
    refetchIntervalInBackground: false,
  })

  useEffect(() => {
    isNewVersionAvailable.current =
      !!data && data?.appVersion !== usingAppVersion
  }, [data])

  // Reload the page if the application was deployed
  useEffect(() => {
    if (isNewVersionAvailable.current) {
      window.location.reload()
    }
  }, [location])

  return null
}

export { AppVersion }
