import { Formik } from 'formik'
import { get } from 'lodash'
import { useCallback } from 'react'
import { useLenderOfRecords } from 'admin/hooks/use-lender-of-record'
import { useUpdateLoan } from 'admin/hooks/use-loans'
import { Button } from 'components/Button'
import { Flex } from 'components/Flex'
import { Form, Select } from 'components/Form'
import { useSession } from 'hooks/use-session'
import { createScheme, required } from 'utils/schemas'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface Props {
  loanId: string
  lenderId: string
  onCancel: () => void
}

const FormSchema = createScheme({
  lenderId: required,
})

function ModalLoanLender({ loanId, lenderId, onCancel }: Props) {
  const { user } = useSession()
  const clientId = get(user, 'client.id', '')
  const { data: lenders, isPending } = useLenderOfRecords(clientId)
  const { mutate: updateLoan, isPending: saving } = useUpdateLoan({
    id: loanId,
  })
  const handleSave = useCallback(({ lenderId }) => {
    updateLoan(
      { lenderofrecordId: lenderId },
      {
        onSuccess: () => {
          onCancel()
        },
      }
    )
  }, [])

  return (
    <Modal
      title="Select a Lender of Record"
      onClose={onCancel}
      className={styles.modal}
      loading={isPending}
    >
      <Formik
        initialValues={{
          lenderId,
        }}
        validationSchema={FormSchema}
        enableReinitialize
        onSubmit={handleSave}
      >
        <Form>
          <Select
            name="lenderId"
            portal
            options={
              lenders?.map(({ id, name }) => ({
                value: id,
                label: name,
              })) || []
            }
          />
          <Flex justifyContent="flex-end">
            <Button variant="tertiary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" loading={saving}>
              Save
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}

export { ModalLoanLender }
