import { Offering } from 'types'

const updateResponseOffering = (offering: Offering): Offering =>
  ({
    ...offering,
    percentSubscribed: offering.percentSubscribed * 100,
    classes: offering.classes?.map(({ rate, ...rest }) => ({
      rate: rate ? rate * 100 : rate,
      ...rest,
    })),
    statistics: offering.statistics?.map(({ value, type, ...rest }) => ({
      value: type === 'percentage' ? value * 100 : value,
      type,
      ...rest,
    })),
    fund: offering.fund
      ? {
          ...offering.fund,
          classes: offering.fund?.classes?.map(({ rate, ...rest }) => ({
            rate: rate ? rate * 100 : rate,
            ...rest,
          })),
        }
      : null,
  }) as Offering

export { updateResponseOffering }
