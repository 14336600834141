const includeAttributes = ({ loanType }) => {
  const entityIncludes: string[] = []
  switch (loanType) {
    case 'Bridge + Rehab':
      entityIncludes.push('numFlipped')
      break
    case 'Bridge':
      entityIncludes.push('numFlipped')
      break
    case 'Construction':
      entityIncludes.push('numConstructions')
      break
  }
  return entityIncludes
}

export { includeAttributes }
