import { Formik } from 'formik'
import { Borrower, BorrowerDetails } from 'admin/services/api/borrowers'
import { Button } from 'components/Button'
import { Form, Select } from 'components/Form'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { TextLink } from 'components/TextLink'
import { PersonRole, PersonTitle } from 'types/person'
import { Modal } from '../index'
import styles from './styles.module.scss'

interface ModalAddLoanProps {
  borrower: BorrowerDetails
  availableAccounts: Borrower[]
  saving: boolean
  showTitle?: boolean
  onSave: (value: {
    id: string
    title: PersonTitle
    role: PersonRole
    linkId: string
  }) => void
  onCancel: () => void
  onCreateNewPerson: (name: string) => void
  newAddedPersonId?: string
}

type FormValues = {
  name: string
  title: PersonTitle | undefined
  role: PersonRole | undefined
  linkId: string
}

const roles = [
  {
    value: 'manager',
    label: 'Manager',
  },
  {
    value: 'viewer',
    label: 'Viewer',
  },
]

const titles = [
  'Manager',
  'Managing Member',
  'President',
  'Authorized Member',
  'Member',
  'Vice President',
  'General Partner',
  'Trustee',
  'Co-trustee',
].map((title) => ({
  value: title,
  label: title,
}))

function ModalLinkAccount({
  borrower,
  availableAccounts,
  saving,
  showTitle = true,
  onSave,
  onCancel,
  onCreateNewPerson,
  newAddedPersonId,
}: ModalAddLoanProps) {
  const isIndividual = !borrower.type || borrower.type === 'individual'
  const initialValue: FormValues = {
    name: borrower.name,
    title: undefined,
    role: undefined,
    linkId: newAddedPersonId ?? '',
  }

  const onSubmit = (values: FormValues) => {
    onSave({
      id: borrower.id,
      title: values.title as PersonTitle,
      role: values.role as PersonRole,
      linkId: values.linkId,
    })
  }

  const title = isIndividual ? 'Add to Company' : 'Add Person'
  const linkLabel = isIndividual ? 'Company' : 'Person'
  const links = (availableAccounts || []).map(({ id, name }) => ({
    label: name,
    value: id,
  }))

  return (
    <Modal title={title} onClose={onCancel} className={styles.modal}>
      <Formik
        initialValues={initialValue}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid className={styles.form} columnGap={16} rowGap={8}>
            <Grid.Item xs={12}>
              <Select
                name="linkId"
                label={linkLabel}
                options={links}
                portal
                noOptionsMessage={({ inputValue }) => (
                  <a
                    className="link"
                    onClick={() => onCreateNewPerson(inputValue)}
                  >
                    <Icon name={IconName.plus} size="sm" /> Create a new{' '}
                    {linkLabel}
                  </a>
                )}
                fieldClassName={styles.select}
              />
              <TextLink
                className={styles.createNew}
                onClick={() => onCreateNewPerson('')}
              >
                <Icon name={IconName.plus} size="sm" /> Create a new {linkLabel}
              </TextLink>
            </Grid.Item>
            {showTitle && (
              <Grid.Item xs={6}>
                <Select name="title" label="Title" options={titles} portal />
              </Grid.Item>
            )}
            <Grid.Item xs={showTitle ? 6 : 12}>
              <Select name="role" label="Role" options={roles} portal />
            </Grid.Item>

            <Grid.Item xs={12} className={styles.buttons}>
              <Button variant="tertiary" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="submit" loading={saving}>
                Save
              </Button>
            </Grid.Item>
          </Grid>
        </Form>
      </Formik>
    </Modal>
  )
}

export default ModalLinkAccount
