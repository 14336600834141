import { toNumber, get } from 'lodash'
import { useState } from 'react'
import { useReport } from 'admin/hooks/use-report'
import { useSession } from 'hooks/use-session'
import { LoanFundingSource, Offering } from 'types'
import { Modal } from '../index'
import InvestmentAgreementForm, {
  InvestmentAgreementValues,
} from './InvestmentAgreementForm'
import InvestmentAmountForm, {
  InvestmentAmountValues,
} from './InvestmentAmountForm'
import InvestmentConfirmationForm from './InvestmentConfirmationForm'
import InvestmentSuccessForm from './InvestmentSuccessForm'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
  initialInvestmentClassId: string
  saving: boolean
  onSave: (investment: Partial<LoanFundingSource>, options: any) => void
  onCancel: () => void
}

const initialInvestmentAmountValues: InvestmentAmountValues = {
  investmentClassId: '',
  amount: '',
}

const initialAgreementValues: InvestmentAgreementValues = {
  agreementText: '',
  isAgreement: false,
}

interface FormValues {
  investmentAmount: InvestmentAmountValues
  investmentAgreement: InvestmentAgreementValues
}

const getTitle = (step: number) => {
  if (step < 3) {
    return 'Invest in Offering'
  }
  if (step === 3) {
    return 'Confirmation'
  }
  return ''
}

function ModalInvestInOffering({
  offering,
  initialInvestmentClassId = 'unknown',
  saving,
  onSave,
  onCancel,
}: Props) {
  const [step, setStep] = useState<number>(1)
  const [data, setData] = useState<FormValues>({
    investmentAmount: {
      ...initialInvestmentAmountValues,
      investmentClassId:
        initialInvestmentClassId === 'unknown'
          ? initialInvestmentAmountValues.investmentClassId
          : initialInvestmentClassId,
    },
    investmentAgreement: { ...initialAgreementValues },
  })
  const { user } = useSession()
  const offeringTermsOfAgreement = get(
    user,
    'client.settings.offeringTermsOfAgreement',
    ''
  )
  initialAgreementValues.agreementText = offeringTermsOfAgreement
  const { data: cashBalance, isPending } = useReport(
    `investor/${user?.actor?.id}/cash-balance`
  )

  const handleNext = (formValues?: unknown) => {
    if (step === 1) {
      setData({
        ...data,
        investmentAmount: formValues as InvestmentAmountValues,
      })
      setStep(2)
    } else if (step === 2) {
      setStep(3)
    } else if (step === 3) {
      const investmentClass = offering.classes?.find(
        (el) => el.id === data.investmentAmount.investmentClassId
      )
      onSave(
        {
          amount: toNumber(data.investmentAmount.amount),
          agreement: data.investmentAgreement.agreementText,
          class: investmentClass?.name,
          rate: investmentClass?.rate,
        },
        {
          onSuccess: () => {
            setStep(4)
          },
        }
      )
    }
  }

  const handleToggleAgreement = (value: boolean) => {
    setData({
      ...data,
      investmentAgreement: {
        ...data.investmentAgreement,
        isAgreement: value,
      },
    })
  }

  const handleBack = () => {
    setStep(step - 1)
  }

  return (
    <Modal
      title={getTitle(step)}
      step={
        step === 4
          ? undefined
          : {
              steps: 3,
              step: step,
            }
      }
      asChild
      loading={isPending}
      className={styles.modal}
      onClose={onCancel}
    >
      {step === 1 && (
        <InvestmentAmountForm
          initialValues={data.investmentAmount}
          investmentClass={offering.classes || []}
          cashBalance={cashBalance || 0}
          onCancel={onCancel}
          onSubmit={handleNext}
        />
      )}
      {step === 2 && (
        <InvestmentAgreementForm
          isAgreement={data.investmentAgreement.isAgreement}
          onBack={handleBack}
          onCancel={onCancel}
          onNext={handleNext}
          toggleAgreement={handleToggleAgreement}
        />
      )}
      {step === 3 && (
        <InvestmentConfirmationForm
          investmentClassName={
            offering.classes?.find(
              (el) => el.id === data.investmentAmount.investmentClassId
            )?.name || 'Name not found'
          }
          amount={data.investmentAmount.amount}
          cashBalance={cashBalance || 0}
          investmentAccountName={
            user?.actor?.name || user?.person?.name || 'Name not found'
          }
          saving={saving}
          onBack={handleBack}
          onCancel={onCancel}
          onNext={handleNext}
        />
      )}
      {step === 4 && (
        <InvestmentSuccessForm
          investmentClassName={
            offering.classes?.find(
              (el) => el.id === data.investmentAmount.investmentClassId
            )?.name || 'Name not found'
          }
          amount={data.investmentAmount.amount}
        />
      )}
    </Modal>
  )
}

export default ModalInvestInOffering
