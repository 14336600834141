import { useQuery } from '@tanstack/react-query'
import { useState, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { Header } from 'components/Header'
import { Logo } from 'components/Logo'
import { Text } from 'components/Text'
import { verifyEmail } from 'services/api/session'
import styles from './styles.module.scss'

interface Props {
  pathTo: any
}

function VerifyEmail({ pathTo }: Props) {
  const [isTokenValid, setIsTokenValid] = useState<boolean>()
  const { id } = useParams() as { id: string }
  const [searchParams] = useSearchParams()

  const {
    data: validationData,
    isPending: isValidatingToken,
    isSuccess: isTokenValidationSuccess,
    isError: isTokenValidationError,
  } = useQuery({
    queryKey: ['verify'],
    queryFn: () =>
      verifyEmail({ id, token: searchParams.get('token') as string }),
  })
  useEffect(() => {
    if (isTokenValidationSuccess) {
      setIsTokenValid(validationData.success)
    }
  }, [isTokenValidationSuccess])
  useEffect(() => {
    if (isTokenValidationError) {
      setIsTokenValid(false)
    }
  }, [isTokenValidationError])

  if (isValidatingToken) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.formBox}>
        <Logo type="statement" to={pathTo('signIn')} />
        {isTokenValid ? (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Your email has been verified
            </Header>
            <Text className={styles.description}>
              You are now able to receive messages about your account.
            </Text>
          </div>
        ) : (
          <div className={styles.form}>
            <Header variant="h1" className={styles.title}>
              Your email verification link has expired
            </Header>
            <Text className={styles.description}>
              Hi there, the email verification link you requested has expired.
              Email verification links expire after 24 hours and can only be
              used once.
            </Text>
          </div>
        )}
        <div />
      </div>
    </div>
  )
}

export { VerifyEmail }
