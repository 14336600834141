import {
  useMutation,
  useQuery,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getQuotes,
  getQuote,
  addQuote,
  updateQuote,
  deleteQuote,
  updateQuoteField,
  sendQuote,
  convertQuote,
} from 'admin/services/api/quotes'
import { KEY_QUOTES } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { QuoteConvertToLoanProps } from 'types'
import { QuoteField } from 'types/field'
import { Filter, Pagination, Sort } from 'types/table'
import { message } from 'utils/message'

interface Props {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
}

const useQuotes = ({ search, filter, pagination, sort }: Props = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [KEY_QUOTES, search, nextFilter, pagination, sort],
    queryFn: () =>
      getQuotes({ search, filter: nextFilter, page: pagination, sort }),
    placeholderData: keepPreviousData,
  })
}

const useQuote = ({ id }: { id: string }) => {
  return useQuery({
    queryKey: [KEY_QUOTES, id],
    queryFn: () => getQuote(id),
  })
}

const useAddQuote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addQuote,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_QUOTES] })
      message.success('Quote saved')
    },
    onError: handleErrorResponse,
  })
}

const useUpdateQuote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: updateQuote,
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quote.id], quote)
      queryClient.invalidateQueries({ queryKey: [KEY_QUOTES] })
    },
    onError: handleErrorResponse,
  })
}

const useUpdateQuoteField = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (field: Partial<QuoteField>) => updateQuoteField({ id, field }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, quote.id], quote)
    },
    onError: handleErrorResponse,
  })
}

const useDeleteQuote = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (id: string) => deleteQuote(id),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [KEY_QUOTES] })
      }, 50)
      message.success('Quote deleted')
    },
    onError: handleErrorResponse,
  })
}

const useSendQuote = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ subject, message }: { subject: string; message: string }) =>
      sendQuote(id, { subject, message }),
    onSuccess: (quote) => {
      queryClient.setQueryData([KEY_QUOTES, id, 'version'], quote)
      queryClient.invalidateQueries({ queryKey: [KEY_QUOTES] })
    },
    onError: handleErrorResponse,
  })
}

const useQuoteConvert = (id: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (data: QuoteConvertToLoanProps) => convertQuote(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_QUOTES] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useQuotes,
  useQuote,
  useAddQuote,
  useUpdateQuote,
  useUpdateQuoteField,
  useDeleteQuote,
  useSendQuote,
  useQuoteConvert,
}
