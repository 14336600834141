import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { size } from 'lodash'
import {
  getTaskTemplates,
  getTaskTemplate,
  addTaskTemplate,
  removeTaskTemplate,
  editTaskTemplate,
  addTaskTemplateTask,
  removeTaskTemplateTask,
  editTaskTemplateTask,
  createLoanTasksFromTemplate,
} from 'admin/services/api/task-templates'
import { KEY_TASK_TEMPLATES, KEY_TASKS } from 'constants/query-keys'
import { handleErrorResponse } from 'services/request'
import { Filter, Pagination, Sort, TaskTemplateTask } from 'types'

const useTaskTemplates = ({
  search,
  filter,
  pagination = { page: 0, size: 500 },
  sort,
  details = false,
}: {
  search?: string
  filter?: Filter
  pagination?: Pagination
  sort?: Sort
  details?: boolean
} = {}) => {
  const nextFilter = size(filter) ? filter : undefined
  return useQuery({
    queryKey: [
      KEY_TASK_TEMPLATES,
      search,
      nextFilter,
      pagination,
      sort,
      details,
    ],
    queryFn: () =>
      getTaskTemplates({
        search,
        filter: nextFilter,
        page: pagination,
        sort,
        details,
      }),
    placeholderData: keepPreviousData,
  })
}

const useTaskTemplate = (id: string) => {
  return useQuery({
    queryKey: [KEY_TASK_TEMPLATES, id],
    queryFn: () => getTaskTemplate(id),
  })
}

const useAddTaskTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: addTaskTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TASK_TEMPLATES] })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveTaskTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: removeTaskTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TASK_TEMPLATES] })
    },
    onError: handleErrorResponse,
  })
}

const useEditTaskTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: editTaskTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TASK_TEMPLATES] })
    },
    onError: handleErrorResponse,
  })
}

const useAddTaskTemplateTask = (templateId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (task: Partial<TaskTemplateTask>) => addTaskTemplateTask(task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_TASK_TEMPLATES, templateId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useEditTaskTemplateTask = (templateId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (task: TaskTemplateTask) => editTaskTemplateTask(task),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_TASK_TEMPLATES, templateId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useRemoveTaskTemplateTask = (templateId: string) => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (taskId: string) => removeTaskTemplateTask(taskId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [KEY_TASK_TEMPLATES, templateId],
      })
    },
    onError: handleErrorResponse,
  })
}

const useCreateLoanTasksFromTemplate = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({
      loanId,
      templateId,
    }: {
      loanId: string
      templateId: string
    }) => createLoanTasksFromTemplate(loanId, templateId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [KEY_TASKS] })
    },
    onError: handleErrorResponse,
  })
}

export {
  useTaskTemplates,
  useTaskTemplate,
  useAddTaskTemplate,
  useRemoveTaskTemplate,
  useEditTaskTemplate,
  useAddTaskTemplateTask,
  useEditTaskTemplateTask,
  useRemoveTaskTemplateTask,
  useCreateLoanTasksFromTemplate,
}
