import { useCallback } from 'react'
import { pathTo } from 'admin/path-to'
import { Investor } from 'admin/services/api/investors'
import { useInvestorStatements } from 'borrower/hooks/use-investor-statements'
import { Panel } from 'components/Panel'
import { TableStatements } from 'components/TableStatements'
import { useMobile } from 'hooks/use-mobile'
import { downloadPdf } from 'utils/pdf'
import styles from './styles.module.scss'

interface Props {
  investor: Investor
}
export default function TabStatements({ investor }: Props) {
  const investorId = investor.id
  const { data: statements, isPending } = useInvestorStatements(investorId)
  const { isTablet } = useMobile()
  const handleClick = useCallback(
    (id) => {
      if (isTablet) {
        downloadPdf({
          url: pathTo('investorStatement', investorId, id),
          fileName: 'Investment-Report',
        })
      } else {
        window.open(`${pathTo('investorStatement', investorId, id)}`, '_blank')
      }
    },
    [isTablet]
  )

  return (
    <div className={styles.tabContent}>
      <Panel>
        <TableStatements
          statements={statements}
          loading={isPending}
          onClick={handleClick}
          onDownload={(id) =>
            downloadPdf({
              url: pathTo('investorStatement', investorId, id),
              fileName: 'Investment-Report',
            })
          }
        />
      </Panel>
    </div>
  )
}
