import { PageTop } from 'admin/components/PageTop'
import { MainContent } from 'admin/components/layout/MainContent'
import { pathTo } from 'admin/path-to'
import { Flex } from 'components/Flex'
import { PanelPortalSettings } from './PanelPortalSettings'

function PortalSettings() {
  return (
    <MainContent>
      <Flex stack gap={16}>
        <PageTop
          breadcrumbs={{ title: 'Company Settings', link: pathTo('settings') }}
          title="Portal Settings"
        />
        <PanelPortalSettings />
      </Flex>
    </MainContent>
  )
}

export { PortalSettings }
