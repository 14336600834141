import { useState } from 'react'
import {
  useAddOfferingRisk,
  useEditOfferingRisk,
  useDeleteOfferingRisk,
  useOfferingRisks,
} from 'admin/hooks/use-offering-risk'
import { EllipsesActions } from 'components/EllipsesActions'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Icon, IconName } from 'components/Icon'
import { ModalDelete } from 'components/Modal/Delete'
import { ModalRisk } from 'components/Modal/Risk'
import { Panel } from 'components/Panel'
import { TextLink } from 'components/TextLink'
import { Toggle } from 'components/Toggle'
import { Offering, Risk } from 'types'
import styles from './styles.module.scss'

interface Props {
  offering: Offering
}

function PanelRisks({ offering }: Props) {
  const [addingRisk, setAddingRisk] = useState(false)
  const [editingRisk, setEditingRisk] = useState<Risk>()
  const [deletingRisk, setDeletingRisk] = useState<Risk>()
  const { data: risks, isPending } = useOfferingRisks(offering.id)
  const { mutate: add, isPending: isAdding } = useAddOfferingRisk(offering.id)
  const { mutate: edit, isPending: isEditing } = useEditOfferingRisk(
    offering.id
  )
  const { mutate: deleteRisk, isPending: isDeleting } = useDeleteOfferingRisk(
    offering.id
  )
  const handleChange = (id, enabled) => {
    edit({ id, enabled })
  }

  return (
    <Panel title="Risks" loading={isPending}>
      <div className={styles.risksTable}>
        {risks?.length && !isPending ? (
          risks.map((risk) => (
            <div className={styles.tr} key={risk.id}>
              <div>
                <Toggle
                  defaultChecked={risk.enabled}
                  onChange={() => handleChange(risk.id, !risk.enabled)}
                />
              </div>
              <Flex stack gap={4} className={styles.riskDetails}>
                <Header variant="h4">{risk.name}</Header>
                <div>{risk.description}</div>
              </Flex>
              <div>
                <EllipsesActions>
                  <EllipsesActions.Item
                    icon
                    onSelect={() => setDeletingRisk(risk)}
                    className="text-red-100"
                  >
                    <Icon name={IconName.delete} />
                    Delete
                  </EllipsesActions.Item>
                </EllipsesActions>
              </div>
            </div>
          ))
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            className={styles.empty}
          >
            <TextLink onClick={() => setAddingRisk(true)}>
              Setup your first risk
            </TextLink>
          </Flex>
        )}
      </div>
      <TextLink className={styles.addRisk} onClick={() => setAddingRisk(true)}>
        <Icon name={IconName.plus} size="sm" />
        Add Risk
      </TextLink>
      {(addingRisk || editingRisk) && (
        <ModalRisk
          saving={isAdding || isEditing}
          risk={editingRisk}
          onSave={(risk) =>
            addingRisk
              ? add(
                  { ...risk, enabled: true },
                  {
                    onSuccess: () => setAddingRisk(false),
                  }
                )
              : edit(risk, {
                  onSuccess: () => setEditingRisk(undefined),
                })
          }
          onCancel={() => {
            setAddingRisk(false)
            setEditingRisk(undefined)
          }}
        />
      )}
      {deletingRisk && (
        <ModalDelete
          resource="risk"
          loading={isDeleting}
          onDelete={() => {
            deleteRisk(deletingRisk.id)
            setDeletingRisk(undefined)
          }}
          onCancel={() => setDeletingRisk(undefined)}
        />
      )}
    </Panel>
  )
}

export { PanelRisks }
