import clsx from 'clsx'
import { capitalize } from 'lodash'
import { useLoanToBorrowerKpiReport } from 'admin/hooks/use-report'
import { loanStatusToColor } from 'components/Badge/LoanStatusBadge'
import { Flex } from 'components/Flex'
import { Header } from 'components/Header'
import { Loan } from 'types'
import { formatUsd } from 'utils/currency'
import { formatDate } from 'utils/date'
import { MetaFields } from './MetaFields'
import { MeterBar } from './MeterBar'
import { MeterBarScopeOfWork } from './MeterBarScopeOfWork'
import styles from './styles.module.scss'

interface Props {
  loan: Loan
  type: string
  amount: number
  isScopeOfWork?: boolean
  isFunding?: boolean
}

const PaymentKpi = ({
  loan,
  type,
  amount,
  isScopeOfWork,
  isFunding,
}: Props) => {
  const color = loanStatusToColor[loan.status]
  const { data } = useLoanToBorrowerKpiReport(loan.id)

  return (
    <Flex
      stack
      gap={24}
      className="rounded-t border-0 border-b border-solid border-grey-200 bg-grey-50 px-5 pb-6 pt-4"
    >
      {['To Borrower', 'Funding'].includes(type) ? (
        <>
          <Header variant="h3">
            {type === 'Funding' ? 'Release Payment' : 'Process Payment'}
          </Header>
          <Flex gap={36}>
            <div className={styles.field}>
              <div className="text-grey-700">Loan Status</div>
              <div className={clsx('font-bold', color && styles[color])}>
                {capitalize(loan.status)}
              </div>
            </div>
            <div className={styles.field}>
              <div className="text-grey-700">Commitment Balance</div>
              <div className="font-bold">
                {formatUsd(data?.committedAmount)}
              </div>
            </div>
            <div className={styles.field}>
              <div className="text-grey-700">Trust Balance</div>
              <div className="font-bold">{formatUsd(data?.trustBalance)}</div>
            </div>
            {isScopeOfWork && (
              <div className={styles.field}>
                <div className="text-grey-700">Total Scope of Work</div>
                <div className="font-bold">{formatUsd(data?.budgetAmount)}</div>
              </div>
            )}
            {isFunding ? (
              <div className={styles.field}>
                <div className="text-grey-700">Origination Date</div>
                <div className="font-bold">{formatDate(loan.dateClosing)}</div>
              </div>
            ) : (
              <div className={styles.field}>
                <div className="text-grey-700">Maturity Date</div>
                <div className="font-bold">{formatDate(loan.dateMaturity)}</div>
              </div>
            )}
          </Flex>
          {isScopeOfWork ? (
            <MeterBarScopeOfWork
              budgetReleased={data?.budgetReleased || 0}
              budgetAmount={data?.budgetAmount || 0}
              amount={amount}
            />
          ) : (
            <MeterBar
              releasedAmount={data?.releasedBalance || 0}
              committedAmount={data?.committedAmount || 0}
              loanAmount={loan.amount}
              amount={amount}
            />
          )}
        </>
      ) : (
        <>
          <Header variant="h3">Process Payment</Header>
          <MetaFields
            fields={[
              {
                key: 'Principal Balance',
                value: formatUsd(loan.principalBalance),
              },
              { key: 'Regular Payment', value: formatUsd(loan.regularPayment) },
              {
                key: 'Paid Through',
                value: formatDate(loan.datePaidto) || '-',
              },
              {
                key: 'Next Payment',
                value: formatDate(loan.dateNextpayment) || '-',
                tooltip:
                  loan.dateMaturity && loan.dateNextpayment > loan.dateMaturity
                    ? 'Payment after maturity date of the loan'
                    : undefined,
              },
              {
                key: 'Maturity Date',
                value: formatDate(loan.dateMaturity) || '-',
              },
            ]}
          />
        </>
      )}
    </Flex>
  )
}

export { PaymentKpi }
