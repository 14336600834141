import { ReactNode } from 'react'
import { Flex } from 'components/Flex'
import { Icon, IconName } from 'components/Icon'
import { Tooltip } from 'components/Tooltip'
import styles from './styles.module.scss'

interface Props {
  fields: {
    key?: ReactNode
    value?: ReactNode
    tooltip?: string
  }[]
}

function MetaFields({ fields }: Props) {
  return (
    <Flex gap={36}>
      {fields
        .filter(({ key, value }) => key && value)
        .map(({ key, value, tooltip }, index) => (
          <div className={styles.field} key={index}>
            <div className="text-grey-700">{key}</div>
            <Flex
              gap={4}
              alignItems="center"
              className="font-bold text-grey-900"
            >
              {value}
              {tooltip && (
                <Tooltip content={tooltip}>
                  <Icon
                    name={IconName.info}
                    size="sm"
                    className={styles.hint}
                  />
                </Tooltip>
              )}
            </Flex>
          </div>
        ))}
    </Flex>
  )
}

export { MetaFields }
