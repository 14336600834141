import { useState } from 'react'
import { Flex } from 'components/Flex'
import { Grid } from 'components/Grid'
import { Icon, IconName } from 'components/Icon'
import { ModalAddress } from 'components/Modal/Address'
import { Panel } from 'components/Panel'
import { AddressSummary } from 'components/Summary'
import { TextLink } from 'components/TextLink'
import { Person } from 'types'
import { Address } from 'types/address'
import { isAddressDefined } from 'utils/address'
import { message } from 'utils/message'

interface Props {
  person: Person
  isLoading: boolean
  addAddress: (
    { id, address }: { id: string; address: Address },
    { ...options }
  ) => void
  updateAddress: (
    {
      id,
      addressId,
      address,
    }: { id: string; addressId: string; address: Address },
    { ...options }
  ) => void
}

export const PanelAddress = ({
  person,
  isLoading,
  addAddress,
  updateAddress,
}: Props) => {
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)

  const saveAddresses = (addresses: Record<'primary' | 'mailing', Address>) => {
    const currentPrimary = person.addresses?.find(
      ({ type }) => type === 'primary'
    )
    const currentMailing = person.addresses?.find(
      ({ type }) => type === 'mailing'
    )
    const options = {
      onSuccess: () => {
        message.success('Address saved')
        setIsAddressModalVisible(false)
      },
    }

    if (currentPrimary) {
      updateAddress(
        {
          id: person.id,
          addressId: currentPrimary.id,
          address: addresses.primary,
        },
        options
      )
    } else {
      addAddress(
        {
          id: person.id,
          address: { ...addresses.primary, type: 'primary' },
        },
        options
      )
    }

    if (currentMailing) {
      updateAddress(
        {
          id: person.id,
          addressId: currentMailing.id,
          address: addresses.mailing,
        },
        options
      )
    } else {
      addAddress(
        {
          id: person.id,
          address: { ...addresses.mailing, type: 'mailing' },
        },
        options
      )
    }
  }

  const addresses = person.addresses
    ?.sort((a) => (a.type === 'primary' ? -1 : 1))
    .filter((address) => isAddressDefined(address))

  return (
    <>
      <Panel title="Address" onEdit={() => setIsAddressModalVisible(true)}>
        <Grid>
          {addresses?.length ? (
            addresses.map((address) => (
              <Grid.Item sm={6} key={address.id}>
                <AddressSummary address={address} />
              </Grid.Item>
            ))
          ) : (
            <Grid.Item sm={12}>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                className="h-[52px] border-0 border-t border-solid border-grey-75"
              >
                <TextLink onClick={() => setIsAddressModalVisible(true)}>
                  <Icon name={IconName.plus} size="sm" />
                  Add Address
                </TextLink>
              </Flex>
            </Grid.Item>
          )}
        </Grid>
      </Panel>
      {isAddressModalVisible && (
        <ModalAddress
          addresses={person.addresses || []}
          saving={isLoading}
          onSave={(addresses) => saveAddresses(addresses)}
          onCancel={() => setIsAddressModalVisible(false)}
        />
      )}
    </>
  )
}
